import { OnInit, Component } from "@angular/core";
import { ReportService } from '../../../services/report/report.service';
import { Angular2Csv } from 'angular2-csv/Angular2-csv';
import { CustomErrorHandler } from '../../../errorhandler/custom-errorhandler';
import { CommonService } from '../../../services/common/common.service';
import { Router } from '@angular/router';
declare var $: any;
@Component({
  selector: 'app-employee-wise-survey-report',
  templateUrl: './employee-wise-survey-report.component.html',
  styleUrls: ['./employee-wise-survey-report.component.css']
})
export class EmployeeWiseSurveyReportComponent implements OnInit {
  isDemoTenant: boolean;
  loading: boolean;
  reportList: any = [];
  page: number = 2;
  pageNo: number = 1;
  perPage: number = 10;
  totalItem: number = 0;
  exportReport: any;
  noDataMsg: any;
  searchRecord: any = '';
  maxPage: number = 10;
  tempSearch: any = '';
  constructor(private router: Router, private commonService: CommonService, private reportService: ReportService, private customErrorHandler: CustomErrorHandler) {
  }
  ngOnInit() {
    this.isDemoTenant = (localStorage.getItem('isDemoTenant') == 'true');
    this.loading = false;
    this.page = 1;
    this.reportList = [];
    this.getReportList(1);
  }
  ngOnDestroy() {
    $('.fullHeightBlock').animate({ scrollTop: 0 }, 400);
  }
  search() {
    console.log(this.tempSearch, 'tt', this.searchRecord)
    if (this.tempSearch != this.searchRecord) {
      this.tempSearch = this.searchRecord;
      this.page = 1;
      this.reportList = [];
      this.getReportList(1);
    }
  }
  getReportList(currentPage) {
    this.pageNo = currentPage;
    this.page = currentPage;
    this.noDataMsg = '';
    this.exportReport = '';
    this.loading = true;
    this.reportService.getEmployeeWiseSurveyReport(this.pageNo, this.perPage, this.searchRecord, this.exportReport).subscribe(
      res => {
        this.loading = false;
        if (res.result == true) {
          this.totalItem = res.data.total;
          this.reportList = res.data.data;
          this.noDataMsg = res.message;
        } else {
          this.reportList = [];
          this.noDataMsg = res.message;
          this.commonService.showErrorMessage(res);
        }
      },
      error => {
        this.loading = false;
        this.customErrorHandler.handleHttpError(error, 'topPerformingEmployee');
      });
  }
  getSurveyList(surveyId) {
    localStorage.setItem('getSurveyEmployeeId', surveyId);
    this.router.navigate(["reports/survey-list-report"]);
  }
  export() {
    var options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: true,
      useBom: true,
      title: 'Employee Wise Survey List'
    };
    this.exportReport = 'yes';
    this.reportService.getEmployeeWiseSurveyReport(this.pageNo, this.perPage, this.searchRecord, this.exportReport).subscribe(
      res => {
        new Angular2Csv(res.data, 'Employee Wise Survey List', options);
      },
      error => {
        this.customErrorHandler.handleHttpError(error, 'topPerformingEmployee');
      });
  }
}
