import { AuthGuard } from './../../services/_guards/auth.guard';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown/angular2-multiselect-dropdown';
import { InfiniteScrollModule } from 'angular2-infinite-scroll';
import { ChartsModule } from 'ng2-charts';
import { ChartModule } from 'primeng/primeng';
import { Ng2PaginationModule } from 'ng2-pagination';
import { PipeModule } from '../../pipe/pipe.module';
import { CalendarModule } from 'primeng/calendar';
import { SurveyManagementComponent } from './survey-management.component';
import { SurveyListComponent } from './survey-list/survey-list.component';
import { CreateSurveyComponent } from './create-survey/create-survey.component';
import { SurveyAssignedListComponent } from './survey-assigned-list/survey-assigned-list.component';
import { AssignedSurveyComponent } from './assigned-survey/assigned-survey.component';
import { SurveyResultComponent } from './survey-result/survey-result.component';
export const routes: Routes = [
  {
    path: 'survey',
    component: SurveyManagementComponent, canActivate: [AuthGuard],
    children: [
      { path: '', redirectTo: 'survey-list', pathMatch: 'full' },
      { path: 'survey-list', component: SurveyListComponent },
      { path: 'create-survey', component: CreateSurveyComponent },
      { path: 'assigned-survey-list', component: SurveyAssignedListComponent },
      { path: 'assigned-survey', component: AssignedSurveyComponent },
      { path: 'survey-result', component: SurveyResultComponent }
    ]
  }
];
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule.forChild(routes),
    AngularMultiSelectModule,
    InfiniteScrollModule,
    PipeModule.forRoot(),
    CalendarModule,
    Ng2PaginationModule,
    ChartsModule,
    ChartModule
  ],
  declarations: [
    SurveyManagementComponent,
    SurveyListComponent,
    CreateSurveyComponent,
    SurveyAssignedListComponent,
    AssignedSurveyComponent,
    SurveyResultComponent,
  ]
})
export class SurveyModule { }
