import { Role_managementService } from './../../../../services/role_management/role_management.service';
import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { CustomErrorHandler } from '../../../../errorhandler/custom-errorhandler';
import { CommonService } from '../../../../services/common/common.service';
import { Constants } from '../../../../common/constants';
@Component({
    selector: 'assign-role',
    templateUrl: 'assign-role.component.html',
    styleUrls: ['./assign-role.component.css']
})
export class AssignRoleComponent implements OnInit {
    employeeLabel: string;
    modal: any;
    role_type_id: string = '';
    role: any[];
    selectedRole: any[];
    role_type_list: any[];
    errorMessage: string;
    disableButton: boolean;
    signin_btn_txt: string;
    menuTree: any = [];
    selectedFiles2: any = [];
    assignRoleData: any = {};
    menuIds: any = {}
    subMenuIds: any = {}
    locationLabel: any = Constants.locationLabel;
    constructor(
        private router: Router,
        public _Role_managementService: Role_managementService,
        private customErrorHandler: CustomErrorHandler,
        public commonService: CommonService
    ) { }
    ngOnInit() {
        this.selectedRole = [];
        this.employeeLabel = localStorage.getItem('employeeLabel');
        this.roleTypeList();
        this.roleList();
        this.getMenu();
    }
    roleList() {
        this._Role_managementService.roleList().subscribe(
            res => {
                if (res.result != 'Invalid Token') {
                    this.role = res.data;
                }
            },
            error => {
                this.customErrorHandler.handleHttpError(error, 'roleList');
            });
    }
    roleTypeList() {
        this._Role_managementService.roleTypeListForAssign().subscribe(
            res => {
                if (res.result == true) {
                    this.role_type_list = res.data;
                }
            },
            error => {
                this.customErrorHandler.handleHttpError(error, 'roleTypeListForAssign');
            });
    }
    onSubmit() {
        if (this.role_type_id == '' || this.role_type_id == undefined) {
            this.errorMessage = "Role Should not be blank";
            return false;
        }
        let allowMenu = '1';
        for (var key in this.menuIds) {
            if (this.menuIds[key] != undefined && this.menuIds[key] == true) {
                if (allowMenu == '') {
                    allowMenu = key;
                } else {
                    allowMenu += ',' + key;
                }
            }
        }
        this.assignRoleData.role_id = this.role_type_id;
        this.assignRoleData.allow_menu = allowMenu;
        this.assignRoleData.created_by = localStorage.getItem('user_id');
        console.log(this.assignRoleData);
        this._Role_managementService.createRoleAssign(this.assignRoleData).subscribe(
            res => {
                if (res.result == true) {
                    this.commonService.showSuccessMessage(res.message);
                    this.router.navigate(["setting/role-management/assign-rolelist"]);
                } else {
                    this.disableButton = false;
                    this.signin_btn_txt = "Submit";
                    this.commonService.showErrorMessage(res);
                }
            },
            error => {
                this.customErrorHandler.handleHttpError(error, 'createRoleAssign');
                this.disableButton = false;
                this.signin_btn_txt = "Submit";
            });
    }
    getMenu() {
        this._Role_managementService.getMenuList().subscribe(
            res => {
                if (res.result == true) {
                    this.menuTree = res.data;
                }
                else {
                    this.commonService.showErrorMessage(res);
                }
            },
            error => {
                this.customErrorHandler.handleHttpError(error, 'routeDelete');
            });
    }
    selectAll(id) {
        let temp = this.menuTree.find(s => s.id == id);
        if (this.menuIds[id] == true) {
            for (let i = 0; i < temp.children.length; i++) {
                this.menuIds[temp.children[i].id] = true;
            }
        } else {
            for (let i = 0; i < temp.children.length; i++) {
                this.menuIds[temp.children[i].id] = false;
            }
        }
    }
    checkIfAllSelected(menuId, subMenuId) {
        let temp = this.menuTree.find(s => s.id == menuId);
        var this1 = this;
        if (this1.menuIds[subMenuId] == true) {
            this1.menuIds[menuId] = true;
        } else {
            let count = 0;
            for (let i = 0; i < temp.children.length; i++) {
                if (this1.menuIds[temp.children[i].id] == true) {
                    count++;
                }
            }
            if (count == 0) {
                this1.menuIds[menuId] = false;
            }
        }
    }
}
