import { AuthGuard } from './../../services/_guards/auth.guard';
import { NgModule }      from '@angular/core';
import { CommonModule }  from '@angular/common';
import { Routes, RouterModule }  from '@angular/router';
import { FormsModule } from '@angular/forms';
import { InfiniteScrollModule } from 'angular2-infinite-scroll';
import { Http } from '@angular/http';
import { DashboardComponent } from './dashboard.component';
import { ChartsModule } from 'ng2-charts';
import { ChartModule } from 'primeng/chart';
import { GalleriaModule } from 'primeng/galleria';
import { PipeModule } from '../../pipe/pipe.module';

export const routes: Routes = [
  {
      path: 'dashboard',
      component: DashboardComponent,canActivate: [AuthGuard]
  }
];


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule.forChild(routes),
    InfiniteScrollModule,
    ChartsModule,
    GalleriaModule,
    ChartModule,
    PipeModule.forRoot()
  ],
  declarations: [
    DashboardComponent
  ]

})

export class DashboardModule { }
