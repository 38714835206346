import { Injectable } from '@angular/core';
import { HttpHelper } from '../../helpers/http.helper';
import { Config } from '../../config/config';
const company_id = localStorage.getItem('company_id');
@Injectable()
export class RouteService {
    constructor(private _httpHelper: HttpHelper) {
    }
    createRoute(routeParameter) {
        try {
            setTimeout(function () {
            }, 500)
            return this._httpHelper.post(Config.AuthenticationBaseURL1 + 'add-route', routeParameter)
        }
        catch (e) {
        }
    }
    routeList(request) {
        try {
            return this._httpHelper.post(Config.AuthenticationBaseURL1 + 'get-route-list', request)
        }
        catch (e) {
        }
    }
    routeDelete(routeId: string) {
        try {
            var request = {
                "id": routeId,
                "created_by": localStorage.getItem('user_id')
            };
            return this._httpHelper.post(Config.AuthenticationBaseURL1 + 'delete-route', request)
        }
        catch (e) {
        }
    }
    singleRouteDetails(routeId: string) {
        try {
            var request = {
                "route_id": routeId
            };
            return this._httpHelper.post(Config.AuthenticationBaseURL1 + 'get-single-route', request)
        }
        catch (e) {
        }
    }
    updateRoute(routeParameter) {
        try {
            return this._httpHelper.post(Config.AuthenticationBaseURL1 + 'update-route', routeParameter)
        }
        catch (e) {
        }
    }
    addGeofence(geofenceParameter) {
        try {
            return this._httpHelper.post(Config.AuthenticationBaseURL1 + 'add-geofence', geofenceParameter)
        }
        catch (e) {
        }
    }
    getCheckList() {
        try {
            var request = {
                "company_id": localStorage.getItem('company_id')
            };
            return this._httpHelper.post(Config.AuthenticationBaseURL1 + 'get-admin-check-list', request)
        }
        catch (e) {
        }
    }
    assignCheckList(requestParameter) {
        try {
            return this._httpHelper.post(Config.AuthenticationBaseURL1 + 'assign-check-list', requestParameter)
        }
        catch (e) {
        }
    }
    employeeList() {
        try {
            var request = {
                "company_id": company_id
            };
            return this._httpHelper.get(Config.AuthenticationBaseURL1 + 'teamListMember', request)
        }
        catch (e) {
        }
    }

    employeeLocationList(locationId) {
        try {
            var request = {
                "company_id": company_id,
                location_id: locationId
            };
            return this._httpHelper.get(Config.AuthenticationBaseURL1 + 'teamListMember', request)
        }
        catch (e) {
        }
    }
    employeeMeeting(request) {
        try {
            return this._httpHelper.post(Config.AuthenticationBaseURL1 + 'get-employee-meeting', request)
        }
        catch (e) {
        }
    }
}
