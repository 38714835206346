import { Component, OnInit } from '@angular/core';
declare var $: any;
@Component({
  selector: 'app-poll-management',
  templateUrl: './poll-management.component.html',
  styleUrls: ['./poll-management.component.css'],
  host: {
    'class': 'fullHeightBlock mox_fix'
  }
})
export class PollManagementComponent implements OnInit {
  constructor() { }
  ngOnInit() {
    if ($(window).width() > 760) {
      $('.fullHeightBlock').scrollbar();
    }
  }
  ngOnDestroy() {
    $('.fullHeightBlock').remove();
  }
}
