import { Injectable } from '@angular/core';
import { HttpHelper } from '../../helpers/http.helper';
import { Config } from '../../config/config';
const company_id = localStorage.getItem('company_id');
const user_id = localStorage.getItem('user_id');
@Injectable()
export class TeamlistService {
  constructor(private _httpHelper: HttpHelper) {
  }
  teamList(start) {
    try {
      var request = {
        "company_id": company_id
      };
      return this._httpHelper.post(Config.AuthenticationBaseURL + 'teamList', request)
    }
    catch (e) {
    }
  }
  teamListPaging(pageNo, rowPerPage, search, exportReport,locationId, reportingTo) {
    try {
      return this._httpHelper.get(Config.AuthenticationBaseURL1 + 'get-employee-team-list?page=' + pageNo + '&per_page=' + rowPerPage + '&search=' + search + '&export=' + exportReport+'&location_id='+locationId+'&reporting_to='+reportingTo)
    }
    catch (e) {
    }
  }
  SelectByTeamById(id: string) {
    try {
      var request = {
        "company_id": company_id,
        "team_id": id
      };
      return this._httpHelper.post(Config.AuthenticationBaseURL + 'SelectByTeamById', request)
    }
    catch (e) {
    }
  }
  teamDelete(team_id: string) {
    try {
      var request = {
        "team_id": team_id
      };
      return this._httpHelper.post(Config.AuthenticationBaseURL1 + 'delete-team', request)
    }
    catch (e) {
    }
  }
  employeeList(locationId, ownerId) {
    try {
      var request = {
        "company_id": company_id,
        "location_id": locationId,
        "reporting_to": ownerId
      };
      return this._httpHelper.get(Config.AuthenticationBaseURL1 + 'teamListMember', request)
    }
    catch (e) {
    }
  }
  createTeam(name: string, emp_id: string, locationId, ownerId) {
    try {
      var request = {
        "name": name,
        "emp_id": emp_id,
        "company_id": company_id,
        "created_by": user_id,
        "location_id": locationId,
        "owner_id": ownerId
      };
      return this._httpHelper.post(Config.AuthenticationBaseURL + 'createTeam', request)
    }
    catch (e) {
    }
  }
  EditTeam(name: string, emp_id: string, team_id: string,location_id,owner_id) {
    try {
      var request = {
        "name": name,
        "emp_id": emp_id,
        "company_id": company_id,
        "team_id": team_id,
        "location_id": location_id,
        "owner_id": owner_id
      };
      return this._httpHelper.post(Config.AuthenticationBaseURL + 'updateTeamWeb', request)
    }
    catch (e) {
    }
  }



}
