import { Component, OnInit } from '@angular/core';
import { ExpenseService } from '../../services/expense/expense.service';
import { CustomErrorHandler } from './../../errorhandler/custom-errorhandler';
import { Config } from './../../config/config';
import { Angular2Csv } from 'angular2-csv/Angular2-csv';
import { CommonService } from '../../services/common/common.service';
import { Constants } from '../../common/constants';
declare var moment: any;
declare var $: any;
@Component({
  selector: 'app-expense',
  templateUrl: './expense.component.html',
  styleUrls: ['./expense.component.css'],
  host: {
    'class': 'fullHeightBlock'
  }
})
export class ExpenseComponent implements OnInit {
  isExport: boolean;
  Editmodal: any;
  expenseList: any;
  searchTextValue: string;
  errMessage: string;
  startDate: any;
  endDate: any;
  tempSearch: any;
  ImgDownloadUrl: string;
  expense: any = [];
  selectedID: any;
  currentStatus: any;
  statuslist: any;
  totalItem: number = 0;
  perPage: number = 10;
  pageNo: number = 1;
  page: number = 2;
  loading: boolean;
  employeeLabel: string;
  prospectLabel: string;
  exportReport: any;
  reportingUserList: any = [];
  reportingSelectedItem: any = [];
  reportingSettings: any = {};
  locationList: any = [];
  locationSettings: any = {};
  locationSelectedItem: any = [];
  reportingTo: any = '';
  locationId: any = '';
  isDemoTenant: boolean;
  defaultSelectObject: any = {id: '',itemName: 'All'};
  filterLabel = Constants.filterLabel;

  constructor(
    private expenseService: ExpenseService,
    private customErrorHandler: CustomErrorHandler,
    private commonService: CommonService
  ) { }
  ngOnInit() {
    this.isDemoTenant = (localStorage.getItem('isDemoTenant') == 'true');
    this.searchTextValue = '';
    this.prospectLabel = localStorage.getItem('prospectLabel');
    this.employeeLabel = localStorage.getItem('employeeLabel');
    this.isExport = false;
    this.tempSearch = '';
    this.ImgDownloadUrl = Config.ImageDownloadURL;
    this.Editmodal = document.getElementById('EditmyModal');
    this.statusList();
    var start = moment().subtract(6, 'days');
    var end = moment();
    var _this = this;
    function cb(start, end) {
      $('#reportrange span').html(start.format('MMM D, YYYY') + ' - ' + end.format('MMM D, YYYY'));
      _this.startDate = start.format('YYYY-MM-DD');
      _this.endDate = end.format('YYYY-MM-DD');
      _this.getExpenses(1);
    }
    $('#reportrange').daterangepicker({
      startDate: start,
      endDate: end,
      ranges: {
        'Today': [moment(), moment()],
        'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
        'Last 7 Days': [moment().subtract(6, 'days'), moment()],
        'Last 30 Days': [moment().subtract(29, 'days'), moment()],
        'This Month': [moment().startOf('month'), moment().endOf('month')],
        'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
      }
    }, cb);
    cb(start, end);
    if ($(window).width() > 760) {
      $('.fullHeightBlock').scrollbar();
    }
    this.getLocationList();
    this.getReportingUserList(this.locationId);
    this.reportingSettings = {
      singleSelection: true,
      text: "Select Reporting Person",
      enableSearchFilter: true,
      classes: "singleSelection block_elem m_b_15"
    };
    this.locationSettings = {
      singleSelection: true,
      text: "Select "+ Constants.locationLabel,
      enableSearchFilter: true,
      classes: "singleSelection block_elem m_b_15"
    };
  }
  EditOpenmodel(status, id) {
    this.selectedID = id;
    this.currentStatus = status;
    this.Editmodal.style.display = "block";
  }
  EditcloseModal() {
    this.Editmodal.style.display = "none";
  }
  getExpenses(currentPage) {
    this.loading = true;
    this.pageNo = currentPage;
    this.page = currentPage;
    this.errMessage = '';
    this.expenseList = [];
    this.isExport = false;
    this.exportReport = '';
    this.expenseService.expenseList(this.pageNo, this.perPage, this.startDate, this.endDate, this.searchTextValue, this.exportReport, this.reportingTo, this.locationId).subscribe(
      res => {
        this.loading = false;
        if (res.result == true) {
          this.totalItem = res.data.total;
          if (this.totalItem > 0) {
            this.isExport = true;
          }
          this.expenseList = res.data.data;
          this.errMessage = res.message;
        } else {
          this.expenseList = [];
          this.errMessage = res.message;
          this.commonService.showErrorMessage(res);
        }
      },
      error => {
        this.loading = false;
        this.customErrorHandler.handleHttpError(error, 'roleTypeList');
      }
    )
  }
  getImage(image) {
    let index = image.lastIndexOf("/") + 1;
    return image.substr(index);
  }
  Statusupdate() {
    let request = {
      "expense_id": this.selectedID,
      "expense_status": this.currentStatus
    };
    this.expenseService.updateStatus(request).subscribe(() => {
      this.Editmodal.style.display = "none";
      this.getExpenses(1);
    },
      error => {
        this.customErrorHandler.handleHttpError(error, 'roleTypeList');
      })
  }
  statusList() {
    this.expenseService.getAllStatus().subscribe(
      res => {
        if (res.result == true) {
          this.statuslist = res.data;
        }
      },
      error => {
        this.customErrorHandler.handleHttpError(error, 'roleTypeList');
      }
    )
  }
  searchExpense() {
    if (this.tempSearch != this.searchTextValue) {
      this.getExpenses(1);
    }
    this.tempSearch = this.searchTextValue;
  }
  export() {
    var options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: true,
      useBom: true,
      title: "Expense-List"

    };
    this.exportReport = 'yes';
    this.expenseService.expenseList(0, 0, this.startDate, this.endDate, this.searchTextValue, this.exportReport, this.reportingTo, this.locationId).subscribe(
      res => {
        new Angular2Csv(res.data, 'getExpenses', options);
      },
      error => {
        this.customErrorHandler.handleHttpError(error, 'roleTypeList');
      }
    )
  }
  getReportingUserList(locationId) {
    this.commonService.getReportingUserList(locationId).subscribe(res => {
      if (res.result == true) {
        this.reportingUserList = res.data;
        if(this.reportingUserList.length > 1){
          this.reportingUserList.unshift(this.defaultSelectObject);
        }
      }
    }, err => {
      this.customErrorHandler.handleHttpError(err, 'getRoleList');
    })
  }
  getLocationList() {
    this.commonService.getLocationList().subscribe(res => {
      if (res.result == true) {
        this.locationList = res.data;
        if(this.locationList.length > 1){
          this.locationList.unshift(this.defaultSelectObject);
        }
      }
    }, err => {
      this.customErrorHandler.handleHttpError(err, 'getRoleList');
    })
  }
  onUserItemSelect(item: any) {
    this.reportingTo = item.id;
    this.getExpenses(1);
  }
  onUserItemDeSelect(item: any) {
    this.reportingTo = '';
    this.getExpenses(1);
  }
  onLocationItemSelect(item: any) {
    this.locationId = item.id;
    this.reportingTo = '';
    this.reportingUserList = [];
    this.reportingSelectedItem = [];
    // if(this.locationId != ''){
      this.getReportingUserList(this.locationId);
    // }
    this.getExpenses(1);
  }
  onLocationItemDeSelect(item: any) {
    this.locationId = '';
    this.reportingTo = '';
    this.reportingUserList = [];
    this.reportingSelectedItem = [];
    this.getExpenses(1);
  }
  ngOnDestroy() {
    $('.fullHeightBlock').remove();
  }
}
