import { AuthGuard } from './../../../services/_guards/auth.guard';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LocationsComponent } from './locations.component';
import { LocationListComponent } from './location-list/location-list.component';
import { AddLocationComponent } from './add-location/add-location.component';
import { Ng2PaginationModule } from 'ng2-pagination';
import { CalendarModule } from 'primeng/calendar';
export const routes: Routes = [
  {
    path: 'locations',
    component: LocationsComponent, canActivate: [AuthGuard],
    children: [
      { path: '', redirectTo: 'location-list', pathMatch: 'full' },
      { path: 'location-list', component: LocationListComponent },
      { path: 'add-location', component: AddLocationComponent }
    ]
  }
];
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule.forChild(routes),
    ReactiveFormsModule,
    Ng2PaginationModule,
    CalendarModule
  ],
  declarations: [
    LocationsComponent,
    LocationListComponent,
    AddLocationComponent
  ]
})
export class LocationModule { }
