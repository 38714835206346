import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Constants } from '../../../common/constants';
import { CustomErrorHandler } from './../../../errorhandler/custom-errorhandler';
import swal from 'sweetalert2';
import { CommonService } from '../../../services/common/common.service';
import { DigitalFormService } from './../../../services/digital-form/digital-form.service';
@Component({
  selector: 'digital-form-category',
  templateUrl: 'digital-form-category.component.html',
  styleUrls: ['./digital-form-category.component.css']
})

export class DigitalFormCategoryComponent implements OnInit {
  isDemoTenant: boolean;
  errormsg: string;
  msg: boolean;
  form_category: any[];
  loading: boolean;
  actionLable: string;
  employeeLabel: string;
  pageNo: number = 1;
  perPage: number = 10;
  totalItem: number = 0;
  page: number = 2;
  constructor(
    private router: Router,
    public digitalFormService: DigitalFormService,
    private customErrorHandler: CustomErrorHandler,
    public commonService: CommonService
  ) { }
  ngOnInit() {
    this.isDemoTenant = (localStorage.getItem('isDemoTenant') == 'true');
    this.errormsg = '';
    this.msg = false;
    this.actionLable = localStorage.getItem('actionLabel');
    this.employeeLabel = localStorage.getItem('employeeLabel');
    this.loading = true;
    this.categoryList(1);
  }
  categoryList(currentPage) {
    this.pageNo = currentPage;
    this.page = currentPage;
    this.errormsg = '';
    this.digitalFormService.categoryList(this.pageNo, this.perPage).subscribe(
      res => {
        this.loading = false;
        if (res.result == true) {
          this.totalItem = res.data.total;
          this.form_category = res.data.data;
          this.errormsg = res.message;
        } else {
          this.totalItem = 0;
          this.form_category = [];
          this.errormsg = res.message;
          this.commonService.showErrorMessage(res);
        }
      },
      error => {
        this.loading = false;
        this.customErrorHandler.handleHttpError(error, 'categoryList');
      });
  }
  formTemplateCategoryDelete(value) {
    swal({
      text: Constants.DeleteMsg,
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Delete',
    }).then((result) => {
      if (typeof result.value != 'undefined') {
        this.digitalFormService.formTemplateCategoryDelete(value).subscribe(
          res => {
            if (res.result == true) {
              this.commonService.showSuccessMessage(res.message);
              this.categoryList(1);
            }
            else {
              this.commonService.showErrorMessage(res);
            }
          },
          error => {
            this.customErrorHandler.handleHttpError(error, 'formTemplateCategoryDelete');
          });
      }
    });
  }
  fomeTemplateCategoryEdit(id) {
    localStorage.setItem('formTemplateCategoryId', id);
    this.router.navigate(["setting/digital-form-category/edit-digital-form-category"]);
  }
}
