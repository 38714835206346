import { Injectable } from "@angular/core";
import { HttpHelper } from "../../helpers/http.helper";
import { Config } from "../../config/config";
const company_id = localStorage.getItem('company_id');
@Injectable()
export class SideBarService {
  constructor(private _httpHelper: HttpHelper) {
  }
  public getleftSideBarList() {
    try {
      var request = {
        "company_id": company_id
      };
      return this._httpHelper.post(Config.AuthenticationBaseURL + 'get_roles', request)
    }
    catch (e) {
    }
  }
}
