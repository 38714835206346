import { Component, OnInit } from '@angular/core';
declare var $: any;
@Component({
  selector: 'app-reportdashboard',
  templateUrl: './reportdashboard.component.html',
  styleUrls: ['./reportdashboard.component.css']
})
export class ReportdashboardComponent implements OnInit {
  employeeLabel: string;
  prospectLabel: string;
  public voterList: boolean = localStorage.getItem('VoterList') == 'true';
  constructor() { }
  ngOnInit() {
    this.employeeLabel = localStorage.getItem('employeeLabel');
    this.prospectLabel = localStorage.getItem('prospectLabel');
    $('.box_body .card-detail-wrapper').scrollbar();
  }
  ngOnDestroy() {
    $('.fullHeightBlock').animate({ scrollTop: 0 }, 400);
  }
}
