import { AuthGuard } from './../../services/_guards/auth.guard';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { InfiniteScrollModule } from 'angular2-infinite-scroll';
import { CompanySettingsComponent } from './company-settings.component';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown/angular2-multiselect-dropdown';
export const routes: Routes = [
  {
    path: 'company-settings',
    component: CompanySettingsComponent, canActivate: [AuthGuard]
  }
];
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule.forChild(routes),
    InfiniteScrollModule,
    AngularMultiSelectModule
  ],
  declarations: [
    CompanySettingsComponent
  ]
})
export class CompanySettingsModule { }
