import { Component, OnInit } from '@angular/core';
import { AssignService } from '../../../services/assign/assign.service';
import { ReportService } from '../../../services/report/report.service';
import { Angular2Csv } from 'angular2-csv/Angular2-csv';
import { CustomErrorHandler } from '../../../errorhandler/custom-errorhandler';
import { CommonService } from '../../../services/common/common.service';
import { Config } from '../../../config/config';
import { Constants } from '../../../common/constants';
declare var moment: any;
declare var $: any;
@Component({
  selector: 'app-employee-attendence-report',
  templateUrl: './employee-attendence-report.component.html',
  styleUrls: ['./employee-attendence-report.component.css']
})
export class EmployeeAttendenceReportComponent implements OnInit {
  isExport: boolean;
  isDemoTenant: boolean;
  prospectLabel: string;
  employeeLabel: string;
  employeeName: any;
  employee: any = [];
  attendanceReportList: any = [];
  emp_id: any;
  report_type: any;
  errorMessage: string;
  disableButton: boolean;
  signin_btn_txt: string;
  start_date: string;
  end_date: string;
  errormsg: string;
  msg: boolean;
  prospect_type: boolean;
  attend_type: boolean;
  loading: boolean;
  dropdownSettings: any = {};
  selectionList: any = [];
  noDataMsg: string = 'Currently, there is no data available.';
  page: number = 2;
  pageNo: number = 1;
  perPage: number = 10;
  totalItem: number = 0;
  exportReport: any;
  imageBaseUrl: string = Config.AuthenticationURL;
  reportingUserList: any = [];
  reportingSelectedItem: any = [];
  reportingSettings: any = {};
  locationList: any = [];
  locationSettings: any = {};
  locationSelectedItem: any = [];
  reportingTo: any = '';
  locationId: any = '';
  defaultSelectObject: any = { id: '', itemName: 'All' };
  filterLabel = Constants.filterLabel;
  viewImgModal:any;
  imageTitle:string;
  imageUrl:string;
  constructor(private commonService: CommonService, public _assignService: AssignService, public _reportService: ReportService, private customErrorHandler: CustomErrorHandler) {
  }
  ngOnInit() {
    
    this.viewImgModal = document.getElementById('viewImgModal');
    this.isExport = false;
    this.isDemoTenant = (localStorage.getItem('isDemoTenant') == 'true');
    this.employeeLabel = localStorage.getItem('employeeLabel');
    this.prospectLabel = localStorage.getItem('prospectLabel');
    this.msg = true;
    this.prospect_type = false;
    this.attend_type = false;
    this.emp_id = '';
    this.report_type = 1;
    this.dropdownSettings = {
      singleSelection: true,
      text: 'Select ' + this.employeeLabel,
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      enableSearchFilter: true,
      classes: 'myclass custom-class block_elem m_b_15 singleSelection'
    };
    var start = moment().subtract(6, 'days');
    var end = moment();
    var _this;
    _this = this;
    function cb(start, end) {
      $('#reportrange span').html(start.format('MMM D, YYYY') + ' - ' + end.format('MMM D, YYYY'));
      var f_start = start.format('YYYY-MM-DD');
      var f_end = end.format('YYYY-MM-DD');
      _this.start_date = f_start;
      _this.end_date = f_end;
      _this.onSubmit(1);
    }
    $('#reportrange').daterangepicker({
      startDate: start,
      endDate: end,
      ranges: {
        'Today': [moment(), moment(),],
        'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
        'Last 7 Days': [moment().subtract(6, 'days'), moment()],
        'Last 30 Days': [moment().subtract(29, 'days'), moment()],
        'This Month': [moment().startOf('month'), moment().endOf('month'), 'inclusive'],
        'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
      }
    }, cb);
    cb(start, end);
    this.getLocationList();
    this.getReportingUserList(this.locationId);
    this.reportingSettings = {
      singleSelection: true,
      text: "Select Reporting Person",
      enableSearchFilter: true,
      classes: "singleSelection block_elem m_b_15"
    };
    this.locationSettings = {
      singleSelection: true,
      text: "Select "+ Constants.locationLabel,
      enableSearchFilter: true,
      classes: "singleSelection block_elem m_b_15"
    };
    this.onSubmit(1);
  }
  ngOnDestroy() {
    $('.fullHeightBlock').animate({ scrollTop: 0 }, 400);
  }
  openImgModal(title,url) {
    this.imageTitle = title;
    this.imageUrl = url;
    this.viewImgModal.style.display = "block";
  }
  closeImgModal() {
    this.viewImgModal.style.display = "none";
  }
  employeeList() {
    let parameter = {
      'location_id': this.locationId,
      'reporting_to': this.reportingTo
    }
    this._assignService.employeeList(parameter).subscribe(
      res => {
        this.employee = res.data;
        // this.noDataMsg = res.message || 'Currently, there is no data available.';
      },
      error => {
        this.customErrorHandler.handleHttpError(error, 'employeeList');
      });
  }
  onOpen(event: any) {
    this.employee = this.employee.map((item) => {
      return {
        id: item.id,
        itemName: item.itemName
      }
    });
  }
  onItemSelect(item: any) {
    this.emp_id = item.id;
    this.onSubmit(1);
  }
  OnItemDeSelect(item: any) {
    this.emp_id = '';
    this.onSubmit(1);
  }
  onSelectAll(items: any) {
  }
  onDeSelectAll(items: any) {
  }
  onSubmit(currentPage) {
    // if (this.emp_id == '' || this.emp_id == undefined) {
    //   this.errorMessage = this.employeeLabel + " Should not be blank";
    //   return false;
    // }
    // if (this.report_type == '' || this.report_type == undefined) {
    //   this.errorMessage = "Report type Should not be blank";
    //   return false;
    // }
    this.pageNo = currentPage;
    this.page = currentPage;
    this.noDataMsg = '';
    this.exportReport = '';
    this.loading = true;
    this.loading = true;
    this.disableButton = true;
    this.errorMessage = '';
    this.signin_btn_txt = "Creating";
    this._reportService.employeeReport(this.pageNo, this.perPage, this.emp_id, this.report_type, this.start_date, this.end_date, this.exportReport, this.locationId, this.reportingTo).subscribe(
      res => {
        this.loading = false;
        if (res.result == true) {
          this.totalItem = res.data.total;
          this.attendanceReportList = res.data.data;
          this.noDataMsg = res.message;
          if (this.attendanceReportList.length > 0) {
            this.isExport = true;
          }
          else {
            this.isExport = false;
          }
        } else {
          this.attendanceReportList = [];
          this.noDataMsg = res.message;
          this.commonService.showErrorMessage(res);
        }
      },
      error => {
        this.loading = false;
        this.customErrorHandler.handleHttpError(error, 'employeeReport');
        this.disableButton = false;
        this.signin_btn_txt = "Submit";
      });
  }
  export() {
    // if (this.emp_id == '' || this.emp_id == undefined) {
    //   this.errorMessage = this.employeeLabel + " Should not be blank";
    //   return false;
    // }
    // if (this.report_type == '' || this.report_type == undefined) {
    //   this.errorMessage = "Report type Should not be blank";
    //   return false;
    // }
    var options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: true,
      useBom: true,
      title: this.employeeLabel + ' Attendence Report'
    };
    this.exportReport = 'yes';
    this._reportService.employeeReport(0, 0, this.emp_id, this.report_type, this.start_date, this.end_date, this.exportReport, this.locationId, this.reportingTo).subscribe(
      res => {
        new Angular2Csv(res.data, this.employeeLabel + ' Attendence Report', options);
      },
      error => {
        this.customErrorHandler.handleHttpError(error, 'employeeReport');
        this.disableButton = false;
        this.signin_btn_txt = "Submit";
      });
  }
  getReportingUserList(locationId) {
    this.commonService.getReportingUserList(locationId).subscribe(res => {
      if (res.result == true) {
        this.reportingUserList = res.data;
        if (this.reportingUserList.length > 1) {
          this.reportingUserList.unshift(this.defaultSelectObject);
        }
      }
    }, err => {
      this.customErrorHandler.handleHttpError(err, 'getRoleList');
    });
  }
  getLocationList() {
    this.commonService.getLocationList().subscribe(res => {
      if (res.result == true) {
        this.locationList = res.data;
        if (this.locationList.length > 1) {
          this.locationList.unshift(this.defaultSelectObject);
        }
      }
    }, err => {
      this.customErrorHandler.handleHttpError(err, 'getRoleList');
    });
  }
  onUserItemSelect(item: any) {
    this.reportingTo = item.id;
    this.emp_id = '';
    this.selectionList = [];
    this.employee = [];
    this.employeeList();
    this.onSubmit(1);
  }
  onUserItemDeSelect(item: any) {
    this.reportingTo = '';
    this.emp_id = '';
    this.selectionList = [];
    this.employee = [];
    this.employeeList();
    this.onSubmit(1);
  }
  onLocationItemSelect(item: any) {
    this.locationId = item.id;
    this.reportingTo = '';
    this.reportingUserList = [];
    this.reportingSelectedItem = [];
    this.emp_id = '';
    this.selectionList = [];
    this.employee = [];
    this.employeeList();
    // if (this.locationId != '') {
      this.getReportingUserList(this.locationId);
    // }
    this.onSubmit(1);
  }
  onLocationItemDeSelect(item: any) {
    this.locationId = '';
    this.reportingTo = '';
    this.reportingUserList = [];
    this.reportingSelectedItem = [];
    this.emp_id = '';
    this.selectionList = [];
    this.employee = [];
    this.employeeList();
    this.onSubmit(1);
  }
}
