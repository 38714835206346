import { Injectable } from '@angular/core';
import { HttpHelper } from '../../helpers/http.helper';
import { Config } from '../../config/config';
const company_id = localStorage.getItem('company_id');
@Injectable()
export class Role_managementService {
  constructor(private _httpHelper: HttpHelper) {
  }
  roleList() {
    try {
      var request = {
        "company_id": company_id
      };
      return this._httpHelper.post(Config.AuthenticationBaseURL + 'roleList', request)
    }
    catch (e) {
    }
  }
  assignRoleList(pageNo, rowPerPage) {
    try {
      return this._httpHelper.get(Config.AuthenticationBaseURL1 + 'get-assign-role-list?page=' + pageNo + '&per_page=' + rowPerPage)
    }
    catch (e) {
    }
  }
  roleTypeList() {
    try {
      var request = {
        "company_id": company_id
      };
      return this._httpHelper.post(Config.AuthenticationBaseURL + 'roleTypeList', request)
    }
    catch (e) {
    }
  }
  roleTypeListForAssign() {
    try {
      var request = {
        "company_id": company_id
      };
      return this._httpHelper.post(Config.AuthenticationBaseURL + 'roleTypeListForAssign', request)
    }
    catch (e) {
    }
  }
  selectAssignRoleById(id: string) {
    try {
      var request = {
        "id": id
      };
      return this._httpHelper.get(Config.AuthenticationBaseURL1 + 'get-menu-by-role', request)
    }
    catch (e) {
    }
  }
  createRoleAssign(request) {
    try {
      return this._httpHelper.post(Config.AuthenticationBaseURL1 + 'add-menu-access', request)
    }
    catch (e) {
    }
  }
  updateRoleAssign(request) {
    try {
      return this._httpHelper.post(Config.AuthenticationBaseURL1 + 'update-menu-access', request)
    }
    catch (e) {
    }
  }
  getMenuList() {
    try {
      return this._httpHelper.get(Config.AuthenticationBaseURL1 + 'get-menu');
    }
    catch (e) {
    }
  }
}
