import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Constants } from '../../../common/constants';
import { CustomErrorHandler } from './../../../errorhandler/custom-errorhandler';
import swal from 'sweetalert2';
import { CommonService } from '../../../services/common/common.service';
import { DigitalFormService } from './../../../services/digital-form/digital-form.service';
@Component({
  selector: 'digital-form',
  templateUrl: 'digital-form.component.html',
  styleUrls: ['./digital-form.component.css']
})
export class DigitalFormComponent implements OnInit {
  isDemoTenant: boolean;
  errormsg: string;
  msg: boolean;
  actionLable: string;
  modal: any;
  form_template: any[];
  loading: boolean;
  pageNo: number = 1;
  perPage: number = 10;
  totalItem: number = 0;
  page: number = 2;
  searchText: any = '';
  tempSearch: any = '';

  constructor(
    private router: Router,
    public digitalFormService: DigitalFormService,
    private customErrorHandler: CustomErrorHandler,
    public commonService: CommonService
  ) { }
  ngOnInit() {
    this.isDemoTenant = (localStorage.getItem('isDemoTenant') == 'true');
    this.errormsg = '';
    this.msg = false;
    this.actionLable = localStorage.getItem('actionLabel');
    this.loading = true;
    this.form_templateList(1);
  }
  form_templateList(currentPage) {
    this.pageNo = currentPage;
    this.page = currentPage;
    this.errormsg = '';
    this.digitalFormService.form_templateList(this.pageNo, this.perPage, this.searchText).subscribe(
      res => {
        this.loading = false;
        if (res.result == true) {
          this.totalItem = res.data.total;
          this.form_template = res.data.data;
          this.errormsg = res.message;
        } else {
          this.totalItem = 0;
          this.form_template = [];
          this.errormsg = res.message;
          this.commonService.showErrorMessage(res);
        }
      },
      error => {
        this.loading = false;
        this.customErrorHandler.handleHttpError(error, 'form_templateList');
      });
  }
  categoryDelete(value, isAssign) {
    let deleteMsg = Constants.DeleteMsg;
    if(isAssign == true){
      deleteMsg = 'This will delete the assigned records as well.  Are you sure you want to delete it?';
    }
    swal({
      text: deleteMsg,
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Delete',
    }).then((result) => {
      if (typeof result.value != 'undefined') {
        this.digitalFormService.categoryDelete(value).subscribe(
          res => {
            if (res.result == true) {
              this.commonService.showSuccessMessage(res.message);
              this.form_templateList(1);
            }
            else {
              this.commonService.showErrorMessage(res);
            }
          },
          error => {
            this.customErrorHandler.handleHttpError(error, 'categoryDelete');
          }
        );
      }
    });
  }
  fomeTemplateEdit(id) {
    localStorage.setItem('fomeTemplateEditId', id);
    this.router.navigate(["setting/digital-form/edit-digital-form"]);
  }
  Openmodel() {
    this.modal.style.display = "block";
  }
  closeModal() {
    this.modal.style.display = "none";
  }

  onkeyupSearch(item:any){
    if(this.tempSearch != this.searchText){
      this.tempSearch = this.searchText;
      this.form_templateList(1);
    }
  }
}
