import { MeetingService } from './../../../services/meeting/meeting.service';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CustomErrorHandler } from '../../../errorhandler/custom-errorhandler';
import { CommonService } from '../../../services/common/common.service';
import { PollSurveyService } from '../../../services/polls-surveys/poll-survey';
import { Constants } from '../../../common/constants';
declare var moment: any;
@Component({
  selector: 'app-assigned-poll',
  templateUrl: './assigned-poll.component.html',
  styleUrls: ['./assigned-poll.component.css']
})
export class AssignedPollComponent implements OnInit {
  pageLabel: string;
  pollData: any = {};
  teamList = [];
  teamSettings = {};
  teamSelectedItems: any = [];
  pollList = [];
  pollSettings = {};
  pollSelectedItems: any = [];
  employeeMemberList = [];
  employeeMemberSettings = {};
  TeamMemberItems = [];
  employeeLabel = localStorage.getItem('employeeLabel');
  companyId = localStorage.getItem('company_id');
  userId = localStorage.getItem('user_id');
  errorMessage: any;
  startDateTimeMin: any;
  endDateTimeMin: any;
  startTime: any;
  endTime: any;
  startDateErrorMsg: any;
  endDateErrorMsg: any;
  isEditMode: boolean;
  selectedId: any;
  isPollList: boolean = false;
  isAssignTeamList: boolean = false;
  isEmployeeList: boolean = false;
  loading: boolean;
  locationList: any = [];
  locationSelectedItem: any = [];
  locationSettings: any = {};
  locationId;
  // defaultSelectObject: any = { id: '', itemName: 'All' }
  locationLabel: any = Constants.locationLabel;
  constructor(
    private router: Router,
    public commonService: CommonService,
    private customErrorHandler: CustomErrorHandler,
    private pollSurveyService: PollSurveyService,
    public sharedService: MeetingService,
    public activatedRoute: ActivatedRoute,
  ) { }
  ngOnInit() {
    this.startDateTimeMin = new Date();
    this.endDateTimeMin = new Date();
    this.startTime = new Date();
    this.endTime = new Date();
    this.pollData.start_date = new Date();
    this.pollData.end_date = new Date();
    this.pageLabel = 'Assign'
    this.errorMessage = '';
    this.startDateErrorMsg = '';
    this.endDateErrorMsg = '';
    this.pollSettings = {
      singleSelection: true,
      text: "Select Poll",
      enableSearchFilter: true,
      classes: "singleSelection"
    };
    this.employeeMemberSettings = {
      singleSelection: false,
      text: "Select " + this.employeeLabel,
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      enableSearchFilter: true,
      classes: "myclass custom-class"
    };
    this.pollDropdownList();
    // this.getLocationList();
    // this.employeeListFunction(this.locationId);
    this.selectedId = this.activatedRoute.snapshot.queryParamMap.get('id');
    if (this.selectedId) {
      this.pageLabel = 'Edit Assign'
      this.isEditMode = true;
      this.loading = true;
      // this.getAssignPollDetail(this.selectedId);
    } else {
      this.isEditMode = false;
    }
    this.locationSettings = {
      singleSelection: true,
      text: "Select "+ Constants.locationLabel,
      enableSearchFilter: true,
      classes: "singleSelection"
    };
    this.teamSettings = {
      singleSelection: false,
      text: "Select Team",
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      enableSearchFilter: true
    }
  }
  pollDropdownList() {
    this.pollSurveyService.getDropdownPollList().subscribe(res => {
      this.pollList = res.data;
      this.isPollList = true;
      this.getLocationList();
      // this.getAssignPollInfo();
    }, err => {
      this.customErrorHandler.handleHttpError(err, 'surveList');
    });
  }
  teamListFunction(locationId) {
    this.sharedService.teamList(locationId).subscribe(
      res => {
        this.teamList = res.data;
        // this.isAssignTeamList = true;
        // this.getAssignPollInfo();
        let teamId = this.pollData.team_id;
				if (this.selectedId && teamId != undefined && this.teamList.length > 0) {
					this.teamSelectedItems = [];
					teamId = teamId.toString();
					let teamInfo = teamId.split(',');
					for (let i = 0; i < teamInfo.length; i++) {
						let teamTemp = this.teamList.find(s => s.id == teamInfo[i]);
						if (typeof teamTemp != 'undefined') {
							this.teamSelectedItems.push(teamTemp);
						}
					}
				}
      },
      error => {
        this.customErrorHandler.handleHttpError(error, 'teamList');
      });
  }

  getLocationList() {
    this.commonService.getLocationList().subscribe(res => {
      if (res.result == true) {
        this.locationList = res.data;
        if (this.selectedId) {
          this.getAssignPollDetail(this.selectedId);
        } 
      }
    }, error => {
      this.customErrorHandler.handleHttpError(error, 'getRoleList');
    });
  }
  onLocationItemSelect(item: any) {
    this.TeamMemberItems = [];
    this.locationId = item.id;
    this.employeeListFunction(this.locationId);
    this.teamListFunction(this.locationId);
  }
  onLocationItemDeSelect(item: any) {
    this.locationId = '';
  }

  employeeListFunction(locationId) {
    this.sharedService.employeeLocationList(locationId).subscribe(
      res => {
        this.employeeMemberList = res.data;
        this.isEmployeeList = true;
        // this.getAssignPollInfo();
        // let empId = localStorage.getItem('createProspectRouteEmpId') || localStorage.getItem('assigneeId');
        // if (empId) {
        //   let assignEmp = this.employeeMemberList.find(s => s.id == empId);
        //   if (typeof assignEmp != 'undefined' || assignEmp != '') {
        //     this.TeamMemberItems = [];
        //     this.TeamMemberItems.push(assignEmp);
        //   }
        // }
        let employeeId = this.pollData.employee;
				if (this.selectedId && employeeId != undefined && this.employeeMemberList.length > 0) {
					this.TeamMemberItems = [];
					for (let i = 0; i < employeeId.length; i++) {
						let empTemp = this.employeeMemberList.find(s => s.id == employeeId[i].id);
						if (typeof empTemp != 'undefined') {
							this.TeamMemberItems.push(empTemp);
						}
					}
				}
      },
      error => {
        this.customErrorHandler.handleHttpError(error, 'employeeList');
      });
  }
  getAssignPollInfo() {
    if (this.selectedId && this.isPollList == true && this.isEmployeeList == true) {
      this.getAssignPollDetail(this.selectedId);
    }
  }
  assignedPoll(data) {
    this.loading = true;
    data.assigned_by = this.userId//this.companyId;
    data.poll_id = this.pollSelectedItems.map(function (elem) { return elem.id; }).join(",");
    data.employee_id = this.TeamMemberItems.map(function (elem) { return elem.id; }).join(",");
    data.team_id = this.teamSelectedItems.map(function (elem) { return elem.id; }).join(",");
    data.location_id = this.locationSelectedItem.map(function (elem) { return elem.id; }).join(",");
    data.start_date = moment(data.start_date).format('YYYY-MM-DD');
    data.end_date = moment(data.end_date).format('YYYY-MM-DD');
    
    if (this.teamSelectedItems.length == 0 && this.TeamMemberItems.length == 0) {
      this.loading = false;
      this.endDateTimeMin = new Date();
      this.startDateTimeMin = new Date();
      this.errorMessage = "Team or " + this.employeeLabel + " Should not be blank";
      return false;
    }

    if (this.isEditMode) {
      this.updateAssignPoll(data);
    } else {
      this.errorMessage = '';
      if (data.start_date == '' || data.start_date == null) {
        this.loading = false;
        this.startDateErrorMsg = 'Start date is required';
        return false;
      }
      if (data.end_date == '' || data.end_date == null) {
        this.loading = false;
        this.endDateErrorMsg = 'End date is required';
        return false;
      }
      this.pollSurveyService.assignPoll(data).subscribe(res => {
        if (res.result == true) {
          this.loading = false;
          this.commonService.showSuccessMessage(res.message);
          this.router.navigate(["poll/assigned-poll-list"]);
        } else {
          this.loading = false;
          this.commonService.showErrorMessage(res);
        }
      }, err => {
        this.loading = false;
        this.customErrorHandler.handleHttpError(err, 'assignPoll');
      });
    }
  }
  changedTime(time) {
    if (this.endTime < this.pollData.start_date && moment(this.pollData.start_date).format("DD-MM-YYYY") !== moment().format("DD-MM-YYYY")) {
      this.endTime = new Date(this.pollData.start_date.getTime() + 1 * 60000)
    } else {
      this.endTime = new Date(this.pollData.end_date);
      this.endTime = time;
    }
  };
  changedStartTime(time) {
    this.endDateTimeMin = this.pollData.start_date;
    if (this.pollData.start_date.getTime() > time.getTime()) {
      this.startTime = new Date(this.pollData.start_date);
    } else if (moment(this.pollData.start_date).format("DD-MM-YYYY") == moment(this.pollData.end_date).format("DD-MM-YYYY")) {
      this.endTime = new Date(time.getTime() + 1 * 60000)
    }
    if (time > this.pollData.end_date) {
      this.pollData.end_date = time;
    }
  };
  getAssignPollDetail(id) {
    this.pollSurveyService.getSingleAssignPoll(id).subscribe(res => {
      if (res.result == true) {
        this.loading = false;
        this.pollData = res.data;
        this.pollData.start_date = new Date(this.pollData.start_date);
        this.pollData.end_date = new Date(this.pollData.end_date);
        let pollTemp = this.pollList.find(s => s.id == this.pollData.poll_id);
        if (typeof pollTemp != 'undefined') {
          this.pollSelectedItems = [];
          this.pollSelectedItems.push(pollTemp);
        }
        
        let lactionTemp = this.locationList.find(s => s.id == this.pollData.location_id);
        if (typeof lactionTemp != 'undefined') {
          this.locationSelectedItem = [];
          this.locationSelectedItem.push(lactionTemp);
        }
        this.employeeListFunction(this.pollData.location_id);
        this.teamListFunction(this.pollData.location_id);

      }
    }, err => {
      this.customErrorHandler.handleHttpError(err, 'getAssignPollDetail');
    });
  }
  updateAssignPoll(data) {
    data.assign_poll_id = this.pollData.id;
    this.pollSurveyService.updateAssignPoll(data).subscribe(res => {
      if (res.result == true) {
        this.loading = false;
        this.commonService.showSuccessMessage(res.message);
        this.router.navigate(["poll/assigned-poll-list"]);
      } else {
        this.loading = false;
        this.commonService.showErrorMessage(res);
      }
    }, err => {
      this.loading = false;
      this.customErrorHandler.handleHttpError(err, 'updateAssignPoll');
    });
  }
  onOpen(event: any) {
    this.teamList = this.teamList.map((item) => {
      return {
        id: item.id,
        itemName: item.itemName
      }
    });
  }
}
