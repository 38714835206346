import { Injectable } from '@angular/core';
import { HttpHelper } from '../../helpers/http.helper';
import { Config } from '../../config/config';
const company_id = localStorage.getItem('company_id');
const user_id = localStorage.getItem('user_id');
@Injectable()
export class NearbyService {
  constructor(private _httpHelper: HttpHelper) {
  }
  areaList() {
    try {
      var request = {
        "company_id": company_id
      };
      return this._httpHelper.post(Config.AuthenticationBaseURL + 'areaList', request)
    }
    catch (e) {
    }
  }
  areaDelete(area_id: string) {
    try {
      var request = {
        "area_id": area_id
      };
      return this._httpHelper.post(Config.AuthenticationBaseURL + 'deleteArea', request)
    }
    catch (e) {
    }
  }
  createNearby(radius: string, lat: string, lng: string, area_id: string, prospect_id: string) {
    try {
      var request = {
        "radius": radius,
        "lat": lat,
        "lng": lng,
        "area_id": area_id,
        "prospect_id": prospect_id,
        "company_id": company_id,
        "created_by": user_id

      };
      return this._httpHelper.post(Config.AuthenticationBaseURL1 + 'get-near-by-user', request);
    }
    catch (e) {
    }
  }
  sendMessage(body: any) {
    try {
      return this._httpHelper.post(Config.AuthenticationBaseURL1 + 'sendSMS', body);
    }
    catch (e) {
    }
  }
  prospectList() {
    try {
      var request = {
        "company_id": company_id
      };
      return this._httpHelper.post(Config.AuthenticationBaseURL1 + 'prospect-list-only', request)
    }
    catch (e) {
    }
  }
}
