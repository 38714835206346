import { Injectable } from '@angular/core';
import { HttpHelper } from '../../helpers/http.helper';
import { Config } from '../../config/config';
@Injectable()
export class VoterService {
    constructor(
        private _httpHelper: HttpHelper
    ) { }
    getVoterList(queryStrings) {
        try {
            return this._httpHelper.get(Config.AuthenticationBaseURL1 + 'get-voter?page=' + queryStrings.pageNo + '&per_page=' + queryStrings.perPage + '&search=' + queryStrings.search + '&export=' + queryStrings.export + '&status=' + queryStrings.status + '&state=' + queryStrings.stateId + '&constituency=' + queryStrings.constituencyId + '&booth=' + queryStrings.boothId);
        }
        catch (e) {
        }
    }
    addVoter(request) {
        try {
            return this._httpHelper.post(Config.AuthenticationBaseURL1 + 'add-voter', request)
        }
        catch (e) {
        }
    }
    getSingleVoter(voterId) {
        try {
            return this._httpHelper.get(Config.AuthenticationBaseURL1 + 'get-voter-by-id?id=' + voterId);
        }
        catch (e) {
        }
    }
    updateVoter(request) {
        try {
            return this._httpHelper.post(Config.AuthenticationBaseURL1 + 'update-voter', request)
        }
        catch (e) {
        }
    }
    getStateList() {
        try {
            return this._httpHelper.get(Config.AuthenticationBaseURL1 + 'get-state')
        }
        catch (e) {
        }
    }
    getConstituencyList(state) {
        try {
            return this._httpHelper.get(Config.AuthenticationBaseURL1 + 'get-constituency?state=' + state);
        }
        catch (e) {
        }
    }
    deleteVoterDetail(request) {
        try {
            return this._httpHelper.delete(Config.AuthenticationBaseURL1 + 'delete-voter', request);
        }
        catch (e) {
        }
    }
    getAssignVoterList(req) {
        try {
            return this._httpHelper.get(Config.AuthenticationBaseURL1 + 'get-assign-voter', req);
        }
        catch (e) {
        }
    }
    deleteAssignVoterDetail(request) {
        try {
            return this._httpHelper.delete(Config.AuthenticationBaseURL1 + 'delete-assign-voter', request);
        }
        catch (e) {
        }
    }
    updateVoterAssignment(request) {
        try {
            return this._httpHelper.post(Config.AuthenticationBaseURL1 + 'update-assign-voter', request)
        }
        catch (e) {
        }
    }
    assignmentVoter(request) {
        try {
            return this._httpHelper.post(Config.AuthenticationBaseURL1 + 'assign-voter', request)
        }
        catch (e) {
        }
    }
    getBoothList(constituency) {
        try {
            return this._httpHelper.get(Config.AuthenticationBaseURL1 + 'get-booth?constituency=' + constituency);
        }
        catch (e) {
        }
    }
    getAssignDetails(assignId) {
        try {
            return this._httpHelper.get(Config.AuthenticationBaseURL1 + 'assign-voter-by-id?id=' + assignId);
        }
        catch (e) {
        }
    }
    getVoterFeedbackList(queryStrings) {
        try {
            return this._httpHelper.get(Config.AuthenticationBaseURL1 + 'get-voter-media-feedback?page=' + queryStrings.pageNo + '&per_page=' + queryStrings.perPage + '&search=' + queryStrings.search + '&startDate=' + queryStrings.startDate + '&endDate=' + queryStrings.endDate + '&export=' + queryStrings.export);
        }
        catch (e) {
        }
    }
    createGeofence(request) {
        try {
            return this._httpHelper.post(Config.AuthenticationBaseURL1 + 'add-voter-geofence', request)
        }
        catch (e) {
        }
    }
    getAssignGeogenceDetails(assignId) {
        try {
            return this._httpHelper.get(Config.AuthenticationBaseURL1 + 'get-voter-geofence?assign_voter_id=' + assignId);
        }
        catch (e) {
        }
    }
    getVoterGeofenceList() {
        try {
            return this._httpHelper.get(Config.AuthenticationBaseURL1 + 'get-voter-geofence-list');
        }
        catch (e) {
        }
    }
    deleteAllVoterDetail(request) {
        try {
            return this._httpHelper.delete(Config.AuthenticationBaseURL1 + 'delete-all-voter', request);
        }
        catch (e) {
        }
    }
    getElectionDayList(req) {
        try {
            return this._httpHelper.get(Config.AuthenticationBaseURL1 + 'get-voter-day', req);
        }
        catch (e) {
        }
    }
    deleteElectionDayDetail(request) {
        try {
            return this._httpHelper.delete(Config.AuthenticationBaseURL1 + 'delete-voting-day', request);
        }
        catch (e) {
        }
    }
    updateElectionDayInfo(request) {
        try {
            return this._httpHelper.post(Config.AuthenticationBaseURL1 + 'update-voting-day', request)
        }
        catch (e) {
        }
    }
    addElectionDayInfo(request) {
        try {
            return this._httpHelper.post(Config.AuthenticationBaseURL1 + 'assign-voting-day', request)
        }
        catch (e) {
        }
    }
    getSingleElectionDay(electionDayId) {
        try {
            return this._httpHelper.get(Config.AuthenticationBaseURL1 + 'get-single-voter-day?voting_day_id=' + electionDayId);
        }
        catch (e) {
        }
    }

    getEvmAssignmentList(request) {
        try {
            return this._httpHelper.get(Config.AuthenticationBaseURL1 + 'get-booth-assignment', request);
        }
        catch (e) {
        }
    }
    getEvmDetailsList(request) {
        try {
            return this._httpHelper.get(Config.AuthenticationBaseURL1 + 'get-evm-details', request);
        }
        catch (e) {
        }
    }
    deleteEvmAssignmentDetail(request) {
        try {
            return this._httpHelper.delete(Config.AuthenticationBaseURL1 + 'delete-booth-assignment', request);
        }
        catch (e) {
        }
    }
    updateEvmAssignment(request) {
        try {
            return this._httpHelper.post(Config.AuthenticationBaseURL1 + 'update-booth-assignment', request)
        }
        catch (e) {
        }
    }
    assignmentEvm(request) {
        try {
            return this._httpHelper.post(Config.AuthenticationBaseURL1 + 'add-booth-assignment', request)
        }
        catch (e) {
        }
    }
    getSingleEvmAssignment(request) {
        try {
            return this._httpHelper.get(Config.AuthenticationBaseURL1 + 'get-single-booth-assignment', request);
        }
        catch (e) {
        }
    }
}
