import { Injectable } from '@angular/core';
import { HttpHelper } from '../../helpers/http.helper';
import { Config } from '../../config/config';
const company_id = localStorage.getItem('company_id');
const user_id = localStorage.getItem('user_id');
@Injectable()
export class RoleService {
  constructor(private _httpHelper: HttpHelper) {
  }
  roleTypeList(pageNo, rowPerPage) {
    try {
      return this._httpHelper.get(Config.AuthenticationBaseURL1 + 'get-role-list?page=' + pageNo + '&per_page=' + rowPerPage);
    }
    catch (e) {
    }
  }
  SelectByRoleById(id) {
    try {
      var request = {
        "role_type_id": id,
        "company_id": company_id
      };
      return this._httpHelper.post(Config.AuthenticationBaseURL + 'SelectByRoleById', request)
    }
    catch (e) {
    }
  }
  roleTypeDelete(role_id: string) {
    try {
      var request = {
        "role_type_id": role_id,
        "company_id": company_id
      };
      return this._httpHelper.post(Config.AuthenticationBaseURL + 'deleteroleTypeList', request)
    }
    catch (e) {
    }
  }
  createRoleType(name: string) {
    try {
      var request = {
        "name": name,
        "company_id": company_id,
        "created_by": user_id
      };
      return this._httpHelper.post(Config.AuthenticationBaseURL + 'createRoleType', request)
    }
    catch (e) {
    }
  }
  editRoleType(name: string, role_type_id: string) {
    try {
      var request = {
        "name": name,
        "company_id": company_id,
        "role_type_id": role_type_id,
        "created_by": user_id
      };
      return this._httpHelper.post(Config.AuthenticationBaseURL + 'updateRoleType', request)
    }
    catch (e) {
    }
  }
}
