import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { VoterService } from '../../../services/voter/voter.service';
import { CommonService } from '../../../services/common/common.service';
import { CustomErrorHandler } from '../../../errorhandler/custom-errorhandler';
import { Config } from '../../../config/config';
import { Angular2Csv } from 'angular2-csv/Angular2-csv';
import { Constants } from '../../../common/constants';
declare var moment: any;
declare var $: any;
@Component({
  selector: 'app-voter-feedback',
  templateUrl: './voter-feedback.component.html',
  styleUrls: ['./voter-feedback.component.css']
})
export class VoterFeedbackComponent implements OnInit {
  voterFeedbackList: any = [];
  perPage: number = 10;
  pageNo: number = 1;
  searchTextValue: any = '';
  loading: boolean;
  totalItem: number = 0;
  page: number = 2;
  searchDetails: any = {};
  exportReport: any;
  imageBaseUrl: string = Config.AuthenticationURL;
  mediaType: any;
  modal: any;
  previewImage: any;
  noDataMsg: any;
  searchTempVal: any;
  startDate: any;
  endDate: any;
  tempSearch: any;
  maxPage: number = 10;
  feedbackData: any = [];
  isDemoTenant: boolean;
  filterLabel = Constants.filterLabel;
  @ViewChild('videoPlayer') videoPlayer: ElementRef;
  @ViewChild('audioPlayer') audioPlayer: ElementRef;
  constructor(
    public voterService: VoterService,
    public commonService: CommonService,
    private customErrorHandler: CustomErrorHandler
  ) { }
  ngOnInit() {
    this.isDemoTenant = (localStorage.getItem('isDemoTenant') == 'true');
    if ($(window).width() < 768) {
      this.maxPage = 6;
    }
    this.searchTempVal = '';
    this.searchDetails.search_by = '';
    this.modal = document.getElementById('previewModel');
    this.getFeedbackList(1);
    this.tempSearch = '';
    var _this = this;
    var start = moment().subtract(6, 'days');
    var end = moment();
    function cb(start, end) {
      $('#reportrange span').html(start.format('MMM D, YYYY') + ' - ' + end.format('MMM D, YYYY'));
      _this.startDate = start.format('YYYY-MM-DD');
      _this.endDate = end.format('YYYY-MM-DD');
      _this.voterFeedbackList = [];
      _this.getFeedbackList(1);
    }
    $('#reportrange').daterangepicker({
      startDate: start,
      endDate: end,
      maxDate: new Date(),
      ranges: {
        'Today': [moment(), moment()],
        'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
        'Last 7 Days': [moment().subtract(6, 'days'), moment()],
        'Last 30 Days': [moment().subtract(29, 'days'), moment()],
        'This Month': [moment().startOf('month'), moment().endOf('month')],
        'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
      }
    }, cb);
    cb(start, end);
  }
  getFeedbackList(currentPage) {
    this.pageNo = currentPage;
    this.page = currentPage;
    let quoteStrings: any = {
      pageNo: currentPage,
      perPage: this.perPage,
      search: this.searchTextValue,
      startDate: this.startDate,
      endDate: this.endDate,
      export: ''
    }
    this.voterFeedbackList = [];
    this.loading = true;
    this.voterService.getVoterFeedbackList(quoteStrings).subscribe(
      response => {
        this.loading = false;
        let res: any = response;
        this.noDataMsg = res.message;
        if (res.result == true) {
          this.totalItem = res.data.total;
          this.voterFeedbackList = res.data.data;
        } else {
          this.voterFeedbackList = [];
          this.commonService.showErrorMessage(res);
        }
      },
      error => {
        this.loading = false;
        this.customErrorHandler.handleHttpError(error, 'getCheckList');
      });
  }
  onkeyup_value(event) {
    this.searchTextValue = event.target.value;
    if (this.tempSearch != this.searchTextValue) {
      this.getFeedbackList(1);
    }
    this.tempSearch = this.searchTextValue;
  }
  openModal(data) {
    this.feedbackData = data;
    console.log(this.feedbackData);
    console.log(this.feedbackData);
    this.modal.style.display = 'block';
  }
  closeModal() {
    this.feedbackData = [];
    this.previewImage = '';
    this.modal.style.display = 'none';
    this.videoPlayer.nativeElement.pause();
  }
  export() {
    var options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: true,
      useBom: true,
      title: "Voter Feedback List"
    };
    let quoteStrings: any = {
      pageNo: 0,
      perPage: 0,
      search: this.searchTextValue,
      startDate: this.startDate,
      endDate: this.endDate,
      export: 'yes'
    }
    this.voterService.getVoterFeedbackList(quoteStrings).subscribe(
      response => {
        let res: any = response;
        if (res.result == true) {
          new Angular2Csv(res.data, 'getFeedbacklist', options);
        }
      },
      error => {
        this.customErrorHandler.handleHttpError(error, 'getCheckList');
      });
  }
}
