import { MeetingModule } from './meeting/meeting.module';
import { DetailMapModule } from './detail-map/detail-map.module';
import { ResetPasswordModule } from './reset-password/reset-password.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EventModule } from './event/event.module';
import { ReportsModule } from './reports/reports.module';
import { SettingModule } from './setting/setting.module';
import { LocationModule } from './setting/locations/location.module';
import { LoginModule } from './login/login.module';
import { AppDownloadModule } from './app-download/app-download.module';
import { NearbyModule } from './nearby/nearby.module';
import { MessageModule } from './message/message.module';
import { DashboardModule } from './dashboard/dashboard.module';
import { VisitorModule } from './visitor/visitor.module';
import { RouteModule } from './route/route.module';
import { CheckListModule } from './check-list/check-list.module';
import { CompanySettingsModule } from './company-settings/company-settings.module';
import { ExpenseModule } from './expense/expense.module';
import { EmployeeLeaderBoardModule } from './employee-leader-board/employee-leader-board.module';
import { DocumentLibraryModule } from './document-library/document-library.module';
import { VoterManagementModule } from './voter-management/voter-management.module';
import { PollModule } from './poll-management/poll-management.module';
import { SurveyModule } from './survey-management/survey-management.module';
import { AnnouncementsModule } from './announcements/announcements.module';
import { EmployeeModule } from './employee/employee.module';
import { AllAnnouncementsModule } from './all-announcements/all-announcements.module';


@NgModule({
  imports: [
    CommonModule,
    EmployeeModule,
    MeetingModule,
    EventModule,
    ReportsModule,
    SettingModule,
    LoginModule,
    NearbyModule,
    MessageModule,
    DashboardModule,
    ResetPasswordModule,
    DetailMapModule,
    VisitorModule,
    RouteModule,
    CheckListModule,
    CompanySettingsModule,
    ExpenseModule,
    EmployeeLeaderBoardModule,
    DocumentLibraryModule,
    VoterManagementModule,
    SurveyModule,
    PollModule,
    LocationModule,
    AnnouncementsModule,
    AllAnnouncementsModule,
    AppDownloadModule,
  ]
})
export class Modules { }
