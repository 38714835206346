import { Injectable } from "@angular/core";
import { HttpHelper } from "../../helpers/http.helper";
import { Config } from "../../config/config";
@Injectable()
export class DashboardService {
    constructor(private _httpHelper: HttpHelper) {
    }
    public getDashboardData() {
        try {
            return this._httpHelper.get(Config.AuthenticationBaseURL1 + 'get-dashboard-data')
        }
        catch (e) {
        }
    }
    getTodaysDueMeetings() {
        try {
            return this._httpHelper.get(Config.AuthenticationBaseURL1 + 'get-today-due-meeting')
        }
        catch (e) {
        }
    }
    getVoterDashboardDetails() {
        try {
            return this._httpHelper.get(Config.AuthenticationBaseURL1 + 'get-voter-dashboard-details')
        }
        catch (e) {
        }
    }
    getDashboardDetails() {
        try {
            return this._httpHelper.get(Config.AuthenticationBaseURL1 + 'get-dashboard-summary')
        }
        catch (e) {
        }
    }
}
