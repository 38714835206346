import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { NotesService } from '../../../services/notes/notes.service';
import { NoteListModel } from '../../../models/notelist-model';
import { EmployeeService } from '../../../services/employee/employee.service';
import { CustomErrorHandler } from '../../../errorhandler/custom-errorhandler';
import { CommonService } from '../../../services/common/common.service';
import { Config } from '../../../config/config';
import { Constants } from '../../../common/constants';
@Component({
  selector: 'notes',
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.css']
})
export class NotesComponent implements OnInit {
  errormsg: string;
  msg: boolean;
  prospectList = [];
  dropdownSettings = {};
  selectionList = [];
  prospectLabel: string;
  employeeLabel: string;
  selectedItems = [];
  EditselectedItems = [];
  prospect_id: Number;
  noteList:any = [];
  notelistmodel: Array<NoteListModel> = [];
  employeeNameList: Array<string> = [];
  employeeIdList: Array<number> = [];
  modal;
  previewImage;
  prospectName;
  noteListNoDataCount = false;
  serchText;
  imageBaseUrl: string = Config.AuthenticationURL;
  mediaType: string;
  isAudioFound: boolean;
  @ViewChild('player') player: ElementRef;
  reportingUserList: any = [];
  reportingSelectedItem: any = [];
  reportingSettings: any = {};
  locationList: any = [];
  locationSettings: any = {};
  locationSelectedItem: any = [];
  reportingTo: any = '';
  locationId: any = '';
  defaultSelectObject: any = { id: '', itemName: 'All' }
  noDataErrorMsg: any = 'Currently, there is no data available.';
  filterLabel = Constants.filterLabel;
  page: number = 2;
  pageNo: number = 1;
  perPage: number = 10;
  totalItem: number = 0;
  tempSearch;

  constructor(
    private _notesService: NotesService,
    private _employeservice: EmployeeService,
    private customErrorHandler: CustomErrorHandler,
    public commonService: CommonService
  ) { }
  ngOnInit() {
    this.errormsg = '';
    this.msg = true;
    this.employeeLabel = localStorage.getItem('employeeLabel');
    this.modal = document.getElementById('previewModel');
    this.getprospectList();
    this.prospectLabel = localStorage.getItem('prospectLabel');
    this.dropdownSettings = {
      singleSelection: true,
      text: 'Select ' + this.prospectLabel,
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      enableSearchFilter: true,
      classes: 'singleSelection myclass custom-class'
    };

    this.getLocationList();
    this.getReportingUserList(this.locationId);
    
    this.reportingSettings = {
      singleSelection: true,
      text: "Select Reporting Person",
      enableSearchFilter: true,
      classes: "singleSelection block_elem m_b_15"
    };
    this.locationSettings = {
      singleSelection: true,
      text: "Select "+ Constants.locationLabel,
      enableSearchFilter: true,
      classes: "singleSelection block_elem m_b_15"
    }
  }
  getprospectList() {
    let parameter = {
      list_type: 'notes',
      location_id: this.locationId,
      reporting_to: this.reportingTo
    }
    this.commonService.getProspectList(parameter).subscribe(
      res => {
        //   this.errormsg= res.message || 'Currently, no data is available';
        if (res.result === true) {
          this.prospectList = res.data;
          if(this.prospectList.length > 0){
            this.selectionList = [];
            this.selectionList.push(this.prospectList[0]);
            this.prospect_id = this.prospectList[0].id;
            this.prospectName = this.prospectList[0].itemName;
            this.getNotesListForProspect(1);
          }else{
            this.msg = true;
            this.errormsg = Constants.noDataFoundMessage;
            this.noteListNoDataCount = true;
            this.noteList = [];
            this.totalItem = 0;
          }
        } else {
          this.prospectList = [];
          this.commonService.showErrorMessage(res);
        }
      },
      error => {
        this.customErrorHandler.handleHttpError(error, 'prospectCompletedList');
      });
  }
  onProspectSelect() {
    const tem = this.prospectList.find(s => s.name === this.prospectName);
    this.prospect_id = tem.id;
    this.getNotesListForProspect(1);
  }
  getNotesListForProspect(currentPage) {
    this.page = currentPage;
    let queryData = {
      'page' : currentPage,
      'per_page': this.perPage,
      'prospect_id': this.prospect_id || '',
      'search_text': this.serchText
    }

    this._notesService.getNotesListByProspectId(queryData).subscribe(
      res => {
        this.msg = false;
        if (res.result === true) {
          this.noteList = res.data.data;
          this.totalItem = res.data.total;
          if (res.data.data.length > 0) {
            this.noteListNoDataCount = false;
          } else {
            this.msg = true;
            this.errormsg = res.message;
            this.noteListNoDataCount = true;
          }
        } else {
          this.noteList = [];
          this.commonService.showErrorMessage(res);
        }
      },
      error => {
        this.customErrorHandler.handleHttpError(error, 'getNotesListByProspectId');
      });
  }
  onkeyup_value(event) {
    this.serchText = event.target.value;
    if (this.tempSearch != this.serchText) {
      this.getNotesListForProspect(1);
    }
    this.tempSearch = this.serchText;
  }
  generateNoteList(noteList: any) {
    for (let i = 0; i < noteList.length; i++) {
      this.employeeIdList[i] = noteList[i].employee_id;
      this.notelistmodel[i].NoteMessage = noteList[i].note_text;
      this.notelistmodel[i].AttachmentUrl = noteList[i].attachment_url;
      this.notelistmodel[i].AttachmentType = noteList[i].media_type;
      this.notelistmodel[i].Created_Date = noteList[i].created_at;
    }
    this.getEmployeeNameById();
  }

  getEmployeeNameById() {
    const employeeIdList = this.employeeIdList.join(',');
    this._employeservice.employeeNameByIdList(employeeIdList).subscribe(
      res => {
        if (res.result === true) {
          this.employeeNameList = res.data;
          this.nameListToModel();
        }
      },
      error => {
        this.customErrorHandler.handleHttpError(error, 'employeeNameByIdList');
      });
  }
  nameListToModel() {
    for (let i = 0; i < this.employeeNameList.length; i++) {
      this.nameListToModel[i].EmployeName = this.employeeNameList[i];
    }
  }
  openModal(imageUrl, mediaType) {
    this.isAudioFound = false;
    if (mediaType == '2') {
      this.player.nativeElement.load();
      setTimeout(() => {
        if (isNaN(this.player.nativeElement.duration)) {
          this.isAudioFound = true;
        } else {
          this.isAudioFound = false;
        }
        this.player.nativeElement.currentTime = 0
      }, 500);
    }
    this.previewImage = this.imageBaseUrl + imageUrl;
    this.modal.style.display = 'block';
    this.mediaType = mediaType;
  }
  closeModal() {
    this.modal.style.display = 'none';
    this.player.nativeElement.pause();
  }
  // onkeyup_value(event) {
  //   this.noteList[0].note_text;
  // }
  onOpen(event: any) {
    this.prospectList = this.prospectList.map((item) => {
      return {
        id: item.id,
        itemName: item.itemName
      }
    });
  }
  onItemSelect(item: any) {
    this.prospectName = item.itemName;
    this.prospect_id = item.id;
    this.getNotesListForProspect(1);
  }
  OnItemDeSelect(item: any) {
    this.prospectName = undefined;
    this.prospect_id = new Number;
    this.msg = true;
    this.errormsg = Constants.noDataFoundMessage;
    this.noteListNoDataCount = true;
    this.noteList = [];
    this.totalItem = 0;
    // this.getNotesListForProspect(1);
  }
  onSelectAll(items: any) {
  }
  onDeSelectAll(items: any) {
  }

  getReportingUserList(locationId) {
    let currentUserId = localStorage.getItem('user_id');
    this.commonService.getReportingUserList(locationId).subscribe(res => {
      if (res.result == true) {
        this.reportingUserList = res.data;
        if (this.reportingUserList.length > 1) {
          this.reportingUserList.unshift(this.defaultSelectObject);
        }
      }
    }, err => {
      this.customErrorHandler.handleHttpError(err, 'getRoleList');
    });
  }
  getLocationList() {
    this.commonService.getLocationList().subscribe(res => {
      if (res.result == true) {
        this.locationList = res.data;
        if (this.locationList.length > 1) {
          this.locationList.unshift(this.defaultSelectObject);
        }
      }
    }, err => {
      this.customErrorHandler.handleHttpError(err, 'getRoleList');
    });
  }

  onUserItemSelect(item: any) {
    this.reportingTo = item.id;
    this.getprospectList();
  }
  onUserItemDeSelect(item: any) {
    this.reportingTo = '';
    this.getprospectList();
  }
  onLocationItemSelect(item: any) {
    this.locationId = item.id;
    this.reportingTo = '';
    this.reportingUserList = [];
    this.reportingSelectedItem = [];
    this.selectionList = [];
    
    this.prospectName = undefined;
    this.prospect_id = new Number;
    // this.getNotesListForProspect(1);

    this.msg = true;
    this.errormsg = Constants.noDataFoundMessage;
    this.noteListNoDataCount = true;
    this.noteList = [];
    this.totalItem = 0;
    
    this.getReportingUserList(this.locationId);
    this.getprospectList();
  }
  onLocationItemDeSelect(item: any) {
    this.locationId = '';
    this.reportingTo = '';
    this.reportingUserList = [];
    this.reportingSelectedItem = [];
    this.selectionList = [];

    this.prospectName = undefined;
    this.prospect_id = new Number;
    // this.getNotesListForProspect(1);
    
    this.getprospectList();
  }
}
