import { Component, OnInit } from '@angular/core';
import { AssignService } from '../../../services/assign/assign.service';
import { ReportService } from '../../../services/report/report.service';
import { Angular2Csv } from 'angular2-csv/Angular2-csv';
import { CustomErrorHandler } from '../../../errorhandler/custom-errorhandler';
import { CommonService } from '../../../services/common/common.service';
import { Constants } from '../../../common/constants';
declare var moment: any;
declare var $: any;
@Component({
  selector: 'action-report',
  templateUrl: 'action-report.component.html',
  styleUrls: ['./action-report.component.css']
})
export class ActionReportComponent implements OnInit {
  isExport: boolean;
  isDemoTenant: boolean;
  employeeLabel: string;
  selected: any;
  employee: any[];
  leadGenerationList: any = [];
  form_template_id: any;
  report_type: any;
  errorMessage: string;
  disableButton: boolean;
  signin_btn_txt: string;
  start_date: string;
  end_date: string;
  errormsg: string;
  msg: boolean;
  loading: boolean;
  dropdownSettings = {};
  selectionList = [];
  noDataMsg: string;
  page: number = 2;
  pageNo: number = 1;
  perPage: number = 10;
  totalItem: number = 0;
  exportReport: any;
  search: any;
  meetingLabel: any;
  reportingUserList: any = [];
  reportingSelectedItem: any = [];
  reportingSettings: any = {};
  locationList: any = [];
  locationSettings: any = {};
  locationSelectedItem: any = [];
  reportingTo: any = '';
  locationId: any = '';
  defaultSelectObject: any = { id: '', itemName: 'All' };
  filterLabel = Constants.filterLabel;
  constructor(private commonService: CommonService, public _assignService: AssignService, public _reportService: ReportService, private customErrorHandler: CustomErrorHandler) {
  }
  ngOnInit() {
    this.search = '';
    this.noDataMsg = 'Currently, there is no data available.'
    this.isExport = false;
    this.isDemoTenant = (localStorage.getItem('isDemoTenant') == 'true');
    this.employeeLabel = localStorage.getItem('employeeLabel');
    this.meetingLabel = localStorage.getItem('prospectLabel');
    this.msg = true;
    this.form_template_id = ''
    this.dropdownSettings = {
      singleSelection: true,
      text: 'Select Digital Form Category',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      enableSearchFilter: true,
      classes: 'myclass custom-class block_elem m_b_5'
    };
    this.formcategoryList();
    var start = moment().subtract(6, 'days');
    var end = moment();
    var _this;
    _this = this;
    function cb(start, end) {
      $('#reportrange span').html(start.format('MMM D, YYYY') + ' - ' + end.format('MMM D, YYYY'));
      var f_start = start.format('YYYY-MM-DD');
      var f_end = end.format('YYYY-MM-DD');
      _this.start_date = f_start;
      _this.end_date = f_end;
      _this.getLeadGeneration(1);
    }
    $('#reportrange').daterangepicker({
      startDate: start,
      endDate: end,
      ranges: {
        'Today': [moment(), moment(),],
        'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
        'Last 7 Days': [moment().subtract(6, 'days'), moment()],
        'Last 30 Days': [moment().subtract(29, 'days'), moment()],
        'This Month': [moment().startOf('month'), moment().endOf('month'), 'inclusive'],
        'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
      }
    }, cb);
    cb(start, end);
    this.getLeadGeneration(1);
    this.getLocationList();
    this.getReportingUserList(this.locationId);
    this.reportingSettings = {
      singleSelection: true,
      text: "Select Reporting Person",
      enableSearchFilter: true,
      classes: "singleSelection block_elem m_b_15"
    };
    this.locationSettings = {
      singleSelection: true,
      text: "Select "+ Constants.locationLabel,
      enableSearchFilter: true,
      classes: "singleSelection block_elem m_b_15"
    };
  }
  formcategoryList() {
    this._assignService.templateList().subscribe(
      res => {
        this.employee = res.data;
      },
      error => {
        this.customErrorHandler.handleHttpError(error, 'templateList');
      });
  }
  onOpen(event: any) {
    this.employee = this.employee.map((item) => {
      return {
        id: item.id,
        itemName: item.itemName
      };
    });
  }
  onItemSelect(item: any) {
    this.form_template_id = item.id;
  }
  OnItemDeSelect(item: any) {
  }
  onSelectAll(items: any) {
  }
  onDeSelectAll(items: any) {
  }
  getLeadGeneration(currentPage) {
    this.pageNo = currentPage;
    this.page = currentPage;
    this.noDataMsg = '';
    this.exportReport = '';
    this.loading = true;
    this.disableButton = true;
    this.errorMessage = '';
    this.signin_btn_txt = "Creating";
    this._reportService.formCategoryReport(this.pageNo, this.perPage, this.search, this.start_date, this.end_date, this.exportReport, this.locationId, this.reportingTo).subscribe(
      res => {
        this.loading = false;
        if (res.result == true) {
          this.totalItem = res.data.total;
          this.leadGenerationList = res.data.data;
          this.noDataMsg = res.message;
        } else {
          this.leadGenerationList = [];
          this.noDataMsg = res.message;
          this.commonService.showErrorMessage(res);
        }
      },
      error => {
        this.customErrorHandler.handleHttpError(error, 'formCategoryReport');
        this.disableButton = false;
        this.signin_btn_txt = "Submit";
      });
  }
  export() {
    var options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: true,
      useBom: true,
      title: "Lead Generation Report"
    };
    this.exportReport = 'yes';
    this._reportService.formCategoryReport(0, 0, this.form_template_id, this.start_date, this.end_date, this.exportReport, this.locationId, this.reportingTo).subscribe(
      res => {
        new Angular2Csv(res.data, 'Lead_Generation', options);
      },
      error => {
        this.customErrorHandler.handleHttpError(error, 'formCategoryReport');
        this.disableButton = false;
        this.signin_btn_txt = "Submit";
      });
  }
  getReportingUserList(locationId) {
    let currentUserId = localStorage.getItem('user_id');
    this.commonService.getReportingUserList(locationId).subscribe(res => {
      if (res.result == true) {
        this.reportingUserList = res.data;
        if (this.reportingUserList.length > 1) {
          this.reportingUserList.unshift(this.defaultSelectObject);
        }
      }
    }, err => {
      this.customErrorHandler.handleHttpError(err, 'getRoleList');
    })
  }
  getLocationList() {
    this.commonService.getLocationList().subscribe(res => {
      if (res.result == true) {
        this.locationList = res.data;
        if (this.locationList.length > 1) {
          this.locationList.unshift(this.defaultSelectObject);
        }
      }
    }, error => {
      this.customErrorHandler.handleHttpError(error, 'getRoleList');
    });
  }
  onUserItemSelect(item: any) {
    this.reportingTo = item.id;
    this.getLeadGeneration(1);
  }
  onUserItemDeSelect(item: any) {
    this.reportingTo = '';
    this.getLeadGeneration(1);
  }
  onLocationItemSelect(item: any) {
    this.locationId = item.id;
    this.reportingTo = '';
    this.reportingUserList = [];
    this.reportingSelectedItem = [];
    // if (this.locationId != '') {
      this.getReportingUserList(this.locationId);
    // }
    this.getLeadGeneration(1);
  }
  onLocationItemDeSelect(item: any) {
    this.locationId = '';
    this.reportingTo = '';
    this.reportingUserList = [];
    this.reportingSelectedItem = [];
    this.getLeadGeneration(1);
  }
}
