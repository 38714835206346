import { Injectable } from "@angular/core";
import { HttpHelper } from "../../helpers/http.helper";
import { Config } from "../../config/config";
const company_id = localStorage.getItem('company_id');
@Injectable()
export class MessageService {
    constructor(private _httpHelper: HttpHelper) {
    }
    public getMessageEmployee(employee_id) {
        try {
            var request = {
                "employee_id": employee_id
            };
            return this._httpHelper.post(Config.AuthenticationBaseURL1 + 'getMessageEmployee', request);
        }
        catch (e) {
        }
    }
    public getMessageSuperAdmin(employee_id) {
        try {
            var request = {
                "employee_id": employee_id
            };
            return this._httpHelper.post(Config.AuthenticationBaseURL1 + 'getMessageSuperAdmin', request);
        }
        catch (e) {
        }
    }
    getMessageList(sender_id, receiver_id, user_type) {
        try {
            var request = {
                "sender_id": sender_id,
                "receiver_id": receiver_id,
                "type": user_type
            };
            let requestParam = "?sender_id=" + sender_id + '&receiver_id=' + receiver_id + '&type=' + user_type;
            return this._httpHelper.get(Config.AuthenticationBaseURL1 + 'getConversation' + requestParam);
        }
        catch (e) {
        }
    }
    public getMessageListEmployee(sender_id) {
        try {
            var request = {
                "sender_id": sender_id, "company_id": company_id
            };
            return this._httpHelper.post(Config.AuthenticationBaseURL + 'get_communication_message_web', request)
        }
        catch (e) {
        }
    }
    public sendMessageEmployee(sender_id: any, sender_role: string, message: string, reciever_id: string) {
        try {
            var request = {
                "company_id": company_id,
                "sender_id": sender_id,
                "sender_role": sender_role,
                "message": message,
                "reciever_id": reciever_id
            };
            return this._httpHelper.post(Config.AuthenticationBaseURL1 + 'send-message', request)
        }
        catch (e) {
        }
    }
    public getMessageListSuperAdmin(sender_id) {
        try {
            var request = {
                "company_id": company_id,
                "sender_id": sender_id
            };
            return this._httpHelper.post(Config.AuthenticationBaseURL + 'getcommunicationmessagetosuperadminweb', request)
        }
        catch (e) {
        }
    }
    public sendMessageSuperAdmin(sender_id: any, reciever_id: any, message: string) {
        try {
            var request = {
                "company_id": company_id,
                "sender_id": sender_id,
                "reciever_id": reciever_id,
                "message": message,
                "sender_role": 'admin'
            };
            return this._httpHelper.post(Config.AuthenticationBaseURL1 + 'send-message-to-superadmin', request)
        }
        catch (e) {
        }
    }
}
