import { MeetingService } from './../../../services/meeting/meeting.service';
import { EventlistService } from './../../../services/event/eventList.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Angular2Csv } from 'angular2-csv/Angular2-csv';
import { Constants } from '../../../common/constants';
import { CustomErrorHandler } from '../../../errorhandler/custom-errorhandler';
import swal from 'sweetalert2';
import { CommonService } from '../../../services/common/common.service';
@Component({
  selector: 'event-list',
  templateUrl: 'event-list.component.html',
  styleUrls: ['./event-list.component.css']
})
export class EventListComponent implements OnInit {
  isDemoTenant: boolean;
  errormsg: string;
  msg: boolean;
  geofencingLabel: string;
  actionLable: string;
  modal: any;
  Editmodal: any;
  company_id: string;
  eventList: any[];
  geoList: any[];
  errorMessage: string;
  disableButton: boolean;
  signin_btn_txt: string;
  Editname: string;
  Editgeo: string;
  Editgeo_id: string;
  EditGeoName: string;
  Editevent_id: string;
  loading: boolean;
  start: number;
  pagechk: boolean;
  teamList = [];
  teamSelectedItems = [];
  EditteamSelectedItems = [];
  teamSettings = {};
  serchText: any;
  noDataMsg: string;
  pageNo: number = 1;
  perPage: number = 10;
  totalItem: number = 0;
  page: number = 2;
  exportReport: any;
  tempSearch: any = '';

  constructor(
    private router: Router,
    public _eventService: EventlistService,
    public _prospectService: MeetingService,
    private customErrorHandler: CustomErrorHandler,
    public commonService: CommonService
  ) { }
  ngOnInit() {
    this.serchText = '';
    this.isDemoTenant = (localStorage.getItem('isDemoTenant') == 'true');
    this.errormsg = '';
    this.msg = false;
    this.geofencingLabel = localStorage.getItem('geofencingLabel');
    this.actionLable = localStorage.getItem('actionLabel');
    this.modal = document.getElementById('myModal');
    this.Editmodal = document.getElementById('EditmyModal');
    this.loading = true;
    this.getEventList(1);
    this.teamListFunction();
    this.geoListFunction();
    this.teamSelectedItems = [];
    this.teamSettings = {
      singleSelection: false,
      text: "Select Team",
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      enableSearchFilter: true,
      classes: "myclass custom-class"
    };
  }
  closeModal() {
    this.modal.style.display = "none";
  }
  EditOpenmodel() {
    this.Editmodal.style.display = "block";
  }
  EditcloseModal() {
    this.Editmodal.style.display = "none";
  }
  getEventList(currentPage) {
    this.pageNo = currentPage;
    this.page = currentPage;
    this.errormsg = '';
    this.eventList = [];
    this.exportReport = '';
    this._eventService.eventList(this.pageNo, this.perPage, this.serchText, this.exportReport).subscribe(
      res => {
        this.loading = false;
        if (res.result == true) {
          this.totalItem = res.data.total;
          this.eventList = res.data.data;
          this.errormsg = res.message;
        } else {
          this.eventList = [];
          this.msg = true;
          this.errormsg = res.message;
          this.commonService.showErrorMessage(res);
        }
      },
      error => {
        this.customErrorHandler.handleHttpError(error, 'eventList');
        this.loading = false;
        this.msg = true;
        this.errormsg = JSON.parse(error['_body']).MessageList[0];
      }
    )
  }
  SelectEventById(id) {
    localStorage.setItem('editEventId', id);
    this.router.navigate(["event/create-event"]);
  }
  eventDelete(value) {
    swal({
      text: Constants.DeleteMsg,
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Delete',
    }).then((result) => {
      if (typeof result.value != 'undefined') {
        this._eventService.eventDelete(value).subscribe(
          res => {
            if (res.result == true) {
              this.commonService.showSuccessMessage(res.message);
              this.getEventList(1);
            }
            else {
              this.commonService.showErrorMessage(res);
            }
          },
          error => {
            this.customErrorHandler.handleHttpError(error, 'eventDelete');
          });
      }
    })
  }
  onOpen(event: any) {
    this.teamList = this.teamList.map((item) => {
      return {
        id: item.id,
        itemName: item.itemName
      }
    });
  }
  teamListFunction() {
    this._prospectService.teamList().subscribe(
      res => {
        this.teamList = res.data;
      },
      error => {
        this.customErrorHandler.handleHttpError(error, 'teamList');
      }
    )
  }
  geoListFunction() {
    this._prospectService.geoList().subscribe(
      res => {
        this.geoList = res.data;
      },
      error => {
        this.customErrorHandler.handleHttpError(error, 'geoList');
      }
    )
  }
  searchEventDetail() {
    this.pagechk = true;
    this.start = 0;
    this.loading = true;
    this.getEventList(1);
  }

  onSearch(item:any){
    console.log(this.serchText,'aaa')
    if(this.tempSearch != this.serchText){
      this.tempSearch = this.serchText;
      this.getEventList(1);
    }
  }
  export() {
    var options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: true,
      useBom: true,
      title: "Event List"
    };
    this.exportReport = 'yes';
    this._eventService.eventList(0, 0, this.serchText, this.exportReport).subscribe(
      res => {
        new Angular2Csv(res.data, 'event', options);
      },
      error => {
        this.customErrorHandler.handleHttpError(error, 'eventList');
        this.loading = false;
        this.msg = true;
        this.errormsg = JSON.parse(error['_body']).MessageList[0];
      }
    )
  }
  onGeoSelect() {
  }
}
