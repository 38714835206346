import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CustomErrorHandler } from '../../../../errorhandler/custom-errorhandler';
import { Constants } from '../../../../common/constants';
import { LocationService } from '.././../../../services/location/location.service';
import { CommonService } from '../../../../services/common/common.service';
declare var $: any;
declare var google: any;
@Component({
	selector: 'app-add-location',
	templateUrl: './add-location.component.html',
	styleUrls: ['./add-location.component.css']
})
export class AddLocationComponent implements OnInit {
	locationDetails: any = {};
	map: any;
	lat: any;
	lng: any;
	mapMarker: any = [];
	editLocationId: any = '';
	isDemoTenant: boolean;
	locationLabel: any = Constants.locationLabel;
	pageLabel: any;
	isLoading: boolean;
	constructor(private router: Router,
		public commonService: CommonService,
		private customErrorHandler: CustomErrorHandler,
		public locationService: LocationService) {
	}
	ngOnInit() {
		this.isDemoTenant = (localStorage.getItem('isDemoTenant') == 'true');
		this.locationDetails.added_by = localStorage.getItem('user_id');
		this.map = new google.maps.Map(document.getElementById('locationMap'), {
			zoom: 16,
			center: { lat: 22.7664707, lng: 75.8824308 },
			gestureHandling: 'cooperative',
			styles: Constants.MapStyle
		});
		this.pageLabel = 'Add'
		this.editLocationId = localStorage.getItem('editLocationId');
		if (this.editLocationId != '' && this.editLocationId != undefined) {
			this.getLocationById();
			this.pageLabel = 'Edit'
		}
		this.loadAutoComple();

		$('.fullHeightBlock').scroll(function () {
			let spTop = $('#location').offset().top + 35;
			$('.pac-container').css('top', spTop);
		})
	}
	loadAutoComple() {
		var map1 = this.map;
		var input = document.getElementById('location');
		var autocomplete = new google.maps.places.Autocomplete(input);
		// Set initial restrict to the greater list of countries.
		autocomplete.setComponentRestrictions();
		autocomplete.bindTo('bounds', map1);
		var infowindow = new google.maps.InfoWindow();
		var infowindowContent = document.getElementById('infowindow-content');
		infowindow.setContent(infowindowContent);
		var _this = this;
		autocomplete.addListener('place_changed', function () {
			infowindow.close();
			var place = autocomplete.getPlace();
			if (!place.geometry) {
				this.commonService.showCustomError("No details available for input: '" + place.name + "'");
				return;
			}
			_this.locationDetails.location = place.formatted_address;
			_this.lat = place.geometry.location.lat();
			_this.lng = place.geometry.location.lng();
			_this.locationDetails.lat = _this.lat;
			_this.locationDetails.lng = _this.lng;
			if (place.address_components.length > 1) {
				_this.locationDetails.area = place.address_components[1].long_name;
			} else {
				_this.locationDetails.area = place.address_components[0].long_name;
			}
			_this.setMarker();
		});
	}
	onSubmit() {
		this.isLoading = true;
		let locationId = localStorage.getItem('editLocationId');
		if (locationId) {
			this.locationDetails.location_id = locationId;
			this.locationService.updateLocation(this.locationDetails).subscribe(
				res => {
					if (res.result == true) {
						this.commonService.showSuccessMessage(res.message);
						this.router.navigate(["locations/location-list"]);
					} else {
						this.commonService.showCustomError(res.message);
					}
					this.isLoading = false;
				},
				error => {
					this.customErrorHandler.handleHttpError(error, 'onSubmit');
					this.isLoading = false;
				});
		} else {
			this.locationService.createLocation(this.locationDetails).subscribe(
				res => {
					if (res.result == true) {
						this.commonService.showSuccessMessage(res.message);
						let branchoffice = localStorage.getItem('addBranchOfficeFromEmployee');
						if(branchoffice == 'employee'){
							let editLogin = localStorage.getItem('addBranchOfficeEditLoggedInUser');
							if(editLogin == 'Yes'){
								localStorage.setItem('editLoggedInUser','Yes');
							}
							this.router.navigate(["setting/employee-management/employee"]);
						}else{
							this.router.navigate(["locations/location-list"]);
						}
						this.isLoading = false;
					} else {
						this.commonService.showCustomError(res.message);
						this.isLoading = false;
					}
				},
				error => {
					this.customErrorHandler.handleHttpError(error, 'onSubmit');
				});
		}
	}
	getLocationById() {
		let singleLocation = { 'location_id': this.editLocationId }
		this.locationService.selectLocationById(singleLocation).subscribe(
			res => {
				if (res.result == true) {
					this.locationDetails = res.data;
					this.locationDetails.lat = parseFloat(this.locationDetails.lat);
					this.locationDetails.lng = parseFloat(this.locationDetails.lng);
					this.lat = this.locationDetails.lat;
					this.lng = this.locationDetails.lng;
					this.setMarker();
				} else {
					this.commonService.showCustomError(res.message);
				}
			},
			error => {
				this.customErrorHandler.handleHttpError(error, 'onSubmit');
			});
	}
	setMarker() {
		for (var i = 0; i < this.mapMarker.length; i++) {
			this.mapMarker[i].setMap(null);
		}
		this.mapMarker = [];
		let locationLatlng = new google.maps.LatLng(this.lat, this.lng);
		let marker = new google.maps.Marker({
			position: locationLatlng,
			title: this.locationDetails.location
		});
		marker.setMap(this.map);
		this.map.setCenter(locationLatlng);
		this.mapMarker.push(marker);
	}
	ngOnDestroy() {
		localStorage.removeItem('editLocationId');
		localStorage.removeItem('addBranchOfficeFromEmployee');
		localStorage.removeItem('addBranchOfficeEditLoggedInUser');
			
	}
}
