import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CustomErrorHandler } from '../../../errorhandler/custom-errorhandler';
import { Constants } from '../../../common/constants';
import { GeofenceType } from '../../../common/enums';
import { VoterService } from './../../../services/voter/voter.service';
import { CommonService } from '../../../services/common/common.service';
declare var google: any;
declare var map: any;
@Component({
    selector: 'app-assign-geofence',
    templateUrl: './assign-geofence.component.html',
    styleUrls: ['./assign-geofence.component.css']
})
export class AssignGeofenceComponent implements OnInit {
    value: Date = new Date();
    geofenceDetails: any = {};
    map: any;
    lat: any;
    lng: any;
    name: any;
    formatted_address;
    area: any;
    drawGeofencing: any;
    drawGeofencingType: any;
    drawingManager: any;
    geofenceDraw: boolean = false;
    boundries: any = [];
    circleRadius: any;
    circleLat: any;
    circleLng: any;
    shapeType: any;
    geofenceRadius: any;
    geofenceList: any;
    geofenceRadiusSelectedItems: any = [];
    geofenceRadiusSettings = {};
    circle: any = [];
    startDateTimeMin: any;
    endDateTimeMin: any;
    startDateTimeMax: any;
    endDateTimeMax: any;
    startTime: any;
    endTime: any;
    update: Boolean = false;
    rectangleBounds: any = {};
    polygonPaths: any = [];
    directionsService: any;
    directionsDisplay: any;
    errorGeoMessage: any;
    constructor(private router: Router, public _commonService: CommonService, private customErrorHandler: CustomErrorHandler, public voterService: VoterService) {
    }
    ngOnInit() {
        this.geofenceDetails.active_geofence = true;
        this.geofenceDetails.created_by = localStorage.getItem('user_id');
        this.startDateTimeMin = new Date();
        this.endDateTimeMin = new Date();
        this.startTime = new Date();
        this.endTime = new Date();
        this.geofenceRadiusSettings = {
            singleSelection: true,
            text: "Select Geofence radius",
            selectAllText: 'Select All',
            unSelectAllText: 'UnSelect All',
            enableSearchFilter: true,
            classes: "singleSelection"
        };
        this.geofenceDetails.geofencing_area_name = '';
        this.geofenceRadiusSelectedItems = [{ id: 1, itemName: 10 }]
        this.geofenceRadius = Constants.GeofenceRadius;
        this.drawGeofencingType = 0;
        this.map = new google.maps.Map(document.getElementById('map'), {
            zoom: 16,
            center: { lat: 22.7664707, lng: 75.8824308 },
            gestureHandling: 'cooperative',
            styles: Constants.MapStyle
        });
        this.loadAutoComple();
        let assignVoterId = localStorage.getItem('assignVoterGeofenceId');
        if (assignVoterId) {
            this.voterService.getAssignGeogenceDetails(assignVoterId).subscribe(
                res => {
                    if (res.result == true) {
                        if (res.data != null && res.data) {
                            this.drawGeofencing = res.data.boundries;
                            this.geofenceDetails = res.data;
                            this.geofenceDetails.location = this.geofenceDetails.booth_location;
                            this.geofenceDetails.lat = Number(this.geofenceDetails.booth_lat);
                            this.geofenceDetails.lng = Number(this.geofenceDetails.booth_lng);
                            this.lat = this.geofenceDetails.lat;
                            this.lng = this.geofenceDetails.lng;
                            this.shapeType = this.geofenceDetails.type;
                            if (this.shapeType == 1) {
                                this.circleRadius = this.geofenceDetails.radius;
                                this.circleLat = this.geofenceDetails.centered_lat;
                                this.circleLng = this.geofenceDetails.centered_lng;
                            }
                            if (this.shapeType == 2) {
                                this.rectangleBounds = JSON.parse(this.geofenceDetails.boundries);
                            }
                            if (this.shapeType == 3) {
                                this.polygonPaths = JSON.parse(this.geofenceDetails.boundries);
                            }
                            this.drawGeofencingType = this.geofenceDetails.type;
                            this.geofencingDrawing(this.map);
                            this.drawingGeofence();
                            this.directionsService = new google.maps.DirectionsService;
                            this.directionsDisplay = new google.maps.DirectionsRenderer({
                                suppressMarkers: false
                            });
                            this.directionsDisplay.setMap(this.map);
                            var marker = new google.maps.Marker({
                                map: this.map,
                                anchorPoint: new google.maps.Point(0, -29)
                            });
                            var latlng = new google.maps.LatLng(this.lat, this.lng);
                            marker.setPosition(latlng);
                            var bound = new google.maps.LatLngBounds();
                            bound.extend(marker.getPosition());
                            this.map.fitBounds(bound);
                            this.map.setZoom(14);
                        }
                    } else {
                        this._commonService.showCustomError(res.message);
                    }
                },
                error => {
                    this.customErrorHandler.handleHttpError(error, 'createGeofence');
                });
        } else {
            this.router.navigate(["voter/assign-list"]);
        }
    }
    loadAutoComple() {
        var map1 = this.map;
        var input = document.getElementById('location');
        var autocomplete = new google.maps.places.Autocomplete(input);
        // Set initial restrict to the greater list of countries.
        autocomplete.setComponentRestrictions();
        autocomplete.bindTo('bounds', map1);
        var infowindow = new google.maps.InfoWindow();
        var infowindowContent = document.getElementById('infowindow-content');
        infowindow.setContent(infowindowContent);
        var marker = new google.maps.Marker({
            map: map1,
            anchorPoint: new google.maps.Point(0, -29)
        });
        var _this = this;
        autocomplete.addListener('place_changed', function () {
            infowindow.close();
            var place = autocomplete.getPlace();
            if (!place.geometry) {
                this.commonService.showCustomError("No details available for input: '" + place.name + "'");
                return;
            }
            _this.geofenceDetails.location = place.formatted_address;
            _this.lat = place.geometry.location.lat();
            _this.lng = place.geometry.location.lng();
            _this.map = new google.maps.Map(document.getElementById('map'), {
                zoom: 14,
                center: { lat: _this.lat, lng: _this.lng },
                gestureHandling: 'cooperative',
                styles: Constants.MapStyle
            });
            marker = new google.maps.Marker({
                map: _this.map,
                anchorPoint: new google.maps.Point(0, -29)
            });
            if (place.geometry.viewport) {
                map1.fitBounds(place.geometry.viewport);
            } else {
                map1.setCenter(place.geometry.location);
                map1.setZoom(14);
            }
            marker.setPosition(place.geometry.location);
            marker.setVisible(true);
            _this.geofencingDrawing(_this.map);
        });
    }
    createGeofence() {
        this.geofenceDetails.lat = this.lat;
        this.geofenceDetails.lng = this.lng;
        this.boundries = [];
        if (this.shapeType === GeofenceType.Polygone) {
            this.boundries = this.polygonPaths;
        }
        else if (this.shapeType === GeofenceType.Rectangle) {
            this.boundries = this.rectangleBounds;
        }
        else if (this.shapeType === GeofenceType.Circle) {
            this.boundries = {
                radius: this.circleRadius,
                lat: this.circleLat,
                lng: this.circleLng
            };
        }
        this.geofenceDetails.type = this.shapeType;
        this.geofenceDetails.boundries = this.boundries;
        this.geofenceDetails.assign_voter_id = localStorage.getItem('assignVoterGeofenceId');
        if (this.geofenceDetails.boundries.length == 0) {
            this._commonService.showCustomError("Please create geofence");
            return false;
        }
        this.voterService.createGeofence(this.geofenceDetails).subscribe(
            res => {
                if (res.result == true) {
                    this._commonService.showSuccessMessage(res.message);
                    this.router.navigate(["voter/assign-list"]);
                } else {
                    this._commonService.showCustomError(res.message);
                }
            },
            error => {
                this.customErrorHandler.handleHttpError(error, 'createGeofence');
            });
    }
    selectEndEventDate(endDate) {
        this.startDateTimeMax = endDate;
    }
    selectStartEventDate(startDate) {
        this.endDateTimeMin = startDate;
    }
    geofencingDrawing(map1) {
        this.map = map1;
        this.drawingManager = new google.maps.drawing.DrawingManager({
            drawingControl: true,
            drawingControlOptions: {
                position: google.maps.ControlPosition.TOP_CENTER,
                drawingModes: [google.maps.drawing.OverlayType.CIRCLE, google.maps.drawing.OverlayType.RECTANGLE, google.maps.drawing.OverlayType.POLYGON]
            }
        });
        this.drawingManager.setMap(map1);
        this.boundries = [];
        google.maps.event.addListener(this.drawingManager, 'overlaycomplete', (event) => {
            if (event.type === google.maps.drawing.OverlayType.CIRCLE) {
                this.circleRadius = event.overlay.getRadius(); // in meters
                this.circleLat = event.overlay.getCenter().lat();
                this.circleLng = event.overlay.getCenter().lng();
                this.shapeType = GeofenceType.Circle;
                this.geofenceDraw = true;
            }
            else if (event.type === google.maps.drawing.OverlayType.RECTANGLE) {
                var bounds = event.overlay.getBounds();
                this.rectangleBounds = {
                    north: bounds.getNorthEast().lat(),
                    south: bounds.getSouthWest().lat(),
                    east: bounds.getNorthEast().lng(),
                    west: bounds.getSouthWest().lng()
                };
                this.shapeType = GeofenceType.Rectangle;
                this.geofenceDraw = true;
            }
            else if (event.type === google.maps.drawing.OverlayType.POLYGON) {
                var polygonPath = event.overlay.getPath().getArray();
                this.polygonPaths = [];
                polygonPath.forEach(item => {
                    var path = {
                        lat: item.lat(),
                        lng: item.lng()
                    };
                    this.polygonPaths.push(path);
                });
                this.shapeType = GeofenceType.Polygone;
                this.geofenceDraw = true;
            }
            this.drawingManager.setDrawingMode(null);
            this.drawingManager.setOptions({
                drawingControl: false
            });
        });
    }
    skipGeofence() {
        this.router.navigate(["voter/assign-list"]);
    }
    resetGeoference() {
        this.errorGeoMessage = '';
        this.boundries = [];
        this.directionsService = new google.maps.DirectionsService;
        this.directionsDisplay = new google.maps.DirectionsRenderer({
            suppressMarkers: false
        });
        this.map = new google.maps.Map(document.getElementById('map'), {
            zoom: 14,
            center: { lat: this.lat, lng: this.lng },
            styles: Constants.MapStyle
        });
        this.geofencingDrawing(this.map);
        this.directionsDisplay.setMap(this.map);
        this.drawGeofencing = [];
        this.drawGeofencingType = 0;
        this.geofenceDraw = false;
        var marker = new google.maps.Marker({
            map: this.map,
            anchorPoint: new google.maps.Point(0, -29)
        });
        var latlng = new google.maps.LatLng(this.lat, this.lng);
    	marker.setPosition(latlng);
      this.geofenceDetails.location = '';
    }
    drawingGeofence() {
        var _this = this;
        if (this.drawGeofencingType == 2) {
            let rectanglelatLng = JSON.parse(_this.drawGeofencing);
            var rectangle = new google.maps.Rectangle({
                strokeColor: '#000000',
                strokeOpacity: 1,
                strokeWeight: 2,
                fillColor: '#262525',
                fillOpacity: 0.35,
                map: _this.map,
                bounds: rectanglelatLng
            });
            this.drawingManager.setOptions({
                drawingControl: false
            });
            this.geofenceDraw = true;
        }
        if (this.drawGeofencingType == 3) {
            let polygonlatLng = JSON.parse(_this.drawGeofencing);
            var rectangle = new google.maps.Polygon({
                map: _this.map,
                paths: polygonlatLng,
                strokeColor: '#000000',
                strokeOpacity: 1,
                strokeWeight: 2,
                fillColor: '#262525',
                fillOpacity: 0.35,
                draggable: false,
                geodesic: true
            });
            this.drawingManager.setOptions({
                drawingControl: false
            });
            this.geofenceDraw = true;
        }
        if (this.drawGeofencingType == 1) {
            let circlelatLng = JSON.parse(_this.drawGeofencing);
            var marker = new google.maps.Marker({
                map: map,
                position: new google.maps.LatLng(circlelatLng.lat, circlelatLng.lng),
                title: 'Some location'
            });
            var circle = new google.maps.Circle({
                map: _this.map,
                radius: circlelatLng.radius,    // 10 miles in metres
                fillColor: '#262525'
            });
            circle.bindTo('center', marker, 'position');
            this.drawingManager.setOptions({
                drawingControl: false
            });
            this.geofenceDraw = true;
        }
    }
    ngOnDestroy() {
        localStorage.removeItem('assignVoterGeofenceId');
    }
}
