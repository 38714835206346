import { PipeModule } from './../../../pipe/pipe.module';
import { AuthGuard } from './../../../services/_guards/auth.guard';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { TeamComponent } from './team.component';
import { TeamListComponent } from './team-list/team-list.component';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown/angular2-multiselect-dropdown';
import { InfiniteScrollModule } from 'angular2-infinite-scroll';
import { Ng2PaginationModule } from 'ng2-pagination';
export const routes: Routes = [
  {
    path: 'team',
    component: TeamComponent, canActivate: [AuthGuard]
  }
];
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule.forChild(routes),
    AngularMultiSelectModule,
    InfiniteScrollModule,
    PipeModule.forRoot(),
    Ng2PaginationModule
  ],
  declarations: [
    TeamComponent,
    TeamListComponent
  ]
})
export class TeamModule { }
