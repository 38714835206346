import { Injectable } from '@angular/core';
import { HttpHelper } from '../../helpers/http.helper';
import { Config } from '../../config/config';
@Injectable()
export class EventlistService {
    constructor(private _httpHelper: HttpHelper) {
    }
    eventList(pageNo, rowPerPage, search, exportReport) {
        try {
            return this._httpHelper.get(Config.AuthenticationBaseURL1 + 'eventList?page=' + pageNo + '&per_page=' + rowPerPage + '&search=' + search + '&export=' + exportReport)
        }
        catch (e) {
        }
    }
    SelectEventById(id) {
        try {
            var request = {
                "event_id": id
            };
            return this._httpHelper.post(Config.AuthenticationBaseURL + 'SelectEventById', request)
        }
        catch (e) {
        }
    }
    eventDelete(event_id: string) {
        try {
            var request = {
                "event_id": event_id
            };
            return this._httpHelper.post(Config.AuthenticationBaseURL + 'deleteEvent', request)
        }
        catch (e) {
        }
    }
    createEvent(request) {
        try {
            return this._httpHelper.post(Config.AuthenticationBaseURL1 + 'create-event', request)
        }
        catch (e) {
        }
    }
    editEvent(request) {
        try {
            return this._httpHelper.post(Config.AuthenticationBaseURL1 + 'update-event', request)
        }
        catch (e) {
        }
    }
    getRadiusList() {
        try {
            return this._httpHelper.get(Config.AuthenticationBaseURL1 + 'get-radius');
        }
        catch (e) {
        }
    }
}
