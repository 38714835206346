import { Component, OnInit } from '@angular/core';
declare var $: any;
@Component({
	selector: 'reports',
	templateUrl: './reports.component.html',
	styleUrls: ['./reports.component.css'],
	host: {
		'class': 'fullHeightBlock'
	}
})
export class ReportsComponent implements OnInit {
	employeeLabel: string;
	ngOnInit() {
		this.employeeLabel = localStorage.getItem('employeeLabel');
		if ($(window).width() > 760) {
			$('.fullHeightBlock').scrollbar();
		}
	}
	ngOnDestroy() {
		$('.fullHeightBlock').remove();
	}
}
