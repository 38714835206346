import { Component, OnInit } from '@angular/core';
import { Angular2Csv } from 'angular2-csv/Angular2-csv';
import { Constants } from '../../../../common/constants';
import { TeamlistService } from '../../../../services/team/teamList.service';
import { CustomErrorHandler } from '../../../../errorhandler/custom-errorhandler';
import swal from 'sweetalert2';
import { CommonService } from '../../../../services/common/common.service';
@Component({
  selector: 'team-list',
  templateUrl: 'team-list.component.html',
  styleUrls: ['./team-list.component.css']
})
export class TeamListComponent implements OnInit {
  isDemoTenant: boolean;
  isExport: boolean;
  actionLable: string;
  modal: any;
  Editmodal: any;
  company_id: string;
  team: any[];
  employee: any[];
  team_show: boolean;
  loading: boolean;
  loginFormEmail: string;
  status: string;
  errorMessage: string;
  disableButton: boolean;
  signin_btn_txt: string;
  loginDiv: boolean;
  tenantList: any[];
  accessToken: string;
  image_url: string;
  teamName: string;
  editTeamName: string;
  editteam_id: string;
  tempSearch: any;
  noDataMsg: string;
  dropdownList = [];
  selectedItems = [];
  EditselectedItems = [];
  dropdownSettings = {};
  message: any;
  pageNo: number = 1;
  perPage: number = 10;
  totalItem: number = 0;
  page: number = 2;
  exportReport: any;
  teamList: any = [];
  serchText: any;

  reportingOwnerList: any = [];
  ownerSelectedItem: any = [];
  ownerSettings: any = {};
  locationList: any = [];
  locationSettings: any = {};
  locationSelectedItem: any = [];
  ownerId: any = '';
  locationId: any = '';
  locationLabel: any = Constants.locationLabel;
  created_by: any;
  filterLabel = Constants.filterLabel;
  locationSelectedItemFilter: any = [];
  ownerSelectedItemFilter: any = [];
  ownerIdFilter: any = '';
  locationIdFilter: any = '';
  constructor(
    public _teamService: TeamlistService,
    private customErrorHandler: CustomErrorHandler,
    public commonService: CommonService
  ) { }
  ngOnInit() {
    this.serchText = '';
    this.tempSearch = '';
    this.isExport = false;
    this.isDemoTenant = (localStorage.getItem('isDemoTenant') == 'true');
    this.actionLable = localStorage.getItem('actionLabel');
    this.signin_btn_txt = 'Submit';
    this.modal = document.getElementById('myModal');
    this.Editmodal = document.getElementById('EditmyModal');
    this.loading = true;
    this.getTeamList(1);
    this.selectedItems = [];
    this.dropdownSettings = {
      singleSelection: false,
      text: "Select Members",
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      enableSearchFilter: true,
      classes: "myclass custom-class block_elem m_b_15"
    };

    this.getLocationList();
    this.ownerSettings = {
      singleSelection: true,
      text: "Select Reporting Person",
      enableSearchFilter: true,
      classes: "singleSelection block_elem m_b_15"
    };
    this.locationSettings = {
      singleSelection: true,
      text: "Select " + this.locationLabel,
      enableSearchFilter: true,
      classes: "singleSelection block_elem m_b_15"
    }
  }
  onOpen(event: any) {
    this.dropdownList = this.dropdownList.map((item) => {
      return {
        id: item.id,
        itemName: item.itemName
      }
    });
  }
  Openmodel() {
    this.teamName = '';
    this.selectedItems = [];
    this.locationSelectedItem = [];
    this.ownerSelectedItem = [];
    this.reportingOwnerList = [];
    this.modal.style.display = "block";
  }
  closeModal() {
    this.errorMessage = '';
    this.modal.style.display = "none";
  }
  EditOpenmodel() {
    this.locationSelectedItem = [];
    this.ownerSelectedItem = [];
    this.Editmodal.style.display = "block";
  }
  EditcloseModal() {
    this.errorMessage = '';
    this.Editmodal.style.display = "none";
  }
  getTeamList(currentPage) {
    this.isExport = false;
    this.pageNo = currentPage;
    this.page = currentPage;
    this.noDataMsg = '';
    this.teamList = [];
    this.exportReport = '';
    this.team_show = false;
    this.exportReport = '';
    this._teamService.teamListPaging(this.pageNo, this.perPage, this.serchText, this.exportReport, this.locationIdFilter, this.ownerIdFilter).subscribe(
      res => {
        this.loading = false;
        this.noDataMsg = res.message;
        if (res.result == true) {
          this.totalItem = res.data.total;
          this.teamList = res.data.data;
        } else {
          this.totalItem = 0;
          this.teamList = [];
          this.noDataMsg = res.message;
          this.commonService.showErrorMessage(res);
        }
      },
      error => {
        this.loading = false;
        this.customErrorHandler.handleHttpError(error, 'teamListPaging');
      });
  }
  SelectByTeamById(id) {
    this.team_show = false;
    this._teamService.SelectByTeamById(id).subscribe(
      res => {
        if (res.result == true) {
          this.EditOpenmodel();
          this.editteam_id = res.data.id;
          this.editTeamName = res.data.team_name;
          this.EditselectedItems = res.data.member;
          this.created_by = res.data.created_by;
          this.locationSelectedItem = [];
          let temp = this.locationList.find(s => s.id == res.data.location_id);
          if (temp != undefined) {
            this.locationSelectedItem.push(temp);
          }

          this.ownerSelectedItem = [];
          this.locationId = res.data.location_id;
          this.ownerId = res.data.owner_id;
          this.getReportingOwnerList('edit');
          this.employeeList('edit');
        }
      },
      error => {
        this.customErrorHandler.handleHttpError(error, 'SelectByTeamById');
      });
  }
  onkeyup_value(event) {
    this.serchText = event.target.value;
    if (this.tempSearch != this.serchText) {
      this.getTeamList(1);
    }
    this.tempSearch = this.serchText;
  }
  employeeList(status = null) { 
    this._teamService.employeeList(this.locationId, this.ownerId).subscribe(
      res => {
        this.employee = res.data;
        this.dropdownList = this.employee;
        if(status == 'edit'){
          let memberList = this.EditselectedItems;
          console.log(this.employee, memberList)
          this.EditselectedItems = [];
          for(let i = 0; i < memberList.length; i++){
            let empId = memberList[i].id;
            let empTemp = this.employee.find(s => s.id == empId);
            if (empTemp != undefined) {
              this.EditselectedItems.push(empTemp);
            }
          }
        }
      },
      error => {
        this.customErrorHandler.handleHttpError(error, 'employeeList');
      });
  }
  teamDelete(value) {
    swal({
      text: Constants.DeleteMsg,
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Delete',
    }).then((result) => {
      if (typeof result.value != 'undefined') {
        this._teamService.teamDelete(value).subscribe(
          res => {
            if (res.result == true) {
              this.commonService.showSuccessMessage(res.message);
              this.getTeamList(1);
            }
            else {
              this.commonService.showErrorMessage(res);
            }
          },
          error => {
            this.customErrorHandler.handleHttpError(error, 'teamDelete');
          });
      }
    });
  }
  onSubmit(model) {
    if (this.teamName == '' || this.teamName == undefined) {
      this.errorMessage = "Name Should not be blank";
      return false;
    }
    if (this.locationSelectedItem.length == 0) {
      this.errorMessage = "Location Should not be blank";
      return false;
    }
    if (this.ownerSelectedItem.length == 0) {
      this.errorMessage = "Team Owner Should not be blank";
      return false;
    }
    if (this.selectedItems.length == 0) {
      this.errorMessage = "Member Should not be blank";
      return false;
    }
    this.loginFormEmail = model.email;
    this.disableButton = true;
    this.errorMessage = '';
    this.signin_btn_txt = "Creating";
    let member = '';
    for (let i = 0; i < this.selectedItems.length; i++) {
      if (i == 0) {
        member = this.selectedItems[i].id;
      } else {
        member = member + ',' + this.selectedItems[i].id;
      }
    }
    this._teamService.createTeam(this.teamName, member, this.locationId, this.ownerId).subscribe(
      res => {
        if (res.result == true) {
          this.commonService.showSuccessMessage(res.message);
          this.closeModal();
          this.getTeamList(1);
        } else {
          this.disableButton = false;
          this.signin_btn_txt = "Sign In";
          this.commonService.showErrorMessage(res);
          this.errorMessage = res.message;
        }
      },
      error => {
        this.customErrorHandler.handleHttpError(error, 'createTeam');
        this.disableButton = false;
        this.signin_btn_txt = "Sign In";
      });
  }
  editTeam() {
    if (this.editTeamName == '' || this.editTeamName == undefined) {
      this.errorMessage = "Name Should not be blank";
      return false;
    }
    if (this.locationSelectedItem.length == 0) {
      this.errorMessage = "Location Should not be blank";
      return false;
    }
    if (this.ownerSelectedItem.length == 0) {
      this.errorMessage = "Team Owner Should not be blank";
      return false;
    }
    if (this.EditselectedItems.length == 0) {
      this.errorMessage = "Member Should not be blank";
      return false;
    }
    this.disableButton = true;
    this.errorMessage = '';
    this.signin_btn_txt = "Creating";
    let editmember = '';
    for (let i = 0; i < this.EditselectedItems.length; i++) {
      if (i == 0) {
        editmember = this.EditselectedItems[i].id;
      } else {
        editmember = editmember + ',' + this.EditselectedItems[i].id;
      }
    }
    this._teamService.EditTeam(this.editTeamName, editmember, this.editteam_id, this.locationId, this.ownerId).subscribe(
      res => {
        if (res.result == true) {
          this.commonService.showSuccessMessage(res.message);
          this.EditcloseModal();
          this.getTeamList(1);
        } else {
          this.disableButton = false;
          this.signin_btn_txt = "Sign In";
          this.commonService.showErrorMessage(res);
        }
      },
      error => {
        this.customErrorHandler.handleHttpError(error, 'EditTeam');
        this.disableButton = false;
        this.signin_btn_txt = "Sign In";
      });
  }
  export() {
    var options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: true,
      useBom: true,
      title: "Team List"
    };
    this.exportReport = 'yes';
    this._teamService.teamListPaging(0, 0, this.serchText, this.exportReport, this.locationIdFilter, this.ownerIdFilter).subscribe(
      res => {
        new Angular2Csv(res.data, 'team', options);
      },
      error => {
        this.customErrorHandler.handleHttpError(error, 'teamListPaging');
      });
  }

  getReportingOwnerList(status = null) {
    let parameter = {
      'location_id' : this.locationId
    }
    this.commonService.getReportingOwnerList(parameter).subscribe(res => {
      if (res.result == true) {
        this.reportingOwnerList = res.data;
        if(status == 'edit'){
          this.ownerSelectedItem = [];
          // console.log(this.locationId)
          let ownerTemp = this.reportingOwnerList.find(s => s.id == this.ownerId);
          if (ownerTemp != undefined) {
            this.ownerSelectedItem.push(ownerTemp);
          }
        }
      }
    }, err => {
      this.customErrorHandler.handleHttpError(err, 'getRoleList');
    });
  }

  getLocationList() {
    this.commonService.getLocationList().subscribe(res => {
      if (res.result == true) {
        this.locationList = res.data;
      }
    }, err => {
      this.customErrorHandler.handleHttpError(err, 'getRoleList');
    });
  }

  onOwnerItemSelect(item: any) {
    this.ownerId = item.id;
    this.dropdownList = [];
    this.EditselectedItems = [];
    this.selectedItems = [];
    this.getReportingOwnerList();
    this.employeeList();
    
  }
  onOwnerItemDeSelect(item: any) {
    this.ownerId = '';
    this.dropdownList = [];
    this.selectedItems = [];
    this.EditselectedItems = [];
    this.getReportingOwnerList();
    // this.employeeList();
  }
  onLocationItemSelect(item: any) {
    this.locationId = item.id;
    this.ownerSelectedItem = [];
    this.EditselectedItems = [];
    this.selectedItems = [];
    this.dropdownList = [];

    this.getReportingOwnerList();
    // this.employeeList();
  }
  onLocationItemDeSelect(item: any) {
    this.locationId = '';
    this.reportingOwnerList = [];
    this.ownerSelectedItem = [];
    this.EditselectedItems = [];
    this.selectedItems = [];
    this.dropdownList = [];
  }

  onLocationItemSelectFilter(item: any){
    this.locationIdFilter = item.id;
    this.getReportingOwnerList();
    this.getTeamList(1);
  }

  onLocationItemDeSelectFilter(item: any){
    this.locationIdFilter = '';
    this.getTeamList(1);
  }

  onOwnerItemSelectFilter(item: any){
    this.ownerIdFilter = item.id;
    this.getTeamList(1);
  }

  onOwnerItemDeSelectFilter(item: any){
    this.ownerIdFilter = '';
    this.getTeamList(1);
  }
}

