import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CustomErrorHandler } from '../../../errorhandler/custom-errorhandler';
import { RouteService } from '../../../services/route/route.service';
import { Config } from '../../../config/config';
import { Constants } from '../../../common/constants';
import { CommonService } from '../../../services/common/common.service';
declare var moment: any;
declare var $: any;
declare var google: any;
@Component({
    selector: 'route-update',
    templateUrl: 'route-update.component.html',
    styleUrls: ['./route-update.component.css']
})
export class RouteUpdateComponent implements OnInit {
    isDemoTenant: boolean;
    drawingManager: any;
    polygonPaths: any[] = [];
    rectangleBounds: any;
    circleRadius: any;
    circleLat: string;
    circleLng: string;
    shapeType: number;
    creates: any;
    create_area: boolean;
    map: any;
    cityCircle: any;
    dd_radius1: string;
    lat: string;
    lng: string;
    name: string;
    loginFormEmail: string;
    status: string;
    errorMessage: string;
    disableButton: boolean;
    signin_btn_txt: string;
    loginDiv: boolean;
    tenantList: any[];
    accessToken: string;
    image_url: string;
    current_lng: any;
    current_lat: any;
    directionsService: any;
    directionsDisplay: any;
    routeOrigin: any;
    routeDestination: any;
    routePaths: any[] = [];
    originPath: any;
    originResult: any;
    routeDrawResult: any[] = [];
    routeDistance: any = 0;
    routeETA: any = 0;
    routeResult: any;
    routeDetails: any = {};
    errorGeoMessage: any;
    boundries: any;
    routeDistanceKm: any = 0;
    routeETAMin: any = 0;
    employeeMemberList: any = [];
    employeeMemberSettings = {};
    routeCreateDetails: any = {};
    employeeMeetingList: any = [];
    employeeMeetingListDragable: any = [];
    errorEmployeeMessage: any;
    errorRouteMessage: any;
    routeETATime: any;
    markerArray = [];
    stepDisplay: any;
    markers = [];
    meetingModal: any;
    defaultEmployeeMeeting: any = [];
    meetingFormIds: any = [];
    employeeMeetingListTemp: any = [];
    prospectLabel: any;
    meetingRouteLength: any = 0;
    startDate: any;
    endDate: any;
    startDateshow: any;
    endDateshow: any;
    employeeLabel: string;
    locationId;
    locationLabel: string =Constants.locationLabel;
    constructor(private router: Router, private commonService: CommonService, public _routeService: RouteService, private customErrorHandler: CustomErrorHandler) {
    }
    loadAutoComple() {
        var input = document.getElementById('route-draw-input1');
        var autocomplete = new google.maps.places.Autocomplete(input);
        var infowindow = new google.maps.InfoWindow();
        var infowindowContent = document.getElementById('infowindow-content');
        infowindow.setContent(infowindowContent);
        autocomplete.addListener('place_changed', function () {
            infowindow.close();
        });
    }
    success(position) {
        this.current_lng = position.coords.longitude;
        this.current_lat = position.coords.latitude;
        this.map = new google.maps.Map(document.getElementById('map'), {
            center: { lat: this.current_lat, lng: this.current_lng },
            zoom: 8,
            styles: Constants.MapStyle
        });
        setTimeout(() => {
            this.loadAutoComple();
        }, 100);
    }
    fail(position) {
        this.map = new google.maps.Map(document.getElementById('map'), {
            center: { lat: 22.7664707, lng: 75.8824308 },
            zoom: 8,
            styles: Constants.MapStyle
        });
        setTimeout(() => {
            this.loadAutoComple();
        }, 100);
    }
    ngOnInit() {
        this.employeeLabel = localStorage.getItem('employeeLabel');
        var start = moment().subtract(0, 'days');
        var end = moment();
        let this1 = this;
        function cb(start, end) {
            this1.startDate = start.format('YYYY-MM-D');
            this1.startDateshow = start.format('MMMM D, YYYY');
            $('#startDate span').html(start.format('MMMM D, YYYY'));
            if (typeof this1.routeDetails.employee_id != undefined && this1.routeDetails.employee_id != '') {
                this1.getEmployeeDetails(this1.routeDetails.employee_id);
            }
            $('#endDate').daterangepicker({
                singleDatePicker: true,
                startDate: start,
                minDate: start
            }, cbEnd);
            $('#endDate span').html(start.format('MMMM D, YYYY'));
        }
        function cbEnd(start, end) {
            this1.endDate = start.format('YYYY-MM-D');
            this1.endDateshow = start.format('MMMM D, YYYY');
            $('#endDate span').html(start.format('MMMM D, YYYY'));
            if (typeof this1.routeDetails.employee_id != undefined && this1.routeDetails.employee_id != '') {
                this1.getEmployeeDetails(this1.routeDetails.employee_id);
            }
        }
        $('#startDate').daterangepicker({
            singleDatePicker: true,
            startDate: start,
            minDate: start
        }, cb);
        $('#endDate').daterangepicker({
            singleDatePicker: true,
            startDate: start,
            minDate: start
        }, cbEnd);
        cb(start, end);
        cbEnd(start, end);
        this.prospectLabel = localStorage.getItem('prospectLabel');
        this.isDemoTenant = (localStorage.getItem('isDemoTenant') == 'true');
        this.routeETATime = 0;
        this.routeOrigin = '';
        this.routeDestination = '';
        this.directionsService = new google.maps.DirectionsService;
        this.directionsDisplay = new google.maps.DirectionsRenderer({
            suppressMarkers: true
        });
        this.map = new google.maps.Map(document.getElementById('map'), {
            zoom: 8,
            center: { lat: 22.7664707, lng: 75.8824308 },
            gestureHandling: 'cooperative',
            styles: Constants.MapStyle
        });
        this.directionsDisplay.setMap(this.map);
        this.stepDisplay = new google.maps.InfoWindow;
        setTimeout(() => {
            this.loadAutoComple();
            this.getRouteDetails();
        }, 100);
        this.meetingModal = document.getElementById('selectRootFromMeetingModal');
    }
    calculateAndDisplayRoute(directionsService, directionsDisplay) {
        let waypts = [];
        this.routePaths = [];
        let routeLength = this.employeeMeetingList.length;
        let imgUrl = Config.AuthenticationURL + 'public/route-icon/';
        if (routeLength > 1) {
            this.routeOrigin = this.employeeMeetingList[0]['lat'] + ',' + this.employeeMeetingList[0]['lng'];//$('#'+originId).val();
            this.routePaths.push(this.routeOrigin);
            this.originPath = '';
            this.routeDestination = this.employeeMeetingList[routeLength - 1]['lat'] + ',' + this.employeeMeetingList[routeLength - 1]['lng'];
            if (routeLength > 2) {
                for (let i = 1; i < routeLength - 1; i++) {
                    let routeWayPoint = this.employeeMeetingList[i]['lat'] + ',' + this.employeeMeetingList[i]['lng'];
                    waypts.push({
                        location: routeWayPoint,
                        stopover: true
                    });
                    this.routePaths.push(routeWayPoint);
                    let wayPointLatLng = new google.maps.LatLng(this.employeeMeetingList[i]['lat'], this.employeeMeetingList[i]['lng']);
                    let wayPointIcon = imgUrl + (i + 1) + '.png';
                    this.createMarker(wayPointLatLng, '', this.employeeMeetingList[i]['name'], this.employeeMeetingList[i]['image_url'], wayPointIcon);
                }
            }
            this.routePaths.push(this.routeDestination);
        }
        else {
            return false;
        }
        $('.route_input').each(function () {
            var inputId = $(this).attr('id');
        })
        var _this = this;
        this.routeOrigin = new google.maps.LatLng(this.employeeMeetingList[0]['lat'], this.employeeMeetingList[0]['lng']);
        this.routeDestination = new google.maps.LatLng(this.employeeMeetingList[routeLength - 1]['lat'], this.employeeMeetingList[routeLength - 1]['lng']);
        this.createMarker(this.routeOrigin, '', this.employeeMeetingList[0]['name'], this.employeeMeetingList[0]['image_url'], imgUrl + '1.png');
        let destinationIcon = imgUrl + '' + routeLength + '.png';
        if (routeLength > 32) {
            destinationIcon = imgUrl + '32.png'
        }
        this.createMarker(this.routeDestination, '', this.employeeMeetingList[routeLength - 1]['name'], this.employeeMeetingList[routeLength - 1]['image_url'], destinationIcon);
        directionsService.route({
            origin: this.routeOrigin,
            destination: this.routeDestination,
            waypoints: waypts,
            optimizeWaypoints: false,
            travelMode: 'DRIVING'
        }, function (response, status) {
            if (status === 'OK') {
                directionsDisplay.setDirections(response);
                var route = response.routes[0];
                _this.routeDistance = 0;
                _this.routeETA = 0;
                for (var i = 0; i < route.legs.length; i++) {
                    _this.routeDistance += Number(route.legs[i]['distance']['value']);
                    _this.routeETA += Number(route.legs[i]['duration']['value']);
                }
                _this.routeDistanceKm = (_this.routeDistance / 1000).toFixed(1);
                _this.routeETAMin = (_this.routeETA / 60).toFixed(0);
                _this.timeFormating(_this.routeETAMin);
                _this.setMeetingPoint();
            } else {
                let routeLen = this.routeDrawResult.length;
                if (routeLen >= 2) {
                    return false;
                }
            }
        });
    }
    timeFormating(totalMinutes) {
        let totalTime = Number(totalMinutes);
        this.routeETATime = '';
        if (totalTime < 60) {
            this.routeETATime = totalTime + ' m';
        } else {
            if (totalTime > 1440) {
                let days = totalTime / 1440;
                days = parseInt(days.toString());
                if (days > 0) {
                    this.routeETATime = days + ' d ';
                }
                let hours = totalTime / 60;
                hours = parseInt(hours.toString());
                if (hours > 0) {
                    this.routeETATime = this.routeETATime + '' + hours + ' h ';
                }
                let minute = (totalTime % 60);
                minute = parseInt(minute.toString());
                if (minute > 0) {
                    this.routeETATime = this.routeETATime + '' + minute + ' m';
                }
            } else {
                let hours = totalTime / 60;
                hours = parseInt(hours.toString());
                if (hours > 0) {
                    this.routeETATime = hours + ' h ';
                }
                let minute = (totalTime % 60);
                minute = parseInt(minute.toString());
                if (minute > 0) {
                    this.routeETATime = this.routeETATime + '' + minute + ' m';
                }
            }
        }
    }
    displayRoute() {
        let length = this.employeeMeetingList.length;
        this.employeeMeetingListDragable = this.defaultEmployeeMeeting;
        this.employeeMeetingListTemp = [];
        var _this = this;
        $('.route_input').each(function () {
            let id = $(this).attr('id');
            for (let i = 0; i < length; i++) {
                if (id == _this.employeeMeetingList[i]['id']) {
                    _this.employeeMeetingListTemp.push(_this.employeeMeetingList[i]);
                }
            }
        });
        this.employeeMeetingList = this.employeeMeetingListTemp;
        this.calculateAndDisplayRoute(this.directionsService, this.directionsDisplay);
    }
    loadAutoComplete(length) {
        for (let i = 1; i <= length; i++) {
            var input = document.getElementById('route-draw-input' + i);
            var autocomplete = new google.maps.places.Autocomplete(input);
            var infowindow = new google.maps.InfoWindow();
            var infowindowContent = document.getElementById('infowindow-content');
            infowindow.setContent(infowindowContent);
            var _this = this;
            autocomplete.addListener('place_changed', function () {
                _this.displayRoute();
                infowindow.close();
            });
        }
        this.locationDragable();
    }
    locationDragable() {
        var _this = this;
        $('#sortable').sortable({
            placeholder: "ui-state-highlight",
            handle: ".dragdrop-handle",
            opacity: 0.5,
            cursor: "move",
            stop: function (event, ui) {
                _this.displayRoute();
            },
            update: function (event, ui) {
                $('.sort-order-value').each(function (index, value) {
                    if (0 == index) {
                        $(value).parent().find('.delete-handle').hide();
                    } else {
                        $(value).parent().find('.delete-handle').show();
                    }
                    $(value).val(index + 1);
                });
            }
        });
        $('#sortable').disableSelection();
    }
    deleteRoute(meetingId) {
        let meetingData = this.employeeMeetingList;
        if (meetingData.length > 2) {
            let index = this.meetingFormIds.indexOf(meetingId);
            this.meetingFormIds.splice(index, 1);
            $('#meeting-' + meetingId).prop('checked', false);
            this.employeeMeetingList = [];
            for (let i = 0; i < meetingData.length; i++) {
                if (meetingData[i]['id'] != meetingId) {
                    this.employeeMeetingList.push(meetingData[i]);
                }
            }
        } else {
            this.commonService.showCustomError('Minimum 2 point is required for route draw');
            return false;
        }
        this.meetingRouteLength = this.meetingFormIds.length;
        this.deleteMarkers();
        this.calculateAndDisplayRoute(this.directionsService, this.directionsDisplay);
    }
    saveRoute() {
        let routeName = $('#route-name').val();
        if ($.trim(routeName) == '') {
            this.errorMessage = "Please add route title name.";
            return false;
        }
        let routeLen = this.employeeMeetingList.length;
        if (routeLen < 2) {
            this.errorRouteMessage = "Please add meeting first";
            return false;
        }
        let routeResult = {};
        routeResult['id'] = localStorage.getItem('update_route_id');
        routeResult['company_id'] = localStorage.getItem('company_id');
        routeResult['route_distance_km'] = (this.routeDistance) / 1000;
        routeResult['eta'] = this.routeETATime;
        routeResult['route_point'] = this.employeeMeetingList;
        routeResult['driving_mode'] = 'driving';
        routeResult['created_by'] = localStorage.getItem('user_id');
        routeResult['route_name'] = routeName;
        routeResult['geofencing'] = this.boundries;
        routeResult['location_id'] = this.routeDetails.location_id;
        routeResult['employee_id'] = this.routeDetails.employee_id;
        routeResult['start_date'] = this.startDate;
        routeResult['end_date'] = this.endDate;
        var this1 = this;
        setTimeout(function () {
            this1._routeService.updateRoute(routeResult).subscribe(
                res => {
                    this1.routeResult = res;
                    if (res.result == true) {
                        this1.commonService.showSuccessMessage(res.message);
                        let routeId = localStorage.getItem('update_route_id');
                        localStorage.setItem('root_geofence_id', routeId);
                        localStorage.setItem('geofence', 'Update');
                        this1.router.navigate(["route/create-geofence"]);
                    } else {
                        this.commonService.showCustomError(res.message);
                    }
                    
                });
        }, 500);
    }
    getLatLong(address, sequence) {
        var geocoder = new google.maps.Geocoder();
        var _this = this;
        geocoder = new google.maps.Geocoder();
        if (geocoder) {
            geocoder.geocode({
                'address': address
            }, function (results, status) {
                if (status == google.maps.GeocoderStatus.OK) {
                    var latitude = results[0].geometry.location.lat();
                    var longitude = results[0].geometry.location.lng();
                    var result = {
                        lat: latitude,
                        lng: longitude,
                        location: address,
                        sequence_order: sequence
                    }
                    _this.routeDrawResult.push(result);
                }
            });
        }
    }
    getRouteDetails() {
        let routeId = localStorage.getItem('update_route_id');
        if (routeId) {
            this._routeService.singleRouteDetails(routeId).subscribe(
                res => {
                    this.routeDetails.route_name = res.data.route_name;
                    this.routeDetails.employee_name = res.data.employee_name;
                    this.routeDetails.location_name = res.data.location_name;
                    this.routeDetails.employee_id = res.data.employee_id;
                    this.routeDetails.location_id = res.data.location_id;
                    this.employeeMeetingList = res.data.route_direction_points;
                    this.meetingFormIds = [];
                    for (let i = 0; i < this.employeeMeetingList.length; i++) {
                        this.meetingFormIds.push(this.employeeMeetingList[i]['id']);
                    }
                    this.routeETAMin = Number(res.data.eta).toFixed(0);
                    this.routeDistanceKm = Number(res.data.route_distance_km).toFixed(1);
                    let start = moment(res.data.start_date);
                    this.startDate = start.format('YYYY-MM-DD');
                    this.startDateshow = start.format('MMMM D, YYYY');
                    $('#startDate span').html(start.format('MMMM D, YYYY'));
                    let end = moment(res.data.end_date);
                    this.endDate = end.format('YYYY-MM-DD');
                    this.endDateshow = end.format('MMMM D, YYYY');
                    $('#endDate span').html(end.format('MMMM D, YYYY'));
                    this.getEmployeeDetails(this.routeDetails.employee_id);
                    this.meetingRouteLength = this.meetingFormIds.length;
                    this.locationDragable();
                    let meetingRouteId = localStorage.getItem('meetingRouteUpdateId');
                    if (meetingRouteId) {
                        var this_ = this;
                        setTimeout(function () {
                            this_.openMeetingModal();
                        }, 1000);
                    }
                },
                error => {
                    this.customErrorHandler.handleHttpError(error, 'singleRouteDetails');
                });
        }
        else {
            this.router.navigate(["route/route-create"]);
        }
    }
    createMarker(latlng, description, name, imgUrl, icon) {
        var image = {
            url: imgUrl,
            size: new google.maps.Size(32, 32),
            shape: { coords: [17, 17, 18], type: 'circle' },
            optimized: false
        };
        var content = '<div style="background: url(' + imgUrl + ') no-repeat center;width:150px;height: 90px;"></div><br><b style="font-weight:bold">' + name + '</b><p>' + description + '</p>';
        var _this = this;
        var infowindow = new google.maps.InfoWindow({
            content: content,
            maxWidth: 150,
            width: 100
        });
        var marker = new google.maps.Marker({
            position: latlng,
            icon: icon,
            map: _this.map,
            shape: { coords: [17, 17, 18], type: 'circle' }
        });
        google.maps.event.addListener(marker, 'click', function () {
            infowindow.open(_this.map, marker);
        });
        this.markers.push(marker);
    }
    deleteMarkers() {
        //Loop through all the markers and remove
        for (var i = 0; i < this.markers.length; i++) {
            this.markers[i].setMap(null);
        }
        this.markers = [];
    };
    getEmployeeDetails(employeeId) {
        let routeId = localStorage.getItem('update_route_id');
        let parameter = { employee_id: employeeId, start_date: this.startDate, end_date: this.endDate, route_id: routeId }
        this._routeService.employeeMeeting(parameter).subscribe(
            res => {
                this.defaultEmployeeMeeting = res.data;
                this.createMeetingRoot();
                this.setMeetingPoint();
            },
            error => {
                this.customErrorHandler.handleHttpError(error, 'employeeMeeting');
            });
    }
    meetingModalOpen() {
        this.meetingModal.style.display = "block";
    }
    meetingCloseModal() {
        this.errorMessage = '';
        this.meetingModal.style.display = "none";
    }
    openMeetingModal() {
        this.setMeetingPoint();
        this.meetingModalOpen();
    }
    onChange(id: string, isChecked: boolean) {
        let checkValue = this.meetingFormIds.includes(id);
        if (checkValue == false && isChecked == true) {
            this.meetingFormIds.push(id);
        } else if (checkValue == true && isChecked == false) {
            let index = this.meetingFormIds.indexOf(id);
            this.meetingFormIds.splice(index, 1);
        }
        this.meetingRouteLength = this.meetingFormIds.length;
    }
    createMeetingRoot() {
        this.directionsService = new google.maps.DirectionsService;
        this.directionsDisplay = new google.maps.DirectionsRenderer({
            suppressMarkers: true
        });
        this.map = new google.maps.Map(document.getElementById('map'), {
            zoom: 8,
            center: { lat: 22.7664707, lng: 75.8824308 },
            gestureHandling: 'cooperative',
            styles: Constants.MapStyle
        });
        this.directionsDisplay.setMap(this.map);
        this.deleteMarkers();
        this.employeeMeetingList = [];
        this.meetingCloseModal();
        for (let i = 0; i < this.meetingFormIds.length; i++) {
            let id = this.meetingFormIds[i];
            for (let j = 0; j < this.defaultEmployeeMeeting.length; j++) {
                if (id == this.defaultEmployeeMeeting[j]['id']) {
                    this.employeeMeetingList.push(this.defaultEmployeeMeeting[j]);
                }
            }
        }
        this.calculateAndDisplayRoute(this.directionsService, this.directionsDisplay);
    }
    setMeetingPoint() {
        for (let i = 0; i < this.meetingFormIds.length; i++) {
            $('#meeting-' + this.meetingFormIds[i]).prop('checked', true);
        }
        this.meetingRouteLength = $('[id^=meeting-]:checked').length;
    }
    addMeetingPlace() {
        let updateRouteId = localStorage.getItem('update_route_id');
        localStorage.setItem('mapProspectRouteLocationId', this.routeDetails.location_id);
        localStorage.setItem('mapProspectRouteEmpId', this.routeDetails.employee_id);
        localStorage.setItem('mapProspectRouteUpdateId', updateRouteId);
        this.router.navigate(["meeting/meeting-list/create-map-meeting"]);
    }
    ngOnDestroy() {
        localStorage.removeItem('meetingRouteUpdateId');
        localStorage.removeItem('meetingRouteEmpId');
    }    
}
