import { FollowUpsService } from './../../../services/followUps/followUps.service';
import { Component, OnInit } from '@angular/core';
import { CustomErrorHandler } from '../../../errorhandler/custom-errorhandler';
import { CommonService } from '../../../services/common/common.service';
import { Constants } from '../../../common/constants';
@Component({
  selector: 'follow-ups',
  templateUrl: './follow-ups.component.html',
  styleUrls: ['./follow-ups.component.css']
})
export class FollowUpsComponent implements OnInit {
  errormsg: string;
  msg: boolean;
  prospectList:any = [];
  dropdownSettings = {};
  selectionList = [];
  prospectLabel: string;
  employeeLabel: string;
  prospectName: any;
  prospect_id: Number;
  followUpsList = [];
  followUPFlag = true;
  serchText;
  reportingUserList: any = [];
  reportingSelectedItem: any = [];
  reportingSettings: any = {};
  locationList: any = [];
  locationSettings: any = {};
  locationSelectedItem: any = [];
  reportingTo: any = '';
  locationId: any = '';
  defaultSelectObject: any = { id: '', itemName: 'All' };
  noDataErrorMsg:String;
  filterLabel = Constants.filterLabel;
  page: number = 2;
  pageNo: number = 1;
  perPage: number = 10;
  totalItem: number = 0;
  loading: boolean;
  followUpMeetingLabel: any = '';

  constructor(
    private _followUps: FollowUpsService,
    private customErrorHandler: CustomErrorHandler,
    public commonService: CommonService
  ) { }
  ngOnInit() {
    this.noDataErrorMsg = 'Currently, there is no data available.';
    this.errormsg = 'Currently, there is no data available.';
    this.msg = true;
    this.prospectLabel = localStorage.getItem('prospectLabel');
    this.dropdownSettings = {
      singleSelection: true,
      text: "Select " + this.prospectLabel,
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      enableSearchFilter: true,
      classes: "myclass custom-class singleSelection block_elem m_b_15"
    };
    this.employeeLabel = localStorage.getItem('employeeLabel');
    this.getprospectList();
    this.getLocationList();
    this.getReportingUserList(this.locationId);
    // this.getFollowUpsListForProspect(1);
    this.reportingSettings = {
      singleSelection: true,
      text: "Select Reporting Person",
      enableSearchFilter: true,
      classes: "singleSelection block_elem m_b_15"
    };
    this.locationSettings = {
      singleSelection: true,
      text: "Select "+ Constants.locationLabel,
      enableSearchFilter: true,
      classes: "singleSelection block_elem m_b_15"
    }
  }
  getprospectList() {
     let parameter = {
      list_type: 'follow',
      location_id: this.locationId,
      reporting_to: this.reportingTo
    }
    this.commonService.getProspectList(parameter).subscribe(
      res => {
        if (res.result == true) {
          this.prospectList = res.data;
          if(this.prospectList.length > 0){
            this.selectionList = [];
            this.selectionList.push(this.prospectList[0]);
            this.prospect_id = this.prospectList[0].id;
            this.followUpMeetingLabel = this.prospectList[0].itemName;
            this.getFollowUpsListForProspect(1);
          }else{
              // this.errormsg = res.message;
              this.followUpsList = [];
          }
        } else {
          this.commonService.showErrorMessage(res);
          this.prospectList = [];
        }
      },
      error => {
        this.customErrorHandler.handleHttpError(error, 'prospectList');
      });
  }
  onProspectSelect() {
    let tem = this.prospectList.find(s => s.name == this.prospectName);
    this.prospect_id = tem.id;
    this.getFollowUpsListForProspect(1);
  }
  getFollowUpsListForProspect(currentPage) {
    this.loading = true;
    this.page = currentPage;
    let queryData = {
      // 'pageNo' : currentPage,
      'page' : currentPage,
      'per_page': this.perPage,
      'prospect_id': this.prospect_id || '',
      'search_text': this.serchText
    }
    this._followUps.getFollowUpsList(queryData).subscribe(
      res => {
        this.msg = false;
        if (res.result == true) {
          if (res.data.length == 0) {
            this.followUpsList = [];
            this.msg = true;
            this.errormsg = res.message;
          } else {
            this.followUpsList = res.data.data;
            this.totalItem = res.data.total;
            this.msg = false;
          }
          this.loading = false;
        } else {
          this.msg = true;
          this.errormsg = res.message;
          this.followUpsList = [];
          this.loading = false;
        }
      },
      error => {
        this.loading = false;
        this.customErrorHandler.handleHttpError(error, 'getFollowUpsList');
      });
  }
  onOpen(event: any) {
    this.prospectList = this.prospectList.map((item) => {
      return {
        id: item.id,
        itemName: item.itemName
      }
    });
  }
  onItemSelect(item: any) {
    this.prospect_id = item.id;
    this.followUpMeetingLabel = item.itemName;
    this.getFollowUpsListForProspect(1);
  }
  OnItemDeSelect(item: any) {
    this.prospect_id = new Number;
    this.errormsg = Constants.noDataFoundMessage;
    this.followUpsList = [];
    // this.getFollowUpsListForProspect(1);
  }
  onSelectAll(items: any) {
  }
  onDeSelectAll(items: any) {
  }

  getReportingUserList(locationId) {
    let currentUserId = localStorage.getItem('user_id');
    this.commonService.getReportingUserList(locationId).subscribe(res => {
      if (res.result == true) {
        this.reportingUserList = res.data;
        if (this.reportingUserList.length > 1) {
          this.reportingUserList.unshift(this.defaultSelectObject);
        }
      }
    }, err => {
      this.customErrorHandler.handleHttpError(err, 'getRoleList');
    });
  }
  getLocationList() {
    this.commonService.getLocationList().subscribe(res => {
      if (res.result == true) {
        this.locationList = res.data;
        if (this.locationList.length > 1) {
          this.locationList.unshift(this.defaultSelectObject);
        }
      }
    }, err => {
      this.customErrorHandler.handleHttpError(err, 'getRoleList');
    });
  }

  onUserItemSelect(item: any) {
    this.reportingTo = item.id;
    this.getprospectList();
  }
  onUserItemDeSelect(item: any) {
    this.reportingTo = '';
    this.getprospectList();
  }
  onLocationItemSelect(item: any) {
    this.locationId = item.id;
    this.reportingTo = '';
    this.reportingUserList = [];
    this.reportingSelectedItem = [];
    // if (this.locationId != '') {
      this.getReportingUserList(this.locationId);
    // }
    this.getprospectList();
  }
  onLocationItemDeSelect(item: any) {
    this.locationId = '';
    this.reportingTo = '';
    this.reportingUserList = [];
    this.reportingSelectedItem = [];
    this.getprospectList();
  }
}
