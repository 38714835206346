import { AuthGuard } from './../../services/_guards/auth.guard';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { CheckListComponent } from './check-list.component';
import { ChartsModule } from 'ng2-charts';
import { InfiniteScrollModule } from 'angular2-infinite-scroll';
import { ManageCheckListComponent } from './manage-check-list/manage-check-list.component';
import { Ng2PaginationModule } from 'ng2-pagination';

export const routes: Routes = [
  {
    path: 'checklist',
    component: CheckListComponent, canActivate: [AuthGuard],
  },
  {
    path: 'managechecklist',
    component: ManageCheckListComponent, canActivate: [AuthGuard],
  },
  {
    path: 'managechecklist/:id',
    component: ManageCheckListComponent, canActivate: [AuthGuard],
  }
];
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule.forChild(routes),
    ChartsModule,
    InfiniteScrollModule,
    Ng2PaginationModule
  ],
  declarations: [
    CheckListComponent,
    ManageCheckListComponent,
  ]
})

export class CheckListModule { }
