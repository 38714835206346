import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CustomErrorHandler } from '../../../errorhandler/custom-errorhandler';
import { Constants } from '../../../common/constants';
import { EventlistService } from './../../../services/event/eventList.service';
import { CommonService } from '../../../services/common/common.service';
declare var moment: any;
declare var $: any;
declare var google: any;
@Component({
	selector: 'create-event',
	templateUrl: 'create-event.component.html',
	styleUrls: ['./create-event.component.css']
})
export class CreateEventComponent implements OnInit {
	value: Date = new Date();
	eventDetails: any = {};
	map: any;
	lat: any;
	lng: any;
	name: any;
	formatted_address;
	area: any;
	drawGeofencing: any;
	drawGeofencingType: any;
	drawingManager: any;
	geofenceDraw: boolean = false;
	boundries: any = [];
	circleRadius: any;
	circleLat: any;
	circleLng: any;
	shapeType: any;
	geofenceRadius: any;
	geofenceList: any = [];
	geofenceRadiusSelectedItems: any = [];
	geofenceRadiusSettings = {};
	circle: any = [];
	startDateTimeMin: any;
	endDateTimeMin: any;
	startDateTimeMax: any;
	endDateTimeMax: any;
	startTime: any;
	endTime: any;
	update: Boolean = false;
	locationList: any = [];
	locationSelectedItem: any = [];
	locationSettings: any = {};
	locationId;
	locationLabel: any = Constants.locationLabel;
	constructor(
		private router: Router, 
		public _commonService: CommonService, 
		private customErrorHandler: CustomErrorHandler, 
		public _eventService: EventlistService
	) {}
	ngOnInit() {
		this.startDateTimeMin = new Date();
		this.endDateTimeMin = new Date();
		this.startTime = new Date();
		this.endTime = new Date();
		this.locationSettings = {
			singleSelection: true,
			text: "Select "+ Constants.locationLabel,
			enableSearchFilter: true,
			classes: "singleSelection"
		};
		this.getLocationList();
		this.geofenceRadiusSettings = {
			singleSelection: true,
			text: "Select Geofence radius",
			selectAllText: 'Select All',
			unSelectAllText: 'UnSelect All',
			enableSearchFilter: true,
			classes: "singleSelection"
		};
		this.getRadiusList();
		this.geofenceRadiusSelectedItems = [];
		this.geofenceRadius = Constants.GeofenceRadius;
		this.drawGeofencingType = 0;
		this.map = new google.maps.Map(document.getElementById('map'), {
			zoom: 16,
			center: { lat: 22.7664707, lng: 75.8824308 },
			gestureHandling: 'cooperative',
			styles: Constants.MapStyle
		});
		this.loadAutoComple();
		$('.fullHeightBlock').scroll(function () {
			let spTop = $('#location').offset().top + 35;
			$('.pac-container').css('top', spTop);
		});
	}

	getLocationList() {
		this._commonService.getLocationList().subscribe(res => {
			if (res.result == true) {
				this.locationList = res.data;
				console.log(this.locationList);
			}
		}, error => {
			this.customErrorHandler.handleHttpError(error, 'getRoleList');
		});
	}
	onLocationItemSelect(item: any) {
		this.locationId = item.id;
	}
	onLocationItemDeSelect(item: any) {
		this.locationId = '';
	}
	getEventById(eventId) {
		this._eventService.SelectEventById(eventId).subscribe(
			res => {
				if (res.result == true) {
					this.eventDetails = res.data;
					let start = this.eventDetails.start_date.replace(/-/g, '/');
					let end = this.eventDetails.end_date.replace(/-/g, '/');

					let lactionTemp = this.locationList.find(s => s.id == this.eventDetails.location_id);
					this.locationSelectedItem = [];
					if (typeof lactionTemp != 'undefined') {
						this.locationSelectedItem.push(lactionTemp);
					}
					console.log(this.locationSelectedItem);
					this.eventDetails.start_date = new Date(start);
					this.eventDetails.end_date = new Date(end);
					this.startTime = new Date(start);
					this.endTime = new Date(end);
					let temp = this.geofenceList.find(s => s.itemName == this.eventDetails.geofence);
					this.geofenceRadiusSelectedItems = [];
					if (typeof temp != 'undefined') {
						this.geofenceRadiusSelectedItems.push(temp);
					}


					if (this.eventDetails.geofence_active == '1') {
						this.eventDetails.active_geofence = true;
					} else {
						this.eventDetails.active_geofence = false;
					}
					this.eventDetails.lat = parseFloat(this.eventDetails.lat);
					this.eventDetails.lng = parseFloat(this.eventDetails.lng);
					this.lat = this.eventDetails.lat;
					this.lng = this.eventDetails.lng;
					this.setGeofence();
				} else {
					this._commonService.showCustomError(res.message);
				}
			},
			error => {
				this.customErrorHandler.handleHttpError(error, 'createEvent');
			},
		);
	}
	loadAutoComple() {
		var map1 = this.map;
		var input = document.getElementById('location');
		var autocomplete = new google.maps.places.Autocomplete(input);
		// Set initial restrict to the greater list of countries.
		autocomplete.setComponentRestrictions();
		autocomplete.bindTo('bounds', map1);
		var infowindow = new google.maps.InfoWindow();
		var infowindowContent = document.getElementById('infowindow-content');
		infowindow.setContent(infowindowContent);
		var marker = new google.maps.Marker({
			map: map1,
			anchorPoint: new google.maps.Point(0, -29)
		});
		var _this = this;
		autocomplete.addListener('place_changed', function () {
			infowindow.close();
			marker.setVisible(false);
			var place = autocomplete.getPlace();
			if (!place.geometry) {
				this.commonService.showCustomError("No details available for input: '" + place.name + "'");
				return;
			}
			_this.eventDetails.location = place.formatted_address;
			_this.lat = place.geometry.location.lat();
			_this.lng = place.geometry.location.lng();
			_this.circle = [
				{ lat: _this.lat, lng: _this.lng, radius: 10, color: 'red' }
			];
			if (place.address_components.length > 1) {
				_this.eventDetails.area = place.address_components[1].long_name;
			} else {
				_this.eventDetails.area = place.address_components[0].long_name;
			}
			_this.setGeofence();
		});
	}
	getRadiusList() {
		this._eventService.getRadiusList().subscribe(res => {
			if (res.result == true) {
				this.geofenceList = res.data;
				let eventId = localStorage.getItem('editEventId');
				if (eventId) {
					this.update = true;
					this.getEventById(eventId);
				}
				else {
					this.update = false;
				}
			}
		}, error => {
			this.customErrorHandler.handleHttpError(error, 'getRadiusList');
		});
	}

	createEvent() {
		let startDate = this.eventDetails.start_date;
		this.eventDetails.start_date = moment(startDate).format('YYYY-MM-DD') + ' ' + moment(this.startTime).format('hh:mm A');
		let endDate = this.eventDetails.end_date
		this.eventDetails.end_date = moment(endDate).format('YYYY-MM-DD') + ' ' + moment(this.endTime).format('hh:mm A');
		this.eventDetails.geofence = this.geofenceRadiusSelectedItems[0].itemName;
		this.eventDetails.lat = this.lat;
		this.eventDetails.lng = this.lng;
		this.eventDetails.location_id = this.locationSelectedItem.map(function (elem) { return elem.id; }).join(",");
		let eventId = localStorage.getItem('editEventId');
		if (eventId) {
			this._eventService.editEvent(this.eventDetails).subscribe(
				res => {
					if (res.result == true) {
						this._commonService.showSuccessMessage(res.message);
						this.router.navigate(["event/event-list"]);
					} else {
						this._commonService.showCustomError(res.message);
					}
				},
				error => {
					this.customErrorHandler.handleHttpError(error, 'createEvent');
				}
			)
		} else {
			this._eventService.createEvent(this.eventDetails).subscribe(
				res => {
					if (res.result == true) {
						this._commonService.showSuccessMessage(res.message);
						this.router.navigate(["event/event-list"]);
					} else {
						this._commonService.showCustomError(res.message);
					}
				},
				error => {
					this.customErrorHandler.handleHttpError(error, 'createEvent');
				}
			)
		}
	}
	onItemGeofenceradius(item: any) {
		this.setGeofence();
	}
	setGeofence() {
		if (typeof this.lat != 'undefined' && typeof this.lng != 'undefined') {
			var _this = this;
			this.map = new google.maps.Map(document.getElementById('map'), {
				zoom: 18,
				center: { lat: _this.lat, lng: _this.lng },
				styles: Constants.MapStyle
			});
			if (typeof this.geofenceRadiusSelectedItems[0].itemName != 'undefined') {
				var circle = new google.maps.Circle({
					map: _this.map,
					radius: _this.geofenceRadiusSelectedItems[0].itemName,    // 10 miles in metres
					fillColor: '#262525',
					center: { lat: _this.lat, lng: _this.lng },
				});
			}
		}
	}
	selectEndEventDate(endDate) {
		this.startDateTimeMax = endDate;
	}
	selectStartEventDate(startDate) {
		this.endDateTimeMin = startDate;
	}
	changedTime(time) {
		if (this.endTime < this.eventDetails.start_date && moment(this.eventDetails.start_date).format("DD-MM-YYYY") !== moment().format("DD-MM-YYYY")) {
			this.endTime = new Date(this.eventDetails.start_date.getTime() + 1 * 60000)
		} else {
			this.endTime = new Date(this.eventDetails.end_date);
			this.endTime = time;
		}
	};
	changedStartTime(time) {
		this.endDateTimeMin = this.eventDetails.start_date;
		if (this.eventDetails.start_date.getTime() > time.getTime()) {
			this.startTime = new Date(this.eventDetails.start_date);
		} else if (moment(this.eventDetails.start_date).format("DD-MM-YYYY") == moment(this.eventDetails.end_date).format("DD-MM-YYYY")) {
			this.endTime = new Date(time.getTime() + 1 * 60000)
		}
		if (time > this.eventDetails.end_date) {
			this.eventDetails.end_date = time;
		}
	}
	ngOnDestroy() {
		localStorage.removeItem('editEventId');
	}
}
