import { AuthGuard } from './../../services/_guards/auth.guard';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown/angular2-multiselect-dropdown';
import { InfiniteScrollModule } from 'angular2-infinite-scroll';
import { ChartsModule } from 'ng2-charts';
import { Ng2PaginationModule } from 'ng2-pagination';
import { PipeModule } from '../../pipe/pipe.module';
import { CalendarModule } from 'primeng/calendar';
import { PollManagementComponent } from './poll-management.component';
import { PollListComponent } from './poll-list/poll-list.component';
import { CreatePollComponent } from './create-poll/create-poll.component';
import { PollAssignedListComponent } from './poll-assigned-list/poll-assigned-list.component';
import { AssignedPollComponent } from './assigned-poll/assigned-poll.component';
export const routes: Routes = [
  {
    path: 'poll',
    component: PollManagementComponent, canActivate: [AuthGuard],
    children: [
      { path: '', redirectTo: 'poll-list', pathMatch: 'full' },
      { path: 'poll-list', component: PollListComponent },
      { path: 'create-poll', component: CreatePollComponent },
      // { path: 'create-poll/:id', component: CreatePollComponent },
      { path: 'assigned-poll-list', component: PollAssignedListComponent },
      { path: 'assigned-poll', component: AssignedPollComponent }
    ]
  }
];
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule.forChild(routes),
    AngularMultiSelectModule,
    InfiniteScrollModule,
    PipeModule.forRoot(),
    CalendarModule,
    Ng2PaginationModule,
    ChartsModule
  ],
  declarations: [
    PollManagementComponent,
    PollListComponent,
    CreatePollComponent,
    PollAssignedListComponent,
    AssignedPollComponent
  ]
})
export class PollModule { }
