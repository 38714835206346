import { EmployeeListService } from './../../../services/employeelist/employeeList.service';
import { Config } from './../../../config/config';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { HomeSharedService } from '../../../services/shared/home-shared';
import { CustomErrorHandler } from '../../../errorhandler/custom-errorhandler';
import { Constants } from './../../../common/constants';
import { CommonService } from '../../../services/common/common.service';
import { BaseChartDirective } from 'ng2-charts';
import { AngularFireDatabase } from 'angularfire2/database';
import { VoterService } from './../../../services/voter/voter.service';
declare var $: any;
declare var google: any;
declare var OverlappingMarkerSpiderfier: any;
@Component({
  selector: 'map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.css']
})

export class MapComponent implements OnInit {
  @ViewChild(BaseChartDirective) chart: BaseChartDirective;
  imageBaseUrl: string = Config.AuthenticationURL;
  employeeLabel: string;
  home: any[];
  graphdata: any[];
  map: any;
  loading: boolean;
  filter: string;
  search: string;
  start_date: string;
  end_date: string;
  errormsg: string;
  msg: boolean;
  interVal: any;
  newMarkerArray: any = [];
  markers: any = [];
  start: number;
  pagechk: boolean;
  empDetailList: any = [];
  mapStatus: any;
  mapLastUpdate: any;
  companyId: any;
  doughnutChartData: number[] = [0, 0, 0, 0, 0, 0, 0];
  employeeListIds: any = [];
  employeeListIdsNotAvailable: any = [];
  oms: any;
  infowindow: any;
  geofenceCircle: any = [];
  geofenceRectangle: any = [];
  geofencePoligon: any = [];
  geofenceButton: boolean = false;
  voterList: any;
  voterGeofenceList: any = [];
  directionsService: any;
  directionsDisplay: any;
  allEmployeeIds: any = [];
  startActualTime: any;
  endActualTime: any;
  count: number = 0;
  reportingTo: any = '';
  locationId: any = '';
  constructor(
    private router: Router,
    public _homeSharedService: HomeSharedService,
    private commonService: CommonService,
    public _homeService: EmployeeListService,
    private customErrorHandler: CustomErrorHandler,
    private _voterService: VoterService,
    db: AngularFireDatabase
  ) {
    this._homeSharedService.homeDate$.subscribe(data => {
      this.loading = true;
      this.end_date = data.end_date;
      this.start_date = data.start_date;
      this.filter = data.filter;
      if (typeof data.search != 'undefined') {
        this.search = data.search;
      } else {
        this.search = '';
      }
      this.home = [];
      this.reportingTo = data.reporting_to;
      this.locationId = data.location_id;
      this.homeList();
    });
    this.companyId = localStorage.getItem('company_id');
    db.list('/location-tracking').valueChanges().subscribe(location => {
      this.empDetailList = [];
      this.empDetailList = location;
      this.oms = new OverlappingMarkerSpiderfier(this.map, {
        markersWontMove: true,
        markersWontHide: true,
        basicFormatEvents: true,
        spiralFootSeparation: 40,
        spiralLengthStart: 15,
        spiralLengthFactor: 10,
        legWeight: 0,
        keepSpiderfied: true,
        nudgeStackedMarkers: false
      });
      this.infowindow = new google.maps.InfoWindow();
      this.employeeListIdsNotAvailable = Object.assign([], this.employeeListIds);
      if (this.empDetailList.length > 0 && this.employeeListIds.length > 0) {
        this.count = 1;
        for (var i = 0; i < this.empDetailList.length; i++) {
          if (typeof this.empDetailList[i].user_id != 'undefined' && Number(this.empDetailList[i]['company_id']) == Number(this.companyId) && this.employeeListIds.includes(Number(this.empDetailList[i].user_id)) == true) {
            this.removeSpecificMarker(this.empDetailList[i].latitude, this.empDetailList[i].longitude, this.empDetailList[i]['user_id']);
            let index = this.employeeListIdsNotAvailable.indexOf(Number(this.empDetailList[i].user_id));
            this.mapStatus = '';
            if (index > -1) {
              this.employeeListIdsNotAvailable.splice(index, 1);
            }
            this.mapStatus = this.empDetailList[i]['employee_status'];
            this.mapLastUpdate = this.dateDifferenceInMinutes(this.empDetailList[i].created_at);
            this.addMarker(this.empDetailList[i].user_name, this.empDetailList[i].latitude, this.empDetailList[i].longitude, this.empDetailList[i].profile_pic, this.mapLastUpdate, this.empDetailList[i]['user_id'], this.mapStatus, this.empDetailList[i]['contact_no']);
          }
        }
        var myoverlay = new google.maps.OverlayView();
        myoverlay.draw = function () {
          this.getPanes().markerLayer.id = 'markerLayer';
        };
        myoverlay.setMap(this.map);
      }
      if (this.home.length > 0) {
        for (let i = 0; i < this.home.length; i++) {
          if (this.employeeListIdsNotAvailable.includes(Number(this.home[i].id)) == true) {
            this.mapStatus = this.home[i]['current_status'];
            this.mapLastUpdate = this.home[i].last_update;
            let imgUrl = '';
            if (this.home[i].profile_pic) {
              imgUrl = this.imageBaseUrl + '' + this.home[i].profile_pic;
            }
            let contactNo = '';
            if (typeof this.home[i]['mobile_no'] != 'undefined') {
              contactNo = this.home[i]['mobile_no'];
            }
            this.addMarker(this.home[i].first_name, Number(this.home[i].lat), Number(this.home[i].lng), imgUrl, this.mapLastUpdate, this.home[i]['id'], this.mapStatus, contactNo);
          }
        }
        var myoverlay = new google.maps.OverlayView();
        myoverlay.draw = function () {
          this.getPanes().markerLayer.id = 'markerLayer';
        };
        myoverlay.setMap(this.map);
      }
    });
  }
  gotoDetail(track_emp_id, emp_name, emp_profile_pic) {
    localStorage.setItem('emp_name', emp_name);
    localStorage.setItem('emp_profile_pic', emp_profile_pic);
    localStorage.setItem('track_emp_id', track_emp_id);
    this.router.navigate(["detailmap"]);
  }
  ngOnInit() {
    this.voterList = localStorage.getItem('VoterList');
    this.employeeLabel = localStorage.getItem('employeeLabel');
    this.loading = true;
    this.search = '';
    this.filter = '';
    this.start_date = '';
    this.end_date = '';
    this.errormsg = '';
    this.msg = false;
    this.home = [];
    this.homeList();
    this.graphData();
    $('.listdiv0').scrollbar();
    this.map = new google.maps.Map(document.getElementById('map'), {
      center: { lat: 22.7196, lng: 75.8577 },
      zoom: 11,
      styles: Constants.MapStyle
    });
  }
  // Doughnut
  public doughnutChartLabels: string[] = ['Check in', 'Completed', 'Interested', 'Not interested', 'Follow up', 'Not login', 'Login'];
  public doughnutChartType: string = 'doughnut';
  public barChartOptions: any = {
    animation: false,
    responsive: true,
    maintainAspectRatio: false,
    legend: {
      display: false,
      position: 'bottom',
      onClick: (e) => e.stopPropagation()
    }
  }
  // events
  public chartClicked(e: any): void {
  }
  public chartHovered(e: any): void {
  }
  graphData() {
    this.commonService.doughnutChartData().subscribe(
      res => {
        if (res.success == true) {
          this.doughnutChartData = res.data;
          this.doughnutChartLabels = res.label;
          setTimeout(() => {
            if (this.chart && this.chart.chart && this.chart.chart.config) {
              this.chart.chart.config.data.labels = this.doughnutChartLabels;
              this.chart.chart.update();
            }
          });
        }
      },
      error => {
        this.customErrorHandler.handleHttpError(error, 'graphData');
      });
  }
  homeList() {
    this._homeService.getEmployeeList(0, 0, this.search, '', this.filter, this.reportingTo, this.locationId).subscribe(
      res => {
        this.count = 0;
        this.loading = false;
        if (res.result == true) {
          this.home = res.data.data;
          this.errormsg = res.message;
          this.employeeListIds = [];
          for (let i = 0; i < this.home.length; i++) {
            this.employeeListIds.push(this.home[i].id);
          }
          this.employeeListIdsNotAvailable = Object.assign([], this.employeeListIds);//this.employeeListIds;
          /***  map setting Start ***/
          this.clearMarkers();
          if (this.empDetailList.length > 0 && this.employeeListIds.length > 0) {
            for (var i = 0; i < this.empDetailList.length; i++) {
              if (typeof this.empDetailList[i].user_id != 'undefined' && this.empDetailList[i]['company_id'] == this.companyId && this.employeeListIds.includes(Number(this.empDetailList[i].user_id)) == true) {
                let index = this.employeeListIdsNotAvailable.indexOf(Number(this.empDetailList[i].user_id));
                this.mapStatus = '';
                if (index > -1) {
                  this.employeeListIdsNotAvailable.splice(index, 1);
                }
                this.mapStatus = this.empDetailList[i]['employee_status'];
                this.mapLastUpdate = this.dateDifferenceInMinutes(this.empDetailList[i].created_at);
                this.addMarker(this.empDetailList[i].user_name, this.empDetailList[i].latitude, this.empDetailList[i].longitude, this.empDetailList[i].profile_pic, this.mapLastUpdate, this.empDetailList[i]['user_id'], this.mapStatus, this.empDetailList[i]['contact_no']);
              }
            }
            var myoverlay = new google.maps.OverlayView();
            myoverlay.draw = function () {
              this.getPanes().markerLayer.id = 'markerLayer';
            };
            myoverlay.setMap(this.map);
          }
          if (this.home.length > 0) {
            for (let i = 0; i < this.home.length; i++) {
              if (this.employeeListIdsNotAvailable.includes(Number(this.home[i].id)) == true) {
                this.mapStatus = this.home[i]['current_status'];
                this.mapLastUpdate = this.home[i].last_update;
                let imgUrl = '';
                if (this.home[i].profile_pic) {
                  imgUrl = this.imageBaseUrl + '' + this.home[i].profile_pic;
                }
                let contactNo = '';
                if (typeof this.home[i]['mobile_no'] != 'undefined') {
                  contactNo = this.home[i]['mobile_no'];
                }
                this.addMarker(this.home[i].first_name, Number(this.home[i].lat), Number(this.home[i].lng), imgUrl, this.mapLastUpdate, this.home[i]['id'], this.mapStatus, contactNo);
              }
            }
            var myoverlay = new google.maps.OverlayView();
            myoverlay.draw = function () {
              this.getPanes().markerLayer.id = 'markerLayer';
            };
            myoverlay.setMap(this.map);
          }
        } else {
          this.home = [];
          this.msg = true;
          this.errormsg = res.message;
          this.commonService.showErrorMessage(res);
        }
      },
      error => {
        this.loading = false;
        this.customErrorHandler.handleHttpError(error, 'homeList');
      }
    )
  }
  addMarker(name, lat, long, profile_pic, last_update, employeeId, status, contact) {
    if (contact == 'undefined') {
      contact = '';
    }
    if (profile_pic == "" || typeof profile_pic == 'undefined') {
      profile_pic = "assets/images/defualt-user-image-new.png";
    }
    if (typeof last_update == 'undefined') {
      last_update = '';
    }
    if (typeof status == 'undefined') {
      status = '...';
    }
    if (typeof contact == 'undefined') {
      contact = '...';
    }
    var icon = {
      url: profile_pic, // url
      scaledSize: new google.maps.Size(50, 50), // scaled size
      origin: new google.maps.Point(0, 0), // origin
      anchor: new google.maps.Point(0, 0), // anchor
      shape: { coords: [17, 17, 18], type: 'circle' }
    };
    var latlngset = new google.maps.LatLng(lat, long);
    var marker = new google.maps.Marker({
      map: this.map,
      title: name,
      position: latlngset,
      icon: icon,
      emp_id: employeeId
    });
    this.newMarkerArray.push(marker);
    if (this.count != 1) {
      this.map.setCenter(marker.getPosition())
    }
    var content = "<div style='width:50px;float:left'><img class='img_width'  src=" + profile_pic + "></div>";
    content += "<div style='float:right'>";
    content += "<b style='font-size: 16px'>" + name + "</b>";
    content += "<p>Last updated : " + last_update + "</p>";
    content += "</div><hr style='width: 98%;border: 1px solid #ddd;'>";
    content += "<div>";
    content += "<div style='width: 50%;float:left;font-size:14px;'><span>Status</span><br><p style='font-weight: bold;'>" + status + "</p></div>";
    content += "<div style='width: 50%;float:left;font-size:14px;'>";
    if (contact) {
      content += "<span>Contact No.</span><br><p style='font-weight: bold;'>" + contact + "</p>";
    }
    contact += "</div>";
    content += "</div>";
    content += "<br><br><div style='width: 100%;text-align:center;margin-top:20px;'><a id='playbackTracking' class='btn btn-default'>Playback</a></div>";
    var infowindow = this.infowindow;
    var router = this.router;
    google.maps.event.addListener(marker, 'click', (function (marker, content, infowindow) {
      return function () {
        infowindow.close();
        infowindow.setContent(content);
        infowindow.open(this.map, marker);
        document.getElementById("playbackTracking").addEventListener("click", function () {
          router.navigate(["detailmap/" + employeeId]);
        });
      };
    })(marker, content, infowindow));
    this.oms.addMarker(marker);
  }
  clearMarkers() {
    for (let i = 0; i < this.newMarkerArray.length; i++) {
      this.newMarkerArray[i].setMap(null);
    }
  }
  getVoterGeofenceList() {
    this.voterGeofenceList = [];
    this.directionsService = new google.maps.DirectionsService;
    this.directionsDisplay = new google.maps.DirectionsRenderer({
      suppressMarkers: true
    });
    this._voterService.getVoterGeofenceList().subscribe(
      res => {
        this.loading = false;
        if (res.result == true) {
          this.voterGeofenceList = res.data;
          var this1 = this;
          for (let i = 0; i < this.voterGeofenceList.length; i++) {
            let geoData = this.voterGeofenceList[i].geofence_data;
            let geofenceColor = ['#0048BA', '#915C83', '#FF91AF', '#BCD4E6', '#9C2542']
            let color = geofenceColor[Math.floor(Math.random() * geofenceColor.length)]
            if (geoData.type == 1) {
              let latLng = new google.maps.LatLng(geoData.centered_lat, geoData.centered_lng);
              let circle = new google.maps.Circle({
                strokeColor: color,
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: color,
                fillOpacity: 0.35,
                map: this1.map,
                center: latLng,
                radius: geoData.radius
              });
              this.geofenceCircle.push(circle);
            }
            if (geoData.type == 2) {
              let rectangleBoundries = geoData.boundries;
              var rectangle = new google.maps.Rectangle({
                strokeColor: color,
                strokeOpacity: 1,
                strokeWeight: 2,
                fillColor: color,
                fillOpacity: 0.35,
                map: this1.map,
                bounds: rectangleBoundries
              });
              this.geofenceRectangle.push(rectangle);
            }
            if (geoData.type == 3) {
              let polygonBoundries = geoData.boundries;
              var bermudaTriangle = new google.maps.Polygon({
                paths: polygonBoundries,
                strokeColor: color,
                strokeOpacity: 0.8,
                strokeWeight: 3,
                fillColor: color,
                fillOpacity: 0.35
              });
              bermudaTriangle.setMap(this.map);
              this.geofencePoligon.push(bermudaTriangle);
            }
          }
        } else {
          this.commonService.showErrorMessage(res);
        }
      },
      error => {
        this.loading = false;
        this.customErrorHandler.handleHttpError(error, 'actionList');
      }
    )
  }
  geofecneSetting() {
    var this1 = this;
    setTimeout(function () {
      if (this1.geofenceButton == true) {
        this1.getVoterGeofenceList();
      }
      if (this1.geofenceButton == false) {
        for (let i = 0; i < this1.geofenceCircle.length; i++) {
          this1.geofenceCircle[i].setMap(null);
        }
        for (let i = 0; i < this1.geofenceRectangle.length; i++) {
          this1.geofenceRectangle[i].setMap(null);
        }
        for (let i = 0; i < this1.geofencePoligon.length; i++) {
          this1.geofencePoligon[i].setMap(null);
        }
      }
    }, 500);
  }
  removeSpecificMarker(lat, lng, empId) {
    for (let i = 0; i < this.newMarkerArray.length; i++) {
      if ((empId == this.newMarkerArray[i].emp_id) && (lat != this.newMarkerArray[i].position.lng())) {
        console.log('change hua', this.newMarkerArray[i].title, lat, lng)
        this.newMarkerArray[i].setMap(null);
      }
    }
  }
  dateDifferenceInMinutes(startDate) {
    this.startActualTime = new Date(startDate);
    this.endActualTime = new Date();
    let diff = this.endActualTime - this.startActualTime;
    let timeMin = (diff / 1000) / 60;
    let format = Math.floor(timeMin / 60) + ' hr' + Math.floor(timeMin % 60) + ' min';
    return format;
  }
}
