import { AuthGuard } from './../../services/_guards/auth.guard';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { InfiniteScrollModule } from 'angular2-infinite-scroll';
import { ExpenseComponent } from './expense.component';
import { Ng2PaginationModule } from 'ng2-pagination';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown/angular2-multiselect-dropdown';
export const routes: Routes = [
  {
    path: 'expense',
    component: ExpenseComponent, canActivate: [AuthGuard]
  }
];
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule.forChild(routes),
    InfiniteScrollModule,
    Ng2PaginationModule,
    AngularMultiSelectModule
  ],
  declarations: [
    ExpenseComponent
  ]
})
export class ExpenseModule { }
