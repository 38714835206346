import { Injectable } from "@angular/core";
import { HttpHelper } from "../../helpers/http.helper";
import { Config } from "../../config/config";
@Injectable()
export class NotesService {
    constructor(private _httpHelper: HttpHelper) {
    }
    public getNotesListByProspectId(req) {
        try {
            // var request = {
            //     "prospect_id": prospectId || '', "search_text": ""
            // };
            return this._httpHelper.post(Config.AuthenticationBaseURL1 + 'getNotesById', req)
        }
        catch (e) {
        }
    }
}
