import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { EmployeeService } from '../../../services/employee/employee.service';
import { ComplaintService } from './../../../services/complaint/complaint.service';
import { Constants } from '../../../common/constants';
import { Angular2Csv } from 'angular2-csv/Angular2-csv';
import { CustomErrorHandler } from './../../../errorhandler/custom-errorhandler';
import { Config } from '../../../config/config';
import swal from 'sweetalert2';
import { CommonService } from '../../../services/common/common.service';
import { Role_managementService } from '../../../services/role_management/role_management.service';

@Component({
  selector: 'complaint',
  templateUrl: 'complaint.component.html',
  styleUrls: ['./complaint.component.css']
})
export class ComplaintComponent implements OnInit {
  userId: string;
  isExport: boolean;
  isDemoTenant: boolean;
  actionLable: string;
  employeeLabel: string;
  company_id: string;
  loading: boolean;
  imageBaseUrl: string = Config.AuthenticationURL;
  noDataMsg: string;
  tempSearch: any;
  message: any;
  pageNo: number = 1;
  perPage: number = 10;
  totalItem: number = 0;
  page: number = 2;
  exportReport: any;
  complaintList: any = [];
  serchText: any;
  employeeMemberList = [];
  employeeMemberSettings = {};
  employeeMemberItem = [];
  locationList: any = [];
  locationId: any = ''
  locationSettings: any = {};
  locationSelectedItem: any = [];

  status: any = [];
  roleId: any = ''
  roleSettings: any = {};
  roleSelectedItem: any = [];
  newstatus : string;
  defaultSelectObject: any = { id: '', itemName: 'All' }
  filterLabel = Constants.filterLabel;
  status_filter : string;
  search_input : string;
   modal: any;
  constructor(
    private router: Router,
    public _employeeService: EmployeeService,
    private customErrorHandler: CustomErrorHandler,
    public commonService: CommonService,
    public roleManagementService: Role_managementService,
    public _ComplaintService : ComplaintService
  ) { }
  ngOnInit() {
    this.userId = localStorage.getItem('user_id');
    this.serchText = '';
    this.tempSearch = '';
    this.exportReport = '';
    this.isExport = false;
    this.isDemoTenant = (localStorage.getItem('isDemoTenant') == 'true');
    this.employeeLabel = localStorage.getItem('employeeLabel');
    this.actionLable = localStorage.getItem('actionLabel');
    this.loading = true;
    this.getComplaintList(1);
    this.modal = document.getElementById('uploadModel');
    this.employeeMemberSettings = {
      singleSelection: true,
      text: "Select Reporting Person",
      enableSearchFilter: true,
      classes: "singleSelection block_elem m_b_15"
    };
    this.locationSettings = {
      singleSelection: true,
      text: "Select "+ Constants.locationLabel,
      enableSearchFilter: true,
      classes: "singleSelection block_elem m_b_15"
    };

    this.roleSettings = {
      singleSelection: true,
      text: "Select Role",
      enableSearchFilter: true,
      classes: "singleSelection block_elem m_b_15"
    };
    this.status_filter = 'Select Status';
    this.newstatus = 'Select Status';
  }
  complaintEdit(id) {
    localStorage.setItem('complaint_id', id);
    this.router.navigate(["/setting/complaint/edit"]);
  }
  getComplaintList(currentPage) {
    this.pageNo = currentPage;
    this.page = currentPage;
    this.message = '';
    this.complaintList = [];
    this.exportReport = '';
    let reportingUserId = '';
    let locationId = '';
    if (this.employeeMemberItem.length > 0) {
      reportingUserId = this.employeeMemberItem[0].id;
    }
    if (this.locationSelectedItem.length > 0) {
      locationId = this.locationSelectedItem[0].id;
    }
    this._ComplaintService.getComplaintList(this.pageNo, this.perPage, this.exportReport, this.serchText, reportingUserId, locationId, this.roleId).subscribe(
      res => {
        this.loading = false;
        if (res.result == true) {
          this.totalItem = res.data.total;
          this.complaintList = res.data.data;
          this.message = res.message;
        } else {
          this.totalItem = 0;
          this.complaintList = [];
          this.message = res.message;
          this.commonService.showErrorMessage(res);
        }
      },
      error => {
        this.loading = false;
        this.customErrorHandler.handleHttpError(error, 'complaintList');
      });
  }
  onSearch(event) {
    this.serchText = event.target.value;
    if (this.tempSearch != this.serchText) {
      this.getComplaintList(1);
    }
    this.tempSearch = this.serchText;
  }
  complaintDelete(value) {
    let deleteMsg = Constants.DeleteMsg;
    swal({
      text: deleteMsg,
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Delete',
    }).then((result) => {
      if (typeof result.value != 'undefined') {
        this._ComplaintService.complaintDelete(value).subscribe(
          res => {
            if (res.result == true) {
              this.commonService.showSuccessMessage(res.message);
              this.getComplaintList(1);
            }
            else {
              this.commonService.showErrorMessage(res);
            }
          },
          error => {
            this.customErrorHandler.handleHttpError(error, 'employeeDelete');
          });
      }
    })
  }
  createComplaint() {
    localStorage.setItem('createComplaint', 'Yes');
    localStorage.setItem('complaint_id', '');
    this.router.navigate(['/setting/complaint/edit']);
  }
  export() {
    var options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: true,
      useBom: true,
      title:  'Complaint List'
    };
    this.exportReport = 'yes';
    let reportingUserId = '';
    let locationId = '';
    if (this.employeeMemberItem.length > 0) {
      reportingUserId = this.employeeMemberItem[0].id;
    }
    if (this.locationSelectedItem.length > 0) {
      locationId = this.locationSelectedItem[0].id;
    }
    this._ComplaintService.getComplaintList(this.pageNo, this.perPage, this.exportReport, this.serchText, reportingUserId, locationId, this.roleId).subscribe(
      res => {
        new Angular2Csv(res.data, 'Complaint List', options);
      },
      error => {
        this.customErrorHandler.handleHttpError(error, 'complaintList');
      });
  }
  
  
  onItemSelect() {
    
    this.pageNo = 1;
    this.page = 1;
    this.message = '';
    this.complaintList = [];
    this.exportReport = '';
    let reportingUserId = '';
    let locationId = '';
    if (this.employeeMemberItem.length > 0) {
      reportingUserId = this.employeeMemberItem[0].id;
    }
    if (this.locationSelectedItem.length > 0) {
      locationId = this.locationSelectedItem[0].id;
    }
    if(this.search_input != '' && this.search_input != undefined)
    {
      this.serchText = this.search_input;
      this.status_filter = '';
    }

    this._ComplaintService.getComplaintListWithFilter(this.pageNo, this.perPage, this.exportReport, this.serchText, reportingUserId, locationId, this.roleId,this.status_filter).subscribe(
      res => {
        this.loading = false;
        if (res.result == true) {
          this.totalItem = res.data.total;
          this.complaintList = res.data.data;
          this.message = res.message;
        } else {
          this.totalItem = 0;
          this.complaintList = [];
          this.message = res.message;
          this.commonService.showErrorMessage(res);
        }
      },
      error => {
        this.loading = false;
        this.customErrorHandler.handleHttpError(error, 'complaintList');
      });

  }
  OnItemDeSelect(item: any) {
    this.locationId = ''
    this.employeeMemberList = [];
    this.employeeMemberItem = [];
    this.getComplaintList(1);
  }
  onReportingSelected(item: any) {
    this.getComplaintList(1);
  }
  onReportingDeselected(item: any) {
    this.getComplaintList(1);
  }

  onRoleItemSelect(item: any){
    this.roleId = item.id;
    this.getComplaintList(1);
  }

  OnRoleItemDeSelect(item: any){
    this.roleId = '';
    this.getComplaintList(1);
  }

  openModal(complaint_id) {
    localStorage.setItem('complaint_id_status', complaint_id);
    this.modal.style.display = "block";
  }
  closeModal() {
    localStorage.setItem('complaint_id_status', '');
    this.modal.style.display = "none";
  }

  changeComplaintStatus() {
    let complaint_id = localStorage.getItem('complaint_id_status');
    this._ComplaintService.complaintStatusChange(complaint_id,this.newstatus).subscribe(
      res => {
        if (res.result == true) {
          this.commonService.showSuccessMessage(res.message);
          this.modal.style.display = "none";
          this.getComplaintList(1);
        }
        else {
          this.commonService.showErrorMessage(res);
        }
      },
      error => {
        this.customErrorHandler.handleHttpError(error, 'employeeDelete');
      });

  }
}

