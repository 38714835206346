import { MeetingService } from './../../../services/meeting/meeting.service';
import { Component, OnInit } from '@angular/core';
import { AssignService } from '../../../services/assign/assign.service';
import { ReportService } from '../../../services/report/report.service';
import { Angular2Csv } from 'angular2-csv/Angular2-csv';
import { CustomErrorHandler } from '../../../errorhandler/custom-errorhandler';
import { Config } from './../../../config/config';
import { Constants } from '../../../common/constants';
import { CommonService } from '../../../services/common/common.service';
declare var $: any;
@Component({
  selector: 'app-team-report',
  templateUrl: './team-report.component.html',
  styleUrls: ['./team-report.component.css']
})
export class TeamReportComponent implements OnInit {
  imageBaseUrl: string = Config.AuthenticationURL;
  isExport: boolean;
  isDemoTenant: boolean;
  prospectLabel: string;
  employeeName: any;
  message: any;
  pageNo: number = 1;
  perPage: number = 10;
  totalItem: number = 0;
  employeeTeamList: any = [];
  page: number = 2;
  startDate: any;
  endDate: any;
  search: any;
  export: any;
  serverUrl: any;
  teamId: any;
  teamList: any = [];
  teamSelectedItems = [];
  errorMessage: any;
  employeeLabel: any;
  teamSettings = {};
  loading: boolean;
  noDataMsg: string;
  locationList: any = [];
  locationSettings: any = {};
  locationSelectedItem: any = [];
  locationId: any = '';
  defaultSelectObject: any = {id: '',itemName: 'All'};
  filterLabel = Constants.filterLabel;

  constructor(public _prospectService: MeetingService, public commonService: CommonService,public _assignService: AssignService, public _reportService: ReportService, private customErrorHandler: CustomErrorHandler) {
  }
  ngOnInit() {
    this.search = '';
    this.teamSettings = {
      singleSelection: true,
      text: "Select Team",
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      enableSearchFilter: true,
      classes: "myclass custom-class singleSelection"
    };
    this.prospectLabel = localStorage.getItem('prospectLabel');
    this.employeeLabel = localStorage.getItem('employeeLabel');
    this.isExport = false;
    this.isDemoTenant = (localStorage.getItem('isDemoTenant') == 'true');
    this.message = true;
    this.serverUrl = Config.AuthenticationURL;
    this.teamListFunction();
    this.getLocationList();
    this.locationSettings = {
      singleSelection: true,
      text: "Select "+ Constants.locationLabel,
      enableSearchFilter: true,
      classes: "singleSelection sm_max_300 width100 m_b_15 block_elem"
    };
  }
  getTeamReport(currentPage) {
    this.errorMessage = '';
    if (this.teamSelectedItems.length == 0) {
      this.errorMessage = "Team should not be blank";
      return false;
    }
    this.pageNo = currentPage;
    this.page = currentPage;
    this.message = '';
    this.export = '';
    this.loading = true;
    this._reportService.teamReport(this.pageNo, this.perPage, this.teamId, this.search, this.export, this.locationId).subscribe(
      res => {
        this.loading = false;
        this.employeeTeamList = [];
        this.noDataMsg = res.message || 'Currently, there is no data available.'
        if (res.result == true) {
          this.totalItem = res.data.total;
          this.employeeTeamList = res.data.data;
        } else {
          this.message = res.message;
        }
      },
      error => {
        this.loading = false;
        this.customErrorHandler.handleHttpError(error, 'getTeamReport');
      });
  }
  exportTeamReport() {
    this.export = 'yes';
    this._reportService.teamReport(0, 0, this.teamId, this.search, this.export, this.locationId).subscribe(
      res => {
        var options = {
          fieldSeparator: ',',
          quoteStrings: '"',
          decimalseparator: '.',
          showLabels: true,
          showTitle: true,
          useBom: true,
          title: "Team Report"
        };
        new Angular2Csv(res.data, 'Team Report', options);
      },
      error => {
        this.customErrorHandler.handleHttpError(error, 'employeeReport');
      });
  }
  teamListFunction() {
    this._prospectService.teamList(this.locationId).subscribe(
      res => {
        this.teamList = res.data;
        this.noDataMsg = res.message || 'Currently, there is no data available.'
      },
      error => {
        this.customErrorHandler.handleHttpError(error, 'teamList');
      });
  }
  onTeamSelect(item: any) {
    console.log(item);
    this.teamId = item.id;
    this.getTeamReport(1);
  }
  ngOnDestroy() {
    $('.fullHeightBlock').animate({ scrollTop: 0 }, 400);
  }

  getLocationList() {
    this.commonService.getLocationList().subscribe(res => {
      if (res.result == true) {
        this.locationList = res.data;
        if(this.locationList.length > 1){
          this.locationList.unshift(this.defaultSelectObject);
        }
      }
    }, err => {
      this.customErrorHandler.handleHttpError(err, 'getRoleList');
    })
  }

  onLocationItemSelect(item: any) {
    this.locationId = item.id;
    this.teamSelectedItems = [];
    this.employeeTeamList = [];
    this.teamListFunction();
  }
  onLocationItemDeSelect(item: any) {
    this.locationId = '';
    this.teamSelectedItems = [];
    this.employeeTeamList = [];
    this.teamListFunction();
  }
}
