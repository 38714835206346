import { CustomErrorHandler } from './../../errorhandler/custom-errorhandler';
import { Component, OnInit } from '@angular/core';
import { MessageService } from '../../services/message/message.service';
import { Config } from '../../config/config';
import { Socket } from 'ngx-socket-io';
declare var $: any;

@Component({
  selector: 'message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.css'],
  host: {
    'class': 'fullHeightBlock'
  }
})
export class MessageComponent implements OnInit {
  imageBaseUrl: string = Config.AuthenticationURL;
  employee_message: any = true;
  admin_message: any = false;
  employeeList: any = [];
  superAdminList: any = [];
  selectedUser: any = {};
  loginUserId: string;
  message: string;
  messageList: any = [];
  messageLength: number = 0;
  companyId: any;
  employeeBatchCount: any = {}
  currentMessageType: any = 'employee';
  isDemoTenant: boolean;
  loading: boolean;
  isLoginPrimary: any = '';
  deleteUser: any = '';

  constructor(private messageService: MessageService, private customErrorHandler: CustomErrorHandler, private socket: Socket) {
  }
  ngOnInit() {
    this.isDemoTenant = (localStorage.getItem('isDemoTenant') == 'true');
    this.loginUserId = localStorage.getItem('user_id');
    this.companyId = localStorage.getItem('company_id');
    this.isLoginPrimary = localStorage.getItem('isLoginPrimary');
    this.getEmployeeList();
    this.getSuperAdminList();
    
  }
  getEmployeeList() {
    this.messageService.getMessageEmployee(this.loginUserId).subscribe(
      res => {
        if (res.result == true) {
          this.employeeList = res.data;
          this.selectUser(this.employeeList[0]);
        }
      },
      error => {
        this.customErrorHandler.handleHttpError(error, 'getEmployeeList');
      });
  }
  getSuperAdminList() {
    this.messageService.getMessageSuperAdmin(this.loginUserId).subscribe(
      res => {
        if (res.result == true) {
          this.superAdminList = res.data;
        }
      },
      error => {
        this.customErrorHandler.handleHttpError(error, 'getSuperAdminList');
      });
  }
  selectUser(item) {
    this.message = '';
    this.selectedUser = item;
    if (this.currentMessageType == 'employee') {
      $('#employee_' + this.selectedUser.user_id).text('');
    } else {
      $('#super-admin-' + this.selectedUser.user_id).text('')
    }
    this.getMessageList();
    this.getUserMessage();
  }
  selectUserList(userType) {
    this.currentMessageType = userType;
    if (userType == 'employee') {
      this.admin_message = false;
      this.employee_message = true;
      this.selectUser(this.employeeList[0]);
    }
    else if (userType == 'superAdmin') {
      this.admin_message = true;
      this.employee_message = false
      if (this.superAdminList.length > 0) {
        this.selectUser(this.superAdminList[0]);
      }
    }
  }
  sendmessage() {
    if (this.employee_message) {
      var submitData = { login_user: this.loginUserId, user_type: 'employee', message: this.message, selected_user_id: this.selectedUser.user_id, messageType: 'web' }
      this.socket.emit('new message', submitData);
      this.messageService.sendMessageEmployee(this.loginUserId, 'employee', this.message, this.selectedUser.user_id).subscribe(
        res => {
          if (res.result == true) {
            this.message = '';
            this.getMessageList();
          }
        },
        error => {
          this.customErrorHandler.handleHttpError(error, 'sendmessage');
        });
        this.scollMessageDown();
    }
    else if (this.admin_message) {
      var submitData = { login_user: this.loginUserId, user_type: 'admin', message: this.message, selected_user_id: this.selectedUser.user_id, messageType: 'web' }
      this.socket.emit('new message', submitData)
      this.messageService.sendMessageSuperAdmin(this.loginUserId, this.selectedUser.user_id, this.message).subscribe(
        res => {
          if (res.result == true) {
            this.message = '';
            this.getMessageList();
          }
        },
        error => {
          this.customErrorHandler.handleHttpError(error, 'sendmessage');
        });
    }
    this.messageLength = 0;
  }
  getMessageList(scrolltoBottom: boolean = true) {
    let user_type = '';
    if (this.employee_message) {
      user_type = 'employee';
    }
    else {
      user_type = 'super_admin';
    }
    this.deleteUser = 0;
    this.messageService.getMessageList(this.loginUserId, this.selectedUser.user_id, user_type).subscribe(
      res => {
        this.loading = false;
        if (res.result == true) {
          this.messageList = res.data;
          if (res.count > 0) {
            $('#unread-message-count').text(res.count);
          } else {
            $('#unread-message-count').text('');
          }
          this.deleteUser = res.is_delete;
          $('#employee_' + this.selectedUser.user_id).text('')
          this.scollMessageDown();
        }
      },
      error => {
        this.loading = false;
        this.messageList = [];
        this.messageList.message = 'Currently, there is no data available.'
        this.customErrorHandler.handleHttpError(error, 'getMessageList');
      });
  }
  checkSendMessage() {
    this.messageLength = $.trim(this.message).length;
  }
  getUserMessage() {
    var this1 = this;
    let user_type = '';
    if (this.employee_message) {
      user_type = 'employee';
    }
    else {
      user_type = 'admin';
    }
    $('#employee_' + this.selectedUser.user_id).text('')
    var userData = { reciever_id: this.selectedUser.user_id, company_id: 1, type: user_type, messageType: 'mobile' };
    this.socket.on('users connected', (data) => {
      this.socket.emit('set_user_data', userData);
    });
    this.socket.on('new message', function (data) {
      
      if (data.messageType != undefined && data.messageType == 'web') {
        if (data.user_type == 'employee') {
          let temp = this1.employeeList.find(s => s.user_id == data.selected_user_id);
          if (temp != undefined) {
            let index = this1.employeeList.indexOf(temp)
            this1.employeeList.splice(index, 1);
            this1.employeeList.unshift(temp);
          }
        }
        if (data.user_type == 'admin') {
          let temp1 = this1.superAdminList.find(s => s.user_id == data.selected_user_id);
          if (temp1 != undefined) {
            let index1 = this1.superAdminList.indexOf(temp1)
            this1.superAdminList.splice(index1, 1);
            this1.superAdminList.unshift(temp1);
          }
        }
        if (data.user_type == 'admin') {
          let count = $('#employee_' + data.selected_user_id).text();
          count = Number(count) + 1;
          $('#employee_' + data.selected_user_id).text(count);
          if (data.selected_user_id == this1.selectedUser.user_id) {
            data.receiver_id = this1.selectedUser.user_id;
            data.sender_id = this1.loginUserId
            data.sender_profile_pic = localStorage.getItem('profile_pic');
            this1.messageList.push(data);
            $('#employee_' + this1.selectedUser.user_id).text('')
          }
        }
        if (data.user_type == 'employee') {
          let count = $('#super-admin-' + data.selected_user_id).text();
          count = Number(count) + 1;
          $('#super-admin-' + data.selected_user_id).text(count);
          if (data.selected_user_id == this1.selectedUser.user_id) {
            data.receiver_id = this1.selectedUser.user_id;
            data.sender_id = this1.loginUserId
            data.sender_profile_pic = localStorage.getItem('profile_pic');
            this1.messageList.push(data);
            $('#super-admin-' + this1.selectedUser.user_id).text('')
          }
        }
      } else {
        let temp = this1.employeeList.find(s => s.user_id == data.sender_id);
        if (temp != undefined) {
          let index = this1.employeeList.indexOf(temp)
          this1.employeeList.splice(index, 1);
          this1.employeeList.unshift(temp);
        }
        if (data.sender_role == 'employee') {
          let count = $('#employee_' + data.sender_id).text();
          count = Number(count) + 1;
          $('#employee_' + data.sender_id).text(count);
          if (data.sender_id == this1.selectedUser.user_id) {
             this1.messageList.push(data);
            $('#employee_' + this1.selectedUser.user_id).text('')
          }
        }
        let temp1 = this1.superAdminList.find(s => s.user_id == data.sender_id);
        if (temp1 != undefined) {
          let index1 = this1.superAdminList.indexOf(temp1)
          this1.superAdminList.splice(index1, 1);
          this1.superAdminList.unshift(temp1);
        }
        if (data.sender_role == 'admin') {

          let count = $('#super-admin-' + data.sender_id).text();
          count = Number(count) + 1;
          $('#super-admin-' + data.sender_id).text(count);
          if (data.sender_id == this1.selectedUser.user_id) {
            this1.messageList.push(data);
            $('#super-admin-' + this1.selectedUser.user_id).text('')
          }
        }
      }
      this.scollMessageDown();
    });
  }
  scollMessageDown() {
    setTimeout(function () {
      $("#message_scroller").animate({ scrollTop: $('#message_scroller').prop("scrollHeight") }, 1000);
    }, 100);
  }
  ngOnDestroy() {
    $('.fullHeightBlock').remove();
  }
}
