import { LocationService } from '../../../../services/location/location.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Angular2Csv } from 'angular2-csv/Angular2-csv';
import { Constants } from '../../../../common/constants';
import { CustomErrorHandler } from '../../../../errorhandler/custom-errorhandler';
import swal from 'sweetalert2';
import { CommonService } from '../../../../services/common/common.service';
@Component({
  selector: 'app-location-list',
  templateUrl: './location-list.component.html',
  styleUrls: ['./location-list.component.css']
})
export class LocationListComponent implements OnInit {
  isDemoTenant: boolean;
  errormsg: string;
  locationList: any[];
  errorMessage: string;
  loading: boolean;
  serchText: any;
  noDataMsg: string;
  pageNo: number = 1;
  perPage: number = 10;
  totalItem: number = 0;
  page: number = 1;
  exportReport: any;
  deleteLocationDetails: any = {};
  locationLabel: any = Constants.locationLabel;
  tempSearch: any = '';

  constructor(
    private router: Router,
    private locationService: LocationService,
    private customErrorHandler: CustomErrorHandler,
    private commonService: CommonService
  ) { }
  ngOnInit() {
    this.serchText = '';
    this.isDemoTenant = (localStorage.getItem('isDemoTenant') == 'true');
    this.errormsg = '';
    this.loading = true;
    this.getLocationList(1);
  }
  getLocationList(currentPage) {
    this.pageNo = currentPage;
    this.page = currentPage;
    this.errormsg = '';
    this.locationList = [];
    this.exportReport = '';
    this.locationService.locationList(this.pageNo, this.perPage, this.serchText, this.exportReport).subscribe(
      res => {
        this.loading = false;
        if (res.result == true) {
          this.totalItem = res.data.total;
          this.locationList = res.data.data;
          this.errormsg = res.message;
        } else {
          this.locationList = [];
          this.errormsg = res.message;
          this.commonService.showErrorMessage(res);
        }
      },
      error => {
        this.customErrorHandler.handleHttpError(error, 'eventList');
        this.loading = false;
        this.errormsg = JSON.parse(error['_body']).MessageList[0];
      });
  }
  selectLocationById(id) {
    localStorage.setItem('editLocationId', id);
    this.router.navigate(["locations/add-location"]);
  }
  locationDelete(id) {
    swal({
      text: Constants.DeleteMsg,
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Delete',
    }).then((result) => {
      if (typeof result.value != 'undefined') {
        this.deleteLocationDetails.location_id = id;
        this.locationService.deleteLocation(this.deleteLocationDetails).subscribe(
          res => {
            if (res.result == true) {
              this.commonService.showSuccessMessage(res.message);
              this.getLocationList(1);
            }
            else {
              this.commonService.showErrorMessage(res);
            }
          },
          error => {
            this.customErrorHandler.handleHttpError(error, 'eventDelete');
          });
      }
    })
  }
  searchLocationDetail() {
    if(this.tempSearch != this.serchText){
      this.loading = true;
      this.tempSearch = this.serchText;
      this.getLocationList(1);
    }
  }
  export() {
    var options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: true,
      useBom: true,
      title: "Location List"
    };
    this.exportReport = 'yes';
    this.locationService.locationList(0, 0, this.serchText, this.exportReport).subscribe(
      res => {
        new Angular2Csv(res.data, 'location', options);
      },
      error => {
        this.customErrorHandler.handleHttpError(error, 'locationList');
        this.loading = false;
        this.errormsg = JSON.parse(error['_body']).MessageList[0];
      });
  }
}
