import { AuthGuard } from './../../services/_guards/auth.guard';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { EmployeeComponent } from './employee.component';
import { ListComponent } from './list/list.component';
import { MapComponent } from './map/map.component';
import { ChartsModule } from 'ng2-charts';
import { InfiniteScrollModule } from 'angular2-infinite-scroll';
import { Ng2PaginationModule } from 'ng2-pagination';
import { AngularFireModule } from 'angularfire2';
import { AngularFireDatabaseModule } from 'angularfire2/database';
import { Config } from './../../config/config';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown/angular2-multiselect-dropdown';
export const firebaseConfig = Config.FirebaseConfig;
export const routes: Routes = [
  {
    path: 'employee',
    component: EmployeeComponent, canActivate: [AuthGuard],
    children: [
      { path: '', redirectTo: 'list', pathMatch: 'full' },
      { path: 'list', component: ListComponent },
      { path: 'map', component: MapComponent },
    ]
  }
];
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule.forChild(routes),
    ChartsModule,
    InfiniteScrollModule,
    Ng2PaginationModule,
    AngularFireModule.initializeApp(firebaseConfig),
    AngularFireDatabaseModule,
    AngularMultiSelectModule
  ],
  declarations: [
    EmployeeComponent,
    ListComponent,
    MapComponent
  ]
})
export class EmployeeModule { }
