import { NgModule } from '@angular/core';
import { AuthGuard } from './../../services/_guards/auth.guard';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { AnnouncementsComponent } from '../announcements/announcements.component';
import { AnnouncementListComponent } from './announcement-list/announcement-list.component';
import { AddAnnouncementComponent } from './add-announcement/add-announcement.component';
import { FileUploadModule } from 'ng2-file-upload/ng2-file-upload';
import { GalleriaModule } from 'primeng/galleria';
import { Ng2PaginationModule } from 'ng2-pagination';
import { PipeModule } from '../../pipe/pipe.module';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown/angular2-multiselect-dropdown';

export const routes: Routes = [
  {
    path: 'announcements',
    component: AnnouncementsComponent, canActivate: [AuthGuard],
    children: [
      { path: '', redirectTo: 'announcement-list', pathMatch: 'full' },
      { path: 'announcement-list', component: AnnouncementListComponent },
      { path: 'add-announcement', component: AddAnnouncementComponent }
    ]
  }
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule.forChild(routes),
    FileUploadModule,
    GalleriaModule,
    Ng2PaginationModule,
    PipeModule.forRoot(),
    AngularMultiSelectModule
  ],
  declarations: [
    AnnouncementsComponent,
    AnnouncementListComponent,
    AddAnnouncementComponent
  ]
})
export class AnnouncementsModule { }
