import { MovementReportComponent } from './movement-report/movement-report.component';
import { TopPerformingComponent } from './top-performing/top-performing.component';
import { ReportListComponent } from './report-list/report-list.component';
import { AuthGuard } from './../../services/_guards/auth.guard';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { ReportsComponent } from './reports.component';
import { ActionReportComponent } from './action-report/action-report.component';
import { FollowReportComponent } from './follow-report/follow-report.component';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown/multiselect.component';
import { NonPerformingComponent } from './non-performing/non-performing.component';
import { EmployeePerformanceComponent } from './employee-performance/employee-performance.component';
import { InfiniteScrollModule } from 'angular2-infinite-scroll';
import { EmployeeMeetingReportComponent } from './employee-meeting-report/employee-meeting-report.component';
import { EmployeeAttendenceReportComponent } from './employee-attendence-report/employee-attendence-report.component';
import { ExpensesReportComponent } from './expenses-report/expenses-report.component';
import { Ng2PaginationModule } from 'ng2-pagination';
import { ReportdashboardComponent } from './reportdashboard/reportdashboard.component';
import { TeamReportComponent } from './team-report/team-report.component';
import { SalesReportComponent } from './sales-report/sales-report.component';
import { EventReportComponent } from './event-report/event-report.component';
import { InfluencerWiseVoterListComponent } from './influencer-wise-voter-list/influencer-wise-voter-list.component';
import { BoothWiseVoterListComponent } from './booth-wise-voter-list/booth-wise-voter-list.component';
import { AgeGenderWiseVoterListComponent } from './age-gender-wise-voter-list/age-gender-wise-voter-list.component';
import { FavorableVoterListComponent } from './favorable-voter-list/favorable-voter-list.component';
import { VoterWiseFavorableNotaListComponent } from './voter-wise-favorable-nota-list/voter-wise-favorable-nota-list.component';
import { EmployeeWiseSurveyReportComponent } from './employee-wise-survey-report/employee-wise-survey-report.component';
import { EmployeeWisePollReportComponent } from './employee-wise-poll-report/employee-wise-poll-report.component';
import { SurveyListReportComponent } from './survey-list-report/survey-list-report.component';
import { PollListReportComponent } from './poll-list-report/poll-list-report.component';
import { LocationWiseExpenseComponent } from './location-wise-expense/location-wise-expense.component';
import { LocationWisePerformanceComponent } from './location-wise-performance/location-wise-performance.component';
import { UserRouteComponent } from './user-route/user-route.component';
export const routes: Routes = [
  {
    path: 'reports',
    component: ReportsComponent, canActivate: [AuthGuard],
    children: [
      { path: '', redirectTo: 'employee-report', pathMatch: 'full' },
      { path: 'dashboard', component: ReportdashboardComponent },
      { path: 'lead-generation', component: ActionReportComponent },
      { path: 'follow-report', component: FollowReportComponent },
      { path: 'report-list', component: ReportListComponent },
      { path: 'employee-performance', component: EmployeePerformanceComponent },
      { path: 'top-performance', component: TopPerformingComponent },
      { path: 'non-performing', component: NonPerformingComponent },
      { path: 'movement-report', component: MovementReportComponent },
      { path: 'employee-meeting-report', component: EmployeeMeetingReportComponent },
      { path: 'employee-attendence-report', component: EmployeeAttendenceReportComponent },
      { path: 'expenses-report', component: ExpensesReportComponent },
      { path: 'team-report', component: TeamReportComponent },
      { path: 'user-route', component: UserRouteComponent },
      { path: 'sales-report', component: SalesReportComponent },
      { path: 'event-report', component: EventReportComponent },
      { path: 'influencer-wise-voter-report', component: InfluencerWiseVoterListComponent },
      { path: 'booth-wise-voter-report', component: BoothWiseVoterListComponent },
      { path: 'age-gender-wise-voter-report', component: AgeGenderWiseVoterListComponent },
      { path: 'favorable-voter-report', component: FavorableVoterListComponent },
      { path: 'fav-unfav-nota-list', component: VoterWiseFavorableNotaListComponent },
      { path: 'employee-survey-report', component: EmployeeWiseSurveyReportComponent },
      { path: 'employee-poll-report', component: EmployeeWisePollReportComponent },
      { path: 'survey-list-report', component: SurveyListReportComponent },
      { path: 'poll-list-report', component: PollListReportComponent },
      { path: 'location-wise-expense', component: LocationWiseExpenseComponent },
      { path: 'location-wise-performance', component: LocationWisePerformanceComponent }
    ]
  }
];
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    AngularMultiSelectModule,
    InfiniteScrollModule,
    RouterModule.forChild(routes),
    Ng2PaginationModule
  ],
  declarations: [
    ReportsComponent,
    ActionReportComponent,
    FollowReportComponent,
    ReportListComponent,
    ReportListComponent,
    EmployeePerformanceComponent,
    TopPerformingComponent,
    NonPerformingComponent,
    MovementReportComponent,
    EmployeeMeetingReportComponent,
    EmployeeAttendenceReportComponent,
    ExpensesReportComponent,
    ReportdashboardComponent,
    TeamReportComponent,
    UserRouteComponent,
    SalesReportComponent,
    EventReportComponent,
    InfluencerWiseVoterListComponent,
    BoothWiseVoterListComponent,
    AgeGenderWiseVoterListComponent,
    FavorableVoterListComponent,
    VoterWiseFavorableNotaListComponent,
    EmployeeWiseSurveyReportComponent,
    EmployeeWisePollReportComponent,
    SurveyListReportComponent,
    PollListReportComponent,
    LocationWiseExpenseComponent,
    LocationWisePerformanceComponent
  ]
})
export class ReportsModule { }
