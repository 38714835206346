import { Role_managementService } from './../../../../services/role_management/role_management.service';
import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { CustomErrorHandler } from '../../../../errorhandler/custom-errorhandler';
import { CommonService } from '../../../../services/common/common.service';
import { Constants } from '../../../../common/constants';
@Component({
  selector: 'assign-rolelist',
  templateUrl: 'assign-rolelist.component.html',
  styleUrls: ['./assign-rolelist.component.css']
})
export class AssignRolelistComponent implements OnInit {
  isDemoTenant: boolean;
  actionLable: string;
  employeeLabel: string;
  modal: any;
  company_id: string;
  assign_role: any[];
  loading: boolean;
  noDataMsg: string;
  pageNo: number = 1;
  perPage: number = 10;
  totalItem: number = 0;
  page: number = 2;
  locationLabel: any = Constants.locationLabel;
  constructor(
    private router: Router,
    public _Role_managementService:
      Role_managementService,
    private customErrorHandler: CustomErrorHandler,
    public commonService: CommonService
  ) { }
  ngOnInit() {
    this.isDemoTenant = (localStorage.getItem('isDemoTenant') == 'true');
    this.actionLable = localStorage.getItem('actionLabel');
    this.employeeLabel = localStorage.getItem('employeeLabel');
    this.loading = true;
    this.assignRoleList(1);
  }
  assignRoleList(currentPage) {
    this.pageNo = currentPage;
    this.page = currentPage;
    this._Role_managementService.assignRoleList(this.pageNo, this.perPage).subscribe(
      res => {
        this.loading = false;
        if (res.result == true) {
          this.totalItem = res.data.total;
          this.assign_role = res.data.data;
          this.noDataMsg = res.message;
        } else {
          this.totalItem = 0;
          this.assign_role = [];
          this.noDataMsg = res.message;
          this.commonService.showErrorMessage(res);
        }
        console.log(this.assign_role);
      },
      error => {
        this.loading = false;
        this.customErrorHandler.handleHttpError(error, 'assignRoleList');
      });
  }
  editAssignRole(id) {
    localStorage.setItem('assign_role_id', id);
    this.router.navigate(["setting/role-management/update-assign-role"]);
  }
}
