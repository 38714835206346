import { Injectable } from '@angular/core';
import { HttpHelper } from '../../helpers/http.helper';
import { Config } from '../../config/config';
@Injectable()
export class ExpenseService {
  constructor(private _httpHelper: HttpHelper) {
  }
  expenseList(pageNo, perPage, startDate, endDate, search, exportReport, reportingTo, locationId) {
    try {
      let prospectLabel = localStorage.getItem('prospectLabel');
      let employeeLabel = localStorage.getItem('employeeLabel');
      return this._httpHelper.get(Config.AuthenticationBaseURL1 + 'get-expense-list?page=' + pageNo + '&per_page=' + perPage + '&start_date=' + startDate + '&end_date=' + endDate + '&search=' + search + '&export=' + exportReport + '&employee_label=' + employeeLabel + ' Name' + '&prospect_label=' + prospectLabel + '&reporting_to=' + reportingTo + '&location_id=' + locationId);
    }
    catch (e) {
    }
  }
  updateStatus(request) {
    try {
      return this._httpHelper.post(Config.AuthenticationBaseURL1 + 'update-expense-status', request)
    }
    catch (e) {
    }
  }
  getAllStatus() {
    try {
      return this._httpHelper.get(Config.AuthenticationBaseURL1 + 'get-expense-status')
    }
    catch (e) {
    }
  }
}
