import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HomeSharedService } from '../../../services/shared/home-shared';
import { RouteService } from '../../../services/route/route.service';
import { Config } from '../../../config/config';
import "rxjs/add/operator/debounceTime";
import "rxjs/add/operator/distinctUntilChanged";
import { CustomErrorHandler } from '../../../errorhandler/custom-errorhandler';
import { Constants } from '../../../common/constants';
import { CommonService } from '../../../services/common/common.service';
declare var $: any;
declare var google: any;
declare var map: any;
@Component({
    selector: 'assign-checklist',
    templateUrl: 'assign-checklist.component.html',
    styleUrls: ['./assign-checklist.component.css']
})
export class AssignChecklistComponent implements OnInit {
    isDemoTenant: boolean;
    drawingManager: any;
    polygonPaths: any[] = [];
    rectangleBounds: any;
    circleRadius: any;
    circleLat: string;
    circleLng: string;
    shapeType: number;
    creates: any;
    create_area: boolean;
    map: any;
    cityCircle: any;
    dd_radius1: string;
    lat: string;
    lng: string;
    name: string;
    loginFormEmail: string;
    status: string;
    errorMessage: string;
    disableButton: boolean;
    signin_btn_txt: string;
    loginDiv: boolean;
    tenantList: any[];
    accessToken: string;
    image_url: string;
    current_lng: any;
    current_lat: any;
    directionsService: any;
    directionsDisplay: any;
    routeOrigin: any;
    routeDestination: any;
    routePaths: any[] = [];
    originPath: any;
    originResult: any;
    routeDrawResult: any[] = [];
    routeDistance: any = 0;
    routeETA: any = 0;
    routeResult: any;
    checkList: any[] = [];
    checkListData: any[] = [];
    checklistDetails: any = {};
    checklistSettings = {};
    checkListItem: any[] = [];
    selectedItems: any = [];
    employeeMemberList = [];
    employeeMemberSettings: any = {};
    drawGeofencing: any;
    drawGeofencingType: any;
    geofenceDraw: boolean;
    employeeMeetingList: any = [];
    boundries: any;
    play: boolean;
    pause: boolean;
    notblank: boolean;
    notblankprospect: boolean;
    markerArray = [];
    markers = [];
    constructor(private router: Router, private commonService: CommonService, public _routeService: RouteService, public _homeSharedService: HomeSharedService, private customErrorHandler: CustomErrorHandler) {
    }
    ngOnInit() {
        this.isDemoTenant = (localStorage.getItem('isDemoTenant') == 'true');
        let geofenceRouteId = localStorage.getItem('root_geofence_id');
        if (geofenceRouteId == '' || geofenceRouteId == null) {
            this.router.navigate(["route/route-create"]);
        }
        setTimeout(() => {
            this.getCheckList();
        }, 100);
        this.checklistSettings = {
            singleSelection: true,
            text: "Select Checklist",
            enableSearchFilter: true,
            classes: "myclass custom-class"
        };
        this.checklistDetails.check_list = [];
        this.employeeMemberSettings = {
            singleSelection: false,
            text: "Select Employee",
            selectAllText: 'Select All',
            unSelectAllText: 'UnSelect All',
            enableSearchFilter: true,
            classes: "myclass custom-class"
        };
        this.employeeListFunction();
        this.play = true;
        this.pause = false;
        this.notblank = false;
        this.geofenceDraw = false;
        this.drawGeofencingType = 0;
        this.routeOrigin = '';
        this.routeDestination = '';
        this.directionsService = new google.maps.DirectionsService;
        this.directionsDisplay = new google.maps.DirectionsRenderer({
            suppressMarkers: true
        });
        this.map = new google.maps.Map(document.getElementById('map'), {
            zoom: 8,
            center: { lat: 22.7664707, lng: 75.8824308 },
            gestureHandling: 'cooperative',
            styles: Constants.MapStyle
        });
        this.directionsDisplay.setMap(this.map);
        setTimeout(() => {
            this.getRouteDetails();
        }, 100);
    }
    getCheckList() {
        let routeId = localStorage.getItem('root_geofence_id');
        if (routeId) {
            this._routeService.getCheckList().subscribe(
                res => {
                    if (typeof res.data != 'undefined') {
                        this.checkList = res.data.data;
                        let checkListId = localStorage.getItem('checklist_id');
                        if (checkListId) {
                            for (let i = 0; i < this.checkList.length; i++) {
                                if (checkListId == this.checkList[i]['id']) {
                                    this.selectedItems = [
                                        {
                                            "id": checkListId,
                                            "itemName": this.checkList[i]['label'],
                                            "items": this.checkList[i]['items']
                                        }
                                    ];
                                    this.checkListItem = this.checkList[i]['items'];
                                    this.checklistDetails.check_list = this.selectedItems;
                                }
                            }
                        }
                    }
                },
                error => {
                    this.customErrorHandler.handleHttpError(error, 'getCheckList');
                });
        }
        else {
            this.router.navigate(["route/route-create"]);
        }
    }
    onOpen(event: any) {
        this.checkListData = this.checkList.map((item) => {
            return {
                id: item.id,
                itemName: item.label,
                items: item.items
            }
        });
        this.employeeMemberList = this.employeeMemberList.map((item) => {
            return {
                id: item.id,
                itemName: item.itemName
            }
        });
    }
    changesCheckList() {
        this.checkListItem = this.checklistDetails.check_list[0]['items'];
    }
    assignCheckList() {
        this.errorMessage = '';
        let requestParameter = {}
        if (this.checklistDetails.check_list.length > 0) {
            let checkedItem = this.checklistDetails.check_list[0]['id'];
            requestParameter['check_list'] = checkedItem;
        }
        requestParameter['route_id'] = localStorage.getItem('root_geofence_id');
        this._routeService.assignCheckList(requestParameter).subscribe(
            res => {
                if (res.result == true) {
                    this.router.navigate(["route/route-list"]);
                } else {
                    this.errorMessage = res.message;
                }
            },
            error => {
                this.customErrorHandler.handleHttpError(error, 'assignCheckList');
            });
    }
    employeeListFunction() {
        this._routeService.employeeList().subscribe(
            res => {
                this.employeeMemberList = res.data;
            },
            error => {
                this.customErrorHandler.handleHttpError(error, 'employeeList');
            });
    }
    skipCheckList() {
        this.router.navigate(["route/route-list"]);
    }
    getRouteDetails() {
        let routeId = localStorage.getItem('update_route_id');
        if (routeId) {
            this._routeService.singleRouteDetails(routeId).subscribe(
                res => {
                    this.employeeMeetingList = res.data.route_direction_points;
                    let checkListId = res.data.check_list_id;
                    if (checkListId) {
                        localStorage.setItem('checklist_id', checkListId);
                    } else {
                        localStorage.removeItem('checklist_id');
                    }
                    let geoFencingPoint = res.data.geo_fencing;
                    this.calculateAndDisplayRoute(this.directionsService, this.directionsDisplay);
                    if (geoFencingPoint) {
                        this.drawGeofencing = geoFencingPoint.boundries;
                        this.drawGeofencingType = geoFencingPoint.type;
                        this.drawingGeofence();
                    }
                },
                error => {
                    this.customErrorHandler.handleHttpError(error, 'singleRouteDetails');
                });
        }
        else {
            this.router.navigate(["route/route-create"]);
        }
    }
    calculateAndDisplayRoute(directionsService, directionsDisplay) {
        let waypts = [];
        this.routePaths = [];
        let routeLength = this.employeeMeetingList.length;
        let imgUrl = Config.AuthenticationURL + 'public/route-icon/';
        if (routeLength > 1) {
            this.routeOrigin = this.employeeMeetingList[0]['lat'] + ',' + this.employeeMeetingList[0]['lng'];//$('#'+originId).val();
            this.routePaths.push(this.routeOrigin);
            this.originPath = '';
            this.routeDestination = this.employeeMeetingList[routeLength - 1]['lat'] + ',' + this.employeeMeetingList[routeLength - 1]['lng'];
            if (routeLength > 2) {
                for (let i = 1; i < routeLength - 1; i++) {
                    let routeWayPoint = this.employeeMeetingList[i]['lat'] + ',' + this.employeeMeetingList[i]['lng'];
                    waypts.push({
                        location: routeWayPoint,
                        stopover: true
                    });
                    this.routePaths.push(routeWayPoint);
                    let wayPointLatLng = new google.maps.LatLng(this.employeeMeetingList[i]['lat'], this.employeeMeetingList[i]['lng']);
                    let wayPointIcon = imgUrl + (i + 1) + '.png';
                    this.createMarker(wayPointLatLng, '', this.employeeMeetingList[i]['point_name'], this.employeeMeetingList[i]['image_url'], wayPointIcon);
                }
            }
            this.routePaths.push(this.routeDestination);
        }
        else {
            return false;
        }
        $('.route_input').each(function () {
            var inputId = $(this).attr('id');
        })
        var _this = this;
        this.routeOrigin = new google.maps.LatLng(this.employeeMeetingList[0]['lat'], this.employeeMeetingList[0]['lng']);
        this.routeDestination = new google.maps.LatLng(this.employeeMeetingList[routeLength - 1]['lat'], this.employeeMeetingList[routeLength - 1]['lng']);
        this.createMarker(this.routeOrigin, '', this.employeeMeetingList[0]['point_name'], this.employeeMeetingList[0]['image_url'], imgUrl + '1.png');
        let destinationIcon = imgUrl + '' + routeLength + '.png';
        if (routeLength > 32) {
            destinationIcon = imgUrl + '32.png'
        }
        this.createMarker(this.routeDestination, '', this.employeeMeetingList[routeLength - 1]['point_name'], this.employeeMeetingList[routeLength - 1]['image_url'], destinationIcon);
        directionsService.route({
            origin: this.routeOrigin,
            destination: this.routeDestination,
            waypoints: waypts,
            optimizeWaypoints: false,
            travelMode: 'DRIVING'
        }, function (response, status) {
            if (status === 'OK') {
                directionsDisplay.setDirections(response);
                var route = response.routes[0];
                _this.routeDistance = 0;
                _this.routeETA = 0;
                for (var i = 0; i < route.legs.length; i++) {
                    _this.routeDistance += Number(route.legs[i]['distance']['value']);
                    _this.routeETA += Number(route.legs[i]['duration']['value']);
                }
            } else {
                let routeLen = _this.employeeMeetingList.length;
                if (routeLen >= 2) {
                    this.commonService.showCustomError('Directions request failed due to ' + status);
                    return false;
                }
            }
        });
    }
    drawingGeofence() {
        var _this = this;
        if (this.drawGeofencingType == 2) {
            let rectanglelatLng = JSON.parse(_this.drawGeofencing);
            var rectangle = new google.maps.Rectangle({
                strokeColor: '#000000',
                strokeOpacity: 1,
                strokeWeight: 2,
                fillColor: '#262525',
                fillOpacity: 0.35,
                map: _this.map,
                bounds: rectanglelatLng
            });
            this.drawingManager.setOptions({
                drawingControl: false
            });
            this.geofenceDraw = true;
        }
        if (this.drawGeofencingType == 3) {
            let polygonlatLng = JSON.parse(_this.drawGeofencing);
            var rectangle = new google.maps.Polygon({
                map: _this.map,
                paths: polygonlatLng,
                strokeColor: '#000000',
                strokeOpacity: 1,
                strokeWeight: 2,
                fillColor: '#262525',
                fillOpacity: 0.35,
                draggable: true,
                geodesic: true
            });
            this.drawingManager.setOptions({
                drawingControl: false
            });
            this.geofenceDraw = true;
        }
        if (this.drawGeofencingType == 1) {
            let circlelatLng = JSON.parse(_this.drawGeofencing);
            var marker = new google.maps.Marker({
                map: map,
                position: new google.maps.LatLng(circlelatLng.lat, circlelatLng.lng),
                title: 'Some location'
            });
            var circle = new google.maps.Circle({
                map: _this.map,
                radius: circlelatLng.radius,    // 10 miles in metres
                fillColor: '#262525'
            });
            circle.bindTo('center', marker, 'position');
            this.drawingManager.setOptions({
                drawingControl: false
            });
            this.geofenceDraw = true;
        }
    }
    createMarker(latlng, description, name, imgUrl, icon) {
        var image = {
            url: imgUrl,
            size: new google.maps.Size(32, 32),
            shape: { coords: [17, 17, 18], type: 'circle' },
            optimized: false
        };
        var content = '<div style="background: url(' + imgUrl + ') no-repeat center;width:150px;height: 90px;"></div><br><b style="font-weight:bold">' + name + '</b><p>' + description + '</p>';
        var _this = this;
        var infowindow = new google.maps.InfoWindow({
            content: content,
            maxWidth: 150,
            width: 100
        });
        var marker = new google.maps.Marker({
            position: latlng,
            icon: icon,
            map: _this.map,
            shape: { coords: [17, 17, 18], type: 'circle' }
        });
        google.maps.event.addListener(marker, 'click', function () {
            infowindow.open(_this.map, marker);
        });
        this.markers.push(marker);
    }
}
