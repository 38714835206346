import { MeetingService } from './../../../services/meeting/meeting.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { CustomErrorHandler } from '../../../errorhandler/custom-errorhandler';
import { CommonService } from '../../../services/common/common.service'
import { Constants } from '../../../common/constants';
declare var moment: any;
declare var $: any;
@Component({
  selector: 'create-meeting',
  templateUrl: './create-meeting.component.html',
  styleUrls: ['./create-meeting.component.css']
})
export class CreateMeetingComponent implements OnInit, OnDestroy {
  loading: boolean;
  prospectLabel: string;
  employeeLabel: string;
  modal: any;
  company_id: string;
  team: any[];
  employee: any[];
  area: any[];
  team_show: boolean;
  lat: string;
  lng: string;
  name: string;
  area_id: string;
  loginFormEmail: string;
  status: string;
  errorMessage: string;
  disableButton: boolean;
  signin_btn_txt: string;
  loginDiv: boolean;
  tenantList: any[];
  accessToken: string;
  image_url: string;
  prospect_id: string;
  prospect_name: string;
  area_name: string;
  address;
  contact_no;
  email;
  primary_contact_person_name;
  primary_contact_person_phone;
  secondry_contact_person_name;
  secondry_contact_person_phone;
  turnover;
  industry = '';
  company_size = '';
  emailVal = '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$';
  teamList = [];
  employeeMemberList = [];
  eventList = [];
  routeList = [];
  notificationList = [];
  formList = [];
  areaSingleList = [];
  teamSelectedItems = [];
  teamMemberItems = [];
  eventSelectedItems: any;
  routeSelectedItems = [];
  notificationSelectedItems = [];
  formSelectedItems = [];
  areaSingleSelectedItems = [];
  teamSettings = {};
  employeeMemberSettings = {};
  eventSettings = {};
  routeSettings = {};
  notificationSettings = {};
  formSettings = {};
  areaSingleSettings = {};
  notblank: boolean;
  notblankprospect: boolean;
  start_date: string;
  end_date: string;
  priority: string;
  meetingType: string;
  description: string;
  priorityList: any = [];
  meetingTypeList: any = [];
  endDateErrorMsg: any;
  startDateErrorMsg: any;
  meetingTypeErrorMsg: any;
  priorityErrorMsg: any;
  recurring: any;
  prospectDetails: any = {};
  title: any;
  titleErrorMessage: any;
  startDateTimeMin: any;
  endDateTimeMin: any;
  startTime: any;
  endTime: any;
  meetingCreatedButton: boolean = false;
  locationList: any = [];
  locationSettings: any = {};
  locationSelectedItem: any = [];
  locationId: any = '';
  locationErrorMessage: any = '';
  locationLabel: any = Constants.locationLabel;
  isRouteName = localStorage.getItem('createProspectRouteName') != null ? '0' : '1';
  disabledLocation = '';
  disabledEmp = '';

  constructor(
    private router: Router,
    public _prospectService: MeetingService,
    private customErrorHandler: CustomErrorHandler,
    public commonService: CommonService
  ) { }
  ngOnInit() {
    this.startDateTimeMin = new Date();
    this.endDateTimeMin = new Date();
    this.startTime = new Date();
    this.endTime = new Date();
    this.priority = 'Low';
    this.meetingType = '';
    this.loading = false;
    this.recurring = false;
    this.prospectLabel = localStorage.getItem('prospectLabel');
    this.employeeLabel = localStorage.getItem('employeeLabel');
    this.prospect_id = localStorage.getItem('prospect_id');
    this.prospect_name = localStorage.getItem('prospect_name');
    this.area_name = localStorage.getItem('prospect_area_name');
    this.title = localStorage.getItem('prospect_title');

    this.notificationListFunction();
    this.formListFunction();
    this.getPriorityList();
    this.getMeetingTypeList();
    this.teamSelectedItems = [];
    this.teamMemberItems = [];
    this.eventSelectedItems = [];
    this.routeSelectedItems = [];
    this.notificationSelectedItems = [];
    this.formSelectedItems = [];
    this.areaSingleList = [];
    this.teamSettings = {
      singleSelection: false,
      text: "Select Team",
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      enableSearchFilter: true,
      classes: "myclass custom-class"
    };
    this.areaSingleSettings = {
      singleSelection: true,
      text: "Select area",
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      enableSearchFilter: true,
      classes: "myclass custom-class"
    };
    this.employeeMemberSettings = {
      singleSelection: false,
      text: "Select " + this.employeeLabel,
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      enableSearchFilter: true,
      classes: "myclass custom-class"
    };
    this.eventSettings = {
      singleSelection: true,
      text: "Select Event",
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      enableSearchFilter: true,
      classes: "myclass custom-class singleSelection"
    };
    this.routeSettings = {
      singleSelection: true,
      text: "Select Route",
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      enableSearchFilter: true,
      classes: "myclass custom-class"
    };
    this.notificationSettings = {
      singleSelection: false,
      text: "Select Notification type",
      enableSearchFilter: true,
      classes: "myclass custom-class"
    };
    this.formSettings = {
      singleSelection: false,
      text: "Select Digital Form",
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      enableSearchFilter: true,
      classes: "myclass custom-class"
    };
    this.prospectDetails.start_date = new Date();
    this.prospectDetails.end_date = new Date();

    this.locationId = localStorage.getItem('createProspectRouteLocationId');
    this.getLocationList('init');
    this.locationSettings = {
      singleSelection: true,
      text: "Select " + Constants.locationLabel,
      enableSearchFilter: true,
      classes: "singleSelection"
    }
  }
  onOpen(event: any) {
    this.teamList = this.teamList.map((item) => {
      return {
        id: item.id,
        itemName: item.itemName
      }
    });
    this.employeeMemberList = this.employeeMemberList.map((item) => {
      return {
        id: item.id,
        itemName: item.itemName
      }
    });
    this.eventList = this.eventList.map((item) => {
      return {
        id: item.id,
        itemName: item.itemName
      }
    });
    this.routeList = this.routeList.map((item) => {
      return {
        id: item.id,
        itemName: item.itemName
      }
    });
    this.formList = this.formList.map((item) => {
      return {
        id: item.id,
        itemName: item.itemName
      }
    });
  }
  employeeListFunction() {
    this._prospectService.employeeLocationList(this.locationId).subscribe(
      res => {
        this.employeeMemberList = res.data;
        let empId = localStorage.getItem('createProspectRouteEmpId') || localStorage.getItem('assigneeId');
        
        let assignEmp:any = this.employeeMemberList.find(s => s.id == empId);
        if (empId) {
            if (typeof assignEmp != 'undefined' || assignEmp != '') {
              this.teamMemberItems = [];
              this.teamMemberItems.push(assignEmp);
            }
        }
        this.disabledEmp = assignEmp.itemName;
      },
      error => {
        this.customErrorHandler.handleHttpError(error, 'employeeList');
      });
  }
  teamListFunction() {
    this._prospectService.teamList(this.locationId).subscribe(
      res => {
        this.teamList = res.data;
      },
      error => {
        this.customErrorHandler.handleHttpError(error, 'teamList');
      });
  }
  eventListFunction() {
    this._prospectService.eventList(this.locationId).subscribe(
      res => {
        this.eventList = res.data;
      },
      error => {
        this.customErrorHandler.handleHttpError(error, 'eventList');
      });
  }
  routeListFunction() {
    this._prospectService.routeList().subscribe(
      res => {
        this.routeList = res.data;
      },
      error => {
        this.customErrorHandler.handleHttpError(error, 'routeList');
      });
  }
  notificationListFunction() {
    this._prospectService.notificationList().subscribe(
      res => {
        this.notificationList = res.data;
      },
      error => {
        this.customErrorHandler.handleHttpError(error, 'notificationList');
      });
  }
  formListFunction() {
    this._prospectService.formList().subscribe(
      res => {
        this.formList = res.data;
      },
      error => {
        this.customErrorHandler.handleHttpError(error, 'formList');
      });
  }
  onSubmit() {
    let startDate = this.prospectDetails.start_date;
    this.prospectDetails.start_date = moment(startDate).format('YYYY-MM-DD') + ' ' + moment(this.startTime).format('hh:mm A');
    let endDate = this.prospectDetails.end_date
    this.prospectDetails.end_date = moment(endDate).format('YYYY-MM-DD') + ' ' + moment(this.endTime).format('hh:mm A');
    this.titleErrorMessage = '';
    this.endDateErrorMsg = '';
    this.startDateErrorMsg = '';
    this.meetingTypeErrorMsg = '';
    this.priorityErrorMsg = '';
    this.locationErrorMessage = '';
    let errorMsg: any = false;
    if (this.title == '' || this.title == null || this.locationSelectedItem.length == 0 || this.prospectDetails.start_date == null || this.prospectDetails.end_date == null || this.prospectDetails.start_date == '' || this.prospectDetails.end_date == '' || this.priority == '' || this.meetingType == '' || +this.startTime > +this.endTime) {
      if (this.prospectDetails.start_date == '' || this.prospectDetails.start_date == null) {
        this.startDateErrorMsg = 'Start date is required';
        errorMsg = true;
      }
      if (this.prospectDetails.end_date == '' || this.prospectDetails.end_date == null) {
        this.endDateErrorMsg = 'End date is required';
        errorMsg = true;
      }
      if (this.priority == '') {
        this.priorityErrorMsg = 'Priority is required';
        errorMsg = true;
      }
      if (this.meetingType == '') {
        this.meetingTypeErrorMsg = this.prospectLabel + ' type is required';
        errorMsg = true;
      }
      if (this.title == '' || this.title == null) {
        this.titleErrorMessage = 'Title is required';
        errorMsg = true;
      }
      if (+this.startTime > +this.endTime) {
        this.errorMessage = 'End Time should not be before start time.';
        errorMsg = true;
      }
      if (this.locationSelectedItem.length == 0) {
        this.locationErrorMessage = "Location is required";
        errorMsg = true;
      }

    }
    if (this.teamSelectedItems.length == 0 && this.teamMemberItems.length == 0) {
      this.startDateTimeMin = new Date();
      this.endDateTimeMin = new Date();
      this.prospectDetails.start_date = startDate;//new Date();
      this.prospectDetails.end_date = endDate;//new Date();
      this.errorMessage = "Team or " + this.employeeLabel + " Should not be blank";
      return false;
    }
    if (errorMsg == true) {
      this.prospectDetails.start_date = startDate;//new Date();
      this.prospectDetails.end_date = endDate;//new Date();
      return false;
    }
    if (this.recurring == true) {
      this.recurring = 1;
    } else {
      this.recurring = 0;
    }
    this.loading = true;
    this.disableButton = true;
    this.errorMessage = '';
    this.signin_btn_txt = "Creating";
    let team = '';
    let employee_member = '';
    let event = '';
    let route = '';
    let notification = '';
    let formtemplate = '';
    for (let i = 0; i < this.teamSelectedItems.length; i++) {
      if (i == 0) {
        team = this.teamSelectedItems[i].id;
      } else {
        team = team + ',' + this.teamSelectedItems[i].id;
      }
    }
    for (let i = 0; i < this.teamMemberItems.length; i++) {
      if (i == 0) {
        employee_member = this.teamMemberItems[i].id;
      } else {
        employee_member = employee_member + ',' + this.teamMemberItems[i].id;
      }
    }
    for (let i = 0; i < this.eventSelectedItems.length; i++) {
      if (i == 0) {
        event = this.eventSelectedItems[i].id;
      } else {
        event = event + ',' + this.eventSelectedItems[i].id;
      }
    }
    for (let i = 0; i < this.routeSelectedItems.length; i++) {
      if (i == 0) {
        route = this.routeSelectedItems[i].id;
      } else {
        route = route + ',' + this.routeSelectedItems[i].id;
      }
    }
    for (let i = 0; i < this.notificationSelectedItems.length; i++) {
      if (i == 0) {
        notification = this.notificationSelectedItems[i].id;
      } else {
        notification = notification + ',' + this.notificationSelectedItems[i].id;
      }
    }
    for (let i = 0; i < this.formSelectedItems.length; i++) {
      if (i == 0) {
        formtemplate = this.formSelectedItems[i].id;
      } else {
        formtemplate = formtemplate + ',' + this.formSelectedItems[i].id;
      }
    }
    this.loading = true;
    this.meetingCreatedButton = true;
    this._prospectService.assignProspect(this.prospect_id, team, employee_member, event, route, notification, formtemplate, this.address, this.contact_no, this.email, this.primary_contact_person_name, this.secondry_contact_person_name, this.primary_contact_person_phone, this.secondry_contact_person_phone, this.industry, this.turnover, this.company_size, this.prospectDetails.start_date, this.prospectDetails.end_date, this.priority, this.meetingType, this.description, this.recurring, this.title, this.locationId).subscribe(
      res => {
        this.disableButton = false;
        this.loading = false;
        if (res.result == true) {
          this.commonService.showSuccessMessage(res.message || 'Meeting created successfully');
          localStorage.setItem('prospect_id', res.prospect_id);
          localStorage.setItem('prospect_name', res.prospect_name);
          let routeName = localStorage.getItem('createProspectRouteName');
          let routeStart = localStorage.getItem('createProspectRouteStartDate');
          let routeEnd = localStorage.getItem('createProspectRouteEndDate');
          let routeEmpId = localStorage.getItem('createProspectRouteEmpId');
          let routeLocationId = localStorage.getItem('createProspectRouteLocationId');
          let routeUpdateId = localStorage.getItem('createProspectRouteUpdateId');
          if (routeStart && routeEnd && routeEmpId && routeLocationId) {
            localStorage.setItem('meetingRouteName', routeName);
            localStorage.setItem('meetingRouteStartDate', routeStart);
            localStorage.setItem('meetingRouteEndDate', routeEnd);
            localStorage.setItem('meetingRouteEmpId', routeEmpId);
            localStorage.setItem('meetingRouteLocationId', routeLocationId);
            this.router.navigate(["route/route-create"]);
          } else if (routeUpdateId) {
            localStorage.setItem('meetingRouteUpdateId', routeUpdateId);
            this.router.navigate(["route/route-update"]);
          } else {
            this.router.navigate(["meeting/meeting-list"]);
          }
        } else {
          this.signin_btn_txt = "Submit";
          this.commonService.showErrorMessage(res);
          this.meetingCreatedButton = false;
        }
      },
      error => {
        this.customErrorHandler.handleHttpError(error, 'assignProspect');
        this.loading = false;
        this.disableButton = false;
        this.signin_btn_txt = "Submit";
      });
  }
  getPriorityList() {
    this._prospectService.priorityList().subscribe(
      res => {
        this.priorityList = res.data;
      },
      error => {
        this.customErrorHandler.handleHttpError(error, 'priorityList');
      })
  }
  getMeetingTypeList() {
    this._prospectService.meetingTypeList().subscribe(
      res => {
        this.meetingTypeList = res.data;
        let type = this.meetingTypeList.find(s => s.id == res.default_meeting_type);
        if (typeof type != 'undefined' && type != null) {
          this.meetingType = type.itemName;
        }
      },
      error => {
        this.customErrorHandler.handleHttpError(error, 'priorityList');
      });
  }
  changedTime(time) {
    if (this.endTime < this.prospectDetails.start_date && moment(this.prospectDetails.start_date).format("DD-MM-YYYY") !== moment().format("DD-MM-YYYY")) {
      this.endTime = new Date(this.prospectDetails.start_date.getTime() + 1 * 60000)
    } else {
      this.endTime = new Date(this.prospectDetails.end_date);
      this.endTime = time;
    }
  }
  changedStartTime(time) {
    this.endDateTimeMin = this.prospectDetails.start_date;
    if (this.prospectDetails.start_date.getTime() > time.getTime()) {
      this.startTime = new Date(this.prospectDetails.start_date);
    } else if (moment(this.prospectDetails.start_date).format("DD-MM-YYYY") == moment(this.prospectDetails.end_date).format("DD-MM-YYYY")) {
      this.endTime = new Date(time.getTime() + 1 * 60000)
    }
    if (time > this.prospectDetails.end_date) {
      this.prospectDetails.end_date = time;
    }
  }

  getLocationList(flag = null) {
    this.commonService.getLocationList().subscribe(res => {
      if (res.result == true) {
        this.locationList = res.data;
        this.locationId = localStorage.getItem('createProspectRouteLocationId');
        let assignLocation:any = this.locationList.find(s => s.id == this.locationId);
        if (this.locationId) {
          if (typeof assignLocation != 'undefined' || assignLocation != '') {
            this.locationSelectedItem = [];
            this.locationSelectedItem.push(assignLocation);
          }
        }
        this.disabledLocation = assignLocation.itemName;
        this.teamSelectedItems = [];
        this.teamMemberItems = [];
        this.eventSelectedItems = [];
        this.eventListFunction();
        if(flag == 'init'){
          this.employeeListFunction();
        }
        if (this.locationSelectedItem.length != 0) {
          this.locationErrorMessage = "";
        }
      }
    }, err => {
      this.customErrorHandler.handleHttpError(err, 'getRoleList');
    });
  }

  onLocationItemSelect(item: any) {
    this.locationId = item.id;
    this.teamSelectedItems = [];
    this.teamMemberItems = [];
    this.eventSelectedItems = [];
    this.employeeListFunction();
    this.teamListFunction();
    this.eventListFunction();
    if (this.locationSelectedItem.length != 0) {
      this.locationErrorMessage = "";
    }
  }
  onLocationItemDeSelect(item: any) {
    this.locationId = '';
    this.teamSelectedItems = [];
    this.teamMemberItems = [];
    this.eventSelectedItems = [];
    this.employeeMemberList = [];
  }

  ngOnDestroy() {
    localStorage.removeItem('assigneeId');
    localStorage.removeItem('createProspectRouteName');
    localStorage.removeItem('createProspectRouteStartDate');
    localStorage.removeItem('createProspectRouteEndDate');
    localStorage.removeItem('createProspectRouteEmpId');
    localStorage.removeItem('createProspectRouteLocationId');
    localStorage.removeItem('createProspectRouteUpdateId');
  }
}
