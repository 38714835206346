import { MeetingService } from './../../../services/meeting/meeting.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { CustomErrorHandler } from '../../../errorhandler/custom-errorhandler';
import { CommonService } from './../../../services/common/common.service';
import swal from 'sweetalert2';
import { Constants } from '../../../common/constants';
declare var moment: any;
@Component({
  selector: 'meeting-edit',
  templateUrl: 'meeting-edit.component.html',
  styleUrls: ['meeting-edit.component.css']
})
export class MeetingEditComponent implements OnInit, OnDestroy {
  loading: boolean;
  prospectLabel: string;
  employeeLabel: string;
  modal: any;
  company_id: string;
  team: any[];
  employee: any[];
  area: any[];
  team_show: boolean;
  lat: string;
  lng: string;
  name: string;
  area_id: string;
  area_name: string;
  loginFormEmail: string;
  status: string;
  errorMessage: string;
  disableButton: boolean;
  signin_btn_txt: string;
  loginDiv: boolean;
  tenantList: any[];
  accessToken: string;
  image_url: string;
  prospect_id: string;
  prospect_name: string;
  edit_prospect_id: string;
  address;
  contact_no;
  email;
  primary_contact_person_name;
  primary_contact_person_phone;
  secondry_contact_person_name;
  secondry_contact_person_phone;
  turnover;
  industry = 'Select';
  company_size = 'Select';
  teamList: any = [];
  employeeMemberList: any = [];
  eventList: any = [];
  routeList: any = [];
  notificationList: any = [];
  formList: any = [];
  emailVal = '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$';
  teamSelectedItems = [];
  TeamMemberItems = [];
  eventSelectedItems = [];
  routeSelectedItems = [];
  notificationSelectedItems = [];
  formSelectedItems = [];
  teamSettings = {};
  employeeMemberSettings = {};
  eventSettings = {};
  routeSettings = {};
  notificationSettings = {};
  formSettings = {};
  start_date: string;
  end_date: string;
  priority: string;
  meetingType: string;
  description: string;
  priorityList: any = [];
  meetingTypeList: any = [];
  endDateErrorMsg: any;
  endTimeEroorMsg: any;
  startDateErrorMsg: any;
  startTimeErrorMsg: any;
  meetingTypeErrorMsg: any;
  priorityErrorMsg: any;
  startFlag: string;
  endFlag: string;
  recurring: any;
  prospectDetails: any = {};
  title: any;
  titleErrorMessage: any;
  oldEmployeeIds: any;
  startDateTimeMin: any;
  endDateTimeMin: any;
  startTime: any;
  endTime: any;
  locationList: any = [];
  locationSettings: any = {};
  locationSelectedItem: any = [];
  locationId: any = '';
  locationErrorMessage: any = '';
  locationLabel: any = Constants.locationLabel;

  constructor(private router: Router, public _commonService: CommonService, public _prospectService: MeetingService, private customErrorHandler: CustomErrorHandler) {
  }
  ngOnInit() {
    this.startDateTimeMin = new Date();
    this.endDateTimeMin = new Date();
    this.oldEmployeeIds = '';
    this.priority = 'Low';
    this.meetingType = '';
    this.loading = false;
    this.getLocationList();
    this.getPriorityList();
    this.getMeetingTypeList();
    this.prospectLabel = localStorage.getItem('prospectLabel');
    this.employeeLabel = localStorage.getItem('employeeLabel');
    this.prospect_id = localStorage.getItem('edit_prospect_id');
    
    this.notificationListFunction();
    this.formListFunction();
    this.teamSelectedItems = [];
    this.TeamMemberItems = [];
    this.eventSelectedItems = [];
    this.routeSelectedItems = [];
    this.notificationSelectedItems = [];
    this.teamSettings = {
      singleSelection: false,
      text: "Select Team",
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      enableSearchFilter: true,
      classes: "myclass custom-class"
    };
    this.employeeMemberSettings = {
      singleSelection: false,
      text: "Select " + this.employeeLabel,
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      enableSearchFilter: true,
      classes: "myclass custom-class"
    };
    this.eventSettings = {
      singleSelection: true,
      text: "Select Event",
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      enableSearchFilter: true,
      classes: "myclass custom-class singleSelection"
    };
    this.routeSettings = {
      singleSelection: true,
      text: "Select Route",
      enableSearchFilter: true,
      classes: "myclass custom-class"
    };
    this.notificationSettings = {
      singleSelection: false,
      text: "Select Notification type",
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      enableSearchFilter: true,
      classes: "myclass custom-class"
    };
    this.formSettings = {
      singleSelection: false,
      text: "Select Digital Form",
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      enableSearchFilter: true,
      classes: "myclass custom-class"
    };

    this.locationSettings = {
      singleSelection: true,
      text: "Select "+ Constants.locationLabel,
      enableSearchFilter: true,
      classes: "singleSelection"
    }
  }
  onOpen(event: any) {
    this.teamList = this.teamList.map((item) => {
      return {
        id: item.id,
        itemName: item.itemName
      }
    });
    this.employeeMemberList = this.employeeMemberList.map((item) => {
      return {
        id: item.id,
        itemName: item.itemName
      }
    });
    this.eventList = this.eventList.map((item) => {
      return {
        id: item.id,
        itemName: item.itemName
      }
    });
    this.routeList = this.routeList.map((item) => {
      return {
        id: item.id,
        itemName: item.itemName
      }
    });
    this.formList = this.formList.map((item) => {
      return {
        id: item.id,
        itemName: item.itemName
      }
    });
  }
  SelectProspectById(id) {
    this.loading = true;
    this._prospectService.SelectProspectById(id).subscribe(
      res => {
        this.loading = false;
        if (res.result == true) {
          this.prospect_id = res.data.prospect_id;
          this.prospect_name = res.data.place_name;
          this.area_name = res.data.area_name;
          this.teamSelectedItems = res.data.team;
          this.TeamMemberItems = res.data.employee_member;
          for (let i = 0; i < this.TeamMemberItems.length; i++) {
            if (i > 0) {
              this.oldEmployeeIds += ',';
            }
            this.oldEmployeeIds += this.TeamMemberItems[i].id;
          }
          let empId = localStorage.getItem('assigneeId');
          let assignEmp = this.employeeMemberList.find(s => s.id == empId);
          if (empId) {
            if (typeof assignEmp != 'undefined' || assignEmp != '') {
              this.TeamMemberItems.push(assignEmp);
            }
          }
          this.eventSelectedItems = [];
          this.routeSelectedItems = res.data.route;
          this.notificationSelectedItems = [];
          this.formSelectedItems = res.data.formtemplate;
          this.address = res.data.address;
          this.contact_no = res.data.phone;
          this.email = res.data.email;
          this.primary_contact_person_name = res.data.contact_person_name;
          this.primary_contact_person_phone = res.data.contact_person_phone;
          this.secondry_contact_person_name = res.data.contact_person_name1;
          this.secondry_contact_person_phone = res.data.contact_person_phone1;
          this.turnover = res.data.turnover;
          this.industry = res.data.industry_type;
          this.company_size = res.data.company_size;
          this.title = res.data.title;
          this.eventSelectedItems = res.data.event;
          if (res.data.start_date != null && res.data.start_date != '') {
            let start = moment(res.data.start_date);
            this.prospectDetails.start_date = new Date(start);
            this.startTime = new Date(start);
          }
          if (res.data.end_date != null && res.data.end_date != '') {
            let end = moment(res.data.end_date);
            this.prospectDetails.end_date = new Date(end);
            this.endTime = new Date(end);
            this.endDateTimeMin = new Date(end);
          }
          this.priority = res.data.priority;
          this.meetingType = res.data.meeting_type;
          this.description = res.data.description;
          if (res.data.recurring == '1') {
            this.recurring = true;
          } else {
            this.recurring = false;
          }
          this.locationId = res.data.location_id;
          if(this.locationId && this.locationId != '' && this.locationId != 0){
            let status = 'edit';
            this.employeeListFunction(status);
            this.teamListFunction(status);
            this.eventListFunction(status);
          }else{
            this.TeamMemberItems = [];
            this.teamSelectedItems = [];
            this.eventSelectedItems = [];
          }

          this.locationSelectedItem = [];
          let temp = this.locationList.find(s => s.id == res.data.location_id);
          if (temp != undefined) {
            this.locationSelectedItem.push(temp);
          }

        }
      },
      error => {
        this.loading = false;
        this.customErrorHandler.handleHttpError(error, 'SelectProspectById');
      });
  }
  employeeListFunction(status = null) {
    this._prospectService.employeeLocationList(this.locationId).subscribe(
      res => {
        this.employeeMemberList = res.data;
        if(status == 'edit')
        {
          let employeeList = this.TeamMemberItems;
          this.TeamMemberItems = [];
          for(let i = 0;i< employeeList.length; i++)
          {
            let emptemp = this.employeeMemberList.find(s => s.id == employeeList[i].id);
            if (emptemp) {
              if (typeof emptemp != 'undefined' || emptemp != '') {
                this.TeamMemberItems.push(emptemp);
              }
            }
          }  
        }
        // this.teamSelectedItems = res.data.team;
      },
      error => {
        this.customErrorHandler.handleHttpError(error, 'employeeList');
      });
  }
  teamListFunction(status = null) {
    this._prospectService.teamList(this.locationId).subscribe(
      res => {
        this.teamList = res.data;
        if(status == 'edit')
        {
          let teamInfo = this.teamSelectedItems;
          this.teamSelectedItems = [];
          for(let i = 0;i< teamInfo.length; i++)
          {
            let teamTemp = this.teamList.find(s => s.id == teamInfo[i].id);
            if (teamTemp) {
              if (typeof teamTemp != 'undefined' || teamTemp != '') {
                this.teamSelectedItems.push(teamTemp);
              }
            }
          }  
        }
      },
      error => {
        this.customErrorHandler.handleHttpError(error, 'teamList');
      });
  }
  eventListFunction(status = null) {
    this._prospectService.eventList(this.locationId).subscribe(
      res => {
        this.eventList = res.data;
        if(status == 'edit')
        {
          let eventInfo = this.eventSelectedItems;
          this.eventSelectedItems = [];
          for(let i = 0;i< eventInfo.length; i++)
          {
            let eventTemp = this.eventList.find(s => s.id == eventInfo[i].id);
            if (eventTemp) {
              if (typeof eventTemp != 'undefined' || eventTemp != '') {
                this.eventSelectedItems.push(eventTemp);
              }
            }
          }  
        }
      },
      error => {
        this.customErrorHandler.handleHttpError(error, 'eventList');
      });
  }
  routeListFunction() {
    this._prospectService.routeList().subscribe(
      res => {
        this.routeList = res.data;
      },
      error => {
        this.customErrorHandler.handleHttpError(error, 'routeList');
      });
  }
  notificationListFunction() {
    this._prospectService.notificationList().subscribe(
      res => {
        this.notificationList = res.data;
      },
      error => {
        this.customErrorHandler.handleHttpError(error, 'notificationList');
      });
  }
  formListFunction() {
    this._prospectService.formList().subscribe(
      res => {
        this.formList = res.data;
      },
      error => {
        this.customErrorHandler.handleHttpError(error, 'formList');
      });
  }
  onSubmit() {
    let is_same = 0;
    let startDate = this.prospectDetails.start_date;
    this.prospectDetails.start_date = moment(startDate).format('YYYY-MM-DD') + ' ' + moment(this.startTime).format('hh:mm A');
    let endDate = this.prospectDetails.end_date
    this.prospectDetails.end_date = moment(endDate).format('YYYY-MM-DD') + ' ' + moment(this.endTime).format('hh:mm A');
    this.titleErrorMessage = '';
    this.endDateErrorMsg = '';
    this.endTimeEroorMsg = '';
    this.startDateErrorMsg = '';
    this.startTimeErrorMsg = '';
    this.meetingTypeErrorMsg = '';
    this.priorityErrorMsg = '';
    this.locationErrorMessage = '';
    let errorMsg = false;
    if (this.title == '' || this.title == null || this.prospectDetails.start_date == null || this.prospectDetails.end_date == null || this.prospectDetails.start_date == '' || this.prospectDetails.end_date == '' || this.priority == '' || this.meetingType == '' || +this.startTime > +this.endTime) {
      if (this.prospectDetails.start_date == '' || this.prospectDetails.start_date == null) {
        this.startDateErrorMsg = 'Start date is required';
        this.startTimeErrorMsg = 'Start time is required';
        errorMsg = true;
      }
      if (this.prospectDetails.end_date == '' || this.prospectDetails.end_date == null) {
        this.endDateErrorMsg = 'End date is required';
        this.endTimeEroorMsg = 'End time is required';
        errorMsg = true;
      }
      if (this.priority == '') {
        this.priorityErrorMsg = 'Priority is required';
        errorMsg = true;
      }
      if (this.meetingType == '') {
        this.meetingTypeErrorMsg = this.prospectLabel + ' type is required';
        errorMsg = true;
      }
      if (this.title == '' || this.title == null) {
        this.titleErrorMessage = 'Title is required';
        errorMsg = true;
      }
      if (+this.startTime > +this.endTime) {
        this.errorMessage = 'End Time should not be before start time.';
        errorMsg = true;
      }
      if(this.locationSelectedItem.length == 0){
        this.locationErrorMessage = "Location is required";
        errorMsg = true;
      }
    }
    if (this.teamSelectedItems.length == 0 && this.TeamMemberItems.length == 0) {
      this.startDateTimeMin = new Date();
      this.endDateTimeMin = new Date();
      this.prospectDetails.start_date = startDate;//new Date();
      this.prospectDetails.end_date = endDate;//new Date();
      this.errorMessage = "Team or employee Should not be blank";
      return false;
    }
    if (errorMsg == true) {
      this.prospectDetails.start_date = startDate;//new Date();
      this.prospectDetails.end_date = endDate;//new Date();
      return false;
    }
    this.disableButton = true;
    this.errorMessage = '';
    this.signin_btn_txt = "Creating";
    let team = '';
    let employee_member = '';
    let event = '';
    let route = '';
    let notification = '';
    let formtemplate = '';
    for (let i = 0; i < this.teamSelectedItems.length; i++) {
      if (i == 0) {
        team = this.teamSelectedItems[i].id;
      } else {
        team = team + ',' + this.teamSelectedItems[i].id;
      }
    }
    for (let i = 0; i < this.TeamMemberItems.length; i++) {
      if (i == 0) {
        employee_member = this.TeamMemberItems[i].id;
      } else {
        employee_member = employee_member + ',' + this.TeamMemberItems[i].id;
      }
    }
    for (let i = 0; i < this.eventSelectedItems.length; i++) {
      if (i == 0) {
        event = this.eventSelectedItems[i].id;
      } else {
        event = event + ',' + this.eventSelectedItems[i].id;
      }
    }
    for (let i = 0; i < this.routeSelectedItems.length; i++) {
      if (i == 0) {
        route = this.routeSelectedItems[i].id;
      } else {
        route = route + ',' + this.routeSelectedItems[i].id;
      }
    }
    for (let i = 0; i < this.notificationSelectedItems.length; i++) {
      if (i == 0) {
        notification = this.notificationSelectedItems[i].id;
      } else {
        notification = notification + ',' + this.notificationSelectedItems[i].id;
      }
    }
    for (let i = 0; i < this.formSelectedItems.length; i++) {
      if (i == 0) {
        formtemplate = this.formSelectedItems[i].id;
      } else {
        formtemplate = formtemplate + ',' + this.formSelectedItems[i].id;
      }
    }
    if (this.recurring == true) {
      this.recurring = 1;
    } else {
      this.recurring = 0;
    }
    let deleteMeetingFromRoute = false;
    if ((this.prospectDetails.start_date != this.startFlag) || (this.prospectDetails.end_date != this.endFlag)) {
      let meetingId = this.prospect_id;
      let startDateChange = moment(this.prospectDetails.start_date);
      startDateChange = startDateChange.format('YYYY-MM-DD H:mm:s');
      let endDateChange = moment(this.prospectDetails.end_date);
      endDateChange = endDateChange.format('YYYY-MM-DD H:mm:s');
      this._prospectService.checkMeetingInRoute(meetingId, startDateChange, endDateChange, employee_member, this.oldEmployeeIds).subscribe(
        res => {
          if (res.result == true) {
            deleteMeetingFromRoute = true;
            swal({
              text: res.message,
              type: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#d33',
              cancelButtonColor: '#3085d6',
              confirmButtonText: 'Ok',
            }).then((result) => {
              if (typeof result.value != 'undefined') {
                this.loading = true;
                this._prospectService.editProspect(this.prospect_id, this.prospect_name, team, employee_member, event, route, notification, formtemplate, this.area_id, this.address, this.contact_no, this.email, this.primary_contact_person_name, this.secondry_contact_person_name, this.primary_contact_person_phone, this.secondry_contact_person_phone, this.industry, this.turnover, this.company_size, this.prospectDetails.start_date, this.prospectDetails.end_date, this.priority, this.meetingType, this.description, this.recurring, this.title, deleteMeetingFromRoute, is_same, this.locationId).subscribe(
                  res => {
                    this.loading = false;
                    if (res.result == true) {
                      this._commonService.showSuccessMessage(res.message || 'Meeting updated successfully');
                      localStorage.setItem('prospect_id', res.prospect_id);
                      localStorage.setItem('prospect_name', res.prospect_name);
                      this.router.navigate(["meeting/meeting-list"]);
                    } else {
                      this.disableButton = false;
                      this.signin_btn_txt = "Submit";
                      this.errorMessage = res.message;
                    }
                  },
                  error => {
                    this.customErrorHandler.handleHttpError(error, 'editProspect');
                    this.loading = false;
                    this.disableButton = false;
                    this.signin_btn_txt = "Submit";
                  });
              } else {
                this.loading = false;
              }
            });
          } else {
            this.loading = true;
            this._prospectService.editProspect(this.prospect_id, this.prospect_name, team, employee_member, event, route, notification, formtemplate, this.area_id, this.address, this.contact_no, this.email, this.primary_contact_person_name, this.secondry_contact_person_name, this.primary_contact_person_phone, this.secondry_contact_person_phone, this.industry, this.turnover, this.company_size, this.prospectDetails.start_date, this.prospectDetails.end_date, this.priority, this.meetingType, this.description, this.recurring, this.title, deleteMeetingFromRoute, is_same, this.locationId).subscribe(
              res => {
                this.loading = false;
                if (res.result == true) {
                  this._commonService.showSuccessMessage(res.message || 'Meeting updated successfully');
                  localStorage.setItem('prospect_id', res.prospect_id);
                  localStorage.setItem('prospect_name', res.prospect_name);
                  this.router.navigate(["meeting/meeting-list"]);
                } else {
                  console.log(res, 'resres');
                  is_same = res.data.is_same;
                  this.disableButton = false;
                  this.signin_btn_txt = "Submit";
                  swal({
                    text: res.message,
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#d33',
                    cancelButtonColor: '#3085d6',
                    confirmButtonText: 'Yes',
                  }).then((result) => {
                    if (typeof result.value != 'undefined') {
                      this._prospectService.editProspect(this.prospect_id, this.prospect_name, team, employee_member, event, route, notification, formtemplate, this.area_id, this.address, this.contact_no, this.email, this.primary_contact_person_name, this.secondry_contact_person_name, this.primary_contact_person_phone, this.secondry_contact_person_phone, this.industry, this.turnover, this.company_size, this.prospectDetails.start_date, this.prospectDetails.end_date, this.priority, this.meetingType, this.description, this.recurring, this.title, deleteMeetingFromRoute, is_same, this.locationId).subscribe(
                        res => {
                          if (res.result == true) {
                            this._commonService.showSuccessMessage(res.message || 'Meeting updated successfully');
                            localStorage.setItem('prospect_id', res.prospect_id);
                            localStorage.setItem('prospect_name', res.prospect_name);
                            this.router.navigate(["meeting/meeting-list"]);
                          } else {
                            this._commonService.showErrorMessage(res);
                          }
                        },
                        error => {
                          this.customErrorHandler.handleHttpError(error, 'createProspect');
                        });
                    }
                  });
                }
              },
              error => {
                this.customErrorHandler.handleHttpError(error, 'editProspect');
                this.loading = false;
                this.disableButton = false;
                this.signin_btn_txt = "Submit";
              });
          }
        },
        error => {
          this.loading = false;
          this.customErrorHandler.handleHttpError(error, 'priorityList');
        });
    } else {
      this._prospectService.editProspect(this.prospect_id, this.prospect_name, team, employee_member, event, route, notification, formtemplate, this.area_id, this.address, this.contact_no, this.email, this.primary_contact_person_name, this.secondry_contact_person_name, this.primary_contact_person_phone, this.secondry_contact_person_phone, this.industry, this.turnover, this.company_size, this.prospectDetails.start_date, this.prospectDetails.end_date, this.priority, this.meetingType, this.description, this.recurring, this.title, deleteMeetingFromRoute, is_same, this.locationId).subscribe(
        res => {
          this.loading = false;
          console.log(res);
          if (res.result == true) {
            this._commonService.showSuccessMessage(res.message || 'Meeting updated successfully');
            localStorage.setItem('prospect_id', res.prospect_id);
            localStorage.setItem('prospect_name', res.prospect_name);
            this.router.navigate(["meeting/meeting-list"]);
          } else {
            this.disableButton = false;
            this.signin_btn_txt = "Submit";
            this._commonService.showErrorMessage(res);
          }
        },
        error => {
          this.customErrorHandler.handleHttpError(error, 'editProspect');
          this.loading = false;
          this.disableButton = false;
          this.signin_btn_txt = "Submit";
        });
    }
  }
  getPriorityList() {
    this._prospectService.priorityList().subscribe(
      res => {
        this.priorityList = res.data;
      },
      error => {
        this.customErrorHandler.handleHttpError(error, 'priorityList');
      });
  }
  getMeetingTypeList() {
    this._prospectService.meetingTypeList().subscribe(
      res => {
        this.meetingTypeList = res.data;
      },
      error => {
        this.customErrorHandler.handleHttpError(error, 'priorityList');
      });
  }
  changedTime(time) {
    if (this.endTime < this.prospectDetails.start_date && moment(this.prospectDetails.start_date).format("DD-MM-YYYY") !== moment().format("DD-MM-YYYY")) {
      this.endTime = new Date(this.prospectDetails.start_date.getTime() + 1 * 60000)
    } else {
      this.endTime = new Date(this.prospectDetails.end_date);
      this.endTime = time;
    }
  }
  changedStartTime(time) {
    this.endDateTimeMin = this.prospectDetails.start_date;
    if (this.prospectDetails.start_date.getTime() > time.getTime()) {
      this.startTime = new Date(this.prospectDetails.start_date);
    } else if (moment(this.prospectDetails.start_date).format("DD-MM-YYYY") == moment(this.prospectDetails.end_date).format("DD-MM-YYYY")) {
      this.endTime = new Date(time.getTime() + 1 * 60000)
    }
    if (time > this.prospectDetails.end_date) {
      this.prospectDetails.end_date = time;
    }
  }

  onLocationItemSelect(item: any) {
    this.locationId = item.id;
    this.teamSelectedItems = [];
    this.TeamMemberItems = [];
    this.eventSelectedItems = [];
    this.employeeListFunction();
    this.teamListFunction();
    this.eventListFunction();
    if(this.locationSelectedItem.length != 0){
      this.locationErrorMessage = "";
    }
  }
  onLocationItemDeSelect(item: any) {
    this.locationId = '';
    this.teamSelectedItems = [];
    this.TeamMemberItems = [];
    this.eventSelectedItems = [];
    this.employeeMemberList = [];
    this.teamList = [];
  }

  getLocationList() {
    this._commonService.getLocationList().subscribe(res => {
      if (res.result == true) {
        this.locationList = res.data;
        this.SelectProspectById(this.prospect_id);
      }
    }, err => {
      this.customErrorHandler.handleHttpError(err, 'getRoleList');
    });
  }

  ngOnDestroy() {
    localStorage.removeItem('assigneeId');
  }
}
