import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { LoginService } from '../../services/login/login.service';
import { CustomErrorHandler } from '../../errorhandler/custom-errorhandler';
import { CommonService } from './../../services/common/common.service';
declare var $: any;
@Component({
  selector: 'reset-password',
  templateUrl: 'reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  userEmail: string;
  status: string;
  errorMessage: string;
  disableButton: boolean;
  signin_btn_txt: string;
  loginDiv: boolean;
  tenantList: any[];
  accessToken: string;
  successMessage: string;
  loading: boolean;
  constructor(public router: Router, private commonService: CommonService, private loginService: LoginService, public _loginService: LoginService, private customErrorHandler: CustomErrorHandler) {
  }
  ngOnInit() {
    this.successMessage = '';
    this.errorMessage = '';
    this.disableButton = false;
    this.signin_btn_txt = "Reset Password";
    var loginchk = localStorage.getItem('isLogin');
    if (loginchk == "1") {
      this.doLogout();
    }
    var url = window.location.href;
    let index = url.indexOf("=");
    if (index != -1) {
      this.userEmail = decodeURIComponent(url.substr(url.indexOf("=") + 1));
    }
    var queryString = window.location.search;
    queryString = queryString.substring(1);
    $('#carouselExampleIndicators').carousel({
      interval: 3000
    });
    document.getElementsByTagName('html')[0].classList.add('login_body');
  }
  onSubmit(model) {
    if (model.password.trim() == '') {
      this.errorMessage = "please enter password";
      return false;
    }
    if (model.password != model.confirmPassword) {
      this.errorMessage = "confirm password not matched.";
      return false;
    }
    this.disableButton = true;
    this.errorMessage = '';
    this.successMessage = '';
    this.signin_btn_txt = "Reset Password";
    this._loginService.resetPassword(this.userEmail, model.password).subscribe(
      res => {
        this.loading = false;
        if (res.result == true) {
          this.disableButton = false;
          this.commonService.showSuccessMessage('reset password successful');
          this.router.navigate(['login']);
        } else {
          this.disableButton = false;
          this.signin_btn_txt = "Reset Password";
          this.errorMessage = res.message;
          this.commonService.showCustomError(this.errorMessage);
        }
        // this.router.navigate(['login']);
      },
      error => {
        this.loading = false;
        this.customErrorHandler.handleHttpError(error, 'resetPassword');
        this.disableButton = false;
        this.signin_btn_txt = "Reset Password";
      });
  }
  doLogout() {
    this.loginService.logOut().subscribe(
      res => {
        this.loading = false;
        if (res.result == true) {
          localStorage.clear();
          window.location.reload();
        }
      },
      error => {
        this.loading = false;
        this.customErrorHandler.handleHttpError(error, 'logOut');
      });
  }
  ngOnDestroy() {
    document.getElementsByTagName('html')[0].classList.remove('login_body');
  }
}
