import { Injectable } from '@angular/core';
import { HttpHelper } from '../../helpers/http.helper';
import { Config } from '../../config/config';
const company_id = localStorage.getItem('company_id');
@Injectable()
export class TrackingService {
  constructor(private _httpHelper: HttpHelper) {
  }
  trackingData(id, date) {
    try {
      var request = {
        "company_id": company_id,
        "employee_id": id,
        "date": date
      };
      return this._httpHelper.post(Config.AuthenticationBaseURL + 'trackingData', request)
    }
    catch (e) {
    }
  }
  trackingDataWeb(id, date, startTime, endTime) {
    try {
      var request = {
        "company_id": company_id,
        "employee_id": id,
        "date": date,
        "start_time": startTime,
        "end_time": endTime
      };
      return this._httpHelper.post(Config.AuthenticationBaseURL + 'trackingdataweb', request);
    }
    catch (e) {
    }
  }
  trackingProspectData(id, date, startTime = null, endTime = null) {
    try {
      var request = {
        "company_id": company_id,
        "employee_id": id,
        "date": date,
        "start_time": startTime,
        "end_time": endTime
      };
      return this._httpHelper.post(Config.AuthenticationBaseURL + 'trackingProspectData', request)
    }
    catch (e) {
    }
  }
  employeeById(id) {
    try {
      var request = {
        "company_id": company_id,
        "employee_id": id,
      };
      return this._httpHelper.post(Config.AuthenticationBaseURL + 'employeeById', request)
    }
    catch (e) {
    }
  }
}
