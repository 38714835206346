import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'MessageDatetimeFormatterPipe'
})
export class MessageDatetimeFormatterPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    if (!value) {
      return null;
    }
    const date = typeof value === 'object' ? value : new Date(value);
    const DAY_IN_MS: number = 86400000; // 24 * 60 * 60 * 1000
    const today: any = new Date();
    const yesterday: any = new Date(today - DAY_IN_MS);
    const seconds: any = Math.round((today - date) / 1000);
    const minutes = Math.round(seconds / 60);
    const isToday = today.toDateString() === date.toDateString();
    const isYesterday = yesterday.toDateString() === date.toDateString();
    const isThisYear = today.getFullYear() === date.getFullYear();
    if (seconds < 60) {
      return 'now';
    } else if (seconds < 90) {
      return 'about a minute ago';
    } else if (minutes < 60) {
      return `${minutes} minutes ago`;
    } else if (isToday) {
      return this.getFormattedDate(date, 'Today'); // Today at 10:20
    } else if (isYesterday) {
      return this.getFormattedDate(date, 'Yesterday'); // Yesterday at 10:20
    } else if (isThisYear) {
      return this.getFormattedDate(date, '', true); // 10. January at 10:20
    }
    return this.getFormattedDate(date); // 10. January 2017. at 10:20
  }
  getFormattedDate(date, prefomattedDate = '', hideYear = false) {
    const monthNames = [
      'January', 'February', 'March', 'April', 'May', 'June',
      'July', 'August', 'September', 'October', 'November', 'December'
    ];
    const day = date.getDate();
    const month = monthNames[date.getMonth()];
    const year = date.getFullYear();
    const hours = date.getHours();
    let minutes = date.getMinutes();
    if (minutes < 10) {
      // Adding leading zero to minutes
      minutes = `0${minutes}`;
    }
    if (prefomattedDate != '') {
      // Today at 10:20
      // Yesterday at 10:20
      return `${prefomattedDate} at ${hours}:${minutes}`;
    }
    if (hideYear) {
      // 10. January at 10:20
      return `${day} ${month} at ${hours}:${minutes}`;
    }
    // 10. January 2017. at 10:20
    return `${day} ${month} ${year} at ${hours}:${minutes}`;
  }
}
