import { Component, OnInit } from '@angular/core';
import { AssignService } from '../../../services/assign/assign.service';
import { ReportService } from '../../../services/report/report.service';
import { Angular2Csv } from 'angular2-csv/Angular2-csv';
import { CustomErrorHandler } from '../../../errorhandler/custom-errorhandler';
import { CommonService } from '../../../services/common/common.service';
import { Constants } from '../../../common/constants';
declare var moment: any;
declare var $: any;
@Component({
  selector: 'app-location-wise-expense',
  templateUrl: './location-wise-expense.component.html',
  styleUrls: ['./location-wise-expense.component.css']
})
export class LocationWiseExpenseComponent implements OnInit {
  tempSearch: any;
  pageNo: number = 1;
  perPage: number = 10;
  totalItem: number = 0;
  expenseList: any = [];
  page: number = 2;
  startDate: any;
  endDate: any;
  search: any;
  export: any;
  noDataMsg: string;
  loading: boolean;
  isDemoTenant: boolean;
  locationList: any = [];
  locationSettings: any = {};
  locationSelectedItem: any = [];
  locationId: any = '';
  defaultSelectObject: any = { id: '', itemName: 'All' };
  filterLabel = Constants.filterLabel;
  constructor(private commonService: CommonService, public _assignService: AssignService, public _reportService: ReportService, private customErrorHandler: CustomErrorHandler) {
  }
  ngOnInit() {
    this.isDemoTenant = (localStorage.getItem('isDemoTenant') == 'true');
    this.search = '';
    this.tempSearch = '';
    var start = moment().subtract(6, 'days');
    var end = moment();
    var _this;
    _this = this;
    function cb(start, end) {
      $('#reportrange span').html(start.format('MMM D, YYYY') + ' - ' + end.format('MMM D, YYYY'));
      var f_start = start.format('YYYY-MM-DD');
      var f_end = end.format('YYYY-MM-DD');
      _this.startDate = f_start;
      _this.endDate = f_end;
      _this.getExpenseReport(1);
    }
    $('#reportrange').daterangepicker({
      startDate: start,
      endDate: end,
      ranges: {
        'Today': [moment(), moment(),],
        'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
        'Last 7 Days': [moment().subtract(6, 'days'), moment()],
        'Last 30 Days': [moment().subtract(29, 'days'), moment()],
        'This Month': [moment().startOf('month'), moment().endOf('month'), 'inclusive'],
        'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
      }
    }, cb);
    cb(start, end);
    this.getLocationList();
    this.locationSettings = {
      singleSelection: true,
      text: "Select "+ Constants.locationLabel,
      enableSearchFilter: true,
      classes: "singleSelection block_elem m_b_15"
    };
  }
  searchfunction() {
    if (this.tempSearch != this.search) {
      this.tempSearch = this.search;
      this.getExpenseReport(1);
    }
  }
  getExpenseReport(currentPage) {
    this.loading = true;
    this.pageNo = currentPage;
    this.page = currentPage;
    this.export = '';
    let requestParameter = {
      page: this.pageNo,
      per_page: this.perPage,
      start_date: this.startDate,
      end_date: this.endDate,
      location_id: this.locationId,
      search: this.search,
      export: this.export
    }
    this._reportService.locationWiseExpenseReport(requestParameter).subscribe(
      res => {
        this.loading = false;
        this.expenseList = [];
        this.noDataMsg = res.message || 'Currently, there is no data available.'
        if (res.result == true) {
          this.totalItem = res.data.total;
          this.expenseList = res.data.data;
        } else {
          this.noDataMsg = res.message;
        }
      },
      error => {
        this.customErrorHandler.handleHttpError(error, 'employeeReport');
        this.loading = false;
      });
  }
  exportExpenseReport() {
    this.export = 'yes';
    let requestParameter = {
      page: this.pageNo,
      per_page: this.perPage,
      start_date: this.startDate,
      end_date: this.endDate,
      location_id: this.locationId,
      search: this.search,
      export: this.export
    }
    this._reportService.locationWiseExpenseReport(requestParameter).subscribe(
      res => {
        var options = {
          fieldSeparator: ',',
          quoteStrings: '"',
          decimalseparator: '.',
          showLabels: true,
          showTitle: true,
          useBom: true,
          title: "Location Wise Expenses Report"
        };
        new Angular2Csv(res.data, 'Expense Report', options);
      },
      error => {
        this.customErrorHandler.handleHttpError(error, 'employeeReport');
      });
  }
  getLocationList() {
    this.commonService.getLocationList().subscribe(res => {
      if (res.result == true) {
        this.locationList = res.data;
        if (this.locationList.length > 1) {
          this.locationList.unshift(this.defaultSelectObject);
        }
      }
    }, error => {
      this.customErrorHandler.handleHttpError(error, 'getRoleList');
    });
  }
  onLocationItemSelect(item: any) {
    this.locationId = item.id;
    this.getExpenseReport(1);
  }
  onLocationItemDeSelect(item: any) {
    this.locationId = '';
    this.getExpenseReport(1);
  }
  ngOnDestroy() {
    $('.fullHeightBlock').animate({ scrollTop: 0 }, 400);
  }
}
