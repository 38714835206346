import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CustomErrorHandler } from './../../../errorhandler/custom-errorhandler';
import { CommonService } from '../../../services/common/common.service';
import { DigitalFormService } from './../../../services/digital-form/digital-form.service';
@Component({
  selector: 'create-digital-form',
  templateUrl: 'create-digital-form.component.html',
  styleUrls: ['./create-digital-form.component.css']
})
export class CreateDigitalFormComponent implements OnInit {
  errorMessage: string;
  disableButton: boolean;
  signin_btn_txt: string;
  templatedata: any[] = [];
  xyzlist: Array<any> = [];
  title: string;
  optionMsg = '';
  constructor(
    private router: Router,
    public digitalFormService: DigitalFormService,
    private customErrorHandler: CustomErrorHandler,
    public commonService: CommonService
  ) { }
  ngOnInit() {
    this.templatedataList();
  }
  templatedataList() {
    this.digitalFormService.templatedataList().subscribe(
      res => {
        if (res.result != 'Invalid Token') {
          this.templatedata = res.data;
        }
      },
      error => {
        this.customErrorHandler.handleHttpError(error, 'templatedataList');
      });
  }
  onClicked(item1, event) {
    if (event.target.checked) {
      this.xyzlist.push({ 'value': event.target.value });
    } else {
      let index = this.xyzlist.indexOf(event.target.value);
      this.xyzlist.splice(index, 1);
    }
  }
  onSubmit() {
    this.optionMsg = '';
    if (this.title == '' || this.title == undefined) {
      this.errorMessage = "Name Should not be blank";
      return false;
    }
    if (this.xyzlist.length == 0) {
      this.optionMsg = "Please select at least one option";
      return false;
    }
    let form_data = JSON.stringify(this.xyzlist);
    this.disableButton = true;
    this.errorMessage = '';
    this.signin_btn_txt = "Creating";
    this.digitalFormService.createformTemplate(this.title, form_data).subscribe(
      res => {
        if (res.result == true) {
          this.commonService.showSuccessMessage(res.message);
          this.router.navigate(["setting/digital-form"]);
        } else {
          this.disableButton = false;
          this.signin_btn_txt = "Submit";
          this.commonService.showErrorMessage(res);
        }
      },
      error => {
        this.customErrorHandler.handleHttpError(error, 'createformTemplate');
        this.disableButton = false;
        this.signin_btn_txt = "Submit";
      });
  }
  temp = Array;
  math = Math;
}
