import { Component, OnInit } from '@angular/core';
import { AssignService } from '../../../services/assign/assign.service';
import { ReportService } from '../../../services/report/report.service';
import { Angular2Csv } from 'angular2-csv/Angular2-csv';
import { CustomErrorHandler } from '../../../errorhandler/custom-errorhandler';
import { CommonService } from '../../../services/common/common.service';
import { Constants } from '../../../common/constants';
declare var moment: any;
declare var $: any;
@Component({
	selector: 'app-employee-meeting-report',
	templateUrl: './employee-meeting-report.component.html',
	styleUrls: ['./employee-meeting-report.component.css']
})
export class EmployeeMeetingReportComponent implements OnInit {
	isExport: boolean;
	isDemoTenant: boolean;
	prospectLabel: string;
	employeeLabel: string;
	employeeName: any;
	employee: any = [];
	meetingReportList: any = [];
	emp_id: any;
	report_type: any;
	errorMessage: string;
	disableButton: boolean;
	signin_btn_txt: string;
	start_date: string;
	end_date: string;
	errormsg: string;
	msg: boolean;
	prospect_type: boolean;
	attend_type: boolean;
	loading: boolean;
	dropdownSettings = {};
	selectionList = [];
	noDataMsg: string = 'Currently, there is no data available.';
	page: number = 2;
	pageNo: number = 1;
	perPage: number = 10;
	totalItem: number = 0;
	exportReport: any;
	reportingUserList: any = [];
	reportingSelectedItem: any = [];
	reportingSettings: any = {};
	locationList: any = [];
	locationSettings: any = {};
	locationSelectedItem: any = [];
	reportingTo: any = '';
	locationId: any = '';
	defaultSelectObject: any = { id: '', itemName: 'All' };
	filterLabel = Constants.filterLabel;

	constructor(private commonService: CommonService, public _assignService: AssignService, public _reportService: ReportService, private customErrorHandler: CustomErrorHandler) {
	}
	ngOnInit() {
		this.isExport = false;
		this.isDemoTenant = (localStorage.getItem('isDemoTenant') == 'true');
		this.employeeLabel = localStorage.getItem('employeeLabel');
		this.prospectLabel = localStorage.getItem('prospectLabel');
		this.msg = true;
		this.prospect_type = false;
		this.attend_type = false;
		this.emp_id = '';
		this.report_type = 2;
		this.dropdownSettings = {
			singleSelection: true,
			text: 'Select ' + this.employeeLabel,
			selectAllText: 'Select All',
			unSelectAllText: 'UnSelect All',
			enableSearchFilter: true,
			classes: 'myclass custom-class m_b_15 block_elem singleSelection'
		};
		var start = moment().subtract(6, 'days');
		var end = moment();
		var _this;
		_this = this;
		function cb(start, end) {
			$('#reportrange span').html(start.format('MMM D, YYYY') + ' - ' + end.format('MMM D, YYYY'));
			var f_start = start.format('YYYY-MM-DD');
			var f_end = end.format('YYYY-MM-DD');
			_this.start_date = f_start;
			_this.end_date = f_end;
			_this.getEmployeeMeetingDetails(1);
		}
		$('#reportrange').daterangepicker({
			startDate: start,
			endDate: end,
			ranges: {
				'Today': [moment(), moment(),],
				'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
				'Last 7 Days': [moment().subtract(6, 'days'), moment()],
				'Last 30 Days': [moment().subtract(29, 'days'), moment()],
				'This Month': [moment().startOf('month'), moment().endOf('month'), 'inclusive'],
				'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
			}
		}, cb);
		cb(start, end);
		this.getLocationList();
		this.getReportingUserList(this.locationId);
		this.employeeList();
		this.reportingSettings = {
			singleSelection: true,
			text: "Select Reporting Person",
			enableSearchFilter: true,
			classes: "singleSelection m_b_15 block_elem"
		};
		this.locationSettings = {
			singleSelection: true,
			text: "Select "+ Constants.locationLabel,
			enableSearchFilter: true,
			classes: "singleSelection m_b_15 block_elem"
		};
		this.getEmployeeMeetingDetails(1);
		console.log('getEmployeeMeetingDetails');
	}
	ngOnDestroy() {
		$('.fullHeightBlock').animate({ scrollTop: 0 }, 400);
	}
	employeeList() {
		let parameter = {
			location_id: this.locationId,
			reporting_to: this.reportingTo
		}
		this._assignService.employeeList(parameter).subscribe(
			res => {
				this.employee = res.data;
				// this.noDataMsg = res.message || 'Currently, there is no data available.';
			},
			error => {
				this.customErrorHandler.handleHttpError(error, 'employeeList');
			});
	}
	onOpen(event: any) {
		this.employee = this.employee.map((item) => {
			return {
				id: item.id,
				itemName: item.itemName
			};
		});
	}
	onItemSelect(item: any) {
		this.emp_id = item.id;
		this.getEmployeeMeetingDetails(1);
	}
	OnItemDeSelect(item: any) {
		this.emp_id = '';
		this.getEmployeeMeetingDetails(1);
	}
	onSelectAll(items: any) {
	}
	onDeSelectAll(items: any) {
	}
	getEmployeeMeetingDetails(currentPage) {
		// if (this.emp_id == '' || this.emp_id == undefined) {
		// 	this.errorMessage = this.employeeLabel + " should not be blank";
		// 	return false;
		// }
		// if (this.report_type == '' || this.report_type == undefined) {
		// 	this.errorMessage = "Report type Should not be blank";
		// 	return false;
		// }
		this.loading = true;
		this.disableButton = true;
		this.errorMessage = '';
		this.signin_btn_txt = "Creating";
		this.pageNo = currentPage;
		this.page = currentPage;
		this.noDataMsg = '';
		this.exportReport = '';
		this.loading = true;
		this._reportService.employeeReport(this.pageNo, this.perPage, this.emp_id, this.report_type, this.start_date, this.end_date, this.exportReport, this.locationId, this.reportingTo).subscribe(
			res => {
				this.loading = false;
				if (res.result == true) {
					this.totalItem = res.data.total;
					this.meetingReportList = res.data.data;
					this.noDataMsg = res.message;
					if (this.meetingReportList.length > 0) {
						this.isExport = true;
					}
					else {
						this.isExport = false;
					}
				} else {
					this.meetingReportList = [];
					this.noDataMsg = res.message;
					this.commonService.showErrorMessage(res);
				}
			},
			error => {
				this.loading = false;
				this.customErrorHandler.handleHttpError(error, 'employeeReport');
				this.disableButton = false;
				this.signin_btn_txt = "Submit";
			});
	}
	export() {
		var options = {
			fieldSeparator: ',',
			quoteStrings: '"',
			decimalseparator: '.',
			showLabels: true,
			showTitle: true,
			useBom: true,
			title: this.employeeLabel + ' ' + this.prospectLabel + ' report'
		};
		this.exportReport = 'yes';
		this._reportService.employeeReport(this.pageNo, this.perPage, this.emp_id, this.report_type, this.start_date, this.end_date, this.exportReport, this.locationId, this.reportingTo).subscribe(
			res => {
				new Angular2Csv(res.data, 'employee_prospect_report', options);
			},
			error => {
				this.customErrorHandler.handleHttpError(error, 'employeeReport');
				this.disableButton = false;
				this.signin_btn_txt = "Submit";
			});
	}
	getReportingUserList(locationId) {
		this.commonService.getReportingUserList(locationId).subscribe(res => {
			if (res.result == true) {
				this.reportingUserList = res.data;
				if (this.reportingUserList.length > 1) {
					this.reportingUserList.unshift(this.defaultSelectObject);
				}
			}
		}, err => {
			this.customErrorHandler.handleHttpError(err, 'getRoleList');
		});
	}
	getLocationList() {
		this.commonService.getLocationList().subscribe(res => {
			if (res.result == true) {
				this.locationList = res.data;
				if (this.locationList.length > 1) {
					this.locationList.unshift(this.defaultSelectObject);
				}
			}
		}, err => {
			this.customErrorHandler.handleHttpError(err, 'getRoleList');
		});
	}
	onUserItemSelect(item: any) {
		this.reportingTo = item.id;
		this.emp_id = '';
		this.selectionList = [];
		this.employee = [];
		this.getEmployeeMeetingDetails(1);
		this.employeeList();
	}
	onUserItemDeSelect(item: any) {
		this.reportingTo = '';
		this.emp_id = '';
		this.selectionList = [];
		this.employee = [];
		this.getEmployeeMeetingDetails(1);
		this.employeeList();
	}
	onLocationItemSelect(item: any) {
		this.locationId = item.id;
		this.reportingTo = '';
		this.reportingUserList = [];
		this.reportingSelectedItem = [];
		this.emp_id = '';
		this.selectionList = [];
		this.employee = [];
		// if (this.locationId != '') {
			this.getEmployeeMeetingDetails(1);
			this.getReportingUserList(this.locationId);
			this.employeeList();
		// }
	}
	onLocationItemDeSelect(item: any) {
		this.locationId = '';
		this.reportingTo = '';
		this.reportingUserList = [];
		this.reportingSelectedItem = [];
		this.emp_id = '';
		this.selectionList = [];
		this.employee = [];
		this.getEmployeeMeetingDetails(1);
		this.employeeList();
	}
}
