import { MeetingService } from './../../services/meeting/meeting.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AssignService } from '../../services/assign/assign.service';
import { NearbyService } from '../../services/nearby/nearby.service';
import { Config } from '../../config/config';
import { CustomErrorHandler } from '../../errorhandler/custom-errorhandler';
import { Constants } from './../../common/constants';
import { CommonService } from '../../services/common/common.service';
declare var google: any;
declare var OverlappingMarkerSpiderfier: any;
@Component({
  selector: 'nearby',
  templateUrl: 'nearby.component.html',
  styleUrls: ['./nearby.component.css']
})
export class NearbyComponent implements OnInit {
  imageBaseUrl: string = Config.AuthenticationURL;
  employeeLabel: string;
  prospectLabel: string;
  creates: any;
  create_area: boolean;
  map: any;
  modal: any;
  autocomplete: any;
  lat: any;
  lng: any;
  current_lng: any;
  current_lat: any;
  cityCircle: any;
  cityCircleClear: any = [];
  cityCircleCheck: any;
  area: any[];
  prospect: any[];
  radius: string;
  area_id: string;
  prospect_id: string;
  errorMessage: string;
  nearbyName: string;
  type: string;
  nearybyData: any = [];
  newMarkerArray: any = [];
  markers: any = [];
  nearbyNameFinal: string;
  area_idFinal: string;
  prospect_idFinal: string;
  areaName: string;
  prospectName: string;
  oms: any;
  infowindow: any;
  sendMsgModal: any;
  sendErrMsg: any;
  sendToAll: any;
  message: any;
  loading: any;
  isDemoTenant: boolean;
  constructor(
    private router: Router,
    public _assignService: AssignService,
    public _nearbyService: NearbyService,
    public _prospectService: MeetingService,
    private customErrorHandler: CustomErrorHandler,
    public commonService: CommonService
  ) { }
  ngOnInit() {
    this.isDemoTenant = (localStorage.getItem('isDemoTenant') == 'true');
    this.sendToAll = false;
    this.loading = false;
    this.sendMsgModal = document.getElementById('sendMsgModal');
    this.sendErrMsg = document.getElementById('sendErrMsg');
    this.employeeLabel = localStorage.getItem('employeeLabel');
    this.prospectLabel = localStorage.getItem('prospectLabel');
    this.message = document.getElementById("getMsg");
    this.radius = '';
    this.lat = '';
    this.lng = '';
    this.area_id = '';
    this.prospect_id = '';
    this.type = 'location';
    this.cityCircleCheck = 0;
    this.areaList();
    this.prospectList();
    if (navigator.geolocation) {
      // Call getCurrentPosition with success and failure callbacks
      navigator.geolocation.getCurrentPosition(this.success.bind(this), this.fail);
    }
    else {
      this.map = new google.maps.Map(document.getElementById('map'), {
        center: { lat: 22.7664707, lng: 75.8824308 },
        zoom: 8,
        styles: Constants.MapStyle
      });
    }
    this.auto('location');
  }
  success(position) {
    this.current_lng = position.coords.longitude;
    this.current_lat = position.coords.latitude;
    this.map = new google.maps.Map(document.getElementById('map'), {
      center: { lat: this.current_lat, lng: this.current_lng },
      zoom: 8,
      styles: Constants.MapStyle
    });
  }
  fail() {
    this.map = new google.maps.Map(document.getElementById('map'), {
      center: { lat: 22.7664707, lng: 75.8824308 },
      zoom: 8,
      styles: Constants.MapStyle
    });
  }
  auto(value) {
    if (value == 'location') {
      setTimeout(() => {
        this.autocomplete = new google.maps.places.Autocomplete(
                                  /** @type {HTMLInputElement} */(document.getElementById('autocomplete')),
          { types: ['geocode'] });
        google.maps.event.addListener(this.autocomplete, 'place_changed', function () {
          var place = this.getPlace();
          var lat1 = place.geometry.location.lat();
          var lng1 = place.geometry.location.lng();
          localStorage.setItem('near_by_lat', lat1);
          localStorage.setItem('near_by_lng', lng1);
        });
      }, 100);
    }
  }
  areaList() {
    this._assignService.areaList().subscribe(
      res => {
        this.area = res.data;
      },
      error => {
        this.customErrorHandler.handleHttpError(error, 'areaList');
      });
  }
  prospectList() {
    this._nearbyService.prospectList().subscribe(
      res => {
        if (res.result === true) {
          this.prospect = res.data;
        } else {
          this.commonService.showErrorMessage(res);
        }
      },
      error => {
        this.customErrorHandler.handleHttpError(error, 'prospectList');
      });
  }
  onSubmit() {
    if (this.type == 'location') {
      this.lat = localStorage.getItem('near_by_lat');
      this.lng = localStorage.getItem('near_by_lng');
      if (this.nearbyName == '' || this.nearbyName == undefined || this.lat == '' || this.lng == '' || this.lat == undefined || this.lng == undefined) {
        this.errorMessage = "Location Should not be blank";
        return false;
      }
    } else {
      this.lat = '';
      this.lng = '';
    }
    if (this.type == 'area') {
      this.area_idFinal = this.area_id;
      if (this.area_id == '' && this.lat == '') {
        this.errorMessage = "Area Should not be blank.";
        return false;
      }
    }
    else {
      this.area_idFinal = '';
    }
    if (this.type == 'prospect') {
      this.prospect_idFinal = this.prospect_id;
      if (this.prospect_id == '') {
        this.errorMessage = this.prospectLabel + ' ' + "Should not be blank.";
        return false;
      }
    }
    else {
      this.prospect_idFinal = '';
    }
    if (this.radius == '' || this.radius == undefined) {
      this.errorMessage = "Radius Should not be blank";
      return false;
    }
    if (parseInt(this.radius) == 0 || isNaN(parseInt(this.radius))) {
      this.errorMessage = "Only number greater than zero are allowed on radius field";
      return false;
    }
    this.errorMessage = '';
    this._nearbyService.createNearby(this.radius, this.lat, this.lng, this.area_idFinal, this.prospect_idFinal).subscribe(
      res => {
        this.lat = '';
        this.lng = '';
        this.area_idFinal = '';
        this.prospect_idFinal = '';
        if (res.result == true) {
          this.map = new google.maps.Map(document.getElementById('map'), {
            center: { lat: -34.397, lng: 150.644 },
            zoom: 14,
            styles: Constants.MapStyle
          });
          this.oms = new OverlappingMarkerSpiderfier(this.map, {
            markersWontMove: true,
            markersWontHide: true,
            basicFormatEvents: true,
            spiralFootSeparation: 40,
            spiralLengthStart: 15,
            spiralLengthFactor: 10,
            legWeight: 0,
            keepSpiderfied: true,
            nudgeStackedMarkers: false
          });
          this.infowindow = new google.maps.InfoWindow();
          this.nearybyData = res.data.data;
          var dd_radius1 = (1000 * Number(this.radius));
          var sunCircle = {
            strokeColor: "#57b3e1",
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: "#57b3e1",
            fillOpacity: 0.35,
            map: this.map,
            center: new google.maps.LatLng(Number(res.data.lat), Number(res.data.lng)),
            radius: dd_radius1 // in meters
          };
          this.cityCircle = new google.maps.Circle(sunCircle);
          this.cityCircleClear.push(sunCircle);
          this.cityCircleCheck = 1;
          var centermarker = new google.maps.Marker({
            position: new google.maps.LatLng(Number(res.data.lat), Number(res.data.lng))
          });
          this.map.setCenter(centermarker.getPosition());
          this.markers = this.newMarkerArray;
          this.clearMarkers(this.newMarkerArray);
          for (var i = 0; i < this.nearybyData.length; i++) {
            this.addMarker(this.nearybyData[i].first_name, this.nearybyData[i].lat, this.nearybyData[i].lng, this.nearybyData[i].profile_pic, this.nearybyData[i].last_update, this.nearybyData[i].id, this.nearybyData[i].status, this.nearybyData[i].contact_no);
          }
          // I create an OverlayView, and set it to add the "markerLayer" class to the markerLayer DIV
          var myoverlay = new google.maps.OverlayView();
          myoverlay.draw = function () {
            this.getPanes().markerLayer.id = 'markerLayer';
          };
          myoverlay.setMap(this.map);
        } else {
          this.errorMessage = res.message;
        }
      },
      error => {
        this.customErrorHandler.handleHttpError(error, 'createNearby');
      });
  }
  addMarker(name, lat, long, profile_pic, last_update, employeeId, status, contact) {
    if (profile_pic == "") {
      profile_pic = "assets/images/defualt-user-image-new.png";
    } else {
      profile_pic = this.imageBaseUrl + profile_pic
    }
    var iconSize = new google.maps.Size(50, 50);
    var icon = {
      url: profile_pic, // url
      scaledSize: iconSize, // scaled size
      origin: new google.maps.Point(0, 0), // origin
      anchor: new google.maps.Point(0, 0), // anchor
      size: iconSize
    };
    var latlngset = new google.maps.LatLng(lat, long);
    var marker = new google.maps.Marker({
      map: this.map,
      title: name,
      position: latlngset,
      icon: icon
    });
    this.newMarkerArray.push(marker);
    if (contact == 'undefined') {
      contact = '';
    }
    var content = "<div class='clearBlock'><div style='width:50px;float:left' class='info_tooltip'><img class='img_width'  src=" + profile_pic + "></div>";
    content += "<div style='float:left'>";
    content += "<b style='font-size: 16px'>" + name + "</b>";
    content += "</div></div><hr style='width: 98%;border: 1px solid #ddd;'>";
    content += "<div>";
    content += "<div style='width: 50%;float:left;font-size:14px;'><span>Status</span><br><p style='font-weight: bold;'>" + status + "</p></div>";
    content += "<div style='width: 50%;float:left;font-size:14px;'>";
    if (contact) {
      content += "<span>Contact No.</span><br><p style='font-weight: bold;'>" + contact + "</p>";
    }
    contact += "</div>";
    content += "</div>";
    content += "<br><br><div style='width: 100%;text-align:center;margin-top:20px;'><a class='btn btn-default' id='playbackTracking'>Playback</a>&nbsp&nbsp";
    content += "<a class='btn btn-default' ";
    if (this.isDemoTenant != true) {
      content += " id='assignMeeting'";
    }
    if (this.isDemoTenant == true) {
      content += " disabled";
    }
    content += "> Assign Meeting</a><br><br>";
    content += "<a class='btn btn-default' ";
    if (this.isDemoTenant != true) {
      content += " id='openMsgModal'";
    }
    if (this.isDemoTenant == true) {
      content += " disabled";
    }
    content += ">Send Message</a></div>";

    var infowindow = this.infowindow;
    var router = this.router;
    var onSendMsg = this._nearbyService;
    var commonService = this.commonService;
    google.maps.event.addListener(marker, 'click', (function (marker, content) {
      return function () {
        infowindow.close();
        infowindow.setContent(content);
        infowindow.open(this.map, marker);
        document.getElementById("playbackTracking").addEventListener("click", function () {
          router.navigate(["detailmap/" + employeeId]);
        });
        document.getElementById("assignMeeting").addEventListener("click", function () {
          localStorage.setItem('assigneeId', employeeId);
          router.navigate(["meeting/meeting-list"]);
        });
        var sendMsgModal = document.getElementById('sendMsgModal');
        var sendErrMsg = document.getElementById('sendErrMsg');
        var sendMessage = document.getElementById('sendMessage');
        sendErrMsg.style.display = "none";
        document.getElementById("openMsgModal").addEventListener("click", function () {
          sendErrMsg.style.display = "none";
          sendMsgModal.style.display = "block";
          sendMessage.style.display = "block";
        });
        document.getElementById("closeMsgModal").addEventListener("click", function () {
          sendMsgModal.style.display = "none";
          sendErrMsg.style.display = "none";
          sendMessage.style.display = "none";
        });
        document.getElementById("sendMessage").addEventListener("click", function () {
          var message: any = document.getElementById("getMsg");
          if (message.value == null || message.value == "") {
            sendErrMsg.style.display = "block";
          } else {
            sendErrMsg.style.display = "none";
            var data = {
              company_id: localStorage.getItem('company_id'),
              employee_id: employeeId,
              message: message.value
            }
            onSendMsg.sendMessage(data).subscribe(
              res => {
                if (res.result == true) {
                  commonService.showSuccessMessage(res.message);
                  message.value == '';
                  sendMsgModal.style.display = "none";
                  sendMessage.style.display = "none";
                } else {
                  commonService.showErrorMessage(res);
                }
              });
          }
        });
      };
    })(marker, content));
    this.oms.addMarker(marker);
  }
  clearMarkers(data) {
    for (var i = 0; i < data.length; i++) {
      this.markers[i].setMap(null);
    }
  }
  onAreaSelect() {
    let tem = this.area.find(s => s.itemName == this.areaName);
    this.area_id = tem.id;
  }
  onProspectSelect() {
    let tem = this.prospect.find(s => s.name == this.prospectName);
    this.prospect_id = tem.id;
  }
  openMessageModal() {
    this.sendToAll = true;
    console.log(this.sendMsgModal);
    this.sendMsgModal.style.display = "block";
    this.sendErrMsg.style.display = "none";
    this.message.value = '';
  }
  closeMessageModal() {
    this.sendToAll = false;
    this.sendMsgModal.style.display = "none";
    this.sendErrMsg.style.display = "block";
    this.message.value = '';
  }
  sendMessagesToAll() {
    this.loading = true;
    if (this.nearybyData.length > 0) {
      const setEmployeeId = this.nearybyData.map(function (elem) { return elem.id; }).join(",");
      if (this.message.value == null || this.message.value == "") {
        this.sendErrMsg.style.display = "block";
        this.loading = false;
        return false
      } else {
        this.sendErrMsg.style.display = "none";
        var data = {
          company_id: localStorage.getItem('company_id'),
          employee_id: setEmployeeId,
          message: this.message.value
        }
        this._nearbyService.sendMessage(data).subscribe(
          res => {
            if (res.result == true) {
              this.commonService.showSuccessMessage(res.message);
              this.message.value == '';
              this.sendMsgModal.style.display = "none";
              this.loading = false;
              this.sendToAll = false;
            } else {
              this.commonService.showErrorMessage(res);
              this.loading = false;
              this.sendToAll = false;
            }
          });
      }
    }
  }
}
