import { Injectable } from '@angular/core';
import { HttpHelper } from '../../helpers/http.helper';
import { Config } from '../../config/config';
const company_id = localStorage.getItem('company_id');
const user_id = localStorage.getItem('user_id');
@Injectable()
export class AssignService {
  constructor(private _httpHelper: HttpHelper) {
  }
  assignAreaList() {
    try {
      var request = {
        "company_id": company_id
      };
      return this._httpHelper.post(Config.AuthenticationBaseURL + 'createAssignArea_webList', request)
    }
    catch (e) {
    }
  }
  assigntemplateList(req) {
    try {
      return this._httpHelper.get(Config.AuthenticationBaseURL1 + 'get-assign-template-list', req);
    }
    catch (e) {
    }
  }
  areaList() {
    try {
      var request = {
        "company_id": company_id
      };
      return this._httpHelper.post(Config.AuthenticationBaseURL1 + 'area-list-only', request)
    }
    catch (e) {
    }
  }
  templateList(locationId = null) {
    try {
      var request = {
        "company_id": company_id,
        "location_id" : locationId
      };
      return this._httpHelper.post(Config.AuthenticationBaseURL1 + 'template-list-only', request)
    }
    catch (e) {
    }
  }
  areaAssignDelete(area_id: string) {
    try {
      var request = {
        "area_id": area_id,
        "company_id": company_id
      };
      return this._httpHelper.post(Config.AuthenticationBaseURL + 'areaAssignDelete', request)
    }
    catch (e) {
    }
  }
  templateAssignDelete(temp_id: string, parent_id) {
    try {
      var request = {
        "temp_id": temp_id,
        "company_id": company_id,
        'parent_id': parent_id
      };
      return this._httpHelper.post(Config.AuthenticationBaseURL + 'templateAssignDelete', request)
    }
    catch (e) {
    }
  }
  employeeList(request) {
    try {
      return this._httpHelper.get(Config.AuthenticationBaseURL1 + 'teamListMember', request)
    }
    catch (e) {
    }
  }
  teamList(locationId = null) {
    try {
        var request = {
            "company_id": company_id,
            "location_id" : locationId
        };
        return this._httpHelper.post(Config.AuthenticationBaseURL1 + 'teamListonly', request)
    }
    catch (e) {
    }
}
  editAreaSelectData(area_id: string) {
    try {
      var request = {
        "area_id": area_id,
        "company_id": company_id
      };
      return this._httpHelper.post(Config.AuthenticationBaseURL + 'selectAssignAreaById', request)
    }
    catch (e) {
    }
  }
  editTemplateSelectData(temp_id: string, parentid) {
    try {
      var request = {
        "temp_id": temp_id,
        "company_id": company_id,
        'parent_id': parentid
      };
      return this._httpHelper.post(Config.AuthenticationBaseURL + 'selectAssignTemplateById', request)
    }
    catch (e) {
    }
  }
  createAssignArea(area_id: string, emp_id: string) {
    try {
      var request = {
        "area_id": area_id,
        "emp_id": emp_id,
        "company_id": company_id,
        "created_by": user_id
      };
      return this._httpHelper.post(Config.AuthenticationBaseURL + 'createAssignArea_web', request)
    }
    catch (e) {
    }
  }
  editAssignArea(area_id: string, emp_id: string, oldarea_id: string) {
    try {
      var request = {
        "area_id": area_id,
        "oldarea_id": oldarea_id,
        "emp_id": emp_id,
        "company_id": company_id,
        "created_by": user_id
      };
      return this._httpHelper.post(Config.AuthenticationBaseURL + 'editAssignArea_web', request)
    }
    catch (e) {
    }
  }
  editAssignTemplate(temp_id: string, emp_id: string, oldtemp_id: string, location, team_id, parentId) {
    try {
      var request = {
        "temp_id": temp_id,
        "oldtemp_id": oldtemp_id,
        "emp_id": emp_id,
        "company_id": company_id,
        "created_by": user_id,
        location_id: location,
        team_id: team_id,
        parent_id: parentId
      };
      return this._httpHelper.post(Config.AuthenticationBaseURL + 'editAssignTemplate_web', request)
    }
    catch (e) {
    }
  }
  createAssignTemplate(area_id: string, emp_id: string, location, team_id) {
    try {
      var request = {
        "template_id": area_id,
        "emp_id": emp_id,
        "company_id": company_id,
        "created_by": user_id,
        location_id: location,
        team_id: team_id
      };
      return this._httpHelper.post(Config.AuthenticationBaseURL + 'createAssignTemplate_web', request)
    }
    catch (e) {
    }
  }
}
