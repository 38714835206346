import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { TreeNode } from 'primeng/api';
import { HierarchyService } from '../../../services/hierarchy/hierarchy.service';
import { CustomErrorHandler } from './../../../errorhandler/custom-errorhandler';
import { CommonService } from '../../../services/common/common.service';
import { Config } from './../../../config/config';
declare var $: any;
@Component({
  selector: 'app-organizational-chart',
  templateUrl: './organizational-chart.component.html',
  styleUrls: ['./organizational-chart.component.css'],
  host: {
    'class': 'fullHeightBlock'
  },
  encapsulation: ViewEncapsulation.None
})
export class OrganizationalChartComponent implements OnInit {
  loading: any;
  chartData: TreeNode[];
  selectedNode: TreeNode;
  imageBaseUrl = Config.AuthenticationURL;
  constructor(
    public hierarchyService: HierarchyService,
    public commonService: CommonService,
    public customErrorHandler: CustomErrorHandler,
  ) { }
  ngOnInit() {
    if ($(window).width() > 760) {
      $('.fullHeightBlock').scrollbar();
    }
    this.loading = false;
    this.getOrganizationalData();
  }
  getOrganizationalData() {
    this.loading = true;
    this.hierarchyService.getOrganizationalHierarchy().subscribe(res => {
      if (res.result == true) {
        let arrData: any = [];
        res.data.expanded = true;
        console.log(res.data);
        res.data.children.forEach(element => {
          element.expanded = true;
          if (element.children.length > 0) {
            element.children.forEach(ele => {
              ele.expanded = true;
            });
          }
        });
        arrData.push(res.data);
        this.chartData = arrData;
        setTimeout(() => {
          this.loading = false;
        }, 2500);
      }
    }, err => {
      this.loading = false;
      this.customErrorHandler.handleHttpError(err, 'getOrganizationalData');
    });
  }
}
