import { Injectable } from '@angular/core';
import { HttpHelper } from '../../helpers/http.helper';
import { Config } from '../../config/config';
@Injectable()
export class PollSurveyService {
    constructor(
        private _httpHelper: HttpHelper
    ) { }
    createPoll(request) {
        try {
            return this._httpHelper.post(Config.AuthenticationBaseURL1 + 'create-poll', request)
        }
        catch (e) {
        }
    }
    getPollList(queryStrings) {
        try {
            return this._httpHelper.get(Config.AuthenticationBaseURL1 + 'get-polls?page=' + queryStrings.pageNo + '&per_page=' + queryStrings.perPage + '&search=' + queryStrings.search + '&export=' + queryStrings.export);
        }
        catch (e) {
        }
    }
    getSinglePoll(id) {
        try {
            return this._httpHelper.get(Config.AuthenticationBaseURL1 + 'get-single-poll?poll_id=' + id);
        }
        catch (e) {
        }
    }
    updatePoll(request) {
        try {
            return this._httpHelper.post(Config.AuthenticationBaseURL1 + 'update-poll', request)
        }
        catch (e) {
        }
    }
    deletePoll(request) {
        try {
            return this._httpHelper.delete(Config.AuthenticationBaseURL1 + 'delete-poll', request);
        }
        catch (e) {
        }
    }
    assignPoll(request) {
        try {
            return this._httpHelper.post(Config.AuthenticationBaseURL1 + 'assign-poll', request)
        }
        catch (e) {
        }
    }
    updateAssignPoll(request) {
        try {
            return this._httpHelper.post(Config.AuthenticationBaseURL1 + 'update-assign-poll', request)
        }
        catch (e) {
        }
    }
    assignPollList(queryStrings) {
        try {
            return this._httpHelper.get(Config.AuthenticationBaseURL1 + 'get-assign-poll-details', queryStrings);
        }
        catch (e) {
        }
    }
    getSingleAssignPoll(id) {
        try {
            return this._httpHelper.get(Config.AuthenticationBaseURL1 + 'get-assign-single-poll?assign_poll_id=' + id);
        }
        catch (e) {
        }
    }
    deleteAssignPoll(request) {
        try {
            return this._httpHelper.delete(Config.AuthenticationBaseURL1 + 'delete-assign-poll', request);
        }
        catch (e) {
        }
    }
    getDropdownPollList() {
        try {
            return this._httpHelper.get(Config.AuthenticationBaseURL1 + 'get-poll-list');
        }
        catch (e) {
        }
    }
    createSurvey(request) {
        try {
            return this._httpHelper.post(Config.AuthenticationBaseURL1 + 'create-survey', request)
        }
        catch (e) {
        }
    }
    getSurveyList(queryStrings) {
        try {
            return this._httpHelper.get(Config.AuthenticationBaseURL1 + 'get-survey?page=' + queryStrings.pageNo + '&per_page=' + queryStrings.perPage + '&search=' + queryStrings.search + '&export=' + queryStrings.export);
        }
        catch (e) {
        }
    }
    getSingleSurvey(id) {
        try {
            return this._httpHelper.get(Config.AuthenticationBaseURL1 + 'get-survey-by-id?survey_id=' + id);
        }
        catch (e) {
        }
    }
    updateSurvey(request) {
        try {
            return this._httpHelper.post(Config.AuthenticationBaseURL1 + 'update-survey', request)
        }
        catch (e) {
        }
    }
    deleteSurvey(request) {
        try {
            return this._httpHelper.delete(Config.AuthenticationBaseURL1 + 'delete-survey', request);
        }
        catch (e) {
        }
    }
    getDropdownSurveyList() {
        try {
            return this._httpHelper.get(Config.AuthenticationBaseURL1 + 'get-survey-list')
        }
        catch (e) {
        }
    }
    assignSurvey(request) {
        try {
            return this._httpHelper.post(Config.AuthenticationBaseURL1 + 'assign-survey', request)
        }
        catch (e) {
        }
    }
    deleteAssignSurvey(request) {
        try {
            return this._httpHelper.delete(Config.AuthenticationBaseURL1 + 'delete-assign-survey', request);
        }
        catch (e) {
        }
    }
    assignSurveyList(queryStrings) {
        try {
            return this._httpHelper.get(Config.AuthenticationBaseURL1 + 'get-assign-survey', queryStrings)
        }
        catch (e) {
        }
    }
    updateAssignSurvey(request) {
        try {
            return this._httpHelper.post(Config.AuthenticationBaseURL1 + 'edit-assign-survey', request)
        }
        catch (e) {
        }
    }
    getSingleAssignSurvey(id) {
        try {
            return this._httpHelper.get(Config.AuthenticationBaseURL1 + 'get-assign-survey-by-id?assign_survey_id=' + id);
        }
        catch (e) {
        }
    }
    getPollResults(id) {
        try {
            return this._httpHelper.get(Config.AuthenticationBaseURL1 + 'get-poll-result?poll_id=' + id);
        }
        catch (e) {
        }
    }
    getSurveyResults(id) {
        try {
            return this._httpHelper.get(Config.AuthenticationBaseURL1 + 'get-survey-result?survey_id=' + id);
        }
        catch (e) {
        }
    }
}
