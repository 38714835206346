import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/Subject';
@Injectable()
export class HomeSharedService {
  private home = new Subject<string>();
  private homefilter = new Subject<string>();
  private homestartDate = new Subject<string>();
  private homeendDate = new Subject<string>();
  private homeDate = new Subject<any>();
  private actionFilters = new Subject<any>();
  private action = new Subject<string>();
  private filter = new Subject<string>();
  private startDate = new Subject<string>();
  private endDate = new Subject<string>();
  home$ = this.home.asObservable();
  publishData(data: string) {
    this.home.next(data);
  }
  homefilter$ = this.homefilter.asObservable();
  publishHomefilter(data: string) {
    this.homefilter.next(data);
  }
  homestartDate$ = this.homestartDate.asObservable();
  publishHomestartDate(data: string) {
    this.homestartDate.next(data);
  }
  homeendDate$ = this.homeendDate.asObservable();
  publishHomeendDate(data: string) {
    this.homeendDate.next(data);
  }
  homeDate$ = this.homeDate.asObservable();
  publishHomeDate(start_date: string, end_date: string, filter: string, search: string, reportingTo, locationId) {
    let data: any = { 'start_date': start_date, 'end_date': end_date, 'filter': filter, 'search': search, 'reporting_to': reportingTo, 'location_id': locationId }
    this.homeDate.next(data);
  }
  actionFilters$ = this.actionFilters.asObservable();
  publishActionFilters(start_date: string, end_date: string, filter: string, search: string) {
    let data: any = { 'start_date': start_date, 'end_date': end_date, 'filter': filter, 'search': search }
    this.actionFilters.next(data);
  }
  action$ = this.action.asObservable();
  publishActionData(data: string) {
    this.action.next(data);
  }
  filter$ = this.filter.asObservable();
  publishActionfilter(data: string) {
    this.filter.next(data);
  }
  startDate$ = this.startDate.asObservable();
  publishActionstartDate(data: string) {
    this.startDate.next(data);
  }
  endDate$ = this.endDate.asObservable();
  publishActionendDate(data: string) {
    this.endDate.next(data);
  }
}
