import { Config } from './../../config/config';
import { HttpHelper } from './../../helpers/http.helper';
import { Injectable } from '@angular/core';
const company_id = localStorage.getItem('company_id');
@Injectable()
export class FormsService {
  constructor(private _httpHelper: HttpHelper) {
  }
  getFormsList(prospectId) {
    console.log(prospectId);
    try {
      var request = {
        "prospect_id": prospectId || '',
        "company_id": company_id
      };
      return this._httpHelper.post(Config.AuthenticationBaseURL1 + 'get-form-data-prospect', request)
    }
    catch (e) {
    }
  }
  prospectCompletedList() {
    try {
      var request = {
        "company_id": company_id
      };
      return this._httpHelper.post(Config.AuthenticationBaseURL + 'prospectcompletedroplist', request)
    }
    catch (e) {
    }
  }
}