import { MeetingService } from './../../../services/meeting/meeting.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Config } from '../../../config/config';
import { CustomErrorHandler } from '../../../errorhandler/custom-errorhandler';
import { VoterService } from './../../../services/voter/voter.service';
import { CommonService } from '../../../services/common/common.service';
import { Constants } from '../../../common/constants';
declare var moment: any;

@Component({
  selector: 'app-evm-assignment',
  templateUrl: './evm-assignment.component.html',
  styleUrls: ['./evm-assignment.component.css']
})
export class EvmAssignmentComponent implements OnInit {

  	evmAssignmentDetails: any = {};
	editAssignId: any;
	fileToUpload: any;
	documentPageLabel: any;
	stateList: any = [];
	stateSettings: any = {};
	stateSelectedItems: any = [];
	constituencyList: any = [];
	constituencySettings: any = {};
	constituencySelectedItems: any = [];
	boothList: any = [];
	boothSettings: any = {};
	boothSelectedItems: any = [];
	employeeList: any = [];
	employeeSettings: any = {};
	employeeSelectedItems: any = [];
	employeeLabel: any;
	teamSettings: any = {};
	teamSelectedItems: any = [];
	isState: any = false;
	isConstituency: any = false;
	isBoothNumber: any = false;
	employeeTeamErrorMsg: boolean = true;
	minDate: Date = new Date();
	locationList: any = [];
	locationSelectedItem: any = [];
	locationSettings: any = {};
	locationId;
	locationLabel: any = Constants.locationLabel;
	teamList: any = [];
	errorMessage: any = '';
	constructor(private router: Router, private _prospectService: MeetingService, public _commonService: CommonService, private customErrorHandler: CustomErrorHandler, public voterService: VoterService) {
	}
	ngOnInit() {
		this.employeeLabel = localStorage.getItem('employeeLabel');
		this.editAssignId = '';
		this.editAssignId = localStorage.getItem('editEvmAssignmentId');		
		this.getStateList();
		// this.getEmployeeList(this.locationId);
		// if (this.editAssignId) {
		// 	console.log(this.editAssignId);
		// 	this.getSingleAssignDetails(this.editAssignId);
		// } else {
		// 	this.getEmployeeList(this.locationId);
		// }
		this.stateSettings = {
			singleSelection: true,
			text: "Select State",
			selectAllText: 'Select All',
			unSelectAllText: 'UnSelect All',
			enableSearchFilter: true,
			classes: "singleSelection"
		};
		this.constituencySettings = {
			singleSelection: true,
			text: "Select Constituency",
			selectAllText: 'Select All',
			unSelectAllText: 'UnSelect All',
			enableSearchFilter: true,
			classes: "singleSelection"
		}
		this.boothSettings = {
			singleSelection: true,
			text: "Select Booth Number",
			selectAllText: 'Select All',
			unSelectAllText: 'UnSelect All',
			enableSearchFilter: true,
			classes: "singleSelection"
		}
		this.teamSettings = {
			singleSelection: false,
			text: "Select Team",
			selectAllText: 'Select All',
			unSelectAllText: 'UnSelect All',
			enableSearchFilter: true
		}
		this.employeeSettings = {
			singleSelection: false,
			text: "Select " + this.employeeLabel,
			selectAllText: 'Select All',
			unSelectAllText: 'UnSelect All',
			enableSearchFilter: true
		}
		this.documentPageLabel = 'Create';
		this.locationSettings = {
			singleSelection: true,
			text: "Select "+ Constants.locationLabel,
			enableSearchFilter: true,
			classes: "singleSelection"
		};
		this.getLocationList();
		
	}
	evmAssignment() {
		this.errorMessage = '';
		this.isState = false;
		this.isConstituency = false;
		this.isBoothNumber = false;
		this.employeeTeamErrorMsg = false;
		this.evmAssignmentDetails.assign_by = localStorage.getItem('user_id');
		if ((this.evmAssignmentDetails.state == undefined || this.evmAssignmentDetails.state == '') &&
			(this.evmAssignmentDetails.constituency == undefined || this.evmAssignmentDetails.constituency == '') && (this.evmAssignmentDetails.booth_name == undefined || this.evmAssignmentDetails.booth_name == '')
		) {
			this.isState = true;
			this.isConstituency = true;
			this.isBoothNumber = true;
			return false;
		}
		if (this.evmAssignmentDetails.state == undefined || this.evmAssignmentDetails.state == '') {
			this.isState = true;
			return false;
		}
		if (this.evmAssignmentDetails.constituency == undefined || this.evmAssignmentDetails.constituency == '') {
			this.isConstituency = true;
			return false;
		}
		if (this.evmAssignmentDetails.booth_name == undefined || this.evmAssignmentDetails.booth_name == '') {
			this.isBoothNumber = true;
			return false;
		}
		if (this.employeeSelectedItems.length == 0 && this.teamSelectedItems.length == 0) {
			this.errorMessage = "Team or " + this.employeeLabel + " Should not be blank";
			this.employeeTeamErrorMsg = true;
			return false;
		}
		this.evmAssignmentDetails.date = moment(this.evmAssignmentDetails.date).format('YYYY-MM-DD');
		this.evmAssignmentDetails.location_id = this.locationSelectedItem.map(function (elem) { return elem.id; }).join(" ");
		this.evmAssignmentDetails.team_id = this.teamSelectedItems.map(function (elem) { return elem.id; }).join(",");
		if (this.editAssignId) {
			this.evmAssignmentDetails.doc_id = this.editAssignId;
			this.voterService.updateEvmAssignment(this.evmAssignmentDetails).subscribe(
				res => {
					if (res.result == true) {
						this.router.navigate(["voter/evm-assignment-list"]);
						this._commonService.showSuccessMessage(res.message);
					} else {
						this._commonService.showErrorMessage(res);
					}
				},
				error => {
					this.customErrorHandler.handleHttpError(error, 'createEvent');
				});
		} else {
			this.voterService.assignmentEvm(this.evmAssignmentDetails).subscribe(
				res => {
					if (res.result == true) {
						this.router.navigate(["voter/evm-assignment-list"]);
						this._commonService.showSuccessMessage(res.message);
					} else {
						this._commonService.showErrorMessage(res);
					}
				},
				error => {
					this.customErrorHandler.handleHttpError(error, 'createEvent');
				});
		}
	}
	getSingleAssignDetails(assignId) {
		let parameter = {
			id: assignId
		}
		this.voterService.getSingleEvmAssignment(parameter).subscribe(
			res => {
				if (res.result == true) {
					this.evmAssignmentDetails = res.data;
					this.evmAssignmentDetails.date = new Date(this.evmAssignmentDetails.date);
					this.evmAssignmentDetails.employee = res.data.employee_id;
					let stateTemp = this.stateList.find(s => s.id == this.evmAssignmentDetails.state);
					this.stateSelectedItems = [];
					if (typeof stateTemp != 'undefined') {
						this.getConstituencyList(stateTemp.itemName);
						this.stateSelectedItems.push(stateTemp);
					}

					this.getEmployeeList(this.evmAssignmentDetails.location_id);
					this.getTeamList(this.evmAssignmentDetails.location_id)
					let lactionTemp = this.locationList.find(s => s.id == this.evmAssignmentDetails.location_id);
					if (typeof lactionTemp != 'undefined') {
						this.locationSelectedItem = [];
						this.locationSelectedItem.push(lactionTemp);
					}
					
					
					
				} else {
					this._commonService.showCustomError(res.message);
				}
			},
			error => {
				this.customErrorHandler.handleHttpError(error, 'employeeReport');
			});
	}
	getStateList() {
		this.voterService.getStateList().subscribe(
			res => {
				if (res.result == true) {
					this.stateList = res.data;
					if (this.editAssignId) {
						this.documentPageLabel = 'Edit';
						this.getSingleAssignDetails(this.editAssignId);
					}
				} else {
					this._commonService.showCustomError(res.message);
				}
			},
			error => {
				this.customErrorHandler.handleHttpError(error, 'getStateList');
			});
	}

	getLocationList() {
		this._commonService.getLocationList().subscribe(res => {
			if (res.result == true) {
			this.locationList = res.data;
			}
		}, error => {
			this.customErrorHandler.handleHttpError(error, 'getRoleList');
		});
	}
	onLocationItemSelect(item: any) {
		this.locationId = item.id;
		this.getEmployeeList(this.locationId);
		this.getTeamList(this.locationId);
	}
	onLocationItemDeSelect(item: any) {
		this.locationId = '';
	}

	getEmployeeList(locationId) {
		this._prospectService.employeeLocationList(locationId).subscribe(
			res => {
				if(res.result == true){
					this.employeeList = res.data;
					let employeeId = this.evmAssignmentDetails.employee_id;
					if (this.editAssignId && employeeId != undefined && this.employeeList.length > 0) {
						this.employeeSelectedItems = [];
						employeeId = employeeId.toString();
						let employeeInfo = employeeId.split(',');
						for (let i = 0; i < employeeInfo.length; i++) {
							let empTemp = this.employeeList.find(s => s.id == employeeInfo[i]);
							if (typeof empTemp != 'undefined') {
								this.employeeSelectedItems.push(empTemp);
							}
						}
					}
				}
			},
			error => {
				this.customErrorHandler.handleHttpError(error, 'employeeList');
			});
	}
	getTeamList(locationId) {
		this._prospectService.teamList(locationId).subscribe(
			res => {
				this.teamList = res.data;
				let teamId = this.evmAssignmentDetails.team_id;
				if (this.editAssignId && teamId != undefined && this.teamList.length > 0) {
					this.teamSelectedItems = [];
					teamId = teamId.toString();
					let teamInfo = teamId.split(',');
					for (let i = 0; i < teamInfo.length; i++) {
						let teamTemp = this.teamList.find(s => s.id == teamInfo[i]);
						if (typeof teamTemp != 'undefined') {
							this.teamSelectedItems.push(teamTemp);
						}
					}
				}
			},
			error => {
				this.customErrorHandler.handleHttpError(error, 'teamList');
			});
	}
	getConstituencyList(state) {
		this.voterService.getConstituencyList(state).subscribe(
			res => {
				if (res.result == true) {
					this.constituencyList = res.data;
					if (this.editAssignId && this.evmAssignmentDetails.constituency != undefined) {
						let constituencyTemp = this.constituencyList.find(s => s.id == this.evmAssignmentDetails.constituency);
						if (typeof constituencyTemp != 'undefined') {
							this.constituencySelectedItems.push(constituencyTemp);
						}
						this.evmAssignmentDetails.constituency = constituencyTemp.id;
						this.getBoothList(constituencyTemp.id);
					}
				} else {
					this._commonService.showCustomError(res.message);
				}
			},
			error => {
				this.customErrorHandler.handleHttpError(error, 'getConstituencyList');
			});
	}
	getBoothList(constituency) {
		this.voterService.getBoothList(constituency).subscribe(
			res => {
				if (res.result == true) {
					this.boothList = res.data;
					if (this.editAssignId && this.evmAssignmentDetails.booth_id != undefined) {
						let boothTemp = this.boothList.find(s => s.id == this.evmAssignmentDetails.booth_id);
						if (typeof boothTemp != 'undefined') {
							this.boothSelectedItems.push(boothTemp);
						}
					}
				} else {
					this._commonService.showCustomError(res.message);
				}
			},
			error => {
				this.customErrorHandler.handleHttpError(error, 'getBoothList');
			});
	}
	selectStateDetails() {
		this.evmAssignmentDetails.state = this.stateSelectedItems[0].id;
		this.evmAssignmentDetails.constituency = '';
		this.constituencySelectedItems = [];
		this.boothList = [];
		this.evmAssignmentDetails.booth_name = '';
		this.evmAssignmentDetails.booth_id = '';
		this.boothSelectedItems = [];
		this.getConstituencyList(this.stateSelectedItems[0].itemName);
		this.isState = false;
	}
	deselectStateDetails() {
		this.evmAssignmentDetails.state = '';
		this.constituencyList = [];
		this.evmAssignmentDetails.constituency = '';
		this.constituencySelectedItems = [];
		this.boothList = [];
		this.evmAssignmentDetails.booth_name = '';
		this.evmAssignmentDetails.booth_id = '';
		this.boothSelectedItems = [];
	}
	selectConstituencyDetails() {
		this.evmAssignmentDetails.constituency = this.constituencySelectedItems[0].id;
		this.evmAssignmentDetails.booth_name = '';
		this.evmAssignmentDetails.booth_id = '';
		this.boothSelectedItems = [];
		this.getBoothList(this.constituencySelectedItems[0].id);
		this.isConstituency = false;
	}
	deselectConstituencyDetails() {
		this.evmAssignmentDetails.constituency = '';
		this.constituencySelectedItems = [];
		this.boothList = [];
		this.evmAssignmentDetails.booth_name = '';
		this.evmAssignmentDetails.booth_id = '';
		this.boothSelectedItems = [];
	}
	selectBoothDetails() {
		this.evmAssignmentDetails.booth_name = this.boothSelectedItems[0].itemName;
		this.evmAssignmentDetails.booth_id = this.boothSelectedItems[0].id;
		this.isBoothNumber = false;
	}
	deselectBoothDetails() {
		this.evmAssignmentDetails.constituency = '';
		this.constituencySelectedItems = [];
	}
	selectEmployeeDetails() {
		this.evmAssignmentDetails.employee = '';
		for (let i = 0; i < this.employeeSelectedItems.length; i++) {
			if (i == 0) {
				this.evmAssignmentDetails.employee = this.employeeSelectedItems[i].id;
			} else {
				this.evmAssignmentDetails.employee += ',' + this.employeeSelectedItems[i].id;
			}
		}
		if (this.employeeSelectedItems.length == 0 && this.teamSelectedItems.length == 0) {
			this.errorMessage = "Team or " + this.employeeLabel + " Should not be blank";

			this.employeeTeamErrorMsg = true;
		} else {
			this.employeeTeamErrorMsg = false;
		}
	}
	deselectEmployeeDetails(item: any) {
		this.evmAssignmentDetails.employee = '';
		for (let i = 0; i < this.employeeSelectedItems.length; i++) {
			if (i == 0) {
				this.evmAssignmentDetails.employee = this.employeeSelectedItems[i].id;
			} else {
				this.evmAssignmentDetails.employee += ',' + this.employeeSelectedItems[i].id;
			}
		}
		if (this.employeeSelectedItems.length == 0 && this.teamSelectedItems.length == 0) {
			this.employeeTeamErrorMsg = true;
		} else {
			this.employeeTeamErrorMsg = false;
		}
	}
	
	ngOnDestroy() {
		localStorage.removeItem('editEvmAssignmentId');
	}
	
	onOpen(event: any) {
		this.teamList = this.teamList.map((item) => {
			return {
			id: item.id,
			itemName: item.itemName
			}
		});
	}

}
