import { MeetingService } from './../../../services/meeting/meeting.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CustomErrorHandler } from '../../../errorhandler/custom-errorhandler';
import { Constants } from './../../../common/constants';
import { CommonService } from '../../../services/common/common.service';
import swal from 'sweetalert2';
declare var google: any;
@Component({
  selector: 'create-map-meeting',
  templateUrl: 'create-map-meeting.component.html',
  styleUrls: ['./create-map-meeting.component.css']
})
export class CreateMapMeetingComponent implements OnInit {
  prospectLabel: string;
  creates: any;
  create_area: boolean;
  map: any;
  lat: string;
  lng: string;
  name: string;
  area: string;
  loginFormEmail: string;
  status: string;
  errorMessage: string;
  disableButton: boolean;
  signin_btn_txt: string;
  loginDiv: boolean;
  tenantList: any[];
  accessToken: string;
  image_url: string;
  formatted_address: string;
  current_lng: any;
  current_lat: any;
  title: any;
  titleErrorMessage: any;
  constructor(private router: Router, private commonService: CommonService, public _prospectService: MeetingService, private customErrorHandler: CustomErrorHandler) {
    this.create_area = false;
  }
  Opencreate_area() {
    this.create_area = true;
    setTimeout(() => {
      this.loadAutoComple1();
    }, 100);
  }
  loadAutoComple() {
    var map1 = this.map;
    var card = document.getElementById('pac-card');
    var input = document.getElementById('pac-input');
    map1.controls[google.maps.ControlPosition.TOP_RIGHT].push(card);
    var autocomplete = new google.maps.places.Autocomplete(input);
    // bounds option in the request.
    autocomplete.bindTo('bounds', map1);
    var infowindow = new google.maps.InfoWindow();
    var infowindowContent = document.getElementById('infowindow-content');
    infowindow.setContent(infowindowContent);
    var marker = new google.maps.Marker({
      map: map1,
      anchorPoint: new google.maps.Point(0, -29)
    });
    var _this = this;
    autocomplete.addListener('place_changed', function () {
      infowindow.close();
      marker.setVisible(false);
      var place = autocomplete.getPlace();
      if (!place.geometry) {
        // User entered the name of a Place that was not suggested and
        // pressed the Enter key, or the Place Details request failed.
        this.commonService.showCustomError("No details available for input: '" + place.name + "'");
        return;
      }
      // If the place has a geometry, then present it on a map.
      if (place.geometry.viewport) {
        map1.fitBounds(place.geometry.viewport);
      } else {
        map1.setCenter(place.geometry.location);
        map1.setZoom(17);  // Why 17? Because it looks good.
      }
      marker.setPosition(place.geometry.location);
      marker.setVisible(true);
      _this.lat = place.geometry.location.lat();
      _this.lng = place.geometry.location.lng();
      _this.name = place.name;
      _this.formatted_address = place.formatted_address;
      if (place.address_components.length > 1) {
        _this.area = place.address_components[1].long_name;
      } else {
        _this.area = place.address_components[0].long_name;
      }
      var address = '';
      if (place.address_components) {
        address = [
          (place.address_components[0] && place.address_components[0].short_name || ''),
          (place.address_components[1] && place.address_components[1].short_name || ''),
          (place.address_components[2] && place.address_components[2].short_name || '')
        ].join(' ');
      }
      infowindowContent.children['place-icon'].src = place.icon;
      infowindowContent.children['place-name'].textContent = place.name;
      infowindowContent.children['place-address'].textContent = address;
      infowindow.open(map1, marker);
    });
  }
  loadAutoComple1() {
    var map1 = this.map;
    var card = document.getElementById('pac-card');
    var input = document.getElementById('pac-input1');
    map1.controls[google.maps.ControlPosition.TOP_RIGHT].push(card);
    var autocomplete = new google.maps.places.Autocomplete(input);
    // Bind the map's bounds (viewport) property to the autocomplete object,
    // so that the autocomplete requests use the current map bounds for the
    // bounds option in the request.
    autocomplete.bindTo('bounds', map1);
    var infowindow = new google.maps.InfoWindow();
    var infowindowContent = document.getElementById('infowindow-content');
    infowindow.setContent(infowindowContent);
    var marker = new google.maps.Marker({
      map: map1,
      anchorPoint: new google.maps.Point(0, -29)
    });
    autocomplete.addListener('place_changed', function () {
      infowindow.close();
      marker.setVisible(false);
      var place = autocomplete.getPlace();
      if (!place.geometry) {
        // User entered the name of a Place that was not suggested and
        // pressed the Enter key, or the Place Details request failed.
        this.commonService.showCustomError("No details available for input: '" + place.name + "'");
        return;
      }
      // If the place has a geometry, then present it on a map.
      if (place.geometry.viewport) {
        map1.fitBounds(place.geometry.viewport);
      } else {
        map1.setCenter(place.geometry.location);
        map1.setZoom(17);  // Why 17? Because it looks good.
      }
      marker.setPosition(place.geometry.location);
      marker.setVisible(true);
      var address = '';
      if (place.address_components) {
        address = [
          (place.address_components[0] && place.address_components[0].short_name || ''),
          (place.address_components[1] && place.address_components[1].short_name || ''),
          (place.address_components[2] && place.address_components[2].short_name || '')
        ].join(' ');
      }
      infowindowContent.children['place-icon'].src = place.icon;
      infowindowContent.children['place-name'].textContent = place.name;
      infowindowContent.children['place-address'].textContent = address;
      infowindow.open(map1, marker);
    });
  }
  Cancelcreate_area() {
    this.create_area = false;
    setTimeout(() => {
      this.loadAutoComple();
    }, 100);
  }
  ngOnInit() {
    this.title = '';
    this.prospectLabel = localStorage.getItem('prospectLabel');
    if (navigator.geolocation) {
      // Call getCurrentPosition with success and failure callbacks
      navigator.geolocation.getCurrentPosition(this.success.bind(this), this.fail.bind(this));
    }
    else {
      this.map = new google.maps.Map(document.getElementById('map'), {
        center: { lat: 22.7664707, lng: 75.8824308 },
        zoom: 8,
        styles: Constants.MapStyle
      });

      setTimeout(() => {
        this.loadAutoComple();
      }, 100);
    }
  }
  success(position) {
    this.current_lng = position.coords.longitude;
    this.current_lat = position.coords.latitude;
    this.map = new google.maps.Map(document.getElementById('map'), {
      center: { lat: this.current_lat, lng: this.current_lng },
      zoom: 8,
      styles: Constants.MapStyle
    });
    setTimeout(() => {
      this.loadAutoComple();
    }, 100);
  }
  fail(position) {
    this.map = new google.maps.Map(document.getElementById('map'), {
      center: { lat: 22.7664707, lng: 75.8824308 },
      zoom: 8,
      styles: Constants.MapStyle
    });
    setTimeout(() => {
      this.loadAutoComple();
    }, 100);
  }
  gotoDetail() {
    this.router.navigate(["detailmap"]);
  }
  onSubmit() {
    let is_same = 0;
    this.titleErrorMessage = '';
    this.errorMessage = '';
    if (this.name == '' || this.name == undefined || this.title == '') {
      if (this.title == '') {
        this.titleErrorMessage = "Title is required";
      }
      if (this.name == '' || this.name == undefined) {
        this.errorMessage = "location Should not be blank";
      }
      return false;
    }
    this.disableButton = true;
    this.errorMessage = '';
    this.signin_btn_txt = "Creating";
    this._prospectService.createProspect(this.name, this.lat, this.lng, this.area, this.title, is_same).subscribe(
      res => {
        if (res.result == true) {
          localStorage.setItem('prospect_id', res.prospect_id);
          localStorage.setItem('prospect_name', res.prospect_name);
          localStorage.setItem('prospect_area_name', this.area);
          localStorage.setItem('prospect_title', res.title);
          this.router.navigate(["meeting/meeting-list/create-meeting"]);
        } else {
          this.disableButton = false;
          is_same = res.data.is_same;
          console.log(is_same);
          swal({
            text: res.message,
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: 'Yes',
          }).then((result) => {
            if (typeof result.value != 'undefined') {
              this._prospectService.createProspect(this.name, this.lat, this.lng, this.area, this.title, is_same).subscribe(
                res => {
                  if (res.result == true) {
                    localStorage.setItem('prospect_id', res.prospect_id);
                    localStorage.setItem('prospect_name', res.prospect_name);
                    localStorage.setItem('prospect_area_name', this.area);
                    localStorage.setItem('prospect_title', res.title);
                    this.router.navigate(["meeting/meeting-list/create-meeting"]);
                  } else {
                    this.commonService.showErrorMessage(res);
                  }
                },
                error => {
                  this.customErrorHandler.handleHttpError(error, 'createProspect');
                });
            }
          })
          this.signin_btn_txt = "Create";
        }
      },
      error => {
        this.customErrorHandler.handleHttpError(error, 'createProspect');
        this.disableButton = false;
        this.signin_btn_txt = "Create";
      });
  }
  ngOnDestroy() {
    let routeName = localStorage.getItem('mapProspectRouteName');
    let routeStart = localStorage.getItem('mapProspectRouteStartDate');
    let routeEnd = localStorage.getItem('mapProspectRouteEndDate');
    let routeEmpId = localStorage.getItem('mapProspectRouteEmpId');
    let routeLocationId = localStorage.getItem('mapProspectRouteLocationId');
    if (routeStart && routeEnd && routeEmpId && routeLocationId) {
      localStorage.setItem('createProspectRouteName', routeName);
      localStorage.setItem('createProspectRouteStartDate', routeStart);
      localStorage.setItem('createProspectRouteEndDate', routeEnd);
      localStorage.setItem('createProspectRouteEmpId', routeEmpId);
      localStorage.setItem('createProspectRouteLocationId', routeLocationId);
    } else {
      if (routeEmpId && routeLocationId) {
        localStorage.setItem('createProspectRouteEmpId', routeEmpId);
        localStorage.setItem('createProspectRouteLocationId', routeLocationId);
      }
    }
    let updateRouteId = localStorage.getItem('mapProspectRouteUpdateId');
    if (updateRouteId) {
      localStorage.setItem('createProspectRouteUpdateId', updateRouteId);
    }
    localStorage.removeItem('mapProspectRouteUpdateId');
    localStorage.removeItem('mapProspectRouteName');
    localStorage.removeItem('mapProspectRouteStartDate');
    localStorage.removeItem('mapProspectRouteStartDate');
    localStorage.removeItem('mapProspectRouteEmpId');
    localStorage.removeItem('mapProspectRouteLocationId');
  }
}
