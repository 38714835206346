import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from '../../../services/common/common.service';
import { Angular2Csv } from 'angular2-csv/Angular2-csv';
import { CustomErrorHandler } from '../../../errorhandler/custom-errorhandler';
import { PollSurveyService } from '../../../services/polls-surveys/poll-survey';
import swal from 'sweetalert2';
import { Constants } from '../../../common/constants';
declare var $: any;
@Component({
  selector: 'app-survey-list',
  templateUrl: './survey-list.component.html',
  styleUrls: ['./survey-list.component.css']
})
export class SurveyListComponent implements OnInit {
  errormsg: string;
  surveyList: any = [];
  loading: boolean;
  perPage: number = 10;
  pageNo: number = 1;
  page: number = 2;
  tempSearch: any;
  searchTextValue: any = '';
  isDemoTenant: boolean;
  totalItem: number = 0;
  exportReport: any;
  serverUrl: any;
  maxPage: number = 10;
  startDate: any;
  endDate: any;
  constructor(
    private router: Router,
    public commonService: CommonService,
    private customErrorHandler: CustomErrorHandler,
    private pollSsurveyService: PollSurveyService
  ) { }
  ngOnInit() {
    this.isDemoTenant = (localStorage.getItem('isDemoTenant') == 'true');
    this.loading = true;
    this.tempSearch = '';
    this.getSurveList(1);
    if ($(window).width() < 768) {
      this.maxPage = 6;
    }
  }
  getSurveList(currentPage) {
    this.page = currentPage;
    let queryStrings: any = {
      pageNo: currentPage,
      perPage: this.perPage,
      search: this.searchTextValue,
      export: ''
    }
    this.pollSsurveyService.getSurveyList(queryStrings).subscribe(res => {
      this.errormsg = res.message;
      if (res.result == true) {
        this.totalItem = res.data.total;
        this.surveyList = res.data.data;
      } else { }
      this.loading = false;
    }, err => {
      this.customErrorHandler.handleHttpError(err, 'getPoll');
      this.loading = false;
    });
  }
  onSearch(event) {
    this.searchTextValue = event.target.value;
    if (this.tempSearch != this.searchTextValue) {
      this.getSurveList(1);
    }
    this.tempSearch = this.searchTextValue;
  }
  editSurvey(data) {
    if (data.is_edit == 1) {
      this.router.navigate(['survey/create-survey'], { queryParams: { id: data.id } });
    }
    
  }
  navigateToResult(data) {
    this.router.navigate(['survey/survey-result'], { queryParams: { id: data.id } })
  }

  openModel(data) {
    console.log(data);
  }
  exportSurveyList() {
    var options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: true,
      useBom: true,
      title: "survey-List"
    };
    let qeuryStrings: any = {
      pageNo: this.pageNo,
      perPage: this.perPage,
      search: this.searchTextValue,
      export: 'yes',
    }
    this.pollSsurveyService.getSurveyList(qeuryStrings).subscribe(
      res => {
        new Angular2Csv(res.data, 'surveyList', options);
      },
      error => {
        this.customErrorHandler.handleHttpError(error, 'surveyList');
      });
  }
  deleteSurvey(value) {
    if (value.is_edit == 1) {
      swal({
        text: Constants.DeleteMsg,
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        cancelButtonColor: '#3085d6',
        confirmButtonText: 'Delete',
      }).then((result) => {
        if (typeof result.value != 'undefined') {
          let data = { survey_id: value.id };
          this.pollSsurveyService.deleteSurvey(data).subscribe(
            res => {
              if (res.result == true) {
                this.commonService.showSuccessMessage(res.message);
                this.getSurveList(this.pageNo);
              } else {
                this.commonService.showErrorMessage(res);
              }
            },
            error => {
              this.customErrorHandler.handleHttpError(error, 'employeeReport');
            });
        }
      });
    }    
  }
}
