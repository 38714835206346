import { EmployeeLeaderBoardComponent } from './employee-leader-board.component';
import { AuthGuard } from './../../services/_guards/auth.guard';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { InfiniteScrollModule } from 'angular2-infinite-scroll';
import { ChartsModule } from 'ng2-charts';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown/angular2-multiselect-dropdown';
import { Ng2PaginationModule } from 'ng2-pagination';
import { TreeTableModule } from 'primeng/treetable';

export const routes: Routes = [
  {
    path: 'employee-leader-board',
    component: EmployeeLeaderBoardComponent, canActivate: [AuthGuard]
  }
];
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule.forChild(routes),
    InfiniteScrollModule,
    ChartsModule,
    Ng2PaginationModule,
    AngularMultiSelectModule,
    TreeTableModule
  ],
  declarations: [
    EmployeeLeaderBoardComponent
  ]
})
export class EmployeeLeaderBoardModule { }
