import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from '../../../services/common/common.service';
import { CustomErrorHandler } from '../../../errorhandler/custom-errorhandler';
import { PollSurveyService } from '../../../services/polls-surveys/poll-survey';
import swal from 'sweetalert2';
import { Constants } from '../../../common/constants';
import { MeetingService } from '../../../services/meeting/meeting.service';
declare var moment: any;
declare var $: any;
@Component({
  selector: 'app-survey-assigned-list',
  templateUrl: './survey-assigned-list.component.html',
  styleUrls: ['./survey-assigned-list.component.css']
})
export class SurveyAssignedListComponent implements OnInit {
  errormsg: string;
  assignedSurveyList: any = [];
  loading: boolean;
  perPage: number = 10;
  pageNo: number = 1;
  searchTextValue: any = '';
  isDemoTenant: boolean;
  totalItem: number = 0;
  page: number = 2;
  exportReport: any;
  serverUrl: any;
  maxPage: number = 10;
  startDate: any;
  endDate: any;
  tempSearch: any;
  employeeLabel = localStorage.getItem('employeeLabel');
  filterLabel = Constants.filterLabel;
  locationList: any = [];
  locationSettings: any = {};
  locationSelectedItem: any = [];
  locationId = '';
  teamList = [];
  teamSettings = {};
  teamSelectedItems: any = [];
  teamId = '';
  employeeMemberList: any = [];
  employeeMember: any = [];
  employeeMemberSettings = {};
  employeeId = '';
  defaultSelectObject: any = { id: '', itemName: 'All' }
  constructor(
    private router: Router,
    public commonService: CommonService,
    private customErrorHandler: CustomErrorHandler,
    private pollSurveyService: PollSurveyService,
    private _prospectService: MeetingService
  ) {
  }
  ngOnInit() {
    this.isDemoTenant = (localStorage.getItem('isDemoTenant') == 'true');
    this.loading = true;
    this.tempSearch = '';
    this.getAssignedSurveyList(1);
    if ($(window).width() < 768) {
      this.maxPage = 6;
    }
    this.getLocationList();
    this.getEmployeeList(this.locationId);
    this.teamListFunction(this.locationId);
    this.locationSettings = {
      singleSelection: true,
      text: "Select "+ Constants.locationLabel,
      enableSearchFilter: true,
      classes: "singleSelection block_elem m_b_15"
    };

    this.teamSettings = {
			singleSelection: true,
      text: "Select Team",
      enableSearchFilter: true,
      classes: "singleSelection block_elem m_b_15"
    }
    this.employeeMemberSettings = {
      singleSelection: true,
      text: "Select " + this.employeeLabel,
      enableSearchFilter: true,
      classes: "myclass custom-class singleSelection block_elem m_b_15"
    }; 
  }
  getAssignedSurveyList(currentPage) {
    this.page = currentPage;
    let queryStrings: any = {
      page: currentPage,
      perPage: this.perPage,
      search: this.searchTextValue,
      export: '',
      employee_id: this.employeeId, 
      location_id: this.locationId,  
      team_id: this.teamId
    }
    this.pollSurveyService.assignSurveyList(queryStrings).subscribe(res => {
      this.errormsg = res.message;
      if (res.result == true) {
        this.totalItem = res.data.total;
        this.assignedSurveyList = res.data.data;
        this.assignedSurveyList.map(obj => {
          obj.start_date = moment(obj.start_date).format("YYYY-DD-MM");
          obj.end_date = moment(obj.end_date).format("YYYY-DD-MM");
        });
      }
      this.loading = false;
    }, err => {
      this.customErrorHandler.handleHttpError(err, 'getPoll');
      this.loading = false;
    });
  }
  editAssignSurvey(value) {
    if (value.is_edit) {
      this.router.navigate(['survey/assigned-survey'], { queryParams: { id: value.id } })
      this.getAssignedSurveyList(1);
    }
    
  }
  onSearch(event) {
    this.searchTextValue = event.target.value;
    if (this.tempSearch != this.searchTextValue) {
      this.getAssignedSurveyList(1);
    }
    this.tempSearch = this.searchTextValue;
  }
  deleteAssignSurvey(value) {
    if (value.is_edit) {
      swal({
        text: Constants.DeleteMsg,
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        cancelButtonColor: '#3085d6',
        confirmButtonText: 'Delete',
      }).then((result) => {
        if (typeof result.value != 'undefined') {
          let data = { assign_survey_id: value.id };
          this.pollSurveyService.deleteAssignSurvey(data).subscribe(
            res => {
              if (res.result == true) {
                this.commonService.showSuccessMessage(res.message);
                this.getAssignedSurveyList(this.pageNo);
              } else {
                this.commonService.showErrorMessage(res);
              }
            },
            error => {
              this.customErrorHandler.handleHttpError(error, 'employeeReport');
            });
        }
      });
    }
    
  }

  getLocationList() {
    this.commonService.getLocationList().subscribe(res => {
      if (res.result == true) {
        this.locationList = res.data;
        if (this.locationList.length > 1) {
          this.locationList.unshift(this.defaultSelectObject);
        }
      }
    }, err => {
      this.customErrorHandler.handleHttpError(err, 'getRoleList');
    });
  }

  getEmployeeList(location) {
    this._prospectService.employeeLocationList(location).subscribe(
      res => {
        this.employeeMemberList = res.data;
        if (this.employeeMemberList.length > 1) {
          this.employeeMemberList.unshift(this.defaultSelectObject);
        }
      },
      error => {
        this.customErrorHandler.handleHttpError(error, 'employeeList');
      });
  }
  
  teamListFunction(locationId) {
    this._prospectService.teamList(locationId).subscribe(
      res => {
        this.teamList = res.data;
        if (this.teamList.length > 1) {
          this.teamList.unshift(this.defaultSelectObject);
        }
      },
      error => {
        this.customErrorHandler.handleHttpError(error, 'teamList');
      });
  }

  locationItemSelect(item: any) {
    this.locationId = item.id;
    this.teamId = '';
    this.employeeId = '';
    this.teamList = [];
    this.teamSelectedItems = [];
    this.employeeMemberList = [];
    this.employeeMember = [];    
    this.getAssignedSurveyList(1)
    this.getEmployeeList(this.locationId);
    this.teamListFunction(this.locationId);
  }
  locationItemDeSelect(item: any) {
    this.locationId = '';
    this.teamId = '';
    this.employeeId = '';
    this.teamList = [];
    this.teamSelectedItems = [];
    this.employeeMemberList = [];
    this.employeeMember = [];    
    this.getAssignedSurveyList(1)
    this.getEmployeeList(this.locationId);
    this.teamListFunction(this.locationId);
  }

  onUserItemSelect(item: any) {
    this.teamId = '';
    this.employeeId = item.id;
    this.getAssignedSurveyList(1)
  }
  onUserItemDeSelect(item: any) {
    this.teamId = '';
    this.employeeId = '';
    this.getAssignedSurveyList(1)
  }

  onTeamSelect(item: any) {
    this.employeeId = '';
    this.teamId = item.id;
    this.getAssignedSurveyList(1)
  }
  onTeamDeSelect(item: any) {
    this.employeeId = '';
    this.teamId = '';
    this.getAssignedSurveyList(1)
  }
}
