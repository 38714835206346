import { Injectable } from '@angular/core';
import { HttpHelper } from '../../helpers/http.helper';
import { Config } from '../../config/config';
const company_id = localStorage.getItem('company_id');
const user_id = localStorage.getItem('user_id');
@Injectable()
export class DigitalFormService {
  constructor(private _httpHelper: HttpHelper) {
  }
  createformCategory(name: string, data: string) {
    try {
      var request = {
        "name": name,
        "data": data,
        "company_id": company_id,
        "created_by": user_id
      };
      return this._httpHelper.post(Config.AuthenticationBaseURL + 'createformCategory', request)
    }
    catch (e) {
    }
  }
  editformCategory(formTemplateCategoryId: string, name: string, data: string) {
    try {
      var request = {
        "name": name,
        "data": data,
        "company_id": company_id,
        "formTemplateCategoryId": formTemplateCategoryId,
      };
      return this._httpHelper.post(Config.AuthenticationBaseURL + 'editformCategory', request)
    }
    catch (e) {
    }
  }
  createformTemplate(name: string, data: string) {
    try {
      var request = {
        "name": name,
        "data": data,
        "company_id": company_id,
        "employee_id": user_id
      };
      return this._httpHelper.post(Config.AuthenticationBaseURL + 'createformTemplate', request)
    }
    catch (e) {
    }
  }
  editFormTemplate(formTemplateId: string, name: string, data: string) {
    try {
      var request = {
        "name": name,
        "data": data,
        "formTemplateId": formTemplateId,
        "company_id": company_id,
        "employee_id": user_id
      };
      return this._httpHelper.post(Config.AuthenticationBaseURL + 'editFormTemplate', request)
    }
    catch (e) {
    }
  }
  createNotification(sms, mail, push) {
    try {
      var request = {
        "sms": sms,
        "mail": mail,
        "push": push,
        "company_id": company_id,
        "employee_id": user_id
      };
      return this._httpHelper.post(Config.AuthenticationBaseURL + 'createNotification', request)
    }
    catch (e) {
    }
  }
  createNotificationList() {
    try {
      var request = {
        "company_id": company_id
      };
      return this._httpHelper.post(Config.AuthenticationBaseURL + 'createNotificationList', request)
    }
    catch (e) {
    }
  }
  categoryList(pageNo, rowPerPage) {
    try {
      return this._httpHelper.get(Config.AuthenticationBaseURL1 + 'get-form-category-list?page=' + pageNo + '&per_page=' + rowPerPage);
    }
    catch (e) {
    }
  }
  form_templateList(pageNo, rowPerPage, search) {
    try {
      return this._httpHelper.get(Config.AuthenticationBaseURL1 + 'get-form-template-list?page=' + pageNo + '&per_page=' + rowPerPage+'&search='+ search);
    }
    catch (e) {
    }
  }
  formTemplateCategoryById(id: string) {
    try {
      var request = {
        "company_id": company_id,
        "id": id
      };
      return this._httpHelper.post(Config.AuthenticationBaseURL + 'form_categoryListById', request)
    }
    catch (e) {
    }
  }
  formTemplateById(id: string) {
    try {
      var request = {
        "company_id": company_id,
        "id": id
      };
      return this._httpHelper.post(Config.AuthenticationBaseURL + 'formTemplateById', request)
    }
    catch (e) {
    }
  }
  categoryDelete(id: string) {
    try {
      var request = {
        "id": id
      };
      return this._httpHelper.post(Config.AuthenticationBaseURL + 'deleteForm_category', request)
    }
    catch (e) {
    }
  }
  formTemplateCategoryDelete(id: string) {
    try {
      var request = {
        "id": id
      };
      return this._httpHelper.post(Config.AuthenticationBaseURL + 'formTemplateCategoryDelete', request)
    }
    catch (e) {
    }
  }
  templatedataList() {
    try {
      var request = {
        "company_id": company_id
      };
      return this._httpHelper.post(Config.AuthenticationBaseURL + 'templatedataList', request)
    }
    catch (e) {
    }
  }
}
