import { CreateMapMeetingComponent } from './create-map-meeting/create-map-meeting.component';
import { CreateMeetingComponent } from './create-meeting/create-meeting.component';
import { MeetingListComponent } from './meeting-list/meeting-list.component';
import { MeetingComponent } from './meeting.component';
import { AuthGuard } from './../../services/_guards/auth.guard';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown/angular2-multiselect-dropdown';
import { InfiniteScrollModule } from 'angular2-infinite-scroll';
import { Ng2PaginationModule } from 'ng2-pagination';
import { PipeModule } from '../../pipe/pipe.module';
import { CalendarModule } from 'primeng/calendar';
import { FormsComponent } from './forms/forms.component';
import { FollowUpsComponent } from './follow-ups/follow-ups.component';
import { NotesComponent } from './notes/notes.component';
import { MeetingEditComponent } from './meeting-edit/meeting-edit.component';
import { FileUploadModule } from 'ng2-file-upload';
export const routes: Routes = [
  {
    path: 'meeting',
    component: MeetingComponent, canActivate: [AuthGuard],
    children: [
      { path: '', redirectTo: 'meeting-list', pathMatch: 'full' },
      { path: 'meeting-list', component: MeetingListComponent },
      { path: 'meeting-list/meeting-edit', component: MeetingEditComponent },
      { path: 'meeting-list/create-meeting', component: CreateMeetingComponent },
      { path: 'meeting-list/create-map-meeting', component: CreateMapMeetingComponent },
      { path: 'forms', component: FormsComponent },
      { path: 'followups', component: FollowUpsComponent },
      { path: 'notes', component: NotesComponent }
    ]
  }
];
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule.forChild(routes),
    AngularMultiSelectModule,
    InfiniteScrollModule,
    PipeModule.forRoot(),
    CalendarModule,
    Ng2PaginationModule,
    FileUploadModule
  ],
  declarations: [
    MeetingComponent,
    MeetingListComponent,
    CreateMeetingComponent,
    CreateMapMeetingComponent,
    MeetingEditComponent,
    NotesComponent,
    FollowUpsComponent,
    FormsComponent
  ]
})
export class MeetingModule { }
