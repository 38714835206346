import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Angular2Csv } from 'angular2-csv/Angular2-csv';
import { VoterService } from '../../../services/voter/voter.service';
import { Constants } from '../../../common/constants';
import { CustomErrorHandler } from '../../../errorhandler/custom-errorhandler';
import swal from 'sweetalert2';
import { CommonService } from '../../../services/common/common.service';
import { Config } from '../../../config/config';
import { MeetingService } from '../../../services/meeting/meeting.service';
@Component({
	selector: 'app-election-day-list',
	templateUrl: './election-day-list.component.html',
	styleUrls: ['./election-day-list.component.css']
})
export class ElectionDayListComponent implements OnInit {
	isExport: boolean;
	isDemoTenant: boolean;
	errormsg: string;
	msg: boolean;
	actionLable: string;
	employeeLabel: string;
	electionDayList: any = [];
	loading: boolean;
	perPage: number = 10;
	tempSearch: any;
	pageNo: number = 1;
	searchTextValue: any = '';
	totalItem: number = 0;
	page: number = 2;
	exportReport: any;
	serverUrl: any;
	deleteRequest: any = {}
	searchTempVal: any;
	searchDetails: any = {};
	filterLabel = Constants.filterLabel;
	locationList: any = [];
	locationSettings: any = {};
	locationSelectedItem: any = [];
	locationId = '';
	employeeMemberList: any = [];
	employeeMember: any = [];
	employeeMemberSettings = {};
	employeeId = '';
	defaultSelectObject: any = { id: '', itemName: 'All' }

	constructor(
		private router: Router,
		public commonService: CommonService,
		public voterService: VoterService,
		private customErrorHandler: CustomErrorHandler,
		private _prospectService: MeetingService
	) { }
	ngOnInit() {
		this.searchTempVal = '';
		this.searchDetails.search_by = '';
		this.tempSearch = '';
		this.serverUrl = Config.imageBaseUrlCI;
		this.isExport = false;
		this.isDemoTenant = (localStorage.getItem('isDemoTenant') == 'true');
		this.errormsg = '';
		this.msg = false;
		this.actionLable = localStorage.getItem('actionLabel');
		this.employeeLabel = localStorage.getItem('employeeLabel');
		this.loading = true;
		this.getElectionDayList(1);
		this.getLocationList();
    	this.getEmployeeList(this.locationId);
		this.locationSettings = {
			singleSelection: true,
			text: "Select "+ Constants.locationLabel,
			enableSearchFilter: true,
			classes: "singleSelection block_elem m_b_15"
		};
		this.employeeMemberSettings = {
			singleSelection: true,
			text: "Select " + this.employeeLabel,
			enableSearchFilter: true,
			classes: "myclass custom-class singleSelection block_elem m_b_15"
		}; 
	}
	getElectionDayList(currentPage) {
		this.pageNo = currentPage;
		this.page = currentPage;
		this.errormsg = '';
		this.electionDayList = [];
		this.loading = true;
		this.exportReport = '';
		let queryData = {
			page: this.pageNo,
			per_page: this.perPage,
			search: this.searchTextValue,
			export: this.exportReport,
			employee_label: this.employeeLabel,
			location_id: this.locationId,
			employee: this.employeeId
		}
		this.voterService.getElectionDayList(queryData).subscribe(
			response => {
				this.loading = false;
				let res: any = response;
				if (res.result == true) {
					this.totalItem = res.data.total;
					this.electionDayList = res.data.data;
					this.errormsg = res.message;
				} else {
					this.electionDayList = [];
					this.msg = true;
					this.errormsg = res.message;
					this.commonService.showErrorMessage(res);
				}
			},
			error => {
				this.loading = false;
				this.customErrorHandler.handleHttpError(error, 'getCheckList');
			});
	}
	onkeyup_value(event) {
		this.searchTextValue = event.target.value;
		if (this.tempSearch != this.searchTextValue) {
			this.getElectionDayList(1);
		}
		this.tempSearch = this.searchTextValue;
	}
	export() {
		var options = {
			fieldSeparator: ',',
			quoteStrings: '"',
			decimalseparator: '.',
			showLabels: true,
			showTitle: true,
			useBom: true,
			title: "Election Day List"
		};
		this.exportReport = 'yes';
		let queryData = {
			page: this.pageNo,
			per_page: this.perPage,
			search: this.searchTextValue,
			export: this.exportReport,
			employee_label: this.employeeLabel,
			location_id: this.locationId,
			employee: this.employeeId
		}
		this.voterService.getElectionDayList(queryData).subscribe(
			response => {
				let res: any = response;
				if (res.result == true) {
					new Angular2Csv(res.data, 'Election Day List', options);
				}
			},
			error => {
				this.loading = false;
				this.customErrorHandler.handleHttpError(error, 'getCheckList');
			});
	}
	editElectionDayDetails(electionDayId) {
		localStorage.setItem('editElectionDayId', electionDayId);
		this.router.navigate(['./voter/election-day']);
	}
	deleteElectionDayDetails(electionDayId) {
		swal({
			text: Constants.DeleteMsg,
			type: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#d33',
			cancelButtonColor: '#3085d6',
			confirmButtonText: 'Delete',
		}).then((result) => {
			if (typeof result.value != 'undefined') {
				this.deleteRequest.voting_day_id = electionDayId;
				this.voterService.deleteElectionDayDetail(this.deleteRequest).subscribe(
					res => {
						if (res.result == true) {
							this.commonService.showSuccessMessage(res.message);
							this.getElectionDayList(1);
						} else {
							this.commonService.showCustomError(res.message);
						}
					},
					error => {
						this.customErrorHandler.handleHttpError(error, 'employeeReport');
					});
			}
		});
	};
	getLocationList() {
		this.commonService.getLocationList().subscribe(res => {
			if (res.result == true) {
				this.locationList = res.data;
				if (this.locationList.length > 1) {
					this.locationList.unshift(this.defaultSelectObject);
				}
			}
		}, err => {
			this.customErrorHandler.handleHttpError(err, 'getRoleList');
		});
	}

	getEmployeeList(location) {
		this._prospectService.employeeLocationList(location).subscribe(res => {
			this.employeeMemberList = res.data;
			if (this.employeeMemberList.length > 1) {
				this.employeeMemberList.unshift(this.defaultSelectObject);
			}
		}, error => {
			this.customErrorHandler.handleHttpError(error, 'employeeList');
		});
	}
	locationItemSelect(item: any) {
		this.locationId = item.id;
		this.employeeId = '';
		this.employeeMemberList = [];
		this.employeeMember = [];    
		this.getElectionDayList(1);
		this.getEmployeeList(this.locationId);
	}
	locationItemDeSelect(item: any) {
		this.locationId = '';
		this.employeeMemberList = [];
		this.employeeMember = [];    
		this.getElectionDayList(1);
		this.getEmployeeList(this.locationId);
	}

	onUserItemSelect(item: any) {
		this.employeeId = item.id;
		this.getElectionDayList(1);
	}
	onUserItemDeSelect(item: any) {
		this.employeeId = '';
		this.getElectionDayList(1);
	}
}
