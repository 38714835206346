import { MeetingService } from './../../../services/meeting/meeting.service';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { Angular2Csv } from 'angular2-csv/Angular2-csv';
import { Subject } from 'rxjs/Subject';
import "rxjs/add/operator/debounceTime";
import "rxjs/add/operator/distinctUntilChanged";
import { Constants } from '../../../common/constants';
import { CustomErrorHandler } from '../../../errorhandler/custom-errorhandler';
import { FileUploader } from 'ng2-file-upload';
import { Config } from '../../../config/config';
import { CommonService } from '../../../services/common/common.service';
import swal from 'sweetalert2';
declare var moment: any;
declare var $: any;
@Component({
  selector: 'meeting-list',
  templateUrl: './meeting-list.component.html',
  styleUrls: ['./meeting-list.component.css']
})
export class MeetingListComponent implements OnInit {
  imageBaseUrl: string = Config.AuthenticationURL;
  isDemoTenant: boolean;
  isExport: boolean;
  errormsg: string;
  msg: boolean;
  prospectLabel: string;
  actionLable: string;
  employeeLabel: string;
  company_id: string;
  prospectList: any[];
  loading: boolean;
  start: number;
  pagechk: boolean;
  creates: any;
  createprospect: any;
  searchModalChanged: Subject<string> = new Subject<string>();
  searchTextValue: any = '';
  meetingModal: any;
  meetingDetails: any = {};
  digitalFormLength: any;
  employeeMemberLength: any;
  teamLength: any;
  startDate: any;
  endDate: any;
  tempSearch: any;
  meetingTypeList: any = [];
  meetingSelectedItems: any = [];
  meetingSettings = {};
  employeeMemberList: any = [];
  employeeMember: any = [];
  employeeMemberSettings = {};
  locationSettings = {};
  meetingType: any;
  employeeId: any;
  message: any;
  pageNo: number = 1;
  perPage: number = 10;
  totalItem: number = 0;
  page: number = 2;
  exportReport: any;
  reportingUserList: any = [];
  reportingSelectedItem: any = [];
  reportingSettings: any = {};
  reportingLocationList: any = [];
  reportingLocationSettings: any = {};
  locationSelectedItem: any = [];
  reportingTo: any = '';
  reportingLocationId: any = '';
  reportingLocationSelectedItem: any;

  meetingStatusList: any = [];
  meetingStatusSelectedItem: any = [{ id : '', itemName: 'All'}];
  meetingStatusSettings: any = {};
 
  defaultSelectObject: any = { id: '', itemName: 'All' }
  filterLabel = Constants.filterLabel;
  teamList = [];
  teamSettings = {};
  teamSelectedItems: any = [];
  teamId = '';
  uploader: FileUploader;
  @ViewChild('document_info') uploadElRef: ElementRef;
  serverUrl = Config.AuthenticationURL;
  constructor(
    private router: Router,
    public _commonService: CommonService,
    public _prospectService:
      MeetingService, private customErrorHandler: CustomErrorHandler
  ) {
    this.createprospect = false;
  }
  ngOnInit() {
    this.meetingType = '';
    this.tempSearch = '';
    this.employeeId = '';
    this.isExport = false;
    this.isDemoTenant = (localStorage.getItem('isDemoTenant') == 'true');
    this.errormsg = '';
    this.msg = false;
    this.actionLable = localStorage.getItem('actionLabel');
    this.employeeLabel = localStorage.getItem('employeeLabel');
    this.prospectLabel = localStorage.getItem('prospectLabel');
    this.loading = true;
    this.prospectList = [];
    this.employeeLabel = localStorage.getItem('employeeLabel');
    this.meetingModal = document.getElementById('viewMeetingModal');
    this.meetingStatusList = [
                              {id: '', itemName: 'All'},
                              {id: '0', itemName: 'Pending'},
                              {id: '1', itemName: 'Completed'}
                            ]


    this.meetingStatusSelectedItem = [{id: '0', itemName: 'Pending'}];
    this.meetingSettings = {
      singleSelection: true,
      text: "Select " + this.prospectLabel + " Type",
      enableSearchFilter: true,
      classes: "myclass custom-class singleSelection block_elem m_b_15"
    };
    this.employeeMemberSettings = {
      singleSelection: true,
      text: "Select " + this.employeeLabel,
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      enableSearchFilter: true,
      classes: "myclass custom-class singleSelection block_elem m_b_15"
    };   
    var _this;
    _this = this;
    _this.searchModalChanged.debounceTime(1000)
      .subscribe((model) => {
        _this.pagechk = true;
        _this.prospectList = [];
        _this.start = 0;
        _this.getProspectList(1);
      });
    var start = moment().startOf('month');
    var end = moment().endOf('month');
    function cb(start, end) {
      $('#reportrange span').html(start.format('MMM D, YYYY') + ' - ' + end.format('MMM D, YYYY'));
      var f_start = start.format('MMMM D, YYYY');
      var f_end = end.format('MMMM D, YYYY');
      _this.startDate = start.format('YYYY-MM-DD');
      _this.endDate = end.format('YYYY-MM-DD');
      _this.prospectList = [];
      _this.getProspectList(1);
    }
    $('#reportrange').daterangepicker({
      startDate: start,
      endDate: end,
      ranges: {
        'Next 30 Days': [moment(), moment().add(1, 'M')],
        'Today': [moment(), moment()],
        'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
        'Last 7 Days': [moment().subtract(6, 'days'), moment()],
        'Last 30 Days': [moment().subtract(29, 'days'), moment()],
        'This Month': [moment().startOf('month'), moment().endOf('month')],
        'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
      }
    }, cb);
    cb(start, end);
    this.getMeetingTypeList();
    this.employeeListFunction(this.reportingLocationId);
    this.getProspectList(1);
    this.getReportingLocationList();
    this.getReportingUserList(this.reportingLocationId);
    this.teamListFunction(this.reportingLocationId);
    this.reportingSettings = {
      singleSelection: true,
      text: "Select Reporting Person",
      enableSearchFilter: true,
      classes: "singleSelection block_elem m_b_15"
    };
    this.reportingLocationSettings = {
      singleSelection: true,
      text: "Select "+ Constants.locationLabel,
      enableSearchFilter: true,
      classes: "singleSelection block_elem m_b_15"
    };

    this.teamSettings = {
      singleSelection: true,
      text: "Select Team",
      enableSearchFilter: true,
      classes: "singleSelection block_elem m_b_15"
    }

    this.meetingStatusSettings = {
      singleSelection: true,
      text: "Select Status",
      enableSearchFilter: true,
      classes: "myclass custom-class singleSelection block_elem m_b_15"
    };
    this.uploadMettings();
  }
  Opencreatepro() {
    this.createprospect = true;
  }
  getProspectList(currentPage) {
    this.isExport = false;
    this.pageNo = currentPage;
    this.page = currentPage;
    this.message = '';
    this.prospectList = [];
    this.exportReport = '';
    let meetingStatus = '';
    if(this.meetingStatusSelectedItem.length > 0){
      meetingStatus = this.meetingStatusSelectedItem[0].id;
    }
    console.log(this.teamId);
    let queryData = {
      'page': this.pageNo,
      'per_page': this.perPage, 
      'start_date': this.startDate, 
      'end_date': this.endDate, 
      'meeting_type': this.meetingType, 
      'employee_id': this.employeeId, 
      'search': this.searchTextValue, 
      'export': this.exportReport, 
      'reporting_location_id': this.reportingLocationId, 
      'reporting_to': this.reportingLocationId, 
      'meeting_status': meetingStatus, 
      'team_id': this.teamId
    }
    this._prospectService.getMeetingList(queryData).subscribe(
      res => {
        this.loading = false;
        if (res.result == true) {
          this.totalItem = res.data.total;
          this.prospectList = res.data.data;
          this.message = res.message;
        } else {
          this.prospectList = [];
          this.msg = true;
          this.message = res.message;
          this._commonService.showErrorMessage(res);
        }
      },
      error => {
        this.loading = false;
        this.customErrorHandler.handleHttpError(error, 'prospectList');
      });
  }
  prospectDelete(value) {
    swal({
      text: Constants.DeleteMsg,
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Delete',
    }).then((result) => {
      if (typeof result.value != 'undefined') {
        this._prospectService.prospectDelete(value).subscribe(
          res => {
            if (res.result == true) {
              this._commonService.showSuccessMessage(res.message);
              this.prospectList = [];
              this.getProspectList(1);
            }
            else {
              this._commonService.showErrorMessage(res);
            }
          },
          error => {
            this.customErrorHandler.handleHttpError(error, 'prospectDelete');
          });
      } else {
        this.loading = false;
      }
    });
  }
  prospectEdit(id) {
    localStorage.setItem('edit_prospect_id', id);
    this.router.navigate(["meeting/meeting-list/meeting-edit"]);
  }
  onkeyup_value(event) {
    this.searchTextValue = event.target.value;
    if (this.tempSearch != this.searchTextValue) {
      this.getProspectList(1);
    }
    this.tempSearch = this.searchTextValue;
  }
  export() {
    console.log(this.prospectLabel);
    var options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: true,
      useBom: true,
      title: this.prospectLabel + " List"
    };
    this.exportReport = 'yes';
    let meetingStatus = '';
    if(this.meetingStatusSelectedItem.length > 0){
      meetingStatus = this.meetingStatusSelectedItem[0].id;
    }
    let queryData = {
      'page': this.pageNo,
      'per_page': this.perPage, 
      'start_date': this.startDate, 
      'end_date': this.endDate, 
      'meeting_type': this.meetingType, 
      'employee_id': this.employeeId, 
      'search': this.searchTextValue, 
      'export': this.exportReport, 
      'reporting_location_id': this.reportingLocationId, 
      'reporting_to': this.reportingLocationId, 
      'meeting_status': meetingStatus, 
      'team_id': this.teamId
    }
    this._prospectService.getMeetingList(queryData).subscribe(
      res => {
        new Angular2Csv(res.data, this.prospectLabel + ' List', options);
      },
      error => {
        this.customErrorHandler.handleHttpError(error, 'prospectList');
      });
  }
  meetingModalOpen() {
    this.meetingModal.style.display = "block";
  }
  routeCloseModal() {
    this.meetingModal.style.display = "none";
  }
  openMeetingViewModal(meetingId) {
    this.meetingDetails = {}
    this.meetingModalOpen();
    this._prospectService.SelectProspectById(meetingId).subscribe(
      res => {
        if (res.result == true) {
          this.meetingDetails = res.data;
          this.digitalFormLength = res.data.formtemplate.length;
          this.employeeMemberLength = res.data.employee_member.length;
          this.teamLength = res.data.team.length;
        } else {
          this.meetingDetails = {}
        }
      },
      error => {
        this.customErrorHandler.handleHttpError(error, 'SelectProspectById');
      });
  }
  getMeetingTypeList() {
    this._prospectService.meetingTypeList().subscribe(
      res => {
        this.meetingTypeList = res.data;
        if (this.meetingTypeList.length == 0) {
          this.meetingTypeList.unshift({
            'id': '',
            'itemName': 'No Data Available'
          });
        } else {
          this.meetingTypeList.unshift({
            'id': '',
            'itemName': 'Select All'
          });
        }
      },
      error => {
        this.customErrorHandler.handleHttpError(error, 'priorityList');
      });
  }
  employeeListFunction(locationId) {
    this._prospectService.employeeLocationList(locationId).subscribe(
      res => {
        this.employeeMemberList = res.data;
        if (this.employeeMemberList.length == 0) {
          this.employeeMemberList.unshift({
            'id': '',
            'itemName': 'No Data Available'
          });
        } else {
          this.employeeMemberList.unshift({
            'id': '',
            'itemName': 'Select All'
          });
        }
      },
      error => {
        this.customErrorHandler.handleHttpError(error, 'employeeList');
      });
  }
  
  teamListFunction(locationId) {
    this._prospectService.teamList(locationId).subscribe(
      res => {
        this.teamList = res.data;
        if (this.teamList.length > 1) {
          this.teamList.unshift(this.defaultSelectObject);
        }
      },
      error => {
        this.customErrorHandler.handleHttpError(error, 'teamList');
      });
  }
  selectMeetingByType(item: any) {
    if (item.itemName == 'No Data Available') {
      return false;
    }
    if (item.itemName == 'Select All' || item.itemName == 'All') {
      this.meetingType = '';
    } else {
      this.meetingType = item.itemName;
    }
    this.pagechk = true;
    this.start = 0;
    this.prospectList = [];
    this.getProspectList(1);
  }
  selectMeetingByEmployee(item: any) {
    if (item.itemName == 'No Data Available') {
      return false;
    }
    this.employeeId = item.id;
    this.prospectList = [];
    this.getProspectList(1);
  }
  // selectMeetingByLocation(item: any) {
  //   if (item.itemName == 'No Data Available') {
  //     return false;
  //   }
  //   this.locationId = item.id;
  //   this.prospectList = [];
  //   this.getProspectList(1);
  // }
  deselectMeetingByType(item: any) {
    if (item.itemName == 'No Data Available') {
      return false;
    }
    this.meetingType = '';
    this.prospectList = [];
    this.getProspectList(1);
  }
  deselectMeetingByEmployee(item: any) {
    if (item.itemName == 'No Data Available') {
      return false;
    }
    this.employeeId = '';
    this.prospectList = [];
    this.getProspectList(1);
  }
  getReportingUserList(reportingLocationId) {
    let currentUserId = localStorage.getItem('user_id');
    this._commonService.getReportingUserList(reportingLocationId).subscribe(res => {
      if (res.result == true) {
        this.reportingUserList = res.data;
        if (this.reportingUserList.length > 1) {
          this.reportingUserList.unshift(this.defaultSelectObject);
        }
      }
    }, err => {
      this.customErrorHandler.handleHttpError(err, 'getRoleList');
    });
  }
  getReportingLocationList() {
    this._commonService.getLocationList().subscribe(res => {
      if (res.result == true) {
        this.reportingLocationList = res.data;
        if (this.reportingLocationList.length > 1) {
          this.reportingLocationList.unshift(this.defaultSelectObject);
        }
      }
    }, err => {
      this.customErrorHandler.handleHttpError(err, 'getRoleList');
    });
  }
  onUserItemSelect(item: any) {
    this.reportingTo = item.id;
    this.getProspectList(1);
  }
  onUserItemDeSelect(item: any) {
    this.reportingTo = '';
    this.getProspectList(1);
  }

  onTeamSelect(item: any) {
    console.log(item);
    this.teamId = item.id;
    this.getProspectList(1);
  }
  onTeamDeSelect(item: any) {
    this.teamId = '';
    this.getProspectList(1);
  }

  onReportingLocationItemSelect(item: any) {
    this.reportingLocationId = item.id;
    this.reportingTo = '';
    this.teamId = '';
    this.employeeId = '';
    this.reportingUserList = [];
    this.reportingSelectedItem = [];
    this.teamList = [];
    this.teamSelectedItems = [];
    this.employeeMemberList = [];
    // this.employeeMember = [];
    this.employeeMemberSettings = [];
    // if (this.reportingLocationId != '') {
      this.employeeListFunction(this.reportingLocationId);
      this.getReportingUserList(this.reportingLocationId);
      this.teamListFunction(this.reportingLocationId);
    // }
    this.getProspectList(1);
  }
  onReportingLocationItemDeSelect(item: any) {
    this.reportingLocationId = '';
    this.reportingTo = '';
    this.reportingUserList = [];
    this.reportingSelectedItem = [];
    this.teamList = [];
    this.teamSelectedItems = [];
    // this.employeeMember = [];
    this.employeeMemberList = [];
    this.employeeMemberSettings = [];
    this.employeeListFunction(this.reportingLocationId);
    this.getProspectList(1);
    this.teamListFunction(this.reportingLocationId);
  }

  selectMeetingStatus(item: any){
    this.getProspectList(1);
  }

  deselectMeetingStatus(item: any){
    this.getProspectList(1);
  }

  uploadMettings() {
    var this1 = this;
    const URL = Config.AuthenticationBaseURL + 'uploadXlsx';
    this.uploader = new FileUploader({
      url: URL,
      headers: [{ name: 'company_id', value: localStorage.getItem('company_id') },{ name: 'user_id', value: localStorage.getItem('user_id') }],
      allowedFileType: ["xls"]
    });
    this.uploader.onAfterAddingFile = function (file) {
      file.withCredentials = false;
      $('.loader-wrapper').css('display', 'block');
      file.upload();
    };
    this.uploader.onWhenAddingFileFailed = (item) => {
      let errorMsg = 'Invaild file, file format should be .xlsx'
      this.uploadElRef.nativeElement.value = '';
      this._commonService.showCustomError(errorMsg)
    }
    this.uploader.onCompleteItem = (item, response, status, headers) => {
      if (status == 200) {
        var res = JSON.parse(response);
        if (res.results == true) {
          this.uploadElRef.nativeElement.value = ''
          this.getProspectList(1);
          this._commonService.showSuccessMessage(res.message || 'Record inserted sucessfully!');
          $('.loader-wrapper').css('display', 'none');
        } else {
          this.uploadElRef.nativeElement.value = ''
          this.getProspectList(1);
          $('.loader-wrapper').css('display', 'none');
          this._commonService.showErrorMessage(res.message || 'Record not inserted!');
        }
      } else {
        this.uploadElRef.nativeElement.value = ''
        this.getProspectList(1);
        $('.loader-wrapper').css('display', 'none');
        this._commonService.showErrorMessage(res.message || 'Record not inserted!');
      }
    }
  }
}
