import { CreateDigitalFormComponent } from './create-digital-form/create-digital-form.component';
import { DigitalFormComponent } from './digital-form/digital-form.component';
import { EditDigitalFormCategoryComponent } from './edit-digital-form-category/edit-digital-form-category.component';
import { CreateDigitalFormCategoryComponent } from './create-digital-form-category/create-digital-form-category.component';
import { AuthGuard } from './../../services/_guards/auth.guard';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FileUploadModule } from 'ng2-file-upload';
import { SettingComponent } from './setting.component';
import { RoleManagementComponent } from './role-management/role-management.component';
import { EmployeeManagementComponent } from './employee-management/employee-management.component';
import { ComplaintComponent } from './complaint/complaint.component';

import { NotificationComponent } from './notification/notification.component';
import { EditEmployeeComponent } from './edit-employee/edit-employee.component';
import { EditComplaintComponent } from './edit-complaint/edit-complaint.component';

import { RoleComponent } from './role/role.component';
import { TeamComponent } from './team/team.component';
import { AssignRoleComponent } from './role-management/assign-role/assign-role.component';
import { AssignRolelistComponent } from './role-management/assign-rolelist/assign-rolelist.component';
import { UpdateAssignRoleComponent } from './role-management/update-assign-role/update-assign-role.component';
import { InfiniteScrollModule } from 'angular2-infinite-scroll';
import { ImageCropperModule } from 'ng2-img-cropper';
import { TeamModule } from './team/team.module';
import { TeamListComponent } from './team/team-list/team-list.component';
import { Ng2PaginationModule } from 'ng2-pagination';
import { CalendarModule } from 'primeng/calendar';
import { OrganizationalChartComponent } from '../hierarchy/organizational-chart/organizational-chart.component';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown/angular2-multiselect-dropdown';
import { TreeModule } from 'primeng/tree';
import { OrganizationChartModule } from 'primeng/primeng';
import { DigitalFormCategoryComponent } from './digital-form-category/digital-form-category.component';
import { EditDigitalFormComponent } from './edit-digital-form/edit-digital-form.component';
import { AssignsDigitalFormComponent } from './assigns-digital-form/assigns-digital-form.component';
import { AssignsDigitalFormModule } from './assigns-digital-form/assigns-digital-form.module';
export const routes: Routes = [
  {
    path: 'setting',
    component: SettingComponent, canActivate: [AuthGuard],
    children: [
      { path: 'edit-profile', component: EditEmployeeComponent, canActivate: [AuthGuard] },
      { path: '', redirectTo: 'role', pathMatch: 'full' },
      {
        path: 'role-management', component: RoleManagementComponent,
        children: [
          { path: '', redirectTo: 'assign-rolelist', pathMatch: 'full' },
          { path: 'assign-role', component: AssignRoleComponent },
          { path: 'update-assign-role', component: UpdateAssignRoleComponent },
          { path: 'assign-rolelist', component: AssignRolelistComponent }
        ]
      },
      {
        path: 'employee-management', component: EmployeeManagementComponent
      },
      {
        path: 'complaint', component: ComplaintComponent
      },

      { path: 'organizational-chart', component: OrganizationalChartComponent },
      { path: 'notification', component: NotificationComponent },
      { path: 'digital-form', component: DigitalFormComponent },
      { path: 'digital-form/create-digital-form', component: CreateDigitalFormComponent },
      { path: 'digital-form/edit-digital-form', component: EditDigitalFormComponent },
      { path: 'digital-form-category', component: DigitalFormCategoryComponent },
      { path: 'digital-form-category/create-digital-form-category', component: CreateDigitalFormCategoryComponent },
      { path: 'digital-form-category/edit-digital-form-category', component: EditDigitalFormCategoryComponent },
      { path: 'employee-management/employee', component: EditEmployeeComponent },
      { path: 'complaint/edit', component: EditComplaintComponent },
      
      { path: 'role', component: RoleComponent },
      { path: 'assigns-template', component: AssignsDigitalFormComponent },
      {
        path: 'team', component: TeamComponent,
        children: [
          { path: '', redirectTo: 'team-list', pathMatch: 'full' },
          { path: 'team-list', component: TeamListComponent }
        ]
      },
    ]
  }
];
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule.forChild(routes),
    FileUploadModule,
    ReactiveFormsModule,
    InfiniteScrollModule,
    ImageCropperModule,
    AssignsDigitalFormModule,
    TeamModule,
    Ng2PaginationModule,
    CalendarModule,
    AngularMultiSelectModule,
    TreeModule,
    OrganizationChartModule
  ],
  declarations: [
    SettingComponent,
    RoleManagementComponent,
    EmployeeManagementComponent,
    ComplaintComponent,
    NotificationComponent,
    DigitalFormComponent,
    CreateDigitalFormComponent,
    DigitalFormCategoryComponent,
    CreateDigitalFormCategoryComponent,
    EditEmployeeComponent,
    EditComplaintComponent,
    RoleComponent,
    AssignRoleComponent,
    AssignRolelistComponent,
    UpdateAssignRoleComponent,
    EditDigitalFormCategoryComponent,
    EditDigitalFormComponent,
    OrganizationalChartComponent
  ]
})
export class SettingModule { }
