import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PollSurveyService } from '../../../services/polls-surveys/poll-survey';
import { CommonService } from '../../../services/common/common.service';
import { CustomErrorHandler } from '../../../errorhandler/custom-errorhandler';
@Component({
  selector: 'app-survey-result',
  templateUrl: './survey-result.component.html',
  styleUrls: ['./survey-result.component.css']
})
export class SurveyResultComponent implements OnInit {
  loading: boolean;
  selectedSurveyId: any;
  surveyResultArr: any = [];
  pageLabel: string = '';
  data: any;
  chartOptions = {
    legend: {
      display: false
    },
    scales: {
      yAxes: [{
        ticks: {
          beginAtZero: true
        }
      }],
      xAxes: [{
        stacked: false,
        beginAtZero: true,
        ticks: {
          stepSize: 1,
          min: 0,
          autoSkip: false
        }
      }]
    }
  }
  DEFAULT_COLORS = ['#3366CC', '#DC3912', '#FF9900', '#109618']
  constructor(
    public commonService: CommonService,
    private customErrorHandler: CustomErrorHandler,
    private pollSurveyService: PollSurveyService,
    public activatedRoute: ActivatedRoute,
  ) { }
  ngOnInit() {
    this.selectedSurveyId = this.activatedRoute.snapshot.queryParamMap.get('id');
    this.getPollResult(this.selectedSurveyId);
  }
  barChartDataFunction(surveyData) {
    this.pageLabel = surveyData.survey_name;
    surveyData.question_list.forEach((element, idx) => {
      let chartData = [];
      let chartLabels = [];
      let surveyObj: any = {
        datasets: [{
          data: []
        }]
      };
      surveyObj.question = element.question;
      element.option_list.forEach((obj, i) => {
        chartData.push(obj.vote_count);
        chartLabels.push(obj.option_name);
      });
      surveyObj.labels = chartLabels;
      surveyObj.datasets[0].data = chartData;
      surveyObj.datasets[0].backgroundColor = this.DEFAULT_COLORS;
      this.surveyResultArr.push(surveyObj);
    });
  }
  getPollResult(poll_id) {
    this.pollSurveyService.getSurveyResults(poll_id).subscribe(res => {
      console.log(res);
      if (res.result == true) {
        let data = res.data[0];
        this.barChartDataFunction(data);
      }
    }, error => {
      this.customErrorHandler.handleHttpError(error, 'getPollResult');
    });
  }
}
