import { AuthGuard } from './../../services/_guards/auth.guard';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { NearbyComponent } from './nearby.component';
import { MapComponent } from './map/map.component';
export const routes: Routes = [
  {
    path: 'nearby',
    component: NearbyComponent, canActivate: [AuthGuard]
  },
  {
    path: 'nearbyMap',
    component: MapComponent, canActivate: [AuthGuard]
  },
];
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule.forChild(routes)
  ],
  declarations: [
    NearbyComponent,
    MapComponent
  ]
})
export class NearbyModule { }
