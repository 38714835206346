import { DetailMapComponent } from './detail-map.component';
import { AuthGuard } from './../../services/_guards/auth.guard';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { CalendarModule } from 'primeng/calendar';
import { InfiniteScrollModule } from 'angular2-infinite-scroll';
export const routes: Routes = [
  { path: 'detailmap', component: DetailMapComponent, canActivate: [AuthGuard] },
  { path: 'detailmap/:id', component: DetailMapComponent, canActivate: [AuthGuard] }
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule.forChild(routes),
    CalendarModule,
    InfiniteScrollModule
  ],
  declarations: [
    DetailMapComponent
  ]
})
export class DetailMapModule { }
