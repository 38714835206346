import { EmployeeService } from './../../../services/employee/employee.service';
import { Config } from './../../../config/config';
import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { FileUploader } from 'ng2-file-upload';
import { CropperSettings } from 'ng2-img-cropper';
import { CustomErrorHandler } from './../../../errorhandler/custom-errorhandler';
import { CommonService } from '../../../services/common/common.service';
import { Constants } from '../../../common/constants';
declare var moment: any;
@Component({
  selector: 'edit-employee',
  templateUrl: 'edit-employee.component.html',
  styleUrls: ['./edit-employee.component.css']
})
export class EditEmployeeComponent implements OnInit {
  imageBaseUrl: string = Config.AuthenticationURL;
  user_id: string;
  employeeLabel: string;
  loginFormEmail: string;
  status: string;
  errorMessage: string;
  disableButton: boolean;
  signin_btn_txt: string;
  loginDiv: boolean;
  tenantList: any[];
  role: any[];
  accessToken: string;
  image_url: string;
  name: string;
  emp_id: string;
  email: string;
  pwd: string;
  mobile_no: string;
  gender: string = 'Male';
  dob: any;
  user_type: string = '0';
  employee_id: string;
  modal: any;
  uploadedImage: any = '';
  currentImage: any;
  data: any;
  cropperSettings: CropperSettings;
  pageHeader: string;
  uploadImageText: string
  lat = '';
  lng = '';
  createProspect = false;
  isAnnouncement = false;
  prospectLabel: string;
  disableEmail: boolean = true;
  passwordRequired: boolean = true;
  URL = Config.AuthenticationBaseURL + 'upload_attached';
  uploader: FileUploader = new FileUploader({ url: this.URL });
  minDateDOB: any;
  maxDateDOB: any
  emailVal = '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$';
  employeeMemberList = [];
  employeeMemberSettings = {};
  employeeMemberItem = [];
  locationList: any = [];
  locationSettings: any = {};
  locationSelectedItem: any = [];
  locationId: any = '';
  hierarchyId: any;
  roleType: any = '';
  checkAnnouncement = localStorage.getItem('isAnnouncement') == '1' ? true : false;
  locationLabel: any = Constants.locationLabel;
  selectedLocation = '';
  selectedReporting = '';
  isEdit:any;
  isPrimary: boolean = false;
  isLoginPrimary: any = 0;
  editLoggedInEmp: any = '';
  yearRange:any;
  constructor(
    public router: Router,
    private commonService: CommonService,
    public _EmployeeService: EmployeeService,
    private customErrorHandler: CustomErrorHandler
  ) {
    this.errorMessage = '';
    this.disableButton = false;
    this.signin_btn_txt = "Submit";
    this.loginDiv = true;
    this.isEdit = localStorage.getItem('createEmployee');
    var _this = this;
    this.uploader.onAfterAddingFile = function (file) {
      file.withCredentials = false;
      file.upload();
    };
    this.uploader.onCompleteItem = function (item, response, status, headers) {
      if (status == 200) {
        var res = JSON.parse(response);
        if (res.results == true) {
          _this.image_url = res.url;
        }
      }
    }
    //image cropper
    this.cropperSettings = new CropperSettings();
    this.cropperSettings.rounded = true;
    this.cropperSettings.croppedWidth = 200;
    this.cropperSettings.croppedHeight = 200;
    this.data = {};
    this.employeeMemberSettings = {
      singleSelection: true,
      text: "Select Reporting Person",
      enableSearchFilter: true,
      classes: "singleSelection"
    };
    this.locationSettings = {
      singleSelection: true,
      text: "Select " + this.locationLabel,
      enableSearchFilter: true,
      classes: "singleSelection"
    };
    this.getRoleList();
  }
  ngOnInit() {
    this.getYearRange();
    let editEmpId = localStorage.getItem('employeeIdAddBranchOffice1');
    if(editEmpId){
      localStorage.setItem('employee_id', editEmpId);
    }
    this.isLoginPrimary = localStorage.getItem('isLoginPrimary');
    this.getLocationList();
    this.minDateDOB = new Date();
    this.maxDateDOB = new Date();
    this.prospectLabel = localStorage.getItem('prospectLabel');
    this.user_id = localStorage.getItem('user_id');
    this.employeeLabel = localStorage.getItem('employeeLabel');
    this.modal = document.getElementById('uploadModel');
    this.employee_id = localStorage.getItem('employee_id');
    let employeeToEdit = localStorage.getItem('employee_id');
    let loggedInEmployeeToedit = localStorage.getItem('editLoggedInUser');
    let navigateTocreateEmployee = localStorage.getItem('createEmployee');
    this.editLoggedInEmp = loggedInEmployeeToedit;
    if (loggedInEmployeeToedit == 'Yes') {
      this.employee_id = this.user_id;
      this.passwordRequired = false;
      this.pageHeader = 'Update Resource';
      this.uploadImageText = 'Update Profile Picture';
      let loggedInUserId = localStorage.getItem('user_id');
      setTimeout(() => {
        this.employeeById(loggedInUserId);
      });
      this.roleList();
    }
    else if (navigateTocreateEmployee == 'Yes') {
      this.disableEmail = false;
      this.pageHeader = 'Create Resource';
      this.passwordRequired = true;
      this.uploadImageText = 'Upload Profile Picture';
      this.roleList();

    }
    else if (employeeToEdit != null) {
      this.pageHeader = 'Update Resource';
      this.passwordRequired = false;
      this.uploadImageText = 'Update Profile Picture';
      setTimeout(() => {
        this.employeeById(employeeToEdit);
      }, 500);
      this.roleList();
    }
    // this.name = localStorage.getItem('nameFromBranchOfficeEmployee1');
    // this.emp_id = localStorage.getItem('empIdFromBranchOfficeEmployee1');
    // this.email = localStorage.getItem('emailFromBranchOfficeEmployee1');
    // this.pwd = localStorage.getItem('pwdFromBranchOfficeEmployee1');
    // if(localStorage.getItem('genderFromBranchOfficeEmployee1')){
    //   this.gender = localStorage.getItem('genderFromBranchOfficeEmployee1');
    // } 
    // this.user_type = localStorage.getItem('userTypeFromBranchOfficeEmployee1');
    // this.dob = localStorage.getItem('dobFromBranchOfficeEmployee1');
    // this.dob = new Date(this.dob);
    // this.mobile_no = localStorage.getItem('mobileNoFromBranchOfficeEmployee1');
    // this.uploadedImage = localStorage.getItem('imageFromBranchOfficeEmployee1');
    // this.lat = localStorage.getItem('latFromBranchOfficeEmployee1');
    // this.lng = localStorage.getItem('lngFromBranchOfficeEmployee1');


    this.lat = '';
    this.lng = '';
    this.roleList();
  }
  success(position) {
    this.lng = position.coords.longitude;
    this.lat = position.coords.latitude;
  }
  fail() {
    this.lng = '75.8824308';
    this.lat = '22.7664707';
    this.commonService.showCustomError("Please allow your geolocation.");
  }
  roleList() {
    this._EmployeeService.roleList().subscribe(
      res => {
        if (res.result == true) {
          this.role = res.data;
        }
      },
      error => {
        this.customErrorHandler.handleHttpError(error, 'roleList');
      });
  }
  employeeById(userId) {
    this._EmployeeService.employeeById(userId).subscribe(
      res => {
        if (res.result == true) {
          this.name = res.data.first_name;
          this.emp_id = res.data.emp_id;
          this.email = res.data.email;
          this.mobile_no = res.data.mobile_no;
          this.gender = res.data.gender;
          this.user_type = res.data.user_type;
          this.uploadedImage = res.data.profile_pic;
          this.pwd = res.data.password;
          this.dob = (res.data.dob == null || res.data.dob == "") ? new Date() : new Date(res.data.dob);
          this.roleType = res.data.role;
          this.hierarchyId = res.data.hierarchy_id || '';
          this.isAnnouncement = res.data.is_announcement == 1 ? true : false;
          this.isPrimary = res.data.is_primary == 1 ? true : false;
          let reportingTo = this.employeeMemberList.find(s => s.id == res.data.reporting_to);
          if (typeof reportingTo != 'undefined') {
            this.employeeMemberItem = [];
            this.employeeMemberItem.push(reportingTo);
            this.selectedReporting = reportingTo.itemName;
          }
          let locationTemp = this.locationList.find(s => s.id == res.data.location_id);
          if (typeof locationTemp != 'undefined') {
            this.locationSelectedItem = [];
            this.locationSelectedItem.push(locationTemp);
            this.selectedLocation = locationTemp.itemName;
          }
          let checked = res.data.create_prospect;
          if (checked == "1") {
            this.createProspect = true;
          }
          else {
            this.createProspect = false;
          }
          if (this.uploadedImage == null || this.uploadedImage == '') {
            this.uploadImageText = 'Upload Profile Picture';
          }
          else {
            this.uploadImageText = 'Update Profile Picture';
          }
        }
      },
      error => {
        this.customErrorHandler.handleHttpError(error, 'employeeById');
      });
  }
  onSubmit(empManagmentForm) {
    if (empManagmentForm.invalid) {
      this.errorMessage = "Please complete all the mandatory fields.";
      return false;
    }
    if (this.dob == '' || this.dob == undefined) {
      this.errorMessage = "Date of Birth is required";
      return false;
    }
    this.loginFormEmail = this.email;
    this.disableButton = true;
    this.errorMessage = '';
    if (this.locationSelectedItem.length > 0) {
      this.locationId = this.locationSelectedItem[0].id;
    } else {
      this.locationId = '';
    }
    let create = localStorage.getItem('createEmployee');
    if (create == 'Yes') {
      this.signin_btn_txt = "creating..";
      let checked = "0";
      if (this.createProspect) {
        checked = "1";
      }
      else {
        checked = "0";
      }
      let is_announcement = this.isAnnouncement ? '1' : '0'
      this.lat = Config.MapCenter.lat.toString();
      this.lng = Config.MapCenter.lng.toString();
      let reportingTo = this.employeeMemberItem[0].id || '';
      this._EmployeeService.createEmployee(this.name, this.emp_id, this.email, this.pwd, this.gender, this.user_type, this.dob, this.mobile_no, this.uploadedImage, this.lat, this.lng, checked, reportingTo, this.locationId, is_announcement).subscribe(
        res => {
          if (res.result == true) {
            this.commonService.showSuccessMessage(res.message);
            this.router.navigate(["setting/employee-management"]);
          } else {
            this.disableButton = false;
            this.signin_btn_txt = "Submit";
            this.commonService.showErrorMessage(res);
          }
        },
        error => {
          this.customErrorHandler.handleHttpError(error, 'createEmployee');
          this.errorMessage = 'Something went wrong, please try again later.'; // JSON.parse(error['_body']).MessageList[0];
          this.disableButton = false;
          this.signin_btn_txt = "Submit";
        });
    }
    else {
      this.signin_btn_txt = "updating..";
      let userid: string;
      let loggedInEmployeeToedit = localStorage.getItem('editLoggedInUser');
      if (loggedInEmployeeToedit == 'Yes') {
        userid = localStorage.getItem('user_id');
      }
      else {
        userid = localStorage.getItem('employee_id');
      }
      let checked = "0";
      if (this.createProspect) {
        checked = "1";
      }
      let reportingTo;
      if (this.employeeMemberItem.length > 0) {
        reportingTo = this.employeeMemberItem[0].id || '';
      } else {
        reportingTo = ''
      }
      console.log(this.locationId,'loca');
      let is_announcement = this.isAnnouncement ? '1' : '0'
      this._EmployeeService.updateEmployee(this.name, this.emp_id, this.email, this.pwd, this.gender, this.user_type, this.dob, this.mobile_no, this.uploadedImage, userid, checked, reportingTo, this.locationId, is_announcement, this.hierarchyId, this.isPrimary).subscribe(
        res => {
          if (res.result == true) {
            this.commonService.showSuccessMessage(res.message);
            if (loggedInEmployeeToedit == 'Yes') {
              console.log(loggedInEmployeeToedit);
              this.router.navigate(["dashboard"]);
              localStorage.setItem('username', res.data.first_name);
              localStorage.setItem('profile_pic', res.data.profile_pic);
              setTimeout(() => {
                window.location.reload();
              });
            } else {
              this.router.navigate(["setting/employee-management"]);
              if (localStorage.getItem('user_id') == localStorage.getItem('employee_id')) {
                localStorage.setItem('username', res.data.first_name);
                localStorage.setItem('profile_pic', res.data.profile_pic);
                setTimeout(() => {
                  window.location.reload();
                });
              }
            }
          } else {
            this.disableButton = false;
            this.signin_btn_txt = "Submit";
            this.commonService.showErrorMessage(res);
          }
        },
        error => {
          this.customErrorHandler.handleHttpError(error, 'updateEmployee');
          this.disableButton = false;
          this.signin_btn_txt = "Submit";
        });
    }
  }
  openModal() {
    this.data = {};
    this.modal.style.display = "block";
  }
  closeModal() {
    this.modal.style.display = "none";
  }
  resetToDefault() {
    this.data.image = null;
  }
  saveCropppedImage(e) {
    let base64: string;
    base64 = <string>this.data.image;
    base64 = base64.split(/,/)[1];
    this._EmployeeService.uploadProfileImage(base64).subscribe(
      res => {
        if (res.results == true) {
          this.uploadedImage = res.url;
          this.image_url = res.url;
        }
      },
      error => {
        this.customErrorHandler.handleHttpError(error, 'uploadProfile');
      }
    );
    //closing the modal popUp.
    this.modal.style.display = "none";
  }
  touchFunction() {
    this.errorMessage = '';
  }
  onClicked(event) {
    if (event.target.checked == true) {
      this.createProspect = true;
    }
    else {
      this.createProspect = false;
    }
  }
  onAnnouncement(event) {
    if (event.target.checked == true) {
      this.isAnnouncement = true;
    }
    else {
      this.isAnnouncement = false;
    }
  }
  checkDateOfBirth() {
    if (this.dob != null && this.dob != '') {
      let birthYear = moment(this.dob).format('YYYY');
      let currentYear = moment().format('YYYY');
      if (birthYear > currentYear || birthYear < 1900) {
        this.dob = null;
      }
      console.log('date', this.dob);
    }
  }
  getRoleList() {
    let empId = localStorage.getItem('employee_id');
    if(empId == null || empId == undefined){
      empId = '';
    }
    let parameterReq = {employee_id: empId}
    this._EmployeeService.getUserRoleList(parameterReq).subscribe(res => {
      if (res.result == true) {
        this.employeeMemberList = res.data.filter(ele => ele.id != this.employee_id);
      }
    }, error => {
      this.customErrorHandler.handleHttpError(error, 'getRoleList');
    });
  }
  getLocationList() {
    this._EmployeeService.getLocationList().subscribe(res => {
      if (res.result == true) {
        this.locationList = res.data;
      }
    }, error => {
      this.customErrorHandler.handleHttpError(error, 'getRoleList');
    });
  }

  addBranchOffice(){
    console.log(this.employeeMemberItem, this.locationSelectedItem)
    localStorage.setItem('addBranchOfficeFromEmployee','employee');
    let editAdmin = localStorage.getItem('editLoggedInUser');
    if(editAdmin == 'Yes'){
       localStorage.setItem('addBranchOfficeEditLoggedInUser','Yes');
    }
    this.router.navigate(["locations/add-location"]);
  }

  getYearRange() {
    let cutrrentYear = new Date();
    this.yearRange = '1980:' + cutrrentYear.getFullYear();
  }

  ngOnDestroy() {
    localStorage.removeItem('editLoggedInUser');
    localStorage.removeItem('employee_id');
    localStorage.removeItem('createEmployee');
  }
}
