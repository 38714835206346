import { MeetingService } from './../../../services/meeting/meeting.service';
import { Component, OnInit } from '@angular/core';
import { AssignService } from '../../../services/assign/assign.service';
import { ReportService } from '../../../services/report/report.service';
import { Angular2Csv } from 'angular2-csv/Angular2-csv';
import { CustomErrorHandler } from '../../../errorhandler/custom-errorhandler';
import { Config } from './../../../config/config';
import { CommonService } from '../../../services/common/common.service';
import { Constants } from '../../../common/constants';
declare var $: any;
@Component({
  selector: 'app-event-report',
  templateUrl: './event-report.component.html',
  styleUrls: ['./event-report.component.css']
})
export class EventReportComponent implements OnInit {
  isExport: boolean;
  isDemoTenant: boolean;
  prospectLabel: string;
  employeeLabel: string;
  employeeName: any;
  message: any;
  pageNo: number = 1;
  perPage: number = 10;
  totalItem: number = 0;
  employeeEventList: any = [];
  page: number = 2;
  startDate: any;
  endDate: any;
  search: any;
  export: any;
  serverUrl: any;
  eventId: any;
  eventList: any = [];
  eventSelectedItems: any = [];
  errorMessage: any;
  eventSettings = {};
  loading: boolean;
  noDataMsg: string;
  reportingUserList: any = [];
  reportingSelectedItem: any = [];
  reportingSettings: any = {};
  locationList: any = [];
  locationSettings: any = {};
  locationSelectedItem: any = [];
  reportingTo: any = '';
  locationId: any = '';
  defaultSelectObject: any = { id: '', itemName: 'All' };
  filterLabel = Constants.filterLabel;
  constructor(private commonService: CommonService, public _prospectService: MeetingService, public _assignService: AssignService, public _reportService: ReportService, private customErrorHandler: CustomErrorHandler) {
  }
  ngOnInit() {
    this.search = '';
    this.eventSettings = {
      singleSelection: true,
      text: "Select Event",
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      enableSearchFilter: true,
      classes: "myclass custom-class singleSelection block_elem m_b_15"
    };
    this.prospectLabel = localStorage.getItem('prospectLabel');
    this.employeeLabel = localStorage.getItem('employeeLabel');
    this.isExport = false;
    this.isDemoTenant = (localStorage.getItem('isDemoTenant') == 'true');
    this.message = true;
    this.serverUrl = Config.AuthenticationURL;
    this.eventListFunction();
    this.getEventReport(1);
    this.getLocationList();
    this.getReportingUserList(this.locationId);
    this.reportingSettings = {
      singleSelection: true,
      text: "Select Reporting Person",
      enableSearchFilter: true,
      classes: "singleSelection block_elem m_b_15"
    };
    this.locationSettings = {
      singleSelection: true,
      text: "Select "+ Constants.locationLabel,
      enableSearchFilter: true,
      classes: "singleSelection block_elem m_b_15"
    };
  }
  ngOnDestroy() {
    $('.fullHeightBlock').animate({ scrollTop: 0 }, 400);
  }
  getEventReport(currentPage) {
    this.loading = true;
    if (this.eventSelectedItems.length > 0) {
      this.eventId = this.eventSelectedItems[0].id;
    } else {
      this.eventId = '';
    }
    this.pageNo = currentPage;
    this.page = currentPage;
    this.message = '';
    this.export = '';
    this._reportService.eventReport(this.pageNo, this.perPage, this.eventId, this.search, this.export, this.locationId, this.reportingTo).subscribe(
      res => {
        this.loading = false;
        this.employeeEventList = [];
        this.noDataMsg = res.message || 'Currently, there is no data available.';
        if (res.result == true) {
          this.totalItem = res.data.total;
          this.employeeEventList = res.data.data;
          if (this.employeeEventList.length > 0) {
            this.isExport = true;
          }
          else {
            this.isExport = false;
          }
        } else {
          this.message = res.message;
        }
      },
      error => {
        this.customErrorHandler.handleHttpError(error, 'employeeReport');
        this.loading = false;
      });
  }
  exportEventReport() {
    this.export = 'yes';
    this._reportService.eventReport(0, 0, this.eventId, this.search, this.export, this.locationId, this.reportingTo).subscribe(
      res => {
        var options = {
          fieldSeparator: ',',
          quoteStrings: '"',
          decimalseparator: '.',
          showLabels: true,
          showTitle: true,
          useBom: true,
          title: "Event Report"
        };
        new Angular2Csv(res.data, 'Event Report', options);
      },
      error => {
        this.customErrorHandler.handleHttpError(error, 'employeeReport');
      });
  }
  eventListFunction() {
    this._prospectService.eventList(this.locationId).subscribe(
      res => {
        this.eventList = res.data;
        if (this.eventList.length > 1) {
          this.eventList.unshift(this.defaultSelectObject);
        }
        this.noDataMsg = res.message || 'Currently, there is no data available.'
      },
      error => {
        this.customErrorHandler.handleHttpError(error, 'eventList');
      });
  }
  onEventSelect(item: any) {
    this.getEventReport(1);
  }
  onEventDeselect(item: any) {
    this.getEventReport(1);
  }
  getReportingUserList(locationId) {
    let currentUserId = localStorage.getItem('user_id');
    this.commonService.getReportingUserList(locationId).subscribe(res => {
      if (res.result == true) {
        this.reportingUserList = res.data;
        if (this.reportingUserList.length > 1) {
          this.reportingUserList.unshift(this.defaultSelectObject);
        }
      }
    }, err => {
      this.customErrorHandler.handleHttpError(err, 'getRoleList');
    })
  }
  getLocationList() {
    this.commonService.getLocationList().subscribe(res => {
      if (res.result == true) {
        this.locationList = res.data;
        if (this.locationList.length > 1) {
          this.locationList.unshift(this.defaultSelectObject);
        }
      }
    }, error => {
      this.customErrorHandler.handleHttpError(error, 'getRoleList');
    });
  }
  onUserItemSelect(item: any) {
    this.reportingTo = item.id;
    this.getEventReport(1);
  }
  onUserItemDeSelect(item: any) {
    this.reportingTo = '';
    this.getEventReport(1);
  }
  onLocationItemSelect(item: any) {
    this.locationId = item.id;
    this.reportingTo = '';
    this.reportingUserList = [];
    this.reportingSelectedItem = [];
    // if (this.locationId != '') {
      this.getReportingUserList(this.locationId);
    // }
    this.eventListFunction();
    this.getEventReport(1);
  }
  onLocationItemDeSelect(item: any) {
    this.locationId = '';
    this.reportingTo = '';
    this.reportingUserList = [];
    this.reportingSelectedItem = [];
    this.eventListFunction();
    this.getEventReport(1);
  }

  onEventItemSelect(item: any){
    this.getEventReport(1);
  }

  onEventItemDeSelect(item: any){
    this.getEventReport(1);
  }
}
