import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Angular2Csv } from 'angular2-csv/Angular2-csv';
import { CheckListService } from '../../services/check_list/check_list.service';
import { Constants } from '../../common/constants';
import { CustomErrorHandler } from '../../errorhandler/custom-errorhandler';
import swal from 'sweetalert2';
import { CommonService } from '../../services/common/common.service';
declare var $: any;
@Component({
  selector: 'check-list-list',
  templateUrl: 'check-list.component.html',
  styleUrls: ['./check-list.component.css'],
  host: {
    'class': 'fullHeightBlock'
  }
})

export class CheckListComponent implements OnInit {
  isExport: boolean;
  isDemoTenant: boolean;
  errormsg: string;
  msg: boolean;
  actionLable: string;
  employeeLabel: string;
  checklist: any = [];
  loading: boolean;
  perPage: number = 10;
  pageNo: number = 1;
  searchTextValue: any;
  tempSearch: any;
  totalItem: number = 0;
  page: number = 2;
  exportReport: any;
  constructor(private router: Router, public commonService: CommonService, public _CheckListService: CheckListService, private customErrorHandler: CustomErrorHandler) {
  }
  ngOnInit() {
    this.tempSearch = '';
    this.isExport = false;
    this.isDemoTenant = (localStorage.getItem('isDemoTenant') == 'true');
    this.errormsg = '';
    this.msg = false;
    this.actionLable = localStorage.getItem('actionLabel');
    this.employeeLabel = localStorage.getItem('employeeLabel');
    this.loading = true;
    this.getCheckList(1);
    if ($(window).width() > 760) {
      $('.fullHeightBlock').scrollbar();
    }
  }
  ngOnDestroy() {
    $('.fullHeightBlock').remove();
  }

  getCheckList(currentPage) {
    this.pageNo = currentPage;
    this.page = currentPage;
    this.errormsg = '';
    this.checklist = [];
    this.exportReport = '';
    this.isExport = false;
    let request = {
      "company_id": localStorage.getItem('company_id'),
      "per_page": this.perPage,
      "page": this.pageNo,
      "search": this.searchTextValue,
      "export": this.exportReport
    };
    this._CheckListService.getCheckList(request).subscribe(
      res => {
        this.loading = false;
        if (res.result == true) {
          this.totalItem = res.data.total;
          this.checklist = res.data.data;
          this.errormsg = res.message;
          if (this.totalItem > 0) {
            this.isExport = true;
          }
        } else {
          this.checklist = [];
          this.msg = true;
          this.errormsg = res.message;
          this.commonService.showErrorMessage(res);
        }
      },
      error => {
        this.loading = false;
        this.customErrorHandler.handleHttpError(error, 'getCheckList');
      }
    )
  }

  checkListItemDelete(value) {
    swal({
      text: Constants.DeleteMsg,
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Delete',
    }).then((result) => {
      if (typeof result.value != 'undefined') {
        this._CheckListService.checkListItemDelete(value).subscribe(
          res => {
            if (res.result == true) {
              this.commonService.showSuccessMessage(res.message);
              // this.pagechk = true;
              this.pageNo = 1;
              this.checklist = [];
              this.getCheckList(1);
            }
            else {
              this.commonService.showErrorMessage(res);
            }
          },
          error => {
            this.customErrorHandler.handleHttpError(error, 'checkListItemDelete');
          });
      }
    })
  }

  checkListItemEdit(id) {
    localStorage.setItem('checkListId', id);
    this.router.navigate(["managechecklist/" + id]);
  }

  onkeyup_value(event) {
    this.searchTextValue = event.target.value;    
    if (this.tempSearch != this.searchTextValue) {
      this.getCheckList(1);
    }
    this.tempSearch = this.searchTextValue;
  }
  export() {
    var options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: true,
      useBom: true,
      title: "Check-List"
    };
    this.exportReport = 'yes';
    let request = {
      "company_id": localStorage.getItem('company_id'),
      "per_page": this.perPage,
      "page": this.pageNo,
      "search": this.searchTextValue,
      "export": this.exportReport
    };
    this._CheckListService.getCheckList(request).subscribe(
      res => {
        new Angular2Csv(res.data, 'getchecklist', options);

      },
      error => {
        this.customErrorHandler.handleHttpError(error, 'getCheckList');
      }
    )
  }
}
