import { Injectable } from '@angular/core';
import { HttpHelper } from '../../helpers/http.helper';
import { Config } from '../../config/config';
@Injectable()
export class LoginService {
    constructor(private _httpHelper: HttpHelper) {
    }
    doLogin(email: string, password: string) {
        try {
            var request = {
                "email": email,
                "pwd": password
            };
            return this._httpHelper.post(Config.AuthenticationBaseURL1 + 'login', request)
        }
        catch (e) {
        }
    }
    logOut() {
        try {
            const company_id = localStorage.getItem('company_id');
            const user_id = localStorage.getItem('user_id');
            var request = {
                "company_id": company_id,
                "employee_id": user_id
            };
            return this._httpHelper.post(Config.AuthenticationBaseURL + 'logout', request)
        }
        catch (e) {
        }
    }
    forgotPassword(email: string) {
        try {
            var request = {
                "email": email
            };
            return this._httpHelper.post(Config.AuthenticationBaseURL1 + 'forgotpassword', request)
        }
        catch (e) {
        }
    }
    resetPassword(email: string, password: string) {
        try {
            var request = {
                "email": email,
                "password": password
            };
            return this._httpHelper.post(Config.AuthenticationBaseURL + 'resetpassword', request)
        }
        catch (e) {
        }
    }
    saveVisitorData(name: string, email: string, contactNo: string) {
        try {
            var request = {
                "name": name,
                "email": email,
                "contact_no": contactNo
            };
            return this._httpHelper.post(Config.AuthenticationBaseURL + 'saveVisitorData', request)
        }
        catch (e) {
        }
    }
}
