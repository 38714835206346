import { Injectable } from '@angular/core';
import { HttpHelper } from '../../helpers/http.helper';
import { Config } from '../../config/config';
const company_id = localStorage.getItem('company_id');
const user_id = localStorage.getItem('user_id');
@Injectable()
export class CheckListService {
  constructor(private _httpHelper: HttpHelper) {
  }
  createformCategory(name: string, data: any[]) {
    try {
      var request = {
        "label": name,
        "list_item": data,
        "company_id": company_id,
        "created_by": user_id
      };
      return this._httpHelper.post(Config.AuthenticationBaseURL1 + 'add-check-list', request)
    }
    catch (e) {
    }
  }
  editformCategory(formTemplateCategoryId: string, name: string, data: any[]) {
    try {
      var request = {
        "label": name,
        "list_item": data,
        "company_id": company_id,
        "id": formTemplateCategoryId,
        "created_by": user_id
      };
      return this._httpHelper.post(Config.AuthenticationBaseURL1 + 'update-check-list', request)
    }
    catch (e) {
    }
  }
  createformTemplate(name: string, data: string) {
    try {
      var request = {
        "name": name,
        "data": data,
        "company_id": company_id,
        "employee_id": user_id
      };
      return this._httpHelper.post(Config.AuthenticationBaseURL + 'createformTemplate', request)
    }
    catch (e) {
    }
  }
  editFormTemplate(formTemplateId: string, name: string, data: string) {
    try {
      var request = {
        "name": name,
        "data": data,
        "formTemplateId": formTemplateId,
        "company_id": company_id,
        "employee_id": user_id
      };
      return this._httpHelper.post(Config.AuthenticationBaseURL + 'editFormTemplate', request)
    }
    catch (e) {
    }
  }
  createNotification(sms, mail, push) {
    try {
      var request = {
        "sms": sms,
        "mail": mail,
        "push": push,
        "company_id": company_id,
        "employee_id": user_id
      };
      return this._httpHelper.post(Config.AuthenticationBaseURL + 'createNotification', request)
    }
    catch (e) {
    }
  }
  createNotificationList() {
    try {
      var request = {
        "company_id": company_id
      };
      return this._httpHelper.post(Config.AuthenticationBaseURL + 'createNotificationList', request)
    }
    catch (e) {
    }
  }
  getCheckList(request) {
    try {
      return this._httpHelper.post(Config.AuthenticationBaseURL1 + 'get-admin-check-list', request)
    }
    catch (e) {
    }
  }
  getCheckListById(id: string) {
    try {
      var request = {
        "company_id": company_id,
        "id": id
      };
      return this._httpHelper.post(Config.AuthenticationBaseURL1 + 'get-single-check-list', request)
    }
    catch (e) {
    }
  }
  categoryDelete(id: string) {
    try {
      var request = {
        "id": id
      };
      return this._httpHelper.post(Config.AuthenticationBaseURL + 'deleteForm_category', request)
    }
    catch (e) {
    }
  }
  checkListItemDelete(id: string) {
    try {
      var request = {
        "id": id
      };
      return this._httpHelper.post(Config.AuthenticationBaseURL1 + 'delete-check-list', request)
    }
    catch (e) {
    }
  }
}
