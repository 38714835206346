import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
@Injectable()
export class HttpHelper {
    protected httpheaders: HttpHeaders;
    constructor(private _http: HttpClient) {
        this.requestHeader();
    }
    post(url: string, data: any): Observable<any> {
        const httpOptions = {
            headers: this.httpheaders
        };
        return this._http.post(url, JSON.stringify(data), httpOptions);
    }
    get(url: string, params = null): Observable<any> {
        const httpOptions = {
            headers: this.httpheaders,
            params: params
        };
        return this._http.get(url, httpOptions);
    }
    put(url: string, data: any): Observable<any> {
        const httpOptions = {
            headers: this.httpheaders
        };
        return this._http.put(url, JSON.stringify(data), httpOptions);
    }
    delete(url: string, data: any): Observable<any> {
        const httpOptions = {
            headers: this.httpheaders,
            body: JSON.stringify(data)
        };
        return this._http.delete(url, httpOptions);
    }
    private requestHeader() {
        let loginchk = localStorage.getItem('isLogin');
        if (loginchk == '1') {
            this.httpheaders = new HttpHeaders({
                'Content-Type': 'application/json', 'Accept': 'application/json',
                'Authorization': localStorage.getItem('token'),
                'user_id': localStorage.getItem('user_id'),
                'company_id': localStorage.getItem('company_id')
            });
        }
        else {
            this.httpheaders = new HttpHeaders({ 'Content-Type': 'application/json', 'Accept': 'application/json' });
        }
    }
}
