import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { VoterService } from '../../../services/voter/voter.service';
import { CommonService } from '../../../services/common/common.service';
import { CustomErrorHandler } from '../../../errorhandler/custom-errorhandler';
import { Config } from '../../../config/config';
import { Router } from '@angular/router';
import { Constants } from '../../../common/constants';
declare var $: any;
declare var google: any;

@Component({
  selector: 'app-evm-details',
  templateUrl: './evm-details.component.html',
  styleUrls: ['./evm-details.component.css']
})
export class EvmDetailsComponent implements OnInit {

  evmDetailList: any = [];
  perPage: number = 10;
  pageNo: number = 1;
  searchTextValue: any = '';
  loading: boolean;
  totalItem: number = 0;
  page: number = 2;
  searchDetails: any = {};
  exportReport: any;
  imageBaseUrl: string = Config.AuthenticationURL;
  mediaType: any;
  modal: any;
  previewImage: any;
  noDataMsg: any;
  searchTempVal: any;
  startDate: any;
  endDate: any;
  tempSearch: any;
  maxPage: number = 10;
  isDemoTenant: boolean;
  mapModal: any;
  boothLocation: any;
  map: any;
  employeeLabel = localStorage.getItem('employeeLabel');
  evmDetails: any = [];
  evmImage: any = '';
  constructor(
    public voterService: VoterService,
    public commonService: CommonService,
    private customErrorHandler: CustomErrorHandler,
    private router: Router
  ) { }
  ngOnInit() {
    this.isDemoTenant = (localStorage.getItem('isDemoTenant') == 'true');
    if ($(window).width() < 768) {
      this.maxPage = 6;
    }
    this.searchTempVal = '';
    this.searchDetails.search_by = '';
    this.modal = document.getElementById('previewModel');
    this.mapModal = document.getElementById('mapModel');
    this.getEvmDetailList(1);
    this.tempSearch = '';
  }
  getEvmDetailList(currentPage) {
    this.pageNo = currentPage;
    this.page = currentPage;
    let quoteStrings: any = {
      pageNo: currentPage,
      perPage: this.perPage,
      search: this.searchTextValue,
      // startDate: this.startDate,
      // endDate: this.endDate,
      export: ''
    }
    // this.evmDetailList = [];
    this.loading = true;
    this.voterService.getEvmDetailsList(quoteStrings).subscribe(
      response => {
        this.loading = false;
        let res: any = response;
        this.noDataMsg = res.message;
        if (res.result == true) {
          this.totalItem = res.data.total;
          this.evmDetailList = res.data.data;
          console.log(this.evmDetailList);
        } else {
          this.evmDetailList = [];
          this.commonService.showErrorMessage(res);
        }
      },
      error => {
        this.loading = false;
        this.customErrorHandler.handleHttpError(error, 'getCheckList');
      });
  }
  onkeyup_value(event) {
    this.searchTextValue = event.target.value;
    if (this.tempSearch != this.searchTextValue) {
      this.getEvmDetailList(1);
    }
    this.tempSearch = this.searchTextValue;
  }
  openModal(url) {
    console.log(url);
    this.evmImage = url;
    this.modal.style.display = 'block';
  }
  closeModal() {
    this.evmImage = '';
    this.modal.style.display = 'none';
  }
  
  openMapModal(lat, lng, title) {
    this.mapModal.style.display = 'block';
    this.boothLocation = title;
    this.getMapLocationDetails(lat, lng);
  }
  closeMapModal() {
    this.mapModal.style.display = 'none';
    this.boothLocation = '';
    this.getMapLocationDetails(0, 0);
  }

  getMapLocationDetails(lat, lng) {
    let upLat = parseFloat(lat);
    let upLng = parseFloat(lng);
    this.map = new google.maps.Map(document.getElementById('map'), {
      zoom: 2,
      center: { lat: upLat, lng: upLng },
      gestureHandling: 'cooperative',
      styles: Constants.MapStyle
    });
    let myLatLng = {lat: upLat, lng: upLng};
    let marker = new google.maps.Marker({
      position: myLatLng,
      map: this.map,
      title: this.boothLocation
    });
  }

}
