import { Injectable } from '@angular/core';
import { HttpHelper } from '../../helpers/http.helper';
import { Config } from '../../config/config';
declare var $: any;
const company_id = localStorage.getItem('company_id');
const user_id = localStorage.getItem('user_id');
@Injectable()
export class EmployeeListService {
  constructor(private _httpHelper: HttpHelper) {
  }
  homeList(search: string, filter: string, start_date: string, end_date: string, start) {
    try {
      var request = {
        "company_id": company_id,
        "search": search,
        "filter": filter,
        "start_date": start_date,
        "end_date": end_date,
        "start": start
      };
      return this._httpHelper.post(Config.AuthenticationBaseURL + 'homeList', request)
    }
    catch (e) {
    }
  }
  graphData() {
    try {
      var request = {
        "company_id": company_id
      };
      return this._httpHelper.post(Config.AuthenticationBaseURL + 'homeGraphData', request)
    }
    catch (e) {
    }
  }
  areaDelete(area_id: string) {
    try {
      var request = {
        "area_id": area_id
      };
      return this._httpHelper.post(Config.AuthenticationBaseURL + 'deleteArea', request)
    }
    catch (e) {
    }
  }
  createArea(name: string, lat: string, lng: string) {
    try {
      var request = {
        "name": name,
        "company_id": company_id,
        "created_by": user_id,
        "lat": lat,
        "lng": lng
      };
      return this._httpHelper.post(Config.AuthenticationBaseURL + 'createArea_Web', request)
    }
    catch (e) {
    }
  }
  getEmployeeList(pageNo, rowPerPage, search, exportReport, filter, reportingTo, locationId) {
    try {
      if (exportReport == 'yes') {
        return this._httpHelper.get(Config.AuthenticationBaseURL1 + 'get-employee-list' + '?page=' + pageNo + '&per_page=' + rowPerPage + '&filter=' + filter + '&search=' + search + '&export=' + exportReport + '&reporting_to=' + reportingTo + '&location_id=' + locationId);
      } else {
        return this._httpHelper.get(Config.AuthenticationBaseURL1 + 'get-employee-list' + '?page=' + pageNo + '&per_page=' + rowPerPage + '&filter=' + filter + '&search=' + search + '&reporting_to=' + reportingTo + '&location_id=' + locationId);
      }
    }
    catch (e) {
    }
  }
}
