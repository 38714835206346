import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { LoginService } from '../../services/login/login.service';
import { Constants } from '../../common/constants';
import { CustomErrorHandler } from '../../errorhandler/custom-errorhandler';
@Component({
  selector: 'visitor',
  templateUrl: 'visitor.component.html',
  styleUrls: ['./visitor.component.scss']
})
export class VisitorComponent implements OnInit {
  name: string;
  email: string;
  contactNo: string;
  errorMessage: string;
  successMessage: string;
  constructor(public router: Router, public _loginService: LoginService, private customErrorHandler: CustomErrorHandler) {
  }
  ngOnInit() {
    var loginchk = localStorage.getItem('isLogin');
    if (loginchk == "1") {
      this.router.navigate(["dashboard"]);
    }
    this.errorMessage = '';
    this.successMessage = '';
    this.name = '';
    this.email = '';
    this.contactNo = '';
    document.getElementsByTagName('html')[0].classList.add('login_body');
  }
  ngOnDestroy() {
    document.getElementsByTagName('html')[0].classList.remove('login_body');
  }
  onSubmit(model) {
    this.errorMessage = '';
    this.successMessage = '';
    if (model.invalid || this.name == '' || this.email == '' || this.contactNo == '') {
      this.errorMessage = "Please fill all mendatory fields";
      return false;
    }
    this._loginService.saveVisitorData(this.name, this.email, this.contactNo).subscribe(
      res => {
        if (res.result == true) {
          this.demoLogin();
        } else {
          this.errorMessage = Constants.CommonErrorMessage;
        }
      },
      error => {
        this.errorMessage = Constants.CommonErrorMessage;
      });
  }
  demoLogin() {
    let email = 'demouser@gmail.com';
    let password = 'password';
    this._loginService.doLogin(email, password).subscribe(
      res => {
        if (res.result == true) {
          localStorage.setItem('token', res.token);
          localStorage.setItem('isLogin', '1');
          localStorage.setItem('username', res.data[0].first_name);
          localStorage.setItem('profile_pic', res.data[0].profile_pic);
          localStorage.setItem('user_id', res.data[0].id);
          localStorage.setItem('company_id', res.data[0].company_id);
          localStorage.setItem('side_bar_data', res.side_bar_data);
          localStorage.setItem('logo_url', res.data[0].logo_url);
          localStorage.setItem('TenantName', res.data[0].company_name);
          let demo = 'true'; // res.data[0].is_demo==1?'true':'false';
          localStorage.setItem('isDemoTenant', demo);
          window.location.href = '';
        } else {
          this.errorMessage = Constants.CommonErrorMessage;
        }
      },
      error => {
        this.customErrorHandler.handleHttpError(error, 'doLogin');

      });
  }
  touch() {
    this.errorMessage = '';
  }
}
