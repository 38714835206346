import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Config } from '../../../config/config';
import { CustomErrorHandler } from '../../../errorhandler/custom-errorhandler';
import { DocumentLibraryService } from './../../../services/document-library/document-library.service';
import { CommonService } from '../../../services/common/common.service';
import { FileUploader } from 'ng2-file-upload';
declare var $: any;
@Component({
	selector: 'app-create-document',
	templateUrl: './create-document.component.html',
	styleUrls: ['./create-document.component.css']
})
export class CreateDocumentComponent implements OnInit {
	documentDetails: any = {};
	categoryDetails: any = {};
	categorySettings: any = {};
	categorySelectedItems: any = [];
	categoryList: any = [];
	modal: any;
	documentfile: string;
	showName: any;
	serverUrl: any;
	editDocumentId: any;
	fileToUpload: any;
	documentPageLabel: any;
	documentFileEorror: any;
	uploadURL: string = Config.AuthenticationBaseURL + 'upload_attached_doc';
	uploader: FileUploader = new FileUploader({ url: this.uploadURL });
	isDemoTenant: boolean;
	createdUser = '';
	constructor(private router: Router, public _commonService: CommonService, private customErrorHandler: CustomErrorHandler, public _documentLibraryService: DocumentLibraryService) {
		var _this = this;
		this.documentFileEorror = '';
		this.uploader.onAfterAddingFile = function (file) {
			file.withCredentials = false;
			file.upload();
		};
		this.uploader.onCompleteItem = function (item, response, status, headers) {
			if (status == 200) {
				var res = JSON.parse(response);
				_this.documentFileEorror = '';
				if (res.results == true) {
					_this.documentDetails.document = res.url;
					let imageextension = res.url.split('.').pop();
					if (imageextension == 'png' || imageextension == 'jpg' || imageextension == 'jpeg' || imageextension == 'bmp' || imageextension == 'gif') {
						_this.documentfile = _this.serverUrl + '' + res.url;
					} else {
						_this.documentfile = _this.serverUrl + '' + res.dummy_url;
					}
				} else {
					_this.documentFileEorror = res.message;
					_this.documentDetails.document_info = '';
					_this.documentDetails.doc_url = '';
					_this.documentfile = '';
					console.log(_this.documentFileEorror, 'error')
				}
			}
		}
	}
	ngOnInit() {
		this.isDemoTenant = (localStorage.getItem('isDemoTenant') == 'true');
		this.documentPageLabel = 'Create';
		this.documentDetails.document = '';
		this.editDocumentId = '';
		this.editDocumentId = localStorage.getItem('editDocumentId');
		this.serverUrl = Config.imageBaseUrlCI;
		this.categorySettings = {
			singleSelection: true,
			text: "Select Category",
			selectAllText: 'Select All',
			unSelectAllText: 'UnSelect All',
			enableSearchFilter: true,
			classes: "singleSelection"
		};
		this.modal = document.getElementById('myModal');
		this.getCategoryList();
		if (this.editDocumentId) {
			this.documentPageLabel = 'Edit';
			this.getSingleDocument(this.editDocumentId);
		}
	}
	createDocument() {
		this.documentDetails.created_by = localStorage.getItem('user_id');
		if (this.categorySelectedItems.length > 0) {
			this.documentDetails.category_id = this.categorySelectedItems[0].id;
		} else {
			return false;
		}
		if (this.editDocumentId) {
			if (this.documentFileEorror) {
				return false;
			}
			this.documentDetails.doc_id = this.editDocumentId;
			this._documentLibraryService.updateDocument(this.documentDetails).subscribe(
				res => {
					if (res.result == true) {
						this.router.navigate(["document/document-list"]);
						this._commonService.showSuccessMessage(res.message);
					} else {
						this._commonService.showErrorMessage(res);
					}
				},
				error => {
					this.customErrorHandler.handleHttpError(error, 'createEvent');
				}
			)
		} else {
			this._documentLibraryService.addDocument(this.documentDetails).subscribe(
				res => {
					if (res.result == true) {
						this.router.navigate(["document/document-list"]);
						this._commonService.showSuccessMessage(res.message);
					} else {
						this._commonService.showErrorMessage(res);
					}
				},
				error => {
					this.customErrorHandler.handleHttpError(error, 'createEvent');
				}
			)
		}
	}
	Openmodel() {
		this.categoryDetails = {};
		this.modal.style.display = "block";
	}
	closeModal(form: HTMLFormElement) {
		this.modal.style.display = "none";
		form.resetForm();
	}
	createCategory(form: HTMLFormElement) {
		this.categoryDetails.created_by = localStorage.getItem('user_id');
		this._documentLibraryService.createCategory(this.categoryDetails).subscribe(
			res => {
				if (res.result == true) {
					this.getCategoryList();
					this.modal.style.display = "none";
				} else {
					this._commonService.showCustomError(res.message);
				}
				this.closeModal(form);
			},
			error => {
				this.customErrorHandler.handleHttpError(error, 'createEvent');
			}
		)
	}
	getCategoryList() {
		this._documentLibraryService.getCategory().subscribe(
			res => {
				if (res.result == true) {
					this.categoryList = res.data;
					if (typeof this.documentDetails.category_id != 'undefined') {
						let document = this.categoryList.find(s => s.id == this.documentDetails.category_id);
						if (document != undefined && this.categorySelectedItems.length == 0) {
							this.categorySelectedItems = [];// [{ "id": "107", "itemName": "India" }];//[];
							this.categorySelectedItems.push(document);
						}
					}
				} else {
					this._commonService.showCustomError(res.message);
				}
			},
			error => {
				this.customErrorHandler.handleHttpError(error, 'createEvent');
			}
		)
	}
	changeListener($event): void {
		let eventName = $event.target.name;
		let inputValue = $event.target;
		let file: File = inputValue.files[0];
		let myReader: FileReader = new FileReader();
		let imgName = $event.target.files[0].name;
		this.showName = imgName;
		myReader.onloadend = (e) => {
			this.fileToUpload = inputValue.files[0];
			this.documentDetails.document = myReader.result;
			this.documentfile = this.documentDetails.document;
			$('.document-file').attr('src', myReader.result);
		}
		myReader.readAsDataURL(file);
	}
	getSingleDocument(documentId) {
		this._documentLibraryService.getSingleDocument(documentId).subscribe(
			res => {
				if (res.result == true) {
					this.documentDetails = res.data;
					this.createdUser = this.documentDetails.created_user;
					this.documentfile = this.serverUrl + '' + this.documentDetails.doc_url;
					let document = this.categoryList.find(s => s.id == this.documentDetails.category_id);
					if (document != undefined && this.categorySelectedItems.length == 0) {
						this.categorySelectedItems = [];
						this.categorySelectedItems.push(document);
					}
					this.documentfile = this.serverUrl + '' + this.documentDetails.icon_url;
				} else {
					this._commonService.showCustomError(res.message);
				}
			},
			error => {
				this.customErrorHandler.handleHttpError(error, 'employeeReport');
			}
		)
	}
	ngOnDestroy() {
		localStorage.removeItem('editDocumentId');
	}
}
