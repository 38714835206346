import { CustomErrorHandler } from './../../../errorhandler/custom-errorhandler';
import { AssignService } from './../../../services/assign/assign.service';
import { Component, OnInit } from '@angular/core';
import { Constants } from '../../../common/constants';
import swal from 'sweetalert2';
import { CommonService } from '../../../services/common/common.service';
import { MeetingService } from '../../../services/meeting/meeting.service';
@Component({
  selector: 'assigns-digital-form',
  templateUrl: 'assigns-digital-form.component.html',
  styleUrls: ['./assigns-digital-form.component.css']
})
export class AssignsDigitalFormComponent implements OnInit {
  isDemoTenant: boolean;
  errormsg: string;
  msg: boolean;
  actionLable: string;
  employeeLabel: string;
  modal: any;
  editmodal: any;
  company_id: string;
  assignArea: any[];
  template: any[];
  employee: any[];
  team_show: boolean;
  loading: boolean;
  areaName;
  editAreaName;
  loginFormEmail: string;
  status: string;
  errorMessage: string;
  disableButton: boolean;
  signin_btn_txt: string;
  loginDiv: boolean;
  tenantList: any[];
  accessToken: string;
  image_url: string;
  area_id: string;
  oldarea_id: string;
  editarea_id: string;
  dropdownList: any = [];
  editdropdownList: any = [];
  selectedItems = [];
  EditselectedItems = [];
  dropdownSettings = {};
  pageNo: number = 1;
  perPage: number = 10;
  totalItem: number = 0;
  page: number = 2;
  locationList: any = [];
  locationAssignList: any = [];
  locationSettings: any = {};
  locationAssignSettings: any = {};
  locationSelectedItem: any = [];
  locationAssignSelectedItem: any = [];
  reportingTo: any = '';
  locationId: any = '';
  locationAssignId: any = '';
  defaultSelectObject: any = { id: '', itemName: 'All' }
  locationLabel: any = Constants.locationLabel;
  teamList = [];
  teamSettings = {};
  teamSelectedItems: any = [];
  editTeamId;
  parentId;
  filterLabel = Constants.filterLabel;
  teamListFilter = [];
  teamFilterSettings = {};
  teamFilterSelectedItems: any = [];
  teamId = '';
  employeeMemberList: any = [];
  employeeMember: any = [];
  employeeMemberSettings = {};
  employeeId = '';
  editSelectedEmployee: any = [];
  constructor(
    public _assignService: AssignService,
    private customErrorHandler: CustomErrorHandler,
    public commonService: CommonService,
    private _prospectService: MeetingService
  ) { }
  ngOnInit() {
    this.isDemoTenant = (localStorage.getItem('isDemoTenant') == 'true');
    this.errormsg = '';
    this.msg = false;
    this.employeeLabel = localStorage.getItem('employeeLabel');
    this.actionLable = localStorage.getItem('actionLabel');
    this.modal = document.getElementById('myModal');
    this.editmodal = document.getElementById('EditmyModal');
    this.loading = true;
    this.assigntemplateList(1);
    this.templateList();
    // this.employeeList(this.locationAssignId);
    this.selectedItems = [];
    this.dropdownSettings = {
      singleSelection: false,
      text: "Select Members",
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      enableSearchFilter: true,
      classes: "myclass custom-class"
    };
    this.getLocationList();
    // this.getEmployeeList(this.locationId);
    // this.teamListFilterFunction(this.locationId);
    this.locationSettings = {
      singleSelection: true,
      text: "Select "+ Constants.locationLabel,
      enableSearchFilter: true,
      classes: "singleSelection block_elem m_b_15"
    };
    this.locationAssignSettings = {
      singleSelection: true,
      text: "Select "+ Constants.locationLabel,
      enableSearchFilter: true,
      classes: "singleSelection"
    };
    this.teamSettings = {
			singleSelection: false,
			text: "Select Team",
			selectAllText: 'Select All',
			unSelectAllText: 'UnSelect All',
			enableSearchFilter: true
    }
    
    this.teamFilterSettings = {
			singleSelection: true,
      text: "Select Team",
      enableSearchFilter: true,
      classes: "singleSelection block_elem m_b_15"
    }
    this.employeeMemberSettings = {
      singleSelection: true,
      text: "Select " + this.employeeLabel,
      enableSearchFilter: true,
      classes: "myclass custom-class singleSelection block_elem m_b_15"
    }; 
  }
 
  onOpen(event: any) {
    this.dropdownList = this.dropdownList.map((item) => {
      return {
        id: item.id,
        itemName: item.itemName
      }
    });
    this.teamList = this.teamList.map((item) => {
      return {
        id: item.id,
        itemName: item.itemName
      }
    });
  }
  Openmodel() {
    this.modal.style.display = "block";
    this.teamSelectedItems = [];
    this.locationAssignSelectedItem = [];
    this.editTeamId = '';

    this.teamList = [];
    this.dropdownList = [];
    this.selectedItems = [];
    this.getAssignLocationList();
  }
  closeModal() {
    this.area_id = '';
    this.selectedItems = [];
    this.modal.style.display = "none";
  }
  EditOpenmodel() {
    this.editmodal.style.display = "block";
    this.getAssignLocationList();
  }
  EditcloseModal() {
    this.errorMessage = '';
    this.editmodal.style.display = "none";
  }
  assigntemplateList(currentPage) {
    this.pageNo = currentPage;
    this.page = currentPage;
    this.loading = true;
    this.team_show = false;
    this.errormsg = '';
    let queryStrings: any = {
      pageNo: this.pageNo,
      perPage: this.perPage,
      employee_id: this.employeeId, 
      location_id: this.locationId,  
      team_id: this.teamId
    }
    this._assignService.assigntemplateList(queryStrings).subscribe(
      res => {
        this.loading = false;
        if (res.result == true) {
          this.totalItem = res.data.total;
          this.assignArea = res.data.data;
          this.errormsg = res.message;
        } else {
          this.totalItem = 0;
          this.assignArea = [];
          this.errormsg = res.message;
          this.commonService.showErrorMessage(res);
        }
      },
      error => {
        this.loading = false;
        this.customErrorHandler.handleHttpError(error, 'assigntemplateList');
      });
  }
  editTemplateSelectData(area_id, parentId) {
    this.oldarea_id = area_id;
    let parentid = parentId;
    this.editSelectedEmployee = [];
    this._assignService.editTemplateSelectData(area_id, parentid).subscribe(
      res => {
        this.EditOpenmodel();
        this.editarea_id = res.data.template_id;
        this.editTeamId = res.data.team_id;
        this.parentId = res.data.parent_id
        this.teamListFunction(res.data.location_id);
        this.employeeList(res.data.location_id, 'edit');
        this.editAreaName = res.data.name;
        this.EditselectedItems = res.data.selected_employee;
        this.editSelectedEmployee = this.EditselectedItems;
        let lactionTemp = this.locationAssignList.find(s => s.id == res.data.location_id);
        if (typeof lactionTemp != 'undefined') {
          this.locationAssignSelectedItem = [];
          this.locationAssignSelectedItem.push(lactionTemp);
        }        
        // this.employeeListFunction(this.pollData.location_id);
      },
      error => {
        this.customErrorHandler.handleHttpError(error, 'editTemplateSelectData');
      });
  }
  templateList() {
    this._assignService.templateList().subscribe(
      res => {
        this.template = res.data;

        this.area_id = '';
      },
      error => {
        this.customErrorHandler.handleHttpError(error, 'templateList');
      });
  }
  employeeList(locationAssignId, status = null) {
    let parameter = {
      location_id: locationAssignId
    }
    this._assignService.employeeList(parameter).subscribe(
      res => {
        this.employee = res.data;
        this.dropdownList = this.employee;
        this.editdropdownList = this.employee;
        if(status == 'edit'){
          let empList = this.EditselectedItems;
          this.EditselectedItems = [];
          for(let i = 0; i< empList.length;i++){
            let empId = empList[i].id;
            let empTemp = this.editdropdownList.find(s => s.id == empId);
            if (typeof empTemp != 'undefined') {
              this.EditselectedItems.push(empTemp);
            }  
          }
        }
      },
      error => {
        this.customErrorHandler.handleHttpError(error, 'employeeList');
      });
  }
  templateAssignDelete(value, parentId) {
    swal({
      text: Constants.DeleteMsg,
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Delete',
    }).then((result) => {
      if (typeof result.value != 'undefined') {
        this._assignService.templateAssignDelete(value, parentId).subscribe(
          res => {
            if (res.result == true) {
              this.commonService.showSuccessMessage(res.message);
              this.assigntemplateList(1);
            }
            else {
              this.commonService.showErrorMessage(res);
            }
          },
          error => {
            this.customErrorHandler.handleHttpError(error, 'templateAssignDelete');
          });
      }
    });
  }
  onSubmit() {
    if (this.area_id == '' || this.area_id == undefined) {
      this.errorMessage = "Digital Form Should not be blank";
      return false;
    }

    if (this.locationAssignSelectedItem.length == 0) {
      this.errorMessage = "Branch Office Should not be blank";
      return false;
    }

    if (this.teamSelectedItems.length == 0 && this.selectedItems.length == 0) {
      this.errorMessage = "Team or " + this.employeeLabel + " Should not be blank";
      return false;
    }
    this.disableButton = true;
    this.errorMessage = '';
    this.signin_btn_txt = "Creating";
    let member = '';
    for (let i = 0; i < this.selectedItems.length; i++) {
      if (i == 0) {
        member = this.selectedItems[i].id;
      } else {
        member = member + ',' + this.selectedItems[i].id;
      }
    }
    let team_id = this.teamSelectedItems.map(function (elem) { return elem.id; }).join(",");
    let location_id = this.locationAssignSelectedItem.map(function (elem) { return elem.id; }).join(",");
    this._assignService.createAssignTemplate(this.area_id, member, location_id, team_id).subscribe(
      res => {
        if (res.result == true) {
          this.commonService.showSuccessMessage(res.message);
          this.closeModal();
          this.assigntemplateList(1);
          this.locationAssignSelectedItem = [];
        } else {
          this.disableButton = false;
          this.signin_btn_txt = "Submit";
          this.commonService.showErrorMessage(res);
        }
      },
      error => {
        this.customErrorHandler.handleHttpError(error, 'createAssignTemplate');
        this.disableButton = false;
        this.signin_btn_txt = "Submit";
      });
  }
  EditonSubmit() {
    if (this.editarea_id == '' || this.editarea_id == undefined) {
      this.errorMessage = "Digital Form Should not be blank";
      return false;
    }
    if (this.locationAssignSelectedItem.length == 0) {
      this.errorMessage = "Branch Office Should not be blank";
      return false;
    }
    
    if (this.teamSelectedItems.length == 0 && this.EditselectedItems.length == 0) {
      this.errorMessage = "Team or " + this.employeeLabel + " Should not be blank";
      return false;
    }
    this.disableButton = true;
    this.errorMessage = '';
    this.signin_btn_txt = "Creating";
    let editmember = '';
    for (let i = 0; i < this.EditselectedItems.length; i++) {
      if (i == 0) {
        editmember = this.EditselectedItems[i].id;
      } else {
        editmember = editmember + ',' + this.EditselectedItems[i].id;
      }
    }
    let team_id = this.teamSelectedItems.map(function (elem) { return elem.id; }).join(",");
    let location_id = this.locationAssignSelectedItem.map(function (elem) { return elem.id; }).join(",");
    this._assignService.editAssignTemplate(this.editarea_id, editmember, this.oldarea_id, location_id, team_id, this.parentId).subscribe(
      res => {
        if (res.result == true) {
          this.commonService.showSuccessMessage(res.message);
          this.EditcloseModal();
          this.assigntemplateList(1);
          this.locationAssignSelectedItem = [];
        } else {
          this.disableButton = false;
          this.signin_btn_txt = "Submit";
          this.commonService.showErrorMessage(res);
        }
      },
      error => {
        this.customErrorHandler.handleHttpError(error, 'editAssignTemplate');
        this.disableButton = false;
        this.signin_btn_txt = "Submit";
      });
  }
  onAreaSelect() {
    let tem = this.template.find(s => s.name == this.areaName);
    this.area_id = tem.id;
  }
  onEditAreaSelect() {
    let tem = this.template.find(s => s.name == this.editAreaName);
    this.editarea_id = tem.id;
  }
 
  getLocationList() {
    this.commonService.getLocationList().subscribe(res => {
      if (res.result == true) {
        this.locationList = res.data;
        this.locationAssignList = res.data;
        if (this.locationList.length > 1) {
          this.locationList.unshift(this.defaultSelectObject);
        }
      }
    }, error => {
      this.customErrorHandler.handleHttpError(error, 'getRoleList');
    });
  }
  getAssignLocationList() {
    this.commonService.getLocationList().subscribe(res => {
      if (res.result == true) {
        this.locationAssignList = res.data;
      }
    }, error => {
      this.customErrorHandler.handleHttpError(error, 'getRoleList');
    });
  }

  onAssignLocation(item: any) {
    this.locationAssignId = item.id;
    this.EditselectedItems = [];
    this.teamSelectedItems = [];
    this.selectedItems = [];

    this.employeeList(this.locationAssignId);
    this.teamListFunction(this.locationAssignId);
  }
  onAssignLocationDeSelect(item: any) {
    this.locationAssignId = '';
    this.teamSelectedItems = [];
    this.teamListFilter = [];
    this.employeeMemberList = [];
    this.employeeMember = []; 
    this.teamList = [];
    this.editdropdownList = [];
    this.EditselectedItems = [];
    this.dropdownList = [];
    this.selectedItems = [];
  }
  teamListFunction(locationAssignId) {
    this._assignService.teamList(locationAssignId).subscribe(
      res => {
        this.teamSelectedItems = [];
        this.teamList = res.data;
        let teamId = this.editTeamId;
				if (teamId != undefined && this.teamList.length > 0) {
            this.teamSelectedItems = [];
            // teamId = teamId.toString();
            let teamInfo = teamId.split(',');
            for (let i = 0; i < teamInfo.length; i++) {
              let teamTemp = this.teamList.find(s => s.id == teamInfo[i]);
              console.log(teamTemp);
              if (typeof teamTemp != 'undefined') {
                this.teamSelectedItems.push(teamTemp);
              }
            }
        }
        // this.isAssignTeamList = true;
        // this.getAssignPollInfo();        
      },
      error => {
        this.customErrorHandler.handleHttpError(error, 'teamList');
      });
  }
  

  getEmployeeList(location) {
    this._prospectService.employeeLocationList(location).subscribe(
      res => {
        this.employeeMemberList = res.data;
        if (this.employeeMemberList.length > 1) {
          this.employeeMemberList.unshift(this.defaultSelectObject);
          
          // console.log(this.editSelectedEmployee);
        }
      },
      error => {
        this.customErrorHandler.handleHttpError(error, 'employeeList');
      });
  }
  
  teamListFilterFunction(locationId) {
    this._prospectService.teamList(locationId).subscribe(
      res => {
        this.teamListFilter = res.data;
        if (this.teamListFilter.length > 1) {
          this.teamListFilter.unshift(this.defaultSelectObject);
        }
      },
      error => {
        this.customErrorHandler.handleHttpError(error, 'teamList');
      });
  }

  locationItemSelect(item: any) {
    this.locationId = item.id;
    this.teamId = '';
    this.employeeId = '';
    this.teamListFilter = [];
    this.teamSelectedItems = [];
    this.employeeMemberList = [];
    this.employeeMember = [];  
    this.selectedItems = [];  
    this.assigntemplateList(1)
    this.getEmployeeList(this.locationId);
    this.teamListFilterFunction(this.locationId);
  }
  locationItemDeSelect(item: any) {
    this.locationId = '';
    this.teamId = '';
    this.employeeId = '';
    this.teamSelectedItems = [];
    this.teamListFilter = [];
    this.employeeMemberList = [];
    this.employeeMember = [];   
    this.teamList = [];
    this.dropdownList = [];
    this.selectedItems = [];

    // this.editdropdownList = []; 
    this.assigntemplateList(1)
    this.getEmployeeList(this.locationId);
    this.teamListFilterFunction(this.locationId);
  }

  onUserItemSelect(item: any) {
    this.teamId = '';
    this.employeeId = item.id;
    this.assigntemplateList(1)
  }
  onUserItemDeSelect(item: any) {
    this.teamId = '';
    this.employeeId = '';
    this.assigntemplateList(1)
  }

  onTeamSelect(item: any) {
    this.employeeId = '';
    this.teamId = item.id;
    this.assigntemplateList(1)
  }
  onTeamDeSelect(item: any) {
    this.employeeId = '';
    this.teamId = '';
    this.assigntemplateList(1)
  }
}
