import { CustomErrorHandler } from './../../errorhandler/custom-errorhandler';
import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { LoginService } from '../../services/login/login.service';
import { Constants } from '../../common/constants';
import { CommonService } from '../../services/common/common.service';
declare var $: any;
@Component({
  selector: 'login',
  templateUrl: 'login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loginFormEmail: string;
  status: string;
  errorMessage: string;
  disableButton: boolean;
  signin_btn_txt: string;
  loginDiv: boolean;
  tenantList: any[];
  accessToken: string;
  forgotPassworErrorMessage: string;
  forgotPassworSuccessMessage: string;
  forgotPasswordEmail: string;
  email: string;
  password: string;
  loading: boolean;
  constructor(
    public router: Router,
    public _loginService: LoginService,
    private customErrorHandler: CustomErrorHandler,
    public commonService: CommonService
  ) { }
  ngOnInit() {
    this.errorMessage = '';
    this.disableButton = false;
    this.signin_btn_txt = "Sign In";
    this.loginDiv = true;
    var loginchk = localStorage.getItem('isLogin');
    if (loginchk == "1") {
      this.router.navigate(["dashboard"]);
    }
    $('#carouselExampleIndicators').carousel({
      interval: 3000
    });
    this.setHeight();
    document.getElementsByTagName('html')[0].classList.add('login_body');
  }
  setHeight() {
    var windowHeight = $(window).innerHeight();
    $('.login-bg').css('min-height', windowHeight);
  };
  onLoginResize(event) {
    this.setHeight();
  }
  onSubmit(model) {
    if ((this.password == '' || this.password == undefined) && (this.email == '' || this.email == undefined)) {
      this.errorMessage = "Email and Password should not be blank";
      return false;
    }
    if (this.email == '' || this.email == undefined) {
      this.errorMessage = "Email should not be blank";
      return false;
    } else if (this.password == '' || this.password == undefined) {
      this.errorMessage = "Password should not be blank";
      return false;
    }
    this.loginFormEmail = this.email;
    this.disableButton = true;
    this.errorMessage = '';
    this.signin_btn_txt = "Signing in..";
    this._loginService.doLogin(this.email, this.password).subscribe(
      res => {
        this.loading = false;
        if (res.result == true) {
          localStorage.setItem('token', res.token);
          localStorage.setItem('isLogin', '1');
          localStorage.setItem('isAnnouncement', res.data.is_announcement);
          localStorage.setItem('username', res.data.first_name);
          localStorage.setItem('profile_pic', res.data.profile_pic);
          localStorage.setItem('user_id', res.data.id);
          localStorage.setItem('company_id', res.data.company_id);
          localStorage.setItem('side_bar_data', res.side_bar_data);
          localStorage.setItem('logo_url', res.data.logo_url);
          localStorage.setItem('TenantName', res.data.company_name);
          let demo = res.data.is_demo == 1 ? 'true' : 'false';
          localStorage.setItem('isDemoTenant', demo);
          localStorage.setItem('version', res.version);
          localStorage.setItem('VoterList', res.voter_list);
          localStorage.setItem('userType', res.data.user_type);
          localStorage.setItem('isLoginPrimary', res.data.is_primary);
          window.location.href = '';
        } else {
          this.disableButton = false;
          this.signin_btn_txt = "Sign In";
          this.errorMessage = res.message;
        }
      },
      error => {
        this.loading = false;
        this.customErrorHandler.handleHttpError(error, 'doLogin');
        this.disableButton = false;
        this.signin_btn_txt = "Sign In";
      }
    );
  }
  demoLogin() {
    let email = 'demouser@gmail.com';
    let password = 'password';
    this._loginService.doLogin(email, password).subscribe(
      res => {
        this.loading = false;
        if (res.result == true) {
          localStorage.setItem('token', res.token);
          localStorage.setItem('isLogin', '1');
          localStorage.setItem('username', res.data[0].first_name);
          localStorage.setItem('profile_pic', res.data[0].profile_pic);
          localStorage.setItem('user_id', res.data[0].id);
          localStorage.setItem('company_id', res.data[0].company_id);
          localStorage.setItem('side_bar_data', res.side_bar_data);
          localStorage.setItem('logo_url', res.data[0].logo_url);
          localStorage.setItem('TenantName', res.data[0].company_name);
          let demo = 'true'; // res.data[0].is_demo==1?'true':'false';
          localStorage.setItem('isDemoTenant', demo);
          window.location.href = '';
        } else {
          this.errorMessage = Constants.CommonErrorMessage;
        }
      },
      error => {
        this.loading = false;
        this.customErrorHandler.handleHttpError(error, 'demoLogin');
      }
    );
  }
  forgotPassword() {
    this.loading = true;
    this.forgotPassworErrorMessage = '';
    this.forgotPassworSuccessMessage = '';
    if (this.forgotPasswordEmail == undefined || this.forgotPasswordEmail.trim() == '') {
      this.forgotPassworErrorMessage = "Email is required";
      return false;
    }
    this._loginService.forgotPassword(this.forgotPasswordEmail).subscribe(
      res => {
        this.loading = false;
        if (res.result == true) {
          this.commonService.showSuccessMessage('Reset password link sent to your email id.');
          $('#forgot').modal('hide');
        } else {
          this.commonService.showCustomError(res.message);
        }
      },
      error => {
        this.loading = false;
        this.customErrorHandler.handleHttpError(error, 'forgotPassword');
      });
  }
  Resetform() {
    this.forgotPasswordEmail = '';
  }
  ngOnDestroy() {
    document.getElementsByTagName('html')[0].classList.remove('login_body');
  }
}
