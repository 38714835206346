import { DigitalFormService } from './../../../services/digital-form/digital-form.service';
import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { CustomErrorHandler } from './../../../errorhandler/custom-errorhandler';
import { CommonService } from '../../../services/common/common.service';
@Component({
  selector: 'create-digital-form-category',
  templateUrl: 'create-digital-form-category.component.html',
  styleUrls: ['./create-digital-form-category.component.css']
})
export class CreateDigitalFormCategoryComponent implements OnInit {
  loginFormEmail: string;
  status: string;
  errorMessage: string;
  disableButton: boolean;
  signin_btn_txt: string;
  loginDiv: boolean;
  tenantList: any[];
  accessToken: string;
  image_url: string;
  title: string;
  form_category_field: Array<any> = [];
  optionMsg: any;
  constructor(
    private router: Router,
    public digitalFormService: DigitalFormService,
    private customErrorHandler: CustomErrorHandler,
    public commonService: CommonService
  ) {
    this.errorMessage = '';
    this.optionMsg = '';
    this.disableButton = false;
    this.signin_btn_txt = "Submit";
    this.loginDiv = true;
  }
  ngOnInit() {
    this.form_category_field.push({ 'value': 'textbox', 'key': '', 'options': '' });
  }
  Addmore() {
    this.form_category_field.push({ 'value': 'textbox', 'key': '', 'options': '' });
  }
  remove(index) {
    this.form_category_field.splice(index, 1);
  }
  saveForm(data) {
    this.optionMsg = '';
    this.errorMessage = '';
    console.log(data);
    if (this.title == '' || this.title == undefined) {
      this.errorMessage = "Name Should not be blank";
      return false;
    }
    data.forEach(element => {
      if (element.value == 'dropdown' || element.value == 'checkbox') {
        if (element.options == undefined || element.options == '') {
          this.optionMsg = "If type is checkbox or dropdown then option should not be blank";
          return false;
        }
      }
    });
    if (this.optionMsg != '') {
      return false;
    }
    let form_data = JSON.stringify(this.form_category_field);
    this.disableButton = true;
    this.errorMessage = '';
    this.signin_btn_txt = "Creating";
    this.digitalFormService.createformCategory(this.title, form_data).subscribe(
      res => {
        if (res.result == true) {
          this.commonService.showSuccessMessage(res.message);
          this.router.navigate(["setting/digital-form-category"]);
        } else {
          this.disableButton = false;
          this.signin_btn_txt = "Submit";
          this.commonService.showErrorMessage(res);
        }
      }, error => {
        this.customErrorHandler.handleHttpError(error, 'createformCategory');
        this.disableButton = false;
        this.signin_btn_txt = "Submit";
      });
  }
}
