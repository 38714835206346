import { MeetingService } from './../../../services/meeting/meeting.service';
import { Component, OnInit } from '@angular/core';
import { AssignService } from '../../../services/assign/assign.service';
import { ReportService } from '../../../services/report/report.service';
import { Angular2Csv } from 'angular2-csv/Angular2-csv';
import { CustomErrorHandler } from '../../../errorhandler/custom-errorhandler';
import { Config } from './../../../config/config';
import { CommonService } from '../../../services/common/common.service';
import { Constants } from '../../../common/constants';
declare var $: any;
@Component({
  selector: 'app-sales-report',
  templateUrl: './sales-report.component.html',
  styleUrls: ['./sales-report.component.css']
})
export class SalesReportComponent implements OnInit {
  imageBaseUrl: string = Config.AuthenticationURL;
  isExport: boolean;
  public isDemoTenant: boolean;
  prospectLabel: string;
  employeeName: any;
  message: any;
  pageNo: number = 1;
  perPage: number = 10;
  totalItem: number = 0;
  salesList: any = [];
  page: number = 2;
  startDate: any;
  endDate: any;
  search: any;
  export: any;
  serverUrl: any;
  teamId: any;
  teamList: any = [];
  teamSelectedItems = [];
  errorMessage: any;
  employeeLabel: any;
  teamSettings = {};
  noDataMsg: string;
  loading: boolean;
  reportingUserList: any = [];
  reportingSelectedItem: any = [];
  reportingSettings: any = {};
  locationList: any = [];
  locationSettings: any = {};
  locationSelectedItem: any = [];
  reportingTo: any = '';
  locationId: any = '';
  defaultSelectObject: any = { id: '', itemName: 'All' };
  filterLabel = Constants.filterLabel;
  constructor(public _prospectService: MeetingService, public _assignService: AssignService, public _reportService: ReportService, private customErrorHandler: CustomErrorHandler, private commonService: CommonService) {
  }
  ngOnInit() {
    this.search = '';
    this.loading = false;
    this.teamSettings = {
      singleSelection: true,
      text: "Select Team",
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      enableSearchFilter: true,
      classes: "myclass custom-class singleSelection"
    };
    this.prospectLabel = localStorage.getItem('prospectLabel');
    this.employeeLabel = localStorage.getItem('employeeLabel');
    this.isExport = false;
    this.isDemoTenant = (localStorage.getItem('isDemoTenant') == 'true');
    this.message = true;
    this.serverUrl = Config.AuthenticationURL;
    this.getSalesReport(1);
    this.getLocationList();
    this.getReportingUserList(this.locationId);
    this.reportingSettings = {
      singleSelection: true,
      text: "Select Reporting Person",
      enableSearchFilter: true,
      classes: "singleSelection block_elem m_b_15"
    };
    this.locationSettings = {
      singleSelection: true,
      text: "Select "+ Constants.locationLabel,
      enableSearchFilter: true,
      classes: "singleSelection block_elem m_b_15"
    };
  }
  getSalesReport(currentPage) {
    this.teamId = '';
    this.pageNo = currentPage;
    this.page = currentPage;
    this.message = '';
    this.export = '';
    this._reportService.salesReport(this.pageNo, this.perPage, this.teamId, this.search, this.export, this.locationId, this.reportingTo).subscribe(
      res => {
        this.loading = false;
        this.salesList = [];
        this.noDataMsg = res.message || 'Currently, there is no data available.'
        if (res.result == true) {
          this.totalItem = res.data.total;
          this.salesList = res.data.data;
        } else {
          this.message = res.message;
        }
      },
      error => {
        this.loading = false;
        this.customErrorHandler.handleHttpError(error, 'employeeReport');
      });
  }
  exportSalesReport() {
    this.export = 'yes';
    this._reportService.salesReport(0, 0, this.teamId, this.search, this.export, this.locationId, this.reportingTo).subscribe(
      res => {
        var options = {
          fieldSeparator: ',',
          quoteStrings: '"',
          decimalseparator: '.',
          showLabels: true,
          showTitle: true,
          useBom: true,
          title: "Sales Report"
        };
        new Angular2Csv(res.data, 'Sales Report', options);
      },
      error => {
        this.customErrorHandler.handleHttpError(error, 'employeeReport');
      });
  }
  onTeamSelect(item: any) {
    this.getSalesReport(1);
  }
  getLocationList() {
    this.commonService.getLocationList().subscribe(res => {
      if (res.result == true) {
        this.locationList = res.data;
        if (this.locationList.length > 1) {
          this.locationList.unshift(this.defaultSelectObject);
        }
      }
    }, error => {
      this.customErrorHandler.handleHttpError(error, 'getRoleList');
    });
  }
  getReportingUserList(locationId) {
    this.commonService.getReportingUserList(locationId).subscribe(res => {
      if (res.result == true) {
        this.reportingUserList = res.data;
        if (this.reportingUserList.length > 1) {
          this.reportingUserList.unshift(this.defaultSelectObject);
        }
      }
    }, error => {
      this.customErrorHandler.handleHttpError(error, 'getRoleList');
    });
  }
  onUserItemSelect(item: any) {
    this.reportingTo = item.id;
    this.getSalesReport(1);
  }
  onUserItemDeSelect(item: any) {
    this.reportingTo = '';
    this.getSalesReport(1);
  }
  onLocationItemSelect(item: any) {
    this.locationId = item.id;
    this.reportingTo = '';
    this.reportingUserList = [];
    this.reportingSelectedItem = [];
    // if (this.locationId != '') {
      this.getReportingUserList(this.locationId);
    // }
    this.getSalesReport(1);
  }
  onLocationItemDeSelect(item: any) {
    this.locationId = '';
    this.reportingTo = '';
    this.reportingUserList = [];
    this.reportingSelectedItem = [];
    this.getSalesReport(1);
  }
  ngOnDestroy() {
    $('.fullHeightBlock').animate({ scrollTop: 0 }, 400);
  }
}
