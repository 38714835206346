import { Component, OnInit } from '@angular/core';
import { RoleService } from '../../../services/role/role.service';
import { CustomErrorHandler } from './../../../errorhandler/custom-errorhandler';
import { Constants } from '../../../common/constants';
import swal from 'sweetalert2';
import { CommonService } from '../../../services/common/common.service';
@Component({
  selector: 'role',
  templateUrl: 'role.component.html',
  styleUrls: ['./role.component.css']
})
export class RoleComponent implements OnInit {
  isDemoTenant: boolean;
  actionLable: string;
  modal: any;
  Editmodal: any;
  company_id: string;
  role: any[];
  loading: boolean;
  name: string;
  editName: string;
  editRole_type_id: string;
  errorMessage: string;
  disableButton: boolean;
  signin_btn_txt: string;
  noDataMsg: string;
  pageNo: number = 1;
  perPage: number = 10;
  totalItem: number = 0;
  page: number = 2;
  constructor(
    public _roleService: RoleService,
    private customErrorHandler: CustomErrorHandler,
    public commonService: CommonService
  ) { }
  ngOnInit() {
    this.isDemoTenant = (localStorage.getItem('isDemoTenant') == 'true');
    this.actionLable = localStorage.getItem('actionLabel');
    this.signin_btn_txt = 'Submit';
    this.loading = true;
    this.roleTypeList(1);
    this.modal = document.getElementById('myModal');
    this.Editmodal = document.getElementById('EditmyModal');
  }
  Openmodel() {
    this.modal.style.display = "block";
  }
  closeModal() {
    this.name = '';
    this.errorMessage = '';
    this.modal.style.display = "none";
  }
  EditOpenmodel() {
    this.Editmodal.style.display = "block";
  }
  EditcloseModal() {
    this.errorMessage = '';
    this.Editmodal.style.display = "none";
  }
  roleTypeList(currentPage) {
    this.pageNo = currentPage;
    this.page = currentPage;
    this._roleService.roleTypeList(this.pageNo, this.perPage).subscribe(
      res => {
        this.loading = false;
        if (res.result == true) {
          this.totalItem = res.data.total;
          this.role = res.data.data;
          this.noDataMsg = res.message;
        } else {
          this.totalItem = 0;
          this.role = [];
          this.noDataMsg = res.message;
          this.commonService.showErrorMessage(res);
        }
      },
      error => {
        this.loading = false;
        this.customErrorHandler.handleHttpError(error, 'roleTypeList');
      });
  }
  SelectByRoleById(id) {
    this._roleService.SelectByRoleById(id).subscribe(
      res => {
        if (res.result == true) {
          this.EditOpenmodel();
          this.editRole_type_id = res.data.id;
          this.editName = res.data.name;
        }
      },
      error => {
        this.customErrorHandler.handleHttpError(error, 'SelectByRoleById');
      });
  }
  roleTypeDelete(value) {
    swal({
      text: Constants.DeleteMsg,
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Delete',
    }).then((result) => {
      if (typeof result.value != 'undefined') {
        this._roleService.roleTypeDelete(value).subscribe(
          res => {
            if (res.result == true) {
              this.commonService.showSuccessMessage(res.message);
              this.roleTypeList(1);
            }
            else {
              this.commonService.showErrorMessage(res);
            }
          },
          error => {
            this.customErrorHandler.handleHttpError(error, 'roleTypeDelete');
          });
      }
    })
  }
  createRole(value) {
    if (this.name == undefined || this.name.trim() == '') {
      this.errorMessage = "Name Should not be blank";
      return false;
    }
    this.disableButton = true;
    this.errorMessage = '';
    this.signin_btn_txt = "Creating..";
    this._roleService.createRoleType(this.name).subscribe(
      res => {
        if (res.result == true) {
          this.commonService.showSuccessMessage(res.message);
          this.closeModal();
          this.roleTypeList(1);
          this.signin_btn_txt = "Submit";
        } else {
          this.closeModal();
          this.disableButton = false;
          this.signin_btn_txt = "Submit";
          this.commonService.showErrorMessage(res);
        }
      },
      error => {
        this.customErrorHandler.handleHttpError(error, 'createRoleType');
        this.disableButton = false;
        this.signin_btn_txt = "Submit";
      });
  }
  editRole() {
    if (this.editName.trim() == '' || this.editName == undefined) {
      this.errorMessage = "Name Should not be blank";
      return false;
    }
    this.disableButton = true;
    this.errorMessage = '';
    this.signin_btn_txt = "Submit";
    this._roleService.editRoleType(this.editName, this.editRole_type_id).subscribe(
      res => {
        if (res.result == true) {
          this.commonService.showSuccessMessage(res.message);
          this.EditcloseModal();
          this.roleTypeList(1);
        } else {
          this.disableButton = false;
          this.signin_btn_txt = "Update";
          this.commonService.showErrorMessage(res);
        }
      },
      error => {
        this.customErrorHandler.handleHttpError(error, 'editRoleType');
        this.disableButton = false;
        this.signin_btn_txt = "Update";
      });
  }
}
