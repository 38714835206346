import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { EmployeeService } from '../../../services/employee/employee.service';
import { CustomErrorHandler } from '../../../errorhandler/custom-errorhandler';
import { CommonService } from '../../../services/common/common.service';
import { AnnouncementService } from '../../../services/announcements/announcements.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FileUploader } from 'ng2-file-upload';
import { Config } from '../../../config/config';
import swal from 'sweetalert2';
import { Constants } from '../../../common/constants';

@Component({
  selector: 'app-add-announcement',
  templateUrl: './add-announcement.component.html',
  styleUrls: ['./add-announcement.component.css']
})
export class AddAnnouncementComponent implements OnInit {
  @ViewChild('attachment') inputFileVar: ElementRef;
  announceDetails: any = {};
  attachment: any;
  image: any;
  image_url: any;
  loading: any;
  isAttachment: any;
  userId:any = localStorage.getItem('user_id');
  isEditMode: boolean;
  selectedAnnouncement: any;
  pageLabel:any;
  fileError : any;
  uploadURL:string = Config.AuthenticationBaseURL1 + 'upload-file';
  uploader: FileUploader = new FileUploader({ 
    url: this.uploadURL
    // allowedMimeType: ['image/png', 'image/gif', 'image/jpeg']
  });
  imageBaseUrl = Config.AuthenticationURL;
  uploadedList:any = [];
  isAnnouncement = localStorage.getItem('isAnnouncement') == '1' ? true : false;
  announcementType = 'notice';

  branchOfficeList: any = [];
  branchOfficeSettings: any = {};
  branchOfficeSelectedItem: any = [];
  isPrimaryUser: boolean = false;
  isPrimaryLoginUser: any = '';

  constructor(
    public employeeService: EmployeeService,
    private customErrorHandler: CustomErrorHandler,
    private commonservice: CommonService,
    public announcementService: AnnouncementService,
    private router: Router,
    public activatedRoute: ActivatedRoute,
  ) { 
    var _this = this;
		this.fileError = '';
		this.uploader.onAfterAddingFile = function (files) {
      files.withCredentials = false;
      console.log(files,'files');
      let imgName = files.file.name;
      let imageExtensionArray = imgName.split('.').pop();
		  if (imageExtensionArray === 'jpg' || imageExtensionArray === 'png' || imageExtensionArray === 'jpeg' || imageExtensionArray === 'bmp') {
        files.upload();
      }else{
        swal({
          type: 'error',
          text: Constants.extensionmsg,
          confirmButtonText: 'ok',
        })
        this.uploadedList = [];
        return false;
      }
      
		};
		this.uploader.onCompleteItem = function (item, response, status, headers) {
      if (status == 200) {
        _this.fileError = '';
        var res = JSON.parse(response);
        if (res.result == true) {
          let fileName = res.data.file_name;
          _this.uploadedList.push(fileName);
        }
      }
    }
  }

  ngOnInit() {
    this.isPrimaryLoginUser = localStorage.getItem('isLoginPrimary');
    if(this.isPrimaryLoginUser == 1){
      this.announcementType = 'announcement';
    }
    this.loading = false;
    this.pageLabel = 'Add'
    this.selectedAnnouncement = this.activatedRoute.snapshot.queryParamMap.get('id');
    if (this.selectedAnnouncement) {
      this.isEditMode = true;
      this.pageLabel = 'Edit'
      this.getBranchOfficeList();
    } else {
      this.isEditMode = false;
      this.getBranchOfficeList();
    }

    this.branchOfficeSettings = {
      singleSelection: false,
      text: "Select Branch Office",
      selectAllText: 'Select All',
      unSelectAllText: 'Unselect All',
      enableSearchFilter: true,
      classes: "myclass custom-class block_elem m_b_15"
    };   

  }

  createAnnouncement(){ 
    this.loading = true;   
    this.announceDetails.location = this.branchOfficeSelectedItem;
    if(this.announcementType != 'announcement'){
      this.announceDetails.location = [];
    }
    if (this.isEditMode) {
      this.updateAnnouncement();
    } else {
      this.announceDetails.added_by = this.userId;
      this.announceDetails.type = this.announcementType;
      this.announceDetails.images = this.uploadedList;
      this.announcementService.createAnnouncement(this.announceDetails).subscribe(res => {
        if (res.result == true) {
          this.loading = false;
          this.commonservice.showSuccessMessage(res.message);
          this.router.navigate(["announcements/announcement-list"]);
        } else {
          this.loading = false;
          this.commonservice.showErrorMessage(res);
        }
      }, err => {
        this.loading = false;
        this.customErrorHandler.handleHttpError(err, 'createAnnouncement');
      });
    }  
  }

  updateAnnouncement() {
    this.loading = true;
    this.announceDetails.announcement_id = this.selectedAnnouncement;
    this.announceDetails.images = this.uploadedList
    this.announceDetails.type = this.announcementType;
    this.announcementService.updateAnnouncement(this.announceDetails).subscribe(res => {
      if (res.result == true) {
        this.loading = false;
        this.commonservice.showSuccessMessage(res.message);
        this.router.navigate(["announcements/announcement-list"]);
      } else {
        this.loading = false;
        this.commonservice.showErrorMessage(res);
      }
    }, err => {
      this.loading = false;
      this.customErrorHandler.handleHttpError(err, 'updateAnnouncement');
    });
  }

  resetFile() {
    this.inputFileVar.nativeElement.value = "";
  }

  getAnnouncementDetail() {
    this.loading = true;
    let queryStrings: any = {
      announcement_id: this.selectedAnnouncement
    }
    this.announcementService.getSingleAnnouncement(queryStrings).subscribe(res => {
      if (res.result == true) {
          this.announceDetails = res.data;
          this.uploadedList = this.announceDetails.images;
          this.announcementType = this.announceDetails.type;
          this.branchOfficeSelectedItem = [];
          for(let i = 0; i < this.announceDetails.location.length; i++){
            let lactionTemp = this.branchOfficeList.find(s => s.id == this.announceDetails.location[i]);
            if (typeof lactionTemp != 'undefined') {
              this.branchOfficeSelectedItem.push(lactionTemp);
            }
          }
          // console.log(this.branchOfficeSelectedItem,'nnn')
          this.loading = false;
          this.isPrimaryUser = res.data.is_primary;
      } else {
        this.loading = false;
      }
    }, err => {
      this.loading = false;
      this.customErrorHandler.handleHttpError(err, 'getAnnouncementDetail');
    });
  }

  removeAttachment(index) {
    this.uploadedList.splice(index, 1);
  }

  getBranchOfficeList() {
    this.commonservice.getLocationList().subscribe(res => {
      if (res.result == true) {
        this.branchOfficeSelectedItem = [];
        this.branchOfficeList = res.data;
        if(this.isEditMode == false){
          for(let i = 0;i < this.branchOfficeList.length;i++){
            this.branchOfficeSelectedItem.push(this.branchOfficeList[i]);
          }
        }else{
          this.getAnnouncementDetail();
        }
      }
    }, err => {
      this.customErrorHandler.handleHttpError(err, 'getRoleList');
    });
  }

  // changeListener($event): void {
  //   console.log($event,'event')
  //   let eventName = $event.target.name;
  //   let inputValue = $event.target;
  //   let file: File = inputValue.files[0];
  //   let myReader: FileReader = new FileReader();
  //   let imgName = $event.target.files[0].name;

  //   let imageextensionarray = imgName.split('.').pop();
  //   console.log(imageextensionarray,'aarray')
  //   if (imageextensionarray === 'jpg' || imageextensionarray === 'png' || imageextensionarray === 'jpeg' || imageextensionarray === 'bmp') {
  //     myReader.onloadend = (e) => {
  //     }
  //   }else{
  //     $event.target.files.name = '';
  //     swal({
  //       type: 'error',
  //       text: Constants.extensionmsg,
  //       confirmButtonText: 'ok',
  //     })
  //     this.uploadedList = [];
  //   }
  //   // myReader.readAsDataURL(file);
  // }
}
