import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { CustomErrorHandler } from './../../../errorhandler/custom-errorhandler';
import { CommonService } from '../../../services/common/common.service';
import { DigitalFormService } from './../../../services/digital-form/digital-form.service';
@Component({
  selector: 'edit-digital-form-category',
  templateUrl: 'edit-digital-form-category.component.html',
  styleUrls: ['./edit-digital-form-category.component.css']
})
export class EditDigitalFormCategoryComponent implements OnInit {
  loginFormEmail: string;
  status: string;
  errorMessage: string;
  disableButton: boolean;
  signin_btn_txt: string;
  loginDiv: boolean;
  tenantList: any[];
  accessToken: string;
  image_url: string;
  title: string;
  formTemplateCategoryId: string;
  form_category_field: Array<any> = [];
  constructor(
    private router: Router,
    public digitalFormService: DigitalFormService,
    private customErrorHandler: CustomErrorHandler,
    public commonService: CommonService
  ) {
    this.errorMessage = '';
    this.disableButton = false;
    this.signin_btn_txt = "Submit";
    this.loginDiv = true;
  }
  ngOnInit() {
    this.formTemplateCategoryById();
    this.form_category_field.push({ 'value': 'textbox', 'key': '', 'options': '' });
  }
  Addmore() {
    this.form_category_field.push({ 'value': 'textbox', 'key': '', 'options': '' });
  }
  remove(index) {
    this.form_category_field.splice(index, 1);
  }
  formTemplateCategoryById() {
    this.formTemplateCategoryId = localStorage.getItem('formTemplateCategoryId');
    this.digitalFormService.formTemplateCategoryById(this.formTemplateCategoryId).subscribe(
      res => {
        if (res.result == true) {
          this.title = res.data.package_name;
          this.form_category_field = res.data.field_data;
        } else {
        }
      },
      error => {
        this.customErrorHandler.handleHttpError(error, 'formTemplateCategoryById');
      });
  }
  saveForm() {
    if (this.title == '' || this.title == undefined) {
      this.errorMessage = "Name Should not be blank";
      return false;
    }
    let form_data = JSON.stringify(this.form_category_field);
    this.disableButton = true;
    this.errorMessage = '';
    this.signin_btn_txt = "updating..";
    this.digitalFormService.editformCategory(this.formTemplateCategoryId, this.title, form_data).subscribe(
      res => {
        if (res.result == true) {
          this.commonService.showSuccessMessage(res.message);
          this.router.navigate(["setting/digital-form-category"]);
        } else {
          this.disableButton = false;
          this.signin_btn_txt = "Submit";
          this.commonService.showErrorMessage(res);
        }
      },
      error => {
        this.customErrorHandler.handleHttpError(error, 'editformCategory');
        this.disableButton = false;
        this.signin_btn_txt = "Submit";
      });
  }
}
