import { MeetingService } from './../../../services/meeting/meeting.service';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CustomErrorHandler } from '../../../errorhandler/custom-errorhandler';
import { CommonService } from '../../../services/common/common.service';
import { PollSurveyService } from '../../../services/polls-surveys/poll-survey';
@Component({
  selector: 'app-create-poll',
  templateUrl: './create-poll.component.html',
  styleUrls: ['./create-poll.component.css']
})
export class CreatePollComponent implements OnInit {
  pageLabel: string;
  pollData: any = {};
  employeeLabel = localStorage.getItem('employeeLabel');
  companyId = localStorage.getItem('company_id');
  userId = localStorage.getItem('user_id');
  errorMessage: any;
  isLeaderErr: boolean;
  isEditMode: boolean;
  selectedPollId: any;
  isOptionErr: any;
  loading: boolean;
  constructor(
    private router: Router,
    public activatedRoute: ActivatedRoute,
    public commonService: CommonService,
    private customErrorHandler: CustomErrorHandler,
    private pollSurveyService: PollSurveyService,
    public sharedService: MeetingService,
  ) {
    this.pollData.option = [{ 'option_name': '' }];
  }
  ngOnInit() {
    this.isOptionErr = false;
    this.isLeaderErr = false;
    this.pageLabel = 'Create'
    this.selectedPollId = this.activatedRoute.snapshot.queryParamMap.get('id');
    if (this.selectedPollId) {
      this.isEditMode = true;
      this.pageLabel = 'Edit'
      this.getPollDetail();
    } else {
      this.isEditMode = false;
    }
  }
  addMoreLeader() {
    let obj = { 'option_name': '' }
    this.pollData.option.push(obj);
    this.isOptionErr = false;
  }
  removeLeader(index) {
    this.pollData.option.splice(index, 1);
    console.log(this.pollData.option);
  }
  createPoll(data) {
    this.loading = true;
    this.isOptionErr = false;
    data.added_by = this.userId//this.companyId;
    if (this.isEditMode) {
      data.poll_id = this.selectedPollId;
      this.updatePoll(data);
    } else {
      if (this.pollData.option.length < 2) {
        this.loading = false;
        this.isOptionErr = true;
        return false;
      }
      this.pollSurveyService.createPoll(data).subscribe(res => {
        if (res.result == true) {
          this.loading = false;
          this.commonService.showSuccessMessage(res.message);
          this.router.navigate(["poll/poll-list"]);
        } else {
          this.loading = false;
          this.commonService.showErrorMessage(res);
        }
      }, err => {
        this.loading = false;
        this.customErrorHandler.handleHttpError(err, 'createPoll');
      });
    }
  }
  getPollDetail() {
    this.pollSurveyService.getSinglePoll(this.selectedPollId).subscribe(res => {
      console.log(res);
      this.pollData = res.data;
    }, err => {
      this.customErrorHandler.handleHttpError(err, 'getPollDetail');
    });
  }
  updatePoll(data) {
    this.pollSurveyService.updatePoll(data).subscribe(res => {
      if (res.result == true) {
        this.loading = false;
        this.commonService.showSuccessMessage(res.message);
        this.router.navigate(["poll/poll-list"]);
      } else {
        this.loading = false;
        this.commonService.showErrorMessage(res);
      }
    }, err => {
      this.loading = false;
      this.customErrorHandler.handleHttpError(err, 'updatePoll');
    });
  }
}
