import { CustomErrorHandler } from './../../errorhandler/custom-errorhandler';
import { CommonService } from './../../services/common/common.service';
import { EmployeeLeaderBoardService } from './../../services/employee-leader-board/employee-leader-board.service';
import { Component, OnInit, ViewEncapsulation ,AfterViewInit} from '@angular/core';
import { Config } from '../../config/config';
import { Angular2Csv } from 'angular2-csv/Angular2-csv';
import { Constants } from '../../common/constants';
import {TreeNode} from 'primeng/api';
declare var moment: any;
declare var $: any;

export interface TreeNode {
  data?: any;
  children?: TreeNode[];
  leaf?: boolean;
  expanded?: boolean;
}

@Component({
  selector: 'app-employee-leader-board',
  templateUrl: './employee-leader-board.component.html',
  styleUrls: ['./employee-leader-board.component.css'],
  host: {
    'class': 'fullHeightBlock'
  },
  encapsulation: ViewEncapsulation.None
})
export class EmployeeLeaderBoardComponent implements OnInit , AfterViewInit{
  isExport: boolean;
  leaderBoard: any = [];
  isDemoTenant: boolean;
  employeeLabel: string;
  startDate: any;
  endDate: any;
  employeeleaderboard: any = [];
  searchTextValue: any;
  imageBaseUrl: string = Config.AuthenticationURL;
  tempSearch: any;
  message: any;
  prospectLabel: string;
  pageNo: number = 1;
  perPage: number = 10;
  totalItem: number = 0;
  page: number = 2;
  exportReport: any;
  loading: any;
  voterList: boolean = localStorage.getItem('VoterList') == 'true';
  reportingUserList: any = [];
  reportingSelectedItem: any = [];
  reportingSettings: any = {};
  locationList: any = [];
  locationSettings: any = {};
  locationSelectedItem: any = [];
  reportingTo: any = '';
  locationId: any = '';
  defaultSelectObject: any = {id: '',itemName: 'All'}
  files = [];
  filterLabel = Constants.filterLabel;
  constructor(
    private employeeLeaderBoardService: EmployeeLeaderBoardService,
    private customErrorHandler: CustomErrorHandler,
    private commonservice: CommonService
  ) { }
  ngOnInit() {
    this.loading = true;
    this.startDate = '';
    this.endDate = '';
    this.isDemoTenant = (localStorage.getItem('isDemoTenant') == 'true');
    this.isExport = false;
    this.tempSearch = '';
    this.searchTextValue = '';
    this.employeeLabel = localStorage.getItem('employeeLabel');
    this.prospectLabel = localStorage.getItem('prospectLabel');
    this.getEmployeeLeaderBoard(1);
    var _this = this;
    var start = moment().subtract(6, 'days');
    var end = moment();
    function cb(start, end) {
      $('#reportrange span').html(start.format('MMM D, YYYY') + ' - ' + end.format('MMM D, YYYY'));
      _this.startDate = start.format('YYYY-MM-DD');
      _this.endDate = end.format('YYYY-MM-DD');
      _this.getEmployeeLeaderBoard(1);
    }
    $('#reportrange').daterangepicker({
      startDate: start,
      endDate: end,
      maxDate: new Date(),
      ranges: {
        'Today': [moment(), moment()],
        'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
        'Last 7 Days': [moment().subtract(6, 'days'), moment()],
        'Last 30 Days': [moment().subtract(29, 'days'), moment()],
        'This Month': [moment().startOf('month'), moment().endOf('month')],
        'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
      }
    }, cb);
    cb(start, end);
    if ($(window).width() > 760) {
      $('.fullHeightBlock').scrollbar();
    }
    this.getLocationList();
    this.getReportingUserList(this.locationId);
    this.reportingSettings = {
      singleSelection: true,
      text: "Select Reporting Person",
      enableSearchFilter: true,
      classes: "singleSelection block_elem m_b_15"
    };
    this.locationSettings = {
      singleSelection: true,
      text: "Select "+ Constants.locationLabel,
      enableSearchFilter: true,
      classes: "singleSelection block_elem m_b_15"
    };
  }
  getEmployeeLeaderBoard(currentPage) {
    
    this.loading = true;
    this.isExport = false;
    this.pageNo = currentPage;
    this.page = currentPage;
    this.message = '';
    this.exportReport = '';
    let request = 
      "page=" + this.pageNo + "&per_page=" + this.perPage +
      "&company_id=" + localStorage.getItem('company_id') +
      "&search=" + this.searchTextValue +
      "&start_date=" + this.startDate +
      "&end_date=" + this.endDate +
      "&reporting_to=" + this.reportingTo +
      "&location_id=" + this.locationId;
    this.employeeLeaderBoardService.getLeaberBoardList(request).subscribe(
      res => {
        if (res.result == true) {
          
          let data : any = res.data;
          this.files = data;
          // this.totalItem = res.data.total;
          // this.leaderBoard = res.data.data;
          this.message = res.message;
        } else {
          this.leaderBoard = [];
          this.message = res.message;
          this.commonservice.showErrorMessage(res);
        }
        this.loading = false;
      },
      error => {
        this.loading = false;
        this.customErrorHandler.handleHttpError(error, 'graphData');
      });
      
    
  }
  searchEmployeeLeaderBoard() {
    if (this.tempSearch != this.searchTextValue) {
      this.getEmployeeLeaderBoard(1);
    }
    this.tempSearch = this.searchTextValue;
  }
  export() {
    var options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: true,
      useBom: true,
      title: this.employeeLabel + ' LeaderBoard-List'
    };
    let request =
      "page=0&per_page=0" +
      "&company_id=" + localStorage.getItem('company_id') +
      "&search=" + this.searchTextValue +
      "&start_date=" + this.startDate +
      "&end_date=" + this.endDate + "&export=yes";
    this.employeeLeaderBoardService.getLeaberBoardList(request).subscribe(
      res => {
        new Angular2Csv(res.data, this.employeeLabel + ' LeaderBoard-List', options);
      },
      error => {
        this.customErrorHandler.handleHttpError(error, 'graphData');
      });
  }
  getReportingUserList(locationId) {
    this.commonservice.getReportingUserList(locationId).subscribe(res => {
      if (res.result == true) {
        this.reportingUserList = res.data;
        if(this.reportingUserList.length > 1){
          this.reportingUserList.unshift(this.defaultSelectObject);
        }
      }
    }, err => {
      this.customErrorHandler.handleHttpError(err, 'getRoleList');
    })
  }
  getLocationList() {
    this.commonservice.getLocationList().subscribe(res => {
      if (res.result == true) {
        this.locationList = res.data;
        if(this.locationList.length > 1){
          this.locationList.unshift(this.defaultSelectObject);
        }
      }
    }, err => {
      this.customErrorHandler.handleHttpError(err, 'getRoleList');
    })
  }
  onUserItemSelect(item: any) {
    this.reportingTo = item.id;
    this.getEmployeeLeaderBoard(1);
  }
  onUserItemDeSelect(item: any) {
    this.reportingTo = '';
    this.getEmployeeLeaderBoard(1);
  }
  onLocationItemSelect(item: any) {
    this.locationId = item.id;
    this.reportingTo = '';
    this.reportingUserList = [];
    this.reportingSelectedItem = [];
    // if(this.locationId != ''){
      this.getReportingUserList(this.locationId);
    // }
    this.getEmployeeLeaderBoard(1);
  }
  onLocationItemDeSelect(item: any) {
    this.locationId = '';
    this.reportingTo = '';
    this.reportingUserList = [];
    this.reportingSelectedItem = [];
    this.getEmployeeLeaderBoard(1);
  }
  ngOnDestroy() {
    $('.fullHeightBlock').remove();
  }

  ngAfterViewInit() {
    this.loading = true;
    this.getEmployeeLeaderBoard(1);
    this.loading = false;
  }
}
