import { Component, OnInit } from '@angular/core';
import { SideBarService } from '../../services/sidebar/sidebar.service';
import { CustomErrorHandler } from '../../errorhandler/custom-errorhandler';
import { Config } from '../../config/config';
import { Constants } from '../../common/constants';
@Component({
	selector: 'app-sidebar',
	templateUrl: './sidebar.component.html'
})
export class SidebarComponent implements OnInit {
	version: string = localStorage.getItem('version');
	employeeLabel: string;
	logoUrl: any;
	loginFormEmail: string;
	side_data: any;
	employee: boolean;
	area: boolean;
	prospect: boolean;
	assigns: boolean;
	nearby: boolean;
	team: boolean;
	event: boolean;
	giofance: boolean;
	settings: boolean;
	reports: boolean;
	emp_updated: string;
	actions_updated: string;
	area_updated: string;
	prospect_updated: string;
	assigns_updated: string;
	nearby_updated: string;
	team_updated: string;
	event_updated: string;
	giofance_updated: string;
	settings_updated: string;
	reports_updated: string;
	updtedList: any = [];
	route: boolean;
	route_manage: string;
	check_list: string;
	checkList: boolean;
	employee_leader_board: string;
	employeeLeaderBoard: boolean;
	expenses: string;
	expense: boolean;
	docLibrary: boolean;
	document_library: string;
	imageBaseUrl: string = Config.AuthenticationURL;
	voterList: boolean = false;
	survey: boolean = false;
	serveyTitle: string;
	poll: boolean = false;
	dashboard: boolean = false;
	routeAndMeeting: boolean = false;
	meetingDigitalForm: boolean = false;
	followUps: boolean = false;
	notes: boolean = false;
	herarchy: boolean = false;
	roleCategory: boolean = false;
	roleManagement: boolean = false;
	userManagement: boolean = false;
	organizationChart: boolean = false;
	surveyList: boolean = false;
	assignSurvey: boolean = false;
	pollList: boolean = false;
	pollTitle: string;
	complaintTitle: string;
	assignPoll: boolean = false;
	configurations: boolean = false;
	location: boolean = false;
	digitalFormCatefory: boolean = false;
	configurationDigitalForm: boolean = false;
	assignDigitalForm: boolean = false;
	more: boolean = false;
	documentLibrary: boolean = false;
	announcement: boolean = false;
	voter: boolean = false;
	voterListReport: boolean = false;
	assignVoter: boolean = false;
	voterFeedback: boolean = false;
	electionDay: boolean = false;
	evmAssignment: boolean = false;
	locationLabel: any = Constants.locationLabel;
	constructor(private _sidebarservice: SideBarService, private customErrorHandler: CustomErrorHandler) { }
	ngOnInit() {
		this.voterList = localStorage.getItem('VoterList') == 'true';
		this.employeeLabel = localStorage.getItem('employeeLabel');
		if (this.employeeLabel == null) {
			this.employeeLabel = 'Employee(s)';
		}
		this.logoUrl = localStorage.getItem('logo_url');
		this.getLeftSideBarList();
		const side_bar_data = localStorage.getItem('side_bar_data');
		this.side_data = side_bar_data.split(',');
		for (let i = 0; i < this.side_data.length; i++) {
			if (this.side_data[i] == 1) {
				this.dashboard = true;
			}
			if (this.side_data[i] == 2) {
				this.employee = true;
			}
			if (this.side_data[i] == 3) {
				this.routeAndMeeting = true;
			}
			if (this.side_data[i] == 4) {
				this.route = true;
			}
			if (this.side_data[i] == 5) {
				this.prospect = true;
			}
			if (this.side_data[i] == 6) {
				this.meetingDigitalForm = true;
			}
			if (this.side_data[i] == 7) {
				this.followUps = true;
			}
			if (this.side_data[i] == 8) {
				this.notes = true;
			}
			if (this.side_data[i] == 9) {
				this.nearby = true;
			}
			if (this.side_data[i] == 10) {
				this.herarchy = true;
			}
			if (this.side_data[i] == 11) {
				this.employeeLeaderBoard = true;
			}
			if (this.side_data[i] == 12) {
				this.roleCategory = true;
			}
			if (this.side_data[i] == 13) {
				this.roleManagement = true;
			}
			if (this.side_data[i] == 14) {
				this.userManagement = true;
			}
			if (this.side_data[i] == 15) {
				this.team = true;
			}
			if (this.side_data[i] == 16) {
				this.organizationChart = true;
			}
			if (this.side_data[i] == 17) {
				this.survey = true;
			}
			if (this.side_data[i] == 18) {
				this.surveyList = true;
			}
			if (this.side_data[i] == 19) {
				this.assignSurvey = true;
			}
			if (this.side_data[i] == 20) {
				this.poll = true;
			}
			if (this.side_data[i] == 21) {
				this.pollList = true;
			}
			if (this.side_data[i] == 22) {
				this.assignPoll = true;
			}
			if (this.side_data[i] == 23) {
				this.configurations = true;
			}
			if (this.side_data[i] == 24) {
				this.location = true;
			}
			if (this.side_data[i] == 25) {
				this.checkList = true;
			}
			if (this.side_data[i] == 26) {
				this.digitalFormCatefory = true;
			}
			if (this.side_data[i] == 27) {
				this.configurationDigitalForm = true;
			}
			if (this.side_data[i] == 28) {
				this.assignDigitalForm = true;
			}
			if (this.side_data[i] == 29) {
				this.more = true;
			}
			if (this.side_data[i] == 30) {
				this.event = true;
			}
			if (this.side_data[i] == 31) {
				this.expense = true;
			}
			if (this.side_data[i] == 32) {
				this.documentLibrary = true;
			}
			if (this.side_data[i] == 33) {
				this.announcement = true;
			}
			if (this.side_data[i] == 34) {
				this.reports = true;
			}
			if (this.side_data[i] == 35) {
				this.voter = true;
			}
			if (this.side_data[i] == 36) {
				this.voterListReport = true;
			}
			if (this.side_data[i] == 37) {
				this.assignVoter = true;
			}
			if (this.side_data[i] == 38) {
				this.voterFeedback = true;
			}
			if (this.side_data[i] == 39) {
				this.electionDay = true;
			}
			if (this.side_data[i] == 40) {
				this.evmAssignment = true;
			}
		}
	}
	getLeftSideBarList() {
		this._sidebarservice.getleftSideBarList().subscribe(
			res => {
				this.updtedList = res.data;
				for (let i = 0; i < this.updtedList.length; i++) {
					switch (this.updtedList[i].role_name) {
						case 'Employee': {
							this.emp_updated = this.updtedList[i].update_label != null ? this.updtedList[i].update_label : this.updtedList[i].role_name;
							localStorage.setItem('employeeLabel', this.emp_updated);
							break;
						}
						case 'Actions': {
							this.actions_updated = this.updtedList[i].update_label != null ? this.updtedList[i].update_label : this.updtedList[i].role_name;
							localStorage.setItem('actionLabel', this.actions_updated);
							break;
						}
						case 'Meetings': {
							this.prospect_updated = this.updtedList[i].update_label != null ? this.updtedList[i].update_label : this.updtedList[i].role_name;
							localStorage.setItem('prospectLabel', this.prospect_updated);
							break;
						}
						case 'Routes': {
							this.route_manage = this.updtedList[i].update_label != null ? this.updtedList[i].update_label : this.updtedList[i].role_name;
							break;
						}
						case 'Assign Digital Form': {
							this.assigns_updated = this.updtedList[i].update_label != null ? this.updtedList[i].update_label : this.updtedList[i].role_name;
							break;
						}
						case 'Near By': {
							this.nearby_updated = this.updtedList[i].update_label != null ? this.updtedList[i].update_label : this.updtedList[i].role_name;
							break;
						}
						case 'Team': {
							this.team_updated = this.updtedList[i].update_label != null ? this.updtedList[i].update_label : this.updtedList[i].role_name;
							break;
						} case 'Events': {
							this.event_updated = this.updtedList[i].update_label != null ? this.updtedList[i].update_label : this.updtedList[i].role_name;
							break;
						} case 'Geofence': {
							this.giofance_updated = this.updtedList[i].update_label != null ? this.updtedList[i].update_label : this.updtedList[i].role_name;
							localStorage.setItem('geofencingLabel', this.giofance_updated);
							break;
						} case 'Configurations': {
							this.settings_updated = this.updtedList[i].update_label != null ? this.updtedList[i].update_label : this.updtedList[i].role_name;
							break;
						} case 'Reports': {
							this.reports_updated = this.updtedList[i].update_label != null ? this.updtedList[i].update_label : this.updtedList[i].role_name;
							break;
						} case 'Check List': {
							this.check_list = this.updtedList[i].update_label != null ? this.updtedList[i].update_label : this.updtedList[i].role_name;
							break;
						}
						case 'Employee Leader Board': {
							this.employee_leader_board = this.updtedList[i].update_label != null ? this.updtedList[i].update_label : this.updtedList[i].role_name;
							break;
						}
						case 'Expenses': {
							this.expenses = this.updtedList[i].update_label != null ? this.updtedList[i].update_label : this.updtedList[i].role_name;
							break;
						}
						case 'Document Library': {
							this.document_library = this.updtedList[i].update_label != null ? this.updtedList[i].update_label : this.updtedList[i].role_name;
							break;
						}
						case 'Survey': {
							this.serveyTitle = this.updtedList[i].update_label != null ? this.updtedList[i].update_label : this.updtedList[i].role_name;
							break;
						}
						case 'Polls': {
							this.pollTitle = this.updtedList[i].update_label != null ? this.updtedList[i].update_label : this.updtedList[i].role_name;
							break;
						}
						case 'Complaint': {
							this.complaintTitle = this.updtedList[i].update_label != null ? this.updtedList[i].update_label : this.updtedList[i].role_name;
							break;
						}
						
					}
				}
			},
			error => {
				this.customErrorHandler.handleHttpError(error, 'getleftSideBarList');
			});
	}
}
