import { OnInit, Component } from "@angular/core";
import { ReportService } from '../../../services/report/report.service';
import { Angular2Csv } from 'angular2-csv/Angular2-csv';
import { CustomErrorHandler } from '../../../errorhandler/custom-errorhandler';
declare var $: any;
@Component({
  selector: 'movement-report',
  templateUrl: 'movement-report.component.html',
  styleUrls: ['./movement-report.component.css']
})
export class MovementReportComponent implements OnInit {
  isDemoTenant: boolean;
  start: number;
  pagechk: boolean;
  loading: boolean;
  noData: boolean;
  reportList: any[];
  employeeLabel: string;
  noDataMsg: string;
  constructor(private reportService: ReportService, private customErrorHandler: CustomErrorHandler) {
  }
  ngOnInit() {
    this.isDemoTenant = (localStorage.getItem('isDemoTenant') == 'true');
    this.employeeLabel = localStorage.getItem('employeeLabel');
    this.loading = true;
    this.pagechk = true;
    this.start = 0;
    this.noData = false;
    this.getReportList();
  }
  getReportList() {
    this.noData = false;
    if (this.pagechk) {
      this.reportService.MovementReport(this.start).subscribe(
        res => {
          this.noDataMsg = res.message || 'Currently, there is no data available.';
          if (res.result == true) {
            if (this.reportList.length == 0) {
              this.reportList = res.data;
              if (res.data == 0) {
                this.noData = true;
              }
            } else {
              this.reportList = this.reportList.concat(res.data);
            }
            if (res.data.length == 10) {
              this.pagechk = true;
              this.start = this.start + 10;
            } else {
              this.pagechk = false;
            }
            this.loading = false;
          } else {

            this.loading = false;
          }
        },
        error => {
          this.loading = false;
          this.customErrorHandler.handleHttpError(error, 'MovementReport');
        });
    }
  }
  export() {
    var options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: true,
      useBom: true,
      title: 'movement report' + this.employeeLabel
    };
    new Angular2Csv(this.reportList, 'movement report ' + this.employeeLabel, options);
  }
  ngOnDestroy() {
    $('.fullHeightBlock').animate({ scrollTop: 0 }, 400);
  }
}
