import { Injectable } from '@angular/core';
import { HttpHelper } from '../../helpers/http.helper';
import { Config } from '../../config/config';
const company_id = localStorage.getItem('company_id');
const user_id = localStorage.getItem('user_id');
@Injectable()
export class EmployeeService {
  constructor(private _httpHelper: HttpHelper) {
  }
  createEmployee(name: string, emp_id: string, email: string, pwd: string, gender: string, user_type: string, dob: string, mobile_no: string, profile_pic: string, lat: string, lng: string, create_prospect = '0', reportingTo, locationId, isAnnouncement) {
    try {
      var request = {
        "name": name,
        "emp_id": emp_id,
        "email": email,
        "pwd": pwd,
        "gender": gender,
        "user_type": user_type,
        "dob": dob,
        "mobile_no": mobile_no,
        "company_id": company_id,
        "created_by": user_id,
        "profile_pic": profile_pic,
        "lat": lat,
        "lng": lng,
        "create_prospect": create_prospect,
        'reporting_to': reportingTo,
        "location_id": locationId,
        'is_announcement': isAnnouncement
      };
      return this._httpHelper.post(Config.AuthenticationBaseURL1 + 'create-employee', request)
    }
    catch (e) {
    }
  }
  updateEmployee(name: string, emp_id: string, email: string, pwd: string, gender: string, user_type: string, dob: string, mobile_no: string, profile_pic: string, employee_id: string, create_prospect = '0', reportingTo, locationId, isAnnouncement, hierarchyId, isPrimary) {
    try {
      var request:any = {
        "name": name,
        "emp_id": emp_id,
        "email": email,
        "pwd": pwd,
        "gender": gender,
        "user_type": user_type,
        "dob": dob,
        "mobile_no": mobile_no,
        "company_id": company_id,
        "created_by": user_id,
        "profile_pic": profile_pic,
        "employee_id": employee_id,
        "create_prospect": create_prospect,
        "reporting_to": reportingTo,
        "location_id": locationId,
        'is_announcement': isAnnouncement,
        'hierarchy_id': hierarchyId
      };
      if (isPrimary) {
        request.location_id = locationId,
        request.emp_id = emp_id
      }
      return this._httpHelper.post(Config.AuthenticationBaseURL1 + 'update-employee', request)
    }
    catch (e) {
    }
  }
  employeeById(id) {
    try {
      var request = {
        "company_id": company_id,
        "employee_id": id,
      };
      return this._httpHelper.post(Config.AuthenticationBaseURL1 + 'get-employee-by-id', request)
    }
    catch (e) {
    }
  }
  employeeNameByIdList(id: string) {
    try {
      var request = {
        "company_id": company_id,
        "employee_id": id,
      };
      return this._httpHelper.post(Config.AuthenticationBaseURL + '', request)
    }
    catch (e) {
    }
  }
  employeeList(pageNo, rowPerPage, exportReport, search, reportingUserId, locationId, roleId) {
    try {
      return this._httpHelper.get(Config.AuthenticationBaseURL1 + 'employeeListOnly' + '?page=' + pageNo + '&per_page=' + rowPerPage + '&export=' + exportReport + '&search=' + search + '&reporting_to=' + reportingUserId + '&location_id=' + locationId+'&role_id='+roleId);
    }
    catch (e) {
    }
  }
  employeeDelete(employee_id: string) {
    try {
      var request = {
        "employee_id": employee_id
      };
      return this._httpHelper.post(Config.AuthenticationBaseURL + 'deleteEmployee', request)
    }
    catch (e) {
    }
  }
  roleList() {
    try {
      var request = {
        "company_id": company_id
      };
      return this._httpHelper.post(Config.AuthenticationBaseURL1 + 'get-role-type-list', request)
    }
    catch (e) {
    }
  }
  //upload_attached_base64
  //base64String
  uploadProfile(base64: string) {
    try {
      var request = {
        "base64String": base64
      };
      return this._httpHelper.post(Config.AuthenticationBaseURL + 'upload_attached_base64', request)
    }
    catch (e) {
    }
  }
  uploadProfileImage(base64: string) {
    try {
      var request = {
        "base64String": base64
      };
      return this._httpHelper.post(Config.AuthenticationBaseURL1 + 'upload-image-web', request)
    }
    catch (e) {
    }
  }
  getUserRoleList(request) {
    try {
      return this._httpHelper.get(Config.AuthenticationBaseURL1 + 'hierarchy/get-role-list', request)
    }
    catch (e) {
    }
  }
  getLocationList() {
    try {
      return this._httpHelper.get(Config.AuthenticationBaseURL1 + 'get-location-list')
    }
    catch (e) {
    }
  }
}
