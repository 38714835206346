import { Pipe, PipeTransform } from '@angular/core';
@Pipe({ name: 'filterpipe' })
export class FilterPipe implements PipeTransform {
    transform(list: any, property: string[], searchText: any): any {
        if (searchText == null) return list;
        switch (property.length) {
            case 0: {
                return list;
            }
            case 1: {
                return list.filter(function (list) {
                    return list[property[0]].toLowerCase().indexOf(searchText.toLowerCase()) > -1;
                })
            }
            case 2: {
                return list.filter(function (list) {
                    return (list[property[0]].toLowerCase().indexOf(searchText.toLowerCase()) > -1 || list[property[1]].toLowerCase().indexOf(searchText.toLowerCase()) > -1);
                })
            }
            case 3: {
                return list.filter(function (list) {
                    return (list[property[0]].toLowerCase().indexOf(searchText.toLowerCase()) > -1 || list[property[1]].toLowerCase().indexOf(searchText.toLowerCase()) > -1 || list[property[2]].toLowerCase().indexOf(searchText.toLowerCase()) > -1);
                })
            }
            case 4: {
                return list.filter(function (list) {
                    return (list[property[0]].toLowerCase().indexOf(searchText.toLowerCase()) > -1 || list[property[1]].toLowerCase().indexOf(searchText.toLowerCase()) > -1 || list[property[2]].toLowerCase().indexOf(searchText.toLowerCase()) > -1 || list[property[3]].toLowerCase().indexOf(searchText.toLowerCase()) > -1);
                })
            }
            case 5: {
                break;
            }
            case 6: {
                break;
            }
            default: {
                break;
            }
        }
    }
}