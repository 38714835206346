import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Config } from '../../../config/config';
import { CustomErrorHandler } from '../../../errorhandler/custom-errorhandler';
import { VoterService } from './../../../services/voter/voter.service';
import { CommonService } from '../../../services/common/common.service';
import { FileUploader } from 'ng2-file-upload';
declare var moment: any;
declare var $: any;
@Component({
	selector: 'app-add-voter',
	templateUrl: './add-voter.component.html',
	styleUrls: ['./add-voter.component.css']
})
export class AddVoterComponent implements OnInit {
	voterIdDetails: any = {};
	categoryDetails: any = {};
	categorySettings: any = {};
	categorySelectedItems: any = [];
	categoryList: any = [];
	modal: any;
	documentfile: string;
	showName: any;
	serverUrl: any;
	editVoterId: any;
	fileToUpload: any;
	voterPageLabel: any;
	stateList: any = [];
	stateSettings: any = {};
	stateSelectedItems: any = [];
	constituencyList: any = [];
	constituencySettings: any = {};
	constituencySelectedItems: any = [];
	loading: boolean;
	isConstituency: any = false;
	URL = Config.AuthenticationBaseURL + 'uploadXlsx';
	public uploader: FileUploader = new FileUploader({ url: this.URL });
	constructor(private router: Router, public _commonService: CommonService, private customErrorHandler: CustomErrorHandler, public voterService: VoterService) {
	}
	ngOnInit() {
		this.isConstituency = true;
		this.voterIdDetails.verify_status = 0;
		this.voterIdDetails.country = 'India';
		this.stateSettings = {
			singleSelection: true,
			text: "Select State",
			selectAllText: 'Select All',
			unSelectAllText: 'UnSelect All',
			enableSearchFilter: true,
			classes: "singleSelection"
		};
		this.constituencySettings = {
			singleSelection: true,
			text: "Select Constituency",
			selectAllText: 'Select All',
			unSelectAllText: 'UnSelect All',
			enableSearchFilter: true,
			classes: "singleSelection"
		}
		this.voterIdDetails.gender = '';
		this.voterPageLabel = 'Create';
		this.editVoterId = '';
		this.editVoterId = localStorage.getItem('editVoterId');
		this.documentfile = '';
		this.serverUrl = Config.imageBaseUrlCI;
		this.categorySettings = {
			singleSelection: true,
			text: "Select Category",
			selectAllText: 'Select All',
			unSelectAllText: 'UnSelect All',
			enableSearchFilter: true,
			classes: "singleSelection"
		};
		this.modal = document.getElementById('myModal');
		this.getStateList();
		if (this.editVoterId) {
			this.voterPageLabel = 'Edit';
			this.getSingleVoter(this.editVoterId);
		} else {
			this.voterIdDetails.id_card_number = this.makeRandomString();
		}
		$('.main-content').css('height', '100%');
	}
	createVoter() {
		this.isConstituency = false;
		this.loading = true;
		if (this.voterIdDetails.constituency == undefined || this.voterIdDetails.constituency == '') {
			this.isConstituency = true;
			return false;
		}
		this.voterIdDetails.dob = moment(this.voterIdDetails.dob).format('YYYY-MM-DD');
		if (this.editVoterId) {
			this.voterIdDetails.doc_id = this.editVoterId;
			this.voterService.updateVoter(this.voterIdDetails).subscribe(
				res => {
					this.loading = false;
					if (res.result == true) {
						this.router.navigate(["voter/voter-list"]);
						this._commonService.showSuccessMessage(res.message);
					} else {
						this._commonService.showErrorMessage(res);
					}
				},
				error => {
					this.loading = false;
					this.customErrorHandler.handleHttpError(error, 'createEvent');
				});
		} else {
			this.voterService.addVoter(this.voterIdDetails).subscribe(
				res => {
					if (res.result == true) {
						this.loading = false;
						this.router.navigate(["voter/voter-list"]);
						this._commonService.showSuccessMessage(res.message);
					} else {
						this._commonService.showErrorMessage(res);
					}
				},
				error => {
					this.loading = false;
					this.customErrorHandler.handleHttpError(error, 'createEvent');
				});
		}
	}
	getSingleVoter(voterId) {
		this.voterService.getSingleVoter(voterId).subscribe(
			res => {
				if (res.result == true) {
					this.voterIdDetails = res.data;
					this.voterIdDetails.dob = new Date(this.voterIdDetails.dob);
					let stateTemp = this.stateList.find(s => s.itemName == this.voterIdDetails.state);
					this.stateSelectedItems = [];
					if (typeof stateTemp != 'undefined') {
						this.getConstituencyList(this.voterIdDetails.state);
						this.stateSelectedItems.push(stateTemp);
					}
				} else {
					this._commonService.showCustomError(res.message);
				}
			},
			error => {
				this.customErrorHandler.handleHttpError(error, 'employeeReport');
			});
	}
	getStateList() {
		this.voterService.getStateList().subscribe(
			res => {
				if (res.result == true) {
					this.stateList = res.data;
					if (this.editVoterId && this.voterIdDetails.state != undefined) {
						this.getConstituencyList(this.voterIdDetails.state);
						let stateTemp = this.stateList.find(s => s.itemName == this.voterIdDetails.state);
						this.stateSelectedItems = [];
						if (typeof stateTemp != 'undefined') {
							this.stateSelectedItems.push(stateTemp);
						}
					}
				} else {
					this._commonService.showCustomError(res.message);
				}
			},
			error => {
				this.customErrorHandler.handleHttpError(error, 'getStateList');
			});
	}
	selectStateDetails() {
		this.voterIdDetails.state = this.stateSelectedItems[0].itemName;
		this.voterIdDetails.constituency = '';
		this.constituencySelectedItems = [];
		this.getConstituencyList(this.stateSelectedItems[0].itemName);
	}
	getConstituencyList(state) {
		this.voterService.getConstituencyList(state).subscribe(
			res => {
				if (res.result == true) {
					this.constituencyList = res.data;
					if (this.editVoterId && this.voterIdDetails.constituency != undefined) {
						let constituencyTemp = this.constituencyList.find(s => s.id == this.voterIdDetails.constituency);
						if (typeof constituencyTemp != 'undefined') {
							this.constituencySelectedItems.push(constituencyTemp);
							this.isConstituency = false;
						}
					}
				} else {
					this._commonService.showCustomError(res.message);
				}
			},
			error => {
				this.customErrorHandler.handleHttpError(error, 'getStateList');
			});
	}
	deselectStateDetails() {
		this.voterIdDetails.state = '';
		this.constituencyList = [];
		this.voterIdDetails.constituency = '';
		this.constituencySelectedItems = [];
	}
	selectConstituencyDetails() {
		this.isConstituency = false;
		this.voterIdDetails.constituency = this.constituencySelectedItems[0].id;
	}
	deselectConstituencyDetails() {
		this.isConstituency = true;
		this.voterIdDetails.constituency = '';
		this.constituencySelectedItems = [];
	}
	checkNumber(event: any) {
		const pattern = /[0-9]/;
		let inputChar = String.fromCharCode(event.charCode);
		console.log(inputChar);
		if (!pattern.test(inputChar) && event.charCode != '' && event.charCode != null && event.charCode != undefined && event.charCode != 8) {
			event.preventDefault();
		}
	}
	makeRandomString() {
		let text = "";
		let alphabetString = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
		for (var i = 0; i < 4; i++)
			text += alphabetString.charAt(Math.floor(Math.random() * alphabetString.length));
		let numericString = "0123456789";
		for (var i = 0; i < 7; i++)
			text += numericString.charAt(Math.floor(Math.random() * numericString.length));
		return text;
	}
	ngOnDestroy() {
		localStorage.removeItem('editVoterId');
	}
}
