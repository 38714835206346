import { OnInit, Component } from "@angular/core";
import { ReportService } from '../../../services/report/report.service';
import { Angular2Csv } from 'angular2-csv/Angular2-csv';
import { CustomErrorHandler } from '../../../errorhandler/custom-errorhandler';
import { Config } from "../../../config/config";
import { CommonService } from '../../../services/common/common.service';
import { Constants } from "../../../common/constants";
declare var moment: any;
declare var $: any;
@Component({
  selector: 'employee-performance',
  templateUrl: 'employee-performance.component.html',
  styleUrls: ['./employee-performance.component.css']
})
export class EmployeePerformanceComponent implements OnInit {
  imageBaseUrl: string = Config.AuthenticationURL;
  isDemoTenant: boolean;
  loading: boolean;
  noData: boolean;
  reportList: any[];
  employeeLabel: string;
  prospectLabel: string;
  start_date: string;
  end_date: string;
  noDataMsg: string;
  page: number = 2;
  pageNo: number = 1;
  perPage: number = 10;
  totalItem: number = 0;
  exportReport: any;
  reportingUserList: any = [];
  reportingSelectedItem: any = [];
  reportingSettings: any = {};
  locationList: any = [];
  locationSettings: any = {};
  locationSelectedItem: any = [];
  reportingTo: any = '';
  locationId: any = '';
  defaultSelectObject: any = { id: '', itemName: 'All' };
  filterLabel = Constants.filterLabel;
  constructor(private commonService: CommonService, private reportService: ReportService, private customErrorHandler: CustomErrorHandler) {
  }
  ngOnInit() {
    this.isDemoTenant = (localStorage.getItem('isDemoTenant') == 'true');
    this.employeeLabel = localStorage.getItem('employeeLabel');
    this.prospectLabel = localStorage.getItem('prospectLabel');
    this.noData = false;
    this.reportList = [];
    var start = moment().subtract(6, 'days');
    var end = moment();
    var _this;
    _this = this;
    function cb(start, end) {
      $('#reportrange span').html(start.format('MMM D, YYYY') + ' - ' + end.format('MMM D, YYYY'));
      var f_start = start.format('YYYY-MM-DD');
      var f_end = end.format('YYYY-MM-DD');
      _this.start_date = f_start;
      _this.end_date = f_end;
      _this.search();
    }
    $('#reportrange').daterangepicker({
      startDate: start,
      endDate: end,
      ranges: {
        'Today': [moment(), moment(),],
        'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
        'Last 7 Days': [moment().subtract(6, 'days'), moment()],
        'Last 30 Days': [moment().subtract(29, 'days'), moment()],
        'This Month': [moment().startOf('month'), moment().endOf('month'), 'inclusive'],
        'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
      }
    }, cb);
    cb(start, end);
    this.getReportList(1);
    this.getLocationList();
    this.getReportingUserList(this.locationId);
    this.reportingSettings = {
      singleSelection: true,
      text: "Select Reporting Person",
      enableSearchFilter: true,
      classes: "singleSelection m_b_15 block_elem"
    };
    this.locationSettings = {
      singleSelection: true,
      text: "Select "+ Constants.locationLabel,
      enableSearchFilter: true,
      classes: "singleSelection m_b_15 block_elem"
    }
  }
  search() {
    this.getReportList(1);
  }
  getReportList(currentPage) {
    this.loading = true;
    this.noData = false;
    this.pageNo = currentPage;
    this.page = currentPage;
    this.noDataMsg = '';
    this.reportList = [];
    this.exportReport = '';
    this.reportService.employeePerformance(this.pageNo, this.perPage, this.start_date, this.end_date, this.exportReport, this.locationId, this.reportingTo).subscribe(
      res => {
        this.loading = false;
        if (res.result == true) {
          this.totalItem = res.data.total;
          this.reportList = res.data.data;
          this.noDataMsg = res.message;
        } else {
          this.reportList = [];
          this.noDataMsg = res.message;
          this.commonService.showErrorMessage(res);
        }
      },
      error => {
        this.loading = false;
        this.customErrorHandler.handleHttpError(error, 'employeePerformance');
      });
  }
  export() {
    var options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: true,
      useBom: true,
      title: this.employeeLabel + ' Performance'
    };
    this.exportReport = 'yes';
    this.reportService.employeePerformance(this.pageNo, this.perPage, this.start_date, this.end_date, this.exportReport, this.locationId, this.reportingTo).subscribe(
      res => {
        new Angular2Csv(res.data, this.employeeLabel + ' performance', options);
      },
      error => {
        this.customErrorHandler.handleHttpError(error, 'employeePerformance');
      });
  }
  getReportingUserList(locationId) {
    let currentUserId = localStorage.getItem('user_id');
    this.commonService.getReportingUserList(locationId).subscribe(res => {
      if (res.result == true) {
        this.reportingUserList = res.data;
        if (this.reportingUserList.length > 1) {
          this.reportingUserList.unshift(this.defaultSelectObject);
        }
      }
    }, err => {
      this.customErrorHandler.handleHttpError(err, 'getRoleList');
    });
  }
  getLocationList() {
    this.commonService.getLocationList().subscribe(res => {
      if (res.result == true) {
        this.locationList = res.data;
        if (this.locationList.length > 1) {
          this.locationList.unshift(this.defaultSelectObject);
        }
      }
    }, err => {
      this.customErrorHandler.handleHttpError(err, 'getRoleList');
    });
  }
  onUserItemSelect(item: any) {
    this.reportingTo = item.id;
    this.getReportList(1);
  }
  onUserItemDeSelect(item: any) {
    this.reportingTo = '';
    this.getReportList(1);
  }
  onLocationItemSelect(item: any) {
    this.locationId = item.id;
    this.reportingTo = '';
    this.reportingUserList = [];
    this.reportingSelectedItem = [];
    // if (this.locationId != '') {
      this.getReportingUserList(this.locationId);
    // }
    this.getReportList(1);
  }
  onLocationItemDeSelect(item: any) {
    this.locationId = '';
    this.reportingTo = '';
    this.reportingUserList = [];
    this.reportingSelectedItem = [];
    this.getReportList(1);
  }
  ngOnDestroy() {
    $('.fullHeightBlock').animate({ scrollTop: 0 }, 400);
  }
}
