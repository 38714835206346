import { Injectable } from '@angular/core';
import { HttpHelper } from '../../helpers/http.helper';
import { Config } from '../../config/config';
@Injectable()
export class DocumentLibraryService {
    constructor(private _httpHelper: HttpHelper) {
    }
    createCategory(request) {
        try {
            return this._httpHelper.post(Config.AuthenticationBaseURL1 + 'add-category', request)
        }
        catch (e) {
        }
    }
    getCategory() {
        try {
            return this._httpHelper.get(Config.AuthenticationBaseURL1 + 'get-categories');
        }
        catch (e) {
        }
    }
    addDocument(request) {
        try {
            return this._httpHelper.post(Config.AuthenticationBaseURL1 + 'add-document', request)
        }
        catch (e) {
        }
    }
    updateDocument(request) {
        try {
            return this._httpHelper.put(Config.AuthenticationBaseURL1 + 'update-document', request);
        }
        catch (e) {
        }
    }
    getDocument(req) {
        try {
            return this._httpHelper.get(Config.AuthenticationBaseURL1 + 'get-document', req);
        }
        catch (e) {
        }
    }
    deleteDocument(request) {
        try {
            return this._httpHelper.delete(Config.AuthenticationBaseURL1 + 'delete-document', request)
        }
        catch (e) {
        }
    }
    getSingleDocument(documentId) {
        try {
            return this._httpHelper.get(Config.AuthenticationBaseURL1 + 'get-single-document' + '?doc_id=' + documentId);
        }
        catch (e) {
        }
    }
}
