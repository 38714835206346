import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CustomErrorHandler } from './../../../errorhandler/custom-errorhandler';
import { CommonService } from '../../../services/common/common.service';
import { DigitalFormService } from './../../../services/digital-form/digital-form.service';
@Component({
  selector: 'edit-digital-form',
  templateUrl: 'edit-digital-form.component.html',
  styleUrls: ['./edit-digital-form.component.css']
})
export class EditDigitalFormComponent implements OnInit {
  errorMessage: string;
  disableButton: boolean;
  signin_btn_txt: string;
  templatedata: any[] = [];
  xyzlist: Array<any> = [];
  title: string;
  formTemplateId: string;
  optionMsg = '';
  constructor(
    private router: Router,
    public digitalFormService: DigitalFormService,
    private customErrorHandler: CustomErrorHandler,
    public commonService: CommonService
  ) { }
  ngOnInit() {
    this.formTemplateById();
  }
  formTemplateById() {
    this.formTemplateId = localStorage.getItem('fomeTemplateEditId');
    this.digitalFormService.formTemplateById(this.formTemplateId).subscribe(
      res => {
        if (res.result == true) {
          this.title = res.title;
          this.templatedata = res.data;
        }
      },
      error => {
        this.customErrorHandler.handleHttpError(error, 'formTemplateById');
      });
  }
  templatedataList() {
    this.digitalFormService.templatedataList().subscribe(
      res => {
        if (res.result != 'Invalid Token') {
          this.templatedata = res.data;
        } else {
        }
      },
      error => {
        this.customErrorHandler.handleHttpError(error, 'templatedataList');
      });
  }
  onClicked(j, i, event) {
    if (event.target.checked) {
      this.templatedata[j].package_data[i].ischecked = true;
    } else {
      this.templatedata[j].package_data[i].ischecked = false;
    }
  }
  onSubmit() {
    this.optionMsg = '';
    if (this.title == '' || this.title == undefined) {
      this.errorMessage = "Name Should not be blank";
      return false;
    }
    if (this.templatedata.length == 0) {
      this.optionMsg = "Please select at least one option";
      return false;
    }
    let form_data = JSON.stringify(this.templatedata);
    this.disableButton = true;
    this.errorMessage = '';
    this.signin_btn_txt = "Creating";
    this.digitalFormService.editFormTemplate(this.formTemplateId, this.title, form_data).subscribe(
      res => {
        if (res.result == true) {
          this.router.navigate(["setting/digital-form"]);
          this.commonService.showSuccessMessage(res.message);
        } else {
          this.disableButton = false;
          this.signin_btn_txt = "Submit";
          this.commonService.showErrorMessage(res);
        }
      }, error => {
        this.customErrorHandler.handleHttpError(error, 'editFormTemplate');
        this.disableButton = false;
        this.signin_btn_txt = "Submit";
      });
  }
  temp = Array;
  math = Math;
}
