import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { EmployeeService } from '../../../services/employee/employee.service';
import { Constants } from '../../../common/constants';
import { Angular2Csv } from 'angular2-csv/Angular2-csv';
import { CustomErrorHandler } from './../../../errorhandler/custom-errorhandler';
import { Config } from '../../../config/config';
import swal from 'sweetalert2';
import { CommonService } from '../../../services/common/common.service';
import { Role_managementService } from '../../../services/role_management/role_management.service';

@Component({
  selector: 'employee-management',
  templateUrl: 'employee-management.component.html',
  styleUrls: ['./employee-management.component.css']
})
export class EmployeeManagementComponent implements OnInit {
  userId: string;
  isExport: boolean;
  isDemoTenant: boolean;
  actionLable: string;
  employeeLabel: string;
  company_id: string;
  loading: boolean;
  imageBaseUrl: string = Config.AuthenticationURL;
  noDataMsg: string;
  tempSearch: any;
  message: any;
  pageNo: number = 1;
  perPage: number = 10;
  totalItem: number = 0;
  page: number = 2;
  exportReport: any;
  employeeList: any = [];
  serchText: any;
  employeeMemberList = [];
  employeeMemberSettings = {};
  employeeMemberItem = [];
  locationList: any = [];
  locationId: any = ''
  locationSettings: any = {};
  locationSelectedItem: any = [];

  roleList: any = [];
  roleId: any = ''
  roleSettings: any = {};
  roleSelectedItem: any = [];

  defaultSelectObject: any = { id: '', itemName: 'All' }
  filterLabel = Constants.filterLabel;

  constructor(
    private router: Router,
    public _employeeService: EmployeeService,
    private customErrorHandler: CustomErrorHandler,
    public commonService: CommonService,
    public roleManagementService: Role_managementService
  ) { }
  ngOnInit() {
    this.userId = localStorage.getItem('user_id');
    this.serchText = '';
    this.tempSearch = '';
    this.exportReport = '';
    this.isExport = false;
    this.isDemoTenant = (localStorage.getItem('isDemoTenant') == 'true');
    this.employeeLabel = localStorage.getItem('employeeLabel');
    this.actionLable = localStorage.getItem('actionLabel');
    this.loading = true;
    this.getEmployeeList(1);
    this.getLocationList();
    this.getReportingUserList(this.locationId);
    this.employeeMemberSettings = {
      singleSelection: true,
      text: "Select Reporting Person",
      enableSearchFilter: true,
      classes: "singleSelection block_elem m_b_15"
    };
    this.locationSettings = {
      singleSelection: true,
      text: "Select "+ Constants.locationLabel,
      enableSearchFilter: true,
      classes: "singleSelection block_elem m_b_15"
    };

    this.roleSettings = {
      singleSelection: true,
      text: "Select Role",
      enableSearchFilter: true,
      classes: "singleSelection block_elem m_b_15"
    };
    this.getRoleList();
  }
  employeeEdit(id) {
    localStorage.setItem('employee_id', id);
    this.router.navigate(["/setting/employee-management/employee"]);
  }
  getEmployeeList(currentPage) {
    this.pageNo = currentPage;
    this.page = currentPage;
    this.message = '';
    this.employeeList = [];
    this.exportReport = '';
    let reportingUserId = '';
    let locationId = '';
    if (this.employeeMemberItem.length > 0) {
      reportingUserId = this.employeeMemberItem[0].id;
    }
    if (this.locationSelectedItem.length > 0) {
      locationId = this.locationSelectedItem[0].id;
    }
    this._employeeService.employeeList(this.pageNo, this.perPage, this.exportReport, this.serchText, reportingUserId, locationId, this.roleId).subscribe(
      res => {
        this.loading = false;
        if (res.result == true) {
          this.totalItem = res.data.total;
          this.employeeList = res.data.data;
          this.message = res.message;
        } else {
          this.totalItem = 0;
          this.employeeList = [];
          this.message = res.message;
          this.commonService.showErrorMessage(res);
        }
      },
      error => {
        this.loading = false;
        this.customErrorHandler.handleHttpError(error, 'employeeList');
      });
  }
  onSearch(event) {
    this.serchText = event.target.value;
    if (this.tempSearch != this.serchText) {
      this.getEmployeeList(1);
    }
    this.tempSearch = this.serchText;
  }
  employeeDelete(value, pending) {
     console.log(pending,'pp')
    let deleteMsg = Constants.DeleteMsg;
    if(pending == true){
      deleteMsg = 'All the assigned tasks to this user will be pending now. Are you sure you want delete this?';
    }
    swal({
      text: deleteMsg,
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Delete',
    }).then((result) => {
      if (typeof result.value != 'undefined') {
        this._employeeService.employeeDelete(value).subscribe(
          res => {
            if (res.result == true) {
              this.commonService.showSuccessMessage(res.message);
              this.getEmployeeList(1);
            }
            else {
              this.commonService.showErrorMessage(res);
            }
          },
          error => {
            this.customErrorHandler.handleHttpError(error, 'employeeDelete');
          });
      }
    })
  }
  createEmployee() {
    localStorage.setItem('createEmployee', 'Yes');
    this.router.navigate(['/setting/employee-management/employee']);
  }
  export() {
    var options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: true,
      useBom: true,
      title: this.employeeLabel + ' List'
    };
    this.exportReport = 'yes';
    let reportingUserId = '';
    let locationId = '';
    if (this.employeeMemberItem.length > 0) {
      reportingUserId = this.employeeMemberItem[0].id;
    }
    if (this.locationSelectedItem.length > 0) {
      locationId = this.locationSelectedItem[0].id;
    }
    this._employeeService.employeeList(this.pageNo, this.perPage, this.exportReport, this.serchText, reportingUserId, locationId, this.roleId).subscribe(
      res => {
        new Angular2Csv(res.data, this.employeeLabel + ' List', options);
      },
      error => {
        this.customErrorHandler.handleHttpError(error, 'employeeList');
      });
  }
  getReportingUserList(locationId) {
    let currentUserId = localStorage.getItem('user_id');
    this.commonService.getReportingUserList(locationId).subscribe(res => {
      if (res.result == true) {
        this.employeeMemberList = res.data;
        if (this.employeeMemberList.length > 1) {
          this.employeeMemberList.unshift(this.defaultSelectObject);
        }
      }
    }, error => {
      this.customErrorHandler.handleHttpError(error, 'getRoleList');
    });
  }
  getLocationList() {
    this.commonService.getLocationList().subscribe(res => {
      if (res.result == true) {
        this.locationList = res.data;
        if (this.locationList.length > 1) {
          this.locationList.unshift(this.defaultSelectObject);
        }
      }
    }, error => {
      this.customErrorHandler.handleHttpError(error, 'getRoleList');
    });
  }
  onItemSelect(item: any) {
    this.locationId = item.id;
    this.employeeMemberItem = [];
    this.employeeMemberList = [];
    // if (item.id != '') {
      this.getReportingUserList(this.locationId);
    // }
    this.getEmployeeList(1);
  }
  OnItemDeSelect(item: any) {
    this.locationId = ''
    this.employeeMemberList = [];
    this.employeeMemberItem = [];
    this.getEmployeeList(1);
  }
  onReportingSelected(item: any) {
    this.getEmployeeList(1);
  }
  onReportingDeselected(item: any) {
    this.getEmployeeList(1);
  }

  getRoleList() {
    this.roleManagementService.roleTypeList().subscribe(res => {
      if (res.result == true) {
        this.roleList = res.data;
        this.roleList.unshift({id: 0, itemName : 'Employee'});
        if (this.roleList.length > 1) {
          this.roleList.unshift(this.defaultSelectObject);
        }
      }
    }, error => {
      this.customErrorHandler.handleHttpError(error, 'getRoleList');
    });
  }
  
  onRoleItemSelect(item: any){
    this.roleId = item.id;
    this.getEmployeeList(1);
  }

  OnRoleItemDeSelect(item: any){
    this.roleId = '';
    this.getEmployeeList(1);
  }
}

