import { Component, OnInit } from '@angular/core';
import { Config } from './../../../config/config';
import { Constants } from './../../../common/constants';
declare var google: any;
@Component({
    selector: 'map',
    templateUrl: 'map.component.html'
})
export class MapComponent implements OnInit {
    imageBaseUrl: string = Config.AuthenticationURL;
    employeeLabel: string;
    map: any;
    nearybyData: any;
    constructor() {
    }
    ngOnInit() {
        this.employeeLabel = localStorage.getItem('employeeLabel');
        this.map = new google.maps.Map(document.getElementById('map'), {
            center: { lat: -34.397, lng: 150.644 },
            zoom: 15,
            styles: Constants.MapStyle
        });
        this.nearybyData = JSON.parse(localStorage.getItem('nearybyData'));
        var map1 = this.map;
        var marker, i
        for (i = 0; i < this.nearybyData.length; i++) {
            var name = this.nearybyData[i].first_name;
            var lat = this.nearybyData[i].lat;
            var long = this.nearybyData[i].lng;
            var profile_pic = this.nearybyData[i].profile_pic;
            if (profile_pic == "") {
                profile_pic = "assets/images/defualt-user-image-new.png";
            } else {
                profile_pic = this.imageBaseUrl + profile_pic;
            }
            var icon = {
                url: profile_pic, // url
                scaledSize: new google.maps.Size(50, 50), // scaled size
                origin: new google.maps.Point(0, 0), // origin
                anchor: new google.maps.Point(0, 0) // anchor
            };
            var latlngset = new google.maps.LatLng(lat, long);
            var marker = new google.maps.Marker({
                map: map1, title: name, position: latlngset, icon: icon
            });
            map1.setCenter(marker.getPosition());
            var content = this.employeeLabel + ": " + name + '</h3>' + " <img class='img_width'  src=" + this.imageBaseUrl + profile_pic + ">";
            var infowindow = new google.maps.InfoWindow();
            google.maps.event.addListener(marker, 'click', (function (marker, content, infowindow) {
                return function () {
                    infowindow.setContent(content);
                    infowindow.open(map1, marker);
                };
            })(marker, content, infowindow));
        }
    }
}
