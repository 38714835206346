import { Injectable } from '@angular/core';
import { HttpHelper } from '../../helpers/http.helper';
import { Config } from '../../config/config';
const company_id = localStorage.getItem('company_id');
@Injectable()
export class FollowUpsService {
  constructor(private _httpHelper: HttpHelper) {
  }
  getFollowUpsList(req) {
    try {
      // var request = {
      //   "prospect_id": prospectId || '',
      //   "company_id": company_id
      // };
      return this._httpHelper.post(Config.AuthenticationBaseURL1 + 'followuplistweb', req)
    }
    catch (e) {
    }
  }
  prospectList() {
    try {
      var request = {
        "company_id": company_id
      };
      return this._httpHelper.post(Config.AuthenticationBaseURL1 + 'prospect-drop-list', request)
    }
    catch (e) {
    }
  }
}