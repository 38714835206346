import { Component, OnInit } from '@angular/core';
declare var moment: any;
declare var $: any;
@Component({
    selector: 'route',
    templateUrl: 'route.component.html',
    styleUrls: ['./route.component.css'],
    host: {
        'class': 'fullHeightBlock'
    }
})
export class RouteComponent implements OnInit {
    headpart: any;
    ngOnInit() {
        this.headpart = 'show';
        var start = moment().subtract(0, 'days');
        var end = moment();
        var _this;
        _this = this;
        function cb(start, end) {
            $('#reportrange span').html(start.format('MMM D, YYYY') + ' - ' + end.format('MMM D, YYYY'));
            var f_start = start.format('YYYY-MM-DD');
            var f_end = end.format('YYYY-MM-DD');
            localStorage.setItem('home_date', f_start);
            _this.homeSharedService.publishHomestartDate(f_start);
            _this.homeSharedService.publishHomeendDate(f_end);
        }
        $('#reportrange').daterangepicker({
            startDate: start,
            endDate: end,
            ranges: {
                'Today': [moment(), moment(),],
                'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                'This Month': [moment().startOf('month'), moment().endOf('month'), 'inclusive'],
                'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
            }
        }, cb);
        if ($(window).width() > 760) {
            setTimeout(() => {
                $('.fullHeightBlock').scrollbar();
            }, 500)
        }
        cb(start, end);
    }
    ngOnDestroy() {
        $('.fullHeightBlock').remove();
    }
    showhidemode(status) {
        this.headpart = status;
    }
}
