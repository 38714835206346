import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
@Injectable()
export class AuthGuard implements CanActivate {
    constructor(private router: Router) {
    }
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (localStorage.getItem('isLogin') == '1') {
            return true;
        }
        // not logged in so redirect to login page with the return url
        localStorage.clear();
        let urlCheck = this.router.url.substr(1);
        if(!urlCheck.startsWith('login') && urlCheck != ''){
          window.location.reload();
        }
        else{
            this.router.navigate(['login'], { queryParams: { returnUrl: state.url } });
        }
        return false;
    }
}
