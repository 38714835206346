import { AssignsDigitalFormComponent } from './assigns-digital-form.component';
import { AuthGuard } from './../../../services/_guards/auth.guard';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown/angular2-multiselect-dropdown';
import { Ng2PaginationModule } from 'ng2-pagination';
export const routes: Routes = [
  {
    path: 'assigns-template',
    component: AssignsDigitalFormComponent, canActivate: [AuthGuard]
  }
];
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule.forChild(routes),
    AngularMultiSelectModule,
    Ng2PaginationModule
  ],
  declarations: [
    AssignsDigitalFormComponent
  ]
})
export class AssignsDigitalFormModule { }
