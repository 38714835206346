import { DigitalFormService } from './services/digital-form/digital-form.service';
import { CustomErrorHandler } from './errorhandler/custom-errorhandler';
import { DashboardService } from './services/dashboard/dashboard.service';
import { AuthGuard } from './services/_guards/auth.guard';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler, APP_INITIALIZER } from '@angular/core';
import { HttpModule } from '@angular/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Router, RouterModule } from '@angular/router';
import { routing } from './app.routing';
import { AppComponent } from './app.component';
import { Modules } from './modules/module';
import { HeaderComponent } from './modules/header/header.component';
import { SidebarComponent } from './modules/sidebar/sidebar.component';
import { LoginService } from './services/login/login.service';
import { TeamlistService } from './services/team/teamList.service';
import { EventlistService } from './services/event/eventList.service';
import { EmployeeService } from './services/employee/employee.service';
import { ComplaintService } from './services/complaint/complaint.service';

import { Role_managementService } from './services/role_management/role_management.service';
import { AssignService } from './services/assign/assign.service';
import { TrackingService } from './services/tracking/tracking.service';
import { NearbyService } from './services/nearby/nearby.service';
import { ReportService } from './services/report/report.service';
import { headerService } from './services/header/header.service';
import { RoleService } from './services/role/role.service';
import { RouteService } from './services/route/route.service';
import { CommonService } from './services/common/common.service';
import { CheckListService } from './services/check_list/check_list.service';
import { ExpenseService } from './services/expense/expense.service';
import { HttpHelper } from './helpers/http.helper';
import { FileUploadModule } from 'ng2-file-upload';
import { TooltipModule } from "ng2-tooltip";
import { HomeSharedService } from './services/shared/home-shared';
import { SideBarService } from './services/sidebar/sidebar.service';
import { MessageService } from './services/message/message.service';
import { NotesService } from './services/notes/notes.service';
import { FollowUpsService } from './services/followUps/followUps.service';
import { FormsService } from './services/forms/forms.service';
import { HttpClientModule } from '@angular/common/http';
import { CompanySettingsService } from './services/company-settings/company-settings.service';
import { DocumentLibraryService } from './services/document-library/document-library.service';
import { EmployeeLeaderBoardService } from './services/employee-leader-board/employee-leader-board.service';
import { VoterService } from './services/voter/voter.service';
import { PollSurveyService } from './services/polls-surveys/poll-survey';
import { ConfigService } from './services/config/config.service';
import { LocationService } from './services/location/location.service';
import { HierarchyService } from './services/hierarchy/hierarchy.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
import { Config } from './config/config';
import { AnnouncementService } from './services/announcements/announcements.service';
import { PipeModule } from './pipe/pipe.module';
import { MeetingService } from './services/meeting/meeting.service';
import { EmployeeListService } from './services/employeelist/employeeList.service';

import { InfiniteScrollModule } from 'ngx-infinite-scroll';

const config: SocketIoConfig = { url: Config.SocketUrl, options: {} };
export function initFactory(config: ConfigService) {
    return () => config.load();
}
@NgModule({
    declarations: [
        AppComponent,
        SidebarComponent,
        HeaderComponent
    ],
    imports: [
        BrowserModule,
        HttpModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        Modules,
        routing,
        TooltipModule,
        FileUploadModule,
        HttpClientModule,
        BrowserAnimationsModule,
        SocketIoModule.forRoot(config),
        PipeModule.forRoot(),
        InfiniteScrollModule
    ],
    providers: [
        AuthGuard,
        LoginService,
        HttpHelper,
        MeetingService,
        TeamlistService,
        EventlistService,
        EmployeeService,
        ComplaintService,
        EmployeeListService,
        DigitalFormService,
        Role_managementService,
        AssignService,
        HomeSharedService,
        TrackingService,
        NearbyService,
        CompanySettingsService,
        ReportService,
        headerService,
        RoleService,
        SideBarService,
        NotesService,
        MessageService,
        DashboardService,
        FollowUpsService,
        FormsService,
        RouteService,
        CommonService,
        CheckListService,
        CustomErrorHandler,
        EmployeeLeaderBoardService,
        ExpenseService,
        HierarchyService,
        {
            provide: ErrorHandler,
            useClass: CustomErrorHandler
        },
        DocumentLibraryService,
        VoterService,
        PollSurveyService,
        ConfigService,
        {
            provide: APP_INITIALIZER,
            useFactory: initFactory,
            deps: [ConfigService],
            multi: true
        },
        LocationService,
        AnnouncementService
    ],
    bootstrap: [AppComponent],
    entryComponents: []
})
export class AppModule {
    constructor(private router: Router) {
    }
}
