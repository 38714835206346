import { MeetingService } from './../../../services/meeting/meeting.service';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CustomErrorHandler } from '../../../errorhandler/custom-errorhandler';
import { CommonService } from '../../../services/common/common.service';
import { PollSurveyService } from '../../../services/polls-surveys/poll-survey';
@Component({
  selector: 'app-create-survey',
  templateUrl: './create-survey.component.html',
  styleUrls: ['./create-survey.component.css']
})
export class CreateSurveyComponent implements OnInit {
  pageLabel: string;
  surveyData: any = {};
  employeeLabel = localStorage.getItem('employeeLabel');
  companyId = localStorage.getItem('company_id');
  userId = localStorage.getItem('user_id');
  errorMessage: any;
  isEditMode: boolean;
  selectedSurveyId: any;
  isMinQuestion: any;
  isMinQption: any;
  loading: boolean;
  constructor(
    private router: Router,
    public activatedRoute: ActivatedRoute,
    public commonService: CommonService,
    private customErrorHandler: CustomErrorHandler,
    private pollSurveyService: PollSurveyService,
    public sharedService: MeetingService,
  ) { }
  ngOnInit() {
    this.pageLabel = 'Create'
    this.surveyData.questionnaire = [{ 'question': '' }];
    this.surveyData.option = [{ 'option': '' }];
    this.errorMessage = '';
    this.selectedSurveyId = this.activatedRoute.snapshot.queryParamMap.get('id');
    if (this.selectedSurveyId) {
      this.isEditMode = true;
      this.pageLabel = 'Edit'
      this.getSurveyDetail(this.selectedSurveyId);
    } else {
      this.isEditMode = false;
    }
  }
  addMore(type) {
    if (type == 'questionnaire') {
      this.isMinQuestion = false;
      if (this.surveyData.questionnaire.length <= 24) {
        let questionObj = { 'question': '' }
        this.surveyData.questionnaire.push(questionObj);
      }
    }
    if (type == 'options') {
      this.isMinQption = false;
      if (this.surveyData.option.length <= 2) {
        let optionObj = { 'option': '' }
        this.surveyData.option.push(optionObj);
      }
    }
  }
  remove(index, type) {
    if (type == 'questionnaire') {
      this.surveyData.questionnaire.splice(index, 1);
    }
    if (type == 'options') {
      this.surveyData.option.splice(index, 1);
    }
  }
  createSurvey(data) {
    this.loading = true;
    this.isMinQuestion = false;
    this.isMinQption = false;
    data.added_by = this.userId;
    if (this.isEditMode) {
      data.survey_id = this.selectedSurveyId;
      this.updateSurvey(data);
    } else {
      if (this.surveyData.questionnaire.length < 2) {
        this.loading = false;
        this.isMinQuestion = true;
        return false;
      } else if (this.surveyData.option.length < 2) {
        this.loading = false;
        this.isMinQption = true;
        return false;
      }
      this.pollSurveyService.createSurvey(data).subscribe(res => {
        if (res.result == true) {
          this.commonService.showSuccessMessage(res.message);
          this.router.navigate(["survey/survey-list"]);
          this.loading = false;
        } else {
          this.loading = false;
          this.commonService.showErrorMessage(res);
        }
      }, error => {
        this.customErrorHandler.handleHttpError(error, 'createSurvey');
        this.loading = false;
      });
    }
  }
  getSurveyDetail(id) {
    this.pollSurveyService.getSingleSurvey(id).subscribe(res => {
      this.surveyData = res.data;
      this.surveyData.option.forEach((element, idx) => {
        if (this.surveyData.option[idx].option && this.surveyData.option[idx].option === "None of these") {
          this.surveyData.option.splice(idx, 1);
        }
      });
    }, error => {
      this.customErrorHandler.handleHttpError(error, 'getSurveyDetail');
    });
  }
  updateSurvey(data) {
    this.pollSurveyService.updateSurvey(data).subscribe(res => {
      if (res.result == true) {
        this.loading = false;
        this.commonService.showSuccessMessage(res.message);
        this.router.navigate(["survey/survey-list"]);
      } else {
        this.loading = false;
        this.commonService.showErrorMessage(res);
      }
    }, error => {
      this.loading = false;
      this.customErrorHandler.handleHttpError(error, 'updateSurvey');
    });
  }
}
