import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from '../../../services/common/common.service';
import { CustomErrorHandler } from '../../../errorhandler/custom-errorhandler';
import { Angular2Csv } from 'angular2-csv/Angular2-csv';
import { PollSurveyService } from '../../../services/polls-surveys/poll-survey';
import swal from 'sweetalert2';
import { Constants } from '../../../common/constants';
import { BaseChartDirective } from 'ng2-charts';
declare var $: any;
@Component({
  selector: 'app-poll-list',
  templateUrl: './poll-list.component.html',
  styleUrls: ['./poll-list.component.css']
})
export class PollListComponent implements OnInit {
  message: string;
  pollList: any = [];
  loading: boolean;
  perPage: number = 10;
  pageNo: number = 1;
  page: number = 2;
  searchTextValue: any = '';
  isDemoTenant: boolean;
  totalItem: number = 0;
  serverUrl: any;
  tempSearch: any;
  maxPage: number = 10;
  modelData: any;
  @ViewChild('graphModel') graphModel: ElementRef;
  @ViewChild('baseChart') chart: BaseChartDirective;
  barChartOptions: any = {
    scaleShowVerticalLines: true,
    responsive: true,
    animationEasing: "easeOutBounce",
    pointLabelFontSize: 12,
    lineTension: 0,
    legend: {
      onClick: (e) => e.stopPropagation(),
      display: false
    },
    scales: {
      yAxes: [{ ticks: { min: 0 } }],
      xAxes: [{ ticks: { min: 0 } }]
    }
  };
  barChartLabels: any[] = ['default'];
  barChartType: string = 'horizontalBar';
  barChartLegend: boolean = true;
  barChartData: any = [{ data: [0] }];
  chartColors = [{
    backgroundColor: '#86c7f3',
    borderColor: '#264E94',
    pointBackgroundColor: '#003082',
    pointBorderColor: '#fff',
    pointHoverBackgroundColor: '#fff',
    pointHoverBorderColor: 'rgba(148,159,177,0.8)'
  }];
  constructor(
    private router: Router,
    public commonService: CommonService,
    private customErrorHandler: CustomErrorHandler,
    private pollSurveyService: PollSurveyService
  ) { }
  ngOnInit() {
    this.loading = true;
    this.tempSearch = '';
    this.isDemoTenant = (localStorage.getItem('isDemoTenant') == 'true');
    this.loading = true;
    this.getPollList(1);
    if ($(window).width() < 768) {
      this.maxPage = 6;
    }
  }
  getPollList(currentPage) {
    this.page = currentPage;
    let queryStrings: any = {
      pageNo: currentPage,
      perPage: this.perPage,
      search: this.searchTextValue,
      export: ''
    }
    this.pollSurveyService.getPollList(queryStrings).subscribe(res => {
      this.message = res.message;
      if (res.result == true) {
        this.totalItem = res.data.total;
        this.pollList = res.data.data;
      } else { }
      this.loading = false;
    }, err => {
      this.customErrorHandler.handleHttpError(err, 'getPoll');
      this.loading = false;
    });
  }
  onSearch(event) {
    this.searchTextValue = event.target.value;
    if (this.tempSearch != this.searchTextValue) {
      this.getPollList(1);
    }
    this.tempSearch = this.searchTextValue;
  }
  exportPollList() {
    var options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: true,
      useBom: true,
      title: "poll-List"
    };
    let qeuryStrings: any = {
      pageNo: this.pageNo,
      perPage: this.perPage,
      search: this.searchTextValue,
      export: 'yes',
    }
    this.pollSurveyService.getPollList(qeuryStrings).subscribe(
      res => {
        new Angular2Csv(res.data, 'pollList', options);
      },
      error => {
        this.customErrorHandler.handleHttpError(error, 'pollList');
      });
  }
  editPoll(value) {
    if (value.is_edit) {
      this.router.navigate(['poll/create-poll'], { queryParams: { id: value.id } });
    }
  }

  deletePoll(value) {
    if (value.is_edit) {
      swal({
        text: Constants.DeleteMsg,
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        cancelButtonColor: '#3085d6',
        confirmButtonText: 'Delete',
      }).then((result) => {
        if (typeof result.value != 'undefined') {
          let data = { poll_id: value.id };
          this.pollSurveyService.deletePoll(data).subscribe(
            res => {
              if (res.result == true) {
                this.commonService.showSuccessMessage(res.message);
                this.getPollList(this.pageNo);
              } else {
                this.commonService.showErrorMessage(res);
              }
            },
            error => {
              this.customErrorHandler.handleHttpError(error, 'employeeReport');
            });
        }
      });
    }    
  }
  openModal(data) {
    this.modelData = data;
    this.graphModel.nativeElement.style.display = 'block';
    this.getPollResult(data.id);
  }
  closeModal() {
    this.graphModel.nativeElement.style.display = 'none';
  }
  barChartDataFunction(pollData) {
    let chartData: number[] = [];
    this.chart.chart.config.data.labels = [];
    pollData.option_list.forEach(element => {
      chartData.push(element.vote_count);
      this.chart.chart.config.data.labels.push(element.option_name);
    });
    this.barChartData = [{ data: chartData }];
  }
  getPollResult(poll_id) {
    this.pollSurveyService.getPollResults(poll_id).subscribe(res => {
      if (res.result == true) {
        let data = res.data[0];
        this.barChartDataFunction(data);
      }
    }, err => {
      this.customErrorHandler.handleHttpError(err, 'getPollResult');
    });
  }
}
