import { CustomErrorHandler } from './../../errorhandler/custom-errorhandler';
import { Component, OnInit, ViewChild, ElementRef, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { headerService } from '../../services/header/header.service';
declare var $: any;
import { LoginService } from '../../services/login/login.service';
import { Config } from '../../config/config';
import { Socket } from 'ngx-socket-io';
declare var moment: any;
@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
    isDemoTenant: boolean;
    imageBaseUrl: string = Config.AuthenticationURL;
    showEditProfile: string;
    logoUrl: any;
    tenantName: string;
    notification = [];
    alertlist = [];
    loading: boolean;
    interVal: any;
    MBmenu: boolean = false;
    emptyAlert: string;
    emptyNotification: string;

  
    constructor(
        private router: Router,
        public _headerService: headerService,
        private loginService: LoginService,
        private customErrorHandler: CustomErrorHandler,
        private socket: Socket
    ) { }
    count: any;
    alert_count: any;
    message_count: any;
    noti_data: boolean;
    alert_data: boolean;
    message_data: boolean;
    $: any;
    username: string;
    profile_pic: string;
    loginUserId: any = '';
    baseUrlScout: any = '';
    defaultTenantUrl : any = '';
    company_id = localStorage.getItem('company_id');
    row_per_page = 10;
    page_no = 1;
    pageScroll = 100

    ngOnInit() {
        this.baseUrlScout = Config.BasecampScoutWebURL;
        this.defaultTenantUrl = this.baseUrlScout +'assets/images/default_tenant.png';
        this.loginUserId = localStorage.getItem('user_id');
        this.socket.on('users connected', (data) => {
            console.log('connnect notification')
            this.socket.emit('set_user_company_id', { company_id: this.company_id });
        });
        this.isDemoTenant = (localStorage.getItem('isDemoTenant') == 'true');
        this.showEditProfile = localStorage.getItem('ShowSettings');
        this.logoUrl = localStorage.getItem('logo_url');
        this.tenantName = localStorage.getItem('TenantName');
        if (localStorage.getItem('isLogin') == undefined) {
            this.router.navigate(["login"]);
            window.location.reload();
        }
        this.noti_data = false;
        this.count = 0;
        this.alert_count = 0;
        this.message_count = 0;
        this.username = localStorage.getItem('username');
        this.profile_pic = localStorage.getItem('profile_pic');
        this.moveMarker();
        $('.search').on('click', function () {
            $('body').removeClass('current');
            $(this).addClass('current');
        });
        $("#search_btn").click(function () {
            $("#serach_input").toggle("slide", { direction: "right" }, 100);
        });
        var submitIcon = $('.searchbox-icon');
        var inputBox = $('.searchbox-input');
        var searchBox = $('.searchbox');
        var isOpen = false;
        submitIcon.click(function () {
            if (isOpen == false) {
                searchBox.addClass('searchbox-open');
                inputBox.focus();
                isOpen = true;
            } else {
                searchBox.removeClass('searchbox-open');
                inputBox.focusout();
                isOpen = false;
            }
        });
        submitIcon.mouseup(function () {
            return false;
        });
        searchBox.mouseup(function () {
            return false;
        });
        $(document).mouseup(function () {
            if (isOpen == true) {
                $('.searchbox-icon').css('display', 'block');
                submitIcon.click();
            }
        });
        function buttonUp() {
            var inputVal = $('.searchbox-input').val();
            inputVal = $.trim(inputVal).length;
            if (inputVal !== 0) {
                $('.searchbox-icon').css('display', 'none');
            } else {
                $('.searchbox-input').val('');
                $('.searchbox-icon').css('display', 'block');
            }
        }
        if (this.isDemoTenant) {
            $('body').addClass('isDemoSite');
        }
        else {
            $('body').removeClass('isDemoSite');
        }
        this.getNotificationData();
    }
    getNotificationData() {
        var this1 = this;
        this.socket.on('get_notification', function (data) {
            console.log(data)
            data.date = moment(data.date).fromNow();
            this1.notification.unshift(data);
            this1.count = Number(this1.count) + 1;
        });
    }
    toggleMenu(e: Element) {
        let targetE = e.getAttribute('data-target');
        document.getElementById(targetE).classList.toggle('activeMenu');
        this.MBmenu == false ? this.MBmenu = true : this.MBmenu = false;
    }
    doLogout() {
        this.loginService.logOut().subscribe(
            res => {
                if (res.result == true) {
                    if (localStorage.getItem('isDemoTenant') == 'true') {
                        localStorage.clear();
                        window.location.href = 'https://www.basecampscout.com/';
                    } else {
                        localStorage.clear();
                        window.location.reload();
                    }
                }
            },
            error => {
                this.customErrorHandler.handleHttpError(error, 'logOut');
            }
        );
    }
    notificationList() {
        let postData = {
            row_per_page: this.row_per_page,
            page_no: this.page_no,
            company_id: this.company_id,
            alert_type: 0
        }
        this._headerService.notificationList(postData).subscribe(
            res => {
                this.emptyNotification = res.message || 'Currently, no data is available';
                if (res.result == true) {
                    let notiData = res.data;
                    if (this.page_no > 1) {
                        notiData.forEach(element => {
                            this.notification.push(element);
                        });    
                    } else {
                        this.notification = notiData;
                    }
              
                    
                    if (this.notification.length > 0) {
                        this.noti_data = false;
                    }
                    else {
                        this.noti_data = true;
                    }
                    this.loading = false;
                } else {
                    this.loading = false;
                    this.noti_data = true;
                }
            },
            error => {
                this.loading = false;
                this.customErrorHandler.handleHttpError(error, 'notificationList');
            });
    }
    
    
    onScrollDown(event, type) {
        this.page_no ++;
        if (type == 'notification') {
            this.notificationList();
        } else {
            this.alertList();
        }
        
    }
    setPageNo(type) {
        this.page_no = 1;
        if (type == 'notification') {
            this.notificationList();
        } else {
            this.alertList();
        }
    }

    moveMarker() {
        this.notificationCount();
        this.notificationList();
        this.alertCount();
        this.alertList();
        this.messageCount();
        this.interVal = setInterval(() => {
            this.alertCount();
            this.alertList();
        }, 60000);
    }
    notificationCount() {
        this._headerService.notificationCount(0).subscribe(
            res => {
                if (res.result == true) {
                    this.count = res.data.count;
                }
            },
            error => {
                this.customErrorHandler.handleHttpError(error, 'notificationCount');
            });
    }
    notificationUpdate() {
        this._headerService.notificationUpdate(0).subscribe(
            res => {
                if (res.result == true) {
                    this.notificationCount()
                }
            },
            error => {
                this.customErrorHandler.handleHttpError(error, 'notificationUpdate');
            });
    }
    //// alert related functions
    alertList() {
        let postData = {
            row_per_page: this.row_per_page,
            page_no: this.page_no,
            company_id: this.company_id,
            alert_type: 1
        }
        this._headerService.notificationList(postData).subscribe(
            res => {
                this.emptyAlert = res.message || 'Currently, no data is available';
                if (res.result == true) {
                    if (res.data.length > 0) {
                        let notiData = res.data;
                        if (this.page_no > 1) {
                            notiData.forEach(element => {
                                this.alertlist.push(element);
                            });   
                        } else {
                            this.alertlist = notiData;
                        }
                        this.alert_data = false;
                    }
                    else {
                        this.alert_data = true;
                    }
                    this.loading = false;
                } else {
                    this.loading = false;
                    this.alert_data = true;
                }
            },
            error => {
                this.loading = false;
                this.customErrorHandler.handleHttpError(error, 'notificationList');
            }
        );
    }
    alertCount() {
        this._headerService.notificationCount(1).subscribe(
            res => {
                if (res.result == true) {
                    this.alert_count = res.data.count;
                }
            },
            error => {
                this.customErrorHandler.handleHttpError(error, 'notificationCount');
            }
        )
    }
    alertUpdate() {
        this._headerService.notificationUpdate(1).subscribe(
            res => {
                if (res.result == true) {
                    this.alertCount()
                }
            },
            error => {
                this.customErrorHandler.handleHttpError(error, 'notificationUpdate');
            });
    }  
    editProfile() {
        console.log(this.router.url)
        localStorage.setItem('editLoggedInUser', 'Yes');
        if (this.router.url == '/setting/edit-profile' || this.router.url == '/setting/employee-management/employee') {
            window.location.reload();
        } else {
            this.router.navigate(['setting/edit-profile']);
        }
    }
    messageCount() {
        var this1 = this;
        this._headerService.unreadMessageCount().subscribe(
            res => {
                if (res.result == true) {
                    this.message_count = res.data.count;
                } else {
                    this.message_count = 0;
                }
            },
            error => {
                this.loading = false;
                this.customErrorHandler.handleHttpError(error, 'messageCount');
            });
        this.socket.on('new message', function (data) {
            let messageCount = $('#unread-message-count').text();
            if (messageCount == '') {
                messageCount = 0;
            }
            setTimeout(function(){
                console.log(data,'message data')
                if(data.messageType == undefined){
                    messageCount = Number(messageCount) + 1;
                    $('#unread-message-count').text(messageCount);
                    this1.message_count = Number(this1.message_count) + 1;
                }else{
                    if(data.selected_user_id == this1.loginUserId){
                        if(data.login_user != this1.loginUserId){
                            if(data.login_user != data.sender_id){
                                messageCount = Number(messageCount) + 1;
                                $('#unread-message-count').text(messageCount);

                                this1.message_count = Number(this1.message_count) + 1;
                            }
                        }
                    }
                }
            },200);
       });
    }
}
