import { Injectable } from '@angular/core';
import { HttpHelper } from '../../helpers/http.helper';
import { Config } from '../../config/config';
const company_id = localStorage.getItem('company_id');
const user_id = localStorage.getItem('user_id');
@Injectable()
export class ReportService {
  constructor(private _httpHelper: HttpHelper) {
  }
  employeeReport(pageNo, rowPerPage, emp_id: string, report_type: string, start_date: string, end_date: string, exportReport: string, locationId, reportingTo) {
    try {
      var request = {
        "per_page": rowPerPage,
        "page": pageNo,
        "employee_id": emp_id,
        "company_id": company_id,
        "created_by": user_id,
        "report_type": report_type,
        "start_date": start_date,
        "end_date": end_date,
        "export": exportReport,
        "location_id": locationId,
        "reporting_to": reportingTo
      };
      return this._httpHelper.post(Config.AuthenticationBaseURL1 + 'get-employee-report', request)
    }
    catch (e) {
    }
  }
  formCategoryReport(pageNo, rowPerPage, search: string, startDate, endDate, exportReport, locationId, reportingTo) {
    try {
      var request = 'page=' + pageNo + '&per_page=' + rowPerPage + '&start_date=' + startDate + '&end_date=' + endDate + '&search=' + search + '&export=' + exportReport + '&location_id=' + locationId + '&reporting_to=' + reportingTo;
      return this._httpHelper.get(Config.AuthenticationBaseURL1 + 'get-form-category-report?' + request)
    }
    catch (e) {
    }
  }
  regionWiseReport(area_id: string) {
    try {
      var request = {
        "area_id": area_id,
        "company_id": company_id,
        "created_by": user_id
      };
      return this._httpHelper.post(Config.AuthenticationBaseURL + 'regionWiseReport', request)
    }
    catch (e) {
    }
  }
  followUpReport(pageNo, perPage, meetingId: string, startDate, endDate, isExport, locationId, reportingTo) {
    try {
      let request = 'page=' + pageNo + '&per_page=' + perPage + '&meeting_id=' + meetingId + '&start_date=' + startDate + '&end_date=' + endDate + '&export=' + isExport + '&location_id=' + locationId + '&reporting_to=' + reportingTo;
      return this._httpHelper.get(Config.AuthenticationBaseURL1 + 'followups-report?' + request);
    }
    catch (e) {
    }
  }
  prospectList() {
    try {
      var request = {
        "company_id": company_id
      };
      return this._httpHelper.post(Config.AuthenticationBaseURL1 + 'prospect-drop-list', request)
    }
    catch (e) {
    }
  }
  employeePerformance(pageNo, rowPerPage, startDate, endDate, exportReport, locationId, reportingId) {
    try {
      var request = {
        "company_id": company_id,
        "per_page": rowPerPage,
        "page": pageNo,
        "start_date": startDate,
        "end_date": endDate,
        "export": exportReport,
        "location_id": locationId,
        "reporting_to": reportingId
      };
      return this._httpHelper.post(Config.AuthenticationBaseURL1 + 'get-employee-performance', request)
    }
    catch (e) {
    }
  }
  topPerformingEmployee(page, pageSize, startDate, endDate, exportReport, locationId, reportingTo) {
    try {
      var request = {
        "company_id": company_id,
        "per_page": pageSize,
        "page": page,
        "start_date": startDate,
        "end_date": endDate,
        "export": exportReport,
        "location_id": locationId,
        "reporting_to": reportingTo
      };
      return this._httpHelper.post(Config.AuthenticationBaseURL1 + 'get-top-performing', request)
    }
    catch (e) {
    }
  }
  nonPerformingEmployee(page, pageSize, startDate, endDate, exportReport, locationId, reportingTo) {
    try {
      var request = {
        "company_id": company_id,
        "start_date": startDate,
        "end_date": endDate,
        "per_page": pageSize,
        "page": page,
        "export": exportReport,
        "location_id": locationId,
        "reporting_to": reportingTo
      };
      return this._httpHelper.post(Config.AuthenticationBaseURL1 + 'get-non-performing', request)
    }
    catch (e) {
    }
  }
  MovementReport(start) {
    try {
      var request = {
        "company_id": company_id,
        "per_page": "10",
        "page": start
      };
      return this._httpHelper.post(Config.AuthenticationBaseURL1 + 'get-movement-report', request)
    }
    catch (e) {
    }
  }
  employeeExpenseReport(pageNo, rowPerPage, startDate, endDate, search, exportReport, locationId, reportingTo) {
    try {
      if (exportReport == 'yes') {
        return this._httpHelper.get(Config.AuthenticationBaseURL1 + 'get-employee-expense' + '?page=' + pageNo + '&per_page=' + rowPerPage + '&start_date=' + startDate + '&end_date=' + endDate + '&search=' + search + '&export=' + exportReport + '&location_id=' + locationId + '&reporting_to=' + reportingTo);
      } else {
        return this._httpHelper.get(Config.AuthenticationBaseURL1 + 'get-employee-expense' + '?page=' + pageNo + '&per_page=' + rowPerPage + '&start_date=' + startDate + '&end_date=' + endDate + '&search=' + search + '&location_id=' + locationId + '&reporting_to=' + reportingTo);
      }
    }
    catch (e) {
    }
  }
  teamReport(pageNo, rowPerPage, teamId, search, exportReport, locationId) {
    try {
      if (exportReport == 'yes') {
        return this._httpHelper.get(Config.AuthenticationBaseURL1 + 'team-report' + '?page=' + pageNo + '&per_page=' + rowPerPage + '&team_id=' + teamId + '&search=' + search + '&export=' + exportReport);
      } else {
        return this._httpHelper.get(Config.AuthenticationBaseURL1 + 'team-report' + '?page=' + pageNo + '&per_page=' + rowPerPage + '&team_id=' + teamId + '&search=' + search);
      }
    }
    catch (e) {
    }
  }
  salesReport(pageNo, rowPerPage, teamId, search, exportReport, locationId, reportingTo) {
    try {
      if (exportReport == 'yes') {
        return this._httpHelper.get(Config.AuthenticationBaseURL1 + 'sales-report' + '?page=' + pageNo + '&per_page=' + rowPerPage + '&team_id=' + teamId + '&search=' + search + '&export=' + exportReport + '&location_id=' + locationId + '&reporting_to=' + reportingTo);
      } else {
        return this._httpHelper.get(Config.AuthenticationBaseURL1 + 'sales-report' + '?page=' + pageNo + '&per_page=' + rowPerPage + '&team_id=' + teamId + '&search=' + search + '&location_id=' + locationId + '&reporting_to=' + reportingTo);
      }
    }
    catch (e) {
    }
  }
  eventReport(pageNo, rowPerPage, eventId, search, exportReport, locationId, reportingTo) {
    try {
      if (exportReport == 'yes') {
        return this._httpHelper.get(Config.AuthenticationBaseURL1 + 'event-report' + '?page=' + pageNo + '&per_page=' + rowPerPage + '&event_id=' + eventId + '&search=' + search + '&export=' + exportReport + '&location_id=' + locationId + '&reporting_to=' + reportingTo);
      } else {
        return this._httpHelper.get(Config.AuthenticationBaseURL1 + 'event-report' + '?page=' + pageNo + '&per_page=' + rowPerPage + '&event_id=' + eventId + '&search=' + search + '&location_id=' + locationId + '&reporting_to=' + reportingTo);
      }
    }
    catch (e) {
    }
  }
  productivityReport(pageNo, rowPerPage, search, exportReport) {
    try {
      if (exportReport == 'yes') {
        return this._httpHelper.get(Config.AuthenticationBaseURL1 + 'employee-ladderboard-report' + '?page=' + pageNo + '&per_page=' + rowPerPage + '&search=' + search + '&export=' + exportReport);
      } else {
        return this._httpHelper.get(Config.AuthenticationBaseURL1 + 'employee-ladderboard-report' + '?page=' + pageNo + '&per_page=' + rowPerPage + '&search=' + search);
      }
    }
    catch (e) {
    }
  }
  influencerWiseVoterReport(pageNo, rowPerPage, search, exportReport) {
    try {
      if (exportReport == 'yes') {
        return this._httpHelper.get(Config.AuthenticationBaseURL1 + 'get-influence-voter-list' + '?page=' + pageNo + '&per_page=' + rowPerPage + '&search=' + search + '&export=' + exportReport);
      } else {
        return this._httpHelper.get(Config.AuthenticationBaseURL1 + 'get-influence-voter-list' + '?page=' + pageNo + '&per_page=' + rowPerPage + '&search=' + search);
      }
    }
    catch (e) {
    }
  }
  boothWiseVoterReport(pageNo, rowPerPage, search, exportReport) {
    try {
      if (exportReport == 'yes') {
        return this._httpHelper.get(Config.AuthenticationBaseURL1 + 'get-booth-wise-voter-list' + '?page=' + pageNo + '&per_page=' + rowPerPage + '&search=' + search + '&export=' + exportReport);
      } else {
        return this._httpHelper.get(Config.AuthenticationBaseURL1 + 'get-booth-wise-voter-list' + '?page=' + pageNo + '&per_page=' + rowPerPage + '&search=' + search);
      }
    }
    catch (e) {
    }
  }
  ageGenderWiseVoterReport(pageNo, rowPerPage, search, exportReport) {
    try {
      if (exportReport == 'yes') {
        return this._httpHelper.get(Config.AuthenticationBaseURL1 + 'get-age-or-gender-wise-voter-list' + '?page=' + pageNo + '&per_page=' + rowPerPage + '&search=' + search + '&export=' + exportReport);
      } else {
        return this._httpHelper.get(Config.AuthenticationBaseURL1 + 'get-age-or-gender-wise-voter-list' + '?page=' + pageNo + '&per_page=' + rowPerPage + '&search=' + search);
      }
    }
    catch (e) {
    }
  }
  downloadImage(image, category) {
    try {
      let params: string = '?image=' + image + '&category_name=' + category;
      window.open(Config.AuthenticationBaseURL1 + 'download-image' + params)
      return this._httpHelper.get(Config.AuthenticationBaseURL1 + 'download-image' + params);
    }
    catch (e) {
    }
  }
  favorableVoterReport(pageNo, rowPerPage, search, exportReport) {
    try {
      if (exportReport == 'yes') {
        return this._httpHelper.get(Config.AuthenticationBaseURL1 + 'get-favorable-report' + '?page=' + pageNo + '&per_page=' + rowPerPage + '&search=' + search + '&export=' + exportReport);
      } else {
        return this._httpHelper.get(Config.AuthenticationBaseURL1 + 'get-favorable-report' + '?page=' + pageNo + '&per_page=' + rowPerPage + '&search=' + search);
      }
    }
    catch (e) {
    }
  }
  getMyInfluenceReport(pageNo, rowPerPage, search, exportReport, boothName, type) {
    try {
      return this._httpHelper.get(Config.AuthenticationBaseURL1 + 'get-my-influence-report' + '?page=' + pageNo + '&per_page=' + rowPerPage + '&search=' + search + '&export=' + exportReport + '&booth_name=' + boothName + '&type=' + type);

    }
    catch (e) {
    }
  }
  getEmployeeWiseSurveyReport(pageNo, rowPerPage, search, exportReport) {
    try {
      return this._httpHelper.get(Config.AuthenticationBaseURL1 + 'get-survey-user-wise-report' + '?page=' + pageNo + '&per_page=' + rowPerPage + '&search=' + search + '&export=' + exportReport);
    }
    catch (e) {
    }
  }
  getEmployeeWisePollReport(pageNo, rowPerPage, search, exportReport) {
    try {
      return this._httpHelper.get(Config.AuthenticationBaseURL1 + 'get-poll-user-wise-report' + '?page=' + pageNo + '&per_page=' + rowPerPage + '&search=' + search + '&export=' + exportReport);
    }
    catch (e) {
    }
  }
  getSurveyReport(search, exportReport, employeeId) {
    try {
      return this._httpHelper.get(Config.AuthenticationBaseURL1 + 'get-survey-user-report' + '?employee_id=' + employeeId + '&search=' + search + '&export=' + exportReport);
    }
    catch (e) {
    }
  }
  getPollReport(search, exportReport, employeeId) {
    try {
      return this._httpHelper.get(Config.AuthenticationBaseURL1 + 'get-poll-user-report' + '?employee_id=' + employeeId + '&search=' + search + '&export=' + exportReport);
    }
    catch (e) {
    }
  }
  locationWiseExpenseReport(request) {
    try {
      return this._httpHelper.get(Config.AuthenticationBaseURL1 + 'get-expense-by-location', request);
    }
    catch (e) {
    }
  }
  locationWisePerformanceReport(request) {
    try {      
      return this._httpHelper.get(Config.AuthenticationBaseURL1 + 'get-location-wise-performance', request);
      // return this._httpHelper.get(Config.AuthenticationBaseURL1 + 'location-wise-performance', request);
    }
    catch (e) {
    }
  }

  userRouteReport(pageNo, rowPerPage, empId, search, exportReport, locationId,startDate,endDate) {
    try {
      if (exportReport == 'yes') {
        return this._httpHelper.get(Config.AuthenticationBaseURL1 + 'user-route-report' + '?page=' + pageNo + '&per_page=' + rowPerPage + '&emp_id=' + empId + '&search=' + search + '&start_date=' + startDate + '&end_date=' + endDate + '&export=' + exportReport);
      } else {
        return this._httpHelper.get(Config.AuthenticationBaseURL1 + 'user-route-report' + '?page=' + pageNo + '&per_page=' + rowPerPage + '&emp_id=' + empId + '&search=' + search+ '&start_date=' + startDate+ '&end_date=' + endDate);
      }
    }
    catch (e) {
    }
  }
}
