import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { Angular2Csv } from 'angular2-csv/Angular2-csv';
import { VoterService } from '../../../services/voter/voter.service';
import { Constants } from '../../../common/constants';
import { CustomErrorHandler } from '../../../errorhandler/custom-errorhandler';
import swal from 'sweetalert2';
import { CommonService } from '../../../services/common/common.service';
import { FileUploader } from 'ng2-file-upload';
import { Config } from '../../../config/config';
import { Subject, Subscription } from 'rxjs';
declare var $: any;
declare var google: any;
@Component({
  selector: 'app-voter-list',
  templateUrl: './voter-list.component.html',
  styleUrls: ['./voter-list.component.css']
})
export class VoterListComponent implements OnInit {
  documentDetails: any = {};
  documentfile: string;
  isExport: boolean;
  isDemoTenant: boolean;
  errormsg: string;
  msg: boolean;
  actionLable: string;
  employeeLabel: string;
  voterList: any;
  loading: boolean;
  perPage: number = 100;
  pageNo: number = 1;
  searchTextValue: any = '';
  totalItem: number = 0;
  page: number = 2;
  exportReport: any;
  serverUrl: any;
  maxPage: number = 10;
  state: any = '';
  metadata = [{ 'type': 'content' }];
  startDate: any;
  endDate: any;
  statusType: any = ''
  statusTypeList: any = [];
  statusSelectedItems: any = [];
  statusSettings = {};
  deleteRequest: any = {}
  locationModel: any;
  directionsService: any;
  directionsDisplay: any;
  map: any;
  locationName: any;
  markers: any = [];
  voterImportModel: any;
  uploader: FileUploader;
  stateList: any = [];
  stateSettings: any = {};
  stateSelectedItems: any = [];

  stateDetailList: any = [];
  stateDetailSettings: any = {};
  stateDetailSelectedItems: any = [];
  stateDetailId:any ='';
  stateName:any ='';
  constituencyList: any = [];
	constituencySettings: any = {};
  constituencySelectedItems: any = [];
  constituencyId:any ='';
	boothList: any = [];
	boothSettings: any = {};
  boothSelectedItems: any = [];
  boothId:any ='';
  @ViewChild('document_info') uploadElRef: ElementRef
  searchTextChanged = new Subject<string>();
  searchEvent: Subscription;
  filterLabel = Constants.filterLabel;
  constructor(
    private router: Router,
    public commonService: CommonService,
    public voterService: VoterService,
    private customErrorHandler: CustomErrorHandler
  ) { }
  ngOnInit() {
    this.serverUrl = Config.AuthenticationURL;
    this.isExport = false;
    this.isDemoTenant = (localStorage.getItem('isDemoTenant') == 'true');
    this.errormsg = '';
    this.msg = false;
    this.actionLable = localStorage.getItem('actionLabel');
    this.employeeLabel = localStorage.getItem('employeeLabel');
    this.loading = true;
    this.getVoterList(1);
    this.statusSettings = {
      singleSelection: true,
      text: "Select Status",
      enableSearchFilter: true,
      classes: "myclass custom-class singleSelection"
    };
    this.statusTypeList = [{
      'id': '',
      'itemName': 'Select All'
    }, {
      'id': '0',
      'itemName': 'Pending'
    }, {
      'id': '1',
      'itemName': 'Verified'
    }, {
      'id': '2',
      'itemName': 'Not Verified'
    }];
    if ($(window).width() < 768) {
      this.maxPage = 6;
    }
    // Get voter List and cancel previous call using debounce
    this.searchEvent = this.searchTextChanged
      .debounceTime(500)
      .distinctUntilChanged()
      .subscribe(() => {
        this.getVoterList(1);
      });
    this.getStateList();
    this.stateSettings = {
      singleSelection: true,
      text: "Select State",
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      enableSearchFilter: true,
      classes: "singleSelection block_elem m_b_15"
    };
    this.stateDetailSettings = {
      singleSelection: true,
      text: "Select State",
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      enableSearchFilter: true,
      classes: "singleSelection block_elem m_b_15"
    };
    this.constituencySettings = {
			singleSelection: true,
			text: "Select Constituency",
			enableSearchFilter: true,
			classes: "singleSelection block_elem m_b_15"
		}
		this.boothSettings = {
			singleSelection: true,
			text: "Select Booth Number",
			selectAllText: 'Select All',
			unSelectAllText: 'UnSelect All',
			enableSearchFilter: true,
			classes: "singleSelection block_elem m_b_15"
		}
    this.locationModel = document.getElementById('selectLocationModal');
    this.voterImportModel = document.getElementById('selectVoterModal');
    this.map = new google.maps.Map(document.getElementById('map'), {
      zoom: 8,
      center: { lat: 22.7196, lng: 75.8577 },
      gestureHandling: 'cooperative',
      styles: Constants.MapStyle
    });
    this.directionsDisplay.setMap(this.map);
  }
  getVoterList(currentPage) {
    this.pageNo = currentPage;
    this.page = currentPage;
    this.exportReport = '';
    let quoteStrings: any = {
      pageNo: currentPage,
      perPage: this.perPage,
      search: this.searchTextValue,
      export: '',
      status: this.statusType,
      stateId: this.stateName,
      constituencyId: this.constituencyId,
      boothId: this.boothId
    }
    this.errormsg = '';
    this.loading = true;
    this.voterService.getVoterList(quoteStrings).subscribe(
      response => {
        this.loading = false;
        let res: any = response;
        if (res.result == true) {
          this.totalItem = res.data.total;
          this.voterList = res.data.data;
          this.errormsg = res.message;
        } else {
          this.voterList = [];
          this.msg = true;
          this.errormsg = res.message;
          this.commonService.showErrorMessage(res);
        }
      },
      error => {
        this.loading = false;
        this.voterList = [];
        this.customErrorHandler.handleHttpError(error, 'getVoterList');
      });
  }
  onkeyup_value(event) {
    this.searchTextValue = event.target.value;
    this.searchTextChanged.next(this.searchTextValue);
  }
  export() {
    var options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: true,
      useBom: true,
      title: "Voter-List"
    };
    if(this.searchTextValue == undefined){
      this.searchTextValue = '';
    }
    if(typeof this.statusType == 'undefined'){
      this.statusType = '';
    }
    let qeuryStrings: any = {
      pageNo: this.pageNo,
      perPage: this.perPage,
      search: this.searchTextValue,
      export: 'yes',
      status: this.statusType,
      constituencyId: this.constituencyId,
      boothId: this.boothId,
      stateId: this.stateName
    }
    this.voterService.getVoterList(qeuryStrings).subscribe(
      res => {
        new Angular2Csv(res.data, 'voterList', options);
      },
      error => {
        this.customErrorHandler.handleHttpError(error, 'voterList');
      });
  }
  editVoterDetails(voterId) {
    localStorage.setItem('editVoterId', voterId);
    this.router.navigate(['./voter/add-voter']);
  }
  deleteVoterDetails(voterId) {
    swal({
      text: Constants.DeleteMsg,
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Delete',
    }).then((result) => {
      if (typeof result.value != 'undefined') {
        this.deleteRequest.id = voterId;
        this.voterService.deleteVoterDetail(this.deleteRequest).subscribe(
          res => {
            if (res.result == true) {
              this.commonService.showSuccessMessage(res.message);
              this.getVoterList(this.pageNo);
            } else {
              this.commonService.showCustomError(res.message);
            }
          },
          error => {
            this.customErrorHandler.handleHttpError(error, 'employeeReport');
          });
      }
    });
  }
  selectStatusType(item: any) {
    this.statusType = this.statusSelectedItems;
    this.getVoterList(1);
  }
  deleteAllVoterList() {
    swal({
      text: Constants.DeleteAllMsg,
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Delete',
    }).then((result) => {
      if (typeof result.value != 'undefined') {
        this.deleteRequest = {}
        this.voterService.deleteAllVoterDetail(this.deleteRequest).subscribe(
          res => {
            if (res.result == true) {
              this.getVoterList(1);
            } else {
              this.commonService.showCustomError(res.message);
            }
          },
          error => {
            this.customErrorHandler.handleHttpError(error, 'employeeReport');
          });
      }
    });
  }
  openLocationViewModal(latitude, longitude, status) {
    if (status == '1' || status == 1) {
      this.locationName = 'Verified Location';
    } else {
      this.locationName = 'Not Verified Location';
    }
    this.locationModel.style.display = "block";
    for (let i = 0; i < this.markers.length; i++) {
      this.markers[i].setMap(null);
    }
    if (latitude != '' && longitude != '') {
      let myLatLng = { lat: Number(latitude), lng: Number(longitude) };
      let marker = new google.maps.Marker({
        position: myLatLng,
        map: this.map,
        title: ' '
      });
      this.map.setCenter(myLatLng);
      this.map.setZoom(14);
      this.markers.push(marker);
    } else {
      let myLatLng = { lat: 22.7196, lng: 75.8577 }
      this.map.setCenter(myLatLng);
      this.map.setZoom(14);
    }
  }
  routeCloseModal() {
    this.locationModel.style.display = "none";
  }
  uploadVoterList() {
    this.voterImportModel.style.display = "block";
  }
  voterCloseModal() {
    this.voterImportModel.style.display = "none";
  }
  getStateList() {
    this.voterService.getStateList().subscribe(
      res => {
        if (res.result == true) {
          this.stateList = res.data;
        } else {
          this.commonService.showCustomError(res.message);
        }
      },
      error => {
        this.customErrorHandler.handleHttpError(error, 'getStateList');
      });
  }
  getConstituencyList(state) {
		this.voterService.getConstituencyList(state).subscribe(
			res => {
				if (res.result == true) {
					this.constituencyList = res.data;					
				} else {
					this.commonService.showCustomError(res.message);
				}
			},
			error => {
				this.customErrorHandler.handleHttpError(error, 'getStateList');
			});
	}
	getBoothList(constituency) {
		this.voterService.getBoothList(constituency).subscribe(
			res => {
				if (res.result == true) {
					this.boothList = res.data;				
				} else {
					this.commonService.showCustomError(res.message);
				}
			},
			error => {
				this.customErrorHandler.handleHttpError(error, 'getStateList');
			});
	}
  selectState(item: any) {
    this.state = this.stateSelectedItems[0].itemName;
    var this1 = this;
    const URL = Config.AuthenticationBaseURL1 + 'uploadXlsx';
    this.uploader = new FileUploader({
      url: URL,
      headers: [{ name: 'company_id', value: localStorage.getItem('company_id') },{ name: 'user_id', value: localStorage.getItem('user_id') }],
      allowedFileType: ["xls"],
      additionalParameter: {
        state: this1.state
      }
    });
    this.uploader.onAfterAddingFile = function (file) {
      file.withCredentials = false;
      $('.loader-wrapper').css('display', 'block');
      this1.voterImportModel.style.display = "none";
      file.upload();
    };
    this.uploader.onWhenAddingFileFailed = (item) => {
      let errorMsg = 'Invaild file, file format should be .xlsx'
      this.uploadElRef.nativeElement.value = '';
      this.stateSelectedItems = [];
      this.commonService.showCustomError(errorMsg)
    }
    this.uploader.onCompleteItem = (item, response, status, headers) => {
      if (status == 200) {
        this.stateSelectedItems = [];
        var res = JSON.parse(response);
        if (res.result == true) {
          this.uploadElRef.nativeElement.value = ''
          this.getVoterList(1);
          this.commonService.showSuccessMessage(res.message || 'Record inserted sucessfully!');
          $('.loader-wrapper').css('display', 'none');
        } else {
          this.uploadElRef.nativeElement.value = ''
          this.getVoterList(1);
          $('.loader-wrapper').css('display', 'none');
          this.commonService.showErrorMessage(res.message || 'Record not inserted!');
        }
      } else {
        this.uploadElRef.nativeElement.value = ''
        this.getVoterList(1);
        $('.loader-wrapper').css('display', 'none');
        this.commonService.showErrorMessage(res.message || 'Record not inserted!');
      }
    }
  }
  deselectState(item: any) {
    this.stateSelectedItems = [];
    this.state = '';    
  }

  selectStateDetails(item: any){
    this.stateDetailId =item.id;
    this.stateName =  item.itemName;
    this.constituencySelectedItems = [];
    this.getConstituencyList(this.stateDetailSelectedItems[0].itemName);
    this.getVoterList(1);
  }
  deselectStateDetails(item: any) {
    this.stateDetailId ='';
    this.stateDetailSelectedItems = [];
    this.constituencyList =[];
    this.constituencySelectedItems =[];
    this.getVoterList(1);
  }
  selectConstituencyDetails(item: any) {
    this.constituencyId = item.id;
		this.boothSelectedItems = [];
    this.getBoothList(this.constituencySelectedItems[0].id);
    this.getVoterList(1);
	}
	deselectConstituencyDetails() {
    this.constituencyId = '';
		this.constituencySelectedItems = [];
		this.boothList = [];
    this.boothSelectedItems = [];
    this.getVoterList(1);
	}
	selectBoothDetails(item: any) {
  this.boothId = item.id;
  this.getVoterList(1);
	}
	deselectBoothDetails() {
    this.boothId ='';
    this.getVoterList(1);
	}
}
