import { ErrorHandler, Injectable } from '@angular/core';
@Injectable()
export class CustomErrorHandler implements ErrorHandler {
    constructor() {
    }
    public handleError(error) {
    }
    /**    
    * Handled Exception    
    * @param error: Error object    
    * @param methodName: Method Name    
    */
    public handledError(error: Error, methodName?: string) {
    }

    /**    
    * Handle Http Error    
    * @param response: http response    
    * @param methodName: Method Name    
    */
    public handleHttpError(response: any, methodName?: string): string {
        // let errorMsg: string = "";
        // let statusCode: number = response.status;
        // When device is offline it reloads the page to display default offline of browser page
        if (!navigator.onLine) {
            window.location.reload();
            return;
        }
        console.log('http error handler: unhandled error occured on method: ' + methodName);
        // this code will display default error message for unhandled errors
        // swal({
        //     type: 'error',
        //     title: 'Oops...',
        //     text: Constants.CommonErrorMessage
        //   });
    }
}
