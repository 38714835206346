import { Config } from './../../config/config';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { TrackingService } from '../../services/tracking/tracking.service';
import { CustomErrorHandler } from '../../errorhandler/custom-errorhandler';
import { Constants } from './../../common/constants';
declare var moment: any;
declare var $: any;
declare var google: any;
@Component({
    selector: 'detail-map',
    templateUrl: 'detail-map.component.html',
    styleUrls: ['./detail-map.component.css'],
    host: {
      'class': 'fullHeightBlock'
    }
})
export class DetailMapComponent implements OnInit {
    msg: boolean;
    errormsg: string;
    prospectLabel: string;
    snappedCoordinates = [];
    placeIdArray = [];
    route;
    polylines = [];
    clat: any;
    clng: any;
    infoWindow: any;
    markers: any;
    marker: any;
    newMarkerArray: any[];
    speedArray: any[];
    speed: any;
    interVal: any;
    lastPosn: any;
    map: any;
    count: any;
    lineCoordinates: any;
    trackData: any[];
    trackProspectData: any[];
    emp_name: any;
    emp_profile_pic: any;
    play: boolean;
    pause: boolean;
    notblank: boolean;
    notblankprospect: boolean;
    id: any;
    numDeltas = 100;
    delay = 10; //milliseconds
    i = 0;
    deltaLat: number;
    deltaLng: number;
    position;
    employeeData: any;
    startTime: any;
    endTime: any;
    selectDate: any;
    tooltipContent: any;
    infowindow: any;
    timeArray = [];
    modeArrayStatus = [];
    stoppageArray = [];
    userRunningSpeed = [];
    imageBaseUrl: any;
    routeName: any;
    travelledDistance: any;
    travelledTime: any;
    startTimeRoute: any;
    endTimeRoute: any;
    paybackCount: number = 0;
    constructor(private router: Router, public _trackingService: TrackingService, public routeActivate: ActivatedRoute, private customErrorHandler: CustomErrorHandler) {
    }
    ngOnInit() {
        this.startTimeRoute = new Date(moment().startOf('day'));//'00:00 AM';
        this.endTimeRoute = new Date(moment().endOf('day'));//'11:59 PM';
        this.startTime = moment(this.startTimeRoute).format('HH:mm');
        this.endTime = moment(this.endTimeRoute).format('HH:mm');
        this.routeName = '';
        this.travelledDistance = '';
        this.travelledTime = '';
        this.imageBaseUrl = Config.AuthenticationURL;
        this.prospectLabel = localStorage.getItem('prospectLabel');
        if (this.prospectLabel == null) {
            this.prospectLabel = 'Prospects'
        }
        this.msg = false;
        this.errormsg = '';
        this.clat = 23.258467;
        this.clng = 77.431245;
        this.infoWindow = new google.maps.InfoWindow;
        this.markers = [];
        this.newMarkerArray = [];
        let trackId = this.routeActivate.snapshot.paramMap.get('id');
        if (trackId) {
            this.id = trackId;
            this._trackingService.employeeById(this.id).subscribe(
                res => {
                    this.employeeData = res.data;
                    if (this.employeeData) {
                        this.emp_name = this.employeeData.first_name;
                        this.emp_profile_pic = this.employeeData.profile_pic;
                    }
                },
                error => {
                    this.customErrorHandler.handleHttpError(error, 'employeeById');
                }
            )
        } else {
            this.id = localStorage.getItem('track_emp_id');
            this.emp_name = localStorage.getItem('emp_name');
            this.emp_profile_pic = localStorage.getItem('emp_profile_pic');
        }
        this.speedArray = [1000, 900, 800, 700, 600, 500, 400, 300, 200, 100];
        this.speed = 1;
        this.interVal = "";
        this.lastPosn = "";
        this.map;
        this.count = 0;
        this.lineCoordinates;
        var start = moment();
        var end = moment();
        var _this2;
        _this2 = this;
        function cb(start, end) {
            $('#reportrange span').html(start.format('MMMM D, YYYY'));
            _this2.notblank = false;
            _this2.notblankprospect = false;
            _this2.lineCoordinates = [];
            _this2.trackingData(start.format('YYYY-MM-DD'));
            _this2.selectDate = start.format('YYYY-MM-DD');
            _this2.trackingProspectData(start.format('YYYY-MM-DD'));
        }
        $('#reportrange').daterangepicker({
            singleDatePicker: true,
            startDate: start,
            endDate: end,
            maxDate: moment(),
            ranges: {
                'Today': [moment(), moment()],
                'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                'This Month': [moment().startOf('month'), moment().endOf('month')],
                'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
            }
        }, cb);
        cb(start, end);
        this.play = true;
        this.pause = false;
        this.notblank = false;
        if($(window).width()>760){
          $('.fullHeightBlock').scrollbar();
        }
    }
    ngOnDestroy() {
      $('.fullHeightBlock').remove();
    }
    changeTime() {
        this.notblank = false;
        this.notblankprospect = false;
        this.lineCoordinates = [];
        this.trackingData(this.selectDate);
        this.trackingProspectData(this.selectDate);
    }
    animatePath(map, route, marker, pathCoords) {
        var index = 0;
        route.setPath([]);
        for (var index = 0; index < pathCoords.length; index++)
            setTimeout((offset) => {
                route.getPath().push(pathCoords.getAt(offset));
                marker.setPosition(pathCoords.getAt(offset));
                map.panTo(pathCoords.getAt(offset));
            }, index * 100, index);
    }

    trackingData(date) {
        this.routeName = '';
        this.travelledDistance = '';
        this.travelledTime = '';
        this.play = true;
        this.pause = false;
        this.startTime;
        this.endTime;
        this._trackingService.trackingDataWeb(this.id, date, this.startTime, this.endTime).subscribe(
            res => {
                this.paybackCount = 0;
                var mapOptions = {
                    zoom: 15,
                    center: new google.maps.LatLng(22.752559, 75.865535),
                    travelMode: google.maps.TravelMode.DRIVING,
                    mapTypeId: google.maps.MapTypeId.ROADMAP,
                    styles: Constants.MapStyle
                };
                this.map = new google.maps.Map(document.getElementById('map'), mapOptions);
                var src = 'https://data.basecampfleetpro.com/43.kml';
                var kmlLayer = new google.maps.KmlLayer(src, {
                  suppressInfoWindows: true,
                  preserveViewport: false,
                  map: this.map
                });

                if (res.data.length > 0) {
                    this.travelledDistance = res.distance;
                    this.travelledTime = res.travel_time;
                    this.notblank = true;
                    this.trackData = res.data;
                    this.lineCoordinates = [];
                    this.timeArray = [];
                    this.userRunningSpeed = [];
                    for (let i of this.trackData) {
                        this.stoppageArray.push(i);
                        this.timeArray.push(i.time);
                        this.userRunningSpeed.push(i.speed);
                        if (i.mode == null || $.trim(i.mode) == '') {
                            i.mode = 'Driving';
                        }
                        this.modeArrayStatus.push(i.mode);
                        let d_lat = Number(i.lat);
                        let d_lng = Number(i.lng);
                        this.lineCoordinates.push(new google.maps.LatLng(d_lat, d_lng));
                    }
                    var markerstart = new google.maps.Marker({
                        map: this.map,
                        position: this.lineCoordinates[0],
                        icon: "assets/images/30_s.png"
                    });
                    this.position = this.lineCoordinates[0];
                    this.map.setCenter(markerstart.getPosition());
                    var markerend = new google.maps.Marker({
                        map: this.map,
                        position: this.lineCoordinates[this.lineCoordinates.length - 1],
                        icon: "assets/images/30_e.png"
                    });
                    markerend.getPosition();
                    var line = new google.maps.Polyline({
                        path: this.lineCoordinates,
                        icons: [{
                            icon: {
                                strokeColor: '#0000ff',
                                strokeWeight: 5,
                                fillColor: '#0000ff',
                                fillOpacity: 1
                            },
                            offset: '100%',
                            repeat: '250px'
                        }],
                        map: this.map,
                        strokeColor: "#4998f6",//"#ff0000",
                        strokeOpacity: 1,
                        strokeWeight: 5
                    });
                    line.setMap(this.map);
                    this.speed = (<HTMLInputElement>document.getElementById('dd_range')).value;
                }
                else {
                }
            },
            error => {
                this.customErrorHandler.handleHttpError(error, 'trackingDataWeb');
            }
        )
    }
    moveMarker() {
        if (this.play == true) {
            return false;
        }
        if (typeof this.marker !== 'undefined' && this.marker) {
            this.marker.setMap(null);
        }
        this.marker = new google.maps.Marker({
            map: this.map,
            icon: "assets/images/30_W.png"

        });
        this.infowindow = new google.maps.InfoWindow();
        this.infowindow.open(this.map, this.marker);
        $(".gm-style-iw").next("div").hide();
        this.interVal = setInterval(() => {
            if (this.userRunningSpeed[this.count] > 40) {
                let speedIcon = "assets/images/flag.png";
                let speedInfowindow = new google.maps.InfoWindow();
                let speedTooltipContent = 'Speed - ' + this.userRunningSpeed[this.count] + '<br><b>Time:</b> ' + this.timeArray[this.count];
                let speedMarker = new google.maps.Marker({
                    position: this.lineCoordinates[this.count],
                    map: this.map,
                    icon: speedIcon
                });
                speedMarker.setMap(this.map);
                google.maps.event.addListener(speedMarker, 'click', (function (speedMarker, speedTooltipContent, speedInfowindow) {
                    return function () {
                        speedInfowindow.setContent(speedTooltipContent);
                        speedInfowindow.open(this.map, speedMarker);
                    };
                })(speedMarker, speedTooltipContent, speedInfowindow));
            }
            let content = '<b>Mode: </b>' + this.modeArrayStatus[this.count] + '<br><b>Time:</b> ' + this.timeArray[this.count];
            this.infowindow.setContent(content);
            this.markers = this.newMarkerArray;
            var point = this.lineCoordinates[this.count];
            this.lastPosn = point;
            var result = this.lineCoordinates[this.count];

            //set stoppage point 
            if (this.stoppageArray[this.count].stop_time > 4) {
                let stopageIcon = '';
                if (this.stoppageArray[this.count].stop_time < 10) {
                    stopageIcon = "assets/images/stop_5_9.png";
                } else if (this.stoppageArray[this.count].stop_time < 15) {
                    stopageIcon = "assets/images/stop_10_14.png";
                } else {
                    stopageIcon = "assets/images/stop_15.png";
                }
                let stopInfowindow = new google.maps.InfoWindow();
                let stoppageTooltipContent = 'Stop Time - ' + this.stoppageArray[this.count].stop_time + ' Min. <br><b>Time:</b> ' + this.timeArray[this.count];
                var stoppageMarker = new google.maps.Marker({
                    position: this.lineCoordinates[this.count],
                    map: this.map,
                    icon: stopageIcon
                });
                stoppageMarker.setMap(this.map);
                google.maps.event.addListener(stoppageMarker, 'click', (function (stoppageMarker, stoppageTooltipContent, stopInfowindow) {
                    return function () {
                        stopInfowindow.setContent(stoppageTooltipContent);
                        stopInfowindow.open(this.map, stoppageMarker);
                    };
                })(stoppageMarker, stoppageTooltipContent, stopInfowindow));
            }
            this.route.getPath().push(point);
            this.marker.setPosition(point);
            if (this.count == this.lineCoordinates.length - 1) {
                this.count = 0;
                clearInterval(this.interVal);
                this.play = true;
                this.pause = false;
            }
            else {
                this.count++;
            }
        }, this.speedArray[this.speed]);
    }

    addMarker(marker) {
        this.clearMarkers(this.markers);
        this.newMarkerArray.push(marker);
        return marker;
    }

    clearMarkers(data) {
        for (var i = 0; i < data.length; i++) {
            this.markers[i].setMap(null);
        }
    }

    doPause() {
        this.play = true;
        this.pause = false;
        clearInterval(this.interVal);
    }

    doPlay() {
        if (typeof this.route !== 'undefined' && this.route && this.paybackCount == 0) {
            this.route.setPath([]);
        }
        this.paybackCount++;
        this.route = new google.maps.Polyline({
            path: [],
            geodesic: true,
            strokeColor: '#000000',
            strokeOpacity: 1,
            strokeWeight: 4,
            editable: false,
            map: this.map,
            zIndex: 9999
        });
        this.play = false;
        this.pause = true;
        if (this.interVal != "") {
            clearInterval(this.interVal);
            this.moveMarker();
        }
        else {
            this.moveMarker();
        }
    }

    doStop() {
        this.marker.setMap(null);
        this.route.setPath([]);
        this.play = true;
        this.pause = false;
        if (this.interVal != "") {
            this.count = 0;
            clearInterval(this.interVal);
            this.interVal = "";
            this.marker = new google.maps.Marker({
                map: this.map,
                position: this.lineCoordinates[0],
                icon: "assets/images/30_W.png"
            });
            this.addMarker(this.marker);
            this.map.setCenter(this.lineCoordinates[0]);
        }
    }

    changeRange(value: number) {
        this.speed = value;
        if (this.interVal != "") {
            clearInterval(this.interVal);
            this.moveMarker();
        }
    }

    trackingProspectData(date) {
        this.routeName = '';
        this._trackingService.trackingProspectData(this.id, date, this.startTime, this.endTime).subscribe(
            res => {
                this.notblankprospect = false;
                if (res.result == true) {
                    if (res.data.length > 0) {
                        if (typeof res.data[0].route_name != 'undefined') {
                            this.routeName = res.data[0].route_name;
                        }
                        this.notblankprospect = false;
                        this.trackProspectData = res.data;
                    }
                    else {
                        this.trackProspectData = [];
                        this.notblankprospect = true;
                    }
                }
                else {
                    this.trackProspectData = [];
                    this.notblankprospect = true;
                }
            },
            error => {
                this.customErrorHandler.handleHttpError(error, 'trackingProspectData');
            }
        )
    }
    // Store snapped polyline returned by the snap-to-road service.
    processSnapToRoadResponse(data) {
        this.snappedCoordinates = [];
        this.placeIdArray = [];
        for (var i = 0; i < data.snappedPoints.length; i++) {
            var latlng = new google.maps.LatLng(
                data.snappedPoints[i].location.latitude,
                data.snappedPoints[i].location.longitude);
            this.snappedCoordinates.push(latlng);
            this.placeIdArray.push(data.snappedPoints[i].placeId);
        }
    }

    // Draws the snapped polyline (after processing snap-to-road response).
    drawSnappedPolyline() {
        var snappedPolyline = new google.maps.Polyline({
            path: this.snappedCoordinates,
            strokeColor: '#000000',//#4998f6',
            strokeWeight: 3
        });
        snappedPolyline.setMap(this.map);
        this.polylines.push(snappedPolyline);
    }

    processSpeedLimitResponse(speedData, start) {
        var end = start + speedData.speedLimits.length;
        for (var i = 0; i < speedData.speedLimits.length - 1; i++) {
            var speedLimit = speedData.speedLimits[i].speedLimit;
            var color = this.getColorForSpeed(speedLimit);
            var coords = this.snappedCoordinates.slice(start + i, start + i + 2);
            var snappedPolyline = new google.maps.Polyline({
                path: coords,
                strokeColor: color,
                strokeWeight: 6
            });
            snappedPolyline.setMap(this.map);
            this.polylines.push(snappedPolyline);
        }
    }

    getColorForSpeed(speed_kph) {
        if (speed_kph <= 40) {
            return 'purple';
        }

        if (speed_kph <= 50) {
            return 'blue';
        }

        if (speed_kph <= 60) {
            return 'green';
        }

        if (speed_kph <= 80) {
            return 'yellow';
        }

        if (speed_kph <= 100) {
            return 'orange';
        }
        return 'red';
    }

    onChangeTime() {
        this.startTime = moment(this.startTimeRoute).format('HH:mm');
        this.endTime = moment(this.endTimeRoute).format('HH:mm');
        this.changeTime();
    }
}
