import { Component, OnInit } from '@angular/core';
declare var $: any;
@Component({
  selector: 'event',
  templateUrl: './event.component.html',
  styleUrls: ['./event.component.css'],
  host: {
    'class': 'fullHeightBlock'
  }
})
export class EventComponent implements OnInit {
  ngOnInit() {
    if ($(window).width() > 760) {
      $('.fullHeightBlock').scrollbar();
    }
  }
  ngOnDestroy() {
    $('.fullHeightBlock').remove();
  }
}
