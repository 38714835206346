import { Injectable } from '@angular/core';
import { HttpHelper } from '../../helpers/http.helper';
import { Config } from '../../config/config';
const company_id = localStorage.getItem('company_id');
@Injectable()
export class headerService {
    constructor(private _httpHelper: HttpHelper) {
    }
    notificationList(request) {
        try {
            // var request = {
            //     "company_id": company_id,
            //     "alert_type": type
            // };
            return this._httpHelper.post(Config.AuthenticationBaseURL1 + 'get-notification-list', request)
        }
        catch (e) {
        }
    }
    notificationCount(type: number) {
        try {
            var request = {
                "company_id": company_id,
                "alert_type": type
            };
            return this._httpHelper.post(Config.AuthenticationBaseURL1 + 'notification-count', request)
        }
        catch (e) {
        }
    }
    notificationUpdate(type: number) {
        try {
            var request = {
                "company_id": company_id,
                "alert_type": type
            };
            return this._httpHelper.post(Config.AuthenticationBaseURL1 + 'notification-update', request)
        }
        catch (e) {
        }
    }
    recievedMessageList(employee_id) {
        try {
            var request = {
                "employee_id": employee_id
            };
            return this._httpHelper.post(Config.AuthenticationBaseURL1 + 'get-message-receiver', request)
        }
        catch (e) {

        }
    }
    unreadMessageCount() {
        try {
            return this._httpHelper.get(Config.AuthenticationBaseURL1 + 'get-unread-count')
        }
        catch (e) {

        }
    }
}
