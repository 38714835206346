import { Role_managementService } from './../../../../services/role_management/role_management.service';
import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { CustomErrorHandler } from '../../../../errorhandler/custom-errorhandler';
import { CommonService } from '../../../../services/common/common.service';
import { Constants } from '../../../../common/constants';
@Component({
    selector: 'update-assign-role',
    templateUrl: 'update-assign-role.component.html',
    styleUrls: ['./update-assign-role.component.css']
})
export class UpdateAssignRoleComponent implements OnInit {
    modal: any;
    role_type_id: string;
    role: any[];
    role_type_list: any[];
    xyzlist: Array<any> = [];
    errorMessage: string;
    disableButton: boolean;
    signin_btn_txt: string;
    menuTree: any = [];
    menuIds: any = [];
    updateRoleDetails: any = {};
    locationLabel: any = Constants.locationLabel;
    constructor(
        private router: Router,
        public _Role_managementService: Role_managementService,
        private customErrorHandler: CustomErrorHandler,
        public commonService: CommonService
    ) { }
    ngOnInit() {
        this.getMenu();
        this.roleTypeList();
        this.selectAssignRoleBYid(localStorage.getItem('assign_role_id'));
    }
    selectAssignRoleBYid(id) {
        this._Role_managementService.selectAssignRoleById(id).subscribe(
            res => {
                if (res.result == true) {
                    this.role_type_id = res.role_type_id;
                    let selectedMenuIds = res.role_id;
                    if (selectedMenuIds) {
                        selectedMenuIds = selectedMenuIds.split(',');
                        for (var i = 0; i < selectedMenuIds.length; ++i) {
                            this.menuIds[selectedMenuIds[i]] = true;
                        }
                    }
                }
            },
            error => {
                this.customErrorHandler.handleHttpError(error, 'selectAssignRoleBYid');
            });
    }
    onClicked(item1, event) {
        item1.checked = !item1.checked;
    }
    roleTypeList() {
        this._Role_managementService.roleTypeList().subscribe(
            res => {
                if (res.result == true) {
                    this.role_type_list = res.data;
                }
            },
            error => {
                this.customErrorHandler.handleHttpError(error, 'roleTypeList');
            });
    }
    getMenu() {
        this._Role_managementService.getMenuList().subscribe(
            res => {
                if (res.result == true) {
                    this.menuTree = res.data;
                }
                else {
                    this.commonService.showErrorMessage(res);
                }
            },
            error => {
                this.customErrorHandler.handleHttpError(error, 'routeDelete');
            });
    }
    selectAll(id) {
        let temp = this.menuTree.find(s => s.id == id);
        if (this.menuIds[id] == true) {
            for (let i = 0; i < temp.children.length; i++) {
                this.menuIds[temp.children[i].id] = true;
            }
        } else {
            for (let i = 0; i < temp.children.length; i++) {
                this.menuIds[temp.children[i].id] = false;
            }
        }
    }
    checkIfAllSelected(menuId, subMenuId) {
        let temp = this.menuTree.find(s => s.id == menuId);
        var this1 = this;
        if (this1.menuIds[subMenuId] == true) {
            this1.menuIds[menuId] = true;
        } else {
            let count = 0;
            for (let i = 0; i < temp.children.length; i++) {
                if (this1.menuIds[temp.children[i].id] == true) {
                    count++;
                }
            }
            if (count == 0) {
                this1.menuIds[menuId] = false;
            }
        }
    }
    onSubmit() {
        let allowMenu = '1';
        for (var key in this.menuIds) {
            if (this.menuIds[key] != undefined && this.menuIds[key] == true) {
                if (allowMenu == '') {
                    allowMenu = key;
                } else {
                    allowMenu += ',' + key;
                }
            }
        }
        this.disableButton = true;
        this.errorMessage = '';
        this.updateRoleDetails.id = localStorage.getItem('assign_role_id');
        this.updateRoleDetails.allow_menu = allowMenu;
        this.updateRoleDetails.role_id = this.role_type_id;
        this._Role_managementService.updateRoleAssign(this.updateRoleDetails).subscribe(
            res => {
                if (res.result == true) {
                    if (this.role_type_id == localStorage.getItem('userType')) {
                        let msg = res.message + ' Need to login again to see updated changes.'
                        this.commonService.showWarningMessage(msg);
                    } else {
                        this.commonService.showSuccessMessage(res.message);
                        this.router.navigate(["setting/role-management/assign-rolelist"]);
                    }
                } else {
                    this.disableButton = false;
                    this.signin_btn_txt = "Submit";
                    this.commonService.showErrorMessage(res);
                }
            },
            error => {
                this.customErrorHandler.handleHttpError(error, 'updateRoleAssign');
                this.disableButton = false;
                this.signin_btn_txt = "Submit";
            });
    }
}
