import { MeetingService } from './../../../services/meeting/meeting.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Config } from '../../../config/config';
import { CustomErrorHandler } from '../../../errorhandler/custom-errorhandler';
import { VoterService } from './../../../services/voter/voter.service';
import { CommonService } from '../../../services/common/common.service';
import { Constants } from '../../../common/constants';
declare var moment: any;
@Component({
	selector: 'app-election-day',
	templateUrl: './election-day.component.html',
	styleUrls: ['./election-day.component.css']
})
export class ElectionDayComponent implements OnInit {
	electionDayInfo: any = {};
	categoryDetails: any = {};
	categorySettings: any = {};
	categorySelectedItems: any = [];
	categoryList: any = [];
	modal: any;
	documentfile: string;
	showName: any;
	serverUrl: any;
	editElectionDayId: any;
	fileToUpload: any;
	documentPageLabel: any;
	stateList: any = [];
	stateSettings: any = {};
	stateSelectedItems: any = [];
	constituencyList: any = [];
	constituencySettings: any = {};
	constituencySelectedItems: any = [];
	boothList: any = [];
	boothSettings: any = {};
	boothSelectedItems: any = [];
	employeeList: any = [];
	employeeSettings: any = {};
	employeeSelectedItems: any = [];
	employeeLabel: any;
	teamList: any = [];
	teamSettings: any = {};
	teamSelectedItems: any = [];
	isState: any = false;
	isConstituency: any = false;
	isBoothNumber: any = false;
	employeeTeamErrorMsg: boolean = true;
	currentDate: any;
	pageHeading: any = 'Add Election Day';
	locationList: any = [];
	locationSelectedItem: any = [];
	locationSettings: any = {};
	locationId;
	locationLabel: any = Constants.locationLabel;
	loading:boolean;
	constructor(
		private router: Router, 
		private _prospectService: MeetingService, 
		public _commonService: CommonService, 
		private customErrorHandler: CustomErrorHandler, 
		public voterService: VoterService
	) {}

	ngOnInit() {
		this.currentDate = new Date();
		this.employeeLabel = localStorage.getItem('employeeLabel');
		this.getTeamList();
		this.getStateList();
		this.getLocationList();
		// this.getEmployeeList();
		this.stateSettings = {
			singleSelection: true,
			text: "Select State",
			selectAllText: 'Select All',
			unSelectAllText: 'UnSelect All',
			enableSearchFilter: true,
			classes: "singleSelection"
		};
		this.constituencySettings = {
			singleSelection: true,
			text: "Select Constituency",
			selectAllText: 'Select All',
			unSelectAllText: 'UnSelect All',
			enableSearchFilter: true,
			classes: "singleSelection"
		}
		this.boothSettings = {
			singleSelection: true,
			text: "Select Booth Number",
			selectAllText: 'Select All',
			unSelectAllText: 'UnSelect All',
			enableSearchFilter: true,
			classes: "singleSelection"
		}
		this.teamSettings = {
			singleSelection: false,
			text: "Select Team",
			selectAllText: 'Select All',
			unSelectAllText: 'UnSelect All',
			enableSearchFilter: true
		}
		this.employeeSettings = {
			singleSelection: false,
			text: "Select " + this.employeeLabel,
			selectAllText: 'Select All',
			unSelectAllText: 'UnSelect All',
			enableSearchFilter: true
		}
		this.documentPageLabel = 'Create';
		this.editElectionDayId = '';
		this.editElectionDayId = localStorage.getItem('editElectionDayId');
		this.documentfile = '';
		this.serverUrl = Config.imageBaseUrlCI;
		this.categorySettings = {
			singleSelection: true,
			text: "Select Category",
			selectAllText: 'Select All',
			unSelectAllText: 'UnSelect All',
			enableSearchFilter: true,
			classes: "singleSelection"
		};
		this.modal = document.getElementById('myModal');
		if (this.editElectionDayId) {
			this.pageHeading = "Edit Election Day";
		}
		this.locationSettings = {
			singleSelection: true,
			text: "Select "+ Constants.locationLabel,
			enableSearchFilter: true,
			classes: "singleSelection"
		};
	}
	addElectionDay() {
		this.loading = true;
		this.isState = false;
		this.isConstituency = false;
		this.isBoothNumber = false;
		this.employeeTeamErrorMsg = false;
		this.electionDayInfo.added_by = localStorage.getItem('user_id');
		if ((this.electionDayInfo.state == undefined || this.electionDayInfo.state == '') &&
			(this.electionDayInfo.constituency == undefined || this.electionDayInfo.constituency == '') && (this.electionDayInfo.booth_number == undefined || this.electionDayInfo.booth_number == '')
		) {
			this.isState = true;
			this.isConstituency = true;
			this.isBoothNumber = true;
			return false;
		}
		if (this.electionDayInfo.state == undefined || this.electionDayInfo.state == '') {
			this.isState = true;
			return false;
		}
		if (this.electionDayInfo.constituency == undefined || this.electionDayInfo.constituency == '') {
			this.isConstituency = true;
			return false;
		}
		if (this.electionDayInfo.booth_number == undefined || this.electionDayInfo.booth_number == '') {
			this.isBoothNumber = true;
			return false;
		}
		if (this.employeeSelectedItems.length == 0 && this.teamSelectedItems.length == 0) {
			this.employeeTeamErrorMsg = true;
			return false;
		}
		let startTime = this.electionDayInfo.start_time;
		let endTime = this.electionDayInfo.end_time;
		this.electionDayInfo.location_id = this.locationSelectedItem.map(function (elem) { return elem.id; }).join(" ");
		this.electionDayInfo.voting_date = moment(this.electionDayInfo.voting_date).format('YYYY-MM-DD');
		this.electionDayInfo.start_date = this.electionDayInfo.voting_date + ' ' + moment(startTime).format('HH:mm:ss');
		this.electionDayInfo.end_date = this.electionDayInfo.voting_date + ' ' + moment(endTime).format('HH:mm:ss');
		if (this.editElectionDayId) {
			this.electionDayInfo.voting_day_id = this.editElectionDayId;
			this.voterService.updateElectionDayInfo(this.electionDayInfo).subscribe(
				res => {
					if (res.result == true) {
						this._commonService.showSuccessMessage(res.message);
						this.router.navigate(["voter/election-day-list"]);
					} else {
						this._commonService.showErrorMessage(res);
					}
					this.loading = false;
				},
				error => {
					this.customErrorHandler.handleHttpError(error, 'createEvent');
					this.loading = false;
				});
		} else {
			this.voterService.addElectionDayInfo(this.electionDayInfo).subscribe(res => {
				if (res.result == true) {
					this._commonService.showSuccessMessage(res.message);
					this.router.navigate(["voter/election-day-list"]);
				} else {
					this._commonService.showErrorMessage(res);
				}
				this.loading = false;
			}, error => {
				this.customErrorHandler.handleHttpError(error, 'createEvent');
				this.loading = false;
			});
		}
	}
	getSingleElectionDayDetails(electionDayId) {
		this.voterService.getSingleElectionDay(electionDayId).subscribe(
			res => {
				if (res.result == true) {
					this.electionDayInfo = res.data;
					this.electionDayInfo.voting_date = new Date(this.electionDayInfo.voting_date);
					this.electionDayInfo.start_time = new Date(this.electionDayInfo.start_date);
					this.electionDayInfo.end_time = new Date(this.electionDayInfo.end_date);
					this.electionDayInfo.volunteer_id = res.data.volunteer_id;
					let stateTemp = this.stateList.find(s => s.id == this.electionDayInfo.state);
					this.stateSelectedItems = [];
					if (typeof stateTemp != 'undefined') {
						this.getConstituencyList(stateTemp.itemName);
						this.stateSelectedItems.push(stateTemp);
					}
					this.getEmployeeList(this.electionDayInfo.location_id);
					// let employeeId = this.electionDayInfo.volunteer_id;
					// if (this.editElectionDayId && employeeId != undefined && this.employeeList.length > 0) {
					// 	this.employeeSelectedItems = [];
					// 	let employeeInfo = employeeId.split(',');
					// 	console.log(employeeId, employeeInfo, 'employeeInfo')
					// 	for (let i = 0; i < employeeInfo.length; i++) {
					// 		let empTemp = this.employeeList.find(s => s.id == employeeInfo[i]);
					// 		if (typeof empTemp != 'undefined') {
					// 			this.employeeSelectedItems.push(empTemp);
					// 		}
					// 	}
					// }
					let lactionTemp = this.locationList.find(s => s.id == this.electionDayInfo.location_id);
					if (typeof lactionTemp != 'undefined') {
						this.locationSelectedItem = [];
						this.locationSelectedItem.push(lactionTemp);
					}
				} else {
					this._commonService.showCustomError(res.message);
				}
			},
			error => {
				this.customErrorHandler.handleHttpError(error, 'employeeReport');
			});
	}
	getStateList() {
		this.voterService.getStateList().subscribe(
			res => {
				if (res.result == true) {
					this.stateList = res.data;
					if (this.editElectionDayId) {
						this.documentPageLabel = 'Edit';
						this.getSingleElectionDayDetails(this.editElectionDayId);
					}
				} else {
					this._commonService.showCustomError(res.message);
				}
			},
			error => {
				this.customErrorHandler.handleHttpError(error, 'getStateList');
			});
	}

	getLocationList() {
		this._commonService.getLocationList().subscribe(res => {
			if (res.result == true) {
			this.locationList = res.data;
			}
		}, error => {
			this.customErrorHandler.handleHttpError(error, 'getRoleList');
		});
	}
	onLocationItemSelect(item: any) {
		this.locationId = item.id;
		this.getEmployeeList(this.locationId);		
	}
	onLocationItemDeSelect(item: any) {
		this.locationId = '';
	}
	getEmployeeList(locationId) {
		this._prospectService.employeeLocationList(locationId).subscribe(
			res => {
				this.employeeList = res.data;
				let employeeId = this.electionDayInfo.volunteer_id;
				if (this.editElectionDayId && employeeId != undefined && this.employeeList.length > 0) {
					this.employeeSelectedItems = [];
					let employeeInfo = employeeId.split(',');
					for (let i = 0; i < employeeInfo.length; i++) {
						let empTemp = this.employeeList.find(s => s.id == employeeInfo[i]);
						if (typeof empTemp != 'undefined') {
							this.employeeSelectedItems.push(empTemp);
						}
					}
				}
			},
			error => {
				this.customErrorHandler.handleHttpError(error, 'employeeList');
			});
	}
	ngOnDestroy() {
		localStorage.removeItem('editElectionDayId');
	}
	getConstituencyList(state) {
		this.voterService.getConstituencyList(state).subscribe(
			res => {
				if (res.result == true) {
					this.constituencyList = res.data;
					if (this.editElectionDayId && this.electionDayInfo.constituency != undefined) {
						let constituencyTemp = this.constituencyList.find(s => s.id == this.electionDayInfo.constituency);
						if (typeof constituencyTemp != 'undefined') {
							this.constituencySelectedItems.push(constituencyTemp);
						}
						this.electionDayInfo.constituency = constituencyTemp.id;
						this.getBoothList(constituencyTemp.id);
					}
				} else {
					this._commonService.showCustomError(res.message);
				}
			},
			error => {
				this.customErrorHandler.handleHttpError(error, 'getStateList');
			});
	}
	getBoothList(constituency) {
		this.voterService.getBoothList(constituency).subscribe(
			res => {
				if (res.result == true) {
					this.boothList = res.data;
					if (this.editElectionDayId && this.electionDayInfo.booth_number != undefined) {
						let boothTemp = this.boothList.find(s => s.id == this.electionDayInfo.booth_number);
						if (typeof boothTemp != 'undefined') {
							this.boothSelectedItems.push(boothTemp);
						}
					}
				} else {
					this._commonService.showCustomError(res.message);
				}
			},
			error => {
				this.customErrorHandler.handleHttpError(error, 'getStateList');
			});
	}
	selectStateDetails() {
		this.electionDayInfo.state = this.stateSelectedItems[0].id;
		this.electionDayInfo.constituency = '';
		this.constituencySelectedItems = [];
		console.log(this.constituencySelectedItems, 'lll')
		this.boothList = [];
		this.electionDayInfo.booth_number = '';
		this.boothSelectedItems = [];
		this.getConstituencyList(this.stateSelectedItems[0].itemName);
		this.isState = false;
	}
	deselectStateDetails() {
		this.electionDayInfo.state = '';
		this.constituencyList = [];
		this.electionDayInfo.constituency = '';
		this.constituencySelectedItems = [];
		this.boothList = [];
		this.electionDayInfo.booth_number = '';
		this.boothSelectedItems = [];
	}
	selectConstituencyDetails() {
		this.electionDayInfo.constituency = this.constituencySelectedItems[0].id;
		this.electionDayInfo.booth_number = '';
		this.boothSelectedItems = [];
		this.getBoothList(this.constituencySelectedItems[0].id);
		this.isConstituency = false;
	}
	deselectConstituencyDetails() {
		this.electionDayInfo.constituency = '';
		this.constituencySelectedItems = [];
		this.boothList = [];
		this.electionDayInfo.booth_number = '';
		this.boothSelectedItems = [];
	}
	selectBoothDetails() {
		console.log(this.boothSelectedItems, 'boothSelectedItems');
		this.electionDayInfo.booth_number = this.boothSelectedItems[0].id;
		this.isBoothNumber = false;

	}
	deselectBoothDetails() {
		this.electionDayInfo.constituency = '';
		this.constituencySelectedItems = [];
	}
	selectEmployeeDetails() {
		this.electionDayInfo.volunteer_id = '';
		for (let i = 0; i < this.employeeSelectedItems.length; i++) {
			if (i == 0) {
				this.electionDayInfo.volunteer_id = this.employeeSelectedItems[i].id;
			} else {
				this.electionDayInfo.volunteer_id += ',' + this.employeeSelectedItems[i].id;
			}
		}
		if (this.employeeSelectedItems.length == 0 && this.teamSelectedItems.length == 0) {
			this.employeeTeamErrorMsg = true;
		} else {
			this.employeeTeamErrorMsg = false;
		}
	}
	deselectEmployeeDetails(item: any) {
		this.electionDayInfo.volunteer_id = '';
		for (let i = 0; i < this.employeeSelectedItems.length; i++) {
			if (i == 0) {
				this.electionDayInfo.volunteer_id = this.employeeSelectedItems[i].id;
			} else {
				this.electionDayInfo.volunteer_id += ',' + this.employeeSelectedItems[i].id;
			}
		}
		if (this.employeeSelectedItems.length == 0 && this.teamSelectedItems.length == 0) {
			this.employeeTeamErrorMsg = true;
		} else {
			this.employeeTeamErrorMsg = false;
		}
	}
	selectTeamDetails() {
		this.electionDayInfo.team = '';
		for (let i = 0; i < this.teamSelectedItems.length; i++) {
			if (i == 0) {
				this.electionDayInfo.team = this.teamSelectedItems[i].id;
			} else {
				this.electionDayInfo.team += ',' + this.teamSelectedItems[i].id;
			}
		}
		if (this.employeeSelectedItems.length == 0 && this.teamSelectedItems.length == 0) {
			this.employeeTeamErrorMsg = true;
		} else {
			this.employeeTeamErrorMsg = false;
		}
	}
	deselectTeamDetails(item: any) {
		this.electionDayInfo.team = '';
		for (let i = 0; i < this.teamSelectedItems.length; i++) {
			if (i == 0) {
				this.electionDayInfo.team = this.teamSelectedItems[i].id;
			} else {
				this.electionDayInfo.team += ',' + this.teamSelectedItems[i].id;
			}
		}
		if (this.employeeSelectedItems.length == 0 && this.teamSelectedItems.length == 0) {
			this.employeeTeamErrorMsg = true;
		} else {
			this.employeeTeamErrorMsg = false;
		}
	}
	getTeamList() {
		this._prospectService.teamList().subscribe(
			res => {
				this.teamList = res.data;
			},
			error => {
				this.customErrorHandler.handleHttpError(error, 'teamList');
			});
	}
	changedStartTime(time) {
		if (typeof this.electionDayInfo.end_time != 'undefined' && this.electionDayInfo.end_time != '') {
			if (time > this.electionDayInfo.end_time) {
				this.electionDayInfo.end_time = new Date(this.electionDayInfo.start_time.getTime() + 1 * 60000)
			}
		} else {
			this.electionDayInfo.end_time = time;
		}
	}
	changedEndTime(time) {
		if (typeof this.electionDayInfo.start_time != 'undefined' && this.electionDayInfo.start_time != '') {
			if (time < this.electionDayInfo.start_time) {
				this.electionDayInfo.end_time = new Date(this.electionDayInfo.start_time.getTime() + 1 * 60000)
			}
		} else {
			this.electionDayInfo.start_time = time;
		}
	};
	changeElectionDate() {
		let currentDate = new Date();
		currentDate = moment(currentDate).format('YYYY-MM-DD');
		let selectedDate = new Date(this.electionDayInfo.voting_date);
		selectedDate = moment(selectedDate).format('YYYY-MM-DD');
		if (selectedDate < currentDate) {
			this.electionDayInfo.voting_date = '';
		}
	}
	changeElectionTime(time) {
		console.log(this.electionDayInfo.end_time, 'end');
		console.log(this.electionDayInfo.start_time, 'start');
		let start = new Date(this.electionDayInfo.start_time).getTime();
		let end = new Date(this.electionDayInfo.end_time).getTime();
		if (typeof this.electionDayInfo.end_time != 'undefined' && typeof this.electionDayInfo.start_time != 'undefined') {
			if (end < start) {
				this.electionDayInfo.end_time = this.electionDayInfo.start_time;
			}
		}
	}
}
