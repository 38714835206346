import { Component, OnInit } from '@angular/core';
import { Role_managementService } from '../../../services/role_management/role_management.service';
import { CustomErrorHandler } from '../../../errorhandler/custom-errorhandler';
@Component({
  selector: 'role-management',
  templateUrl: 'role-management.component.html',
  styleUrls: ['./role-management.component.css']
})
export class RoleManagementComponent implements OnInit {
  modal: any;
  role_type_id: string;
  role: any[];
  role_type_list: any[];
  xyzlist: Array<any> = [];
  errorMessage: string;
  disableButton: boolean;
  signin_btn_txt: string;
  constructor(public _Role_managementService: Role_managementService, private customErrorHandler: CustomErrorHandler) {
  }
  ngOnInit() {
    this.roleTypeList();
    this.roleList();
  }
  onClicked(item1, event) {
    if (event.target.checked) {
      this.xyzlist.push({ 'value': event.target.value });
    } else {
      let index = this.xyzlist.indexOf(event.target.value);
      this.xyzlist.splice(index, 1);
    }
  }
  roleList() {
    this._Role_managementService.roleList().subscribe(
      res => {
        if (res.result != 'Invalid Token') {
          this.role = res.data;
        }
      },
      error => {
        this.customErrorHandler.handleHttpError(error, 'roleList');
      });
  }
  roleTypeList() {
    this._Role_managementService.roleTypeList().subscribe(
      res => {
        if (res.result == true) {
          this.role_type_list = res.data;
        }
      },
      error => {
        this.customErrorHandler.handleHttpError(error, 'roleTypeList');
      });
  }
}
