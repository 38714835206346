import { EmployeeListService } from './../../../services/employeelist/employeeList.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HomeSharedService } from '../../../services/shared/home-shared';
import { CustomErrorHandler } from '../../../errorhandler/custom-errorhandler';
import { Config } from '../../../config/config';
import { CommonService } from './../../../services/common/common.service';
import { Constants } from '../../../common/constants';
declare var moment: any;
@Component({
  selector: 'list',
  templateUrl: './list.component.html'
})
export class ListComponent implements OnInit {
  imageBaseUrl: string = Config.AuthenticationURL;
  nodata: boolean;
  prospectLabel: string;
  employee: string;
  company_id: string;
  home: any[];
  loading: boolean;
  filter: string;
  search: string;
  start_date: string;
  end_date: string;
  start: number;
  pagechk;
  msg;
  errormsg;
  message: any;
  pageNo: number = 1;
  perPage: number = 10;
  totalItem: number = 0;
  page: number = 2;
  export: any;
  employeeList: any = [];
  reportingTo: any = '';
  locationId: any = '';
  filterLabel = Constants.filterLabel;
  
  constructor(
    private router: Router,
    public _homeService: EmployeeListService,
    public _homeSharedService: HomeSharedService,
    private customErrorHandler: CustomErrorHandler,
    private commonservice: CommonService
  ) {
    this._homeSharedService.homeDate$.subscribe(data => {
      this.loading = true;
      this.end_date = data.end_date;
      this.start_date = data.start_date;
      this.filter = data.filter;
      if (typeof data.search != 'undefined') {
        this.search = data.search;
      } else {
        this.search = '';
      }
      this.start = 0;
      this.home = [];
      this.reportingTo = data.reporting_to;
      this.locationId = data.location_id;
      this.getEmployeeList(1);
    });
  }
  ngOnInit() {
    this.nodata = false;
    this.prospectLabel = localStorage.getItem('prospectLabel');
    this.employee = localStorage.getItem('employeeLabel');
    this.loading = true;
    this.search = '';
    this.filter = '';
    this.start_date = moment().subtract(6, 'days').format('YYYY-MM-DD');
    this.end_date = moment().format('YYYY-MM-DD');
    this.start = 0;
    this.home = [];
    this.getEmployeeList(1);
  }
  gotoDetail(track_emp_id, emp_name, emp_profile_pic) {
    localStorage.setItem('home_date', moment().format('MMMM D, YYYY'));
    localStorage.setItem('emp_name', emp_name);
    localStorage.setItem('emp_profile_pic', emp_profile_pic);
    localStorage.setItem('track_emp_id', track_emp_id);
    this.router.navigate(["detailmap"]);
  }
  createEmployee() {
    localStorage.setItem('createEmployee', 'Yes');
    this.router.navigate(["setting/employee"]);
  }
  getEmployeeList(currentPage) {
    this.pageNo = currentPage;
    this.page = currentPage;
    this.message = '';
    this.export = '';
    this._homeService.getEmployeeList(this.pageNo, this.perPage, this.search, this.export, this.filter, this.reportingTo, this.locationId).subscribe(
      res => {
        this.loading = false;
        this.employeeList = [];
        if (res.result == true) {
          this.totalItem = res.data.total;
          this.employeeList = res.data.data;
          this.message = res.message;
        } else {
          this.message = res.message;
          this.commonservice.showErrorMessage(res);
        }
      },
      error => {
        this.loading = false;
        this.customErrorHandler.handleHttpError(error, 'employeeReport');
      }
    )
  }
}
