import { AuthGuard } from './../../services/_guards/auth.guard';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { VoterManagementComponent } from './voter-management.component';
import { VoterListComponent } from './voter-list/voter-list.component';
import { AddVoterComponent } from './add-voter/add-voter.component';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown/angular2-multiselect-dropdown';
import { InfiniteScrollModule } from 'angular2-infinite-scroll';
import { Ng2PaginationModule } from 'ng2-pagination';
import { FileUploadModule } from 'ng2-file-upload';
import { CalendarModule } from 'primeng/calendar';
import { VoterAssignmentComponent } from './voter-assignment/voter-assignment.component';
import { VoterAssignmentListComponent } from './voter-assignment-list/voter-assignment-list.component';
import { VoterFeedbackComponent } from './voter-feedback/voter-feedback.component';
import { AssignGeofenceComponent } from './assign-geofence/assign-geofence.component';
import { ElectionDayComponent } from './election-day/election-day.component';
import { ElectionDayListComponent } from './election-day-list/election-day-list.component';
import { EvmAssignmentComponent } from './evm-assignment/evm-assignment.component';
import { EvmAssignmentListComponent } from './evm-assignment-list/evm-assignment-list.component';
import { EvmDetailsComponent } from './evm-details/evm-details.component';

export const routes: Routes = [
  {
    path: 'voter',
    component: VoterManagementComponent, canActivate: [AuthGuard],
    children: [
      { path: '', redirectTo: 'voter-list', pathMatch: 'full' },
      { path: 'voter-list', component: VoterListComponent },
      { path: 'add-voter', component: AddVoterComponent },
      { path: 'assign-list', component: VoterAssignmentListComponent },
      { path: 'assign', component: VoterAssignmentComponent },
      { path: 'feedback-list', component: VoterFeedbackComponent },
      { path: 'assign-geofence', component: AssignGeofenceComponent },
      { path: 'election-day', component: ElectionDayComponent },
      { path: 'election-day-list', component: ElectionDayListComponent },
      { path: 'evm-assignment', component: EvmAssignmentComponent },
      { path: 'evm-assignment-list', component: EvmAssignmentListComponent },
      { path: 'evm-details', component: EvmDetailsComponent },
    ]
  }
];
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule.forChild(routes),
    InfiniteScrollModule,
    AngularMultiSelectModule,
    Ng2PaginationModule,
    FileUploadModule,
    CalendarModule
  ],
  declarations: [
    VoterManagementComponent,
    VoterListComponent,
    AddVoterComponent,
    VoterAssignmentComponent,
    VoterAssignmentListComponent,
    VoterFeedbackComponent,
    VoterFeedbackComponent,
    AssignGeofenceComponent,
    ElectionDayComponent,
    ElectionDayListComponent,
    EvmAssignmentComponent,
    EvmAssignmentListComponent,
    EvmDetailsComponent
  ]
})
export class VoterManagementModule { }
