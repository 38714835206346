import { NgModule } from "@angular/core";
import { FilterPipe } from "./filter.pipe";
import { MessageDatetimeFormatterPipe } from "./message-datetime-formatter.pipe";
@NgModule({
    imports: [],
    declarations: [FilterPipe, MessageDatetimeFormatterPipe],
    exports: [FilterPipe, MessageDatetimeFormatterPipe]
})
export class PipeModule {
    static forRoot() {
        return {
            ngModule: PipeModule,
            providers: [],
        };
    }
} 