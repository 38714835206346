import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RouteService } from '../../../services/route/route.service';
import { Config } from '../../../config/config';
import { CustomErrorHandler } from '../../../errorhandler/custom-errorhandler';
import { Constants } from '../../../common/constants';
import { CommonService } from '../../../services/common/common.service';
declare var moment: any;
declare var $: any;
declare var google: any;
@Component({
    selector: 'route-create',
    templateUrl: 'route-create.component.html',
    styleUrls: ['./route-create.component.css']
})
export class RouteCreateComponent implements OnInit {
    isDemoTenant: boolean;
    drawingManager: any;
    polygonPaths: any[] = [];
    rectangleBounds: any;
    circleRadius: any;
    circleLat: string;
    circleLng: string;
    shapeType: number;
    creates: any;
    create_area: boolean;
    map: any;
    cityCircle: any;
    dd_radius1: string;
    lat: string;
    lng: string;
    name: string;
    loginFormEmail: string;
    status: string;
    errorMessage: string;
    disableButton: boolean;
    signin_btn_txt: string;
    loginDiv: boolean;
    tenantList: any[];
    accessToken: string;
    image_url: string;
    current_lng: any;
    current_lat: any;
    directionsService: any;
    directionsDisplay: any;
    routeOrigin: any;
    routeDestination: any;
    routePaths: any[] = [];
    originPath: any;
    originResult: any;
    routeDrawResult: any[] = [];
    routeDistance: any = 0;
    routeETA: any = 0;
    routeResult: any;
    errorRouteMessage: any;
    routeDistanceKm: any = 0;
    routeETAMin: any = 0;
    employeeMemberList: any = [];
    employeeMemberSettings = {};
    routeCreateDetails: any = {};
    employeeMeetingList: any = [];
    employeeMeetingListDragable: any = [];
    errorEmployeeMessage: any;
    routeETATime: any;
    markerArray = [];
    stepDisplay: any;
    markers = [];
    meetingModal: any;
    defaultEmployeeMeeting: any = [];
    meetingFormIds: any = [];
    employeeMeetingListTemp: any = [];
    prospectLabel: any;
    meetingRouteLength: any = 0;
    startDate: any;
    endDate: any;
    meetingSelectFlag: boolean = false;
    employeeMeetingMsg: any;
    startDateshow: any;
    endDateshow: any;
    meetingEmpId: any;
    locationList: any = [];
    locationSelectedItem: any = [];
    locationSettings: any = {};
    locationId;
    employeeLabel = localStorage.getItem('employeeLabel');
    locationLabel = Constants.locationLabel
    // defaultSelectObject: any = { id: '', itemName: 'All' }
    constructor(private router: Router, private commonService: CommonService, public _routeService: RouteService, private customErrorHandler: CustomErrorHandler) {
    }
    loadAutoComple() {
        var input = document.getElementById('route-draw-input1');
        var autocomplete = new google.maps.places.Autocomplete(input);
        var infowindow = new google.maps.InfoWindow();
        var infowindowContent = document.getElementById('infowindow-content');
        infowindow.setContent(infowindowContent);
        autocomplete.addListener('place_changed', function () {
            infowindow.close();
        });
    }
    success(position) {
        this.current_lng = position.coords.longitude;
        this.current_lat = position.coords.latitude;
        this.map = new google.maps.Map(document.getElementById('map'), {
            center: { lat: this.current_lat, lng: this.current_lng },
            zoom: 8,
            styles: Constants.MapStyle
        });
        setTimeout(() => {
            this.loadAutoComple();
        }, 100);
    }
    fail(position) {
        this.map = new google.maps.Map(document.getElementById('map'), {
            center: { lat: 22.7664707, lng: 75.8824308 },
            zoom: 8,
            styles: Constants.MapStyle
        });
        setTimeout(() => {
            this.loadAutoComple();
        }, 100);
    }
    ngOnInit() {
        this.meetingEmpId = '';
        this.routeCreateDetails.route_name = '';
        // this.employeeListFunction(this.locationId);
        this.prospectLabel = localStorage.getItem('prospectLabel');
        this.isDemoTenant = (localStorage.getItem('isDemoTenant') == 'true');
        this.routeETATime = 0;
        this.routeOrigin = '';
        this.routeDestination = '';
        this.directionsService = new google.maps.DirectionsService;
        this.directionsDisplay = new google.maps.DirectionsRenderer({
            suppressMarkers: true
        });
        this.map = new google.maps.Map(document.getElementById('map'), {
            zoom: 8,
            center: { lat: 22.7664707, lng: 75.8824308 },
            gestureHandling: 'cooperative',
            styles: Constants.MapStyle
        });
        this.directionsDisplay.setMap(this.map);
        this.stepDisplay = new google.maps.InfoWindow;
        setTimeout(() => {
            this.loadAutoComple();
        }, 100);
        this.employeeMemberSettings = {
            singleSelection: true,
            text: "Select " + this.employeeLabel,
            enableSearchFilter: true,
            classes: "myclass custom-class"
        };
        this.meetingModal = document.getElementById('selectRootFromMeetingModal');
        var start = moment().subtract(0, 'days');
        var end = moment();
        let this1 = this;
        function cb(start, end) {
            this1.startDate = start.format('YYYY-MM-D');
            this1.startDateshow = start.format('MMMM D, YYYY');
            $('#reportrange span').html(start.format('MMMM D, YYYY'));
            this1.changesEmployeeDetails();
            $('#endDate').daterangepicker({
                singleDatePicker: true,
                startDate: start,
                minDate: start
            }, cbEnd);
            $('#endDate span').html(start.format('MMMM D, YYYY'));
        }
        function cbEnd(start, end) {
            this1.endDate = start.format('YYYY-MM-D');
            this1.endDateshow = start.format('MMMM D, YYYY');
            $('#endDate span').html(start.format('MMMM D, YYYY'));
            this1.changesEmployeeDetails();
        }
        $('#reportrange').daterangepicker({
            singleDatePicker: true,
            startDate: start,
            minDate: start
        }, cb);
        $('#endDate').daterangepicker({
            singleDatePicker: true,
            startDate: start,
            minDate: start
        }, cbEnd);
        cb(start, end);
        cbEnd(start, end);
        this.getLocationList();
        
        this.locationSettings = {
            singleSelection: true,
            text: "Select "+ Constants.locationLabel,
            enableSearchFilter: true,
            classes: "singleSelection"
        };
    }
    calculateAndDisplayRoute(directionsService, directionsDisplay) {
        let waypts = [];
        this.routePaths = [];
        this.deleteMarkers();
        var listenerHandle = google.maps.event.addListener(this.map, 'bounds_changed', function () {
            // Handler code.
        });
        listenerHandle.remove();
        let routeLength = this.employeeMeetingList.length;
        let imgUrl = Config.AuthenticationURL + 'public/route-icon/';
        if (routeLength > 1) {
            this.routeOrigin = this.employeeMeetingList[0]['lat'] + ',' + this.employeeMeetingList[0]['lng'];//$('#'+originId).val();
            this.routePaths.push(this.routeOrigin);
            this.originPath = '';
            this.routeDestination = this.employeeMeetingList[routeLength - 1]['lat'] + ',' + this.employeeMeetingList[routeLength - 1]['lng'];
            if (routeLength > 2) {
                for (let i = 1; i < routeLength - 1; i++) {
                    let routeWayPoint = this.employeeMeetingList[i]['lat'] + ',' + this.employeeMeetingList[i]['lng'];
                    waypts.push({
                        location: routeWayPoint,
                        stopover: true
                    });
                    this.routePaths.push(routeWayPoint);
                    let wayPointLatLng = new google.maps.LatLng(this.employeeMeetingList[i]['lat'], this.employeeMeetingList[i]['lng']);
                    let wayPointIcon = imgUrl + (i + 1) + '.png';
                    this.createMarker(wayPointLatLng, this.employeeMeetingList[i]['description'], this.employeeMeetingList[i]['name'], this.employeeMeetingList[i]['image_url'], wayPointIcon);
                }
            }
            this.routePaths.push(this.routeDestination);
        }
        else {
            return false;
        }
        $('.route_input').each(function () {
            var inputId = $(this).attr('id');
        });
        var _this = this;
        this.routeOrigin = new google.maps.LatLng(this.employeeMeetingList[0]['lat'], this.employeeMeetingList[0]['lng']);
        this.routeDestination = new google.maps.LatLng(this.employeeMeetingList[routeLength - 1]['lat'], this.employeeMeetingList[routeLength - 1]['lng']);
        this.createMarker(this.routeOrigin, this.employeeMeetingList[0]['description'], this.employeeMeetingList[0]['name'], this.employeeMeetingList[0]['image_url'], imgUrl + '1.png');
        let destinationIcon = imgUrl + '' + routeLength + '.png';
        if (routeLength > 32) {
            destinationIcon = imgUrl + '32.png'
        }
        this.createMarker(this.routeDestination, this.employeeMeetingList[routeLength - 1]['description'], this.employeeMeetingList[routeLength - 1]['name'], this.employeeMeetingList[routeLength - 1]['image_url'], destinationIcon);
        directionsService.route({
            origin: this.routeOrigin,
            destination: this.routeDestination,
            waypoints: waypts,
            optimizeWaypoints: false,
            travelMode: 'DRIVING',
            provideRouteAlternatives: false
        }, function (response, status) {
            if (status === 'OK') {
                directionsDisplay.setDirections(response);
                var route = response.routes[0];
                _this.routeDistance = 0;
                _this.routeETA = 0;
                for (var i = 0; i < route.legs.length; i++) {
                    _this.routeDistance += Number(route.legs[i]['distance']['value']);
                    _this.routeETA += Number(route.legs[i]['duration']['value']);
                }
                _this.routeDistanceKm = (_this.routeDistance / 1000).toFixed(1);
                _this.routeETAMin = (_this.routeETA / 60).toFixed(0);
                _this.timeFormating(_this.routeETAMin);
            } else {
                let routeLen = _this.employeeMeetingList.length;
                if (routeLen >= 2) {
                    return false;
                }
            }
        });
        this.locationDragable();
    }
    createMarker(latlng, description, name, imgUrl, icon) {
        var image = {
            url: imgUrl,
            size: new google.maps.Size(32, 32),
            shape: { coords: [17, 17, 18], type: 'circle' },
            optimized: false
        };
        var content = '<div style="background: url(' + imgUrl + ') no-repeat center;width:150px;height: 90px;"></div><br><b style="font-weight:bold">' + name + '</b><p>' + description + '</p>';
        var _this = this;
        var infowindow = new google.maps.InfoWindow({
            content: content,
            maxWidth: 150,
            width: 100
        });
        var marker = new google.maps.Marker({
            position: latlng,
            icon: icon,
            map: _this.map,
            shape: { coords: [17, 17, 18], type: 'circle' }
        });
        google.maps.event.addListener(marker, 'click', function () {
            infowindow.open(_this.map, marker);
        });
        this.markers.push(marker);
    }
    timeFormating(totalMinutes) {
        let totalTime = Number(totalMinutes);
        this.routeETATime = '';
        console.log(totalTime, 'time');
        if (totalTime < 60) {
            this.routeETATime = totalTime + ' m';
        } else {
            if (totalTime > 1440) {
                let days = totalTime / 1440;
                days = parseInt(days.toString());
                if (days > 0) {
                    this.routeETATime = days + ' d ';
                }
                let hours = totalTime / 60 % 24;
                hours = parseInt(hours.toString());
                if (hours > 0) {
                    this.routeETATime = this.routeETATime + '' + hours + ' h ';
                }
                let minute = (totalTime % 60);
                minute = parseInt(minute.toString());
                if (minute > 0) {
                    this.routeETATime = this.routeETATime + '' + minute + ' m';
                }
            } else {
                let hours = totalTime / 60;
                hours = parseInt(hours.toString());
                if (hours > 0) {
                    this.routeETATime = hours + ' h ';
                }
                let minute = (totalTime % 60);
                minute = parseInt(minute.toString());
                if (minute > 0) {
                    this.routeETATime = this.routeETATime + '' + minute + ' m';
                }
            }
        }
    }
    displayRoute() {
        let length = this.employeeMeetingList.length;
        this.employeeMeetingListDragable = this.defaultEmployeeMeeting;
        this.employeeMeetingListTemp = [];
        var _this = this;
        $('.route_input').each(function () {
            let id = $(this).attr('id');
            for (let i = 0; i < length; i++) {
                if (id == _this.employeeMeetingList[i]['id']) {
                    _this.employeeMeetingListTemp.push(_this.employeeMeetingList[i]);
                }
            }
        });
        this.employeeMeetingList = this.employeeMeetingListTemp;
        this.calculateAndDisplayRoute(this.directionsService, this.directionsDisplay);
    }
    deleteMarkers() {
        //Loop through all the markers and remove
        for (var i = 0; i < this.markers.length; i++) {
            this.markers[i].setMap(null);
        }
        this.markers = [];
    }
    locationDragable() {
        var _this = this;
        $("#sortable").sortable({
            placeholder: "ui-state-highlight",
            handle: ".dragdrop-handle",
            opacity: 0.5,
            cursor: "move",
            stop: function (event, ui) {
                _this.displayRoute();
            },
            update: function (event, ui) {
                $('.sort-order-value').each(function (index, value) {
                    if (0 == index) {
                        $(value).parent().find('.delete-handle').hide();
                    } else {
                        $(value).parent().find('.delete-handle').show();
                    }
                    $(value).val(index + 1);
                });
            }
        });
        $("#sortable").disableSelection();
    }
    deleteRoute(meetingId) {
        let meetingData = this.employeeMeetingList;
        if (meetingData.length > 2) {
            let index = this.meetingFormIds.indexOf(meetingId);
            this.meetingFormIds.splice(index, 1);
            $('#meeting-' + meetingId).prop('checked', false);
            this.employeeMeetingList = [];
            for (let i = 0; i < meetingData.length; i++) {
                if (meetingData[i]['id'] != meetingId) {
                    this.employeeMeetingList.push(meetingData[i]);
                }
            }
        } else {
            this.commonService.showCustomError('Minimum 2 point is required for route draw');
            return false;
        }
        this.meetingRouteLength = this.meetingFormIds.length;
        this.createMeetingRoot();
    }
    saveRoute() {
        let routeName = $('#route-name').val();
        this.errorMessage = '';
        this.errorRouteMessage = '';
        if ($.trim(routeName) == '') {
            this.errorMessage = "Please add route title name.";
            return false;
        }
        let employeeLen = this.routeCreateDetails.employee_name.length;
        if (employeeLen < 1) {
            this.errorEmployeeMessage = "Please select employee";
            return false;
        }
        let routeLen = this.employeeMeetingList.length;
        if (routeLen < 2) {
            this.errorRouteMessage = "Please add meeting first";
            return false;
        }
        let routeResult = {};
        routeResult['route_distance_km'] = (this.routeDistance) / 1000;
        routeResult['eta'] = this.routeETATime;
        routeResult['route_point'] = this.employeeMeetingList;
        routeResult['company_id'] = localStorage.getItem('company_id');
        routeResult['driving_mode'] = 'driving';
        routeResult['created_by'] = localStorage.getItem('user_id');
        routeResult['route_name'] = routeName;
        routeResult['employee_id'] = this.routeCreateDetails.employee_name[0]['id'];
        routeResult['start_date'] = this.startDate;
        routeResult['end_date'] = this.endDate;
        var _this1 = this;
        setTimeout(function () {
            _this1._routeService.createRoute(routeResult).subscribe(
                res => {
                    _this1.routeResult = res;
                    if (_this1.routeResult.result == true) {
                        localStorage.setItem('root_geofence_id', _this1.routeResult.data.id);
                        localStorage.setItem('update_route_id', _this1.routeResult.data.id);
                        localStorage.setItem('geofence', 'Add');
                        _this1.commonService.showSuccessMessage(res.message);
                        _this1.router.navigate(["route/create-geofence"]);
                    } else {
                        _this1.commonService.showCustomError(res.message);
                    }
                },
                error => {
                    this.customErrorHandler.handleHttpError(error, 'createRoute');
                });
        }, 500);
    }
    getLatLong(address, sequence) {
        var geocoder = new google.maps.Geocoder();
        var _this = this;
        geocoder = new google.maps.Geocoder();
        if (geocoder) {
            geocoder.geocode({
                'address': address
            }, function (results, status) {
                if (status == google.maps.GeocoderStatus.OK) {
                    var latitude = results[0].geometry.location.lat();
                    var longitude = results[0].geometry.location.lng();
                    var result = {
                        lat: latitude,
                        lng: longitude,
                        location: address,
                        sequence_order: sequence
                    }
                    _this.routeDrawResult.push(result);
                }
            });
        }
    }

    getLocationList() {
        this.commonService.getLocationList().subscribe(res => {
            if (res.result == true) {
                this.locationList = res.data;
                this.meetingSelectedDetails();
                // if (this.locationList.length > 1) {
                //     this.locationList.unshift(this.defaultSelectObject);
                // }
            }
        }, error => {
            this.customErrorHandler.handleHttpError(error, 'getRoleList');
        });
    }
    onLocationItemSelect(item: any) {
        this.locationId = item.id;
        this.employeeMemberList = [];
        this.routeCreateDetails.employee_name = [];
        this.employeeListFunction(this.locationId);
        this.routeCreateDetails.employee_name = [];
        this.routeCreateDetails.employee_name.push();
    }
    onLocationItemDeSelect(item: any) {
        this.locationId = '';
        this.meetingSelectFlag = false;
        this.employeeMemberList = [];
        this.routeCreateDetails.employee_name = [];
        // this.employeeListFunction(this.locationId);
        this.routeCreateDetails.employee_name = [];
        this.routeCreateDetails.employee_name.push();
    }
    employeeListFunction(locationId, defaultRoute = null) {
        this.meetingSelectFlag = false;
        this._routeService.employeeLocationList(locationId).subscribe(
            res => {
                this.employeeMemberList = res.data;
                if(defaultRoute == 'defaultRoute'){
                    let defaultEmpId = localStorage.getItem('meetingRouteEmpId');
                    let empTemp = this.employeeMemberList.find(s => s.id == defaultEmpId);
                    if (typeof empTemp != 'undefined' || empTemp != '') {
                        this.routeCreateDetails.employee_name = [];
                        this.routeCreateDetails.employee_name.push(empTemp);
                        this.meetingSelectFlag = true;
                        this.changesEmployeeDetails();
                        this.openMeetingModal();
                    } 
                }
            },
            error => {
                this.customErrorHandler.handleHttpError(error, 'employeeList');
            });
    }
    onOpen(event: any) {
        this.employeeMemberList = this.employeeMemberList.map((item) => {
            return {
                id: item.id,
                itemName: item.itemName
            }
        });
    }
    OnItemDeSelect(item: any) {
        this.routeCreateDetails = {}
        this.changesEmployeeDetails();
    }
    changesEmployeeDetails() {
        this.employeeMeetingMsg = '';
        let routeLen = this.employeeMeetingList.length;
        if (routeLen > 0) {
            this.directionsService = new google.maps.DirectionsService;
            this.directionsDisplay = new google.maps.DirectionsRenderer({
                suppressMarkers: true
            });
            this.map = new google.maps.Map(document.getElementById('map'), {
                zoom: 8,
                center: { lat: 22.7664707, lng: 75.8824308 },
                gestureHandling: 'cooperative',
                styles: Constants.MapStyle
            });
        }
        this.meetingFormIds = []
        this.employeeMeetingList = [];
        this.routeDistanceKm = 0;
        this.routeETATime = 0 + ' m';
        this.directionsDisplay.setMap(this.map);
        if (typeof this.routeCreateDetails.employee_name != 'undefined') {
            let employeeId = this.routeCreateDetails.employee_name[0].id;
            let parameter = { employee_id: employeeId, start_date: this.startDate, end_date: this.endDate }
            this._routeService.employeeMeeting(parameter).subscribe(
                res => {
                    this.defaultEmployeeMeeting = res.data;
                    this.employeeMeetingMsg = res.message;
                    this.meetingSelectFlag = true;
                })
        } else {
            this.defaultEmployeeMeeting = []
            this.meetingSelectFlag = false;
        }
    }
    meetingModalOpen() {
        this.meetingModal.style.display = "block";
    }
    meetingCloseModal() {
        this.errorMessage = '';
        this.meetingModal.style.display = "none";
    }
    openMeetingModal() {
        this.meetingModalOpen();
    }
    onChange(id: string, isChecked: boolean) {
        let checkValue = this.meetingFormIds.includes(id);
        if (checkValue == false && isChecked == true) {
            this.meetingFormIds.push(id);
        } else if (checkValue == true && isChecked == false) {
            let index = this.meetingFormIds.indexOf(id);
            this.meetingFormIds.splice(index, 1);
        }
        this.meetingRouteLength = this.meetingFormIds.length;
    }
    createMeetingRoot() {
        this.employeeMeetingList = [];
        this.meetingCloseModal();
        for (let i = 0; i < this.meetingFormIds.length; i++) {
            let id = this.meetingFormIds[i];
            for (let j = 0; j < this.defaultEmployeeMeeting.length; j++) {
                if (id == this.defaultEmployeeMeeting[j]['id']) {
                    this.employeeMeetingList.push(this.defaultEmployeeMeeting[j]);
                }
            }
        }
        this.calculateAndDisplayRoute(this.directionsService, this.directionsDisplay);
    }
    addMeetingPlace() {
        let routeName = this.routeCreateDetails.route_name;
        let employeeId = this.routeCreateDetails.employee_name[0].id;
        localStorage.setItem('mapProspectRouteName', routeName);
        localStorage.setItem('mapProspectRouteStartDate', this.startDate);
        localStorage.setItem('mapProspectRouteEndDate', this.endDate);
        localStorage.setItem('mapProspectRouteLocationId', this.locationId);
        localStorage.setItem('mapProspectRouteEmpId', employeeId);
        this.router.navigate(["meeting/meeting-list/create-map-meeting"]);
    }
    meetingSelectedDetails() {
        let meetingRouteName = localStorage.getItem('meetingRouteName');
        let meetingRouteStart = localStorage.getItem('meetingRouteStartDate');
        let meetingRouteEnd = localStorage.getItem('meetingRouteEndDate');
        let meetingRouteEmpId = localStorage.getItem('meetingRouteEmpId');
        let meetingRouteLocationId = localStorage.getItem('meetingRouteLocationId');

        if (meetingRouteStart && meetingRouteEnd && meetingRouteEmpId) {
            this.meetingEmpId = meetingRouteEmpId;
            this.routeCreateDetails.route_name = meetingRouteName;
            this.startDate = meetingRouteStart;
            this.startDateshow = moment(meetingRouteStart).format('MMMM D, YYYY');
            $('#reportrange span').html(this.startDateshow);
            this.endDate = meetingRouteEnd;
            this.endDateshow = moment(meetingRouteEnd).format('MMMM D, YYYY');
            $('#endDate span').html(this.endDateshow);
            this.routeCreateDetails.employee_name = [];
            this.routeCreateDetails.employee_name.push();
            if(meetingRouteLocationId){
                console.log(this.locationList,'route', meetingRouteLocationId )
                let assignLocation = this.locationList.find(s => s.id == meetingRouteLocationId);
                if (typeof assignLocation != 'undefined' || assignLocation != '') {
                    this.locationSelectedItem = [];
                    this.locationSelectedItem.push(assignLocation);
                }
                this.locationId = meetingRouteLocationId;
                this.employeeListFunction(this.locationId, 'defaultRoute');
            }
        }
    }
    ngOnDestroy() {
        localStorage.removeItem('meetingRouteName');
        localStorage.removeItem('meetingRouteStartDate');
        localStorage.removeItem('meetingRouteEndDate');
        localStorage.removeItem('meetingRouteEmpId');
    }
}
