import { AuthGuard } from './../../services/_guards/auth.guard';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { InfiniteScrollModule } from 'angular2-infinite-scroll';
import { MessageComponent } from './message.component';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown/multiselect.component';
import { PipeModule } from '../../pipe/pipe.module';
export const routes: Routes = [
  {
    path: 'message',
    component: MessageComponent, canActivate: [AuthGuard],
  }
];
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule.forChild(routes),
    InfiniteScrollModule,
    AngularMultiSelectModule,
    PipeModule.forRoot()
  ],
  declarations: [
    MessageComponent
  ]
})
export class MessageModule { }
