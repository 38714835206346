import { OnInit, Component } from "@angular/core";
declare var $: any;
@Component({
  selector: 'report-list',
  templateUrl: 'report-list.component.html',
  styleUrls: ['./report-list.component.css']
})
export class ReportListComponent implements OnInit {
  employeeLabel: string;
  prospectLabel: string;
  constructor() {
  }
  ngOnInit() {
    this.prospectLabel = localStorage.getItem('prospectLabel');
    this.employeeLabel = localStorage.getItem('employeeLabel');
  }
  ngOnDestroy() {
    $('.fullHeightBlock').animate({ scrollTop: 0 }, 400);
  }
}
