import { AuthGuard } from './../../services/_guards/auth.guard';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { CalendarModule } from 'primeng/calendar';
import { FormsModule } from '@angular/forms';
import { EventComponent } from './event.component';
import { CreateEventComponent } from './create-event/create-event.component';
import { EventListComponent } from './event-list/event-list.component';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown/angular2-multiselect-dropdown';
import { InfiniteScrollModule } from 'angular2-infinite-scroll';
import { PipeModule } from '../../pipe/pipe.module';
import { Ng2PaginationModule } from 'ng2-pagination';
export const routes: Routes = [
  {
    path: 'event',
    component: EventComponent, canActivate: [AuthGuard],
    children: [
      { path: '', redirectTo: 'event-list', pathMatch: 'full' },
      { path: 'event-list', component: EventListComponent },
      { path: 'create-event', component: CreateEventComponent },
    ]
  }
];
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule.forChild(routes),
    AngularMultiSelectModule,
    InfiniteScrollModule,
    PipeModule.forRoot(),
    CalendarModule,
    Ng2PaginationModule
  ],
  declarations: [
    EventComponent,
    CreateEventComponent,
    EventListComponent
  ]
})
export class EventModule { }
