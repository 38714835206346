import { Component, OnInit } from '@angular/core';
import { CustomErrorHandler } from './../../../errorhandler/custom-errorhandler';
import { DigitalFormService } from './../../../services/digital-form/digital-form.service';
@Component({
  selector: 'notification',
  templateUrl: 'notification.component.html',
  styleUrls: ['./notification.component.css']
})
export class NotificationComponent implements OnInit {
  errorMessage: string;
  disableButton: boolean;
  signin_btn_txt: string;
  sms: boolean;
  mail: boolean;
  push_noti: boolean;
  sms_value: string;
  mail_value: string;
  push_value: string;
  constructor(public digitalFormService: DigitalFormService, private customErrorHandler: CustomErrorHandler) {
    this.errorMessage = '';
    this.disableButton = false;
    this.signin_btn_txt = "Submit";
  }
  ngOnInit() {
    this.sms = false;
    this.mail = false;
    this.push_noti = false;
    this.notificationList();
  }
  filterData() {
    this.sms = !this.sms;
  }
  filterData1() {
    this.mail = !this.mail;
  }
  filterData2() {
    this.push_noti = !this.push_noti;
  }
  saveForm() {
    if (this.sms) {
      this.sms_value = '1';
    } else {
      this.sms_value = '0';
    }
    if (this.mail) {
      this.mail_value = '1';
    } else {
      this.mail_value = '0';
    }
    if (this.push_noti) {
      this.push_value = '1';
    } else {
      this.push_value = '0';
    }
    this.disableButton = true;
    this.errorMessage = '';
    this.signin_btn_txt = "Creating";
    this.digitalFormService.createNotification(this.sms_value, this.mail_value, this.push_value).subscribe(
      res => {
        if (res.result == true) {
          window.location.reload();
        } else {
          this.disableButton = false;
          this.signin_btn_txt = "Submit";
          this.errorMessage = res.message;
        }
      },
      error => {
        this.customErrorHandler.handleHttpError(error, 'createNotification');
        this.disableButton = false;
        this.signin_btn_txt = "Submit";
      });
  }
  notificationList() {
    this.digitalFormService.createNotificationList().subscribe(
      res => {
        if (res.result != 'Invalid Token') {
          this.sms = res.data.sms
          this.mail = res.data.email
          this.push_noti = res.data.push_noti;
        }
      },
      error => {
        this.customErrorHandler.handleHttpError(error, 'createNotificationList');
      });
  }
}
