import { Injectable } from '@angular/core';
import { HttpHelper } from '../../helpers/http.helper';
import { Config } from '../../config/config';
@Injectable()
export class EmployeeLeaderBoardService {
    constructor(private _httpHelper: HttpHelper) {
    }
    getLeaberBoardList(request) {
        try {
            // return this._httpHelper.get(Config.AuthenticationBaseURL1 + 'employee-ladderboard?' + request);
            return this._httpHelper.get(Config.AuthenticationBaseURL1 + 'get-employee-ladderboard?' + request);
        }
        catch (e) {
        }
    }
}
