import { Router, ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { CheckListService } from '../../../services/check_list/check_list.service';
import { CustomErrorHandler } from '../../../errorhandler/custom-errorhandler';
import { CommonService } from '../../../services/common/common.service';
declare var $: any;

@Component({
  selector: 'manage-check-list',
  templateUrl: 'manage-check-list.component.html',
  styleUrls: ['./manage-check-list.component.css'],
  host: {
    'class': 'fullHeightBlock'
  }
})

export class ManageCheckListComponent implements OnInit {
  isDemoTenant: boolean;
  loginFormEmail: string;
  status: string;
  errorMessage: string;
  disableButton: boolean;
  signin_btn_txt: string;
  loginDiv: boolean;
  isEdit: boolean;
  tenantList: any[];
  accessToken: string;
  image_url: string;
  title: string;
  form_category_field: Array<any> = [];
  id: string;
  errorMessageItem: any;
  constructor(
    private router: Router,
    public _CheckListService: CheckListService,
    private route: ActivatedRoute,
    private customErrorHandler: CustomErrorHandler,
    public commonService: CommonService
  ) {
    this.errorMessage = '';
    this.disableButton = false;
    this.signin_btn_txt = "Submit";
    this.loginDiv = true;
  }

  ngOnInit() {
    this.isDemoTenant = (localStorage.getItem('isDemoTenant') == 'true');
    this.form_category_field.push({ 'item_name': '' });
    this.id = this.route.snapshot.paramMap.get('id');
    if (this.id) {
      this.getCheckListById();
      this.isEdit = true;
    }
    else {
      this.isEdit = false;
    }
    if ($(window).width() > 760) {
      $('.fullHeightBlock').scrollbar();
    }
  }
  ngOnDestroy() {
    $('.fullHeightBlock').remove();
  }

  Addmore() {
    this.form_category_field.push({ 'item_name': '' });
    setTimeout(function () {
      $('input[name=icon_prefix-2]:last').focus();
    }, 200);
  }
  remove(index) {
    this.form_category_field.splice(index, 1);
  }
  getCheckListById() {
    if (this.id != '') {
      this._CheckListService.getCheckListById(this.id).subscribe(
        res => {
          if (res.result == true) {
            this.title = res.data.label;
            this.form_category_field = res.data.items;
          }
        },
        error => {
          this.customErrorHandler.handleHttpError(error, 'getCheckListById');
        }
      )
    }
  }

  saveForm() {
    this.errorMessage = '';
    if (this.title == '' || this.title == undefined || $.trim(this.title) == '') {
      this.errorMessage = "Title should not be blank";
      return false;
    }
    this.errorMessageItem = '';
    for (let i = 0; i < this.form_category_field.length; i++) {
      if (this.form_category_field[i]['item_name'] == '' || $.trim(this.form_category_field[i]['item_name']) == '') {
        this.errorMessageItem = 'Please fill item name';
        return false;
      }
    }

    let form_data = this.form_category_field;

    if (this.title == '' || this.title == undefined) {
      this.errorMessage = "Title Should not be blank";
      return false;
    }
    this.disableButton = true;
    this.errorMessage = '';
    this.signin_btn_txt = "Creating";
    if (this.isEdit == true) {
      this._CheckListService.editformCategory(this.id, this.title, form_data).subscribe(
        res => {
          if (res.result == true) {
            this.router.navigate(["checklist"]);
            this.commonService.showSuccessMessage(res.message);
          } else {
            this.disableButton = false;
            this.signin_btn_txt = "Submit";
            this.commonService.showErrorMessage(res);
          }
        },
        error => {
          this.customErrorHandler.handleHttpError(error, 'editformCategory');
          this.errorMessage = JSON.parse(error['_body']).MessageList[0];
          this.disableButton = false;
          this.signin_btn_txt = "Submit";
        }
      )
    }
    else {
      this._CheckListService.createformCategory(this.title, form_data).subscribe(
        res => {
          if (res.result == true) {
            this.commonService.showSuccessMessage(res.message);
            this.router.navigate(["checklist"]);
          } else {
            this.disableButton = false;
            this.signin_btn_txt = "Submit";
            this.commonService.showErrorMessage(res);
          }
        },
        error => {
          this.customErrorHandler.handleHttpError(error, 'createformCategory');
          this.errorMessage = JSON.parse(error['_body']).MessageList[0];
          this.disableButton = false;
          this.signin_btn_txt = "Submit";
        }
      )
    }
  }
}
