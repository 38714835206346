import { Injectable } from '@angular/core';
import { HttpHelper } from '../../helpers/http.helper';
import { Config } from '../../config/config';
const company_id = localStorage.getItem('company_id');
const user_id = localStorage.getItem('user_id');
@Injectable()
export class MeetingService {
    constructor(private _httpHelper: HttpHelper) {
    }
    prospectList(pageNo, rowPerPage, search, startDate, endDate, meetingType, employeeId, exportReport, reportingLocationId, reportingTo, meetingStatus) {
        try {
            return this._httpHelper.get(Config.AuthenticationBaseURL1 + 'get-all-meeting' + '?page=' + pageNo + '&per_page=' + rowPerPage + '&start_date=' + startDate + '&end_date=' + endDate + '&meeting_type=' + meetingType + '&employee_id=' + employeeId + '&search=' + search + '&export=' + exportReport + '&reporting_location_id=' + reportingLocationId + '&reporting_to=' + reportingTo+'&meeting_status='+meetingStatus);
        }
        catch (e) {
        }
    }

    getMeetingList(req) {
        try {
            return this._httpHelper.get(Config.AuthenticationBaseURL1 + 'get-all-meeting', req);
        }
        catch (e) {
        }
    }
    SelectProspectById(id) {
        try {
            var request = {
                "prospect_id": id
            };
            return this._httpHelper.post(Config.AuthenticationBaseURL + 'SelectProspectById', request)
        }
        catch (e) {
        }
    }
    prospectDelete(prospect_id: string) {
        try {
            var request = {
                "prospect_id": prospect_id
            };
            return this._httpHelper.post(Config.AuthenticationBaseURL + 'deleteProspect', request)
        }
        catch (e) {
        }
    }
    createProspect(name: string, lat: string, lng: string, area: string, title: string, is_same) {
        try {
            var request = {
                "name": name,
                "company_id": company_id,
                "created_by": user_id,
                "lat": lat,
                "lng": lng,
                "area_name": area,
                "title": title,
                'is_same': is_same
            };

            return this._httpHelper.post(Config.AuthenticationBaseURL1 + 'create-meeting', request)
        }
        catch (e) {
        }
    }
    employeeList() {
        try {
            var request = {
                "company_id": company_id,
                "location_id": ''
            };
            return this._httpHelper.get(Config.AuthenticationBaseURL1 + 'teamListMember', request)
        }
        catch (e) {
        }
    }
    employeeLocationList(lactionId) {
        try {
            var request = {
                "company_id": company_id,
                'location_id': lactionId
            };
            return this._httpHelper.get(Config.AuthenticationBaseURL1 + 'teamListMember', request)
        }
        catch (e) {
        }
    }
    teamList(locationId = null) {
        try {
            var request = {
                "company_id": company_id,
                "location_id" : locationId
            };
            return this._httpHelper.post(Config.AuthenticationBaseURL1 + 'teamListonly', request)
        }
        catch (e) {
        }
    }
    eventList(locationId = null) {
        try {
            var request = {
                "company_id": company_id,
                "location_id": locationId
            };
            return this._httpHelper.post(Config.AuthenticationBaseURL1 + 'eventListOnly', request)
        }
        catch (e) {
        }
    }
    geoList() {
        try {
            var request = {
                "company_id": company_id
            };
            return this._httpHelper.post(Config.AuthenticationBaseURL1 + 'geo-list-only', request)
        }
        catch (e) {
        }
    }
    routeList() {
        try {
            var request = {
                "company_id": company_id
            };
            return this._httpHelper.post(Config.AuthenticationBaseURL1 + 'route-list-only', request)
        }
        catch (e) {
        }
    }
    notificationList() {
        try {
            var request = {
                "company_id": company_id
            };
            return this._httpHelper.post(Config.AuthenticationBaseURL + 'notificationListOnly', request)
        }
        catch (e) {
        }
    }
    formList() {
        try {
            var request = {
                "company_id": company_id
            };
            return this._httpHelper.post(Config.AuthenticationBaseURL1 + 'form-template-list', request)
        }
        catch (e) {
        }
    }
    assignProspect(prospect_id: string, team: string, employee: string, event: string, route: string, notification: string, formtemplate: string, address: string, phone: string, email: string, contactPersonName1: string, contactPersonName2: string, contactPersonPhone1: string, contactPersonPhone2: string, industry: string, turnover: string, companySize: string, startDate: string, endDate: string, priority: string, meetingType: string, description: string, recurring: number, title: string, locationId: number) {
        try {
            var request = {
                "prospect_id": prospect_id,
                "company_id": company_id,
                "team": team,
                "employee": employee,
                "event": event,
                "route": route,
                "notification": notification,
                "formtemplate": formtemplate,
                "created_by": user_id,
                "address": address,
                "phone": phone,
                "email": email,
                "contact_person_name": contactPersonName1,
                "contact_person_phone": contactPersonPhone1,
                "contact_person_name1": contactPersonName2,
                "contact_person_phone1": contactPersonPhone2,
                "turnover": turnover,
                "industry_type": industry,
                "company_size": companySize,
                "start_date": startDate,
                "end_date": endDate,
                "priority": priority,
                "meeting_type": meetingType,
                "description": description,
                "recurring": recurring,
                "title": title,
                "location_id": locationId
            };
            return this._httpHelper.post(Config.AuthenticationBaseURL1 + 'assign-prospect', request)
        }
        catch (e) {
        }
    }
    editProspect(prospect_id: string, prospect_name: string, team: string, employee: string, event: string, route: string, notification: string, formtemplate: string, area_id: string, address: string, phone: string, email: string, contactPersonName1: string, contactPersonName2: string, contactPersonPhone1: string, contactPersonPhone2: string, industry: string, turnover: string, companySize: string, startDate: string, endDate: string, priority: string, meetingType: string, description: string, recurring: number, title: string, deleteMeetingFromRoute, isSame, locationId) {
        try {
            var request = {
                "prospect_id": prospect_id,
                "prospect_name": prospect_name,
                "area_id": area_id,
                "company_id": company_id,
                "team": team,
                "employee": employee,
                "event": event,
                "route": route,
                "notification": notification,
                "formtemplate": formtemplate,
                "created_by": user_id,
                "address": address,
                "phone": phone,
                "email": email,
                "contact_person_name": contactPersonName1,
                "contact_person_phone": contactPersonPhone1,
                "contact_person_name1": contactPersonName2,
                "contact_person_phone1": contactPersonPhone2,
                "turnover": turnover,
                "industry_type": industry,
                "company_size": companySize,
                "start_date": startDate,
                "end_date": endDate,
                "priority": priority,
                "meeting_type": meetingType,
                "description": description,
                "recurring": recurring,
                "title": title,
                "deleteMeetingFromRoute": deleteMeetingFromRoute,
                "is_same": isSame,
                "location_id": locationId
            };
            return this._httpHelper.post(Config.AuthenticationBaseURL1 + 'edit-assign-prospect', request)
        }
        catch (e) {
        }
    }
    priorityList() {
        try {
            return this._httpHelper.get(Config.AuthenticationBaseURL1 + 'get-meeting-priority');
        }
        catch (e) {
        }
    }
    meetingTypeList() {
        try {
            return this._httpHelper.get(Config.AuthenticationBaseURL1 + 'get-meeting-type');
        }
        catch (e) {
        }
    }
    getLocationList() {
        try {
            return this._httpHelper.get(Config.AuthenticationBaseURL1 + 'get-company-location');
        }
        catch (e) {
        }
    }
    checkMeetingInRoute(meetingId, startDate, endDate, employee, oldEmployeeIds) {
        try {
            return this._httpHelper.get(Config.AuthenticationBaseURL1 + 'check-meeting-in-route' + '?id=' + meetingId + '&start_date=' + startDate + '&end_date=' + endDate + '&employee=' + employee + '&old_employee=' + oldEmployeeIds);
        }
        catch (e) {
        }
    }

    getMeetingTypeById(request){
        try {
            return this._httpHelper.get(Config.AuthenticationBaseURL1 + 'get-single-meeting-type', request);
        }
        catch (e) {
        }
    }

    updateMeetingType(request) {
        try {
            return this._httpHelper.put(Config.AuthenticationBaseURL1 + 'edit-meeting-type', request);
        }
        catch (e) {
        }
    }

    deleteMeetingTypeById(request){
        try {
            return this._httpHelper.delete(Config.AuthenticationBaseURL1 + 'delete-meeting-type', request);
        }
        catch (e) {
        }
    }

    addMeetingType(request) {
        try {
            return this._httpHelper.post(Config.AuthenticationBaseURL1 + 'add-meeting-type', request);
        }
        catch (e) {
        }
    }
}
