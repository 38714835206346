import { EmployeeService } from './../../../services/employee/employee.service';
import { ComplaintService } from './../../../services/complaint/complaint.service';
import { Config } from './../../../config/config';
import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { FileUploader } from 'ng2-file-upload';
import { CropperSettings } from 'ng2-img-cropper';
import { CustomErrorHandler } from './../../../errorhandler/custom-errorhandler';
import { CommonService } from '../../../services/common/common.service';
import { Constants } from '../../../common/constants';
declare var moment: any;
@Component({
  selector: 'edit-complaint',
  templateUrl: 'edit-complaint.component.html',
  styleUrls: ['./edit-complaint.component.css']
})
export class EditComplaintComponent implements OnInit {
  imageBaseUrl: string = Config.AuthenticationURL;
  user_id: string;
  employeeLabel: string;
  loginFormEmail: string;
  status: string;
  errorMessage: string;
  disableButton: boolean;
  signin_btn_txt: string;
  loginDiv: boolean;
  tenantList: any[];
  role: any[];
  accessToken: string;
  image_url: string;
  name: string;
  emp_id: string;
  email: string;
  pwd: string;
  mobile_no: string;
  gender: string = 'Male';
  dob: any;
  user_type: string = '0';
  employee_id: string;
  modal: any;
  uploadedImage: any = '';
  currentImage: any;
  data: any;
  cropperSettings: CropperSettings;
  pageHeader: string;
  uploadImageText: string
  lat = '';
  lng = '';
  createProspect = false;
  isAnnouncement = false;
  prospectLabel: string;
  disableEmail: boolean = true;
  passwordRequired: boolean = true;
  URL = Config.AuthenticationBaseURL + 'upload_attached';
  uploader: FileUploader = new FileUploader({ url: this.URL });
  minDateDOB: any;
  maxDateDOB: any
  emailVal = '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$';
  employeeMemberList = [];
  employeeMemberSettings = {};
  employeeMemberItem = [];
  locationList: any = [];
  locationSettings: any = {};
  locationSelectedItem: any = [];
  locationId: any = '';
  hierarchyId: any;
  roleType: any = '';
  checkAnnouncement = localStorage.getItem('isAnnouncement') == '1' ? true : false;
  locationLabel: any = Constants.locationLabel;
  selectedLocation = '';
  selectedReporting = '';
  isEdit:any;
  isPrimary: boolean = false;
  isLoginPrimary: any = 0;
  editLoggedInEmp: any = '';
  yearRange:any;
  age : string;
  voter_id : string;
  address: string;
  constituency : string;
  message: string;
  note : string;
  source_type: string;
  constructor(
    public router: Router,
    private commonService: CommonService,
    public _EmployeeService: EmployeeService,
    private customErrorHandler: CustomErrorHandler,
    public _ComplaintService: ComplaintService
  ) {
    this.errorMessage = '';
    this.disableButton = false;
    this.signin_btn_txt = "Submit";
    this.loginDiv = true;
    this.isEdit = localStorage.getItem('createEmployee');
    var _this = this;
    this.uploader.onAfterAddingFile = function (file) {
      file.withCredentials = false;
      file.upload();
    };
    this.uploader.onCompleteItem = function (item, response, status, headers) {
      if (status == 200) {
        var res = JSON.parse(response);
        if (res.results == true) {
          _this.image_url = res.url;
        }
      }
    }
    //image cropper
    this.cropperSettings = new CropperSettings();
    this.cropperSettings.rounded = true;
    this.cropperSettings.croppedWidth = 200;
    this.cropperSettings.croppedHeight = 200;
    this.data = {};
    this.employeeMemberSettings = {
      singleSelection: true,
      text: "Select Reporting Person",
      enableSearchFilter: true,
      classes: "singleSelection"
    };
    this.locationSettings = {
      singleSelection: true,
      text: "Select " + this.locationLabel,
      enableSearchFilter: true,
      classes: "singleSelection"
    };
    this.getRoleList();
  }
  ngOnInit() {
    this.getYearRange();
    let editEmpId = localStorage.getItem('employeeIdAddBranchOffice1');
    if(editEmpId){
      localStorage.setItem('employee_id', editEmpId);
    }
    this.isLoginPrimary = localStorage.getItem('isLoginPrimary');
    this.getLocationList();
    this.minDateDOB = new Date();
    this.maxDateDOB = new Date();
    this.prospectLabel = localStorage.getItem('prospectLabel');
    this.user_id = localStorage.getItem('user_id');
    this.employeeLabel = localStorage.getItem('employeeLabel');
    this.modal = document.getElementById('uploadModel');
    this.employee_id = localStorage.getItem('employee_id');
    this.source_type = 'Not Started';
    let employeeToEdit = localStorage.getItem('complaint_id');
    let loggedInEmployeeToedit = localStorage.getItem('editLoggedInUser');
    let navigateTocreateEmployee = localStorage.getItem('createComplaint');
    this.editLoggedInEmp = loggedInEmployeeToedit;
    setTimeout(() => {
        this.source_type = 'Social Media';
        this.status = 'Not Started';
    });
    if (loggedInEmployeeToedit == 'Yes') {
      this.employee_id = this.user_id;
      this.passwordRequired = false;
      this.pageHeader = 'Update Complaint';
      this.uploadImageText = 'Update Profile Picture';
      let loggedInUserId = localStorage.getItem('user_id');
      setTimeout(() => {
        this.getComplaintById(loggedInUserId);
      });
      
    }
    else if (navigateTocreateEmployee == 'Yes') {
      this.disableEmail = false;
      this.pageHeader = 'Create Complaint';
    }
    else if (employeeToEdit != null) {
      this.pageHeader = 'Update Complaint';
      this.passwordRequired = false;
      this.uploadImageText = 'Update Profile Picture';
      setTimeout(() => {
        this.getComplaintById(employeeToEdit);
      }, 500);
      
    }
  }
  
  roleList() {
    this._EmployeeService.roleList().subscribe(
      res => {
        if (res.result == true) {
          this.role = res.data;
        }
      },
      error => {
        this.customErrorHandler.handleHttpError(error, 'roleList');
      });
  }
  getComplaintById(complaintId) {
    this._ComplaintService.getComplaintById(complaintId).subscribe(
      res => {
        if (res.result == true) {
          this.name = res.data.name;
          this.email = res.data.email;
          this.mobile_no = res.data.phone_number;
          this.gender = res.data.gender;
          this.age = res.data.age;
          this.address = res.data.address;
          this.voter_id = res.data.voter_id;
          this.constituency = res.data.constituency;
          this.message = res.data.message;
          this.source_type = res.data.source_type;
          this.status = res.data.status;
          this.note = res.data.note;
        }
      },
      error => {
        this.customErrorHandler.handleHttpError(error, 'employeeById');
      });
  }
  onSubmit(empManagmentForm) {
    if (empManagmentForm.invalid) {
      this.errorMessage = "Please complete all the mandatory fields.";
      return false;
    }
    if (this.name == '' || this.name == undefined) {
      this.errorMessage = "Name is required";
      return false;
    }
    this.disableButton = true;
    this.errorMessage = '';
    
    let create = localStorage.getItem('createComplaint');
    console.log(create);
    if (create == 'Yes') {
      this.signin_btn_txt = "creating..";
      let checked = "0";
      if (this.createProspect) {
        checked = "1";
      }
      else {
        checked = "0";
      }
      this._ComplaintService.createComplaint(this.name, this.email, this.mobile_no, this.gender, this.age, this.address, this.voter_id, this.constituency, this.source_type, this.message, this.status, this.note).subscribe(
        res => {
          if (res.result == true) {
            this.commonService.showSuccessMessage(res.message);
            this.router.navigate(["setting/complaint"]);
          } else {
            this.disableButton = false;
            this.signin_btn_txt = "Submit";
            this.commonService.showErrorMessage(res);
          }
        },
        error => {
          this.customErrorHandler.handleHttpError(error, 'createEmployee');
          this.errorMessage = 'Something went wrong, please try again later.'; // JSON.parse(error['_body']).MessageList[0];
          this.disableButton = false;
          this.signin_btn_txt = "Submit";
        });
    }
    else {
      this.signin_btn_txt = "updating..";
      let userid: string;
      let complaintId: string;
      let loggedInEmployeeToedit = localStorage.getItem('editLoggedInUser');
      if (loggedInEmployeeToedit == 'Yes') {
        userid = localStorage.getItem('user_id');
      }
      complaintId = localStorage.getItem('complaint_id');
      let checked = "0";
      if (this.createProspect) {
        checked = "1";
      }
      this._ComplaintService.updateComplaint(complaintId,this.name, this.email, this.mobile_no, this.gender, this.age, this.address, this.voter_id, this.constituency, this.source_type, this.message, this.status, this.note).subscribe(
        res => {
          if (res.result == true) {
            this.commonService.showSuccessMessage(res.message);
            if (loggedInEmployeeToedit == 'Yes') {
              this.router.navigate(["dashboard"]);
              setTimeout(() => {
                window.location.reload();
              });
            } else {
              this.router.navigate(["setting/complaint"]);
            }
          } else {
            this.disableButton = false;
            this.signin_btn_txt = "Submit";
            this.commonService.showErrorMessage(res);
          }
        },
        error => {
          this.customErrorHandler.handleHttpError(error, 'updateEmployee');
          this.disableButton = false;
          this.signin_btn_txt = "Submit";
        });
    }
  }
  
  resetToDefault() {
    this.data.image = null;
  }
  saveCropppedImage(e) {
    let base64: string;
    base64 = <string>this.data.image;
    base64 = base64.split(/,/)[1];
    this._EmployeeService.uploadProfileImage(base64).subscribe(
      res => {
        if (res.results == true) {
          this.uploadedImage = res.url;
          this.image_url = res.url;
        }
      },
      error => {
        this.customErrorHandler.handleHttpError(error, 'uploadProfile');
      }
    );
    //closing the modal popUp.
    this.modal.style.display = "none";
  }
  touchFunction() {
    this.errorMessage = '';
  }
  onClicked(event) {
    if (event.target.checked == true) {
      this.createProspect = true;
    }
    else {
      this.createProspect = false;
    }
  }
  onAnnouncement(event) {
    if (event.target.checked == true) {
      this.isAnnouncement = true;
    }
    else {
      this.isAnnouncement = false;
    }
  }
  checkDateOfBirth() {
    if (this.dob != null && this.dob != '') {
      let birthYear = moment(this.dob).format('YYYY');
      let currentYear = moment().format('YYYY');
      if (birthYear > currentYear || birthYear < 1900) {
        this.dob = null;
      }
      console.log('date', this.dob);
    }
  }
  getRoleList() {
    let empId = localStorage.getItem('employee_id');
    if(empId == null || empId == undefined){
      empId = '';
    }
    let parameterReq = {employee_id: empId}
    this._EmployeeService.getUserRoleList(parameterReq).subscribe(res => {
      if (res.result == true) {
        this.employeeMemberList = res.data.filter(ele => ele.id != this.employee_id);
      }
    }, error => {
      this.customErrorHandler.handleHttpError(error, 'getRoleList');
    });
  }
  getLocationList() {
    this._EmployeeService.getLocationList().subscribe(res => {
      if (res.result == true) {
        this.locationList = res.data;
      }
    }, error => {
      this.customErrorHandler.handleHttpError(error, 'getRoleList');
    });
  }

  addBranchOffice(){
    console.log(this.employeeMemberItem, this.locationSelectedItem)
    localStorage.setItem('addBranchOfficeFromEmployee','employee');
    let editAdmin = localStorage.getItem('editLoggedInUser');
    if(editAdmin == 'Yes'){
       localStorage.setItem('addBranchOfficeEditLoggedInUser','Yes');
    }
    this.router.navigate(["locations/add-location"]);
  }

  getYearRange() {
    let cutrrentYear = new Date();
    this.yearRange = '1980:' + cutrrentYear.getFullYear();
  }

  ngOnDestroy() {
    localStorage.removeItem('editLoggedInUser');
    localStorage.removeItem('complaint_id');
    localStorage.removeItem('createComplaint');
  }
}
