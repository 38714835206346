import { OnInit, Component } from "@angular/core";
import { ReportService } from '../../../services/report/report.service';
import { Angular2Csv } from 'angular2-csv/Angular2-csv';
import { CustomErrorHandler } from '../../../errorhandler/custom-errorhandler';
import { CommonService } from '../../../services/common/common.service';
declare var $: any;
@Component({
  selector: 'app-age-gender-wise-voter-list',
  templateUrl: './age-gender-wise-voter-list.component.html',
  styleUrls: ['./age-gender-wise-voter-list.component.css']
})
export class AgeGenderWiseVoterListComponent implements OnInit {
  isDemoTenant: boolean;
  loading: boolean;
  reportList: any = [];
  page: number = 2;
  pageNo: number = 1;
  perPage: number = 10;
  totalItem: number = 0;
  exportReport: any;
  noDataMsg: any;
  searchRecord: any = '';
  maxPage: number = 10;
  tempSearch: any;
  constructor(private commonService: CommonService, private reportService: ReportService, private customErrorHandler: CustomErrorHandler) {
  }
  ngOnInit() {
    this.isDemoTenant = (localStorage.getItem('isDemoTenant') == 'true');
    this.loading = false;
    this.page = 1;
    this.tempSearch = '';
    this.reportList = [];
    this.getReportList(1);
  }
  search() {
    if (this.tempSearch != this.searchRecord) {
      this.getReportList(1);
    }
    this.tempSearch = this.searchRecord;
  }
  getReportList(currentPage) {
    this.pageNo = currentPage;
    this.page = currentPage;
    this.noDataMsg = '';
    this.exportReport = '';
    this.loading = true;
    this.reportService.ageGenderWiseVoterReport(this.pageNo, this.perPage, this.searchRecord, this.exportReport).subscribe(
      res => {
        this.loading = false;
        if (res.result == true) {
          this.totalItem = res.data.total;
          this.reportList = res.data.data;
          this.noDataMsg = res.message;
        } else {
          this.reportList = [];
          this.noDataMsg = res.message;
          this.commonService.showErrorMessage(res);
        }
      },
      error => {
        this.loading = false;
        this.customErrorHandler.handleHttpError(error, 'topPerformingEmployee');
      });
  }
  export() {
    var options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: true,
      useBom: true,
      title: 'Age & Gender wise voter list'
    };
    this.exportReport = 'yes';
    this.reportService.ageGenderWiseVoterReport(this.pageNo, this.perPage, this.searchRecord, this.exportReport).subscribe(
      res => {
        new Angular2Csv(res.data, 'Age & Gender wise voter list', options);
      },
      error => {
        this.customErrorHandler.handleHttpError(error, 'topPerformingEmployee');
      });
  }
  ngOnDestroy() {
    $('.fullHeightBlock').animate({ scrollTop: 0 }, 400);
  }
}
