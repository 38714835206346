
export class Config {
    public static BasecampScoutWebURL: string = '';
    public static AuthenticationBaseURL: string = '';
    public static AuthenticationBaseURL1: string = '';
    public static AuthenticationURL: string = '';
    public static ImageDownloadURL: string = '';
    public static imageBaseUrlCI: string = '';
    public static MapAPIKey: string = 'AIzaSyCV4ZaeaPt4beU4ASBX9fDhffkC1rHujAM';//'AIzaSyCxLditjHaOvwIPDJZf9Ofa6V6iftCFRuU';//
    public static MapCenter = { lat: 22.7664707, lng: 75.8824308 };
    // public static SocketUrl: string = 'http://scoutdev.basecampscout.in:5000'; // 5000 for dev and 5500 for QA
    // public static SocketUrl: string = 'http://scoutqa.basecampscout.in:5500';
    public static SocketUrl: string = 'https://live.basecampscout.com:4000';
    public static FirebaseConfig = {
        apiKey: "AIzaSyCV4ZaeaPt4beU4ASBX9fDhffkC1rHujAM",
        // authDomain: "fleetpro-24da3.firebaseapp.com",
        databaseURL: "https://basecampscout-784d8.firebaseio.com",
        projectId: "basecampscout-784d8",
        // storageBucket: "fleetpro-24da3.appspot.com",
        messagingSenderId: "332673924490"
    };
    constructor() { }
}