import { CommonService } from './../../services/common/common.service';
import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { DashboardService } from '../../services/dashboard/dashboard.service';
import { BaseChartDirective } from 'ng2-charts';
import { CustomErrorHandler } from '../../errorhandler/custom-errorhandler';
import { Config } from '../../config/config';
// import { ViewEncapsulation } from '@angular/compiler/src/core';
declare var $: any;
@Component({
  selector: 'dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
  host: {
    'class': 'fullHeightBlock'
  },
  // encapsulation: ViewEncapsulation.None,
})

export class DashboardComponent implements OnInit {
   voterList:boolean = localStorage.getItem('VoterList') == 'true';
   imageBaseUrl:string = Config.AuthenticationURL;
  @ViewChild(BaseChartDirective) chart: BaseChartDirective;
   dashboardData:any;
  prospectLabel: string;
  actionLable: string;
   employeeLabel: string;
   intrestedProspects: number;
   prospectsCovered: number;
   totalProspects: number;
   employeesOnField: number;
   totalEmployees: number;
   prospectData: number;
   todaysDueMeetings:any =[];
   dough:Boolean = false;
  // private legend:any = { display: false, fullWidth: false};
   barChartOptions: any = {
    scaleShowVerticalLines: true,
    responsive: true,
    animationEasing: "easeOutBounce",
    pointLabelFontSize: 12,
    // legend:this.legend,
    lineTension: 0,
    maintainAspectRatio:false,
    legend: {
      onClick: (e) => e.stopPropagation()
    },
    scales: {
      yAxes: [{ ticks: {min: 0}}]
    }
    // maintainAspectRatio: false,
  };
   barChartType: string = this.voterList?'line': 'bar';
   barChartLegend: boolean = true;
   barChartData: any[] = [{ data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], label: 'Month' }];
   barChartLabels: string[] = ['jan ', 'feb', 'mar', 'apr', 'may', 'jun','jul', 'aug', 'sep', 'oct', 'nov', 'dec'];
   myColors = [
    {
      backgroundColor: '#86c7f3',
      borderColor: '#264E94',
      pointBackgroundColor: '#003082',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(148,159,177,0.8)'
    }
  ];

  DEFAULT_COLORS = ['#ff5077', '#ffa121', '#3595cc', '#a022ff', '#61bf3a', '#ecc91d', '#ec731d', '#2f7ed8', '#0d233a', '#8bbc21', '#910000', '#1aadce', '#492970', '#f28f43', '#77a1e5', '#c42525', '#a6c96a', '#3366CC', '#DC3912', '#FF9900', '#109618', ]
  myColors1 = [
    {
      backgroundColor: this.DEFAULT_COLORS
    }
  ];
  
  doughnutChartMessage: any = '';
  voterDashboardData: any = [];
  dashboardRecord: any;
  isImagesModel: boolean;
  images: any = [];
  isAttachmentModel: boolean;
  isPerformingModel: boolean;
  attachmentDetail:any = {};
  topTeamEmployeeList: any = [];
  performingModelLabel:any;
  activeUsersData: any = [];
  barOptions = {};
  noDoughnut:boolean = true;
  galleryNextBtn : any;
  topTeamOwnerList: any = {};

  constructor(
    private dashboardService: DashboardService, 
    private customErrorHandler:CustomErrorHandler, 
    private commonservice:CommonService
  ) {}

  ngOnInit() {
    this.galleryNextBtn = document.getElementsByClassName('ui-galleria-nav-next');
    this.actionLable = localStorage.getItem('actionLabel');
    if(this.actionLable==null)
    {
      this.actionLable='Action'
    }
    this.employeeLabel = localStorage.getItem('employeeLabel');
    if(this.employeeLabel==null)
    {
      this.employeeLabel='Employee(s)'
    }
    this.prospectLabel = localStorage.getItem('prospectLabel');
    if(this.prospectLabel==null)
    {
      this.prospectLabel='Meeting(s)'
    }
    this.getDashboardDetails();
    this.getDashboardData();
    // this.graphData();
    // this.getTodaysDueMeetings();
    // let clearint = setInterval(()=>{
    //   if($('.empl_list').length > 0){
    //     $('.empl_list').niceScroll();
    //     $('.fullHeightBlock').niceScroll();
    //     clearInterval(clearint);
    //   }
    // },100)
        if($(window).width()>760){
          $('.fullHeightBlock').scrollbar();
          $('#meet-listing').scrollbar();
          var interv = setInterval(()=>{
            if($('.empl_list').length > 0){
              setTimeout(()=>{
                $('.empl_list').scrollbar();
              },500)
              clearInterval(interv);
            }
          },1000);
        }

    if (this.voterList) {
      this.getVoterDashboardDetails();
    }

    this.activeUsersData = {
      labels: [''],
      datasets: [
          {
              backgroundColor: this.DEFAULT_COLORS,
              data: [0]
          }
      ]
    }
    
    this.barOptions = {
      responsive: true,
      maintainAspectRatio: true,
      legend: {
        display: false
      }, scales: {
        yAxes: [{
            ticks: {
                beginAtZero: true
            }
        }],            
        xAxes: [{
          stacked: false,
          beginAtZero: true,
          ticks: {
              stepSize: 1,
              min: 0,
              autoSkip: false
          }
        }]
      }
    };
  }
  ngOnDestroy() {
    $('.fullHeightBlock').remove();
  }

  getDashboardData(){
    this.dashboardService.getDashboardData().subscribe(
      res => {
        setTimeout(()=>{
          let sideHeight = $('#card-wrapper').outerHeight();
          let outerBlock = $('.scroll-wrapper.fullHeightBlock').outerHeight() -20;
          if(sideHeight > outerBlock){
            $('#main_blockwrap').css('max-height',sideHeight + 'px');
            $('#main_block').scrollbar();
          }
          else{
            $('#main_blockwrap').css('max-height',outerBlock + 'px');
            $('#main_block').scrollbar();

          }
        },1000)
        if (res.result == true) {
          this.dashboardData = res.data;
          if(!this.voterList){
            // this.barChartDataFunction(this.dashboardData.intrested_meeting_graph);
          }        
        } else {
          this.commonservice.showErrorMessage(res);
        }
      },
      error => {
        this.customErrorHandler.handleHttpError(error, 'getDashboardData');
      }
    );
  }
 
  barChartDataFunction(prospectDataLocal) {
    let chartdata: number[] = [];
    let chartLabel: string[] = [];
    this.barChartLabels = [];
    for (let i = 0; i < prospectDataLocal.length; i++) {
      chartdata.push(parseInt(prospectDataLocal[i].intrested));
      chartLabel.push(prospectDataLocal[i].month);
    }
    this.barChartLabels = chartLabel;
    if(this.voterList){
      this.barChartData = [{ data: chartdata, label: 'Date' }];
    }else{
      this.barChartData = [{ data: chartdata, label: 'Month' }];
    }
    // setTimeout(() => {
    //   if (this.chart && this.chart.chart && this.chart.chart.config) {
    //     this.chart.chart.config.data.labels = this.barChartLabels;
    //     this.chart.chart.update();
    //   }
    // });
  }

   // Doughnut
   
  doughnutChartData: number[] = [];
  doughnutChartLabels: string[] =  [];//['connect', 'batchmaster'];
  doughnutChartType: string = 'doughnut';
  doughnutChartOptions: any = {
     animation: false,
     responsive: true,
     maintainAspectRatio: false,
     legend: {
      display: false,
       position: 'bottom',
       horizontalAlign: "right",
       verticalAlign: "right",
       onClick: (e) => e.stopPropagation()
     }
  }

  // graphData() {
  //   this.commonservice.doughnutChartData().subscribe(
  //     res => {
  //         this.dough = true;
  //       if (res.success == true) {
  //         this.doughnutChartData =res.data;
  //         this.doughnutChartLabels = res.label;
  //         this.doughnutChartMessage = res.message;
  //       }
  //     },
  //     error => {
  //       this.customErrorHandler.handleHttpError(error, 'graphData');
  //     });
  // }

  // getTodaysDueMeetings(){
  //   this.dashboardService.getTodaysDueMeetings().subscribe(
  //     res => {
  //       if (res.result == true) {
  //         this.todaysDueMeetings = res;
  //       } else {
  //         this.commonservice.showErrorMessage(res);
  //       }
  //     },
  //     error => {
  //       this.customErrorHandler.handleHttpError(error, 'getTodaysDueMeetings');
  //     });
  // }  

  getVoterDashboardDetails() {
    this.dashboardService.getVoterDashboardDetails().subscribe(
      res => {
        if (res.result == true) {
          this.voterDashboardData = res.data;
          // this.barChartDataFunction(this.voterDashboardData.monthwise_verify_voter);
        } else {
          this.commonservice.showErrorMessage(res);
        }
      },
      error => {
        this.customErrorHandler.handleHttpError(error, 'getTodaysDueMeetings');
      });
  }

  renderDoughnutChart(data) {
    let chartdata: number[] = [];
    let chartLabel: string[] = [];
    this.doughnutChartLabels = [];
    Object.keys(data).forEach(key => {
      chartLabel.push(key);
      chartdata.push(data[key])
    });
    this.doughnutChartData = chartdata;
    for(let i = 0; i < this.doughnutChartData.length; i ++){
      if(this.doughnutChartData[i] != 0){
        console.log('hello');
        this.noDoughnut = false;
      }
    }
    console.log('asdsada',this.doughnutChartData);
    console.log('yes',chartdata)
    this.doughnutChartLabels = chartLabel;
  }

  

  renderActiveUsersGraph(data) {
    let locationNames: any = [];
    let activeCounts: any = [];
    data.forEach(element => {
      locationNames.push(element.location_name);
      activeCounts.push(element.active_count);
    });
    this.activeUsersData = {
        labels: locationNames,
        datasets: [
            {
                backgroundColor: this.DEFAULT_COLORS,
                data: activeCounts
            }
        ]
    }
  }

  getDashboardDetails() {
    this.dashboardService.getDashboardDetails().subscribe(res => {
      if (res.result == true) {
        this.dough = true;
        this.dashboardRecord = res.data
        this.renderDoughnutChart(this.dashboardRecord.location_wise_user);
        this.barChartDataFunction(this.dashboardRecord.meeting_graph_data);
        this.renderActiveUsersGraph(this.dashboardRecord.location_wise_user_graph);
      } else {
        this.commonservice.showErrorMessage(res);
      }
    }, err => {
      this.customErrorHandler.handleHttpError(err, 'getDashboardDetails');
    });
  }

  openImageModal(data) {
    this.isImagesModel = true;
    this.images = [];
    data.forEach((element, idx) => {
      let imgObj:any = {};
      imgObj.source = this.imageBaseUrl +element;
      imgObj.title = 'Image ' + (idx + 1);
      this.images.push(imgObj);
    });
    setTimeout(() => {
      if (this.images.length == 1) {
        this.galleryNextBtn[0].style.display = 'none';
      } else {
        this.galleryNextBtn[0].style.display = 'block';
      }
    }, 100);
  }

  closeImageModal() {
    this.images = [];
    this.isImagesModel = false;
  }

  onImageChange( event: any ) {
    let lastIdx = this.images.length-1;
    if (event.index == lastIdx) {
      $('.ui-galleria-nav-next').css('display', 'none');
    } else {
      $('.ui-galleria-nav-next').css('display', 'block');
    }
  }

  openModal(modalType, data) {
    
    if (modalType == 'Attachments') {
      this.isAttachmentModel = true;
      this.attachmentDetail = data;
    } else if (modalType == 'Performing') {
      this.isPerformingModel = true;
      this.performingModelLabel = data.team_name;
      this.topTeamEmployeeList = data.employee;
      this.topTeamOwnerList = data.owner;
      console.log('data',data);
    }
  }

  closeModal() {
    this.attachmentDetail = {};
    this.topTeamEmployeeList = [];
    this.isAttachmentModel = false;
    this.isPerformingModel = false;
    this.performingModelLabel = '';
  }
}


