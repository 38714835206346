import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Angular2Csv } from 'angular2-csv/Angular2-csv';
import { CustomErrorHandler } from '../../../errorhandler/custom-errorhandler';
import { Config } from './../../../config/config';
import { DocumentLibraryService } from './../../../services/document-library/document-library.service';
import { Constants } from './../../../common/constants';
import swal from 'sweetalert2';
import { CommonService } from '../../../services/common/common.service';
@Component({
  selector: 'app-document-list',
  templateUrl: './document-list.component.html',
  styleUrls: ['./document-list.component.css']
})
export class DocumentListComponent implements OnInit {
  isExport: boolean;
  isDemoTenant: boolean;
  prospectLabel: string;
  employeeName: any;
  message: any;
  pageNo: number = 1;
  perPage: number = 9;
  totalItem: number = 0;
  documentList: any = [];
  page: number = 2;
  startDate: any;
  endDate: any;
  search: any;
  export: any;
  serverUrl: any;
  teamId: any;
  teamList: any = [];
  teamSelectedItems = [];
  errorMessage: any;
  employeeLabel: any;
  imageurl: any;
  teamSettings = {};
  deleteDocumentRequest: any = {};
  loading: any;
  searchTextValue = '';
  tempSearch = '';
  categoryId = ''
  filterLabel = Constants.filterLabel;
  categorySettings: any = {};
	categorySelectedItems: any = [];
  categoryList: any = [];
  defaultSelectObject: any = { category_name: '', id: '', itemName: 'All' }
  constructor(private router: Router, public _documentLibraryService: DocumentLibraryService, public commonService: CommonService, private customErrorHandler: CustomErrorHandler) {
  }
  ngOnInit() {
    this.imageurl = Config.imageBaseUrlCI;
    this.search = '';
    this.getCategoryList();
    this.teamSettings = {
      singleSelection: true,
      text: "Select Team",
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      enableSearchFilter: true,
      classes: "myclass custom-class singleSelection"
    };
    this.categorySettings = {
      singleSelection: true,
      text: 'Select Category',
      enableSearchFilter: true,
      classes: "myclass custom-class singleSelection block_elem m_b_15"
    }; 
    this.employeeLabel = localStorage.getItem('employeeLabel');
    this.isExport = false;
    this.isDemoTenant = (localStorage.getItem('isDemoTenant') == 'true');
    this.message = true;
    this.serverUrl = Config.imageBaseUrlCI;
    this.getDocumentReport(1);
  }
  getDocumentReport(currentPage) {
    this.loading = true;
    this.isExport = false;
    this.teamId = '';
    this.pageNo = currentPage;
    this.page = currentPage;
    this.message = '';
    console.log(this.categoryId);
    let queryData = {
      'page': this.pageNo,
      'per_page': this.perPage, 
      'search': this.searchTextValue, 
      'export': '',
      'category_id': this.categoryId
    }
    this._documentLibraryService.getDocument(queryData).subscribe(
      res => {
        this.loading = false;
        this.documentList = [];
        if (res.result == true) {
          this.totalItem = res.data.total;
          if (typeof res.data.data == 'undefined') {
            this.totalItem = 0;
          }
          if (this.totalItem > 0) {
            this.isExport = true;
          }
          this.documentList = res.data.data;
          this.message = res.message;
        } else {
          this.message = res.message;
        }
      },
      error => {
        this.loading = false;
        this.customErrorHandler.handleHttpError(error, 'employeeReport');
      }
    )
  }
  exportDocumentReport() {
    let queryData = {
      'page': this.pageNo,
      'per_page': this.perPage, 
      'search': this.searchTextValue, 
      'export': 'yes',
      'category_id': this.categoryId
    }
    this._documentLibraryService.getDocument(queryData).subscribe(
      res => {
        var options = {
          fieldSeparator: ',',
          quoteStrings: '"',
          decimalseparator: '.',
          showLabels: true,
          showTitle: true,
          useBom: true,
          title: "Document Library"
        };
        new Angular2Csv(res.data, 'Document Library', options);
      },
      error => {
        this.customErrorHandler.handleHttpError(error, 'employeeReport');
      }
    )
  }
  deleteDocument(document) {
    if (document.is_edit) {
      swal({
        text: Constants.DeleteMsg,
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        cancelButtonColor: '#3085d6',
        confirmButtonText: 'Delete',
      }).then((result) => {
        if (typeof result.value != 'undefined') {
          this.deleteDocumentRequest.doc_id = document.id;
          this.loading = true;
          this._documentLibraryService.deleteDocument(this.deleteDocumentRequest).subscribe(
            res => {
              this.loading = false;
              if (res.result == true) {
                this.commonService.showSuccessMessage(res.message);
                this.getDocumentReport(1);
              }
              else {
                this.commonService.showErrorMessage(res);
              }
            },
            error => {
              this.loading = false;
              this.customErrorHandler.handleHttpError(error, 'routeDelete');
            }
          )
        }
      });
    }
    
  }
  documentEdit(document) {
    if (document.is_edit) {
      localStorage.setItem('editDocumentId', document.id);
      this.router.navigate(["document/document-create"]);
    }
  }

  onkeyup_value(event) {
    this.searchTextValue = event.target.value;
    if (this.tempSearch != this.searchTextValue) {
      this.getDocumentReport(1);
    }
    this.tempSearch = this.searchTextValue;
  }

  getCategoryList() {
		this._documentLibraryService.getCategory().subscribe(
			res => {
				if (res.result == true) {
          this.categoryList = res.data;
          if (this.categoryList.length > 0) {
            this.categoryList.unshift(this.defaultSelectObject);
          }
				} 
			},
			error => {
				this.customErrorHandler.handleHttpError(error, 'createEvent');
			}
		)
  }
  
  onCategorySelect(item: any) {
    this.categoryId = item.id;
    this.getDocumentReport(1);
  }
  onCategoryDeselect(item: any) {
    this.categoryId = '';
    this.getDocumentReport(1);
  }
}
