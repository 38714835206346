import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
declare var $: any;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  public isLogin: boolean;
  constructor(public router: Router) {
    router.events.forEach((event) => {
      if (event instanceof NavigationEnd) {
        document.getElementsByClassName('fullHeightBlock')[0].scrollTop = 0;
      }
    });
  }
  ngOnInit() {
    let loginchk = localStorage.getItem('isLogin');
    if (loginchk == '1') {
      this.isLogin = true;
    } else {
      this.isLogin = false;
    }
    $(window).load(function () {
      $('body').css('visibility', 'visible');
    });
  }
}
