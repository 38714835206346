import { Component, OnInit } from '@angular/core';
declare var $: any;
@Component({
	selector: 'team',
	templateUrl: './team.component.html',
	styleUrls: ['./team.component.css']
})
export class TeamComponent implements OnInit {
	ngOnInit() {
	}
}
