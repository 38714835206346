import { Injectable } from '@angular/core';
import { HttpHelper } from '../../helpers/http.helper';
import { Config } from '../../config/config';
const company_id = localStorage.getItem('company_id');
const user_id = localStorage.getItem('user_id');
@Injectable()
export class ComplaintService {
  constructor(private _httpHelper: HttpHelper) {
  }
  createComplaint(name: string, email: string, mobileNo: string, gender: string, age: string, address: string, voter_id: string, constituency: string, source_type: string, message: string, status: string, note:string) {
    try {
      var request = {
        "name": name,
        "email": email,
        "phone_number": mobileNo,
        "gender": gender,
        "age": age,
        "address": address,
        "voter_id": voter_id,
        "company_id": company_id,
        "created_by": user_id,
        "constituency": constituency,
        "source_type": source_type,
        "message": message,
        "status": status,
        'note': note
      };
      return this._httpHelper.post(Config.AuthenticationBaseURL1 + 'add-complaint', request)
    }
    catch (e) {
    }
  }
  updateComplaint(complaintId , name: string, email: string, mobileNo: string, gender: string, age: string, address: string, voter_id: string, constituency: string, source_type: string, message: string, status: string, note:string) {
    try {
      var request = {
        "complaint_id": complaintId,
        "name": name,
        "email": email,
        "phone_number": mobileNo,
        "gender": gender,
        "age": age,
        "address": address,
        "voter_id": voter_id,
        "company_id": company_id,
        "created_by": user_id,
        "constituency": constituency,
        "source_type": source_type,
        "message": message,
        "status": status,
        'note': note
      };
      return this._httpHelper.post(Config.AuthenticationBaseURL1 + 'update-complaint', request)
    }
    catch (e) {
    }
  }
  getComplaintById(id) {
    try {
      var request = {
        "company_id": company_id,
        "complaint_id": id,
      };
      return this._httpHelper.post(Config.AuthenticationBaseURL1 + 'get-single-complaint', request)
    }
    catch (e) {
    }
  }
  
  getComplaintList(pageNo, rowPerPage, exportReport, search, reportingUserId, locationId, roleId) {
    try {
      
      return this._httpHelper.get(Config.AuthenticationBaseURL1 + 'get-complaint' + '?page=' + pageNo + '&per_page=' + rowPerPage + '&export=' + exportReport + '&search=' + search + '&reporting_to=' + reportingUserId + '&location_id=' + locationId+'&role_id='+roleId);
    }
    catch (e) {
    }
  }

  getComplaintListWithFilter(pageNo, rowPerPage, exportReport, search, reportingUserId, locationId, roleId,status_filter : string = '') {
    try {
      if(status_filter == undefined)
       {
         status_filter = '';
       }
      return this._httpHelper.get(Config.AuthenticationBaseURL1 + 'get-complaint' + '?page=' + pageNo + '&per_page=' + rowPerPage + '&export=' + exportReport + '&search=' + search + '&reporting_to=' + reportingUserId + '&location_id=' + locationId+'&role_id='+roleId+'&status='+status_filter);
    }
    catch (e) {
    }
  }
  complaintDelete(complaint_id: string) {
    try {
      var request = {
        "complaint_id": complaint_id
      };
      return this._httpHelper.post(Config.AuthenticationBaseURL1 + 'delete-complaint', request)
    }
    catch (e) {
    }
  }
  complaintStatusChange(complaint_id: string,status: string) {
    try {
      var request = {
        "complaint_id": complaint_id,
        "status" : status
      };
      return this._httpHelper.post(Config.AuthenticationBaseURL1 + 'update-complaint-status', request)
    }
    catch (e) {
    }
  }
  //upload_attached_base64
  //base64String
  
  
}
