import { AuthGuard } from './../../services/_guards/auth.guard';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { DocumentLibraryComponent } from './document-library.component';
import { DocumentListComponent } from './document-list/document-list.component';
import { CreateDocumentComponent } from './create-document/create-document.component';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown/angular2-multiselect-dropdown';
import { InfiniteScrollModule } from 'angular2-infinite-scroll';
import { Ng2PaginationModule } from 'ng2-pagination';
import { FileUploadModule } from 'ng2-file-upload';
export const routes: Routes = [
  {
    path: 'document',
    component: DocumentLibraryComponent, canActivate: [AuthGuard],
    children: [
      { path: '', redirectTo: 'document-list', pathMatch: 'full' },
      { path: 'document-list', component: DocumentListComponent },
      { path: 'document-create', component: CreateDocumentComponent },
    ]
  }
];
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule.forChild(routes),
    InfiniteScrollModule,
    AngularMultiSelectModule,
    Ng2PaginationModule,
    FileUploadModule
  ],
  declarations: [
    DocumentLibraryComponent,
    DocumentListComponent,
    CreateDocumentComponent
  ]
})
export class DocumentLibraryModule { }
