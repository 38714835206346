import { Component, OnInit } from '@angular/core';
import { FormsService } from '../../../services/forms/forms.service';
import { CustomErrorHandler } from '../../../errorhandler/custom-errorhandler';
import { CommonService } from '../../../services/common/common.service';
import { Constants } from '../../../common/constants';
@Component({
  selector: 'forms',
  templateUrl: './forms.component.html',
  styleUrls: ['./forms.component.css']
})
export class FormsComponent implements OnInit {
  errormsg: string;
  msg: boolean;
  prospectList = [];
  dropdownSettings = {};
  selectionList = [];
  prospectLabel: string;
  catagoryList: any[];
  prospectName: any;
  prospect_id: Number;
  formList = [];
  reportingUserList: any = [];
  reportingSelectedItem: any = [];
  reportingSettings: any = {};
  locationList: any = [];
  locationSettings: any = {};
  locationSelectedItem: any = [];
  reportingTo: any = '';
  locationId: any = '';
  defaultSelectObject: any = { id: '', itemName: 'All' };
  filterLabel = Constants.filterLabel;
  loading: boolean;
  digitalFormMeeting: any = '';
  constructor(
    private _formservice: FormsService,
    private customErrorHandler: CustomErrorHandler,
    public commonService: CommonService
  ) { }
  ngOnInit() {
    this.getprospectList();
    this.errormsg = 'Currently, there is no data available.';
    this.msg = true;
    this.prospectLabel = localStorage.getItem('prospectLabel');
    this.dropdownSettings = {
      singleSelection: true,
      text: "Select " + this.prospectLabel,
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      enableSearchFilter: true,
      classes: "myclass custom-class singleSelection"
    };
    this.getLocationList();
    this.getReportingUserList(this.locationId);
    this.reportingSettings = {
      singleSelection: true,
      text: "Select Reporting Person",
      enableSearchFilter: true,
      classes: "singleSelection block_elem m_b_15"
    };
    this.locationSettings = {
      singleSelection: true,
      text: "Select "+ Constants.locationLabel,
      enableSearchFilter: true,
      classes: "singleSelection block_elem m_b_15"
    }
    this.initProspectList();
  }
  getprospectList() {
    let parameter = {
      list_type: 'digital',
      location_id: this.locationId,
      reporting_to: this.reportingTo
    }
    this.commonService.getProspectList(parameter).subscribe(
      res => {
        if (res.result == true) {
          this.prospectList = res.data;
          // this.selectionList = this.prospectList[0];
          if (this.prospectList.length > 0) {
            this.selectionList = [];
            this.prospect_id = this.prospectList[0].id;
            this.digitalFormMeeting = this.prospectList[0].itemName;
             this.selectionList.push(this.prospectList[0]);
             this.getFormListForProspect();
          }
        } else {
          this.prospectList = [];
          this.commonService.showErrorMessage(res);
        }
      },
      error => {
        this.customErrorHandler.handleHttpError(error, 'prospectCompletedList');
      });
  }
  initProspectList() {
    this.loading = true;
    let parameter = {
      list_type: 'digital',
      location_id: this.locationId,
      reporting_to: this.reportingTo
    }
    this.commonService.getProspectList(parameter).subscribe(
      res => {
        this.loading = false;
        if (res.result == true) {
          this.prospectList = res.data;
          // this.selectionList = this.prospectList[0];
          if (this.prospectList.length > 0) {
            this.prospect_id = this.prospectList[0].id;
            this.selectionList = []
            this.selectionList.push(this.prospectList[0]);
            this.digitalFormMeeting = this.prospectList[0].itemName;
            this.getFormListForProspect();
          }
        } else {
          this.prospectList = [];
          this.commonService.showErrorMessage(res);
        }
      },
      error => {
        this.loading = false;
        this.customErrorHandler.handleHttpError(error, 'prospectCompletedList');
      });
  }
  getFormListForProspect() {
    this.loading = true;
    this._formservice.getFormsList(this.prospect_id).subscribe(
      res => {
        this.msg = false;
        if (res.result == true) {
          this.formList = res.data;
          this.loading = false;
          if (this.formList.length > 0) {
            this.processFormData(this.formList);
          }
          else {
            this.msg = true;
            this.errormsg = res.message || 'Currently, there is no data available.';
            this.catagoryList = [];
          }
        } else {
          this.formList = [];
          this.loading = false;
        }
      },
      error => {
        this.loading = false;
        this.customErrorHandler.handleHttpError(error, 'getFormsList');
      });
  }
  processFormData(formlistLocal) {
    let catagorylist = [];
    for (let i = 0; i < formlistLocal.length; i++) {
      catagorylist[i] = formlistLocal[i].category;
    }
    if (catagorylist.length > 0) {
      this.catagoryList = catagorylist.filter(function onlyUnique(value, index, self) {
        return self.indexOf(value) === index;
      });
    }
  }
  onOpen(event: any) {
    this.prospectList = this.prospectList.map((item) => {
      return {
        id: item.id,
        itemName: item.itemName
      }
    });
  }
  onItemSelect(item: any) {
    this.prospect_id = item.id;
    this.digitalFormMeeting = item.itemName;
    this.getFormListForProspect();
  }
  OnItemDeSelect(item: any) {
    this.prospect_id = undefined;
    this.digitalFormMeeting = '';
    // this.getFormListForProspect();
    this.initProspectList();
  }
  onSelectAll(items: any) {
  }
  onDeSelectAll(items: any) {
  }

  getReportingUserList(locationId) {
    let currentUserId = localStorage.getItem('user_id');
    this.commonService.getReportingUserList(locationId).subscribe(res => {
      if (res.result == true) {
        this.reportingUserList = res.data;
        if (this.reportingUserList.length > 1) {
          this.reportingUserList.unshift(this.defaultSelectObject);
        }
      }
    }, err => {
      this.customErrorHandler.handleHttpError(err, 'getRoleList');
    });
  }
  getLocationList() {
    this.commonService.getLocationList().subscribe(res => {
      if (res.result == true) {
        this.locationList = res.data;
        if (this.locationList.length > 1) {
          this.locationList.unshift(this.defaultSelectObject);
        }
      }
    }, err => {
      this.customErrorHandler.handleHttpError(err, 'getRoleList');
    });
  }

  onUserItemSelect(item: any) {
    this.reportingTo = item.id;
    this.getprospectList();
  }
  onUserItemDeSelect(item: any) {
    this.reportingTo = '';
    this.getprospectList();
  }
  onLocationItemSelect(item: any) {
    this.locationId = item.id;
    this.reportingTo = '';
    this.reportingUserList = [];
    this.reportingSelectedItem = [];
    this.selectionList = [];
    this.digitalFormMeeting = '';
    this.msg = true;
    this.errormsg = 'Currently, there is no data available.';
    this.catagoryList = [];
    // if (this.locationId != '') {
      this.getReportingUserList(this.locationId);
    // }
    this.getprospectList();
  }
  onLocationItemDeSelect(item: any) {
    this.locationId = '';
    this.reportingTo = '';
    this.reportingUserList = [];
    this.reportingSelectedItem = [];
    this.selectionList = [];
    this.msg = true;
    this.digitalFormMeeting = '';
    this.errormsg = 'Currently, there is no data available.';
    this.catagoryList = [];
    this.getprospectList();
  }
}
