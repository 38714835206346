import { MeetingService } from './../../../services/meeting/meeting.service';
import { Component, OnInit } from '@angular/core';
import { AssignService } from '../../../services/assign/assign.service';
import { ReportService } from '../../../services/report/report.service';
import { Angular2Csv } from 'angular2-csv/Angular2-csv';
import { CustomErrorHandler } from '../../../errorhandler/custom-errorhandler';
import { Config } from './../../../config/config';
import { Constants } from '../../../common/constants';
import { CommonService } from '../../../services/common/common.service';
declare var $: any;
declare var moment: any;
@Component({
  selector: 'app-user-route',
  templateUrl: './user-route.component.html',
  styleUrls: ['./user-route.component.css']
})
export class UserRouteComponent implements OnInit {
  imageBaseUrl: string = Config.AuthenticationURL;
  isExport: boolean;
  isDemoTenant: boolean;
  prospectLabel: string;
  employeeName: any;
  message: any;
  pageNo: number = 1;
  perPage: number = 10;
  totalItem: number = 0;
  employeeTeamList: any = [];
  page: number = 2;
  startDate: any;
  endDate: any;
  search: any;
  export: any;
  serverUrl: any;
  teamId: any;
  teamList: any = [];
  teamSelectedItems = [];
  errorMessage: any;
  employeeLabel: any;
  teamSettings = {};
  loading: boolean;
  noDataMsg: string;
  locationList: any = [];
  locationSettings: any = {};
  locationSelectedItem: any = [];
  locationId: any = '';
  defaultSelectObject: any = {id: '',itemName: 'All'};
  filterLabel = Constants.filterLabel;
  employee: any = [];
  emp_id: any;
  reportingTo: any = '';
  reportingUserList: any = [];
  reportingSelectedItem: any = [];
  reportingSettings: any = {};
  EmpSettings: any = {};
  start_date: string;
  end_date: string;
  constructor(public _prospectService: MeetingService, public commonService: CommonService,public _assignService: AssignService, public _reportService: ReportService, private customErrorHandler: CustomErrorHandler) {
  }
  ngOnInit() {
    this.search = '';
    this.teamSettings = {
      singleSelection: true,
      text: "Select Team",
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      enableSearchFilter: true,
      classes: "myclass custom-class singleSelection"
    };
    this.prospectLabel = localStorage.getItem('prospectLabel');
    this.employeeLabel = localStorage.getItem('employeeLabel');
    this.isExport = false;
    this.isDemoTenant = (localStorage.getItem('isDemoTenant') == 'true');
    this.message = true;
    this.serverUrl = Config.AuthenticationURL;
     this.getReportingUserList('');
    this.getLocationList();
    this.reportingSettings = {
      singleSelection: true,
      text: "Select Reporting Person",
      enableSearchFilter: true,
      classes: "singleSelection block_elem m_b_15"
    };
    this.locationSettings = {
      singleSelection: true,
      text: "Select "+ Constants.locationLabel,
      enableSearchFilter: true,
      classes: "singleSelection sm_max_300 width100 m_b_15 block_elem"
    };

    this.EmpSettings = {
      singleSelection: true,
      text: "Select User",
      enableSearchFilter: true,
      classes: "singleSelection block_elem m_b_15"
    };
    var start = moment().subtract(6, 'days');
    var end = moment();
    var _this;
    _this = this;
    function cb(start, end) {
      $('#reportrange span').html(start.format('MMM D, YYYY') + ' - ' + end.format('MMM D, YYYY'));
      var f_start = start.format('YYYY-MM-DD');
      var f_end = end.format('YYYY-MM-DD');
      _this.start_date = f_start;
      _this.end_date = f_end;
      _this.onSubmit(1);
    }
    $('#reportrange').daterangepicker({
      startDate: start,
      endDate: end,
      ranges: {
        'Today': [moment(), moment(),],
        'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
        'Last 7 Days': [moment().subtract(6, 'days'), moment()],
        'Last 30 Days': [moment().subtract(29, 'days'), moment()],
        'This Month': [moment().startOf('month'), moment().endOf('month'), 'inclusive'],
        'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
      }
    }, cb);
    cb(start, end);
  }
  onSubmit(currentPage){
    this.getUserRoute(currentPage);
  }
  getUserRoute(currentPage) {
    this.errorMessage = '';
    if (this.emp_id.length == 0) {
      this.errorMessage = "Team should not be blank";
      return false;
    }
    this.pageNo = currentPage;
    this.page = currentPage;
    this.message = '';
    this.export = '';
    this.loading = true;

    this._reportService.userRouteReport(this.pageNo, this.perPage, this.emp_id, this.search, this.export, this.locationId, this.start_date, this.end_date).subscribe(
      res => {
        this.loading = false;
        this.employeeTeamList = [];
        this.noDataMsg = res.message || 'Currently, there is no data available.'
        if (res.result == true) {
          this.totalItem = res.data.total;
          this.employeeTeamList = res.data.data;
        } else {
          this.message = res.message;
        }
      },
      error => {
        this.loading = false;
        this.customErrorHandler.handleHttpError(error, 'getUserRoute');
      });
  }
  exportTeamReport() {
    this.export = 'yes';
    this._reportService.userRouteReport(0, 0, this.emp_id, this.search, this.export, this.locationId,this.start_date, this.end_date).subscribe(
      res => {
        var options = {
          fieldSeparator: ',',
          quoteStrings: '"',
          decimalseparator: '.',
          showLabels: true,
          showTitle: true,
          useBom: true,
          title: "User Route Report"
        };
        new Angular2Csv(res.data, 'User Route Report', options);
      },
      error => {
        this.customErrorHandler.handleHttpError(error, 'employeeReport');
      });
  }
  teamListFunction() {
    this._prospectService.teamList(this.locationId).subscribe(
      res => {
        this.teamList = res.data;
        this.noDataMsg = res.message || 'Currently, there is no data available.'
      },
      error => {
        this.customErrorHandler.handleHttpError(error, 'teamList');
      });
  }
  onTeamSelect(item: any) {
    console.log(item);
    this.teamId = item.id;
    this.getUserRoute(1);
  }
  ngOnDestroy() {
    $('.fullHeightBlock').animate({ scrollTop: 0 }, 400);
  }

  getLocationList() {
    this.commonService.getLocationList().subscribe(res => {
      if (res.result == true) {
        this.locationList = res.data;
        if(this.locationList.length > 1){
          this.locationList.unshift(this.defaultSelectObject);
        }
      }
    }, err => {
      this.customErrorHandler.handleHttpError(err, 'getRoleList');
    })
  }

  onLocationItemSelect(item: any) {
    console.log("wdwwd")
    this.locationId = item.id;
    this.teamSelectedItems = [];
    this.employeeTeamList = [];
    //this.teamListFunction();
    
    this.getReportingUserList(this.locationId);
    this.employeeList();
  }
  onLocationItemDeSelect(item: any) {
    this.locationId = '';
    this.teamSelectedItems = [];
    this.employeeTeamList = [];
    //this.teamListFunction();
    
     this.getReportingUserList(this.locationId);
     this.employeeList();
  }

  employeeList() {
    let parameter = {
      'location_id': this.locationId,
      'reporting_to': this.reportingTo
    }
    console.log("get Employeee  list")
    this._assignService.employeeList(parameter).subscribe(
      res => {
        this.employee = res.data;
        // this.noDataMsg = res.message || 'Currently, there is no data available.';
      },
      error => {
        this.customErrorHandler.handleHttpError(error, 'employeeList');
      });
  }
  onOpen(event: any) {
    this.employee = this.employee.map((item) => {
      return {
        id: item.id,
        itemName: item.itemName
      }
    });
  }
  onItemSelect(item: any) {
    this.emp_id = item.id;
   this.getUserRoute(1);
  }

  OnItemDeSelect(item: any) {
    this.emp_id = '';
   this.getUserRoute(1);
  }
  onSelectAll(items: any) {
    this.getUserRoute(1);
  }
  onDeSelectAll(items: any) {
    this.getUserRoute(1);
  }

  getReportingUserList(locationId) {
    this.commonService.getReportingUserList(locationId).subscribe(res => {
      if (res.result == true) {
        this.reportingUserList = res.data;
        if (this.reportingUserList.length > 1) {
          this.reportingUserList.unshift(this.defaultSelectObject);
        }
      }
    }, err => {
      this.customErrorHandler.handleHttpError(err, 'getRoleList');
    });
  }
}
