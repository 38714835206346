import { Component, OnInit, ViewChild, ElementRef, ViewEncapsulation } from '@angular/core';
import { CustomErrorHandler } from '../../../errorhandler/custom-errorhandler';
import { CommonService } from '../../../services/common/common.service';
import { AnnouncementService } from '../../../services/announcements/announcements.service';
import { Config } from '../../../config/config';
import { Router } from '@angular/router';
import swal from 'sweetalert2';
import { Constants } from '../../../common/constants';
declare var $;

@Component({
  selector: 'app-announcement-list',
  templateUrl: './announcement-list.component.html',
  styleUrls: ['./announcement-list.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class AnnouncementListComponent implements OnInit {
  @ViewChild('imagesModel') imagesModel: ElementRef;
  loading: any;
  perPage: number = 10;
  pageNo: number = 1;
  page: number = 2;
  searchTextValue: any = '';
  isDemoTenant: boolean;
  totalItem: number = 0;
  tempSearch: any;
  annoucementData: any = [];
  imageBaseUrl: string = Config.AuthenticationURL;
  noDataMsg: any;
  images: any = [];
  isImagesModel: boolean;
  isPrimaryUser : boolean = false;
  galleryNextBtn : any;

  constructor(
    private router: Router,
    private customErrorHandler: CustomErrorHandler,
    private commonservice: CommonService,
    public announcementService: AnnouncementService
  ) { }

  ngOnInit() {
    this.isImagesModel = false;
    this.getAnnoucement(1);
    this.isDemoTenant = (localStorage.getItem('isDemoTenant') == 'true');
    this.galleryNextBtn = document.getElementsByClassName('ui-galleria-nav-next');
  }

  onSearch(event) {
    this.searchTextValue = event.target.value;
    if (this.tempSearch != this.searchTextValue) {
      this.getAnnoucement(1);
    }
    this.tempSearch = this.searchTextValue;
  }

  getAnnoucement(currentPage) {
    this.loading = true;
    this.page = currentPage;
    let queryStrings: any = {
      page: currentPage,
      perPage: this.perPage,
      search: this.searchTextValue,
    }
    this.announcementService.getAnnouncementList(queryStrings).subscribe(res => {
      if (res.result == true) {
        this.totalItem = res.data.total;
        this.annoucementData = res.data.data;
        this.noDataMsg = res.message;
        this.loading = false;
        this.isPrimaryUser = res.is_primary;
      } else {
        this.loading = false;
      }
    }, err => {
      this.customErrorHandler.handleHttpError(err, 'getAnnoucement');
      this.loading = false;
    });
  }

  editAnnouncement(id) {
    this.router.navigate(['announcements/add-announcement'], { queryParams: { id: id } })
  }

  deleteAnnouncement(announcementId) {
    swal({
      text: Constants.DeleteMsg,
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Delete',
    }).then((result) => {
      if (typeof result.value != 'undefined') {
        let data = { announcement_id: announcementId };

        this.announcementService.deleteAnnouncement(data).subscribe(
          res => {
            if (res.result == true) {
              this.commonservice.showSuccessMessage(res.message);
              this.getAnnoucement(1);
            } else {
              this.commonservice.showErrorMessage(res);
            }
          },
          error => {
            this.customErrorHandler.handleHttpError(error, 'employeeReport');
          });
      }
    })
  }

  openModal(data) {
    this.isImagesModel = true;
    this.images = [];
    data.forEach((element, idx) => {
      let imgObj: any = {};
      imgObj.source = this.imageBaseUrl + element;
      imgObj.title = 'Image ' + (idx + 1);
      this.images.push(imgObj);
    });
    setTimeout(() => {
      if (this.images.length == 1) {
        this.galleryNextBtn[0].style.display = 'none';
      } else {
        this.galleryNextBtn[0].style.display = 'block';
      }
    }, 100);
  }

  closeModal() {
    this.images = [];
    this.isImagesModel = false;
  }

  onImageChange(event: any) {
    let lastIdx = this.images.length - 1;
    if (event.index == lastIdx) {
      $('.ui-galleria-nav-next').css('display', 'none');
    } else {
      $('.ui-galleria-nav-next').css('display', 'block');
    }
  }
}
