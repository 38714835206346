import { Injectable } from '@angular/core';
import { HttpHelper } from '../../helpers/http.helper';
import { Config } from '../../config/config';
@Injectable()
export class AnnouncementService {
    constructor(
        private _httpHelper: HttpHelper
    ) { }
    createAnnouncement(request) {
        try {
            return this._httpHelper.post(Config.AuthenticationBaseURL1 + 'create-announcement', request)
        }
        catch (e) {
        }
    }
    getAnnouncementList(queryData) {
        try {
            return this._httpHelper.get(Config.AuthenticationBaseURL1 + 'get-announcement', queryData);
        }
        catch (e) {
        }
    }
    getSingleAnnouncement(queryData) {
        try {
            return this._httpHelper.get(Config.AuthenticationBaseURL1 + 'get-single-announcement', queryData);
        }
        catch (e) {
        }
    }
    updateAnnouncement(request) {
        try {
            return this._httpHelper.post(Config.AuthenticationBaseURL1 + 'update-announcement', request)
        }
        catch (e) {
        }
    }
    deleteAnnouncement(queryData) {
        try {
            return this._httpHelper.delete(Config.AuthenticationBaseURL1 + 'delete-announcement', queryData);
        }
        catch (e) {
        }
    }

    getAllAnnouncementList(queryData) {
        try {
            return this._httpHelper.get(Config.AuthenticationBaseURL1 + 'get-my-announcement', queryData);
        }
        catch (e) {
        }
    }
}
