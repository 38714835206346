import { MeetingService } from './../../../services/meeting/meeting.service';
import { Component, OnInit } from '@angular/core';
import { CustomErrorHandler } from '../../../errorhandler/custom-errorhandler';
import { ReportService } from '../../../services/report/report.service';
import { Angular2Csv } from 'angular2-csv/Angular2-csv';
import { CommonService } from '../../../services/common/common.service';
import { Constants } from '../../../common/constants';
@Component({
  selector: 'follow-report',
  templateUrl: 'follow-report.component.html',
  styleUrls: ['./follow-report.component.css']
})
export class FollowReportComponent implements OnInit {
  isExport: boolean;
  isDemoTenant: boolean;
  prospectName: any;
  prospect: any = [];
  reportData: any = [];
  prospect_id: any;
  employeeLabel: string;
  report_type: any;
  errorMessage: string;
  disableButton: boolean;
  signin_btn_txt: string;
  start_date: string;
  end_date: string;
  errormsg: string;
  msg: boolean;
  loading: boolean;
  dropdownSettings = {};
  selectionList = [];
  prospectLabel: string;
  pageNo: number = 1;
  perPage: number = 10;
  totalItem: number = 0;
  page: number = 2;
  message: any;
  noDataMsg: string;
  reportingUserList: any = [];
  reportingSelectedItem: any = [];
  reportingSettings: any = {};
  locationList: any = [];
  locationSettings: any = {};
  locationSelectedItem: any = [];
  reportingTo: any = '';
  locationId: any = '';
  defaultSelectObject: any = { id: '', itemName: 'All' };
  filterLabel = Constants.filterLabel;
  constructor(private commonService: CommonService, public _prospectService: MeetingService, public _reportService: ReportService, private customErrorHandler: CustomErrorHandler) {
  }
  ngOnInit() {
    this.isExport = false;
    this.employeeLabel = localStorage.getItem('employeeLabel');
    this.isDemoTenant = (localStorage.getItem('isDemoTenant') == 'true');
    this.prospectLabel = localStorage.getItem('prospectLabel');
    this.msg = true;
    this.prospect_id = ''
    this.dropdownSettings = {
      singleSelection: true,
      text: 'Select ' + this.prospectLabel,
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      enableSearchFilter: true,
      classes: 'myclass custom-class block_elem m_b_15 singleSelection'
    };
    this.prospectList();
    this.getFollowupReport(1);
    this.getLocationList();
    this.getReportingUserList(this.locationId);
    this.reportingSettings = {
      singleSelection: true,
      text: "Select Reporting Person",
      enableSearchFilter: true,
      classes: "singleSelection block_elem m_b_15"
    };
    this.locationSettings = {
      singleSelection: true,
      text: "Select "+ Constants.locationLabel,
      enableSearchFilter: true,
      classes: "singleSelection block_elem m_b_15"
    };
  }
  prospectList() {
    this._reportService.prospectList().subscribe(
      res => {
        this.noDataMsg = res.message || 'Currently, there is no data available.';
        if (res.result == true) {
          this.prospect = res.data;
        } else {
          this.prospect = [];
        }
      },
      error => {
        this.customErrorHandler.handleHttpError(error, 'prospectList');
      });
  }
  onOpen(event: any) {
    this.prospect = this.prospect.map((item) => {
      return {
        id: item.id,
        itemName: item.itemName
      };
    });
  }
  onItemSelect(item: any) {
    this.prospect_id = item.id;
  }
  OnItemDeSelect(item: any) {
  }
  onSelectAll(items: any) {
  }
  onDeSelectAll(items: any) {
  }
  getFollowupReport(currentPage) {
    this.pageNo = currentPage;
    this.page = currentPage;
    this.message = '';
    this.loading = true;
    this.disableButton = true;
    this.errorMessage = '';
    this.signin_btn_txt = "Creating";
    this._reportService.followUpReport(this.pageNo, this.perPage, this.prospect_id, this.start_date, this.end_date, '', this.locationId, this.reportingTo).subscribe(
      res => {
        this.reportData = [];
        this.loading = false;
        this.message = res.message || '';
        if (res.result == true) {
          const resData: any = res.data
          this.reportData = resData.data;
          this.totalItem = resData.total;
          if (this.reportData.length > 0) {
            this.isExport = true;
          }
          else {
            this.isExport = false;
          }
        }
      },
      error => {
        this.loading = false;
        this.customErrorHandler.handleHttpError(error, 'followUpReport');
        this.disableButton = false;
        this.signin_btn_txt = "Submit";
      });
  }
  exportFollowupReport() {
    this.isExport = true;
    this._reportService.followUpReport(0, 0, this.prospect_id, this.start_date, this.end_date, 'yes', this.locationId, this.reportingTo).subscribe(
      res => {
        var options = {
          fieldSeparator: ',',
          quoteStrings: '"',
          decimalseparator: '.',
          showLabels: true,
          showTitle: true,
          useBom: true,
          title: "Followup Report"
        };
        new Angular2Csv(res.data, 'Followup Report', options);
      },
      error => {
        this.customErrorHandler.handleHttpError(error, 'followUpReport');
        this.disableButton = false;
        this.signin_btn_txt = "Submit";
      });
  }
  getReportingUserList(locationId) {
    this.commonService.getReportingUserList(locationId).subscribe(res => {
      if (res.result == true) {
        this.reportingUserList = res.data;
        if (this.reportingUserList.length > 1) {
          this.reportingUserList.unshift(this.defaultSelectObject);
        }
      }
    }, error => {
      this.customErrorHandler.handleHttpError(error, 'getRoleList');
    });
  }
  getLocationList() {
    this.commonService.getLocationList().subscribe(res => {
      if (res.result == true) {
        this.locationList = res.data;
        if (this.locationList.length > 1) {
          this.locationList.unshift(this.defaultSelectObject);
        }
      }
    }, error => {
      this.customErrorHandler.handleHttpError(error, 'getRoleList');
    });
  }
  onUserItemSelect(item: any) {
    this.reportingTo = item.id;
    this.getFollowupReport(1);
  }
  onUserItemDeSelect(item: any) {
    this.reportingTo = '';
    this.getFollowupReport(1);
  }
  onLocationItemSelect(item: any) {
    this.locationId = item.id;
    this.reportingTo = '';
    this.reportingUserList = [];
    this.reportingSelectedItem = [];
    // if (this.locationId != '') {
      this.getReportingUserList(this.locationId);
    // }
    this.getFollowupReport(1);
  }
  onLocationItemDeSelect(item: any) {
    this.locationId = '';
    this.reportingTo = '';
    this.reportingUserList = [];
    this.reportingSelectedItem = [];
    this.getFollowupReport(1);
  }
}
