import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RouteService } from '../../../services/route/route.service';
import { Angular2Csv } from 'angular2-csv/Angular2-csv';
import { Constants } from '../../../common/constants';
import { Config } from '../../../config/config';
import { CustomErrorHandler } from '../../../errorhandler/custom-errorhandler';
import { CommonService } from '../../../services/common/common.service';
import swal from 'sweetalert2';
declare var moment: any;
declare var $: any;
declare var google: any;
@Component({
  selector: 'route-list',
  templateUrl: 'route-list.component.html',
  styleUrls: ['./route-list.component.css']
})
export class RouteListComponent implements OnInit {
  isExport: boolean;
  isDemoTenant: boolean;
  errormsg: string;
  msg: boolean;
  company_id: string;
  routeData: any[];
  loading: boolean;
  prospectLabel: string;
  searchTextValue: any;
  meetingModal: any;
  directionsService: any;
  directionsDisplay: any;
  map: any;
  employeeMeetingList: any = [];
  routeOrigin: any;
  routeDestination: any;
  routePaths: any[] = [];
  originPath: any;
  originResult: any;
  routeDrawResult: any[] = [];
  routeDistance: any = 0;
  routeETA: any = 0;
  routeResult: any;
  markerArray = [];
  markers = [];
  drawGeofencing: any;
  drawGeofencingType: any;
  drawingManager: any;
  geofenceDraw: boolean;
  startDate: any;
  endDate: any;
  tempSearch: any;
  routeName: string = '';
  employeeLabel: string = '';
  perPage: number = 10;
  pageNo: number = 1;
  page: number = 2;
  totalItem: number = 0;
  reportingUserList: any = [];
  reportingSelectedItem: any = [];
  reportingSettings: any = {};
  locationList: any = [];
  locationSettings: any = {};
  locationSelectedItem: any = [];
  reportingTo: any = '';
  locationId: any = '';
  defaultSelectObject: any = { id: '', itemName: 'All' }
  employeeMemberList: any = [];
  employeeSelectedItem: any = [];
  employeeMemberSettings = {};
  employeeId = '';
  filterLabel = Constants.filterLabel;
  constructor(
    private router: Router,
    private commonService: CommonService,
    public _routeService: RouteService,
    private customErrorHandler: CustomErrorHandler) {
  }
  ngOnInit() {
    this.isExport = false;
    this.tempSearch = '';
    this.isDemoTenant = (localStorage.getItem('isDemoTenant') == 'true');
    this.errormsg = '';
    this.msg = false;
    this.loading = true;
    this.routeData = [];
    this.employeeLabel = localStorage.getItem('employeeLabel');
    localStorage.removeItem('root_geofence_id');
    this.prospectLabel = localStorage.getItem('prospectLabel');
    $('[data-toggle="tooltip"]').tooltip();
    $(function () {
      $('[data-toggle="tooltip"]').tooltip()
    });
    var _this = this;
    this.searchTextValue = '';
    this.meetingModal = document.getElementById('selectRootFromMeetingModal');
    this.directionsService = new google.maps.DirectionsService;
    this.directionsDisplay = new google.maps.DirectionsRenderer({
      suppressMarkers: true
    });
    var start = moment().startOf('month');
    var end = moment().endOf('month');
    function cb(start, end) {
      $('#reportrange span').html(start.format('MMM D, YYYY') + ' - ' + end.format('MMM D, YYYY'));
      _this.startDate = start.format('YYYY-MM-DD');
      _this.endDate = end.format('YYYY-MM-DD');
      _this.pageNo = 1;
      _this.routeData = [];
      _this.routeList(1);
    }
    $('#reportrange').daterangepicker({
      startDate: start,
      endDate: end,
      ranges: {
        'Next 30 Days': [moment(), moment().add(1, 'M')],
        'Today': [moment(), moment()],
        'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
        'Last 7 Days': [moment().subtract(6, 'days'), moment()],
        'Last 30 Days': [moment().subtract(29, 'days'), moment()],
        'This Month': [moment().startOf('month'), moment().endOf('month')],
        'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
      }
    }, cb);
    cb(start, end);
    this.getLocationList();
    this.getReportingUserList(this.locationId);
    this.employeeListFunction(this.locationId);
    this.reportingSettings = {
      singleSelection: true,
      text: "Select Reporting Person",
      enableSearchFilter: true,
      classes: "singleSelection m_b_15 block_elem"
    };
    this.locationSettings = {
      singleSelection: true,
      text: "Select "+ Constants.locationLabel,
      enableSearchFilter: true,
      classes: "singleSelection m_b_15 block_elem"
    };
    this.employeeMemberSettings = {
      singleSelection: true,
      text: "Select " + this.employeeLabel,
      enableSearchFilter: true,
      classes: "singleSelection m_b_15 block_elem"
    };
  }
  onTdClick(index) {
    let td_id = "#td_" + index;
    var $title = $(td_id).find(".title");
    $('.td-more-info').find(".title").remove();
    if (!$title.length) {
      $(td_id).append('<span class="title">' + $(td_id).attr("title") + '</span>');
    } else {
      $title.remove();
    }
  }
  routeList(currentPage) {
    this.pageNo = currentPage;
    this.page = currentPage;
    this.errormsg = '';
    let request = {
      "company_id": localStorage.getItem('company_id'),
      "per_page": this.perPage,
      "page": this.pageNo,
      "search": this.searchTextValue,
      "start_date": this.startDate,
      "end_date": this.endDate,
      "reporting_to": this.reportingTo,
      "location_id": this.locationId,
      'employee_id': this.employeeId
    };
    this._routeService.routeList(request).subscribe(
      res => {
        this.loading = false;
        if (res.result == true) {
          this.totalItem = res.data.total;
          this.routeData = res.data.data;
          this.errormsg = res.message;
        } else {
          this.routeData = [];
          this.msg = true;
          this.errormsg = res.message;
          this.commonService.showErrorMessage(res);
        }
      },
      error => {
        this.loading = false;
        this.customErrorHandler.handleHttpError(error, 'routeList');
      });
  }
  routeEdit(id) {
    localStorage.setItem('update_route_id', id);
    this.router.navigate(["route/route-update"]);
  }
  deleteRoute(routeId) {
    swal({
      text: Constants.DeleteMsg,
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Delete',
    }).then((result) => {
      if (typeof result.value != 'undefined') {
        this._routeService.routeDelete(routeId).subscribe(
          res => {
            if (res.result == true) {
              this.commonService.showSuccessMessage(res.message);
              this.pageNo = 1;
              this.routeList(this.pageNo);
            }
            else {
              this.commonService.showErrorMessage(res);
            }
          },
          error => {
            this.customErrorHandler.handleHttpError(error, 'routeDelete');
          });
      }
    });
  }
  export() {
    var options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: true,
      useBom: true,
      title: 'Route List'
    };
    let request = {
      "company_id": localStorage.getItem('company_id'),
      "per_page": 0,
      "page": 0,
      "search": this.searchTextValue,
      "start_date": this.startDate,
      "end_date": this.endDate,
      "export": "yes"
    };
    this._routeService.routeList(request).subscribe(
      res => {
        new Angular2Csv(res.data, 'route', options);
      },
      error => {
        this.customErrorHandler.handleHttpError(error, 'routeList');
      });
  }
  onkeyup_value(event) {
    this.searchTextValue = event.target.value;
    if (this.tempSearch != this.searchTextValue) {
      this.routeList(1);
    }
    this.tempSearch = this.searchTextValue;
  }
  meetingModalOpen() {
    this.meetingModal.style.display = "block";
  }
  routeCloseModal() {
    this.routeName = '';
    this.meetingModal.style.display = "none";
  }
  openRouteViewModal(routeId) {
    this.meetingModalOpen();
    this.getRouteDetails(routeId);
  }
  getRouteDetails(routeId) {
    this.map = new google.maps.Map(document.getElementById('map'), {
      zoom: 8,
      center: { lat: 22.7664707, lng: 75.8824308 },
      gestureHandling: 'cooperative',
      styles: Constants.MapStyle
    });
    this.directionsDisplay.setMap(this.map);
    this._routeService.singleRouteDetails(routeId).subscribe(
      res => {
        this.routeName = res.data.route_name;
        this.employeeMeetingList = res.data.route_direction_points;
        let geoFencingPoint = res.data.geo_fencing;
        this.calculateAndDisplayRoute(this.directionsService, this.directionsDisplay);
        if (geoFencingPoint) {
          this.drawGeofencing = geoFencingPoint.boundries;
          this.drawGeofencingType = geoFencingPoint.type;
          this.drawingGeofence();
        }
      }
    )
  }
  calculateAndDisplayRoute(directionsService, directionsDisplay) {
    let waypts = [];
    this.routePaths = [];
    let routeLength = this.employeeMeetingList.length;
    let imgUrl = Config.AuthenticationURL + 'public/route-icon/';
    if (routeLength > 1) {
      this.routeOrigin = this.employeeMeetingList[0]['lat'] + ',' + this.employeeMeetingList[0]['lng'];//$('#'+originId).val();
      this.routePaths.push(this.routeOrigin);
      this.originPath = '';
      this.routeDestination = this.employeeMeetingList[routeLength - 1]['lat'] + ',' + this.employeeMeetingList[routeLength - 1]['lng'];
      if (routeLength > 2) {
        for (let i = 1; i < routeLength - 1; i++) {
          let routeWayPoint = this.employeeMeetingList[i]['lat'] + ',' + this.employeeMeetingList[i]['lng'];
          waypts.push({
            location: routeWayPoint,
            stopover: true
          });
          this.routePaths.push(routeWayPoint);
          let wayPointLatLng = new google.maps.LatLng(this.employeeMeetingList[i]['lat'], this.employeeMeetingList[i]['lng']);
          let wayPointIcon = imgUrl + (i + 1) + '.png';
          this.createMarker(wayPointLatLng, '', this.employeeMeetingList[i]['point_name'], this.employeeMeetingList[i]['image_url'], wayPointIcon);
        }
      }
      this.routePaths.push(this.routeDestination);
    }
    $('.route_input').each(function () {
      var inputId = $(this).attr('id');
    })
    var _this = this;
    this.routeOrigin = new google.maps.LatLng(this.employeeMeetingList[0]['lat'], this.employeeMeetingList[0]['lng']);
    this.routeDestination = new google.maps.LatLng(this.employeeMeetingList[routeLength - 1]['lat'], this.employeeMeetingList[routeLength - 1]['lng']);
    this.createMarker(this.routeOrigin, '', this.employeeMeetingList[0]['point_name'], this.employeeMeetingList[0]['image_url'], imgUrl + '1.png');
    let destinationIcon = imgUrl + '' + routeLength + '.png';
    if (routeLength > 32) {
      destinationIcon = imgUrl + '32.png'
    }
    this.createMarker(this.routeDestination, '', this.employeeMeetingList[routeLength - 1]['point_name'], this.employeeMeetingList[routeLength - 1]['image_url'], destinationIcon);
    directionsService.route({
      origin: this.routeOrigin,
      destination: this.routeDestination,
      waypoints: waypts,
      optimizeWaypoints: false,
      travelMode: 'DRIVING'
    }, function (response, status) {
      if (status === 'OK') {
        directionsDisplay.setDirections(response);
        var route = response.routes[0];
        _this.routeDistance = 0;
        _this.routeETA = 0;
        for (var i = 0; i < route.legs.length; i++) {
          _this.routeDistance += Number(route.legs[i]['distance']['value']);
          _this.routeETA += Number(route.legs[i]['duration']['value']);
        }
      } else {
        let routeLen = _this.employeeMeetingList.length;
        if (routeLen >= 2) {
          this.commonService.showCustomError('Directions request failed due to ' + status);
          return false;
        }
      }
    });
  }
  createMarker(latlng, description, name, imgUrl, icon) {
    var image = {
      url: imgUrl,
      size: new google.maps.Size(32, 32),
      shape: { coords: [17, 17, 18], type: 'circle' },
      optimized: false
    };
    var content = '<div style="background: url(' + imgUrl + ') no-repeat center;width:150px;height: 90px;"></div><br><b style="font-weight:bold">' + name + '</b><p>' + description + '</p>';
    var _this = this;
    var infowindow = new google.maps.InfoWindow({
      content: content,
      maxWidth: 150,
      width: 100
    });
    var marker = new google.maps.Marker({
      position: latlng,
      icon: icon,
      map: _this.map,
      shape: { coords: [17, 17, 18], type: 'circle' }
    });
    google.maps.event.addListener(marker, 'click', function () {
      infowindow.open(_this.map, marker);
    });
    this.markers.push(marker);
  }
  drawingGeofence() {
    var _this = this;
    if (this.drawGeofencingType == 2) {
      let rectanglelatLng = JSON.parse(_this.drawGeofencing);
      var rectangle = new google.maps.Rectangle({
        strokeColor: '#000000',
        strokeOpacity: 1,
        strokeWeight: 2,
        fillColor: '#262525',
        fillOpacity: 0.35,
        map: _this.map,
        bounds: rectanglelatLng
      });
      this.drawingManager.setOptions({
        drawingControl: false
      });
      this.geofenceDraw = true;
    }
    if (this.drawGeofencingType == 3) {
      let polygonlatLng = JSON.parse(_this.drawGeofencing);
      var rectangle = new google.maps.Polygon({
        map: _this.map,
        paths: polygonlatLng,
        strokeColor: '#000000',
        strokeOpacity: 1,
        strokeWeight: 2,
        fillColor: '#262525',
        fillOpacity: 0.35,
        draggable: true,
        geodesic: true
      });
      this.drawingManager.setOptions({
        drawingControl: false
      });
      this.geofenceDraw = true;
    }
    if (this.drawGeofencingType == 1) {
      let circlelatLng = JSON.parse(_this.drawGeofencing);
      var marker = new google.maps.Marker({
        map: _this.map,
        position: new google.maps.LatLng(circlelatLng.lat, circlelatLng.lng),
        title: 'Some location'
      });
      var circle = new google.maps.Circle({
        map: _this.map,
        radius: circlelatLng.radius,    // 10 miles in metres
        fillColor: '#262525'
      });
      circle.bindTo('center', marker, 'position');
      this.drawingManager.setOptions({
        drawingControl: false
      });
      this.geofenceDraw = true;
    }
  }
  getReportingUserList(locationId) {
    this.commonService.getReportingUserList(locationId).subscribe(res => {
      if (res.result == true) {
        this.reportingUserList = res.data;
        if (this.reportingUserList.length > 1) {
          this.reportingUserList.unshift(this.defaultSelectObject);
        }
      }
    }, error => {
      this.customErrorHandler.handleHttpError(error, 'getRoleList');
    });
  }
  getLocationList() {
    this.commonService.getLocationList().subscribe(res => {
      if (res.result == true) {
        this.locationList = res.data;
        if (this.locationList.length > 1) {
          this.locationList.unshift(this.defaultSelectObject);
        }
      }
    }, error => {
      this.customErrorHandler.handleHttpError(error, 'getRoleList');
    });
  }

  employeeListFunction(locationId) {
    this._routeService.employeeLocationList(locationId).subscribe(res => {
        this.employeeMemberList = res.data;
        if (this.employeeMemberList.length > 1) {
          this.employeeMemberList.unshift(this.defaultSelectObject);
        }
    }, error => {
        this.customErrorHandler.handleHttpError(error, 'employeeList');
    });
  }
  onUserItemSelect(item: any) {
    this.reportingTo = item.id;
    this.routeList(1);
  }
  onUserItemDeSelect(item: any) {
    this.reportingTo = '';
    this.routeList(1);
  }

  onEmployeeSelect(item: any) {
    this.employeeId = item.id;
    this.routeList(1);
  }
  onEmployeeDeSelect(item: any) {
    this.employeeId = '';
    this.routeList(1);
  }

  onLocationItemSelect(item: any) {
    this.locationId = item.id;
    this.reportingTo = '';
    this.employeeId = '';
    this.reportingUserList = [];
    this.employeeMemberList = [];
    this.reportingSelectedItem = [];
    this.employeeSelectedItem = [];
    // if (this.locationId != '') {
    this.getReportingUserList(this.locationId);
    this.employeeListFunction(this.locationId);
    // }
    this.routeList(1);
  }
  onLocationItemDeSelect(item: any) {
    this.locationId = '';
    this.reportingTo = '';
    this.employeeId = '';
    this.employeeMemberList = [];
    this.reportingUserList = [];
    this.reportingSelectedItem = [];
    this.employeeSelectedItem = [];
    this.routeList(1);
  }
}

