import { CustomErrorHandler } from './../../errorhandler/custom-errorhandler';
import { LoginService } from './../login/login.service';
import { Injectable } from '@angular/core';
import { HttpHelper } from '../../helpers/http.helper';
import { Config } from '../../config/config';
import swal from 'sweetalert2';
@Injectable()
export class CommonService {
    constructor(
        private _httpHelper: HttpHelper,
        private loginService: LoginService,
        private customErrorHandler: CustomErrorHandler
    ) { }
    public showCustomError(message) {
        swal({
            type: 'error',
            customClass: 'swal-wide swal-error-custom-class',
            text: message,
        });
    }
    public showSuccessMessage(message: string) {
        swal({
            position: 'bottom',
            background: '#d7f2c9',
            title: message,
            backdrop: false,
            customClass: 'swal-wide swal-custom-class',
            showConfirmButton: false,
            showCloseButton: true,
            timer: 2000
        });
    }
    public showCustomConfirmation(message) {
        swal({
            title: 'Are you sure?',
            text: message,
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: 'Yes, delete it!',
        }).then((result) => {
            if (result.value) {
                return true;
            }
        });
    }
    doughnutChartData() {
        try {
            return this._httpHelper.get(Config.AuthenticationBaseURL1 + 'get-dashboard-graph-data')
        }
        catch (e) {
        }
    }
    showErrorMessage(error) {
        if (error.message === 'Invalid Token' || error.message === 'Token Required') {
            swal({
                type: 'error',
                customClass: 'swal-wide swal-error-custom-class',
                text: error.message,
                allowOutsideClick: false,
            }).then((result) => {
                if (result.value) {
                    this.logout();
                }
            });
        }
        else {
            if (error.message == '') {
                swal({
                    type: 'error',
                    customClass: 'swal-wide swal-error-custom-class',
                    text: 'Something went wrong.',
                });
            }
            else {
                swal({
                    type: 'error',
                    customClass: 'swal-wide swal-error-custom-class',
                    text: error.message,
                });
            }
        }
    }
    logout() {
        this.loginService.logOut().subscribe(
            res => {
                if (res.result == true) {
                    localStorage.clear();
                    window.location.reload();
                }
                else {
                    this.showErrorMessage(res);
                }
            },
            error => {
                this.customErrorHandler.handleHttpError(error, 'logout');
            });
    }
    getProspectList(params) {
        try {
            return this._httpHelper.get(Config.AuthenticationBaseURL1 + 'get-meeting-list' , params);
        }
        catch (e) {
        }
    }
    showWarningMessage(msg) {
        swal({
            type: 'success',
            allowOutsideClick: false,
            text: msg,
        }).then((result) => {
            if (result.value) {
                this.logout();
            }
        });
    }
    getLocationList() {
        try {
            return this._httpHelper.get(Config.AuthenticationBaseURL1 + 'get-location-in-user')
        }
        catch (e) {
        }
    }
    getReportingUserList(locationId) {
        try {
            let request = { location_id: locationId }
            return this._httpHelper.get(Config.AuthenticationBaseURL1 + 'get-user-by-location', request)
        }
        catch (e) {
        }
    }

    getReportingOwnerList(request){
        try {
            return this._httpHelper.get(Config.AuthenticationBaseURL1 + 'get-my-reporting-user', request)
        }
        catch (e) {
        }
    }
}
