import { Injectable } from "@angular/core";
import { HttpHelper } from "../../helpers/http.helper";
import { Config } from "../../config/config";
const company_id = localStorage.getItem('company_id');
@Injectable()
export class CompanySettingsService {
    constructor(private _httpHelper: HttpHelper) {
    }
    getCompanySettings() {
        try {
            var request = {
                "company_id": company_id
            };
            return this._httpHelper.post(Config.AuthenticationBaseURL1 + 'get-company', request);
        }
        catch (e) {
        }
    }
    saveCompanySettings(request) {
        try {
            return this._httpHelper.post(Config.AuthenticationBaseURL1 + 'update-company', request);
        }
        catch (e) {
        }
    }
    getRadiusList() {
        try {
            return this._httpHelper.get(Config.AuthenticationBaseURL1 + 'get-radius');
        }
        catch (e) {
        }
    }
}
