import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
declare var $;
@Component({
  selector: 'app-app-download',
  templateUrl: './app-download.component.html',
  styleUrls: ['./app-download.component.css']
})
export class AppDownloadComponent implements OnInit {

  constructor(
    public router: Router
    ) { }

  ngOnInit() {
    var loginchk = localStorage.getItem('isLogin');
    if (loginchk == "1") {
      this.router.navigate(["dashboard"]);
    }
    $('body').addClass('scroll');
  }
  ngOnDestroy() {
    $('body').removeClass('scroll');
  }

}
