import { Component, OnInit } from '@angular/core';

declare var $: any;
@Component({
  selector: 'app-announcements',
  templateUrl: './announcements.component.html',
  styleUrls: ['./announcements.component.css'],
  host: {
    'class': 'fullHeightBlock'
  }
})
export class AnnouncementsComponent implements OnInit {
  constructor() { }
  ngOnInit() {
    if ($(window).width() > 760) {
      $('.fullHeightBlock').scrollbar();
    }
  }
  ngOnDestroy() {
    $('.fullHeightBlock').remove();
  }
}
