import { AuthGuard } from './../../services/_guards/auth.guard';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { RouteComponent } from './route.component';
import { RouteListComponent } from './route-list/route-list.component';
import { RouteCreateComponent } from './route-create/route-create.component';
import { RouteUpdateComponent } from './route-update/route-update.component';
import { CreateGeofenceComponent } from './create-geofence/create-geofence.component';
import { AssignChecklistComponent } from './assign-checklist/assign-checklist.component';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown/angular2-multiselect-dropdown';
import { InfiniteScrollModule } from 'angular2-infinite-scroll';
import { Ng2PaginationModule } from 'ng2-pagination';
export const routes: Routes = [
  {
    path: 'route',
    component: RouteComponent, canActivate: [AuthGuard],
    children: [
      { path: '', redirectTo: 'route-list', pathMatch: 'full' },
      { path: 'route-list', component: RouteListComponent },
      { path: 'route-create', component: RouteCreateComponent },
      { path: 'route-update', component: RouteUpdateComponent },
      { path: 'create-geofence', component: CreateGeofenceComponent },
      { path: 'assign-checklist', component: AssignChecklistComponent },
    ]
  }
];
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule.forChild(routes),
    InfiniteScrollModule,
    AngularMultiSelectModule,
    Ng2PaginationModule
  ],
  declarations: [
    RouteComponent,
    RouteListComponent,
    RouteCreateComponent,
    RouteUpdateComponent,
    CreateGeofenceComponent,
    AssignChecklistComponent
  ]
})
export class RouteModule { }
