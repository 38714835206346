import { MeetingService } from './../../services/meeting/meeting.service';
import { Component, OnInit } from '@angular/core';
import { HomeSharedService } from '../../services/shared/home-shared';
import { Subject } from 'rxjs/Subject';
import "rxjs/add/operator/debounceTime";
import "rxjs/add/operator/distinctUntilChanged";
import { CustomErrorHandler } from '../../errorhandler/custom-errorhandler';
import { CommonService } from '../../services/common/common.service';
import { Constants } from '../../common/constants';
declare var $: any;
@Component({
	selector: 'employee',
	templateUrl: './employee.component.html',
	host: {
		'class': 'fullHeightBlock'
	}
})
export class EmployeeComponent implements OnInit {
	searchModalChanged: Subject<string> = new Subject<string>();
	searchTextValue: any;
	filter: string = '';
	startDate: string = '';
	endDate: string = '';
	filterList: any = [];
	reportingUserList = [];
	selectedItems = [];
	reportingUserSettings = {};
	locationList: any = [];
	locationSettings: any = {};
	locationSelectedItem: any = [];
	reportingTo: any = '';
	locationId: any = '';
	defaultSelectObject: any = {id: '',itemName: 'All'}
	filterLabel = Constants.filterLabel;

	meetingTypeList: any = [];
	meetingSelectedItems: any = [];
	meetingSettings = {};
	prospectLabel: any = {};
	constructor(private prospectService: MeetingService, public homeSharedService: HomeSharedService, private customErrorHandler: CustomErrorHandler, private commonService: CommonService) {
	}
	ngOnInit() {
		this.filterListDetails();
		this.getLocationList();
		this.getReportingUserList(this.locationId);
		this.searchModalChanged.debounceTime(1000)
			.subscribe((model) => {
				this.homeSharedService.publishHomeDate(this.startDate, this.endDate, this.filter, this.searchTextValue, this.reportingTo, this.locationId);
			});
		var _this1;
		_this1 = this;
		if ($(window).width() > 760) {
			$('.fullHeightBlock').scrollbar();
		}
		this.selectedItems = [];
		this.reportingUserSettings = {
			singleSelection: true,
			text: "Select Reporting Person",
			selectAllText: 'Select All',
			unSelectAllText: 'UnSelect All',
			enableSearchFilter: true,
			classes: "myclass custom-class sm_max_300 width100 singleSelection m_b_15 block_elem"
		};
		this.locationSettings = {
			singleSelection: true,
			text: "Select "+ Constants.locationLabel,
			enableSearchFilter: true,
			classes: "singleSelection sm_max_300 width100 m_b_15 block_elem"
		};
		this.prospectLabel = localStorage.getItem('prospectLabel');
		this.meetingSettings = {
	      	singleSelection: true,
	      	text: "Select " + this.prospectLabel + " Type",
	      	enableSearchFilter: true,
	      	classes: "myclass custom-class singleSelection block_elem m_b_15"
	    };
	}
	onItemSelect(item: any) {
		console.log(item.id);
		this.reportingTo = item.id;
		this.homeSharedService.publishHomeDate(this.startDate, this.endDate, this.filter, this.searchTextValue, this.reportingTo, this.locationId);
	}
	OnItemDeSelect(item: any) {
		this.reportingTo = '';
		this.homeSharedService.publishHomeDate(this.startDate, this.endDate, this.filter, this.searchTextValue, this.reportingTo, this.locationId);
	}
	onkeyup_value(event) {
		this.searchTextValue = event.target.value;
		this.searchModalChanged.next();
	}
	filterData(value) {
		this.filter = value;
		this.homeSharedService.publishHomeDate(this.startDate, this.endDate, this.filter, this.searchTextValue, this.reportingTo, this.locationId);
	}
	filterListDetails() {
		this.filterList = [];
		this.meetingTypeList = [];
		this.prospectService.meetingTypeList().subscribe(
			res => {
				if (res.result == true) {
					this.filterList = res.data;
					this.meetingTypeList = res.data;
					let allParameter = {id: '', itemName: 'Show All'}
					this.meetingTypeList.push(allParameter);
				}
			},
			error => {
				this.customErrorHandler.handleHttpError(error, 'filterList');
			}
		)
	}
	getReportingUserList(locationId) {
		this.commonService.getReportingUserList(locationId).subscribe(res => {
			if (res.result == true) {
				this.reportingUserList = res.data;
				if(this.reportingUserList.length > 1){
					this.reportingUserList.unshift(this.defaultSelectObject);
				}
			}
		}, err => {
			this.customErrorHandler.handleHttpError(err, 'getRoleList');
		})
	}
	getLocationList() {
		this.commonService.getLocationList().subscribe(res => {
			if (res.result == true) {
				this.locationList = res.data;
				if(this.locationList.length > 1){
					this.locationList.unshift(this.defaultSelectObject);
				}
			}
		}, err => {
			this.customErrorHandler.handleHttpError(err, 'getRoleList');
		})
	}
	onLocationItemSelect(item: any) {
		this.locationId = item.id;
		this.selectedItems = [];
		this.reportingTo = '';
		this.reportingUserList = [];
		this.selectedItems = [];
		// if(this.locationId != ''){
			this.getReportingUserList(this.locationId);
		// }
		this.homeSharedService.publishHomeDate(this.startDate, this.endDate, this.filter, this.searchTextValue, this.reportingTo, this.locationId);
	}
	onLocationItemDeSelect(item: any) {
		this.locationId = '';
		this.reportingTo = '';
		this.reportingUserList = [];
		this.selectedItems = [];
		this.homeSharedService.publishHomeDate(this.startDate, this.endDate, this.filter, this.searchTextValue, this.reportingTo, this.locationId);
	}
	filterSearch(Search,LocationD,reportUser){
		this.locationId = LocationD.id;
		this.reportingTo = reportUser.id;
		if(this.locationId != '' && this.locationId != undefined){
			this.getReportingUserList(this.locationId);
		}
		this.homeSharedService.publishHomeDate(this.startDate, this.endDate, this.filter, this.searchTextValue, this.reportingTo, this.locationId);
		$('body').find('.filter_box').toggleClass('open_box');
	}

	selectMeetingByType(item: any){
		if(item.id == ''){
			this.filterData('');
		}else{
			this.filterData(item.itemName);
		}
	}

	deselectMeetingByType(item: any){
		this.filterData('');
	}

	employeeModule(){
		this.locationSelectedItem = [];
		this.meetingSelectedItems = [];
		this.selectedItems = [];
	}
	ngOnDestroy() {
		$('.fullHeightBlock').remove();
	}
}

