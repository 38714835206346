import { Injectable } from '@angular/core';
import { HttpHelper } from '../../helpers/http.helper';
import { Config } from '../../config/config';
@Injectable()
export class LocationService {
    constructor(private _httpHelper: HttpHelper) {
    }
    locationList(pageNo, rowPerPage, search, exportReport) {
        try {
            return this._httpHelper.get(Config.AuthenticationBaseURL1 + 'get-location?page=' + pageNo + '&per_page=' + rowPerPage + '&search=' + search + '&export=' + exportReport)
        }
        catch (e) {
        }
    }
    selectLocationById(request) {
        try {
            return this._httpHelper.get(Config.AuthenticationBaseURL1 + 'get-single-location', request)
        }
        catch (e) {
        }
    }
    deleteLocation(request) {
        try {
            return this._httpHelper.delete(Config.AuthenticationBaseURL1 + 'delete-location', request)
        }
        catch (e) {
        }
    }
    createLocation(request) {
        try {
            return this._httpHelper.post(Config.AuthenticationBaseURL1 + 'create-location', request)
        }
        catch (e) {
        }
    }
    updateLocation(request) {
        try {
            return this._httpHelper.post(Config.AuthenticationBaseURL1 + 'update-location', request)
        }
        catch (e) {
        }
    }
}
