import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { ResetPasswordComponent } from './reset-password.component';
export const routes: Routes = [
  {
    path: 'resetpassword',
    component: ResetPasswordComponent
  }
];
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule.forChild(routes)
  ],
  declarations: [
    ResetPasswordComponent
  ]
})
export class ResetPasswordModule { }
