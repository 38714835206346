import { Injectable } from '@angular/core';
import { HttpHelper } from '../../helpers/http.helper';
import { Config } from '../../config/config';
@Injectable()
export class HierarchyService {
    constructor(
        private _httpHelper: HttpHelper
    ) { }
    getOrganizationalHierarchy() {
        try {
            return this._httpHelper.get(Config.AuthenticationBaseURL1 + 'hierarchy/get-company-hierarchy')
        }
        catch (e) {
        }
    }
}
