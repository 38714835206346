import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { VoterService } from '../../../services/voter/voter.service';
import { CommonService } from '../../../services/common/common.service';
import { CustomErrorHandler } from '../../../errorhandler/custom-errorhandler';
import { Angular2Csv } from 'angular2-csv/Angular2-csv';
import { Config } from '../../../config/config';
import { Router } from '@angular/router';
import { Constants } from '../../../common/constants';
import swal from 'sweetalert2';
import { MeetingService } from '../../../services/meeting/meeting.service';
declare var moment: any;
declare var $: any;
declare var google: any;

@Component({
  selector: 'app-evm-assignment-list',
  templateUrl: './evm-assignment-list.component.html',
  styleUrls: ['./evm-assignment-list.component.css']
})
export class EvmAssignmentListComponent implements OnInit {

  evmList: any = [];
  perPage: number = 10;
  pageNo: number = 1;
  searchTextValue: any = '';
  loading: boolean;
  totalItem: number = 0;
  page: number = 2;
  searchDetails: any = {};
  exportReport: any;
  imageBaseUrl: string = Config.AuthenticationURL;
  mediaType: any;
  modal: any;
  previewImage: any;
  noDataMsg: any;
  searchTempVal: any;
  startDate: any;
  endDate: any;
  tempSearch: any;
  maxPage: number = 10;
  feedbackData: any = [];
  isDemoTenant: boolean;
  mapModal: any;
  boothLocation: any;
  map: any;
  employeeLabel = localStorage.getItem('employeeLabel');
  evmDetails: any = [];
  filterLabel = Constants.filterLabel;
  locationList: any = [];
  locationSettings: any = {};
  locationSelectedItem: any = [];
  locationId = '';
  teamList = [];
  teamSettings = {};
  teamSelectedItems: any = [];
  teamId = '';
  employeeMemberList: any = [];
  employeeMember: any = [];
  employeeMemberSettings = {};
  employeeId = '';
  defaultSelectObject: any = { id: '', itemName: 'All' }
  constructor(
    public voterService: VoterService,
    public commonService: CommonService,
    private customErrorHandler: CustomErrorHandler,
    private router: Router,
    private _prospectService: MeetingService
  ) { }
  ngOnInit() {
    this.isDemoTenant = (localStorage.getItem('isDemoTenant') == 'true');
    if ($(window).width() < 768) {
      this.maxPage = 6;
    }
    this.searchTempVal = '';
    this.searchDetails.search_by = '';
    this.modal = document.getElementById('previewModel');
    this.mapModal = document.getElementById('mapModel');
    this.getEvmList(1);
    this.getLocationList();
    this.getEmployeeList(this.locationId);
    this.teamListFunction(this.locationId);
    this.tempSearch = '';
    var _this = this;
    var start = moment().subtract(6, 'days');
    var end = moment();
    function cb(start, end) {
      $('#reportrange span').html(start.format('MMMM D, YYYY') + ' - ' + end.format('MMMM D, YYYY'));
      _this.startDate = start.format('YYYY-MM-DD');
      _this.endDate = end.format('YYYY-MM-DD');
      _this.evmList = [];
      _this.getEvmList(1);
    }
    $('#reportrange').daterangepicker({
      startDate: start,
      endDate: end,
      maxDate: new Date(),
      ranges: {
        'Today': [moment(), moment()],
        'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
        'Last 7 Days': [moment().subtract(6, 'days'), moment()],
        'Last 30 Days': [moment().subtract(29, 'days'), moment()],
        'This Month': [moment().startOf('month'), moment().endOf('month')],
        'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
      }
    }, cb);
    cb(start, end);
    this.locationSettings = {
      singleSelection: true,
      text: "Select "+ Constants.locationLabel,
      enableSearchFilter: true,
      classes: "singleSelection block_elem m_b_15"
    };

    this.teamSettings = {
			singleSelection: true,
      text: "Select Team",
      enableSearchFilter: true,
      classes: "singleSelection block_elem m_b_15"
    }
    this.employeeMemberSettings = {
      singleSelection: true,
      text: "Select " + this.employeeLabel,
      enableSearchFilter: true,
      classes: "myclass custom-class singleSelection block_elem m_b_15"
    }; 
  }
  getEvmList(currentPage) {
    this.pageNo = currentPage;
    this.page = currentPage;
    let quoteStrings: any = {
      page: currentPage,
      perPage: this.perPage,
      search: this.searchTextValue,
      // startDate: this.startDate,
      // endDate: this.endDate,
      export: '',
      employee_id: this.employeeId, 
      location_id: this.locationId,  
      team_id: this.teamId
    }
    // this.evmList = [];
    this.loading = true;
    this.voterService.getEvmAssignmentList(quoteStrings).subscribe(
      response => {
        this.loading = false;
        let res: any = response;
        this.noDataMsg = res.message;
        if (res.result == true) {
          this.totalItem = res.data.total;
          this.evmList = res.data.data;
          console.log(this.evmList);
        } else {
          this.evmList = [];
          this.commonService.showErrorMessage(res);
        }
      },
      error => {
        this.loading = false;
        this.customErrorHandler.handleHttpError(error, 'getCheckList');
      });
  }
  onkeyup_value(event) {
    this.searchTextValue = event.target.value;
    if (this.tempSearch != this.searchTextValue) {
      this.getEvmList(1);
    }
    this.tempSearch = this.searchTextValue;
  }
  openModal(data) {
    this.evmDetails = data;
    this.modal.style.display = 'block';
  }
  closeModal() {
    this.feedbackData = [];
    this.previewImage = '';
    this.modal.style.display = 'none';
  }
  export() {
    var options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: true,
      useBom: true,
      title: "EVM Assignment List",
      employee_label: this.employeeLabel
    };
    let quoteStrings: any = {
      pageNo: 0,
      perPage: 0,
      search: this.searchTextValue,
      // startDate: this.startDate,
      // endDate: this.endDate,
      export: 'yes',
      employee_id: this.employeeId, 
      location_id: this.locationId,  
      team_id: this.teamId
    }
    this.voterService.getEvmAssignmentList(quoteStrings).subscribe(
      response => {
        let res: any = response;
        if (res.result == true) {
          new Angular2Csv(res.data, 'Evm Assignment List', options);
        }
      },
      error => {
        this.customErrorHandler.handleHttpError(error, 'getEvmAssignmentList');
      });
  }

  editEvmDetails(electionDayId) {
    if (this.isDemoTenant) {
      return false;
    }
		localStorage.setItem('editEvmAssignmentId', electionDayId);
		this.router.navigate(['./voter/evm-assignment']);
	}
	deleteEvmDetails(electionDayId) {
    if (this.isDemoTenant) {
      return false;
    }
    let req = {
      id: electionDayId
    }
		swal({
			text: Constants.DeleteMsg,
			type: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#d33',
			cancelButtonColor: '#3085d6',
			confirmButtonText: 'Delete',
		}).then((result) => {
			if (typeof result.value != 'undefined') {
				this.voterService.deleteEvmAssignmentDetail(req).subscribe(
					res => {
						if (res.result == true) {
							this.commonService.showSuccessMessage(res.message);
							this.getEvmList(1);
						} else {
							this.commonService.showCustomError(res.message);
						}
					},
					error => {
						this.customErrorHandler.handleHttpError(error, 'employeeReport');
					});
			}
		});
  };
  
  openMapModal(lat, lng, title) {
    this.mapModal.style.display = 'block';
    this.boothLocation = title;
    this.getMapLocationDetails(lat, lng);
  }
  closeMapModal() {
    this.mapModal.style.display = 'none';
    this.boothLocation = '';
    this.getMapLocationDetails(0, 0);
  }

  getMapLocationDetails(lat, lng) {
    let upLat = parseFloat(lat);
    let upLng = parseFloat(lng);
    this.map = new google.maps.Map(document.getElementById('map'), {
      zoom: 2,
      center: { lat: upLat, lng: upLng },
      gestureHandling: 'cooperative',
      styles: Constants.MapStyle
    });
    let myLatLng = {lat: upLat, lng: upLng};
    let marker = new google.maps.Marker({
      position: myLatLng,
      map: this.map,
      title: this.boothLocation
    });
  }

  getLocationList() {
    this.commonService.getLocationList().subscribe(res => {
      if (res.result == true) {
        this.locationList = res.data;
        if (this.locationList.length > 1) {
          this.locationList.unshift(this.defaultSelectObject);
        }
      }
    }, err => {
      this.customErrorHandler.handleHttpError(err, 'getRoleList');
    });
  }

  getEmployeeList(location) {
    this._prospectService.employeeLocationList(location).subscribe(
      res => {
        this.employeeMemberList = res.data;
        if (this.employeeMemberList.length > 1) {
          this.employeeMemberList.unshift(this.defaultSelectObject);
        }
      },
      error => {
        this.customErrorHandler.handleHttpError(error, 'employeeList');
      });
  }
  
  teamListFunction(locationId) {
    this._prospectService.teamList(locationId).subscribe(
      res => {
        this.teamList = res.data;
        if (this.teamList.length > 1) {
          this.teamList.unshift(this.defaultSelectObject);
        }
      },
      error => {
        this.customErrorHandler.handleHttpError(error, 'teamList');
      });
  }

  locationItemSelect(item: any) {
    this.locationId = item.id;
    this.teamList = [];
    this.teamId = '';
    this.employeeId = '';
    this.teamSelectedItems = [];
    this.employeeMemberList = [];
    this.employeeMember = [];    
    this.getEvmList(1)
    this.getEmployeeList(this.locationId);
    this.teamListFunction(this.locationId);
  }
  locationItemDeSelect(item: any) {
    this.locationId = '';
    this.teamId = '';
    this.employeeId = '';
    this.teamList = [];
    this.teamSelectedItems = [];
    this.employeeMemberList = [];
    this.employeeMember = [];    
    this.getEvmList(1)
    this.getEmployeeList(this.locationId);
    this.teamListFunction(this.locationId);
  }

  onUserItemSelect(item: any) {
    this.teamId = '';
    this.employeeId = item.id;
    this.getEvmList(1)
  }
  onUserItemDeSelect(item: any) {
    this.teamId = '';
    this.employeeId = '';
    this.getEvmList(1)
  }

  onTeamSelect(item: any) {
    this.employeeId = '';
    this.teamId = item.id;
    this.getEvmList(1)
  }
  onTeamDeSelect(item: any) {
    this.employeeId = '';
    this.teamId = '';
    this.getEvmList(1)
  }

}
