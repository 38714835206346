import { Component, OnInit, ViewChild, ElementRef, ViewEncapsulation } from '@angular/core';
import { CustomErrorHandler } from '../../errorhandler/custom-errorhandler';
import { CommonService } from '../../services/common/common.service';
import { AnnouncementService } from '../../services/announcements/announcements.service';
import { Config } from '../../config/config';
import { Router } from '@angular/router';
import swal from 'sweetalert2';
import { Constants } from '../../common/constants';
declare var $;

@Component({
  selector: 'app-all-announcements',
  templateUrl: './all-announcements.component.html',
  styleUrls: ['./all-announcements.component.css'],
  host: {
    'class': 'fullHeightBlock'
  }
})
export class AllAnnouncementsComponent implements OnInit {

		loading: any;
  	perPage: number = 10;
  	pageNo: number = 1;
  	page: number = 2;
  	searchTextValue: any = '';
  	totalItem: number = 0;
  	tempSearch: any;
  	annoucementData: any = [];
  	noDataMsg: any;
		isPrimaryUser : boolean = false;
		imageBaseUrl: string = Config.AuthenticationURL;
		images: any = [];
		isImagesModel: boolean;
		galleryNextBtn : any;
  	
  	constructor(
	    private router: Router,
	    private customErrorHandler: CustomErrorHandler,
	    private commonservice: CommonService,
	    public announcementService: AnnouncementService
		) { }

  	ngOnInit() {
			this.isImagesModel = false;
  		this.getAnnoucement(1);
			if($(window).width()>760){
				$('.fullHeightBlock').scrollbar();
			}
			this.galleryNextBtn = document.getElementsByClassName('ui-galleria-nav-next');
  	}
		ngOnDestroy() {
			$('.fullHeightBlock').remove();
		}

  	getAnnoucement(currentPage) {
	    this.loading = true;
	    this.page = currentPage;
	    let queryStrings: any = {
	      page: currentPage,
	      perPage: this.perPage,
	      search: this.searchTextValue,
	    }
	    this.announcementService.getAllAnnouncementList(queryStrings).subscribe(res => {
	      if (res.result == true) {
	        this.totalItem = res.data.total;
	        this.annoucementData = res.data.data;
	        this.noDataMsg = res.message;
	        this.loading = false;
	      } else {
	        this.commonservice.showErrorMessage(res);
	      }
	    }, err => {
	      this.customErrorHandler.handleHttpError(err, 'getAnnoucement');
	      this.loading = false;
	    });
	}

	onSearch(event){
		this.searchTextValue = event.target.value;
	    if (this.tempSearch != this.searchTextValue) {
	      	this.getAnnoucement(1);
	    	this.tempSearch = this.searchTextValue;
	    }
	}

	openModal(data) {
    this.isImagesModel = true;
    this.images = [];
    data.forEach((element, idx) => {
      let imgObj: any = {};
      imgObj.source = this.imageBaseUrl + element;
      imgObj.title = 'Image ' + (idx + 1);
      this.images.push(imgObj);
    });
    setTimeout(() => {
      if (this.images.length == 1) {
        this.galleryNextBtn[0].style.display = 'none';
      } else {
        this.galleryNextBtn[0].style.display = 'block';
      }
    }, 100);
  }

  closeModal() {
    this.images = [];
    this.isImagesModel = false;
  }

  onImageChange(event: any) {
    let lastIdx = this.images.length - 1;
    if (event.index == lastIdx) {
      $('.ui-galleria-nav-next').css('display', 'none');
    } else {
      $('.ui-galleria-nav-next').css('display', 'block');
    }
  }
}
