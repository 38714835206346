import { environment } from './../../../environments/environment';
import { Injectable } from "@angular/core";
import { Config } from "../../config/config";
@Injectable()
export class ConfigService {
    constructor() {
    }
    public async  load(): Promise<void> {
        if (Config.BasecampScoutWebURL == '') {
            this.getUrl();
        }
    }
    private getUrl() {
        if (window.location.hostname == 'localhost') {
            Config.BasecampScoutWebURL = 'https://live.basecampscout.com' + window.location.pathname;
            // Config.BasecampScoutWebURL = 'http://scout.basecampscout.com' + window.location.pathname;
            // Config.BasecampScoutWebURL = 'http://scoutqa.basecampscout.in' + window.location.pathname;
            // Config.BasecampScoutWebURL = 'http://panjascout.basecampscout.com' + window.location.pathname;
            
            // Config.BasecampScoutWebURL = environment.serverUrl + window.location.pathname;
            Config.AuthenticationBaseURL = Config.BasecampScoutWebURL + "live/index.php/auth/";
            Config.AuthenticationBaseURL1 = Config.BasecampScoutWebURL + "live2/api/v1/";
            Config.AuthenticationURL = Config.BasecampScoutWebURL + "live2/";
            Config.ImageDownloadURL = Config.BasecampScoutWebURL + "live2/api/v1/download-image?image=";
            Config.imageBaseUrlCI = Config.BasecampScoutWebURL + "live/";
        } else {           
            // Config.BasecampScoutWebURL = 'http://' + window.location.hostname + window.location.pathname;
            Config.BasecampScoutWebURL = 'https://' + window.location.hostname + window.location.pathname;
            Config.AuthenticationBaseURL = Config.BasecampScoutWebURL + "live/index.php/auth/";
            Config.AuthenticationBaseURL1 = Config.BasecampScoutWebURL + "live2/api/v1/";
            Config.AuthenticationURL = Config.BasecampScoutWebURL + "live2/";
            Config.ImageDownloadURL = Config.BasecampScoutWebURL + "live2/api/v1/download-image?image=";
            Config.imageBaseUrlCI = Config.BasecampScoutWebURL + "live/";
        }
    }
}
