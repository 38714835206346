import { MeetingService } from './../../services/meeting/meeting.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import swal from 'sweetalert2';
import { CompanySettingsService } from '../../services/company-settings/company-settings.service';
import { Constants } from '../../common/constants';
import { CustomErrorHandler } from '../../errorhandler/custom-errorhandler';
import { CommonService } from '../../services/common/common.service';
import { Config } from '../../config/config';
declare var $: any;
@Component({
  selector: 'app-company-settings',
  templateUrl: './company-settings.component.html',
  styleUrls: ['./company-settings.component.css'],
  host: {
    'class': 'fullHeightBlock'
  }
})
export class CompanySettingsComponent implements OnInit {
  companyLogo: string = '';
  companyLogoName: string = '';
  mobileLogo: string = '';
  mobileLogoName: string = '';
  companySettings: any = {};
  serverUrl: string;
  imageextension: any = [];
  companyImage: any;
  mobileImage: any;
  meetingType: string;
  meetingTypeList: any = [];
  meetingTypeErrorMsg: any;
  meetingModal: any;
  meetingDetails: any = {};
  prospectLabel: string;
  geofenceRadius: any;
	geofenceList: any = [];
	geofenceRadiusSelectedItems: any = [];
	geofenceRadiusSettings = {};
  meetingTypeModal: any;
  userDefinedMeeting: any = [];
  isDemoTenant: boolean;
  editDefaultMeetingType: any = '';
  meetingSelection: any = 'Add';
  baseUrlScout: any = '';

  constructor(private router: Router, private _prospectService: MeetingService, private companySettingsService: CompanySettingsService, private customErrorHandler: CustomErrorHandler, private commonService: CommonService) { }
  
  ngOnInit() {
    this.getRadiusList();
    this.meetingType = '';
    this.serverUrl = Config.AuthenticationURL;
    this.getCompanySettings();
    this.companySettings = {};
    this.getMeetingTypeList();
    this.meetingModal = document.getElementById('meetingModal');
    this.meetingTypeModal = document.getElementById('meetingTypeModal');
    this.prospectLabel = localStorage.getItem('prospectLabel');
    //this.imageextension= Constants.imageextensionarray;
    this.baseUrlScout = Config.BasecampScoutWebURL;
    if ($(window).width() > 760) {
      $('.fullHeightBlock').scrollbar();
    }
    this.geofenceRadiusSettings = {
			singleSelection: true,
			text: "Select " + this.prospectLabel +" Geofence radius",
			selectAllText: 'Select All',
			unSelectAllText: 'UnSelect All',
			enableSearchFilter: true,
			classes: "singleSelection"
    };
    this.isDemoTenant = (localStorage.getItem('isDemoTenant') == 'true');
  }
  ngOnDestroy() {
    $('.fullHeightBlock').remove();
  }
  companyChange(elem: HTMLInputElement) {
    this.companyLogoName = elem.files[0].name;
  }
  mobileChange(elem: HTMLInputElement) {
    this.mobileLogoName = elem.files[0].name;
  }

  getRadiusList() {
		this.companySettingsService.getRadiusList().subscribe(res => {
			if (res.result == true) {
				this.geofenceList = res.data;			
			}
		}, error => {
			this.customErrorHandler.handleHttpError(error, 'getRadiusList');
		});
	}

  getCompanySettings() {
    this.companySettingsService.getCompanySettings().subscribe(
      res => {
        if (res.result == true) {
          this.companySettings = res.data;
          let geoTemp = this.geofenceList.find(s => s.itemName == this.companySettings.geo_radius);
					this.geofenceRadiusSelectedItems = [];
					if (typeof geoTemp != 'undefined') {
						this.geofenceRadiusSelectedItems.push(geoTemp);
					}
          this.companySettings.companylogo = this.companySettings.companylogo;
          this.companySettings.companylogo = '';
          this.companySettings.mobilelogo = this.companySettings.mobilelogo;
          if (this.companySettings.logo == '' || this.companySettings.logo == null) {
            this.companyImage = '';
          } else {
            this.companyImage = this.serverUrl + '' + this.companySettings.logo;
          }
          if (this.companySettings.mobile_logo == '' || this.companySettings.mobile_logo == null) {
            this.mobileImage = '';
          } else {
            this.mobileImage = this.serverUrl + '' + this.companySettings.mobile_logo;
          }
        } else {
          this.commonService.showCustomError(res);
        }
      },
      error => {
        this.customErrorHandler.handleHttpError(error, 'getCompanySettings');
      }
    )
  }
  saveCompanySettings() {
    let geo_radius = this.geofenceRadiusSelectedItems.map(function (elem) { return elem.itemName; }).join(",");
    console.log(geo_radius);
    var data = {
        "company_name": this.companySettings.company_name,
        "geo_radius": geo_radius,
        "logo": this.companySettings.companylogo,
        "mobile_logo": this.companySettings.mobilelogo,
        "company_id": this.companySettings.id,
        "meeting_type": this.meetingType
    };
    this.companySettingsService.saveCompanySettings(data).subscribe(res => {
        if (res.result == true) {
          $('.header-company-name').text(res.data.company_name);
          localStorage.setItem('TenantName', res.data.company_name);
          localStorage.setItem('logo_url', res.data.logo);
          $('.comp_logo').attr('src', this.serverUrl + '' + res.data.logo);
          $('#sidebar-company-logo').attr('src', this.serverUrl + '' + res.data.logo);
          this.commonService.showSuccessMessage(res.message);
          this.router.navigate(["dashboard"]);
        } else {
          this.commonService.showCustomError(res.message);
        }
    }, error => {
      this.customErrorHandler.handleHttpError(error, 'saveCompanySettings');
    });
  }

  changeListener($event): void {
    let eventName = $event.target.name;
    let inputValue = $event.target;
    let file: File = inputValue.files[0];
    let myReader: FileReader = new FileReader();
    let imgName = $event.target.files[0].name;

    let imageextensionarray = imgName.split('.').pop();
    if (imageextensionarray === 'jpg' || imageextensionarray === 'png' || imageextensionarray === 'jpeg' || imageextensionarray === 'bmp') {
      myReader.onloadend = (e) => {
        if (eventName == 'companylogo') {
          this.companySettings.companylogo = myReader.result;
          this.companyImage = this.companySettings.companylogo;
          $('.companyLogo').attr('src', myReader.result);
        }
        if (eventName == 'mobilelogo') {
          this.companySettings.mobilelogo = myReader.result;
          this.mobileImage = this.companySettings.mobilelogo;
          $('.mobilelogo').attr('src', myReader.result);
        }
      }

    }
    else {
      if (eventName == 'companylogo') {
        $('.companyLogo').val('');
        this.companySettings.companylogo = '';
        $('input[name=companyLogo]').val('');
        this.companyImage = '';
      }
      if (eventName == 'mobilelogo') {
        $('.mobilelogo').val('');
        this.companySettings.mobilelogo = '';
        $('input[name=mobilelogo]').val('');
        this.mobileImage = '';
      }

      $event.target.files.name = '';
      swal({
        type: 'error',
        text: Constants.extensionmsg,
        confirmButtonText: 'ok',
      })
    }
    myReader.readAsDataURL(file);
  }

  getMeetingTypeList() {
    this._prospectService.meetingTypeList().subscribe(
      res => {
        this.meetingTypeList = res.data;
        let defaultMeeting = res.default_meeting_type;
        this.userDefinedMeeting = res.user_define_meeting;
        if (typeof defaultMeeting != 'undefined' && defaultMeeting != null) {
          this.meetingType = defaultMeeting;
        }
      },
      error => {
        this.customErrorHandler.handleHttpError(error, 'priorityList');
      }
    )
  }

  meetingModalOpen() {
    this.meetingTypeErrorMsg = '';
    this.meetingDetails = {};
    this.meetingModal.style.display = "block";
    this.editDefaultMeetingType = '';
    this.meetingSelection = "Add";
  }

  meetingCloseModal(form: HTMLFormElement) {
    // this.errorMessage = '';
    this.meetingModal.style.display = "none";
    form.resetForm();
  }

  createMeetingType(form: HTMLFormElement) {
    this.meetingModal.style.display = "none";
    let type = this.meetingTypeList.find(s => s.itemName.toLocaleLowerCase() == this.meetingDetails.meeting_name.toLocaleLowerCase());
    // if (typeof type == 'undefined' || type == '') {
    // } else {
    //   this.meetingTypeErrorMsg = this.prospectLabel + ' type already exist.';
    // }
      this.meetingDetails.meeting_label = this.prospectLabel;
      if(this.editDefaultMeetingType && this.editDefaultMeetingType != ''){
        this.meetingDetails.meeting_id = this.editDefaultMeetingType; 
        this._prospectService.updateMeetingType(this.meetingDetails).subscribe(
          res => {
            if (res.result == true) {
              this.getMeetingTypeList();
              this.commonService.showSuccessMessage(res.message);
              this.meetingCloseModal(form);
            } else {
              this.commonService.showCustomError(res.message);
            }
          },
          error => {
            this.customErrorHandler.handleHttpError(error, 'saveCompanySettings');
          }
        )
      }else{
        this._prospectService.addMeetingType(this.meetingDetails).subscribe(
          res => {
            if (res.result == true) {
              this.getMeetingTypeList();
              this.commonService.showSuccessMessage(res.message);
              this.meetingCloseModal(form);
            } else {
              this.commonService.showCustomError(res.message);
            }
          },
          error => {
            this.customErrorHandler.handleHttpError(error, 'saveCompanySettings');
          }
        )
      }

  }

  meetingTypeModalOpen() {
    this.meetingTypeErrorMsg = '';
    this.meetingDetails = {};
    this.meetingTypeModal.style.display = "block";
  }

  meetingTypeCloseModal(form: HTMLFormElement) {
    this.meetingTypeModal.style.display = "none";
    form.resetForm();
  }

  deleteDefaultMeetingType(meetingTypeId){
    this.meetingTypeModal.style.display = "none";
    swal({
      text: Constants.DeleteMsg,
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Delete',
    }).then((result) => {
      if (typeof result.value != 'undefined') {
        let parameter = {
          meeting_id : meetingTypeId
        }

        this._prospectService.deleteMeetingTypeById(parameter).subscribe(
          res => {
            if (res.result == true) {
                this.getMeetingTypeList();
               this.commonService.showSuccessMessage(res.message);
            } else {
              this.commonService.showCustomError(res);
            }
            this.meetingTypeModal.style.display = "none";
          },
          error => {
            this.customErrorHandler.handleHttpError(error, 'priorityList');
          }
        )
      }
    })
  }

  selectDefaultMeetingTypeById(meetingTypeId){
    this.meetingSelection = "Edit";
    this.meetingTypeModal.style.display = "none";
    this.meetingModal.style.display = "block";
    this.editDefaultMeetingType = meetingTypeId;
    let parameter = {
      meeting_id: meetingTypeId
    }
    this._prospectService.getMeetingTypeById(parameter).subscribe(
      res => {
        if (res.result == true) {
          this.meetingDetails.meeting_name = res.data.name;
          this.meetingDetails.meeting_yes_option = res.data.option_yes;
          this.meetingDetails.meeting_no_option = res.data.option_no;
        } else {
          this.commonService.showCustomError(res);
        }
      },
      error => {
        this.customErrorHandler.handleHttpError(error, 'priorityList');
      }
    )
  }
}
